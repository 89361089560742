import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.List
    }
  },
  Chart: {
    Datasets: {
      Label: AppModule.Omzet.Name,
      BackgroundColor: "#42b983"
    },
    Height: AppCatalog.Chart.Canvas.Height,
    ScaleLabel: {
      FontColor: "black",
      FontSize: 20,
      FontStyle: "bold"
    },
    Threshold: {
      BorderColor: "red",
      BorderWidth: 2,
      DrawTime: "afterDraw",
      Mode: "horizontal",
      ScaleID: "y-axis-0",
      Type: "line"
    },
    Type: "bar"
  },
  Section: {
    InvoiceSummary: {
      Title: AppCatalog.Info.Summary + " " + AppModule.Invoice.FullName,
      // support
      SellPrice: AppCatalog.Field.SellPrice.Label + " Normal",
      SpecialPrice: AppCatalog.Field.SellPrice.Label + " Spesial",
      TotalDisc: AppCatalog.Field.Disc.Label
    },
    PosSummary: {
      Title: AppCatalog.Info.Summary + " " + AppModule.Pos.FullName,
    },
    Summary: {
      Title: AppModule.Omzet.Name
    }
  }
}