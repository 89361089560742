<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <ModuleAttrRelatedGlobalDetails
          :moduleAttrList="details.ModuleAttrList"
        />
        <ModuleList
          :moduleList="details.ModuleList"
          :moduleAttrList="details.ModuleAttrList"
        />
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../AcsInfo.js";
import Model from "../AcsModel.js";
import Services from "../../../services/Api/AccessServices.js";

import Details from "./AcsD_Details.vue";
import ModuleList from "./AcsD_ModuleList.vue";
import ModuleAttrRelatedGlobalDetails from "../../ModuleAttr/RelatedGlobalDetails/Page.vue";

export default {
  components: {
    Details,
    ModuleList,
    ModuleAttrRelatedGlobalDetails
  },
  data: () => ({
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
    },

    /*** custom ***/

    resetData(id) {
      const access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.activeTab = 0;

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, access
      );
    }
  }
}
</script>