import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import SpadItemCatalog from "./SpadItemCatalog.js";

export default {
  getItemInfo(userItem) {
    let infoList = [];

    // deleted item
    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    else {
      // SKU
      infoList.push(SpadItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, SpadItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(SpadItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", userItem, SpadItemCatalog)
      );
    }

    return infoList;
  }
}