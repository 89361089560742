import App from "../../services/App/App.js";

export default {
  NewItem: {
    ItemID: 0,
    PackagingName: "pcs"
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  }
}