import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ActivityTypeName: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  ChangesIn: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Qty.Label + " Masuk",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  ChangesOut: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Qty.Label + " Keluar",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  // DEPRECATED
  Changes: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  }
}