<template>
  <div class="field">
    <template v-if="viewMode">
      <ViewCmp
        :catalog="catalog"
        :data="data"
        :isActiveField="isActiveField"
        :dateField="dateField"
        :numberField="numberField"
      />
    </template>
    <template v-else-if="inputMode">
      <Input
        :autoNumberField="autoNumberField"
        :catalog="catalog"
        :data="data"
        :dateDisabled="dateDisabled"
        :dateField="dateField"
        :dateValidations="dateValidations"
        :numberField="numberField"
        @input="handleInput_Input"
      />
    </template>
    <template v-else-if="searchMode">
      <Search
        :catalog="catalog"
        :data="data"
        :dateField="dateField"
        :numberField="numberField"
        @search="handleSearch_Search"
        @clear="handleSearch_Clear"
      />
    </template>
  </div>
</template>

<script>
import Input from "./NumberWithDate_Input.vue";
import Search from "./NumberWithDate_Search.vue";
import ViewCmp from "./NumberWithDate_View.vue";

export default {
  props: {
    catalog: Object,
    isActiveField: String,
    data: Object,
    autoNumberField: String,
    dateField: String,
    numberField: String,
    // mode: ONLY select 1 (one)
    inputMode: Boolean,
    viewMode: Boolean,
    searchMode: Boolean,
    // for inputMode
    dateDisabled: {
      type: Boolean,
      default: undefined
    },
    dateValidations: Object
  },
  components: {
    Input,
    Search,
    ViewCmp
  },
  methods: {
    /*** by input component ***/

    handleInput_Input(dateValue, numberValue) {
      this.$emit(this.$kst.Enum.Event.Input, dateValue, numberValue);
    },

    /*** by search component ***/

    handleSearch_Search() {
      this.$emit(this.$kst.Enum.Event.Search);
    },
    handleSearch_Clear() {
      this.$emit(this.$kst.Enum.Event.Clear);
    }
  }
}
</script>