<template>
  <kst-table paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
    
    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="sellPrice">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="Catalog.SellPrice.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template v-if="weight">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-weight"
        field="Weight"
        :label="Catalog.Weight.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template slot="empty">
      <kst-table-empty>
        <template v-if="newItem">
          <kst-button action-mode
            class="is-primary mt-4"
            :label="Info.Button.NewItem"
            :moduleType="Model.ModuleType.New"
            @click="handleAdd_NewItem"
          />
        </template>
      </kst-table-empty>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../StkCatalog.js";
import Info from "../StkInfo.js";
import Model from "../StkModel.js";

export default {
  props: {
    items: Array,
    userData: Object,
    // button visibility
    newItem: Boolean,
    // field visibility 
    sellPrice: Boolean,
    weight: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  methods: {
    handleAdd_NewItem() {
      this.$emit(this.$kst.Enum.Event.NewItem);
    }
  }
}
</script>