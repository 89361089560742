import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./InvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.SONumber.Label + ", atau " +
      Catalog.PONumber.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },

  ClientID: {
    Label: Catalog.ClientID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "Client",
    Param: AppModule.Client.Param + AppCatalog.Field.ID.Param
  },
  Client: {
    Label: Catalog.ClientID.Label,
    Type: Catalog.Client.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  InvoiceDate: {
    Label: Catalog.InvoiceDate.Label,
    Type: Catalog.InvoiceDate.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  RecordTypeID: {
    Label: AppCatalog.Field.RecordTypeID.Label,
    Type: AppCatalog.Field.RecordTypeID.Type,
    Input: AppCatalog.Field.RecordTypeID.Input,
    Param: App.Enum.Param.Invoice.RecordTypeID
  },
  Status: {
    Label: Catalog.Status.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}