<template>
  <kst-page-report 
    :catalog="OmzSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @loaded="handleLoaded"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result>
      <Result
        :chartData="chartData"
        :invoiceSummaryList="invoiceSummaryList"
        :posSummaryList="posSummaryList"
        :summaryData="summaryData"
        :searchData="searchData"
      />
    </template>
  </kst-page-report>
</template>

<script>
import Model from "../OmzModel.js";
import OmzSearchCatalog from "../OmzSearchCatalog.js";
import Services from "../../../services/Api/OmzetServices.js";

import Result from "./OmzR_Result.vue";
import Search from "./OmzR_Search.vue";

export default {
  components: {
    Result,
    Search
  },
  data: () => ({
    Model: Model,
    OmzSearchCatalog: OmzSearchCatalog,
    access: {},
    chartData: {},
    invoiceSummaryList: [],
    posSummaryList: [],
    summaryData: {},
    searchData: {
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadReportData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getReportData(options);
      loadReportData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, OmzSearchCatalog.WarehouseID.Label
      );

      this.updateResult(data.TransactionDetails, data.InvoiceDetails,
        data.PosPaymentList
      );
    },

    handleLoad(loadReport) {
      const options = this.getListOptions();
      const config = Services.getReport(options);
      loadReport(config);
    },

    handleLoaded(data) {
      this.updateResult(data.TransactionDetails, data.InvoiceDetails,
        data.PosPaymentList
      );
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.chartData = Model.createChart();
      this.invoiceSummaryList = [];
      this.posSummaryList = [];
      this.summaryData = Model.createSummary();
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID)
      };
    },

    updateResult(transactionDetails, invoiceDetails, posPaymentList) {
      this.chartData = Model.Report.getChart(transactionDetails,
        this.searchData
      );
      this.invoiceSummaryList = Model.Report.getInvoiceSummary(invoiceDetails);
      this.posSummaryList = posPaymentList;
      this.summaryData = Model.Report.getSummary(transactionDetails,
        this.access, this.searchData
      );
    }
  }
}
</script>