import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  LastUpdatedBy: {
    Label: AppCatalog.Field.LastUpdatedBy.Label,
    Type: AppCatalog.Field.LastUpdatedBy.Type
  },
  LastUpdatedByID: {
    Label: AppCatalog.Field.LastUpdatedByID.Label,
    Type: AppCatalog.Field.LastUpdatedByID.Type,
    RelatedValue: AppCatalog.Field.LastUpdatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.LastUpdatedByID.RelatedModule
  },
  LastUpdatedDate: {
    Label: AppCatalog.Field.LastUpdatedDate.Label,
    Type: AppCatalog.Field.LastUpdatedDate.Type,
    ReadOnly: AppCatalog.Field.LastUpdatedDate.ReadOnly,
    Output: AppCatalog.Field.LastUpdatedDate.Output
  }
}