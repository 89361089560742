<template>
  <kst-section collapsed
    :collapsible="sectionCollapsible"
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
  >
    <kst-input field="DueDate" :data="userData" :catalog="Catalog"
      :customValidations="dueDateValidations"
    />
    <kst-input field="PaymentTypeName" :data="userData" :catalog="Catalog"
      :options="paymentOptions"
    />
    <template v-if="paymentValueVisible">
      <kst-input field="PaymentValue" :data="userData" :catalog="Catalog">
        <template #left>
          <div class="control">
            <kst-button fullPaymentMode @click="handlePaymentValue_fullPayment"/>
          </div>
        </template>
      </kst-input>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    userData: Object,
    paymentOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    dueDateValidations() {
      return Model.New.validationDueDate();
    },
    paymentValueVisible() {
      return this.userData.PaymentTypeName !== this.$kst.Search.OptionNone;
    },
    sectionCollapsible() {
      return this.userData.PaymentTypeName === this.$kst.Search.OptionNone;
    },
    sectionTitle() {
      return Info.Section.RecPay.Title;
    },
    sectionHelp() {
      return Model.getRecPaySectionHelp(this.userData);
    }
  },
  methods: {
    handlePaymentValue_fullPayment() {
      Model.New.setDetailsByFullPayment(this.userData);
    }
  }
}
</script>