import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./DbcdCatalog.js";
import DbcdTransCatalog from "./DbcdTransCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  TransactionDate: {
    Label: Catalog.TransactionDate.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  TypeID: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.TransactionType.Param + AppCatalog.Field.ID.Param
  },
  VendorID: {
    Label: DbcdTransCatalog.VendorID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "VendorName",
    Param: AppModule.Vendor.Param + AppCatalog.Field.ID.Param
  },
  VendorName: {
    Label: DbcdTransCatalog.VendorID.Label,
    Type: DbcdTransCatalog.VendorName.Type,
    Param: AppModule.Vendor.Param + AppCatalog.Field.Name.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}