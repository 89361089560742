<template>
  <div>
    <kst-load-data activeOnLoad @init="handleLoad_Init" />

    <kst-input load-on-focus field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input field="Email" :data="userData" :catalog="Catalog"/>
    <kst-input field="Password" :data="userData" :catalog="Catalog"
      :customValidations="passwordValidations"
    />
    <kst-input field="Phone" :data="userData" :catalog="Catalog"/>
    <kst-input field="AccessID" :data="userData" :catalog="Catalog"
      :options="accessOptions" @input="handleInput_AccessID"
    />
    <kst-input field="DefaultWarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input field="DefaultModuleID" :data="userData" :catalog="Catalog"
      :options="defaultModuleOptions"
    />
  </div>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Model from "../UserModel.js";
import Services from "../../../services/Api/UserServices.js";

export default {
  props: {
    userData: Object,
    accessOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    defaultModuleOptions: {},
    Catalog: Catalog,
    // func
    loadDataFunc: null,
  }),
  computed: {
    passwordValidations() {
      return Model.validationPassword();
    },
  },
  methods: {
    /*** by load data ***/

    handleLoad_Init(loadData) {
      this.loadDataFunc = loadData;
    },
    
    handleInput_AccessID() {
      this.userData.DefaultModuleID = null;
      this.defaultModuleOptions = {};
      
      this.loadAccessModuleData();
    },

    loadAccessModuleData() {
      const config = Services.getRelatedAccessAccessibility(this.userData.AccessID);

      this.loadDataFunc(config, this.loadAccessModuleData_Success, true);
    },
    loadAccessModuleData_Success(data){
      this.defaultModuleOptions = Model.createDefaultModuleOptions(data.ModuleList);
    },
  }
}
</script>