<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <Details :details="details"/>
    <CompanyProfile :details="details"/>
  </kst-page-details>
</template>

<script>
import Model from "../WhsModel.js";
import Services from "../../../services/Api/WarehouseService.js";

import Details from "./WhsD_Details.vue";
import CompanyProfile from "./WhsD_CompanyProfile.vue";

export default {
  components:{
    Details,
    CompanyProfile,
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
  }),
  methods: {
    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
    },

     /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.details = {};

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
 
}
</script>