import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import SttrItemCatalog from "./SttrItemCatalog.js";

export default {
  getItemInfo(userItem) {
    let infoList = [];

    // deleted item
    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(SttrItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, SttrItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(SttrItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", userItem, SttrItemCatalog)
      );
    }

    return infoList;
  }
}