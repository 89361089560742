<template>
  <div class="block">
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input field="IsActive" :data="userData" :catalog="Catalog"
      :disabled="userData.HasUser"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../AcsCatalog.js";
import Info from "../AcsInfo.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return this.userData.HasUser ? [Info.Message.Edit_IsActive] : [];
    }
  }
}
</script>