import App from "../../services/App/App.js";
import ModelBody from "./DbpyModel_Body.js";
import ModelDetails from "./DbpyModel_Details.js";
import ModelEditStatus from "./DbpyModel_EditStatus.js";
import ModelList from "./DbpyModel_List.js";
import ModelNew from "./DbpyModel_New.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  DebtPayReceive: ModelBody.DebtPayReceive,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  StatusID: ModelBody.StatusID,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createReceive() {
    return ModelBody.createReceive();
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.StatusID);
  },

  setDetailsByStatus(details) {
    ModelBody.setDetailsByStatus(details, ModelBody.StatusID);
  },

  populateData(userData, userReceiveList) {
    let result = ModelBody.populateDetails(userData);
    result.ReceiveList = ModelBody.populateReceiveList(userReceiveList);
    return result;
  },

  /*** method - Details ***/

  Details: {
    Actions: ModelDetails.Actions
  },

  /*** method - EditStatus ***/

  EditStatus: {
    setDetailsByEditData(userData, debtPayData) {
      ModelEditStatus.setDetailsByEditData(userData, debtPayData,
        ModelBody.StatusID
      );
    },

    getDebtPaySectionHelp(debtPayData) {
      return ModelEditStatus.getDebtPaySectionHelp(debtPayData);
    },

    populateData(userData) {
      return ModelEditStatus.populateData(userData);
    }
  },

  /*** method - List ***/

  List: {
    Search: ModelList.Search
  },

  /*** method - New ***/

  New: {
    setDetailsByDetailsVerified(userData) {
      ModelNew.setDetailsByDetailsVerified(userData);
    },
    setDetailsByPaymentList(userData, paymentList) {
      ModelNew.setDetailsByPaymentList(userData, paymentList);
    },
    setDetailsByVendor(userData, vendorData) {
      ModelNew.setDetailsByVendor(userData, vendorData);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    setReceiveByPaymentValue(userData, userReceiveList, selectedReceive) {
      ModelNew.setReceiveByPaymentValue(userData, userReceiveList,
        selectedReceive
      );
    },
    setReceiveErrors(userReceive, field, errors) {
      userReceive.Errors = App.Vee.getRowErrors(userReceive, field, errors);
    },

    setReceiveListBySearch(userReceiveList, receiveList) {
      ModelNew.setReceiveListBySearch(userReceiveList, receiveList);
    },
    setReceiveListByCheck(userData, userReceiveList, selectedReceiveList,
      selectedReceive
    ) {
      ModelNew.setReceiveListByCheck(userData, userReceiveList,
        selectedReceiveList, selectedReceive
      );
    },
    setReceiveListByCheckAll(userData, userReceiveList) {
      ModelNew.setReceiveListByCheckAll(userData, userReceiveList);
    },
    setReceiveListByUncheckAll(userData, userReceiveList) {
      ModelNew.setReceiveListByUncheckAll(userData, userReceiveList);
    },

    getInvalidReceiveTotal(userData) {
      return ModelNew.getInvalidReceiveTotal(userData);
    },

    clearDetailsByVendor(userData) {
      ModelNew.clearDetailsByVendor(userData);
    },
  
    resetReceiveTotal(userData) {
      ModelNew.resetReceiveTotal(userData);
    },

    updateDetails(userData, userReceiveList, selectedReceive) {
      ModelNew.updateDetails(userData, userReceiveList, selectedReceive);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.RelatedList, ModelBody.Module, access
      );
    }
  },

  /*** external ***/

  getPaymentOptions(paymentList, optionAllText) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText);
  },

  getWarehouseOptions(warehouseList, optionAllText, optionSomeText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText, optionSomeText);
  }
}