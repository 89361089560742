import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";

import Version from "./Version";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Version)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Version),
        name: RouterModel.getName(AppModule.Version),
        component: Version,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Version),
          module: RouterModel.getModule(AppModule.Version)
        }
      }
    ];
  }
}