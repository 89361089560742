import App from "../../services/App/App.js";

export default {
  createOptions(dataList, optionAllText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  }
}