<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RcvCatalog.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import RcvItemCatalog from "../RcvItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.PurchasingID.ExportLabel);
      this.addColumnHeader(Catalog.PurchasingID.Label);
      this.addColumnHeader(Catalog.PurchasingIsActive);
      this.addColumnHeader(Catalog.ReceiveNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(Catalog.Total);
        this.addColumnHeader(Catalog.DueDate);
        this.addColumnHeader(Catalog.DebtTotal);
      }

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(RcvItemCatalog.DispatchID.Label);
        this.addColumnHeader(RcvItemCatalog.DispatchID);
        this.addColumnHeader(RcvItemCatalog.Packaging);
        this.addColumnHeader(RcvItemCatalog.Quantity);
        this.addColumnHeader(RcvItemCatalog.TotalQuantity);
        if (this.access.Fld_Amount) {
          this.addColumnHeader(RcvItemCatalog.BuyPrice);
          this.addColumnHeader(RcvItemCatalog.ExpenseAmount);
        }
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("PurchasingID", row, Catalog);
        this.addCellRelatedValue("PurchasingID", row, Catalog);
        if (row.PurchasingID !== null) {
          this.addCellStatus(row.PurchasingIsActive, 
            Model.StatusPurchasingIsActive);
        } else {
          this.addCell();
        }
        this.addCellValue("ReceiveNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellRelatedValue("VendorID", row, Catalog);
        this.addCellValue("VendorAddress", row, Catalog);
        if (this.access.Fld_Amount) {
          this.addCellValue("Total", row, Catalog);
          this.addCellValue("DueDate", row, Catalog);
          this.addCellValue("DebtTotal", row, Catalog);
        }

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, RcvItemCatalog);
          this.addCellRelatedValue("DispatchID", row, RcvItemCatalog);
          this.addCellValue("Packaging", row, RcvItemCatalog);
          this.addCellValue("Quantity", row, RcvItemCatalog);
          this.addCellValue("TotalQuantity", row, RcvItemCatalog);
          if (this.access.Fld_Amount) {
            this.addCellValue("BuyPrice", row, RcvItemCatalog);
            this.addCellValue("ExpenseAmount", row, RcvItemCatalog);
          }
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>