import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.CompanyProfile,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
  },

  // related
  Price: {
    Module: AppModule.Price,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  Email: {
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Company_Act_Details,
    Act_Edit: AppModuleAttr.Company_Act_Edit
  },

  Actions: {
    Details: [
      AppModuleType.Edit
    ]
  },

  /*** method ***/

  createDetails() {
    return {
      id: null,
      // user input
      name: "",
      address: "",
      phone: "",
      email: "",
      owner: "",
      spv_passwd: "",
      ImageSrc: "",
      Image64: "",
      ImageIsLocal: true,
      // user input - setting
      InvoiceClientSourceID: null,
      InvoicePrintNote: "",
      ProfitLossRptTypeID: null,
      ReceiveVendorSourceID: null,
      SelfWarehouseID: null
    };
  },

  populateData(userData) {
    return {
      name: App.Out.getString(userData.name),
      address: App.Out.getString(userData.address),
      phone: App.Out.getString(userData.phone),
      email: App.Out.getString(userData.email),
      owner: App.Out.getString(userData.owner),
      Image64: (userData.ImageIsLocal ? userData.Image64 : null),
      // user input - setting
      DebtDueReminderDays: App.Out.getInteger(userData.DebtDueReminderDays),
      DebtEmailSentTime: App.Out.getTimeString(userData.DebtEmailSentTime),
      InvoiceClientSourceID: userData.InvoiceClientSourceID,
      InvoicePrintNote: App.Out.getString(userData.InvoicePrintNote),
      ProfitLossRptTypeID: userData.ProfitLossRptTypeID,
      RecDueReminderDays: App.Out.getInteger(userData.RecDueReminderDays),
      ReceiveVendorSourceID: userData.ReceiveVendorSourceID,
      RecEmailSentTime: App.Out.getTimeString(userData.RecEmailSentTime),
      SelfWarehouseID: userData.SelfWarehouseID
    };
  }
}