import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./RcvCatalog.js";
import ModelBody from "./RcvModel_Body.js";
import RcvItemCatalog from "./RcvItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";
import PurchaseModel from "../Purchase/PurModel.js";

export default {
  setDetailsByEditData(userData, editDetails) {
    userData.ID = editDetails.ID;
    // user input
    userData.PurchasingID = editDetails.PurchasingID;
    userData.WarehouseID = editDetails.WarehouseID;
    userData.ReceiveNumber = App.In.getString(editDetails.ReceiveNumber);
    userData.IsAutoNumber = false;
    userData.VendorID = editDetails.VendorID;
    userData.VendorName = App.In.getString(editDetails.VendorName);
    userData.VendorAddress = App.In.getString(editDetails.VendorAddress);
    userData.Comment = App.In.getString(editDetails.Comment);
    userData.DueDate = App.In.getDate(editDetails.DueDate);
    // by system
    userData.Date = App.In.getDate(editDetails.Date);
    userData.PurchasingNumber = editDetails.PurchasingNumber;
    userData.PurchasingDate = editDetails.PurchasingDate;
    userData.PurchasingIsActive = App.In.getBoolean(editDetails.PurchasingIsActive);
    userData.VendorAlias = editDetails.VendorAlias;
  },

  setItemByPurchase(userItem, purchaseItem) {
    userItem.PurchasingItemID = purchaseItem.ID;
    // user input
    userItem.DispatchID = purchaseItem.DispatchID;
    userItem.Name = App.In.getString(purchaseItem.Name);
    userItem.Quantity = App.In.getInteger(purchaseItem.QtyAvailable);
    userItem.PackagingName = purchaseItem.PackagingName;
    userItem.BuyPrice = App.In.getInteger(purchaseItem.BuyPrice);
    // by system
    userItem.SKU = purchaseItem.SKU;
    userItem.PackagingValue = purchaseItem.PackagingValue;
    userItem.PurchasingItemQuantity = purchaseItem.QtyAvailable;
    // validation
    userItem.CustomValidations = {
      [App.Vee.Rule.MaxValue]: [
        userItem.PurchasingItemQuantity,
        RcvItemCatalog.PurchasingItemQuantity.Label
      ]
    };
  },
  setItemsByEditData(userData, userItems, details, purchaseItems, returnItemSummaryList) {
    let item, returnItem;
    App.Array.truncate(userItems);

    for (const row of details.Items) {
      item = ModelBody.createItem(userData);

      returnItem = App.Array.searchItem(
        returnItemSummaryList, "ReceiveItemID", row.ID
      );

      this.setItemsByEditData_setItem(item, row, details, purchaseItems, returnItem);
      ModelBody.updateItem(item);
      userItems.push(item);
    }
  },
  setItemsByEditData_setItem(userItem, editItem, details, purchaseItems, returnItem) {
    userItem.ID = editItem.ID;
    // user input
    userItem.DispatchID = editItem.DispatchID;
    userItem.Name = App.In.getString(editItem.Name);
    userItem.Quantity = App.In.getInteger(editItem.Quantity);
    userItem.PackagingName = editItem.PackagingName;
    userItem.BuyPrice = App.In.getInteger(editItem.BuyPrice);
    // by system
    userItem.SKU = editItem.SKU;
    userItem.PackagingValue = editItem.PackagingValue;
    userItem.DispatchIsActive = editItem.DispatchIsActive;

    if (userItem.DispatchIsActive) {
      if (editItem.PurchasingItemID !== null &&
        details.PurchasingIsActive === 1
      ) {
        userItem.PurchasingItemID = editItem.PurchasingItemID;

        const purchaseItem = App.Array.searchItem(
          purchaseItems, "ID", editItem.PurchasingItemID
        );

        userItem.PurchasingItemQuantity = purchaseItem === null
          ? 0 : purchaseItem.QtyAvailable;
        userItem.CustomValidations[App.Vee.Rule.MaxValue] = [
          userItem.PurchasingItemQuantity,
          RcvItemCatalog.PurchasingItemQuantity.Label
        ];
      } else {
        userItem.PackagingOptions = ItemModel.createPackagingOptions(
          editItem.PackagingList);
      }

      if (returnItem !== null) {
        userItem.ReturnItemQuantity = returnItem.Qty;
        userItem.CustomValidations[App.Vee.Rule.MinValue] = [
          userItem.ReturnItemQuantity,
          RcvItemCatalog.ReturnItemQuantity.Label
        ]; 
      }
    }
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    } 
    else {
      // SKU
      infoList.push(RcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RcvItemCatalog)
      );
    }

    // Purchase
    if (item.PurchasingItemID) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Purchase.FullName
      );
    }

    return infoList;
  },

  compareData(userData, userItems, receiveData) {
    const data = ModelBody.populateDetails(userData);
    const items = ModelBody.populateItems(userItems);
    let fields;

    // normalize: details
    data.Date = App.Compare.getDateTime(data.Date);
    data.DueDate = App.Compare.getDateTime(data.DueDate);
    receiveData.Date = App.Compare.getDateTime(receiveData.Date);
    receiveData.DueDate = App.Compare.getDateTime(receiveData.DueDate);

    // normalize: items
    for (let item of receiveData.Items) {
      item.BuyPrice = App.Compare.getDecimal(item.BuyPrice);
    }

    /*** compare: details ***/

    // fields: based on populateDetails
    fields = [
      // skip: ID
      "PurchasingID", "WarehouseID", "ReceiveNumber", "Date", "VendorID",
      "VendorName", "VendorAddress", "Comment", "DueDate"
    ];
    if (!App.Compare.validateObject(data, receiveData, fields)) {
      return false;
    }

    /*** compare: items ***/

    // fields: based on populateItems
    fields = [
      "ID", "PurchasingItemID", "DispatchID", "Name", "Quantity",
      "PackagingName", "PackagingValue", "BuyPrice"
    ];
    if (!App.Compare.validateArray(items, receiveData.Items, fields)) {
      return false;
    }

    return true;
  },

  validationDueDate(userData) {
    return {
      [App.Vee.Rule.MinDate]: {
        target: userData.Date,
        label: Catalog.Date.Label
      }
    };
  },

  /*** external ***/

  getPurchaseItemsForSelection(purchaseItems, userItems) {
    let resultItems = [];
    let item;

    for (const purchaseItem of purchaseItems) {
      item = App.Array.searchItem(
        userItems, "PurchasingItemID", purchaseItem.ID
      );

      if (!item) {
        resultItems.push(purchaseItem);
      }
    }

    return resultItems;
  },

  getPurchaseItems(purchaseItemList, receiveItems) {
    let purchaseItems = [];
    let receiveItem, isValid;

    for (let oldPurchaseItem of purchaseItemList) {
      isValid = true;

      receiveItem = App.Array.searchItem(receiveItems, "PurchasingItemID",
        oldPurchaseItem.ID
      );

      // populate: ReceiveQty
      oldPurchaseItem.ReceiveQty = receiveItem === null
        ? 0 : receiveItem.Quantity;

      oldPurchaseItem.QtyAvailable =
        oldPurchaseItem.Quantity - oldPurchaseItem.ReceiveQty;

      if (receiveItem !== null) {
        oldPurchaseItem.QtyAvailable += receiveItem.Quantity;
      }

      // deleted-item
      else if (oldPurchaseItem.DispatchIsActive === 0) {
        isValid = false;
      }
      else if (receiveItem === null) {
        // closed item
        if (oldPurchaseItem.IsClosed === 1) {
          isValid = false;
        }
        // qty available
        else if (oldPurchaseItem.QtyAvailable <= 0) {
          isValid = false;
        }
      }

      if (isValid) {
        purchaseItems.push(oldPurchaseItem);
        PurchaseModel.updateItem(oldPurchaseItem);
      }
    }

    return purchaseItems;
  }
}