<template>
  <kst-table action paginated view-mode :data="dataList">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdatetime"
      field="CreatedDate"
      :label="Catalog.CreatedDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getResultInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="TransactionNumber"
      :label="Catalog.TransactionNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="isAllWarehouse">
      <b-table-column sortable
        v-slot="{ column, row }"
        cell-class="ks-col-warehouse"
        field="TransWarehouseID"
        :label="DbcdTransCatalog.TransWarehouseID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
      </b-table-column>
    </template>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveID"
      :label="DbcdTransCatalog.ReceiveID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ReceiveDebtChanges"
      :label="DbcdTransCatalog.ReceiveDebtChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ReceiveDebtAfter"
      :label="DbcdTransCatalog.ReceiveDebtAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VendorID"
      :label="DbcdTransCatalog.VendorID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="VendorDebtChanges"
      :label="DbcdTransCatalog.VendorDebtChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="VendorDebtAfter"
      :label="DbcdTransCatalog.VendorDebtAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <template #action="{ row }">
      <template v-if="row.HasAccess">
        <kst-actions fullLabel :actions="row.Actions"/>
      </template>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DbcdCatalog.js";
import DbcdTransCatalog from "../DbcdTransCatalog.js";
import Model from "../DbcdModel.js";

export default {
  props: {
    searchData: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    DbcdTransCatalog: DbcdTransCatalog,
    Model: Model,
    isAllWarehouse: false
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isAllWarehouse =
        this.$kst.Search.getValue(this.searchData.WarehouseID, null) === null;
      Model.updateList(this.dataList);
    }
  }
}
</script>