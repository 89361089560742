<template>
  <kst-table action paginated view-mode
    :data="items"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="ModuleName"
      :label="Catalog.ModuleName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="AutoNumberTypeID"
      :label="Catalog.AutoNumberTypeID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PrefixSuffix"
      :label="Catalog.PrefixSuffix.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Year"
      :label="Catalog.Year.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Month"
      :label="Catalog.Month.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="Counter"
      :label="Catalog.Counter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      field="CounterLength"
      :label="Catalog.CounterLength.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Model from "../AnumModel";
import Catalog from "../AnumCatalog.js";

export default {
  props: {
    items: Array,
  },
  data: () => ({
    Catalog: Catalog
  }),
  created() {
    for (let data of this.items) {
      Model.updateDetails(data);
    }
  },
}
</script>