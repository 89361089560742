<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RcvCatalog.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import RcvItemCatalog from "../RcvItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details);

      if (this.userData.WithReceiveList) {
        if (data.ReceiveList) {
          this.createSheet();
          this.addReceiveList(data.ReceiveList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);
      this.addDetails_Item(details.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ReceiveNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.PurchasingID.ExportLabel);
      this.addColumnHeader(Catalog.PurchasingID.Label);
      this.addColumnHeader(Catalog.PurchasingDate);
      this.addColumnHeader(Catalog.PurchasingIsActive);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(Catalog.Total);
      }
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("ReceiveNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("PurchasingID", details, Catalog);
      this.addCellRelatedValue("PurchasingID", details, Catalog);
      this.addCellValue("PurchasingDate", details, Catalog);
      if (details.PurchasingID !== null) {
        this.addCellStatus(details.PurchasingIsActive, 
          Model.StatusPurchasingIsActive);
      } else {
        this.addCell();
      }
      this.addCellValue("VendorID", details, Catalog);
      this.addCellValue("VendorAlias", details, Catalog);
      this.addCellRelatedValue("VendorID", details, Catalog);
      this.addCellValue("VendorAddress", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      if (this.access.Fld_Amount) {
        this.addCellValue("Total", details, Catalog);
      }
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },
    addDetails_Item(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ReceiveItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(RcvItemCatalog.DispatchID.Label);
      this.addColumnHeader(RcvItemCatalog.DispatchID);
      this.addColumnHeader(RcvItemCatalog.SKU);
      this.addColumnHeader(RcvItemCatalog.PurchasingItemQuantity);
      this.addColumnHeader(RcvItemCatalog.Packaging);
      this.addColumnHeader(RcvItemCatalog.Quantity);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(RcvItemCatalog.BuyPrice);
        this.addColumnHeader(RcvItemCatalog.ExpenseAmount);
      }

      // value
      for ( const row of items ) {
        Model.updateItemReadOnly(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, RcvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, RcvItemCatalog);
        this.addCellValue("SKU", row, RcvItemCatalog);
        this.addCellValue("PurchasingItemQuantity", row, RcvItemCatalog);
        this.addCellValue("Packaging", row, RcvItemCatalog);
        this.addCellValue("Quantity", row, RcvItemCatalog);
        if (this.access.Fld_Amount) {
          this.addCellValue("BuyPrice", row, RcvItemCatalog);
          this.addCellValue("ExpenseAmount", row, RcvItemCatalog);
        }
      }
    }
  }
}
</script>