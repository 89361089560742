import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.Transaction,
  ModuleType: {
    Export: AppModuleType.Export,
    Report: AppModuleType.Report,
    Print: AppModuleType.Print
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.Transaction_Act_Export,
    Act_Report: AppModuleAttr.Transaction_Act_Report,
    Act_Print: AppModuleAttr.Transaction_Act_Print,
    // related: non-action
    Purchase_Fld_Amount: AppModuleAttr.Purchase_Fld_Amount,
    Receive_Fld_Amount: AppModuleAttr.Receive_Fld_Amount,
    // related: based on menu
    Expenses_Act_Details: AppModuleAttr.Expenses_Act_Details,
    StockOpname_Act_Details: AppModuleAttr.StockOpname_Act_Details,
    StockReduction_Act_Details: AppModuleAttr.StockReduction_Act_Details,
    StockTrf_Act_Details: AppModuleAttr.StockTrf_Act_Details,
    StockClosing_Act_Details: AppModuleAttr.StockClosing_Act_Details,
    HppAdj_Act_Details: AppModuleAttr.HppAdj_Act_Details,
    SpecialPriceAdj_Act_Details: AppModuleAttr.SpecialPriceAdj_Act_Details,
    Purchase_Act_Details: AppModuleAttr.Purchase_Act_Details,
    Receive_Act_Details: AppModuleAttr.Receive_Act_Details,
    RtnReceive_Act_Details: AppModuleAttr.RtnReceive_Act_Details,
    Sales_Act_Details: AppModuleAttr.Sales_Act_Details,
    Invoice_Act_Details: AppModuleAttr.Invoice_Act_Details,
    RtnInvoice_Act_Details: AppModuleAttr.RtnInvoice_Act_Details,
    Delivery_Act_Details: AppModuleAttr.Delivery_Act_Details,
    SalesRecap_Act_Details: AppModuleAttr.SalesRecap_Act_Details
  },

  UrlParams: ["Date", "ItemID", "WarehouseID", "TransactionTypeID"],

  /*** method ***/

  createTransOptions(optionAllText) {
    return this.helper.createTransOptions(optionAllText);
  },

  updateTransactionList(transList, access) {
    this.helper.updateTransactionList(transList, access);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createTransOptions(optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      const items = [
        { [rowId]: AppModule.Expense.ID, [rowLabel]: AppModule.Expense.FullName },
        { [rowId]: AppModule.StockOpname.ID, [rowLabel]: AppModule.StockOpname.FullName },
        { [rowId]: AppModule.StockReduction.ID, [rowLabel]: AppModule.StockReduction.FullName },
        { [rowId]: AppModule.StockTrf.ID, [rowLabel]: AppModule.StockTrf.FullName },
        { [rowId]: AppModule.StockClosing.ID, [rowLabel]: AppModule.StockClosing.FullName },
        { [rowId]: AppModule.HppAdj.ID, [rowLabel]: AppModule.HppAdj.FullName },
        { [rowId]: AppModule.SpecialPriceAdj.ID, [rowLabel]: AppModule.SpecialPriceAdj.FullName },
        { [rowId]: AppModule.Purchase.ID, [rowLabel]: AppModule.Purchase.FullName },
        { [rowId]: AppModule.Receive.ID, [rowLabel]: AppModule.Receive.FullName },
        { [rowId]: AppModule.RtnReceive.ID, [rowLabel]: AppModule.RtnReceive.FullName },
        { [rowId]: AppModule.Sales.ID, [rowLabel]: AppModule.Sales.FullName },
        { [rowId]: AppModule.Invoice.ID, [rowLabel]: AppModule.Invoice.FullName },
        { [rowId]: AppModule.RtnInvoice.ID, [rowLabel]: AppModule.RtnInvoice.FullName },
        { [rowId]: AppModule.Delivery.ID, [rowLabel]: AppModule.Delivery.FullName },
        { [rowId]: AppModule.SalesRecap.ID, [rowLabel]: AppModule.SalesRecap.FullName }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    updateTransactionList(transList, access) {
      const moduleObj = this.updateTransactionList_getModuleObj();

      for (const trans of transList) {
        trans.HasAmountAccess = this.updateTransactionList_hasAmountAccess(
          trans, access
        );
        trans.ModuleName = moduleObj[trans.ModuleID].FullName;
        trans.Packaging = trans.PackagingName
          ? App.Data.getPackaging(trans.PackagingName, trans.PackagingValue)
          : null;
        trans.UrlParams = App.Route.getParams({
          [AppCatalog.Field.ID.Param]: trans.ID
        });

        switch(trans.ModuleID) {
          case AppModule.Expense.ID:
            trans.HasAccess = access.Expenses_Act_Details;
            trans.Module = AppModule.Expense;
            break;
          case AppModule.StockOpname.ID:
            trans.HasAccess = access.StockOpname_Act_Details;
            trans.Module = AppModule.StockOpname;
            break;
          case AppModule.StockReduction.ID:
            trans.HasAccess = access.StockReduction_Act_Details;
            trans.Module = AppModule.StockReduction;
            break;
          case AppModule.StockTrf.ID:
            trans.HasAccess = access.StockTrf_Act_Details;
            trans.Module = AppModule.StockTrf;
            break;
          case AppModule.StockClosing.ID:
            trans.HasAccess = access.StockClosing_Act_Details;
            trans.Module = AppModule.StockClosing;
            break;
          case AppModule.HppAdj.ID:
            trans.HasAccess = access.HppAdj_Act_Details;
            trans.Module = AppModule.HppAdj;
            break;
          case AppModule.SpecialPriceAdj.ID:
            trans.HasAccess = access.SpecialPriceAdj_Act_Details;
            trans.Module = AppModule.SpecialPriceAdj;
            break;
          case AppModule.Purchase.ID:
            trans.HasAccess = access.Purchase_Act_Details;
            trans.Module = AppModule.Purchase;
            break;
          case AppModule.Receive.ID:
            trans.HasAccess = access.Receive_Act_Details;
            trans.Module = AppModule.Receive;
            break;
          case AppModule.RtnReceive.ID:
            trans.HasAccess = access.RtnReceive_Act_Details;
            trans.Module = AppModule.RtnReceive;
            break;
          case AppModule.Sales.ID:
            trans.HasAccess = access.Sales_Act_Details;
            trans.Module = AppModule.Sales;
            break;
          case AppModule.Invoice.ID:
            trans.HasAccess = access.Invoice_Act_Details;
            trans.Module = AppModule.Invoice;
            break;
          case AppModule.RtnInvoice.ID:
            trans.HasAccess = access.RtnInvoice_Act_Details;
            trans.Module = AppModule.RtnInvoice;
            break;
          case AppModule.Delivery.ID:
            trans.HasAccess = access.Delivery_Act_Details;
            trans.Module = AppModule.Delivery;
            break;
          case AppModule.SalesRecap.ID:
            trans.HasAccess = access.SalesRecap_Act_Details;
            trans.Module = AppModule.SalesRecap;
            break;
          default:
            trans.HasAccess = false;
        }
      }
    },
    updateTransactionList_getModuleObj() {
      return {
        // order based on menu
        [AppModule.Expense.ID]: AppModule.Expense,
        [AppModule.StockOpname.ID]: AppModule.StockOpname,
        [AppModule.StockReduction.ID]: AppModule.StockReduction,
        [AppModule.StockTrf.ID]: AppModule.StockTrf,
        [AppModule.StockClosing.ID]: AppModule.StockClosing,
        [AppModule.HppAdj.ID]: AppModule.HppAdj,
        [AppModule.SpecialPriceAdj.ID]: AppModule.SpecialPriceAdj,
        [AppModule.Purchase.ID]: AppModule.Purchase,
        [AppModule.Receive.ID]: AppModule.Receive,
        [AppModule.RtnReceive.ID]: AppModule.RtnReceive,
        [AppModule.Sales.ID]: AppModule.Sales,
        [AppModule.Invoice.ID]: AppModule.Invoice,
        [AppModule.RtnInvoice.ID]: AppModule.RtnInvoice,
        [AppModule.Delivery.ID]: AppModule.Delivery,
        [AppModule.SalesRecap.ID]: AppModule.SalesRecap
      };
    },
    updateTransactionList_hasAmountAccess(trans, access) {
      if (trans.ModuleID === AppModule.Purchase.ID) {
        return access.Purchase_Fld_Amount;
      }
      else if (trans.ModuleID === AppModule.Receive.ID) {
        return access.Receive_Fld_Amount;
      }

      return true;
    }
  }
}