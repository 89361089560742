import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    BestSellingItems: {
      Module: AppModule.Invoice,
      ModuleType: AppModuleType.List
    },
    DeathStock: {
      Module: AppModule.Stock,
      ModuleType: AppModuleType.List
    },
    LowStock: {
      Module: AppModule.LowStock,
      ModuleType: AppModuleType.List
    },
    StockWithoutInvoice: {
      Module: AppModule.Stock,
      ModuleType: AppModuleType.List
    },
    TopBuyer: {
      Invoice: {
        Module: AppModule.Invoice,
        ModuleType: AppModuleType.List
      },
      RtnInvoice: {
        Module: AppModule.RtnInvoice,
        ModuleType: AppModuleType.List
      }
    },
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.List
    }
  },
  Section: {
    BestSellingItems: {
      Title: "Barang Terlaris"
    },
    Data: {
      Title: "Data (" + AppCatalog.Field.Total.Label + ")",
      Help: AppCatalog.Help.StockSummary
    },
    DeathStock: {
      Title: AppCatalog.Field.Stock.Label + " Lama Tak Terjual"
    },
    LowStock: {
      Title: AppCatalog.Field.Stock.Label + " Rendah"
    },
    StockWithoutInvoice: {
      Title: AppCatalog.Field.Stock.Label + " Belum Pernah Terjual"
    },
    TopBuyer: {
      Title: "Top Buyer"
    },
    TransSummary: {
      Title: AppCatalog.Info.Summary + " Transaksi"
    }
  },
  Transaction: {
    DebtOverdue: AppModule.Debt.Name + " " + AppCatalog.Field.Overdue.Label,
    ProfitLoss: AppCatalog.Info.NettProfit,
    RecOverdue: AppModule.Rec.Name + " " + AppCatalog.Field.Overdue.Label
  }
}