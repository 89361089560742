import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  ClientRecAfter: {
    Label: AppModule.Rec.FullName + " Akhir",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ClientRecBefore: {
    Label: AppModule.Rec.FullName + " Awal",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ClientRecChanges: {
    Label: AppModule.Rec.FullName + " Masuk/Keluar",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 500,
    ReadOnly: true
  },
  InvoiceID: {
    Label: AppModule.Invoice.FullName,
    ExportLabel: AppCatalog.Field.Number.Label + " " + AppModule.Invoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "InvoiceNumber"
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  InvoiceRecAfter: {
    Label: AppModule.Rec.FullName + " Akhir",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  InvoiceRecBefore: {
    Label: AppModule.Rec.FullName + " Awal",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  InvoiceRecChanges: {
    Label: AppModule.Rec.FullName + " Masuk/Keluar",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },

  // support
  TransWarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "TransWarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}