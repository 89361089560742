<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";
import SlsItemCatalog from "../SlsItemCatalog.js";
import InvCatalog from "../../Invoice/InvCatalog.js";
import InvModel from "../../Invoice/InvModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      if (this.userData.WithInvoiceList) {
        if (Object.prototype.hasOwnProperty.call(data, "InvoiceList")) {
          this.createSheet();
          this.addInvoiceList(data.InvoiceList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_details(details);
      this.addDetails_items(details.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.DraftNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientAlias);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.StatusID);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("DraftNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("ClientID", details, Catalog);
      this.addCellValue("ClientAlias", details, Catalog);
      this.addCellRelatedValue("ClientID", details, Catalog);
      this.addCellValue("ClientAddress", details, Catalog);
      this.addCellValue("PONumber", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue(details.DiscValue ? "DiscValue" : "DiscPercent",
        details, Catalog
      );
      this.addCellStatus(details.StatusID, Model.Status);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },
    addDetails_items(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.SalesItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(SlsItemCatalog.DispatchID.Label);
      this.addColumnHeader(SlsItemCatalog.DispatchID);
      this.addColumnHeader(SlsItemCatalog.SKU);
      this.addColumnHeader(SlsItemCatalog.QtySalesOrder);
      this.addColumnHeader(SlsItemCatalog.Packaging);
      this.addColumnHeader(SlsItemCatalog.Qty);
      this.addColumnHeader(SlsItemCatalog.SellPrice);
      this.addColumnHeader(SlsItemCatalog.Total);
      this.addColumnHeader(SlsItemCatalog.IsClosed);

      // value
      for (const row of items) {
        Model.updateItemReadOnly(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, SlsItemCatalog);
        this.addCellRelatedValue("DispatchID", row, SlsItemCatalog);
        this.addCellValue("SKU", row, SlsItemCatalog);
        this.addCellValue("QtySalesOrder", row, SlsItemCatalog);
        this.addCellValue("Packaging", row, SlsItemCatalog);
        this.addCellValue("Qty", row, SlsItemCatalog);
        this.addCellValue("SellPrice", row, SlsItemCatalog);
        this.addCellValue("Total", row, SlsItemCatalog);
        this.addCellValue("IsClosed", row, SlsItemCatalog);
      }
    },
    addInvoiceList(invoiceList) {
      this.addInvoiceList_rows(invoiceList);

      this.addSheet(Info.Export.Details.Excel.SheetName.InvoiceList);
    },
    addInvoiceList_rows(invoiceList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(InvCatalog.SONumber);
      this.addColumnHeader_ID(InvCatalog.WarehouseID.Label);
      this.addColumnHeader(InvCatalog.WarehouseID);
      this.addColumnHeader(InvCatalog.InvoiceDate);
      this.addColumnHeader(InvCatalog.PONumber);
      this.addColumnHeader(InvCatalog.Total);
      this.addColumnHeader(InvCatalog.Status);

      // value
      for ( const row of invoiceList ) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("SONumber", row, InvCatalog);
        this.addCellValue("WarehouseID", row, InvCatalog);
        this.addCellRelatedValue("WarehouseID", row, InvCatalog);
        this.addCellValue("InvoiceDate", row, InvCatalog);
        this.addCellValue("PONumber", row, InvCatalog);
        this.addCellValue("Total", row, InvCatalog);
        this.addCellStatus(row.Status, InvModel.Status);
      }
    }
  }
}
</script>