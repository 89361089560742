<template>
  <kst-page-print pageFooter
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column">
        <div class="is-size-3 mb-4 has-text-centered">{{ title }}</div>
        <div class="label">{{ Catalog.WarehouseID.Label }} :</div>
      </div>
    </div>

    <Items class="block" :items="items"/>
  </kst-page-print>
</template>

<script>
import Catalog from "../StopCatalog.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import Items from "./StopPI_Items.vue";

export default {
  components: {
    Items
  },
  data: () => ({
    Catalog: Catalog,
    items: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const config = Services.getPrintItemsData();
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.items = data.ItemList;
    },

    /*** custom ***/

    resetData() {
      this.items = [];
    }
  }
}
</script>