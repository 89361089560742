<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";
import SlsItemCatalog from "../SlsItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object,
    searchData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      if (this.userData.WithItemsByClient) {
        this.createSheet();
        this.addSummaryItemListByClient(data.ListByClient);
      }

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.DraftNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.StatusID);
      this.addColumnHeader(Catalog.IsSelfService);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(SlsItemCatalog.DispatchID.Label);
        this.addColumnHeader(SlsItemCatalog.DispatchID);
        this.addColumnHeader(SlsItemCatalog.Packaging);
        this.addColumnHeader(SlsItemCatalog.Qty);
        this.addColumnHeader(SlsItemCatalog.SellPrice);
        this.addColumnHeader(SlsItemCatalog.ItemTotal);
        this.addColumnHeader(SlsItemCatalog.IsClosed);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("DraftNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("ClientAddress", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue(row.DiscValue ? "DiscValue" : "DiscPercent", row,
          Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellStatus(row.StatusID, Model.Status);
        this.addCellStatus(row.IsSelfService, Model.IsSelfService);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, SlsItemCatalog);
          this.addCellRelatedValue("DispatchID", row, SlsItemCatalog);
          this.addCellValue("Packaging", row, SlsItemCatalog);
          this.addCellValue("Qty", row, SlsItemCatalog);
          this.addCellValue("SellPrice", row, SlsItemCatalog);
          this.addCellValue("ItemTotal", row, SlsItemCatalog);
          this.addCellValue("IsClosed", row, SlsItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    },

    addSummaryItemListByClient(dataListByClient) {
      const dataSummary = Model.ExportList.getPivotSummaryObj(dataListByClient);
      const summaryList = dataSummary.SummaryList;
      const clientList = dataSummary.SummaryObj.ClientName;
      const itemList = dataSummary.SummaryObj.DispatchName;
      let totalQtyPerRow, totalQtyPerCol = [];

      // date info
      const startDate = this.$kst.Format.getLongDate(this.searchData.Date[0]);
      const endDate = this.$kst.Format.getLongDate(this.searchData.Date[1]);
      this.addColumnHeader(Catalog.Date.Label);
      this.addRow();
      this.addCell(startDate + " - " + endDate);
      this.addRow();

      // label header
      this.addRow();
      this.addSequenceHeader();
      this.mergeRow(2);
      this.addCell(Catalog.ClientName.ExportListLabel);
      this.mergeRow(2);
      this.addCell(SlsItemCatalog.DispatchName.ExportListLabel);
      this.mergeCol(itemList.length); 
      this.moveColumn(itemList.length - 1);
      this.addCell(Catalog.Total.Label);
      this.mergeRow(2);

      // label header item
      this.addRow();
      this.addCell("");
      this.addCell("");
      for (let dispatchName of itemList) {
        totalQtyPerCol.push(0);
        this.addCell(dispatchName);
      }

      let totalQty = 0, indexSummary = 0, indexItem;

      // value client item
      for (let clientName of clientList) {
        totalQtyPerRow = 0;

        this.addRow();
        this.addSequenceCell();
        this.addCell(clientName);

        for (indexItem = 0; indexItem < itemList.length; indexItem++) {
          if (indexSummary >= summaryList.length) {
            this.addCell(0);
          } else if (
            summaryList[indexSummary].ClientName === clientName && 
            summaryList[indexSummary].DispatchName === itemList[indexItem]
          ) {
            this.addCell(summaryList[indexSummary].TotalQty);
            totalQtyPerRow += summaryList[indexSummary].TotalQty;
            totalQtyPerCol[indexItem] += summaryList[indexSummary].TotalQty;
            indexSummary++;
          } else {
            this.addCell(0);
          }
        }

        // value total per row
        this.addCell(totalQtyPerRow);
        totalQty += totalQtyPerRow;
      }

      // value total per column
      this.addRow();
      this.addCell("");
      this.addCell(Catalog.Total.Label);
      for (indexItem = 0; indexItem < itemList.length; indexItem++) {
        this.addCell(totalQtyPerCol[indexItem]);
      }
      this.addCell(totalQty);

      // value summary total
      this.addRow();
      this.addRow();
      this.addCell("");
      this.addCell(SlsItemCatalog.TotalQty.ExportListLabel);
      this.addCell(totalQty);

      this.addSheet(Info.Export.List.Excel.SheetName.ListByClient);
    }
  }
}
</script>