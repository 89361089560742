import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true,
    Highlight: true
  },
  Quantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  SellPriceNetto: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true,
    Highlight: true
  },
  SpecialPriceID: {
    Label: AppCatalog.Field.Disc.Label,
    SearchLabel: AppModule.SpecialPrice.FullName,
    Type: AppCatalog.Field.ID.Type,
    RelatedValue: "SpecialPriceName",
    RelatedModule: AppModule.SpecialPrice,
    NullText: "Tanpa " + AppCatalog.Field.Disc.Label
  }
}