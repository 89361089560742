import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DebtPayTotal: {
    Label: AppModule.DebtPay.FullName,
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ReceiveDebtTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Debt.Name,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ReceiveDueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  ReceiveTotal: {
    Label: AppModule.Receive.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  RtnReceiveTotal: {
    Label: AppModule.RtnReceive.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  }
}