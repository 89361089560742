<template>
  <kst-print-table input-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="RcvItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog" />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="RcvItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Quantity"
      :label="RcvItemCatalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="RcvItemCatalog"/>
      </kst-value>
    </b-table-column>

    <template v-if="access.Fld_Amount">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="BuyPrice"
        :label="RcvItemCatalog.BuyPrice.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog" />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="ExpenseAmount"
        :label="RcvItemCatalog.ExpenseAmount.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog" />
      </b-table-column>
    </template>
  </kst-print-table>
</template>

<script>
import RcvItemCatalog from "../RcvItemCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    RcvItemCatalog: RcvItemCatalog
  })
}
</script>
