<template>
  <div>
    <kst-output field="Name" :data="details" :catalog="Catalog"/>
    <kst-output field="Alias" :data="details" :catalog="Catalog"/>
    <kst-output field="DaysDueDate" :data="details" :catalog="Catalog"/>
    <kst-output field="Mobile" :data="details" :catalog="Catalog"/>
    <kst-output field="Phone" :data="details" :catalog="Catalog"/>
    <kst-output field="Email" :data="details" :catalog="Catalog"/>
    <kst-output field="Comment" :data="details" :catalog="Catalog"/>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
  </div>
</template>

<script>
import Catalog from "../VenCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>