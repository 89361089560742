<template>
  <kst-page-export
    :catalog="DbcdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithDebtCard"
      :label="Info.Export.Excel.Option.DebtCard"
    />
  </kst-page-export>
</template>

<script>
import DbcdSearchCatalog from "../DbcdSearchCatalog.js";
import Info from "../DbcdInfo.js";
import Model from "../DbcdModel.js";
import Services from "../../../services/Api/DebtCardServices.js";

import Export from "./DbcdEx_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    DbcdSearchCatalog: DbcdSearchCatalog,
    Info: Info,
    Model: Model,
    searchData: {
      TransactionDate: null,
      TypeID: null,
      VendorID: null,
      VendorName: null,
      WarehouseID: null
    },
    userData: {
      WithDebtCard: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExport(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithDebtCard = true;
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        type: this.$kst.Search.getValue(this.searchData.TypeID),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.TransactionDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.TransactionDate[1])
      };
    }
  }
}
</script>