<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :paymentOptions="paymentOptions"
      :warehouseOptions="warehouseOptions"
      @submit="handleDetails_Submit"
    />
    <ReceiveList
      :userData="userData"
      :userReceiveList="userReceiveList"
      @init="handleReceiveList_Init"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../DbpyModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/DebtPayServices.js";

import Details from "./DbpyN_Details.vue";
import ReceiveList from "./DbpyN_RcvList.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    ReceiveList
  },
  data: () => ({
    userData: {},
    userReceiveList: [],
    paymentOptions: {},
    warehouseOptions: {},
    // func
    loadListFunc: null,
    submitFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew, submit) {
      this.submitFunc = submit;
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.paymentOptions = Model.getPaymentOptions(data.PaymentTypeList);
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, null, SearchCatalog.WarehouseID.Label
      );

      Model.New.setDetailsByPaymentList(this.userData, data.PaymentTypeList);
      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      if (this.userData.IsVerified) {
        let data = Model.populateData(this.userData, this.userReceiveList);
        const config = Services.postData(data);
        saveData(config, this.handleSubmit_Success);
      }
      else {
        Model.New.setDetailsByDetailsVerified(this.userData);
        this.userData.IsVerified = true;
        this.loadListFunc();
      }
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userReceiveList = [];
      this.paymentOptions = {};
      this.warehouseOptions = {};
    },

    handleDetails_Submit() {
      this.submitFunc();
    },

    handleReceiveList_Init(loadList) {
      this.loadListFunc = loadList;
    }
  }
}
</script>