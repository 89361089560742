<template>
  <kst-page-print-details page-footer
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column">
        <Details :data="data"/>
      </div>
    </div>

    <Items class="block" :data="data" :items="data.Items"/>
    <Total class="block" :data="data"/>
  </kst-page-print-details>
</template>

<script>
import Model from "../StrdModel.js";
import Services from "../../../services/Api/StockReductionServices.js";

import Details from "./StrdPD_Details.vue";
import Items from "./StrdPD_Items.vue";
import Total from "./StrdPD_Total.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Details,
    Items,
    Total,
    Company
  },
  data: () => ({
    data: {},
    companyData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintDetailsData(id);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.data = data.Details;
      this.companyData = data.CompanyDetails;
      Model.setDetailsBySpecialPrice(this.data, data.SpecialPriceCount);

      for (const item of this.data.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.data = {};
      this.companyData = {};
    }
  }
}
</script>