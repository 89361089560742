<template>
  <div class="block">
    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="ConvNumber"
    />

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :disabled="userItemsRaw.length > 0" :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from "../StcvCatalog.js";

export default {
  props: {
    userData: Object,
    userItemsRaw: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>