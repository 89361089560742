<template>
  <kst-action v-if="row.DispatchID === Model.NewItem.ItemID">
    <kst-action-item>
      <kst-button action-mode full-label
        :module="Model.Item.Module"
        :moduleType="Model.Item.ModuleType.New"
        :urlParams="urlParams"
      />
    </kst-action-item>
    <kst-action-item>
      <kst-button action-mode full-label
        :module="Model.Item.Module"
        :moduleType="Model.Item.ModuleType.Select"
        @click="handleSelect_Click(row)"
      />
    </kst-action-item>
  </kst-action>
</template>

<script>
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";

export default {
  props: {
    data: Object,
    row: Object
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [PurItemCatalog.ID.Param]: this.row.ID
      });
    }
  },
  methods: {
    handleSelect_Click(row) {
      this.$emit(this.$kst.Enum.Event.NewItem, row);
    }
  }
}
</script>