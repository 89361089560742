<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="RcvItemCatalog.Name.Label"
    >
      <template v-if="row.PurchasingItemID">
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
        />
      </template>
      <template v-else-if="row.DispatchIsActive">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
          :index="index" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.Edit.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="RcvItemCatalog.PackagingName.Label"
    >
      <template v-if="row.PurchasingItemID">
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="RcvItemCatalog"
        />
      </template>
      <template v-else-if="row.DispatchIsActive">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
          :index="index" :options="row.PackagingOptions"
          @input="handlePackaging(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="RcvItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="RcvItemCatalog.Quantity.Label"
    >
      <template v-if="row.DispatchIsActive">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
          :customValidations="row.CustomValidations"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
        />
      </template>
    </b-table-column>

    <template v-if="access.Fld_Amount">
      <b-table-column numeric
        v-slot="{ column, row, index }"
        cell-class="ks-col-price-button"
        field="BuyPrice"
        :label="RcvItemCatalog.BuyPrice.Label"
      >
        <template v-if="row.DispatchIsActive">
          <kst-input error-off field-only :key="row.Key"
            :field="column.field" :data="row" :catalog="RcvItemCatalog"
            :index="index" @input="handleBuyPrice(row)" @error="handleRowErrors"
          >
            <template #right>
              <div class="control" v-show="row.PurchasingItemID === null">
                <kst-tooltip search-mode hover-only
                  position="is-bottom"
                  :content="RcvItemCatalog.BuyPrice.Label"
                >
                  <kst-button search-mode @click="handleSearch_price(index)"/>
                </kst-tooltip>
              </div>
            </template>
          </kst-input>
        </template>
        <template v-else>
          <kst-output box-off field-only :key="row.Key"
            :field="column.field" :data="row" :catalog="RcvItemCatalog"
          />
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="ExpenseAmount"
        :label="RcvItemCatalog.ExpenseAmount.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="RcvItemCatalog"
        />
      </b-table-column>
    </template>

    <template #action="{ row, index }">
      <template v-if="row.ReturnItemQuantity === 0">
        <kst-tooltip delete-mode hover-only :content="Model.ReceiveItem.Module.FullName">
          <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
        </kst-tooltip>
      </template>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import RcvItemCatalog from "../RcvItemCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    access: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    RcvItemCatalog: RcvItemCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItemByPackaging(item);
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleBuyPrice(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.Edit_ConfirmDeleteItem,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },
    handleRowDelete_confirm(index) {
      this.userItems.splice(index, 1);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },

    handleSearch_price(index) {
      this.$emit(this.$kst.Enum.Event.Callback, index);
    }
  }
}
</script>