import AppCatalog from "../services/App/AppCatalog.js";
import _ from "lodash";

export default {
  data: () => ({
    debounceCallback: null,
    debounceDelay: AppCatalog.Search.Debounce.Delay,
    debounceData: null,
    debounceLast: [],
    debounceLastTime: null
  }),
  created() {
    // support debounce
    const _this = this;
    this.handleSearch = _.debounce(
      function () { _this.debounceSearch(); },
      AppCatalog.Search.Debounce.Delay
    );
  },
  methods: {
    initSearch(data, callback) {
      this.debounceLastTime = this.getTimestamp();

      this.debounceData = data;
      this.saveFilter();
      this.debounceCallback = callback;
    },
    debounceSearch() {
      const currentTime = this.getTimestamp();
      const timeDiff = currentTime - this.debounceLastTime;

      if (!this.isFilterChanged()) {
        if (timeDiff <= this.debounceDelay) {
          return;
        }
      }

      this.debounceLastTime = currentTime;

      if (this.debounceCallback) {
        this.debounceCallback();
      }
    },
    getTimestamp() {
      return +new Date();
    },
    isFilterChanged() {
      let isChanged = false;

      for (const key in this.debounceLast) {
        if (this.debounceLast[key] !== this.debounceData[key]) {
          isChanged = true;
          break;
        }
      }

      if (isChanged) {
        this.saveFilter();
      }

      return isChanged;
    },
    saveFilter() {
      for (const key in this.debounceData) {
        this.debounceLast[key] = this.debounceData[key];
      }
    }
  }
}