<template>
  <kst-section collapsed info-mode
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
    :help-expanded="sectionHelpExpanded"
    :help="sectionHelp"
  >
  <template v-if="isCollapsible">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="userData"
      isActiveField="PurchasingIsActive"
      dateField="PurchasingDate"
      numberField="PurchasingID"
    />
  </template>
  </kst-section>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.userData.PurchasingID !== null;
    },
    sectionHelp() {
      return Model.getPurchaseSectionHelp(this.userData);
    },
    sectionHelpExpanded() {
      return Info.Message.Edit_PurchaseFields;
    },
    sectionTitle() {
      return Info.Section.Purchase.Title;
    }
  }
}
</script>