import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./ItemModel.js";
import PackagingCatalog from "./PackagingCatalog.js";

export default {
  Button: {
    ItemVariantNew: {
      Label: AppModuleType.New.Name + " " + AppModule.ItemVariant.FullName
    }
  },
  Dialog: {
    SearchListBarcode: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.ModuleType.SearchList.Class,
      Title: AppModuleType.SearchList.Name + " " +
        AppCatalog.Field.Barcode.Label + " dari " + Model.Module.FullName
    }
  },
  Export: {
    Details: {
      Excel: {
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          RefList: Model.ItemVariant.ModuleType.List.Name + " " +
            Model.ItemVariant.Module.FullName
        },
        // ui
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          RefList: Model.ItemVariant.ModuleType.List.Name + " " +
            Model.ItemVariant.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        FileName: {
          ItemPackaging: AppModuleType.List.FullName + " " + 
            AppModule.Item.FullName + " dengan " + 
            AppModule.ItemPackaging.FullName + AppCatalog.Xlsx.Default.Extension
        },
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Item.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Item.FullName,
          ItemPackaging: "Dengan " + AppModuleType.List.FullName + " " + 
            AppModule.ItemPackaging.FullName
        }
      }
    }
  },
  Message: {
    PrintBarcode_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,

    // packaging
    New_Packaging_ConfirmDelete:
      AppCatalog.Message.ConfirmDelete + " " +
      Model.ItemPackaging.Module.FullName + "?",
    New_Packaging_Default:
      "Tidak diperkenankan mengubah " + PackagingCatalog.Qty.Label + ", " +
      "untuk " + Model.ItemPackaging.Module.FullName + " pertama.",
    New_Packaging_DeleteFirst:
      "Tidak diperkenankan menghapus " + Model.ItemPackaging.Module.FullName + ", " +
      "untuk " + Model.ItemPackaging.Module.FullName + " pertama.",
    New_Packaging_Fields:
      PackagingCatalog.Qty.Label + ", " +
      PackagingCatalog.SellPrice.Label + " dan " +
      PackagingCatalog.Weight.Label + " adalah per " +
      Model.ItemPackaging.Module.FullName + ".",
    Edit_Packaging_ConfirmDelete:
      AppCatalog.Message.ConfirmDelete + " " +
      Model.ItemPackaging.Module.FullName + "?",
    Edit_Packaging_Default:
      "Tidak diperkenankan mengubah " + PackagingCatalog.Name.Label + " dan " +
      PackagingCatalog.Qty.Label + " yang pernah dibuat.",
    Edit_Packaging_DeleteFirst:
      "Tidak diperkenankan menghapus " + Model.ItemPackaging.Module.FullName +
      " yang pernah dibuat.",
    Edit_Packaging_Fields:
      PackagingCatalog.Qty.Label + ", " +
      PackagingCatalog.SellPrice.Label + " dan " +
      PackagingCatalog.Weight.Label + " adalah per " +
      Model.ItemPackaging.Module.FullName + "."
  },
  Placeholder:{
    VariantName: AppCatalog.Info.Example + ": Merah",
    VariantType: AppCatalog.Info.Example + ": Warna"
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    PackagingList: {
      Title: AppModuleType.List.Name + " " + AppModule.ItemPackaging.FullName
    },
    Ref: {
      Title: AppCatalog.Field.Reference.Label + " " + Model.Module.FullName
    },
    Variant: {
      Title: "Keterangan " + AppModule.ItemVariant.FullName
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    RefList: Model.ItemVariant.ModuleType.List.Name + " " +
      Model.ItemVariant.Module.FullName
  }
}