<template>
  <div class="kstmod-invoice-relatedlist">
    <Action :access="access" :salesId="salesId"/>
    <List
      :access="access"
      :detailed="detailed"
      :dataList="dataList"
      :specialPriceCount="specialPriceCount"
      :client="client"
      :date="date"
      :paymenttype="paymenttype"
      :ponumber="ponumber"
      :status="status"
      :warehouse="warehouse"
    />
  </div>
</template>

<script>
import Model from "../InvModel.js";

import Action from "./InvRL_Action.vue";
import List from "./InvRL_List.vue";

export default {
  components: {
    Action,
    List
  },
  props: {
    dataList: Array,
    detailed: Boolean,
    salesId: Number,
    specialPriceCount: Number,
    // field visibility (alphabetical order)
    client: Boolean,
    date: Boolean,
    paymenttype: Boolean,
    ponumber: Boolean,
    status: Boolean,
    warehouse: Boolean
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      if (this.dataList) {
        this.updateList();
      }
    },

    updateList() {
      if (!this.detailed) {
        return;
      }

      for (const row of this.dataList) {
        for (const item of row.Items) {
          Model.updateItem(item);
        }
      }
    }
  }
}
</script>