import App from "../../services/App/App.js";

export default {
  StatusID: {
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label
    },
    Void: {
      ID: 2,
      Class: App.Enum.Status.Void.Class,
      Label: App.Enum.Status.Void.Label
    }
  }
}