<template>
  <kst-page-blank hasTitle 
    @init="handleInit" 
  >
    <List :items="items"/>
  </kst-page-blank>
</template>

<script>
import Services from "../../../services/Api/AutoNumberServices.js";

import List from "./AnumL_List.vue";

export default {
  components: {
    List
  },
  data: () => ({
    items:[],
  }),
  methods: {
    handleInit(loadListData) {
      const config = Services.getListData();
      loadListData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.items = data.List
    },
  }
}
</script>