<template>
  <kst-container class="kst-page-export" :class="theClass">
    <nav class="level mb-0 p-5 ks-border-b-light">
      <div class="level-left">
        <div>
          <kst-title class="m-0">{{ theTitle }}</kst-title>
        </div>
      </div>
      <div class="level-right">
        <template v-if="reloadAreaVisible">
          <kst-button slim-size reload-mode @click="handleReload"/>
        </template>
      </div>
    </nav>

    <div class="kst-page-export__content p-5">
      <kst-skeleton :active="isLoading"/>

      <template v-if="hasAccess !== true">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <kst-section export-mode>
          <slot />
        </kst-section>
      </template>

      <kst-failure init-mode :items="failureInit"/>
      <kst-failure save-mode :items="failure"/>
    </div>

    <template v-if="buttonsAreaVisible">
      <div class="p-5 ks-border-t-light">
        <kst-button action-mode
          class="is-primary"
          :moduleType="moduleType"
          @click="handleExport"
        />
      </div>
    </template>
  </kst-container>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event (as needed)
 * 2. handle Export event and do loadExport
 * 3. handle loadExport success-callback
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin],
  props: {
    catalog: Object,
    params: Array,
    search: Object,
    validate: Function // function
  },
  data: () => ({
    hasAccess: null,
    // loading
    exportLoading: null,
    isLoading: true,
    // area
    buttonsAreaVisible: false,
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    // error
    failure: null,
    failureInit: null
  }),
  computed: {
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(this.$kst.Enum.Event.Init);
        this.init_success();
      }
    },
    init_data() {
      this.hasAccess = null;
      // loading
      this.isLoading = true;
      // area
      this.buttonsAreaVisible = false;
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failure = null;
      this.failureInit = null;
    },
    init_validate() {
      // check: access
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess !== true) {
        this.init_failed();
        return false;
      }

      if (Object.keys(this.$route.query).length === 0) {
        this.failureInit = [AppCatalog.Message.ParamIncomplete];
        this.init_failed();
        return false;
      }

      // populate: url params
      this.populateUrlQuery(this.search, this.params, this.catalog);

      const isValid = this.validate ? this.validate() : true;

      if (!isValid) {
        this.failureInit = [AppCatalog.Message.ParamIncomplete];
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.buttonsAreaVisible = true;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleReload() {
      this.init();
    },

    /*** custom ***/

    handleExport() {
      this.$emit(this.$kst.Enum.Event.Export, this.loadExport);
    },

    loadExport(config, successCallback) {
      this.failure = null;
      this.exportLoading = this.openLoading();

      this.runAxios(config, successCallback,
        this.loadExport_success,
        this.loadExport_fault,
        this.loadExport_fault,
        null, true
      );
    },
    loadExport_End() {
      this.closeLoading(this.exportLoading);
      this.exportLoading = null;
    },
    loadExport_success(data, successCallback) {
      const fileName = this.$kst.Xlsx.getFileName(this.module, this.moduleType);
      successCallback(data, fileName);
      this.loadExport_End();
    },
    loadExport_fault(message) {
      this.failure = message;
      this.loadExport_End();
    }
  }
}
</script>