<template>
  <div class="field is-grouped mt-2">
    <div class="control">
      <kst-output floating-label field-only
        class="ks-col-sku"
        field="SKU" :data="itemData" :catalog="StcdItemCatalog"
      />
    </div>
    <div class="control is-expanded">
      <kst-output floating-label field-only
        :label="StcdItemCatalog.ID.ColumnLabel"
        field="ID" :data="itemData" :catalog="StcdItemCatalog"
      />
    </div>
  </div>
</template>

<script>
import StcdItemCatalog from "../StcdItemCatalog.js";

export default {
  props: {
    itemData: Object
  },
  data: () => ({
    StcdItemCatalog: StcdItemCatalog
  })
}
</script>