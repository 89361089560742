<template>
  <kst-page-list
    :catalog="StcvSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";
import StcvSearchCatalog from "../StcvSearchCatalog.js";

import List from "./StcvL_List.vue";
import Search from "./StcvL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    StcvSearchCatalog: StcvSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, StcvSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.normalizeSearch();
    },
    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>