<template>
  <kst-output control-off field="CreatedBy" :data="data" :catalog="Catalog">
    <template #default>
      <kst-output expanded floating-label
        class="pr-2"
        field="CreatedByID" :data="createdBy" :catalog="Catalog"
      />
    </template>
    <template #right>
      <kst-output addons expanded floating-label
        field="CreatedDate" :data="data" :catalog="Catalog"
      />
    </template>
  </kst-output>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "./Catalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    createdBy() {
      if (this.data.CreatedByID === undefined || this.data.CreatedByID === null) {
        return {
          CreatedByID: this.data.CreatedByID,
          CreatedByName: AppCatalog.Info.System
        };
      }
      else {
        return {
          CreatedByID: this.data.CreatedByID,
          CreatedByName: this.data.CreatedByName
        };
      }
    }
  }
}
</script>