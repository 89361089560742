<template>
  <kst-page-list
    :catalog="ExpSearchCatalog"
    :params="searchParams"
    :resultHelp="resultData.Help"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :paymentTypeOptions="paymentTypeOptions"
        :subCategoryOptions="subCategoryOptions"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
    <template #search-result-buttons-before>
      <kst-button actionMode slimSize
        class="is-primary"
        :label="Info.Button.SummaryList.Title"
        :moduleType="Info.Button.SummaryList.ModuleType"
        @click="handleSummary_click"
      />
    </template>

    <template #search-result-footer>
      <SummaryList
        :summaryObj="summaryObj"
        @init="handleSummaryList_init"
      />
    </template>
  </kst-page-list>
</template>

<script>
import ExpSearchCatalog from "../ExpSearchCatalog.js";
import Info from "../ExpInfo.js";
import Model from "../ExpModel.js";
import Services from "../../../services/Api/ExpenseServices.js";

import List from "./ExpL_List.vue";
import Search from "./ExpL_Search.vue";
import SummaryList from "./ExpL_SummaryList.vue";

export default {
  components: {
    List,
    Search,
    SummaryList
  },
  data: () => ({
    Info: Info,
    Model: Model,
    ExpSearchCatalog: ExpSearchCatalog,
    access: {},
    resultData: {
      Help: null
    },
    searchData: {
      Search: "",
      Date: null,
      PaymentTypeName: null,
      SubCategoryID: null,
      WarehouseID: null
    },
    searchParams: ["Search", "Date", "PaymentTypeName", "SubCategoryID", 
      "WarehouseID"],
    summaryObj: [],
    paymentTypeOptions: {},
    subCategoryOptions: {},
    warehouseOptions: {},
    // func
    showSummaryListFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.summaryObj = Model.getSummary(data.List);
      this.resultData.Help = Model.getResultSectionHelp(data.List);

      this.paymentTypeOptions = Model.getPaymentOptions(
        data.PaymentTypeList,
        ExpSearchCatalog.PaymentTypeName.Label
      );
      this.subCategoryOptions = Model.getExpenseCategoryOptions(
        data.ExpenseSubCatList,
        ExpSearchCatalog.SubCategoryID.Label
      );
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList,
        ExpSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      this.resultData.Help = null;

      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config, this.handleLoad_success);
    },
    handleLoad_success(data) {
      this.summaryObj = Model.getSummary(data.List);
      this.resultData.Help = Model.getResultSectionHelp(data.List);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** by summary modal ***/

    handleSummaryList_init(show) {
      this.showSummaryListFunc = show;
    },

    /*** custom ***/

    handleSummary_click() {
      this.showSummaryListFunc();
    },

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.summaryObj = {};
      this.paymentTypeOptions = {};
      this.subCategoryOptions = {};
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.PaymentTypeName === null) {
        this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      }
      if (this.searchData.SubCategoryID === null) {
        this.searchData.SubCategoryID = this.$kst.Search.OptionAll;
      }
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      this.searchData.SubCategoryID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const paymentType =
        this.$kst.Search.getValue(this.searchData.PaymentTypeName, null) === null
        ? null
        : this.searchData.PaymentTypeName;

      return {
        paymenttype: paymentType,
        subcategory: this.$kst.Search.getValue(this.searchData.SubCategoryID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields,
      };
    }
  }
}
</script>