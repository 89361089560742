export default {
  /**
   * Search item of specified conditions in items (Array of Object)
   * (for Array of primitive-data-type, please use: Array.indexOf() function)
   * @param {Array} items - Array of Object
   * @param {Object} conditions - key-value pairs of conditions
   * - {String} key - key in Object
   * - {any} value - value to search
   * @param {Object} options - options in search
   * - {Boolean} insensitive - true: lower case comparison applied
   *   WARNING!! when use this option, ensure the value and data are in String data-type.
   * - {Boolean} all_insequence - true: get collections until condition is false
   * @returns {Object | Array | null}
   * - when options.all_insequence used, always returns Array
   */
  getItem2(items, conditions, options) {
    if (items === undefined || items === null) {
      return null;
    }

    if (options === undefined) {
      options = {};
    }
    const { all_insequence } = options;

    let resultList = [];

    let item, keyCond, valueCond, isFound;

    for (item of items) {
      isFound = true;

      for (keyCond in conditions) {
        valueCond = conditions[keyCond];

        if (item[keyCond] !== valueCond) {
          isFound = false;
          break;
        }
      }

      if (isFound) {
        if (all_insequence) {
          resultList.push(item);
        }
        else {
          return item;
        }
      }
      else {
        if (isFound) {
          return resultList;
        }
      }
    }

    return all_insequence ? resultList : null;
  },

  /**
   * Search item of specified key in items (Array of Object)
   * (for Array of primitive-data-type, please use: Array.indexOf() function)
   * @param {Array} items - Array of Object
   * @param {String} key - key in Object
   * @param {any} value - value to search
   * @param {Object} options - options in search
   * - {Boolean} insensitive - true: lower case comparison applied
   *   WARNING!! when use this option, ensure the value and data are in String data-type.
   * - {Boolean} all_insequence - true: get collections until condition is false
   * @returns {Object | Array | null}
   * - when options.all_insequence used, always returns Array
   */
  searchItem(items, key, value, options) {
    if (items === undefined || items === null) {
      return null;
    }
    if (value === undefined || value === null) {
      return null;
    }

    if (options === undefined) {
      options = {};
    }
    const { insensitive, all_insequence } = options;
    const newValue = (insensitive ? value.toLowerCase() : value);

    let resultList = [], isFound = false;

    for (const item of items) {
      if (insensitive) {
        if (item[key] !== null) {
          if (item[key].toLowerCase() === newValue) {
            if (all_insequence) {
              isFound = true;
              resultList.push(item);
            }
            else {
              return item;
            }
          }
          else {
            if (isFound) {
              return resultList;
            }
          }
        }
      }
      else {
        if (item[key] === newValue) {
          if (all_insequence) {
            isFound = true;
            resultList.push(item);
          }
          else {
            return item;
          }
        }
        else {
          if (isFound) {
            return resultList;
          }
        }
      }
    }

    return all_insequence ? resultList : null;
  },

  /**
   * Search value of specified key in items (Array of Object)
   * (for Array of primitive-data-type, please use: Array.indexOf() function)
   * @param {Array} items - Array of Object
   * @param {String} key - key in Object
   * @param {any} value - value to search
   * @returns {any | undefined}
   */
  searchValue(items, itemKey, value, valueKey) {
    for (const item of items) {
      if (item[itemKey] === value) {
        return item[valueKey];
      }
    }
  },

  truncate(items) {
    return items.splice(0, items.length);
  }
}