<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="details"
      dateField="Date"
      numberField="ConvNumber"
    />

    <kst-output field="Comment" :data="details" :catalog="Catalog"/>
    <kst-created :data="details"/>
    <kst-output field="WarehouseID" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StcvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>