<template>
  <kst-table action paginated view-mode
    :data="dataList"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="VeniCatalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="VeniCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="VeniCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-barcode"
      field="Barcode"
      :label="VeniCatalog.Barcode.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="VeniCatalog.Packaging.Label"
    >
    <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="VeniCatalog.Qty.Label"
    >
    <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="BuyPrice"
      :label="VeniCatalog.BuyPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Info.Button.VendorItem.Label">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import DialogMixin from "../../../mixins/DialogMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

import Info from "../VeniInfo.js";
import VeniCatalog from "../VeniCatalog.js";

export default {
  mixins: [AxiosMixin, DialogMixin, LoadingMixin, SnackbarMixin],
  props: {
    dataList: Array
  },
  data: () => ({
    Info: Info,
    VeniCatalog: VeniCatalog,
    deleteDataService: null,
    // loading
    deleteLoading: null
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$emit(this.$kst.Enum.Event.Init, this.setService);
    },

    handleRowDelete(index){
      this.showConfirm(
        Info.Message.ConfirmDelete,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },

    handleRowDelete_confirm(index) {
      let data = this.dataList[index];
      if (this.deleteDataService) {
        const config = this.deleteDataService(data.ID);
        this.deleteData(config)
      }
    },

    /*** custom ***/

    deleteData(config) {
      this.deleteLoading = this.openLoading();
      this.runAxios(config, null,
        this.deleteData_Success,
        this.deleteData_Fault,
        this.deleteData_Fault
      );
    },
    deleteData_End() {
      this.closeLoading(this.deleteLoading);
      this.deleteLoading = null;
    },
    deleteData_Success(data) {
      this.$emit(this.$kst.Enum.Event.Delete, data);
      this.deleteData_End();
    },
    deleteData_Fault(message) {
      this.openSnackbar({
        message: message,
        errorType: true
      });
      this.deleteData_End();
    },

    setService(deleteDataService){
      this.deleteDataService = deleteDataService;
    },
  }
}
</script>