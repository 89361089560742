<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../DbpyCatalog.js";
import DbpyRcvCatalog from "../DbpyRcvCatalog.js";
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);
      this.addDetails_ReceiveList(details.ReceiveList);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PayNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.PaymentValue);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.StatusID);

      if (details.StatusID === Model.StatusID.Void.ID) {
        this.addColumnHeader(Catalog.VoidByID);
        this.addColumnHeader(Catalog.VoidDate);
        this.addColumnHeader(Catalog.VoidComment);
      }

      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("PayNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("VendorID", details, Catalog);
      this.addCellValue("VendorAlias", details, Catalog);
      this.addCellRelatedValue("VendorID", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("PaymentTypeName", details, Catalog);
      this.addCellValue("PaymentValue", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellStatus(details.StatusID, Model.StatusID);

      if (details.StatusID === Model.StatusID.Void.ID) {
        this.addCellRelatedValue("VoidByID", details, Catalog);
        this.addCellValue("VoidDate", details, Catalog);
        this.addCellValue("VoidComment", details, Catalog);
      }

      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
    addDetails_ReceiveList(receiveList) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.ReceiveList.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(DbpyRcvCatalog.ReceiveDate);
      this.addColumnHeader_ID(DbpyRcvCatalog.ReceiveID.Label);
      this.addColumnHeader(DbpyRcvCatalog.ReceiveID);
      this.addColumnHeader_ID(DbpyRcvCatalog.VendorID.Label);
      this.addColumnHeader(DbpyRcvCatalog.VendorID);
      this.addColumnHeader_ID(DbpyRcvCatalog.WarehouseID.Label);
      this.addColumnHeader(DbpyRcvCatalog.WarehouseID);
      this.addColumnHeader(DbpyRcvCatalog.PaymentValue);

      // value
      for (const row of receiveList) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ReceiveDate", row, DbpyRcvCatalog);
        this.addCellValue("ReceiveID", row, DbpyRcvCatalog);
        this.addCellRelatedValue("ReceiveID", row, DbpyRcvCatalog);
        this.addCellValue("VendorID", row, DbpyRcvCatalog);
        this.addCellRelatedValue("VendorID", row, DbpyRcvCatalog);
        this.addCellValue("WarehouseID", row, DbpyRcvCatalog);
        this.addCellRelatedValue("WarehouseID", row, DbpyRcvCatalog);
        this.addCellValue("PaymentValue", row, DbpyRcvCatalog);
      }
    }
  }
}
</script>