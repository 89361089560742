<template>
  <kst-section :title="Info.Section.Invoice.Title">
    <Search
      :userData="userData"
      :cashierOptions="cashierOptions"
      :warehouseOptions="warehouseOptions"
      @submit="handleSearch_Submit"
    />

    <kst-load-data activeOnLoad loadingElement @init="handleLoad_Init">
      <List :userInvoiceList="userInvoiceList"/>
    </kst-load-data>
  </kst-section>
</template>

<script>
import Info from "../SlrcInfo.js";
import Model from "../SlrcModel.js";
import Services from "../../../services/Api/SalesRecapServices.js";

import List from "./SlrcN_InvoiceList_List.vue";
import Search from "./SlrcN_InvoiceList_Search.vue";

export default {
  components: {
    List,
    Search
  },
  props: {
    userData: Object,
    userInvoiceList: Array,
    cashierOptions: Object,
    isListLoading: Boolean,
    warehouseOptions: Object
  },
  data: () => ({
    Info: Info,
    // func
    loadData: null
  }),
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.loadInvoiceList);
  },
  methods: {
    /*** by search ***/

    handleSearch_Submit() {
      this.loadInvoiceList();
    },

    /*** by load data ***/

    handleLoad_Init(loadData) {
      this.loadData = loadData;
    },

    /*** custom ***/

    loadInvoiceList() {
      Model.New.setDetailsByReload(this.userData);

      const options = this.getListOptions();
      const config = Services.getRelatedInvoiceList(options);
      this.loadData(config, this.loadList_Success, true);
    },
    loadList_Success(data) {
      Model.New.setInvoiceListByInvoiceList(
        this.userInvoiceList, data.InvoiceList
      );
      Model.setDetailsByPaymentTypeCash(this.userData, data.PaymentTypeCash);
      Model.updateDetails(this.userData, this.userInvoiceList);
    },

    getListOptions() {
      return {
        cashier: this.userData.CashierID,
        date: this.$kst.Out.getDateString(this.userData.Date),
        ispos: this.$kst.Search.getValue(this.userData.InvoiceRecordTypeID),
        warehouse: this.userData.WarehouseID
      };
    }
  }
}
</script>