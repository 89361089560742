import AppCatalog from "../../services/App/AppCatalog.js";
import Catalog from "./SpCatalog.js";
import Model from "./SpModel.js";

export default {
  Dialog: {
    Select: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.ModuleType.Select.Class,
      Title: Model.ModuleType.Select.Name + " " + Model.Module.FullName
    }
  },
  Message: {
    Edit_Name:
      "Tidak diperkenankan mengubah " + Catalog.Name.Label + ".",
  }
}