import App from "../../services/App/App.js";
import FullMenu from "./LayoutMenuFull.js";

export default {
  getList() {
    let result = [];
    let myMenu = App.Session.getModule();
    let fullMenu = FullMenu.getList();
    let isParentAdded;

    for (let i = 0; i < fullMenu.length; i++) {
      if (Object.prototype.hasOwnProperty.call(fullMenu[i], "Items")) {
        isParentAdded = false;
        for (let j = 0; j < fullMenu[i].Items.length; j++) {
          const child = fullMenu[i].Items[j];
          if (myMenu.includes(child.ID)) {
            if (!isParentAdded) {
              let row = Object.assign({}, fullMenu[i]);
              row.Items = [];
              result.push(row);
              isParentAdded = true;
            }
            result[result.length - 1].Items.push(child);
          }
        }
      }
      else {
        if (myMenu.includes(fullMenu[i].ID)) {
          result.push(fullMenu[i]);
        }
      }
    }

    return result;
  }
}