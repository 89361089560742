<template>
  <table class="ks-is-fullwidth">
    <tr>
      <td>
        <kst-value class="print-thermal-size ks-word-break" field="SONumber"
          :data="data" :catalog="Catalog"
        />
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="InvoiceDate"
          :data="data" :catalog="Catalog"
        />
      </td>
    </tr>
    <tr>
      <td>
        <kst-value class="print-thermal-size" field="WarehouseID"
          :data="data" :catalog="Catalog"
        />
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="CreatedByID"
          :data="data" :catalog="Catalog"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "../PosCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>