<template>
  <kst-page-list
    :catalog="PurSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../PurModel.js";
import PurSearchCatalog from "../PurSearchCatalog.js";
import Services from "../../../services/Api/PurchaseServices.js";

import List from "./PurL_List.vue";
import Search from "./PurL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    PurSearchCatalog: PurSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      Status: null,
      Vendor: "",
      VendorID: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      if (this.searchData.VendorID !== null) {
        this.searchData.Vendor = this.$kst.In.getString(data.Search.Vendor);
      }

      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, PurSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.normalizeSearch();
    },
    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.Status = this.$kst.Search.OptionAll;
      this.searchData.Vendor = "";
      this.searchData.VendorID = null;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.Vendor) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        status: this.$kst.Search.getValue(this.searchData.Status),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>