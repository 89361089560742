import App from "../../services/App/App.js";

export default {
  StatusPurchasingIsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.AltClass,
      Label: App.Enum.Status.InActive.AltLabel
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label
    }
  }
}