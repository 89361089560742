<template>
  <kst-table action detailed view-mode
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="DepartureDate"
      :label="Catalog.DepartureDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="LogisticsNumber"
      :label="Catalog.LogisticsNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VehicleIDNumber"
      :label="Catalog.VehicleIDNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Destination"
      :label="Catalog.Destination.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-weight-total has-text-weight-bold"
      field="TotalWeight"
      :label="Catalog.TotalWeight.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-status :value="row.Status" :statusEnum="Model.Status"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DlvCatalog.js";
import Model from "../DlvModel.js";

import Action from "./DlvRL_Row_Action.vue";
import Items from "./DlvRL_Row_Items.vue";

export default {
  components: {
    Action,
    Items
  },
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>