<template>
  <div>
    <span ref="banner">
      <div class="slideshow-image slideshow-banner1"></div>
      <div class="slideshow-image slideshow-banner2"></div>
      <div class="slideshow-image slideshow-banner3"></div>
      <div class="slideshow-image slideshow-banner4"></div>
    </span>
    <div class="bullet-container">
      <span ref="bullet">
        <div class="bullet"></div>
        <div class="bullet"></div>
        <div class="bullet"></div>
        <div class="bullet"></div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    bannerIndex: 0,
    bannerDuration: 10000
  }),
  mounted() {
    this.initializeBanner();
  },
  methods: {
    initializeBanner() {
      setInterval(() => this.changeBanner(), this.bannerDuration);
    },
    changeBanner() {
      if(this.$refs && this.$refs.banner && this.$refs.bullet) {
        const banner = this.$refs.banner;
        const bullet = this.$refs.bullet;
        const children = banner.children;
        const bulletchildren = bullet.children;
        const bannerIdx = this.bannerIndex >= children.length ? 0 : this.bannerIndex;
        let bannerImage;
        for(let i in children) {
          bannerImage = children[i];
          if(bannerImage.style) {
            if(i == bannerIdx) {
              children[i].style.opacity = 1;
              bulletchildren[i].style.background = "#999999";
            } else {
              children[i].style.opacity = 0;
              bulletchildren[i].style.background = "#cccccc";
            }
          }
        }
        this.bannerIndex = bannerIdx + 1;
      }
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>