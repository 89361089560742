<template>
  <kst-table action paginated view-mode
    :data="dataList"
    :selected.sync="userData.selected"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientID"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-ref"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="InvItemCatalog.PackagingName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog">
        ({{ row.PackagingValue }})
      </kst-value>
    </b-table-column>

    <b-table-column numeric sortable
      cell-class="ks-col-price has-text-weight-bold"
      field="SellPrice"
      :label="InvItemCatalog.SellPrice.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <template #action="{ row }">
      <PriceIcon :row="row" :sellPrice="sellPrice"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvCatalog.js";
import InvItemCatalog from "../InvItemCatalog.js";

import PriceIcon from "./InvScLPrice_List_Icon.vue";

export default {
  components: {
    PriceIcon
  },
  props: {
    dataList: Array,
    sellPrice: Number,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    InvItemCatalog: InvItemCatalog
  })
}
</script>