import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.item;

export default {
  deleteData(id) {
    return {
      method: App.Enum.Axios.Method.Delete,
      url: apiEndpoint + "?id=" + id
    };
  },
  getDeleteData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/deletedata?id=" + id
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getExportDetails(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails?id=" + id
    };
  },
  getExportList(params) {
    const attributes = ["search", "field", "withpackaging"];
    const urlParams = App.Out.getParams(params, attributes);
    
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData(params) {
    const attributes = ["purchaseitem", "ref"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata" + urlParams
    };
  },
  getPrintListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    }
  },
  getPrintBarcodeData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printbarcodedata?id=" + id
    };
  },
  getRelatedItemList(params) {
    const attributes = ["search", "field", "excludeid"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/list" + urlParams
    };
  },
  getRelatedPackagingList(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/packaging/list?id=" + id
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  }
}