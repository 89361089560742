<template>
  <kst-section items-mode>
    <kst-table action input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="PurItemCatalog.DispatchID.ColumnLabel"
      >
        <template v-if="row.DispatchID === Model.NewItem.ItemID">
          <kst-output control-off field-only status-off
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else-if="row.DispatchIsActive">
          <kst-output field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else>
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="PurItemCatalog.Packaging.Label"
      >
        <template v-if="row.DispatchID === Model.NewItem.ItemID">
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else-if="row.DispatchIsActive">
          <kst-output field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else>
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Quantity"
        :label="PurItemCatalog.Quantity.Label"
      >
        <template v-if="row.DispatchID === Model.NewItem.ItemID">
          <kst-output field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else-if="row.DispatchIsActive">
          <kst-output field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else>
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
      </b-table-column>

      <template v-if="access.Fld_Amount">
        <b-table-column numeric
          v-slot="{ column, row }"
          cell-class="ks-col-price"
          field="BuyPrice"
          :label="PurItemCatalog.BuyPrice.Label"
        >
          <template v-if="row.DispatchID === Model.NewItem.ItemID">
            <kst-output field-only
              :field="column.field" :data="row" :catalog="PurItemCatalog"
            />
          </template>
          <template v-else-if="row.DispatchIsActive">
            <kst-output field-only
              :field="column.field" :data="row" :catalog="PurItemCatalog"
            />
          </template>
          <template v-else>
            <kst-output box-off field-only
              :field="column.field" :data="row" :catalog="PurItemCatalog"
            />
          </template>
        </b-table-column>

        <b-table-column numeric
          v-slot="{ column, row }"
          cell-class="ks-col-subtotal"
          field="ExpenseAmount"
          :label="PurItemCatalog.ExpenseAmount.Label"
        >
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </b-table-column>
      </template>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-status"
        field="IsClosed"
        :label="PurItemCatalog.IsClosed.Label"
      >
        <kst-item-status :field="column.field" :data="row" :catalog="PurItemCatalog"/>
      </b-table-column>

      <template #action="{ row }">
        <Action :data="data" :row="row" @newitem="handleNewItem"/>
      </template>
    </kst-table>
  </kst-section>
</template>

<script>
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";

import Action from "./PurD_Items_Action.vue";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    data: Object,
    items: Array
  },
  data: () => ({
    PurItemCatalog: PurItemCatalog,
    Model: Model
  }),
  methods: {
    handleNewItem(purchaseItem) {
      this.$emit(this.$kst.Enum.Event.NewItem, purchaseItem);
    }
  }
}
</script>