import App from "../../services/App/App.js";

export default {
  getTransactionGroupObj(transList) {
    let transGroupObj = {}, warehouseIdList = [];
    let warehouseId, transGroup;

    for (const trans of transList) {
      warehouseId = trans.WarehouseID;

      if (!warehouseIdList.includes(warehouseId)) {
        warehouseIdList.push(warehouseId);

        // new warehouse: create group
        transGroup = {
          WarehouseID: trans.WarehouseID,
          WarehouseName: trans.WarehouseName,
          TransactionList: []
        };
        transGroupObj[warehouseId] = transGroup;
      }
      else {
        transGroup = transGroupObj[warehouseId];
      }
      
      trans.ActivityTypeName = App.Data.getActivityTypeName(
        trans.ActivityTypeID
      );
      
      transGroup.TransactionList.push(trans);
    }


    return transGroupObj;
  },
  getTransactionSummaryList(transSummaryList) {
    const activityTypeEnum = App.Enum.ActivityType;
      let resultList = [], result;

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.Invoice.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.Invoice.ID,
        ActivityTypeName: activityTypeEnum.Invoice.Module.FullName,
        ChangesIn: null,
        ChangesOut: result ? result.ChangesOut : 0
      });

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.Receive.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.Receive.ID,
        ActivityTypeName: activityTypeEnum.Receive.Module.FullName,
        ChangesIn: result ? result.ChangesIn : 0,
        ChangesOut: null
      });

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.StockOpname.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.StockOpname.ID,
        ActivityTypeName: activityTypeEnum.StockOpname.Module.FullName,
        ChangesIn: result ? result.ChangesIn : 0,
        ChangesOut: result ? result.ChangesOut : 0
      });

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.RtnInvoice.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.RtnInvoice.ID,
        ActivityTypeName: activityTypeEnum.RtnInvoice.Module.FullName,
        ChangesIn: result ? result.ChangesIn : 0,
        ChangesOut: null
      });

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.StockReduction.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.StockReduction.ID,
        ActivityTypeName: activityTypeEnum.StockReduction.Module.FullName,
        ChangesIn: null,
        ChangesOut: result ? result.ChangesOut : 0
      });

      result = App.Array.searchItem(
        transSummaryList, "ActivityTypeID", activityTypeEnum.RtnReceive.ID
      );
      resultList.push({
        ActivityTypeID: activityTypeEnum.RtnReceive.ID,
        ActivityTypeName: activityTypeEnum.RtnReceive.Module.FullName,
        ChangesIn: null,
        ChangesOut: result ? result.ChangesOut : 0
      });

      return resultList;
  }
}