<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :categoryOptions="categoryOptions"
    />
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../ExpcModel.js";
import Services from "../../../services/Api/ExpenseCategoryServices.js";

import Details from "./ExpcE_Details.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details
  },
  data: () => ({
    userData: {},
    categoryOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.categoryOptions = Model.createCategoryOptions(data.CategoryList);
      Model.Edit.setDetailsByData(this.userData, data.Details);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.categoryOptions = {};
    }
  }
}
</script>