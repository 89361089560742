import App from "../../services/App/App.js";
import HppAdjItemCatalog from "./HpadItemCatalog.js";
import ModelBody from "./HpadModel_Body.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // SKU
    infoList.push(HppAdjItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", item, HppAdjItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(HppAdjItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", item, HppAdjItemCatalog)
    );

    // Packaging
    infoList.push(HppAdjItemCatalog.Packaging.Label + ": " +
      App.Value.getValue("Packaging", item, HppAdjItemCatalog)
    );

    return infoList;
  },

  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },
  setItemByStock(item, stockData) {
    // user input
    item.DispatchID = stockData.ItemID;
    item.HppPerPcs = App.In.getDecimal(stockData.HppPerPcs);
    // defined by system
    item.DispatchName = stockData.Name;
    item.SKU = stockData.SKU;
    item.DispatchSellPrice = stockData.SellPrice;
    item.Stock = stockData.Quantity;
    item.PackagingName = stockData.PackagingName;

    ModelBody.updateItemByPackaging(item);
  }
}