import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import CpD from "./Details/CpD.vue";
import CpE from "./Edit/CpE.vue";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.CompanyProfile),
    Edit: RouterModel.getPath(AppModule.CompanyProfile, AppModuleType.Edit),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.CompanyProfile),
        name: RouterModel.getName(AppModule.CompanyProfile),
        component: CpD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.CompanyProfile),
          module: RouterModel.getModule(AppModule.CompanyProfile),
          moduleattr: AppModuleAttr.Company_Act_Details,
          moduletype: AppModuleType.Details,
        }
      },
      {
        path: RouterModel.getPath(AppModule.CompanyProfile, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.CompanyProfile, AppModuleType.Edit),
        component: CpE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.CompanyProfile, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.CompanyProfile),
          moduleattr: AppModuleAttr.Company_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.CompanyProfile)
          },
          submit: {
            url: RouterModel.getPath(AppModule.CompanyProfile)
          }
        }
      }
    ];
  }
}