<template>
  <kst-section items-mode>
    <SalesSearchItem
      :items="salesItems"
      @init="handleSalesItem_init"
      @submit="handleSalesItem_submit"
    />
    <StockSearchListDefault sell-price weight
      @init="handleStock_init"
      @loaded="handleStock_loaded"
      @submit="handleStock_submit"
    />
    <InvoiceSearchListPrice
      @init="handlePrice_init"
      @submit="handlePrice_submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <div class="buttons">
          <template v-if="userData.SalesDraftID !== null && 
            userData.SalesDraftIsActive === true">
              <kst-button action-mode
                class="is-primary"
                :label="Model.SalesItem.Label"
                :moduleType="Model.SalesItem.ModuleType.New"
                @click="handleAdd_salesItem"
              />
          </template>

          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.InvoiceItem.Module.FullName"
            :moduleType="Model.InvoiceItem.ModuleType.New"
            @click="handleAdd_item"
          />
        </div>
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <div class="buttons">
            <template v-if="userData.SalesDraftID !== null && 
              userData.SalesDraftIsActive === true">
                <kst-button action-mode
                  class="is-primary"
                  :label="Model.SalesItem.Label"
                  :moduleType="Model.SalesItem.ModuleType.New"
                  @click="handleAdd_salesItem"
                />
            </template>

            <kst-button action-mode
              class="is-primary"
              :label="Model.InvoiceItem.Module.FullName"
              :moduleType="Model.InvoiceItem.ModuleType.New"
              @click="handleAdd_item"
            />
          </div>
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List
        :userData="userData"
        :userItems="userItems"
        :deliveryItemList="deliveryItemList"
        :stockSpecialPriceObj="stockSpecialPriceObj"
        @callback="handleList_callback"
      />

      <kst-input class="is-hidden"
        field="DeliveryMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="block" :items="invalidItems"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import List from "./InvE_Items_List.vue";
import InvoiceSearchListPrice from "../../Invoice/SearchListPrice/InvScLPrice.vue";
import SalesSearchItem from "../../Sales/SearchItem/SlsScI.vue";
import StockSearchListDefault from "../../Stock/SearchListDefault/StkScLDefault.vue";

export default {
  components: {
    List,
    InvoiceSearchListPrice,
    SalesSearchItem,
    StockSearchListDefault
  },
  props: {
    salesData: Object,
    userData: Object,
    userItems: Array,
    deliveryItemList: Array,
    stockObj: Object,
    stockSpecialPriceObj: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    rowIndex: null,
    salesItems: [],
    // func
    showPriceFunc: null,
    showSalesItemFunc: null,
    showStockFunc: null
  }),
  computed: {
    invalidItems() {
      return Model.getInvalidItems(this.userData);
    }
  },
  methods: {
    /*** by price modal ***/

    handlePrice_init(show) {
      this.showPriceFunc = show;
    },
    handlePrice_submit(row) {
      let userItem = this.userItems[this.rowIndex];
      Model.setItemByPrice(userItem, row);
      Model.updateItem(userItem);
    },

    /*** by sales item modal ***/

    handleSalesItem_init(show) {
      this.showSalesItemFunc = show;
    },
    handleSalesItem_submit(salesItemData, supportData) {
      Model.Edit.updateItemsByStockList(this.userItems,
        [supportData.StockDetails], this.stockObj
      );

      let item = Model.createItem(this.userData);
      Model.Edit.setItemBySalesItem(
        item, salesItemData, supportData.StockDetails,
        supportData.StockSpecialPriceList
      );
      Model.updateItem(item);
      this.userItems.push(item);

      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },

    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_loaded(stockList) {
      Model.Edit.updateItemsByStockList(this.userItems, stockList, this.stockObj);
      Model.validateItemsByStock(this.userData, this.userItems);
    },
    handleStock_submit(stockData, itemData) {
      let item = Model.createItem(this.userData);
      Model.setItemByStock(item, stockData, itemData.ItemDetails,
        itemData.StockSpecialPriceList
      );
      Model.Edit.normalizeItem(item, this.stockObj, this.stockSpecialPriceObj);
      Model.updateItem(item);
      this.userItems.push(item);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    },

    handleAdd_salesItem() {
      this.salesItems = Model.getItemsForSelection(
        this.userItems, this.salesData.Items
      );
      this.showSalesItemFunc(Services.getRelatedSalesItemDetails);
    },

    handleList_callback(index) {
      this.rowIndex = index;
      const userItem = this.userItems[index];
      const sellPrice = this.$kst.Out.getInteger(userItem.SellPrice) /
        userItem.PackagingValue;

      this.showPriceFunc(
        // dispatchId, clientId, clientName, sellPrice, packagingList, getListService
        userItem.DispatchID,
        this.userData.ClientID,
        this.userData.Client,
        sellPrice,
        userItem.PackagingOptions.rows,
        Services.getRelatedInvoicePriceList
      );
    }
  }
}
</script>