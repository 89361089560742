<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value print-mode
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value print-mode
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Barcode"
      :label="Catalog.Barcode.Label"
    >
      <kst-value print-mode
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-stock"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ row }"
      field="Name"
      :label="PackagingCatalog.SellPrice.Label"
    >
      <template v-for="packaging in row.PackagingList">
        <kst-value :key="packaging.ID"
          field="SellPrice" :data="packaging" :catalog="PackagingCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      field="Name"
      :label="Catalog.Packaging.Label"
    >
      <template v-for="packaging in row.PackagingList">
        <div :key="packaging.ID">
          {{packaging.Name}} {{"("}} {{packaging.Qty}} {{")"}}
        </div>
      </template>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ row }"
      field="Name"
      :label="PackagingCatalog.Weight.Label"
    >
      <template v-for="packaging in row.PackagingList">
        <kst-value :key="packaging.ID"
          field="Weight" :data="packaging" :catalog="PackagingCatalog"
        />
      </template>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import PackagingCatalog from "../PackagingCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    PackagingCatalog: PackagingCatalog
  })
}
</script>