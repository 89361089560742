import App from "../../services/App/App.js";
import ModelBody from "./StcvModel_Body.js";
import StcvItemCatalog from "./StcvItemCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },
  setItemsByStock(userItems, stockData) {
    let userItem = ModelBody.createItem();
    // user input
    userItem.ItemID = stockData.ItemID;
    userItem.ItemName = stockData.Name;
    // by system
    userItem.SKU = stockData.SKU;
    userItem.StockPcsQty = stockData.Quantity;
    userItem.PackagingName = stockData.PackagingName;
    userItem.PackagingQty = stockData.PackagingQty;
    userItem.SellPrice = stockData.SellPrice;

    ModelBody.updateItem(userItem);
    userItems.push(userItem);
  },

  getItemInfoFinish(userItem) {
    let infoList = [];

    // SKU
    infoList.push(StcvItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, StcvItemCatalog)
    );

    return infoList;
  },
  getItemInfoRaw(userItem) {
    let infoList = [];

    // SKU
    infoList.push(StcvItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, StcvItemCatalog)
    );

    // StockQty
    infoList.push(StcvItemCatalog.StockQty.Label + ": " +
      App.Value.getValue("StockQty", userItem, StcvItemCatalog) + " " +
      userItem.PackagingName
    );

    return infoList;
  },

  updateItemsByStockList(userItemsRaw, stockList) {
    let stock;

    // update stock
    for (const row of userItemsRaw) {
      stock = App.Array.searchItem(stockList, "ItemID", row.ItemID);

      if (stock !== null) {
        row.StockPcsQty = stock.QuantityPcs;
        ModelBody.updateItem(row);
      }
    }
  }
}