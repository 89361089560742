import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import RcvL from "./List/RcvL.vue";
import RcvN from "./New/RcvN.vue";
import RcvD from "./Details/RcvD.vue";
import RcvE from "./Edit/RcvE.vue";
import RcvED from "./EditDate/RcvED.vue";
import RcvExD from "./ExportDetails/RcvExD.vue";
import RcvExL from "./ExportList/RcvExL.vue";
import RcvPD from "./PrintDetails/RcvPD.vue";
import RcvPL from "./PrintList/RcvPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Receive, AppModuleType.Edit),
    EditDate: RouterModel.getPath(AppModule.Receive, AppModuleType.EditDate),
    ExportDetails: RouterModel.getPath(AppModule.Receive, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Receive, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Receive, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Receive, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.Receive, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.Receive, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.Details),
        component: RcvD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.Edit),
        component: RcvE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.EditDate),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.EditDate),
        component: RcvED,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.EditDate),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_EditDate,
          moduletype: AppModuleType.EditDate,
          cancel: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.ExportDetails),
        component: RcvExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.ExportList),
        component: RcvExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.List),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.List),
        component: RcvL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Receive),
            moduleattr: AppModuleAttr.Receive_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Receive),
            moduleattr: AppModuleAttr.Receive_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Receive),
            moduleattr: AppModuleAttr.Receive_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.New),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.New),
        component: RcvN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Receive, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.PrintDetails),
        component: RcvPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Receive, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Receive, AppModuleType.PrintList),
        component: RcvPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Receive, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Receive),
          moduleattr: AppModuleAttr.Receive_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}