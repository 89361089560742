import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import SpadD from "./Details/SpadD.vue";
import SpadExL from "./ExportList/SpadExL.vue";
import SpadL from "./List/SpadL.vue";
import SpadN from "./New/SpadN.vue";
import SpadPD from "./PrintDetails/SpadPD.vue";
import SpadPL from "./PrintList/SpadPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List),
    New: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.New),
    PrintDetail: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.Details),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.Details),
        component: SpadD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.ExportList),
        component: SpadExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.List),
        component: SpadL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.List),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.SpecialPriceAdj),
            moduleattr: AppModuleAttr.SpecialPriceAdj_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new:{
            module: RouterModel.getModule(AppModule.SpecialPriceAdj),
            moduleattr: AppModuleAttr.SpecialPriceAdj_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.SpecialPriceAdj),
            moduleattr: AppModuleAttr.SpecialPriceAdj_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.New),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.New),
        component: SpadN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.New),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
        component: SpadPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.SpecialPriceAdj, AppModuleType.PrintList),
        component: SpadPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SpecialPriceAdj, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.SpecialPriceAdj),
          moduleattr: AppModuleAttr.SpecialPriceAdj_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}