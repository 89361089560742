<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="details"
      dateField="DepartureDate"
      numberField="LogisticsNumber"
    />

    <kst-output field="ClientID" :data="details" :catalog="Catalog">
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="details" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-output addons expanded floating-label
          field="Client" :data="details" :catalog="Catalog"
        />
      </template>
    </kst-output>

    <kst-output field="VehicleIDNumber" :data="details" :catalog="Catalog"/>
    <kst-output field="Destination" :data="details" :catalog="Catalog"/>
    <kst-output field="ShipmentFee" :data="details" :catalog="Catalog"/>
    <kst-output field="ArrivalDate" :data="details" :catalog="Catalog"/>
    <kst-output field="CompletedDate" :data="details" :catalog="Catalog"/>
    <kst-output field="Comment" :data="details" :catalog="Catalog"/>
    <kst-output field="Status" :data="details" :catalog="Catalog"/>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
    <kst-output field="WarehouseID" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../DlvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>