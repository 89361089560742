import App from "../../services/App/App.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  /*** method ***/

  setDetailsByData(userData, details, detailsSetting) {
    userData.ID = details.ID;
    // user input
    userData.name = App.In.getString(details.name);
    userData.address = App.In.getString(details.address);
    userData.phone = App.In.getString(details.phone);
    userData.email = App.In.getString(details.email);
    userData.owner = App.In.getString(details.owner);
    userData.ImageIsLocal = false;
    userData.ImageSrc = App.In.getString(details.ImageSrc);
    userData.Image64 = App.In.getString(details.Image64);
    // user input - setting
    userData.DebtDueReminderDays = App.In.getInteger(detailsSetting.DebtDueReminderDays);
    userData.DebtEmailSentTime = App.In.getTime(detailsSetting.DebtEmailSentTime);
    userData.InvoiceClientSourceID = detailsSetting.InvoiceClientSourceID;
    userData.InvoicePrintNote = App.In.getString(detailsSetting.InvoicePrintNote);
    userData.ProfitLossRptTypeID = detailsSetting.ProfitLossRptTypeID;
    userData.RecDueReminderDays = App.In.getInteger(detailsSetting.RecDueReminderDays);
    userData.ReceiveVendorSourceID = detailsSetting.ReceiveVendorSourceID;
    userData.RecEmailSentTime = App.In.getTime(detailsSetting.RecEmailSentTime);
  },

  setDetailsByWarehouseList(userData, warehouseList) {
    userData.SelfWarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.SelfWarehouseID
    );
  }
}