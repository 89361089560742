<template>
  <kst-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="ID"
      :label="Catalog.ID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyStock"
      cell-class="ks-col-qty"
      :label="Catalog.QtyStock.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyReceive"
      cell-class="ks-col-qty"
      :label="Catalog.QtyReceive.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="!isAllWarehouse">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        field="QtyStockTrf"
        cell-class="ks-col-qty"
        :label="Catalog.QtyStockTrf.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyStockOpname"
      cell-class="ks-col-qty"
      :label="Catalog.QtyStockOpname.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyAdjustment"
      cell-class="ks-col-qty"
      :label="Catalog.QtyAdjustment.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyStockConv"
      cell-class="ks-col-qty"
      :label="Catalog.QtyStockConv.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyInvoice"
      cell-class="ks-col-qty"
      :label="Catalog.QtyInvoice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="QtyLast"
      cell-class="ks-col-qty"
      :label="Catalog.QtyLast.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="!isAllWarehouse">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        field="QtyStockClosing"
        cell-class="ks-col-qty"
        :label="Catalog.QtyStockClosing.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #footer v-if="dataList.length > 0">
      <th class="is-hidden-mobile"/>
      <th class="is-hidden-mobile">
        <div class="th-wrap">{{ Info.Summary.Total }}</div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyStock" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyReceive" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <template v-if="!isAllWarehouse">
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">
            <kst-value field="QtyStockTrf" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
          </div>
        </th>
      </template>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyStockOpname" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyAdjustment" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyStockConv" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyInvoice" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <th class="is-hidden-mobile">
        <div class="th-wrap is-numeric">
          <kst-value field="QtyLast" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
        </div>
      </th>
      <template v-if="!isAllWarehouse">
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">
            <kst-value field="QtyStockClosing" :data="dataSummary" :catalog="DaiSummaryCatalog"/>
          </div>
        </th>
      </template>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DaiItemCatalog.js";
import DaiSummaryCatalog from "../DaiSummaryCatalog.js";
import Info from "../DaiInfo.js";

export default {
  props: {
    dataList: Array,
    dataSummary: Object,
    searchData: Object
  },
  data: () => ({
    Catalog: Catalog,
    DaiSummaryCatalog: DaiSummaryCatalog,
    Info: Info,
    isAllWarehouse: false
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isAllWarehouse =
        this.$kst.Search.getValue(this.searchData.WarehouseID, null) === null;
    }
  }
}
</script>