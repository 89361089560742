import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InvCatalog from "../Invoice/InvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientID: {
    Label: AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  InvoiceID: {
    Label: AppModule.Invoice.FullName,
    ColumnLabel: InvCatalog.SONumber.Label,
    ExportLabel: InvCatalog.SONumber.Label,
    PrintLabel: InvCatalog.SONumber.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "InvoiceNumber",
    RelatedModule: AppModule.Invoice
  },
  InvoiceDate: {
    Label: InvCatalog.InvoiceDate.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  InvoiceNumber: {
    Label: InvCatalog.SONumber.Label,
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MaxValue: "InvoiceBalance",
    MinValue: AppCatalog.Field.PaymentValue.MinValue
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName + " " + AppModule.Invoice.FullName,
    ColumnLabel: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName +
      " " + AppModule.Invoice.FullName,
    PrintLabel: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  InvoiceBalance: {
    Label: AppCatalog.Field.Balance.Label + " " + AppModule.Rec.FullName,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  InvoiceDueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  InvoiceStatus: {
    Label: InvCatalog.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  InvClientID: {
    Label: AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "InvClientName",
    RelatedModule: AppModule.Client
  },
  InvPaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MaxValue: "InvoiceBalance",
    MinValue: AppCatalog.Field.PaymentValue.MinValue
  },
  InvWarehouseID: {
    Label: AppModule.Warehouse.FullName + " " + AppModule.Invoice.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName +
      " " + AppModule.Invoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "InvWarehouseName",
    RelatedModule: AppModule.Warehouse,
  }
}