<template>
  <kst-section items-mode>
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="SlsItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SlsItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="SlsItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SlsItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="SlsItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SlsItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="SlsItemCatalog.SellPrice.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SlsItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="Total"
        :label="SlsItemCatalog.Total.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="SlsItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-status"
        field="IsClosed"
        :label="SlsItemCatalog.IsClosed.Label"
      >
        <kst-item-status :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Model from "../SlsModel.js";
import SlsItemCatalog from "../SlsItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Model: Model,
    SlsItemCatalog: SlsItemCatalog
  })
}
</script>