import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.pos;

export default {
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  getPrintThermalData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printthermaldata?id=" + id
    };
  },
  getRelatedBarcodeList(warehouseId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/barcode/list?warehouse=" +
        warehouseId
    };
  },
  getRelatedClientList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client/list" + urlParams
    };
  },
  getRelatedStockSpecialPriceList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stockspecialprice/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  postRelatedVerifySpvPass(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/related/verifyspvpass",
      data: data
    };
  }
}
