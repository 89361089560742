import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RcvCatalog.js";
import CstEnum from "../CompanySettingType/CstEnum.js";
import ItemModel from "../Item/ItemModel.js";
import RcvEnum from "./RcvEnum.js";

export default {
  // required in model
  Module: AppModule.Receive,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    EditDate: AppModuleType.EditDate,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  ReceiveItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      SearchList: AppModuleType.SearchList
    }
  },

  // other
  DebtPay: {
    Module: AppModule.DebtPay,
    ModuleType: {
      List: AppModuleType.List
    }
  },
  Purchase: {
    Module: AppModule.Purchase
  },
  PurchaseItem: {
    Label: AppModule.Item.FullName + " " + AppModule.Purchase.FullName,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Receive_Act_Details,
    Act_Edit: AppModuleAttr.Receive_Act_Edit,
    Act_EditDate: AppModuleAttr.Receive_Act_EditDate,
    Act_ExportDetails: AppModuleAttr.Receive_Act_ExportDetails,
    Act_List: AppModuleAttr.Receive_Act_List,
    Act_New: AppModuleAttr.Receive_Act_New,
    Act_PrintDetails: AppModuleAttr.Receive_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.Receive_Act_PrintList,
    Fld_Amount: AppModuleAttr.Receive_Fld_Amount,
    // related
    DebtPay_Act_List: AppModuleAttr.DebtPay_Act_List,
    RtnReceive_Act_List: AppModuleAttr.RtnReceive_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.EditDate,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.EditDate,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    ListFields: ["ReceiveNumber", "PurchasingNumber"],
    ListParams: ["Search", "Date", "VendorID", "VendorName", "WarehouseID"],
    SearchListFields: ["ReceiveNumber", "VendorName", "PurchasingNumber"]
  },

  StatusPurchasingIsActive: RcvEnum.StatusPurchasingIsActive,

  // related
  ReceiveVendorSourceID: CstEnum.ReceiveVendorSourceID,

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      // user input
      PurchasingID: null,
      WarehouseID: App.Session.getDefaultWarehouseID(),
      ReceiveNumber: "",
      IsAutoNumber: true,
      VendorID: null,
      VendorName: "",
      VendorAddress: "",
      Comment: "",
      DueDate: App.In.getDateToday(),
      PaymentTypeName: null,
      PaymentValue: "",
      // by system
      Date: App.In.getDateToday(),
      PurchasingNumber: "",
      PurchasingDate: null,
      PurchasingIsActive: false,
      VendorAlias: "",
      VendorSourceID: CstEnum.ReceiveVendorSourceID.MasterVendor_FreeText.ID,
      // computed
      Total: 0,
      // support
      CurrentDate: App.In.getDateToday(),
      ItemKeyIndex: 0
    };
  },
  createItem(userData) {
    const result = {
      ID: null,
      PurchasingItemID: null,
      // user input
      DispatchID: null,
      Name: "",
      Quantity: "",
      PackagingName: "",
      BuyPrice: "",
      // by system
      SKU: "",
      PackagingValue: 1,
      DispatchIsActive: true,
      PackagingOptions: null,
      PurchasingItemQuantity: 0,
      ReturnItemQuantity: 0,
      // computed
      Packaging: "",
      TotalQuantity: 0,
      ExpenseAmount: 0,
      // support
      Key: userData.ItemKeyIndex,
      // error
      Errors: [],
      ErrorsColl: {},
      // validation
      CustomValidations: {}
    };

    userData.ItemKeyIndex++;
    return result;
  },

  setDetailsByCompanySetting(userData, companySetting) {
    userData.VendorSourceID = companySetting.ReceiveVendorSourceID;
  },
  setDetailsByVendor(userData, vendorDetails) {
    // user input
    userData.VendorID = vendorDetails.ID;
    userData.VendorName = App.In.getString(vendorDetails.Name);
    userData.VendorAddress = App.In.getString(vendorDetails.Address);
    userData.DueDate = App.Data.addDaysToDate(userData.Date,
      vendorDetails.DaysDueDate);
    // by system
    userData.VendorAlias = vendorDetails.Alias;
  },

  setItemByPrice(userItem, priceData) {
    // user input
    userItem.PackagingName = priceData.PackagingName;
    userItem.BuyPrice = App.In.getInteger(priceData.BuyPrice);
    // by system
    userItem.PackagingValue = priceData.PackagingValue;
  },
  setItemByStock(userItem, itemData) {
    userItem.DispatchID = itemData.ID;
    userItem.Name = App.In.getString(itemData.Name);
    // by system
    userItem.SKU = itemData.SKU;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemData.PackagingList
    );

    if (userItem.PackagingOptions.rows.length > 0) {
      const packaging = userItem.PackagingOptions.rows[0];
      userItem.PackagingName = packaging.Name;
      userItem.PackagingValue = packaging.Qty;
    }
  },
  setItemByVendorItem(userItem, vendorItemData) {
    // user input
    userItem.DispatchID = App.In.getInteger(vendorItemData.ItemID);
    userItem.Name = App.In.getString(vendorItemData.Name);
    userItem.Quantity = App.In.getInteger(vendorItemData.Qty);
    userItem.BuyPrice = App.In.getInteger(vendorItemData.BuyPrice);
    // by system
    userItem.SKU = App.In.getString(vendorItemData.SKU);

    userItem.PackagingName = App.In.getString(vendorItemData.PackagingName);
    userItem.PackagingValue = App.In.getInteger(vendorItemData.PackagingValue);
  },

  getDebtPaySectionHelp(userData) {
    return Catalog.DueDate.Label + ": " +
      App.Value.getValue("DueDate", userData, Catalog) +
      ( userData.PaymentTypeName === App.Search.OptionNone 
        ? ""
        : ", " + Catalog.PaymentTypeName.Label + ": " +
          userData.PaymentTypeName + ", " + Catalog.PaymentValue.Label + ": " +
          App.Value.getValue("PaymentValue", userData, Catalog)
      )
    ;
  },
  getDateDisabled(){
    return App.Session.getBackDateInterval() === 0;
  },
  getPurchaseSectionHelp(data) {
    if (data.PurchasingID !== null && !data.PurchasingIsActive) {
      return Catalog.PurchasingID.Label + ": " + data.PurchasingNumber + 
        " " + AppCatalog.Info.DeletedData;
    }
    
    return data.PurchasingID === null
      ? AppCatalog.Message.Without + " " + AppModule.Purchase.FullName
      : Catalog.PurchasingID.Label + ": " + data.PurchasingNumber + ", " +
        Catalog.PurchasingDate.Label + ": " +
        App.Value.getValue("PurchasingDate", data, Catalog);
  },

  getReceiveSectionHelp(data) {
    return Catalog.ReceiveNumber.Label + ": " +
      App.Value.getValue("ReceiveNumber", data, Catalog) + ", " +
      Catalog.Date.Label + ": " +
      App.Value.getValue("Date", data, Catalog);
  },

  clearDetailsByVendor(userData) {
    userData.VendorID = null;
    userData.VendorName = "";
    userData.VendorAddress = "";
    userData.DueDate = App.In.getDate(userData.Date);
    // by system
    userData.VendorAlias = "";
  },

  updateDetails(userData, userItems) {
    let total = 0;

    for (const item of userItems) {
      total += item.ExpenseAmount;
    }

    userData.Total = total;
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.TotalQuantity = App.Data.getTotalQty(
      userItem.Quantity, userItem.PackagingValue
    );
    userItem.ExpenseAmount = App.Data.getTotal(
      userItem.Quantity, userItem.BuyPrice
    );
  },
  updateItemByPackaging(item) {
    if (item.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        item.PackagingOptions.rows, item.PackagingName
      );

      if (packagingData) {
        // defined by system
        item.PackagingValue = packagingData.Qty;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      PurchasingID: userData.PurchasingID,
      WarehouseID: userData.WarehouseID,
      ReceiveNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ReceiveNumber),
      Date: App.Out.getDateString(userData.Date),
      VendorID: userData.VendorID,
      VendorName: App.Out.getString(userData.VendorName),
      VendorAddress: App.Out.getString(userData.VendorAddress),
      Comment: App.Out.getString(userData.Comment),
      DueDate: App.Out.getDateString(userData.DueDate),
      PaymentTypeName: userData.PaymentTypeName === App.Search.OptionNone
        ? null : userData.PaymentTypeName,
      PaymentValue: userData.PaymentTypeName === App.Search.OptionNone
        ? null : App.Out.getInteger(userData.PaymentValue),
      PurchasingIsActive: App.Out.getBoolean(userData.PurchasingIsActive),
      // support
      CurrentDate: App.Out.getDateString(userData.CurrentDate)
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        ID: userItem.ID,
        PurchasingItemID: userItem.PurchasingItemID,
        DispatchID: userItem.DispatchID,
        Name: App.Out.getString(userItem.Name),
        Quantity: App.Out.getInteger(userItem.Quantity),
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        BuyPrice: App.Out.getInteger(userItem.BuyPrice)
      });
    }

    return resultItems;
  }
}