import App from "../../services/App/App";

export default {
  /*** method ***/

  setDetailsByEmailList(details) {
    details.Setting.DebtEmail = this.setEmailList(details.Setting.DebtEmail);
    details.Setting.LowStockEmail = this.setEmailList(details.Setting.LowStockEmail);
    details.Setting.RecEmail = this.setEmailList(details.Setting.RecEmail);
  },

  setEmailList(emailList) {
    let newDetails, newEmailList = [];

    if (emailList && emailList.length > 0) {
      for (const row of emailList) {
        newDetails = { Email: "" };

        newDetails.Email = App.In.getString(row.Email);
        newEmailList.push(newDetails);
      }
    } else {
      newDetails = { Email: "" };
      newEmailList.push(newDetails);
    }

    return newEmailList;
  }
}