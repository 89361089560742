<template>
  <kst-container class="kst-page-blank" :class="theClass">
    <nav class="level mb-0 p-5 ks-border-b-light" v-if="hasTitle">
      <div class="level-left">
        <kst-title class="m-0">{{ theTitle }}</kst-title>
      </div>
    </nav>

    <div class="kst-page-blank__content p-5">
      <kst-skeleton :active="isLoading"/>
      <kst-failure init-mode :items="failureInit"/>

      <template v-if="hasAccess === false">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <slot />
      </template>
    </div>
  </kst-container>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadData, set and do reload (as needed)
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  props: {
    hasTitle: Boolean 
  },
  data: () => ({
    hasAccess: null,
    // loading
    isLoading: true,
    // slot
    defaultSlotVisible: false,
    // error
    failureInit: null
  }),
  computed: {
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(this.$kst.Enum.Event.Init,
          this.loadData,
          this.reload
        );
      }
    },
    init_data() {
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);
      // loading
      this.isLoading = true;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failureInit = null;
    },
    init_validate() {
      // check: access
      if (this.hasAccess === false) {
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.defaultSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
    },

    /*** custom ***/

    reload() {
      this.init();
    },

    loadData(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadData_success,
        this.loadData_fault,
        this.loadData_fault,
        null, true
      );
    },
    loadData_success(data, successCallback) {
      successCallback(data);
      this.init_success();
    },
    loadData_fault(message) {
      this.failureInit = message;
      this.init_failed();
    }
  }
}
</script>