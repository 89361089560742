import App from "../../services/App/App.js";
import ModelPackaging from "./ItemModel_Packaging.js";
import ModelVariant from "./ItemModel_Variant.js";

export default {
  setDetailsByNewPurchasingItem(userData, userPackagingList, userVariantList,
    purchasingItemDetails
  ) {
    this.setDetailsByNewPurchasingItem_newPurchasingItem(
      userData, purchasingItemDetails
    );

    // packaging
    App.Array.truncate(userPackagingList);
    userPackagingList.push(ModelPackaging.createDetails(userData, true));
    userData.DefaultPackagingID = userPackagingList[0].ID;

    // variant
    App.Array.truncate(userVariantList);
    userVariantList.push(ModelVariant.createDetails());
    userVariantList.push(ModelVariant.createDetails());
  },
  setDetailsByNewPurchasingItem_newPurchasingItem(userData,
    purchasingItemDetails
  ) {
    userData.Name = purchasingItemDetails.Name;
  },
  setDetailsByData_NewRef(userData, userPackagingList, userVariantList,
    refDetails
  ) {
    userData.RefID = refDetails.ID;
    userData.TypeID = refDetails.TypeID;
    userData.Name = App.In.getString(refDetails.Name);
    userData.SKU = App.In.getString(refDetails.SKU);
    userData.Barcode = App.In.getString(refDetails.Barcode);
    userData.Comment = App.In.getString(refDetails.Comment);
    // support
    userData.RefName = refDetails.Name;

    ModelPackaging.setList_NewRef(userPackagingList, userData, refDetails);
    ModelVariant.setList_NewRef(userVariantList, refDetails);
  }
}