<template>
  <kst-table checkable view-mode
    :checkedRows.sync="userData.checkedRows"
    :data="paymentTypeList"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../PaytCatalog.js";

export default {
  props: {
    userData: Object,
    paymentTypeList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>