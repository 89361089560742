import App from "../../services/App/App.js";

export default {
  setDetailsByFullReceived(userData) {
    userData.CashReceived = App.In.getInteger(userData.PaymentTypeCash);
  },
  setDetailsByReload(userData) {
    // user input
    userData.CashReceived = "";
    // by system
    userData.PaymentTypeCash = 0;
    userData.Total = 0;
  },
  setInvoiceListByInvoiceList(userInvoiceList, invoiceList) {
    App.Array.truncate(userInvoiceList);

    for (const invoice of invoiceList) {
      userInvoiceList.push(invoice);
    }
  }
}