<template>
  <b-menu class="kst-menu">
    <b-menu-list>
      <template #label>
        <div class="pt-6 pl-4 ks-text-transform">Menu</div>
      </template>
      <template v-for="menu in items">
        <template v-if="menu.Items">
          <template v-if="hasChild(menu)">
            <b-menu-item
              class="kst-menu-item"
              :class="getParentClass(menu.Items)"
              :expanded="getIsExpanded(menu)"
              :key="menu.ID"
            >
              <template slot="label" slot-scope="props">
                <template v-if="getIsExpanded(menu)">
                  <img :src="menu.IconActive"/>
                </template>
                <template v-else>
                  <img :src="menu.Icon"/>
                </template>
                <span class="ml-3">{{ menu.Label }}</span>
                <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-down' : 'menu-up'"/>
              </template>
              <template v-for="child in menu.Items">
                <template v-if="access.includes(child.ID)">
                  <b-menu-item
                    class="kst-menu-item-child"
                    :class="getChildClass(child)"
                    :key="child.ID"
                    tag="router-link"
                    :to="child.Path"
                    @click.native="handleMenu_Click(child)"
                  >
                    <template slot="label">
                      <template v-if="getIsActive(child)">
                        <img :src="child.IconActive"/>
                      </template>
                      <template v-else>
                        <img :src="child.Icon"/>
                      </template>
                      <span class="ml-3">{{ child.Label }}</span>
                    </template>
                  </b-menu-item>
                </template>
              </template>
            </b-menu-item>
          </template>
        </template>
        <template v-else>
          <template v-if="access.includes(menu.ID)">
            <b-menu-item
              class="kst-menu-item-dashboard"
              :class="getChildClass(menu)"
              :active="getIsActive(menu)"
              :key="menu.ID"
              tag="router-link"
              :to="menu.Path"
              :target="getTarget(menu.Path)"
              @click.native="handleMenu_Click(menu)"
            >
              <template slot="label">
                <template>
                  <img :src="menu.Icon"/>
                </template>
                <span class="ml-3">{{ menu.Label }}</span>
              </template>
            </b-menu-item>
          </template>
        </template>
      </template>
    </b-menu-list>
  </b-menu>
</template>

<script>
import AppModule from "@/services/App/AppModule.js";
import RouterModel from "@/router/RouterModel.js";

export default {
  props: {
    /**
     * keys:
     *  *ID: Number, String
     *  *Label: String
     *  IsActive: Boolean
     *  Items: Array
     **/
    items: Array
  },
  computed: {
    access() {
      return this.$kst.Session.getModule();
    }
  },
  methods: {
    handleMenu_Click(menu) {
      this.$emit(this.$kst.Enum.Event.Submit, menu);
    },

    getTarget(menuPath) {
      if (menuPath === RouterModel.getPath(AppModule.Pos)) {
        return "_blank";
      }

      return undefined;
    },

    getIsActive(menu) {
      const path = menu.Path;
      return this.$route.path.split("/")[1] === path.split("/")[1];
    },
    getParentClass(childList) {
      for (const child of childList) {
        if (this.getIsActive(child)) {
          return "k-is-active";
        }
      }
      
      return null;
    },
    getChildClass(child) {
      if (this.getIsActive(child)) {
        return "k-is-active";
      }
      
      return null;
    },
    getIsExpanded(menu) {
      for (const child of menu.Items) {
        if (this.$route.path.split("/")[1] === child.Path.split("/")[1]) {
          return true;
        }
      }

      return false;
    },
    hasChild(menu) {
      let count = 0;

      for (const child of menu.Items) {
        if (this.access.includes(child.ID)) {
          count++;
        }
      }

      return count > 0;
    }
  }
}
</script>