<template>
  <kst-table view-mode
    :action="Model.RelatedList.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PayNumber"
      :label="Catalog.PayNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="Catalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="InvPaymentValue"
      :label="RcpyInvCatalog.InvPaymentValue.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="StatusID"
      :label="Catalog.StatusID.Label"
    >
      <kst-status :value="row[column.field]" :statusEnum="Model.StatusID"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.RelatedList.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../RcpyCatalog.js";
import Model from "../RcpyModel.js";
import RcpyInvCatalog from "../RcpyInvCatalog.js";

export default {
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    RcpyInvCatalog: RcpyInvCatalog,
    Model: Model
  })
}
</script>