<template>
  <b-modal aria-modal has-modal-card trap-focus
    aria-role="dialog"
    class="kst-modal-select"
    :active="isActive"
    @close="handleCancel"
  >
    <div class="modal-card ks-max-width-100p mb-5" :style="'width: ' + width + 'px'">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" @click="handleCancel"/>
      </header>

      <section class="modal-card-body">
        <kst-section search-result-mode select-one-help>
          <slot
            name="search-result"
            :userData="userData"
          />
        </kst-section>
      </section>

      <footer class="modal-card-foot is-justify-content-end">
        <div class="buttons">
          <kst-button close-mode @click="handleCancel"/>
          <kst-button confirm-mode @click="handleConfirm"/>
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 3. handle Submit event and do loadRelated when needed
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  props: {
    title: String,
    width: Number
  },
  data: () => ({
    isActive: false,
    userData: {
      selected: null
    },
    // loading
    detailsLoading: null
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init, this.show, this.hide);
    },
    init_Data() {
      this.isActive = false;
      this.userData.selected = null;
      // loading
      this.detailsLoading = null;
    },

    handleCancel() {
      this.isActive = false;
    },
    handleConfirm() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.$emit(this.$kst.Enum.Event.Submit,
        this.userData.selected,
        this.loadRelated
      );
    },

    loadRelated(config, successCallback) {
      this.detailsLoading = this.openLoading();
      this.runAxios(config, successCallback,
        this.loadRelated_Success,
        this.loadRelated_Fault,
        this.loadRelated_Fault
      );
    },
    loadRelated_End() {
      this.closeLoading(this.detailsLoading);
      this.detailsLoading = null;
    },
    loadRelated_Success(data, successCallback) {
      successCallback(data);
      this.loadRelated_End();
      this.isActive = false;
    },
    loadRelated_Fault(message) {
      this.openSnackbar({
        message: message,
        errorType: true
      });
      this.loadRelated_End();
    },

    show() {
      this.isActive = true;

      // reset
      this.userData.selected = null;
    },
    hide() {
      this.isActive = false;
    }
  }
}
</script>