import AppCatalog from "../services/App/AppCatalog.js";
import AppModule from "../services/App/AppModule.js";
import RouterMixin_Body from "./RouterMixin_Body.js";

export default {
  data: () => ({
    lastRouterPath: null
  }),
  methods: {
    getUrlParam(paramName, defaultValue) {
      const value = this.$route.query[paramName];

      // param start with text "with"
      //  i.e. withitem, withchart, etc.
      if (paramName.startsWith(this.$kst.Enum.Param.With)) {
        // when no value found, but have default value
        if (value === undefined && defaultValue !== undefined) {
          return defaultValue;
        }

        return this.$kst.In.getBoolean(value);
      }

      switch(paramName) {
        case this.$kst.Enum.Param.ID:
        case this.$kst.Enum.Param.DispatchID:
        case this.$kst.Enum.Param.InvoiceID:
        case this.$kst.Enum.Param.PurchaseID:
        case this.$kst.Enum.Param.PurchaseItemID:
        case AppModule.Receive.Param + AppCatalog.Field.ID.Param:
        case this.$kst.Enum.Param.StatusID:
        case this.$kst.Enum.Param.WarehouseID:
          return this.$kst.Out.getInteger(value);
        default:
          return this.$kst.Out.getString(value);
      }
    },
    getUrlQueryObj(search, fieldList, catalog) {
      let queryObj = {}, isRange, index;

      for (const field of fieldList) {
        isRange = false;

        if (Object.prototype.hasOwnProperty.call(catalog[field], "IsRange")) {
          isRange = catalog[field].IsRange ? true : false;
        }

        if (isRange) {
          for (index = 0; index < search[field].length; index++) {
            RouterMixin_Body.getUrlQuery(
              queryObj, field, search[field], catalog, index
            );
          }
        }
        else {
          RouterMixin_Body.getUrlQuery(
            queryObj, field, search[field], catalog
          );
        }
      }

      return queryObj;
    },
    goBack() {
      this.$router.go(-1);
    },
    goForward() {
      this.$router.go(1);
    },
    redirectTo(path) {
      if (path === this.lastRouterPath) {
        return;
      }

      this.lastRouterPath = path;
      this.$router.push(path);
      setTimeout(this.clearPath(), AppCatalog.RouterMixin.Clear.Delay);
    },
    openTab(path) {
      if (path === this.lastRouterPath) {
        return;
      }

      this.lastRouterPath = path;
      window.open(path);
      setTimeout(this.clearPath(), AppCatalog.RouterMixin.Clear.Delay);
    },
    clearPath() {
      this.lastRouterPath = null;
    },

    /*** within page ***/

    updateUrlQuery(path, search, fieldList, catalog) {
      const queryObj = this.getUrlQueryObj(search, fieldList, catalog);
      this.$router.push({ path: path, query: queryObj});
    },
    populateUrlQuery(search, fieldList, catalog) {
      let isRange, index, isValid;

      for (const field of fieldList) {
        isRange = false;

        if (Object.prototype.hasOwnProperty.call(catalog[field], "IsRange")) {
          isRange = catalog[field].IsRange ? true : false;
        }

        if (isRange) {
          isValid = true;
          index = 0;

          while (isValid) {
            isValid = RouterMixin_Body.setUrlQuery(
              this.$route.query, search, field, catalog, index
            );
            index++;
          }
        }
        else {
          RouterMixin_Body.setUrlQuery(
            this.$route.query, search, field, catalog
          );
        }
      }
    }
  }
}