<template>
  <kst-page-export
    :catalog="StkSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access = "access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
  </kst-page-export>
</template>

<script>
import Info from "../StkInfo.js";
import Model from "../StkModel.js";
import Services from "../../../services/Api/StockServices.js";
import StkSearchCatalog from "../StkSearchCatalog.js";

import Export from "./StkEx_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    StkSearchCatalog: StkSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      WarehouseID: null,
      LastInvoiceDate: null,
      StatusID: Model.Status.Available.ID
    },
    userData: {
      WithList: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExport(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data) {
      const newFileName = this.$kst.Xlsx.getFileName(
        Model.Module, Model.ModuleType.ExportList
      );
      this.exportExcelFunc(data, newFileName);
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData.WithList = true;
    },

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    getListOptions() {
      let result = {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        haslastinvoicedate: this.$kst.Search.getValue(
          this.searchData.LastInvoiceDate),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };

      const statusId = this.$kst.Out.getInteger(this.searchData.StatusID);
      if (statusId === Model.Status.Available.ID) {
        result.hasqty = this.$kst.Out.getBoolean(true);
      }
      else if (statusId === Model.Status.NotAvailable.ID) {
        result.hasnoqty = this.$kst.Out.getBoolean(true);
      }

      return result;
    }
  }
}
</script>