export default {
  StockLabelDetail: {
    Label: "Pergerakan Stok",
    BackgroundColor: "#42b983"
  },
  Stock: {
    Title: "Total Stok Opname",
    TitleSection: "Total Stok Opname",
    Icon: "mdi mdi-36px mdi-poll ",
    IconStyle: "has-text-success ",
    IconBgStyle: "has-background-success-light ",
    Field: "Total",
    Help: {
      Icon: "mdi mdi-calendar-clock",
      Title: "Last 24 Hours"
    }
  },
  Tabs: {
    Details: {
        Label: "Rincian"
    }
  },
}