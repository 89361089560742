<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-output field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input load-on-focus field="DiscPercent" :data="userData" :catalog="Catalog"/>
    <kst-input field="IsActive" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SpCatalog.js";
import Info from "../SpInfo.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.Edit_Name];
    }
  },
}
</script>