<template>
  <kst-page-delete
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details 
      :salesData="salesData" 
      :invoiceList="invoiceList"
      :rtnInvoiceList="rtnInvoiceList"
      :userData="userData"
    />
    <Sales :salesData="salesData"/>
    <Invoice :invoiceList="invoiceList"/>
    <RtnInvoice :rtnInvoiceList="rtnInvoiceList"/>
  </kst-page-delete>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import Details from "./SlsDL_Details.vue";
import Invoice from "./SlsDL_Invoice.vue";
import RtnInvoice from "./SlsDL_RtnInvoice.vue";
import Sales from "./SlsDL_Sales.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Invoice,
    RtnInvoice,
    Sales
  },
  data: () => ({
    invoiceList: [],
    rtnInvoiceList: [],
    salesData: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDelete) {
      this.resetData();

      const config = Services.getDeleteData(id);
      loadDelete(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.salesData = data.Details;

      Model.setDetailsByStatus(this.salesData);

      for (const item of this.salesData.Items) {
        Model.updateItemReadOnly(item);
      }

      this.invoiceList = data.InvoiceList;
      this.rtnInvoiceList = data.ReturnInvoiceList;
    },

    handleSubmit(id, saveData) {
      const config = Services.deleteData(id);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.invoiceList = [];
      this.rtnInvoiceList = [];
      this.salesData = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>