<template>
  <kst-container v-if="hasAccess" class="px-5 py-5 kst-dashboard-summary">
    <kst-skeleton :total="2" :active="isLoading"/>
    <kst-failure init-mode :items="failureInit"/>

    <template v-if="contentAreaVisible">
      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="level is-mobile ks-is-fullwidth">
              <div class="level-left is-align-items-start">
                <div class="level-item mr-5 ks-icon-container-xl"
                  :class="info.Icon.BgStyle"
                >
                  <span class="icon is-medium">
                    <i :class="info.Icon.Icon + info.Icon.Style"/>
                  </span>
                </div>
                <div class="level-item is-block">
                  <div class="has-text-weight-bold mb-3">
                    <div>
                      <kst-value class="title is-5"
                        :field="info.Field.Field" :data="data" :catalog="info.Field.Catalog"
                      />
                    </div>
                  </div>
                  <div class="has-text-weight-bold has-text-grey-light is-size-5">
                    {{ info.Title }}
                  </div>
                  <div class="is-italic" v-if="info.Help">
                    {{ info.Help.Title }} <span class="has-text-weight-semibold has-text-danger">{{ info.Help.Value }}</span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <template v-if="info.Button">
          <div class="level-right">
            <kst-button
              class="is-primary"
              :label="info.Button.Title"
              @click="handleSubmit"
            />
          </div>
        </template>
      </nav>
    </template>
  </kst-container>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "@/mixins/RouterMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadData
 */
export default {
  mixins: [AxiosMixin, RouterMixin],
  props: {
    /**
     * info contains key:
     * - Title
     * - Field: Catalog, Field
     * - Button: Title, Url
     * - Icon: Icon, Style, BgStyle
     * - Help: Title, Class, Value
     */
    info: Object,
    data: Object,
    hasAccess: Boolean
  },
  data: () => ({
    // loading
    isLoading: true,
    // area
    contentAreaVisible: false,
    // error
    failureInit: null
  }),
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_Data();
      if (this.init_Validate()) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadData);
      }
    },
    init_Data() {
      // loading
      this.isLoading = true;
      // area
      this.contentAreaVisible = false;
      // error
      this.failureInit = null;
    },
    init_Validate() {
      // check: access
      if (this.hasAccess !== true) {
        this.init_Failed();
        return false;
      }

      return true;
    },
    init_Success() {
      this.isLoading = false;
      this.contentAreaVisible = true;
    },
    init_Failed() {
      this.isLoading = false;
    },

    /*** custom ***/

    loadData(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadData_Success,
        this.loadData_Fault,
        this.loadData_Fault
      );
    },
    loadData_Success(data, successCallback) {
      successCallback(data);
      this.init_Success();
    },
    loadData_Fault(message) {
      this.failureInit = message;
      this.init_Failed();
    },

    handleSubmit() {
      this.openTab(this.info.Button.Url);
    }
  }
}
</script>