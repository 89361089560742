<template>
  <kst-page-delete
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :itemData="itemData"
      :userData="userData"
    />
    <Item :itemData="itemData"/>
  </kst-page-delete>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import Details from "./ItemDL_Details.vue";
import Item from "./ItemDL_Item.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Item
  },
  data: () => ({
    itemData: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDelete) {
      this.resetData();

      const config = Services.getDeleteData(id);
      loadDelete(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.itemData = data.Details;
    },

    handleSubmit(id, saveData) {
      const config = Services.deleteData(id);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.itemData = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>