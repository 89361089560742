import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.security;

export default {
  postLogin(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/login",
      data: data
    };
  },
  postLogout() {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/logout",
    };
  },
  putRelatedUserIsVerified(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/related/user/isverified",
      data: data
    };
  }
}