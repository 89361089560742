<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Receive :details="details"/>
        <Details :details="details"/>
        <Items :items="details.Items"/>
        <Total :details="details"/>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Info from "../RrcvInfo.js";
import Model from "../RrcvModel.js";
import Services from "../../../services/Api/ReturnReceiveServices.js";

import Details from "./RrcvD_Details.vue";
import Items from "./RrcvD_Items.vue";
import Receive from "./RrcvD_Receive.vue";
import Total from "./RrcvD_Total.vue";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Details,
    Items,
    Receive,
    Total
  },
  data: () => ({
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
      for (const item of this.details.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData(id) {
      const access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.activeTab = 0;

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, access
      );
    }
  }
}
</script>