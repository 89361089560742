<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column sortable 
      v-slot="{ column, row }"
      field="ExpenseNumber"
      :label="Catalog.ExpenseNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable 
      cell-class="ks-col-shortdate"
      v-slot="{ column, row }"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SubCategoryName"
      :label="Catalog.SubCategoryName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable 
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable 
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="Catalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      cell-class="ks-col-price has-text-weight-bold"
      v-slot="{ column, row }"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../ExpCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>