<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData" 
      :accessOptions="accessOptions"
      :warehouseOptions="warehouseOptions"
    />
    <TransactionSetting
      :userData="userData"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Services from "../../../services/Api/UserServices.js";
import Model from "../UserModel.js";

import Details from "./UserN_Details.vue";
import TransactionSetting from "./UserN_TransactionSetting.vue";

export default {
  mixins: [InputPageMixin],
  components:{
    Details,
    TransactionSetting
  },
  data: () => ({
    userData: {},
    accessOptions: {},
    warehouseOptions: {},
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data){
      this.accessOptions = Model.createAccessOptions(data.AccessList);
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.accessOptions = {};
      this.warehouseOptions = {};
    },
  }
}
</script>