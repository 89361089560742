<template>
  <div class="block">
    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="RecapNumber"
      @input="handleInput"
    />
  </div>
</template>

<script>
import Catalog from "../SlrcCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleInput(dateValue) {
      if (dateValue !== null) {
        this.$emit(this.$kst.Enum.Event.Input);
      }
    }
  }
}
</script>