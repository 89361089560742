<template>
  <div class="kst-print-sign">
    <slot name="header">
      <div class="kst-print-sign__header">
        {{ header }}
      </div>
    </slot>
    <slot name="body">
      <div class="kst-print-sign__body"/>
    </slot>
    <slot name="footer">
      <div class="kst-print-sign__footer is-flex">
        <span class="kst-print-sign__bracket">(</span>

        <template v-if="footerName">
          <span class="kst-print-sign__footer-name ks-is-fullwidth has-text-centered">
            {{ footerName }}
          </span>
        </template>
        <template v-else>
          <span class="kst-print-sign__footer-name ks-is-fullwidth has-text-centered ks-border-b-black"/>
        </template>

        <span class="kst-print-sign__bracket has-text-right">)</span>
      </div>

      <slot name="footerTitle">
        <template v-if="footerTitle">
          <div class="mt-2 is-flex ks-border-t-black">
            <span class="kst-print-sign__footer-name ks-is-fullwidth has-text-centered">
              {{ footerTitle }}
            </span>
          </div>
        </template>
      </slot>
    </slot>
  </div>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";

export default {
  mixins: [SlotMixins],
  props: {
    header: {
      type: String,
      required: true
    },
    footerName: String,
    footerTitle: String
  }
}
</script>