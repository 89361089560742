import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Total: {
    Label: AppCatalog.Field.Credit.Label,
    Type: AppCatalog.Field.Credit.Type,
    Output: AppCatalog.Field.Credit.Output,
    ReadOnly: AppCatalog.Field.Credit.ReadOnly
  },

  // Axes
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type
  },
  TotalAxes: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },

  // Threshold
  ValueAvg: {
    Label: AppCatalog.Field.Average.Label,
    Type: AppCatalog.Field.Average.Type,
    Output: AppCatalog.Field.Average.Output
  }
}