<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../VenCatalog.js";
import Info from "../VenInfo.js";
import VenaCatalog from "../VenaCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);
      this.addColumnHeader(Catalog.DaysDueDate);
      this.addColumnHeader(Catalog.OverDueDate);
      this.addColumnHeader(Catalog.DebtTotal);

      if (this.userData.WithAddress) {
        this.addColumnHeader(VenaCatalog.AddressName);
        this.addColumnHeader(VenaCatalog.Address);
        this.addColumnHeader(VenaCatalog.ContactName);
        this.addColumnHeader(VenaCatalog.ContactMobile);
        this.addColumnHeader(VenaCatalog.ContactPhone);
        this.addColumnHeader(VenaCatalog.ContactEmail);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("Alias", row, Catalog);
        this.addCellValue("Mobile", row, Catalog);
        this.addCellValue("Phone", row, Catalog);
        this.addCellValue("Email", row, Catalog);
        this.addCellValue("DaysDueDate", row, Catalog);
        this.addCellValue("OverDueDate", row, Catalog);
        this.addCellValue("DebtTotal", row, Catalog);

        if (this.userData.WithAddress) {
          this.addCellValue("AddressName", row, VenaCatalog);
          this.addCellValue("Address", row, VenaCatalog);
          this.addCellValue("ContactName", row, VenaCatalog);
          this.addCellValue("ContactMobile", row, VenaCatalog);
          this.addCellValue("ContactPhone", row, VenaCatalog);
          this.addCellValue("ContactEmail", row, VenaCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>