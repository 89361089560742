<template>
  <kst-section :title="Info.Section.ItemsRaw.Title">
    <StockSearchListDefault sell-price
      @init="handleStock_init"
      @loaded="handleStock_loaded"
      @submit="handleStock_submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <div class="buttons">
          <kst-button action-mode
            class="is-primary"
            :label="Model.StockConvItem.Module.FullName"
            :moduleType="Model.StockConvItem.ModuleType.New"
            @click="handleAdd_item"
          />
        </div>
      </template>
    </template>

    <template #bottom>
      <div class="ks-is-fullwidth">
        <template v-if="userItemsRaw.length > 0">
          <div class="buttons ks-flex-center">
            <kst-button action-mode
              class="is-primary"
              :label="Model.StockConvItem.Module.FullName"
              :moduleType="Model.StockConvItem.ModuleType.New"
              @click="handleAdd_item"
            />
          </div>
        </template>
        <kst-divider class="is-invisible-mobile" columns-mode/>

        <Total :userData="userData"/>
      </div>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List :userData="userData" :userItemsRaw="userItemsRaw"/>

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="mt-4" :items="userData.InvalidItems_byStock"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../StcvCatalog.js";
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import List from "./StcvN_ItemsRaw_List.vue";
import Total from "./StcvN_ItemsRaw_Total.vue";
import StockSearchListDefault from "../../Stock/SearchListDefault/StkScLDefault.vue";

export default {
  components: {
    List,
    Total,
    StockSearchListDefault
  },
  props: {
    userData: Object,
    userItemsRaw: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    // func
    showStockFunc: null
  }),
  methods: {
    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_loaded(stockList) {
      Model.New.updateItemsByStockList(this.userItemsRaw, stockList);
      Model.validateItemsByStock(this.userData, this.userItemsRaw);
    },
    handleStock_submit(stockDetails, itemData) {
      let item = Model.createItem();
      Model.setItemByStock(item, stockDetails, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItemsRaw.push(item);

      Model.updateDetailsByItemsRaw(this.userData, this.userItemsRaw);
    },

    /*** custom ***/

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    }
  }
}
</script>