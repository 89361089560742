<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      @input="handleDetails_Input"
    />
    <InvoiceList
      :userData="userData" 
      :userInvoiceList="userInvoiceList"
      :cashierOptions="cashierOptions"
      :isListLoading="isListLoading"
      :warehouseOptions="warehouseOptions"
      @init="handleInvoiceList_Init"
    />

    <Total :userData="userData"/>
    <Details2 :userData="userData"/>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../SlrcModel.js";
import Services from "../../../services/Api/SalesRecapServices.js";

import Details from "./SlrcN_Details.vue";
import Details2 from "./SlrcN_Details2.vue";
import InvoiceList from "./SlrcN_InvoiceList.vue";
import Total from "./SlrcN_Total.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Details2,
    InvoiceList,
    Total
  },
  data: () => ({
    userData: {},
    userInvoiceList: [],
    isListLoading: false,
    cashierOptions: {},
    warehouseOptions: {},
    // func
    loadInvoiceListFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const options = {
        cashier: this.userData.CashierID,
        date: this.$kst.Out.getDateString(this.userData.Date),
        ispos: this.$kst.Search.getValue(this.userData.InvoiceRecordTypeID),
        warehouse: this.userData.WarehouseID
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.userData.CashierID = data.Search.CashierID;
      this.userData.WarehouseID = data.Search.WarehouseID;
      Model.New.setInvoiceListByInvoiceList(
        this.userInvoiceList, data.InvoiceList
      );
      Model.setDetailsByPaymentTypeCash(this.userData, data.PaymentTypeCash);
      Model.updateDetails(this.userData, this.userInvoiceList);

      this.cashierOptions = Model.getCashierOptions(data.CashierList);
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userInvoiceList);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** by Details component ***/

    handleDetails_Input() {
      this.loadInvoiceListFunc();
    },

    /*** by InvoiceList component ***/

    handleInvoiceList_Init(loadInvoiceList) {
      this.loadInvoiceListFunc = loadInvoiceList;
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userInvoiceList = [];

      this.resetData_Search();
    },
    resetData_Search() {
      if (this.userData.WarehouseID === null) {
        this.userData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    }
  }
}
</script>