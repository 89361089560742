<template>
  <kst-section items-mode>
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="StopItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="Packaging"
        :label="StopItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="Qty"
        :label="StopItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
        />
      </b-table-column>

      <template v-if="access.Glob_HPP">
        <b-table-column numeric
          v-slot="{ column, row }"
          field="HppTotal"
          :label="StopItemCatalog.HppTotal.Label"
        >
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="StopItemCatalog"
          />
        </b-table-column>
      </template>
    </kst-table>
  </kst-section>
</template>

<script>
import Model from "../StopModel.js";
import StopItemCatalog from "../StopItemCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Model: Model,
    StopItemCatalog: StopItemCatalog
  })
}
</script>