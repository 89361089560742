<template>
  <kst-section items-mode class="mt-5">
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="HpadItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="HpadItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="HpadItemCatalog.Qty.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="HpadItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hppperpcs"
        field="HppPerPcs"
        :label="HpadItemCatalog.HppPerPcs.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="HpadItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="HpadItemCatalog.HppTotal.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="HpadItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import HpadItemCatalog from "../HpadItemCatalog.js";
import Model from "../HpadModel.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    HpadItemCatalog: HpadItemCatalog,
    Model: Model
  })
}
</script>