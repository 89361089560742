<template>
  <kst-section collapsible collapsed
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
  >
    <kst-output field="IsNeedDelivery" :data="details" :catalog="Catalog"/>
    <template v-if="details.IsNeedDelivery">
      <kst-output field="DeliveryDate" :data="details" :catalog="Catalog"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Delivery.Title;
    },
    sectionHelp() {
      return Model.getDeliverySectionHelp(this.details);
    }
  }
}
</script>