<template>
  <div class="block">
    <kst-section confirm-mode>
      <kst-warning class="mb-4" :items="detailsWarning"/>

      <kst-input field="PONumberConfirm" :data="userData" :catalog="Catalog"
        :customValidations="PONumberValidations"
      />
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../PurCatalog.js";
import Model from "../PurModel.js";

export default {
  props: {
    purchaseData: Object,
    receiveList: Array,
    rtnReceiveList: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return Model.Delete.getMessageConfirmationSection(
        this.receiveList, this.rtnReceiveList
      );
    },
    PONumberValidations() {
      return Model.Delete.validationPONumber(this.purchaseData);
    }
  }
}
</script>