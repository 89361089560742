<template>  
  <kst-section :title="sectionTitle">
    <kst-output field="DeliveryAddress" :data="details" :catalog="Catalog"/>
    <kst-output field="DeliveryDate" :data="details" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";

export default {
  props: {
    details: Object,
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Delivery.Title;
    }
  }
}
</script>