import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client,
    Help: "Pilih dari master Pelanggan, atau ketik manual."
  },
  ClientMobile: {
    Label: AppCatalog.Field.MobilePhone.Label,
    Type: AppCatalog.Field.MobilePhone.Type,
    MaxLength: 50,
    NullText: '-'
  },
  ClientName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100,
    ExportListLabel: AppCatalog.Field.Name.Label + " " +
      AppModule.Client.FullName,
    Required: true,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 100
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    Required: true,
    RequiredHide: true
  },
  DeliveryAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppCatalog.Info.Delivery,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  DeliveryDate: {
    Label: AppCatalog.Field.Date.Label + " " + AppCatalog.Info.Delivery,
    Type: AppCatalog.Field.Date.Type,
    NullText: '-'
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    PrintLabel: AppCatalog.Field.DiscPercent.PrintLabel,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  DiscValue: {
    Label: AppCatalog.Field.DiscValue.Label,
    ExportLabel: AppCatalog.Field.DiscValue.ExportLabel,
    PrintLabel: AppCatalog.Field.DiscValue.PrintLabel,
    Type: AppCatalog.Field.DiscValue.Type,
    MaxLength: AppCatalog.Field.DiscValue.MaxLength,
    MinValue: AppCatalog.Field.DiscValue.MinValue,
    MaxValue: "TotalBruto",
    Output: AppCatalog.Field.DiscValue.Output,
    Required: true
  },
  DraftNumber: {
    Label: AppCatalog.Field.Number.Label + " SO",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  DraftNumberConfirm: {
    Label: AppCatalog.Field.Number.Label + " SO",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true,
    Required: true
  },
  IsSelfService: {
    Label: AppCatalog.Field.RecordTypeID.Label,
    Type: AppCatalog.Field.RecordTypeID.Type,
    ReadOnly: true
  },
  PONumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Reference.Type,
    MaxLength: 50,
    Highlight: true
  },
  StatusID: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  TotalBruto: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  TotalDisc: {
    Label: AppCatalog.Field.TotalDisc.Label,
    Type: AppCatalog.Field.TotalDisc.Type,
    ReadOnly: AppCatalog.Field.TotalDisc.ReadOnly,
    Output: AppCatalog.Field.TotalDisc.Output
  },
  TotalItems: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    HelpLabel: "item",
    Type: InputType.Integer,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  ClientAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  DiscType: {
    Label: AppCatalog.Field.DiscType.Label,
    Type: AppCatalog.Field.DiscType.Type
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    Type: AppCatalog.Field.Date.Type
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " SO",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}