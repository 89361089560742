import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, details) {
    userData.ID = details.ID
    userData.RefID = details.RefID;
    userData.TypeID = details.TypeID;
    userData.Name = App.In.getString(details.Name);
    userData.SKU = App.In.getString(details.SKU);
    userData.Barcode = App.In.getString(details.Barcode);
    userData.Comment = App.In.getString(details.Comment);
    userData.ImageIsLocal = false;
    userData.ImageSrc = App.In.getString(details.ImageSrc);
    userData.LimitQty = App.In.getInteger(details.LimitQty);
    userData.DefaultPackagingID = App.In.getInteger(details.DefaultPackagingID);
    userData.RefName = details.RefName;
  }
}