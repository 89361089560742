import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ModuleGroupName: {
    Label: AppModule.ModuleGroup.FullName,
    Type: InputType.Text,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },

  // support
  ModuleName: {
    Label: AppCatalog.Field.Name.Label,
    Type: InputType.Text,
    ReadOnly: true
  }
}