import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./InvCatalog.js";
import InvItemCatalog from "./InvItemCatalog.js";
import ModelBody from "./InvModel_Body.js";
import ItemModel from "../Item/ItemModel.js";
import SalesModel from "../Sales/SlsModel.js";

export default {
  setDetailsByClient(userData, clientData) {
    // user input
    userData.ClientID = clientData.ID;
    userData.Client = App.In.getString(clientData.Name);
    userData.ClientAddress = App.In.getString(clientData.Address);
    userData.DueDate = App.Data.addDaysToDate(userData.InvoiceDate,
      clientData.DaysDueDate);
    // by system
    userData.ClientAlias = clientData.Alias;
  },
  setDetailsByData(userData, invoiceData, discTypeEnum) {
    userData.ID = invoiceData.ID;
    // user input
    userData.SalesDraftID = invoiceData.SalesDraftID;
    userData.WarehouseID = invoiceData.WarehouseID;
    userData.SONumber = invoiceData.SONumber;
    userData.InvoiceDate = App.In.getDate(invoiceData.InvoiceDate);
    userData.IsAutoNumber = false;
    userData.PONumber = App.In.getString(invoiceData.PONumber);
    userData.ClientID = invoiceData.ClientID;
    userData.Client = App.In.getString(invoiceData.Client);
    userData.ClientAddress = App.In.getString(invoiceData.ClientAddress);
    userData.Comment = App.In.getString(invoiceData.Comment);
    userData.DueDate = App.In.getDate(invoiceData.DueDate);
    userData.IsNeedDelivery = App.In.getBoolean(invoiceData.IsNeedDelivery);
    userData.DeliveryDate = App.In.getDate(invoiceData.DeliveryDate);
    // by system
    userData.WarehouseName = invoiceData.WarehouseName;
    userData.ClientAlias = invoiceData.ClientAlias;
    userData.DraftNumber = invoiceData.DraftNumber;
    userData.DraftDate = invoiceData.DraftDate;
    userData.SalesDraftIsActive = App.In.getBoolean(
      invoiceData.SalesDraftIsActive
    );

    if (invoiceData.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
      userData.DiscValue = App.In.getInteger(invoiceData.DiscValue);
    }
    else if (invoiceData.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(invoiceData.DiscPercent);
    }
    else {
      userData.DiscType = discTypeEnum.None.ID;
    }
  },
  setItemsByData(userData, userItems, invoiceData, stockObj,
    stockSpecialPriceObj, salesItems
  ) {
    App.Array.truncate(userItems);

    for (const invoiceItem of invoiceData.Items) {
      let userItem = ModelBody.createItem(userData);
      this.setItemsByData_setItem(userItem, invoiceData, invoiceItem, salesItems);
      this.normalizeItem(userItem, stockObj, stockSpecialPriceObj);
      ModelBody.updateItem(userItem);
      userItems.push(userItem);
    }
  },
  setItemsByData_setItem(userItem, invoiceData, invoiceItem, salesItems) {
    // user input
    userItem.DispatchID = invoiceItem.DispatchID;
    userItem.Name = App.In.getString(invoiceItem.Name);
    userItem.RequestedQuantity = App.In.getInteger(invoiceItem.RequestedQuantity);
    userItem.PackagingName = invoiceItem.PackagingName;
    userItem.SellPrice = App.In.getInteger(invoiceItem.SellPrice);
    // by system
    userItem.SKU = invoiceItem.SKU;
    userItem.PackagingValue = invoiceItem.PackagingValue;
    userItem.StockQty = invoiceItem.StockQuantity;
    userItem.DispatchSellPrice = invoiceItem.DispatchSellPrice;
    userItem.DispatchIsActive = App.In.getBoolean(invoiceItem.DispatchIsActive);

    // sales
    if (invoiceItem.SalesDraftItemID !== null && 
      invoiceData.SalesDraftIsActive === 1
    ) {
      userItem.SalesDraftItemID = invoiceItem.SalesDraftItemID;

      const salesItem = App.Array.searchItem(
        salesItems, "ID", invoiceItem.SalesDraftItemID
      );
      if (salesItem) {
        userItem.SalesDraftItemQty = salesItem.QtyAvailable;
        userItem.CustomValidations = {
          [App.Vee.Rule.MaxValue]: [
            userItem.SalesDraftItemQty,
            InvItemCatalog.SalesDraftItemQty.Label
          ]
        };
      }
    }

    // special price
    if (invoiceItem.SpecialPriceID !== null) {
      userItem.SpecialPriceID = invoiceItem.SpecialPriceID;
      userItem.SpecialPriceName = invoiceItem.SpecialPriceName;
      userItem.SpecialPriceDiscPercent = invoiceItem.SpecialPriceDiscPercent;
    }

    // if: handle deleted-item
    if (!userItem.DispatchIsActive) {
      return;
    }

    // if: item from sales order item cannot change packaging
    if (userItem.SalesDraftItemID === null || 
      invoiceData.SalesDraftIsActive === 0
    ) {
      userItem.PackagingOptions = ItemModel.createPackagingOptions(
        invoiceItem.PackagingList
      );
    }

    // special price options
    userItem.SpecialPriceOptions = ModelBody.createSpecialPriceOptions(
      invoiceItem.StockSpecialPriceList,
      InvItemCatalog.SpecialPriceID.Label
    );

    // stock special price
    if (userItem.SpecialPriceID !== App.Search.OptionNone) {
      const dispatchSpecialPrice = App.Array.searchItem(
        invoiceItem.StockSpecialPriceList, "SpecialPriceID",
        invoiceItem.SpecialPriceID
      );

      if (dispatchSpecialPrice) {
        userItem.SpecialPriceQty = dispatchSpecialPrice.Quantity;
      }
    }
  },
  setItemBySalesItem(userItem, salesItemData, stockDetails,
    stockSpecialPriceList
  ) {
    userItem.SalesDraftItemID = salesItemData.ID;
    userItem.SalesDraftItemQty = salesItemData.QtyAvailable;
    userItem.CustomValidations = {
      [App.Vee.Rule.MaxValue]: [
        userItem.SalesDraftItemQty,
        InvItemCatalog.SalesDraftItemQty.Label
      ]
    };
    // user input
    userItem.DispatchID = salesItemData.DispatchID;
    userItem.Name = App.In.getString(salesItemData.DispatchName);
    userItem.RequestedQuantity = App.In.getInteger(salesItemData.QtyInvoice === null
      ? salesItemData.QtyAvailable : salesItemData.QtyInvoice
    );
    userItem.PackagingName = salesItemData.PackagingName;
    userItem.SellPrice = App.In.getInteger(salesItemData.SellPrice);
    // defined by system
    userItem.SKU = salesItemData.SKU;
    userItem.PackagingValue = salesItemData.PackagingValue;
    userItem.StockQty = stockDetails.Quantity;
    userItem.DispatchSellPrice = salesItemData.DispatchSellPrice;
    userItem.DispatchIsActive = App.In.getBoolean(salesItemData.DispatchIsActive);
    userItem.SpecialPriceOptions = ModelBody.createSpecialPriceOptions(
      stockSpecialPriceList, InvItemCatalog.SpecialPriceID.Label
    );
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(InvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(InvItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, InvItemCatalog)
      );

      // Stock
      infoList.push(InvItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, InvItemCatalog) + " " +
        item.PackagingName
      );

      if (item.SpecialPriceID !== App.Search.OptionNone) {
        // SpecialPriceStock
        infoList.push(InvItemCatalog.SpecialPriceStock.Label + ": " +
          App.Value.getValue("SpecialPriceStock", item, InvItemCatalog) +
          " " + item.PackagingName
        );
      }

      // Sales
      if (item.SalesDraftItemID) {
        infoList.push(AppModule.Item.FullName + " dari " +
          AppModule.Sales.FullName
        );
      }
    }

    return infoList;
  },

  getStockByInvoiceItems(invoiceItems) {
    let stockObj = {};

    for (const item of invoiceItems) {
      if (Object.prototype.hasOwnProperty.call(stockObj, item.DispatchID)) {
        stockObj[item.DispatchID] += item.TotalRequestedQuantity;
      }
      else {
        stockObj[item.DispatchID] = item.TotalRequestedQuantity;
      }
    }

    return stockObj;
  },
  getStockSpecialPriceByInvoiceItems(invoiceItems) {
    let stockSpecialPriceList = {}, key;

    for (let item of invoiceItems) {
      if (item.SpecialPriceID !== null) {

        key = this.getStockSpecialPriceKey(item.DispatchID, item.SpecialPriceID);

        if (Object.prototype.hasOwnProperty.call(stockSpecialPriceList, key)) {
          stockSpecialPriceList[key] += item.TotalRequestedQuantity;
        }
        else {
          stockSpecialPriceList[key] = item.TotalRequestedQuantity;
        }
      }
    }

    return stockSpecialPriceList;
  },
  getStockSpecialPriceKey(dispatchId, specialPriceId) {
    return dispatchId + "#" + specialPriceId;
  },

  clearDetailsByClient(userData) {
    userData.ClientID = null;
    userData.Client = "";
    userData.ClientAddress = "";
    userData.DueDate = App.In.getDate(userData.InvoiceDate);
    // by system
    userData.ClientAlias = "";
  },

  normalizeItem(userItem, stockObj, stockSpecialPriceObj) {
    this.normalizeItem_byStock(userItem, stockObj);
    this.normalizeItem_byStockSpecialPrice(userItem, stockSpecialPriceObj);
  },
  normalizeItem_byStock(userItem, stockObj) {
    // +qty
    if (Object.prototype.hasOwnProperty.call(stockObj, userItem.DispatchID)) {
      userItem.StockQty += stockObj[userItem.DispatchID];
    }
  },
  normalizeItem_byStockSpecialPrice(userItem, stockSpecialPriceObj) {
    // must be run after: normalizeItemByStock
    let key;

    // special price qty
    if (userItem.SpecialPriceID !== App.Search.OptionNone) {
      // +qty
      key = this.getStockSpecialPriceKey(
        userItem.DispatchID, userItem.SpecialPriceID
      );
      if (Object.prototype.hasOwnProperty.call(stockSpecialPriceObj, key)) {
        userItem.SpecialPriceQty += stockSpecialPriceObj[key];
      }

      // normalize: stock special price vs stock
      if (userItem.SpecialPriceQty > userItem.StockQty) {
        userItem.SpecialPriceQty = userItem.StockQty;
      }
    }

    // if: handle deleted-item
    if (!userItem.DispatchIsActive) {
      return;
    }

    // special price options
    for (let specialPriceOption of userItem.SpecialPriceOptions.rows) {
      if (specialPriceOption.SpecialPriceID !== App.Search.OptionNone) {
        key = this.getStockSpecialPriceKey(
          userItem.DispatchID, specialPriceOption.SpecialPriceID
        );

        if (Object.prototype.hasOwnProperty.call(stockSpecialPriceObj, key)) {
          specialPriceOption.Qty = specialPriceOption.Quantity +
            stockSpecialPriceObj[key];
        }
        else {
          specialPriceOption.Qty = specialPriceOption.Quantity;
        }

        // normalize: stock special price vs stock
        if (specialPriceOption.Qty > userItem.StockQty) {
          specialPriceOption.Qty = userItem.StockQty;
        }
      }
    }
  },

  updateItemsByStockList(userItems, stockList, stockObj) {
    // reupdate stock, isActive when user search for items
    for (const item of userItems) {
      // +qty init
      if (!item.DispatchIsActive) {
        if (Object.prototype.hasOwnProperty.call(stockObj, item.DispatchID)) {
          item.StockQty += stockObj[item.DispatchID];
        }
        continue;
      }

      for (const stock of stockList) {
        if (stock.DispatchID === item.DispatchID) {
          item.DispatchIsActive = true;
          item.StockQty = stock.Quantity;

          // +qty
          if (Object.prototype.hasOwnProperty.call(stockObj, item.DispatchID)) {
            item.StockQty += stockObj[item.DispatchID];
          }

          break;
        }
      }

      ModelBody.updateItem(item);
    }
  },

  compareData(userData, userItems, invoiceData, discTypeEnum) {
    const data = ModelBody.populateDetails(userData, discTypeEnum);
    const items = ModelBody.populateItems(userItems);
    let fields;

    // normalize: details
    data.DueDate = App.Compare.getDateTime(data.DueDate);
    data.DeliveryDate = App.Compare.getDateTime(data.DeliveryDate);
    invoiceData.DiscPercent = App.Compare.getDecimal(invoiceData.DiscPercent);
    invoiceData.DueDate = App.Compare.getDateTime(invoiceData.DueDate);
    invoiceData.DeliveryDate = App.Compare.getDateTime(
      invoiceData.DeliveryDate
    );

    /*** compare: details ***/

    // fields: based on ModelBody.populateDetails
    fields = [
      // skip: ID, InvoiceDate, PaymentTypeName, PaymentValue
      "SalesDraftID", "WarehouseID", "SONumber", "PONumber", "ClientID",
      "Client", "ClientAddress", "DiscValue", "DiscPercent", "Comment",
      "DueDate", "IsNeedDelivery", "DeliveryDate"
    ];
    if (!App.Compare.validateObject(data, invoiceData, fields)) {
      return false;
    }

    /*** compare: items ***/

    // fields: based on ModelBody.populateItems
    fields = [
      "SalesDraftItemID", "DispatchID", "Name", "PackagingName",
      "PackagingValue", "RequestedQuantity", "SellPrice", "SpecialPriceID"
    ];
    if (!App.Compare.validateArray(items, invoiceData.Items, fields)) {
      return false;
    }

    return true;
  },

  validationDeliveryDate(data) {
    if (data.IsNeedDelivery) {
      return {
        [App.Vee.Rule.MinDate]: {
          target: data.InvoiceDate,
          label: Catalog.InvoiceDate.Label
        }
      };
    }

    return undefined;
  },
  validationDueDate(userData) {
    return {
      [App.Vee.Rule.MinDate]: {
        target: userData.InvoiceDate,
        label: Catalog.InvoiceDate.Label
      }
    };
  },
  validateItemsByDeliveryItems(userData, userItems, deliveryItemList) {
    // reset indicator
    userData.InvalidItems_vsDelivery = null;
    userData.DeliveryMissingQty = 0;

    for (const deliveryItem of deliveryItemList) {
      deliveryItem.UserQuantity = 0;
    }

    // populate userItems into deliveryItemList
    for (const item of userItems) {
      for (const deliveryItem of deliveryItemList) {
        if (deliveryItem.DispatchID === item.DispatchID &&
          deliveryItem.Name === item.Name &&
          deliveryItem.PackagingName === item.PackagingName
        ) {
          deliveryItem.UserQuantity += App.JS.parseInt(item.RequestedQuantity);
          break;
        }
      }
    }

    // validation
    let invalidItem, invalidItems = [];
    for (const deliveryItem of deliveryItemList) {
      if (deliveryItem.Quantity > deliveryItem.UserQuantity) {
        invalidItem = {
          Name: deliveryItem.Name,
          Packaging: App.Data.getPackaging(
            deliveryItem.PackagingName, deliveryItem.PackagingValue
          ),
          RequestedQuantity: (deliveryItem.Quantity - deliveryItem.UserQuantity)
        };

        invalidItems.push(invalidItem);
      }
    }

    if (invalidItems.length > 0) {
      userData.InvalidItems_vsDelivery = invalidItems;
      userData.DeliveryMissingQty = 1;
    }
  },

  /*** external ***/

  updateSalesItems(salesData, invoiceItems) {
    let newSalesItems = [], isValid, invoiceItem;

    for (let salesItem of salesData.Items) {
      isValid = true;

      salesItem.QtyAvailable = salesItem.Qty - salesItem.QtySalesOrder;

      // +qty
      invoiceItem = App.Array.searchItem(
        invoiceItems, "SalesDraftItemID", salesItem.ID
      );

      if (invoiceItem) {
        salesItem.QtyAvailable += invoiceItem.RequestedQuantity;
        salesItem.QtyInvoice = invoiceItem.RequestedQuantity;
      }
      else {
        salesItem.QtyInvoice = null;

        // closed item
        if (salesItem.IsClosed === 1) {
          isValid = false;
        }
        // qty available
        else if (salesItem.QtyAvailable <= 0) {
          isValid = false;
        }
      }

      // deleted-item
      if (salesItem.DispatchIsActive === 0) {
        isValid = false;
      }

      if (isValid) {
        newSalesItems.push(salesItem);
        SalesModel.updateItem(salesItem);
      }
    }

    salesData.Items = newSalesItems;
  }
}