<template>
  <kst-section :title="Info.Section.ItemsFinish.Title">
    <template #bottom>
      <div class="ks-is-fullwidth">
        <kst-divider class="is-invisible-mobile" columns-mode/>
        <Total :details="details"/>
      </div>
    </template>

    <kst-table input-mode :data="itemsFinish">
      <b-table-column
        v-slot="{ column, row }"
        field="ItemID"
        :label="StcvItemCatalog.ItemID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StcvItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="StcvItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StcvItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="StcvItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StcvItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="StcvItemCatalog.SellPrice.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StcvItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="SubTotal"
        :label="StcvItemCatalog.SubTotal.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StcvItemCatalog"
        />
      </b-table-column>

      <template v-if="access.Glob_HPP">
        <b-table-column numeric
          v-slot="{ column, row }"
          cell-class="ks-col-hpptotal"
          field="HppTotal"
          :label="StcvItemCatalog.HppTotal.Label"
        >
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="StcvItemCatalog"
          />
        </b-table-column>
      </template>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import StcvItemCatalog from "../StcvItemCatalog.js";

import Total from "./StcvD_ItemsFinish_Total.vue";

export default {
  components: {
    Total
  },
  props: {
    access: Object,
    details: Object,
    itemsFinish: Array
  },
  data: () => ({
    Info: Info,
    Model: Model,
    StcvItemCatalog: StcvItemCatalog
  })
}
</script>