import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import RcvItemCatalog from "./RcvItemCatalog.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    } 
    else {
      // SKU
      infoList.push(RcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RcvItemCatalog)
      );
    }

    // Purchase
    if (item.PurchasingItemID) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Purchase.FullName
      );
    }

    return infoList;
  }
}