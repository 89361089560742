<template>
  <kst-section
    :help="Info.Section.ItemsFinish.Help"
    :title="Info.Section.ItemsFinish.Title"
  >
    <StockSearchListDefault sell-price
      @init="handleStock_init"
      @submit="handleStock_submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <template v-if="userItemsFinish.length < Catalog.ItemsFinish.MaxLength">
          <div class="buttons">
            <kst-button action-mode
              class="is-primary"
              :label="Model.StockConvItem.Module.FullName"
              :moduleType="Model.StockConvItem.ModuleType.New"
              @click="handleAdd_item"
            />
          </div>
        </template>
      </template>
    </template>

    <template #bottom>
      <div class="ks-is-fullwidth">
        <kst-divider class="is-invisible-mobile" columns-mode/>
        <Total :userData="userData"/>
      </div>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List :userData="userData" :userItemsFinish="userItemsFinish"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../StcvCatalog.js";
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import List from "./StcvN_ItemsFinish_List.vue";
import Total from "./StcvN_ItemsFinish_Total.vue";
import StockSearchListDefault from "../../Stock/SearchListDefault/StkScLDefault.vue";

export default {
  components: {
    List,
    Total,
    StockSearchListDefault
  },
  props: {
    userData: Object,
    userItemsFinish: Array,
    userItemsRaw: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    // func
    showStockFunc: null
  }),
  methods: {
    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_submit(stockDetails, itemData) {
      let item = Model.createItem();
      Model.setItemByStock(item, stockDetails, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItemsFinish.push(item);

      Model.updateDetailsByItemsFinish(this.userData, this.userItemsFinish);
    },

    /*** custom ***/

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails,
        this.handleAdd_item_validate
      );
    },
    handleAdd_item_validate(stockDetails) {
      for (const row of this.userItemsRaw) {
        if (row.ItemID === stockDetails.ItemID) {
          return Info.Message.New_DuplicatedItem;
        }
      }

      return null;
    }
  }
}
</script>