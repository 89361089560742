export default {
  App: {
    BaseUrl: process.env.VUE_APP_BASE_URL
  },
  Api: process.env.VUE_APP_TOKO_API,

  // external
  WebsiteUrl: {
    Home: process.env.VUE_APP_HOMEPAGE_URL
  }
}