import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import ModelBody from "./RcvModel_Body.js";
import RcvItemCatalog from "./RcvItemCatalog.js";
import Catalog from "./RcvCatalog.js";
import ItemModel from "../Item/ItemModel.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import PurchaseModel from "../Purchase/PurModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByFullPayment(userData) {
    userData.PaymentValue = App.In.getInteger(userData.Total);
  },
  setDetailsByPaymentList(userData, paymentList) {
    if (paymentList === undefined || paymentList === null) {
      return;
    }

    userData.PaymentTypeName = PaymentTypeModel.getDefault(paymentList);
  },
  setDetailsByPurchase(userData, purchaseData, vendorDetails) {
    // user input
    userData.PurchasingID = purchaseData.ID;
    userData.VendorID = purchaseData.VendorID;
    userData.VendorName = App.In.getString(purchaseData.Vendor);
    userData.VendorAddress = App.In.getString(purchaseData.VendorAddress);
    userData.DueDate = purchaseData.VendorID === null
      ? App.In.getDateToday()
      : App.Data.addDaysToDate(null, vendorDetails.DaysDueDate);
    // by system
    userData.PurchasingNumber = purchaseData.PONumber;
    userData.PurchasingDate = purchaseData.Date;
    userData.PurchasingIsActive = App.In.getBoolean(
      purchaseData.IsActive
    );
    userData.VendorAlias = purchaseData.VendorAlias;
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setItemByPurchase(userItem, purchaseItem) {
    userItem.PurchasingItemID = purchaseItem.ID;
    // user input
    userItem.DispatchID = purchaseItem.DispatchID;
    userItem.Name = App.In.getString(purchaseItem.Name);
    userItem.Quantity = App.In.getInteger(purchaseItem.QtyAvailable);
    userItem.PackagingName = purchaseItem.PackagingName;
    userItem.BuyPrice = App.In.getInteger(purchaseItem.BuyPrice);
    // by system
    userItem.SKU = purchaseItem.SKU;
    userItem.PackagingValue = purchaseItem.PackagingValue;
    userItem.PurchasingItemQuantity = purchaseItem.QtyAvailable;
    // validation
    userItem.CustomValidations = {
      [App.Vee.Rule.MaxValue]: [
        userItem.PurchasingItemQuantity,
        RcvItemCatalog.PurchasingItemQuantity.Label
      ]
    };
  },
  setItemByScanner(userItem, itemData, qty) {
    userItem.DispatchID = itemData.ID;
    userItem.Name = itemData.Name;
    userItem.Quantity = App.In.getInteger(qty);
    // by system
    userItem.SKU = itemData.SKU;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemData.PackagingList
    );

    this.setItemByScanner_setPackaging(userItem);
  },
  setItemByScanner_setPackaging(userItem) {
    let packaging;
    for (packaging of userItem.PackagingOptions.rows) {
      if (packaging.Qty === AppCatalog.PackagingPcs.Qty) {
        userItem.PackagingName = packaging.Name;
        userItem.PackagingValue = packaging.Qty;
        return;
      }
    }

    packaging = userItem.PackagingOptions.rows[0];
    userItem.PackagingName = packaging.Name;
    userItem.PackagingValue = packaging.Qty;
  },
  setItemsByPurchase(userData, userItems, purchaseItems) {
    App.Array.truncate(userItems);

    for (const row of purchaseItems) {
      let item = ModelBody.createItem(userData);
      this.setItemByPurchase(item, row);
      ModelBody.updateItem(item);
      userItems.push(item);
    }
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    } 
    else {
      // SKU
      infoList.push(RcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RcvItemCatalog)
      );
    }

    // Purchase
    if (item.PurchasingItemID) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Purchase.FullName
      );
    }

    return infoList;
  },

  validationDate(userData) {
    let userBackDateInterval = App.Session.getBackDateInterval();
    if (userBackDateInterval !== 0){
      const backDate = App.Data.addDaysToDate(
        userData.CurrentDate, -1 * userBackDateInterval);

      if (userData.PurchasingDate !== null) {
        let purchasingDate = App.In.getDate(userData.PurchasingDate)
        
        return {
          [App.Vee.Rule.MinDate]: {
            target: purchasingDate > backDate ? purchasingDate : backDate,
            label: purchasingDate > backDate ?
              Catalog.PurchasingDate.ExportLabel : null
          },
          [App.Vee.Rule.MaxDate]: {
            target: userData.CurrentDate,
            label: AppCatalog.Info.Today
          }
        };
      }

      return {
        [App.Vee.Rule.MinDate]: {
          target: backDate
        },
        [App.Vee.Rule.MaxDate]: {
          target: App.In.getDateToday(),
          label: AppCatalog.Info.Today
        }
      };
    }
    
    return undefined
  },
  validationDueDate() {
    return {
      [App.Vee.Rule.MinDate]: {
        target: App.In.getDateToday(),
        label: AppCatalog.Info.Today
      }
    };
  },

  /*** external ***/

  getPurchaseItemsForSelection(purchaseItems, userItems) {
    let resultItems = [];
    let item;

    for (const purchaseItem of purchaseItems) {
      item = App.Array.searchItem(
        userItems, "PurchasingItemID", purchaseItem.ID
      );

      if (!item) {
        resultItems.push(purchaseItem);
      }
    }

    return resultItems;
  },

  updatePurchaseItems(purchaseData) {
    let newPurchaseItems = [], isValid;

    for (let purchaseItem of purchaseData.Items) {
      isValid = true;

      purchaseItem.QtyAvailable =
        purchaseItem.Quantity - purchaseItem.QtyReceive;

      // new-item
      if (purchaseItem.DispatchID === PurchaseModel.NewItem.ItemID) {
        isValid = false;
      }
      // closed item
      else if (purchaseItem.IsClosed === 1) {
        isValid = false;
      }
      // deleted-item
      else if (purchaseItem.DispatchIsActive === 0) {
        isValid = false;
      }
      // qty available
      else if (purchaseItem.QtyAvailable <= 0) {
        isValid = false;
      }

      if (isValid) {
        newPurchaseItems.push(purchaseItem);
        PurchaseModel.updateItem(purchaseItem);
      }
    }

    purchaseData.Items = newPurchaseItems;
  }
}