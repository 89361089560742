<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../ExpCatalog.js";
import Info from "../ExpInfo.js";
import Model from "../ExpModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      if (this.userData.WithSummaryList) {
        const summaryObj = Model.getSummary(data.List);
        this.createSheet();
        this.addSummaryList(summaryObj);
      }

      this.download(fileName);
    },
    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ExpenseNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.CategoryID.Label);
      this.addColumnHeader(Catalog.CategoryID);
      this.addColumnHeader_ID(Catalog.SubCategoryID.Label);
      this.addColumnHeader(Catalog.SubCategoryID);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.Total);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("ExpenseNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("CategoryID", row, Catalog);
        this.addCellRelatedValue("CategoryID", row, Catalog);
        this.addCellValue("SubCategoryID", row, Catalog);
        this.addCellRelatedValue("SubCategoryID", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("PaymentTypeName", row, Catalog);
        this.addCellValue("Total", row, Catalog);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    },
    addSummaryList(summaryObj) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.SubCategoryName);
      this.addColumnHeader(Catalog.Total);

      // value
      for (const row of summaryObj.List) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("SubCategoryName", row, Catalog);
        this.addCellValue("Total", row, Catalog);
      }

      this.addRow();
      this.addCell();
      this.addColumnHeader(Info.Summary.Total);
      this.addCellValue("Total", summaryObj, Catalog);

      this.addSheet(Info.Export.List.Excel.SheetName.SummaryList);
    }
  }
}
</script>