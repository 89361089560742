import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ID: {
    Label: AppModule.Item.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " +  AppModule.Item.FullName,
    Type: AppCatalog.Field.ID.Type,
    RelatedValue: "Name"
  },
  QtyAdjustment: {
    Label: "Penyesuaian",
    ExportLabel: "Penyesuaian " + AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyInvoice: {
    Label: "Jual",
    ExportLabel: AppModule.Invoice.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyLast: {
    Label: "Akhir",
    Type: AppCatalog.Field.Qty.Type
  },
  QtyReceive: {
    Label: "Terima",
    ExportLabel: AppModule.Receive.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyRtnInvoice: {
    ExportLabel: AppModule.RtnInvoice.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyRtnReceive: {
    ExportLabel: AppModule.RtnReceive.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStock: {
    Label: "Awal",
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockClosing: {
    Label: "Tutup",
    ExportLabel: AppModule.StockClosing.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockConv: {
    Label: "Konversi",
    ExportLabel: AppModule.StockConv.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockOpname: {
    Label: "Opname",
    ExportLabel: AppModule.StockOpname.FullName,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockTrf: {
    Label: "Transfer",
    ExportLabel: AppModule.StockTrf.FullName,
    Type: AppCatalog.Field.Qty.Type
  }
}