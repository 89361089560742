import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.ProfitLoss,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.ProfitLoss_Act_Export,
    Act_Print: AppModuleAttr.ProfitLoss_Act_Print,
    Act_Report: AppModuleAttr.ProfitLoss_Act_Report,
    // related
    DebtPay_Act_List: AppModuleAttr.DebtPay_Act_List,
    Expenses_Act_List: AppModuleAttr.Expenses_Act_List,
    HppAdj_Act_List: AppModuleAttr.HppAdj_Act_List,
    Invoice_Act_List: AppModuleAttr.Invoice_Act_List,
    RecPay_Act_List: AppModuleAttr.RecPay_Act_List,
    RtnInvoice_Act_List: AppModuleAttr.RtnInvoice_Act_List,
    StockOpname_Act_List: AppModuleAttr.StockOpname_Act_List,
    StockReduction_Act_List: AppModuleAttr.StockReduction_Act_List,
    StockTrf_Act_List: AppModuleAttr.StockTrf_Act_List
  },

  Search: {
    ListParams: ["Date", "WarehouseID"]
  },

  /*** method ***/

  createChart() {
    return {
      LabelList: [],
      ValueAvg: 0,
      ValueList: []
    };
  },
  createSummary() {
    return {
      Total: 0,
      TransactionList: []
    };
  },

  getChartXLabel(searchData) {
    const year1 = searchData.Date[0].getFullYear();
    const year2 = searchData.Date[1].getFullYear();

    if (year1 === year2) {
      const month1 = searchData.Date[0].getMonth();
      const month2 = searchData.Date[1].getMonth();

      if (month1 === month2) {
        return AppCatalog.MonthLongNames[month1] + " " + year1;
      }
      else {
        return AppCatalog.MonthLongNames[month1] + " - " +
        AppCatalog.MonthLongNames[month2] + " " + year1;
      }
    }
    else {
      const month1 = searchData.Date[0].getMonth();
      const month2 = searchData.Date[1].getMonth();

      return AppCatalog.MonthLongNames[month1] + " " + year1 + " - " +
        AppCatalog.MonthLongNames[month2] + " " + year2;
    }
  }
}