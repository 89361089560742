import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import RecExL from "./ExportList/RecExL.vue";
import RecL from "./List/RecL.vue";
import RecPL from "./PrintList/RecPL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.Rec, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Rec, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Rec, AppModuleType.ExportList),
        component: RecExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Rec, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Rec),
          moduleattr: AppModuleAttr.Rec_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Rec, AppModuleType.List),
        name: RouterModel.getName(AppModule.Rec, AppModuleType.List),
        component: RecL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Rec, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Rec),
          moduleattr: AppModuleAttr.Rec_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Rec),
            moduleattr: AppModuleAttr.Rec_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Rec),
            moduleattr: AppModuleAttr.Rec_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Rec, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Rec, AppModuleType.PrintList),
        component: RecPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Rec, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Rec),
          moduleattr: AppModuleAttr.Rec_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}