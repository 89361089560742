import AppModule from "../../services/App/AppModule.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./CliModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.FullName,
          RecList: AppModuleType.List.FullName + " " + AppModule.Rec.FullName
        },
        // ui
        Option: {
          Details: Model.ModuleType.Details.FullName + " " + Model.Module.FullName,
          RecList: AppModuleType.List.FullName + " " + AppModule.Rec.FullName,
          SelfService: "Dengan " + AppModule.SalesSelfService.FullName,
        }
      }
    },
    List: {
      Excel: {
        //file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Client.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Client.FullName,
          ClientAddress: "Dengan " + AppModule.ClientAddress.FullName,
          Receivables: "Dengan Data " + AppModule.Rec.FullName,
          SelfService: "Dengan " + AppModule.SalesSelfService.FullName,
        }
      }
    }
  },
  Field:{
    DiscPercent: {
      Suffix: AppCatalog.PercentSign
    }
  },
  Message: {
    Edit_ConfirmDeleteAddress:
      "Yakin menghapus " + Model.ClientAddress.Module.FullName + "?",
    ExportDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteAddress:
      "Yakin menghapus " + Model.ClientAddress.Module.FullName + "?"
  },
  Modal: {
    Activate: {
      Title: Model.ModuleType.Activate.FullName + " " +
        AppCatalog.Info.SalesSelfService
    },
    Deactivate: {
      Title: Model.ModuleType.Deactivate.FullName + " " +
        AppCatalog.Info.SalesSelfService,
      Message: AppCatalog.Info.AreYouSureToDeactivate + " " +
        AppCatalog.Info.SalesSelfService + "?"
    },
    EditPin: {
      Title: Model.ModuleType.EditPin.FullName + " " +
        AppCatalog.Info.SalesSelfService
    }
  },
  Section: {
    Address: {
      Title: AppModuleType.List.Name + " " + AppModule.ClientAddress.FullName
    },
    SelfService: {
      Title: AppCatalog.Info.SalesSelfService
    },
    TransactionSetting: {
      Title: AppModule.Settings.Name + " Transaksi"
    }
  },
  Tabs: {
    Details: AppModuleType.Details.FullName,
    ReceivablesList: AppModuleType.List.FullName + " " + AppModule.Rec.FullName
  }
}