<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-3">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="StrdSearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="WarehouseID" :data="searchData" :catalog="StrdSearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="StrdSearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import StrdSearchCatalog from "../StrdSearchCatalog.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    StrdSearchCatalog: StrdSearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>