<template>
  <div class="ml-2 is-block">
    <div class="is-size-7 mb-1 ks-line-height ks-color-grey has-text-right">
      {{ Info.Welcome }}
    </div>
    <div class="is-size-6	has-text-weight-semibold ks-line-height has-text-right">
      <kst-tooltip :content="Info.User.HelpText" :size="Info.User.HelpSize">
        <button
          class="button p-0 has-text-weight-bold ks-borderless ks-height-auto"
          @click="handleLogout"
        >
          {{ userName }}
        </button>
      </kst-tooltip>
    </div>
    <div class="is-size-6	ks-line-height has-text-right">
      {{ companyName }}
    </div>
  </div>
</template>

<script>
import AppModule from "../../services/App/AppModule.js";
import RouterMixin from "../../mixins/RouterMixin.js";
import Info from "./LayoutInfo.js";

export default {
  mixins: [RouterMixin],
  data: () => ({
    Info: Info
  }),
  computed: {
    companyName() {
      return this.$kst.Session.getCompanyName();
    },
    userName() {
      return this.$kst.Session.getName();
    }
  },
  methods: {
    handleLogout() {
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignOut));
    }
  }
}
</script>