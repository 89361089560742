<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details 
      :userData="userData" 
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Delivery
      :userData="userData" 
    />
    <Items 
      :access="access"
      :userData="userData" 
      :userItems="userItems"
    />
      <template v-if="access.Fld_Amount">
        <div class="column">
          <Total :data="userData"/>
        </div>
      </template>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import Details from "./PurN_Details.vue";
import Items from "./PurN_Items.vue";
import Total from "./PurN_Total.vue";
import Delivery from "./PurN_Delivery.vue";

export default {
  components: {
    Delivery,
    Details,
    Items,
    Total
  },
  mixins: [InputPageMixin],
  data: () => ({
    access: {},
    userData: {},
    userItems: [],
    warehouseOptions: {}
  }),
  methods: {
    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.userData.WarehouseID = Model.getWarehouseDefault(
        data.WarehouseList, this.userData.WarehouseID
      );
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      Model.setDetailsByWarehouse(this.userData, this.warehouseOptions);
    },
    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/
    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData = Model.createDetails();
      this.userItems = [];
      this.userData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
  }
}
</script>