<template>
  <kst-section list-mode
    :title="Info.Section.Variant.Title"
  >
    <List :dataList="dataList"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import List from "./ItemDL_Item_Variant_List.vue";

export default {
  components: {
    List
  },
  props: {
    details: Object
  },
  data: () => ({
    Info: Info,
    Model: Model
  }),
  computed: {
    dataList() {
      return Model.Details.getVariantList(this.details);
    }
  }
}
</script>