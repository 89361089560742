import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  /*** related ***/

  /*** property ***/

  /*** method ***/

  updateList(dataList) {
    if (!Array.isArray(dataList)) {
      return;
    }

    for (const row of dataList) {
      row.UrlParams = App.Route.getParams({
        [AppCatalog.Field.ID.Param]: row.ID
      });
    }
  }
}