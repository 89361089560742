<template>
  <kst-table paginated view-mode
    :action="hasAccessAction"
    :data="items"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveNumber"
      :label="Catalog.ReceiveNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="vendor">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="VendorName"
        :label="Catalog.VendorName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="DueDate"
      :label="Catalog.DueDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="DebtTotal"
      :label="Catalog.DebtTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip details-mode hover-only>
        <kst-button action-mode
          class="ks-mt--2"
          :class="Info.Button.Transaction.Class"
          :label="Info.Button.Transaction.Label"
          :module="Info.Button.Transaction.Module"
          :moduleType="Info.Button.Transaction.ModuleType"
          :urlParams="row.UrlParams"
        />
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DebtCatalog.js";
import Info from "../DebtInfo.js";
import Model from "../DebtModel.js";

export default {
  props: {
    access: Object,
    items: Array,
    // field visibility (order by appearance)
    vendor: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  }),
  computed: {
    hasAccessAction() {
      return this.access.Receive_Act_Details;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      Model.RelatedList.updateList(this.items);
    }
  }
}
</script>