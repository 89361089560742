<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RinvCatalog.js";
import Info from "../RinvInfo.js";
import Model from "../RinvModel.js";
import RinvItemCatalog from "../RinvItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.SalesOrderID.ExportLabel);
      this.addColumnHeader(Catalog.SalesOrderID.Label);
      this.addColumnHeader(Catalog.ReturnNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.Total);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(RinvItemCatalog.DispatchID.Label);
        this.addColumnHeader(RinvItemCatalog.DispatchID);
        this.addColumnHeader(RinvItemCatalog.Packaging);
        this.addColumnHeader(RinvItemCatalog.Quantity);
        this.addColumnHeader(RinvItemCatalog.TotalQuantity);
        this.addColumnHeader(RinvItemCatalog.SellPrice);
        this.addColumnHeader(RinvItemCatalog.ReturnInvoiceItemsTotal);
        if (this.access.Glob_HPP) {
          this.addColumnHeader(RinvItemCatalog.HppTotal);
        }
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("SalesOrderID", row, Catalog);
        this.addCellRelatedValue("SalesOrderID", row, Catalog);
        this.addCellValue("ReturnNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("ClientAddress", row, Catalog);
        this.addCellValue(row.DiscValue ? "DiscValue" : "DiscPercent",
          row, Catalog);
        this.addCellValue("Total", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, RinvItemCatalog);
          this.addCellRelatedValue("DispatchID", row, RinvItemCatalog);
          this.addCellValue("Packaging", row, RinvItemCatalog);
          this.addCellValue("Quantity", row, RinvItemCatalog);
          this.addCellValue("TotalQuantity", row, RinvItemCatalog);
          this.addCellValue("SellPrice", row, RinvItemCatalog);
          this.addCellValue("ReturnInvoiceItemsTotal", row, RinvItemCatalog);
          if (this.access.Glob_HPP) {
            this.addCellValue("HppTotal", row, RinvItemCatalog);
          }
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>