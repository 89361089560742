<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Purchase
      :userData="userData"
      :warehouseOptions="warehouseOptions"
      :warehouseList="warehouseList"
      @reset="handlePurchase_reset"
      @submit="handlePurchase_submit"
    />
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Items
      :access="access"
      :itemList="itemList"
      :userData="userData"
      :userItems="userItems"
      :purchaseData="purchaseData"
    />

    <div class="columns">
      <div class="column is-half">
        <Details2 :userData="userData"/>
      </div>
      <div class="column" v-if="access.Fld_Amount">
        <Total :userData="userData"/>
      </div>
    </div>

    <DebtPay v-if="isDebtPayVisible"
      :paymentOptions="paymentOptions"
      :userData="userData"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../RcvCatalog.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import DebtPay from "./RcvN_DebtPay.vue";
import Details from "./RcvN_Details.vue";
import Details2 from "./RcvN_Details2.vue";
import Items from "./RcvN_Items.vue";
import Total from "./RcvN_Total.vue";
import Purchase from "./RcvN_Purchase.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    DebtPay,
    Details,
    Details2,
    Items,
    Total,
    Purchase
  },
  data: () => ({
    access: {},
    userData: {},
    userItems: [],
    itemList: [],
    paymentOptions: {},
    purchaseData: {},
    purchaseStatusEnum: Model.PurchaseStatus,
    warehouseOptions: {},
    warehouseList: []
  }),
  computed: {
    isDebtPayVisible() {
      return this.$kst.Session.companyHasAccess(Model.DebtPay.Module);
    }
  },
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const options = {
        purchase: this.getUrlParam(this.$kst.Enum.Param.PurchaseID)
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.itemList = data.ItemList;
      this.paymentOptions = Model.getPaymentOptions(data.PaymentTypeList,
        null, Catalog.PaymentTypeName.Label
      );
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.warehouseList = data.WarehouseList;

      Model.setDetailsByCompanySetting(this.userData, data.CompanySetting);
      Model.New.setDetailsByPaymentList(this.userData, this.paymentOptions.rows);
      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);

      if (Object.prototype.hasOwnProperty.call(data, "PurchaseDetails")) {
        let vendorDetails = data.PurchaseDetails.VendorID === null
          ? null : data.VendorDetails;
        this.updateByPurchase(data.PurchaseDetails, vendorDetails);
      }
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** by purchase ***/

    handlePurchase_reset() {
      this.resetDataByPurchase();
    },
    handlePurchase_submit(data) {
      this.resetDataByPurchase();

      let vendorDetails = data.PurchaseDetails.VendorID === null
        ? null : data.VendorDetails;
      this.updateByPurchase(data.PurchaseDetails, vendorDetails);
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData = Model.createDetails();
      this.userItems = [];
      this.itemList = [];
      this.purchaseData = {};
      this.warehouseList = [];
    },
    resetDataByPurchase() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.purchaseData = {};

      Model.New.setDetailsByPaymentList(
        this.userData, this.paymentOptions.rows
      );
    },

    updateByPurchase(purchaseDetails, vendorDetails) {
      this.purchaseData = purchaseDetails;
      Model.New.updatePurchaseItems(this.purchaseData);

      Model.New.setItemsByPurchase(this.userData, this.userItems,
        this.purchaseData.Items
      );
      Model.New.setDetailsByPurchase(this.userData, this.purchaseData,
        vendorDetails
      );
    }
  }
}
</script>