import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import RinvItemCatalog from "./RinvItemCatalog.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.unshift(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(RinvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RinvItemCatalog)
      );
    }

    return infoList;
  }
}