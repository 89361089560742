import AppCatalog from "../../services/App/AppCatalog";
import AppModule from "../../services/App/AppModule";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ItemID: {
    Label: "ID " + AppModule.Item.FullName,
    ExportLabel: AppModule.Item.FullName
  },
  ItemName: {
    Label: AppCatalog.Field.Name.Label + " " + AppModule.Item.FullName
  },
  PackagingName: {
    Label: AppCatalog.Field.Name.Label + " " + 
      AppModule.ItemPackaging.FullName
  },
  PackagingQty: {
    Label: AppCatalog.Field.Qty.Label + " " + 
      AppModule.ItemPackaging.FullName
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label + " Saat Ini",
  },

  // support
  Message: {
    Label: "Pesan"
  },
  Info: {
    Label: AppCatalog.Info.Description
  },
  Total: {
    Label: AppCatalog.Field.Total.Label
  }
}