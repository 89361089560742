import AppType from "./App_Type.js";
import App_Output_Body from "./App_Output_Body.js";

const DATE_SEPARATOR = "-";

/**
 * Convert:
 * - from: value (source: user input)
 * - to: value (target: API)
 */
export default {
  getBoolean(value) {
    return (value ? 1 : 0);
  },
  // sample: January 31, 2000 returns "2000-01-31"
  getDateString(value) {
    if (value === undefined || value === null) {
      return null;
    }

    if (!AppType.isDate(value)) {
      return null;
    }

    const iMonth = value.getMonth() + 1;
    const iDate = value.getDate();
    return value.getFullYear() + DATE_SEPARATOR +
      (iMonth < 10 ? "0" + iMonth : iMonth) + DATE_SEPARATOR +
      (iDate < 10 ? "0" + iDate : iDate);
  },
  getDecimal(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = parseFloat(value);

    if (isNaN(newValue)) {
      return null;
    }
    return newValue;
  },
  getInteger(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = parseInt(value);

    if (isNaN(newValue)) {
      return null;
    }
    return newValue;
  },
  getString(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = value.trim();

    if (newValue === "") {
      return null;
    }
    return newValue;
  },
  getTimeString(value) {
    if (value === undefined || value === null) {
      return null;
    }

    if (!AppType.isDate(value)) {
      return null;
    }

    const hours = value.getHours().toString().padStart(2, '0');
    const minutes = value.getMinutes().toString().padStart(2, '0');
    const seconds = value.getSeconds().toString().padStart(2, '0');

    const timeString = `${hours}:${minutes}:${seconds}`;
    return timeString;
  },

  /**
   * Convert array of data into url parameters
   * @param {Object} options - array of object contains data
   * @param {Array} paramNameList - array of keys
   * i.e. options = { first: "hello", last: "world" }, paramNameList = ["first", "hello"]
   *  return: "?first=hello&last=world"
   * Returns {String} url parameter
   */
  getParams: function(options, paramNameList) {
    if (options === undefined || options === null) {
      return "";
    }
    if (!options) {
      return "";
    }
  
    const Separator = "&";
    let paramString = "";

    // iterate possible parameter name
    for (const param of paramNameList) {
      if (Object.prototype.hasOwnProperty.call(options, param)) {
        // value is Array
        if (Array.isArray(options[param])) {
          for (const paramValue of options[param]) {
            if (paramString !== "") {
              paramString += Separator;
            }

            paramString += param + "=" +
              App_Output_Body.getParams_getValue(paramValue);
          }
        }
        else {
          if (paramString !== "") {
            paramString += Separator;
          }

          paramString += param + "=" +
            App_Output_Body.getParams_getValue(options[param]);
        }
      }
    }
  
    return (paramString === "" ? "" : "?" + paramString);
  },

  // DEPRECATED - use: getDateString
  getDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    if (!AppType.isDate(value)) {
      return null;
    }

    return value.toISOString();
  }
}