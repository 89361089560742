<template>
  <b-button
    v-if="theHasAccess"
    class="kst-button"
    :class="theClass"
    :disabled="disabled"
    :href="theHref"
    :icon-left="theIconLeft"
    :label="theLabel"
    :rounded="rounded"
    :tag="theTag"
    :target="theTarget"
    :to="theTo"
    @click="handleClick"
  />
</template>

<script>
import ComponentMixin from "../../../mixins/ComponentMixin.js";
import AppCatalog from "../../../services/App/AppCatalog.js";
import RouterModel from "../../../router/RouterModel.js";

/**
 * Note:
 * - confirm-mode (button) and close-mode (button) are used for selection (i.e. in modal)
 * - cancel-mode (link) and submit-mode (button) are used for form (i.e. in form of new page)
 * - search-mode (button) and clear-mode (button) are used for field (i.e. in date field)
 */
const TAG_LINK = "a";
const TAG_ROUTER_LINK = "router-link";

export default {
  mixins: [ComponentMixin],
  props: {
    disabled: Boolean,
    href: String, // required in mailMode & urlMode
    iconLeft: String,
    label: String,
    rounded: Boolean,
    tag: String,
    target: String,
    to: String, // required in cancelMode
    // size
    slimSize: Boolean,
    // ONLY in: actionMode
    fullLabel: Boolean,
    hasAccess: {
      type: Boolean,
      default: null
    },
    module: Object,
    moduleType: Object,
    urlParams: String,
    // mode: ONLY select 1 (one)
    actionMode: Boolean,
    cancelMode: Boolean,
    checkAllMode: Boolean,
    clearMode: Boolean,
    closeMode: Boolean,
    confirmMode: Boolean,
    deleteMode: Boolean,
    editMode: Boolean,
    fullPaymentMode: Boolean,
    fullReceivedMode: Boolean,
    mailMode: Boolean,
    phoneMode: Boolean,
    printMode: Boolean,
    randomMode: Boolean,
    refreshMode: Boolean,
    reloadMode: Boolean,
    resetMode: Boolean,
    searchMode: Boolean,
    signinMode: Boolean,
    signoutMode: Boolean,
    submitMode: Boolean,
    uncheckAllMode: Boolean,
    urlMode: Boolean,
    verifyMode: Boolean,
    // additional
    isRound: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theHasAccess() {
      return this.getHasAccess();
    },
    theClass() {
      return this.getClassName();
    },
    theHref() {
      return this.getHref();
    },
    theIconLeft() {
      return this.getIconLeft();
    },
    theLabel() {
      return this.getLabel();
    },
    theTag() {
      return this.getTagName();
    },
    theTarget() {
      return this.getTarget();
    },
    theTo() {
      return this.getToUrl();
    }
  },
  methods: {
    handleClick() {
      this.$emit(this.$kst.Enum.Event.Click);
    },

    getHasAccess() {
      return this.hasAccess === false ? false : true;
    },
    getClassName() {
      let className = "";
      if (this.isRound) {
        className = "k-radius-rounded ";
      }

      if (this.slimSize) {
        className = className + "py-0 ks-height-auto ";
      }

      const compClasses = this.getComp_Classes();
      if (compClasses !== "") {
        // value of compClasses auto included
        if (this.moduleType) {
          if (this.$kst.Data.isModuleType_hideActionInMobile(this.moduleType)) {
            return className + AppCatalog.Button.Mobile.Class;
          }
        }
        return className;
      }

      if (this.actionMode) {
        if (this.moduleType) {
          if (this.$kst.Data.isModuleType_hideActionInMobile(this.moduleType)) {
            return className + AppCatalog.Button.Action.Class +
              AppCatalog.Button.Mobile.Class;
          }
        }
        return className + AppCatalog.Button.Action.Class;
      }
      else if (this.cancelMode) {
        return className + AppCatalog.Button.Cancel.Class;
      }
      else if (this.checkAllMode) {
        return className + AppCatalog.Button.CheckAll.Class;
      }
      else if (this.clearMode) {
        return className + AppCatalog.Button.Clear.Class;
      }
      else if (this.closeMode) {
        return className + AppCatalog.Button.Close.Class;
      }
      else if (this.confirmMode) {
        return className + AppCatalog.Button.Confirm.Class;
      }
      else if (this.deleteMode) {
        return className + AppCatalog.Button.Delete.Class;
      }
      else if (this.editMode) {
        return className + AppCatalog.Button.Edit.Class;
      }
      else if (this.fullPaymentMode) {
        return className + AppCatalog.Button.FullPayment.Class;
      }
      else if (this.fullReceivedMode) {
        return className + AppCatalog.Button.FullReceived.Class;
      }
      else if (this.mailMode) {
        return className + AppCatalog.Button.Mail.Class;
      }
      else if (this.phoneMode) {
        return className + AppCatalog.Button.Phone.Class;
      }
      else if (this.printMode) {
        return className + AppCatalog.Button.Print.Class;
      }
      else if (this.randomMode) {
        return className + AppCatalog.Button.Random.Class;
      }
      else if (this.refreshMode) {
        return className + AppCatalog.Button.Refresh.Class;
      }
      else if (this.reloadMode) {
        return className + AppCatalog.Button.Reload.Class;
      }
      else if (this.resetMode) {
        return className + AppCatalog.Button.Reset.Class;
      }
      else if (this.searchMode) {
        return className + AppCatalog.Button.Search.Class;
      }
      else if (this.signinMode) {
        return className + AppCatalog.Button.SignIn.Class;
      }
      else if (this.signoutMode) {
        return className + AppCatalog.Button.SignOut.Class;
      }
      else if (this.submitMode) {
        return className + AppCatalog.Button.Submit.Class;
      }
      else if (this.uncheckAllMode) {
        return className + AppCatalog.Button.UncheckAll.Class;
      }
      else if (this.urlMode) {
        return className + AppCatalog.Button.Url.Class;
      }
      else if (this.verifyMode) {
        return className + AppCatalog.Button.Verify.Class;
      }

      return undefined;
    },
    getHref() {
      if (this.getTagName() !== TAG_LINK) {
        return undefined;
      }

      if (this.mailMode) {
        return "mailto:" + this.href;
      }
      else if (this.phoneMode) {
        return "tel:" + this.href;
      }

      if (this.href !== undefined) {
        return this.href;
      }

      return undefined;
    },
    getIconLeft() {
      if (this.iconLeft !== undefined) {
        return this.iconLeft;
      }

      if (this.actionMode) {
        if (this.moduleType) {
          return this.moduleType.Icon;
        }
      }
      else if (this.cancelMode) {
        return AppCatalog.Button.Cancel.Icon;
      }
      else if (this.checkAllMode) {
        return AppCatalog.Button.CheckAll.Icon;
      }
      else if (this.clearMode) {
        return AppCatalog.Button.Clear.Icon;
      }
      else if (this.closeMode) {
        return AppCatalog.Button.Close.Icon;
      }
      else if (this.confirmMode) {
        return AppCatalog.Button.Confirm.Icon;
      }
      else if (this.deleteMode) {
        return AppCatalog.Button.Delete.Icon;
      }
      else if (this.editMode) {
        return AppCatalog.Button.Edit.Icon;
      }
      else if (this.fullPaymentMode) {
        return AppCatalog.Button.FullPayment.Icon;
      }
      else if (this.fullReceivedMode) {
        return AppCatalog.Button.FullReceived.Icon;
      }
      else if (this.mailMode) {
        return AppCatalog.Button.Mail.Icon;
      }
      else if (this.phoneMode) {
        return AppCatalog.Button.Phone.Icon;
      }
      else if (this.printMode) {
        return AppCatalog.Button.Print.Icon;
      }
      else if (this.randomMode) {
        return AppCatalog.Button.Random.Icon;
      }
      else if (this.refreshMode) {
        return AppCatalog.Button.Refresh.Icon;
      }
      else if (this.reloadMode) {
        return AppCatalog.Button.Reload.Icon;
      }
      else if (this.resetMode) {
        return AppCatalog.Button.Reset.Icon;
      }
      else if (this.searchMode) {
        return AppCatalog.Button.Search.Icon;
      }
      else if (this.signinMode) {
        return AppCatalog.Button.SignIn.Icon;
      }
      else if (this.signoutMode) {
        return AppCatalog.Button.SignOut.Icon;
      }
      else if (this.submitMode) {
        return AppCatalog.Button.Submit.Icon;
      }
      else if (this.uncheckAllMode) {
        return AppCatalog.Button.UncheckAll.Icon;
      }
      else if (this.urlMode) {
        return AppCatalog.Button.Url.Icon;
      }
      else if (this.verifyMode) {
        return AppCatalog.Button.Verify.Icon;
      }

      return undefined;
    },
    getLabel() {
      if (this.label !== undefined) {
        return this.label;
      }

      if (this.actionMode) {
        if (this.fullLabel) {
          if (this.moduleType && this.module) {
            return this.moduleType.Name + " " + this.module.FullName;
          }
        }
        else {
          if (this.moduleType) {
            return this.moduleType.Name;
          }
        }
      }
      else if (this.cancelMode) {
        return AppCatalog.Button.Cancel.Label;
      }
      else if (this.checkAllMode) {
        return AppCatalog.Button.CheckAll.Label;
      }
      else if (this.clearMode) {
        return AppCatalog.Button.Clear.Label;
      }
      else if (this.closeMode) {
        return AppCatalog.Button.Close.Label;
      }
      else if (this.confirmMode) {
        return AppCatalog.Button.Confirm.Label;
      }
      else if (this.deleteMode) {
        return AppCatalog.Button.Delete.Label;
      }
      else if (this.editMode) {
        return AppCatalog.Button.Edit.Label;
      }
      else if (this.fullPaymentMode) {
        return AppCatalog.Button.FullPayment.Label;
      }
      else if (this.fullReceivedMode) {
        return AppCatalog.Button.FullReceived.Label;
      }
      else if (this.mailMode) {
        return AppCatalog.Button.Mail.Label;
      }
      else if (this.phoneMode) {
        return AppCatalog.Button.Phone.Label;
      }
      else if (this.printMode) {
        return AppCatalog.Button.Print.Label;
      }
      else if (this.randomMode) {
        return AppCatalog.Button.Random.Label;
      }
      else if (this.refreshMode) {
        return AppCatalog.Button.Refresh.Label;
      }
      else if (this.reloadMode) {
        return AppCatalog.Button.Reload.Label;
      }
      else if (this.resetMode) {
        return AppCatalog.Button.Reset.Label;
      }
      else if (this.searchMode) {
        return AppCatalog.Button.Search.Label;
      }
      else if (this.signinMode) {
        return AppCatalog.Button.SignIn.Label;
      }
      else if (this.signoutMode) {
        return AppCatalog.Button.SignOut.Label;
      }
      else if (this.submitMode) {
        return AppCatalog.Button.Submit.Label;
      }
      else if (this.uncheckAllMode) {
        return AppCatalog.Button.UncheckAll.Label;
      }
      else if (this.urlMode) {
        return AppCatalog.Button.Url.Label;
      }
      else if (this.verifyMode) {
        return AppCatalog.Button.Verify.Label;
      }

      return undefined;
    },
    getTagName() {
      if (this.tag !== undefined) {
        return this.tag;
      }

      if (this.$kst.Vue.hasEventHandler(this, this.$kst.Enum.Event.Click)) {
        return undefined;
      }
      else if (this.actionMode || this.cancelMode) {
        return TAG_ROUTER_LINK;
      }
      else if (this.mailMode || this.urlMode || this.phoneMode) {
        return TAG_LINK;
      }

      return undefined;
    },
    getTarget() {
      if (this.target !== undefined) {
        return this.target;
      }

      if (this.actionMode) {
        if (this.moduleType) {
          return this.moduleType.Target;
        }
      }
      else if (this.mailMode || this.urlMode || this.phoneMode) {
        return "_blank";
      }

      return undefined;
    },
    getToUrl() {
      if (this.getTagName() !== TAG_ROUTER_LINK) {
        return undefined;
      }

      if (this.to !== undefined) {
        return this.to;
      }

      if (this.actionMode) {
        if (this.module && this.moduleType) {
          return RouterModel.getPath(this.module, this.moduleType) +
            (this.urlParams === undefined ? "" : this.urlParams);
        }
      }
      else if (this.cancelMode) {
        return this.to;
      }

      return undefined;
    }
  }
}
</script>