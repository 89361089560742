<template>
  <kst-page-list
    :catalog="StkSearchCatalog"
    :params="Model.Search.ListParams"
    :resultHelp="resultData.Help"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
        :searchData="searchData"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../StkModel.js";
import StkSearchCatalog from "../StkSearchCatalog.js";
import Services from "../../../services/Api/StockServices.js";

import List from "./StkL_List.vue";
import Search from "./StkL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    StkSearchCatalog: StkSearchCatalog,
    access: {},
    resultData: {
      HppTotal: null,
      Help: null
    },
    searchData: {
      Search: "",
      LastInvoiceDate: null,
      StatusID: Model.Status.Available.ID,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, StkSearchCatalog.WarehouseID.Label
      );

      if (this.access.Glob_HPP) {
        if (Object.prototype.hasOwnProperty.call(data, "HppTotal")) {
          this.resultData.HppTotal = data.HppTotal;
          this.resultData.Help = Model.getResultSectionHelp(this.resultData);
        }
      }
    },

    handleLoad(loadList) {
      this.resultData.Help = null;

      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config, this.handleLoad_success);
    },
    handleLoad_success(data) {
      if (this.access.Glob_HPP) {
        if (Object.prototype.hasOwnProperty.call(data, "HppTotal")) {
          this.resultData.HppTotal = data.HppTotal;
          this.resultData.Help = Model.getResultSectionHelp(this.resultData);
        }
      }
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.resultData.HppTotal = null;
      this.resultData.Help = null;
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.LastInvoiceDate = this.$kst.Search.OptionAll;
      this.searchData.StatusID = Model.Status.Available.ID;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      let result = {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        haslastinvoicedate: this.$kst.Search.getValue(
          this.searchData.LastInvoiceDate),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };

      const statusId = this.$kst.Out.getInteger(this.searchData.StatusID);
      if (statusId === Model.Status.Available.ID) {
        result.hasqty = this.$kst.Out.getBoolean(true);
      }
      else if (statusId === Model.Status.NotAvailable.ID) {
        result.hasnoqty = this.$kst.Out.getBoolean(true);
      }

      return result;
    }
  }
}
</script>