<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Info from "../StcdInfo.js";
import Model from "../StcdModel.js";
import StcdItemCatalog from "../StcdItemCatalog.js";
import StcdStockCatalog from "../StcdStockCatalog.js";
import StcdTransListCatalog from "../StcdTransListCatalog.js";
import StcdTransSummaryCatalog from "../StcdTransSummaryCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    searchData: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      if (this.searchData.ItemID !== null) {
        this.addItemDetails(data.ItemDetails);
      }

      if (this.userData.WithStockList) {
        this.createSheet();
        this.addStockList(data.StockList);
      }

      if (this.userData.WithTransSummary) {
        this.createSheet();
        this.addTransactionSummary(data.TransactionSummary);
      }

      if (this.userData.WithTransList) {
        this.createSheet();
        this.addTransactionList(data.TransactionList);
      }

      this.download(fileName);
    },

    addItemDetails(itemDetails) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(StcdItemCatalog.ID.ColumnLabel);
      this.addColumnHeader(StcdItemCatalog.SKU);

      // value
      this.addRow();
      this.addCellValue_ID(itemDetails);
      this.addCellRelatedValue("ID", itemDetails, StcdItemCatalog);
      this.addCellValue("SKU", itemDetails, StcdItemCatalog);

      this.addSheet(Info.Export.Excel.SheetName.ItemStock);
    },

    addStockList(stockList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StcdStockCatalog.WarehouseID.Label);
      this.addColumnHeader(StcdStockCatalog.WarehouseID);
      this.addColumnHeader(StcdStockCatalog.Quantity);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StcdStockCatalog.HppTotal);
        this.addColumnHeader(StcdStockCatalog.HppPerPcs);
      }

      // value
      for (const row of stockList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("WarehouseID", row, StcdStockCatalog);
        this.addCellRelatedValue("WarehouseID", row, StcdStockCatalog);
        this.addCellValue("Quantity", row, StcdStockCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, StcdStockCatalog);
          this.addCellValue("HppPerPcs", row, StcdStockCatalog);
        }
      }

      this.addSheet(Info.Export.Excel.SheetName.StockList);
    },

    addTransactionSummary(transSummaryList) {
      const dataList = Model.Export.getTransactionSummaryList(transSummaryList);

      // label
      this.addSequenceHeader();
      this.addColumnHeader(StcdTransSummaryCatalog.ActivityTypeName);
      this.addColumnHeader(StcdTransSummaryCatalog.ChangesIn);
      this.addColumnHeader(StcdTransSummaryCatalog.ChangesOut);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("ActivityTypeName", row, StcdTransSummaryCatalog);
        this.addCellValue("ChangesIn", row, StcdTransSummaryCatalog);
        this.addCellValue("ChangesOut", row, StcdTransSummaryCatalog);
      }

      this.addSheet(Info.Export.Excel.SheetName.TransSummary);
    },

    addTransactionList(transList) {
      const dataList = Model.Export.getTransactionGroupObj(transList);

      let isFirstRow = true;
      for (const row of Object.values(dataList)) {
        if (!isFirstRow) {
          this.addRow();
          this.addRow();
        }
        isFirstRow = false;

        // warehouse
        this.addColumnHeader(StcdTransListCatalog.WarehouseID.Label + ":");
        this.addCellRelatedValue("WarehouseID", row, StcdTransListCatalog);

        // transaction list
        this.addRow();
        this.addTransactionList_addTransactionList(row.TransactionList);
      }

      this.addSheet(Info.Export.Excel.SheetName.TransList);
    },
    addTransactionList_addTransactionList(transList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(StcdTransListCatalog.CreatedDate);
      this.addColumnHeader_ID(StcdTransListCatalog.TransactionID.Label);
      this.addColumnHeader(StcdTransListCatalog.ActivityTypeName);
      this.addColumnHeader(StcdTransListCatalog.Changes);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StcdTransListCatalog.HppChanges);
      }
      this.addColumnHeader(StcdTransListCatalog.QuantityAfter);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StcdTransListCatalog.HppAfter);
      }

      // value
      for (const row of transList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("CreatedDate", row, StcdTransListCatalog);
        this.addCellValue("TransactionID", row, StcdTransListCatalog);
        this.addCellValue("ActivityTypeName", row, StcdTransListCatalog);
        this.addCellValue("Changes", row, StcdTransListCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppChanges", row, StcdTransListCatalog);
        }
        this.addCellValue("QuantityAfter", row, StcdTransListCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppAfter", row, StcdTransListCatalog);
        }
      }
    }
  }
}
</script>