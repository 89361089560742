import OutputTypeEnum from "./OutputTypeEnum.js";

/**
 * output description & example: see at the end of the page
 * 
 * shared output type:
 * {acc} Accounting
 * {acc2} Accounting2D
 * {cur} Currency
 **/
export default {
  Boolean: {
    Default: OutputTypeEnum.Boolean,
    ActiveInactive: OutputTypeEnum.ActiveInactive,
    AutoManual: OutputTypeEnum.AutoManual,
    Boolean: OutputTypeEnum.Boolean,
    OpenClosed: OutputTypeEnum.OpenClosed,
    YesNo: OutputTypeEnum.YesNo
  },
  Date: {
    Default: OutputTypeEnum.ShortDate,
    ShortDate: OutputTypeEnum.ShortDate,
    MediumDate: OutputTypeEnum.MediumDate,
    LongDate: OutputTypeEnum.LongDate,
    ShortMonthly: OutputTypeEnum.ShortMonthly,
    LongMonthly: OutputTypeEnum.LongMonthly
  },
  DateTime: {
    Default: OutputTypeEnum.ShortDateTime,
    ShortDateTime: OutputTypeEnum.ShortDateTime,
    MediumDateTime: OutputTypeEnum.MediumDateTime,
    LongDateTime: OutputTypeEnum.LongDateTime
  },
  Decimal: {
    Default: OutputTypeEnum.Decimal,
    Decimal: OutputTypeEnum.Decimal,
    Decimal2D: OutputTypeEnum.Decimal2D,
    Accounting: OutputTypeEnum.Accounting,
    Accounting2D: OutputTypeEnum.Accounting2D,
    Currency: OutputTypeEnum.Currency,
    Text: OutputTypeEnum.Text
  },
  Email: {
    Default: OutputTypeEnum.Email,
    Email: OutputTypeEnum.Email
  },
  ID: {
    Default: OutputTypeEnum.ID,
    ID: OutputTypeEnum.ID,
  },
  Integer: {
    Default: OutputTypeEnum.Integer,
    Integer: OutputTypeEnum.Integer,
    Accounting: OutputTypeEnum.Accounting,
    Accounting2D: OutputTypeEnum.Accounting2D,
    Currency: OutputTypeEnum.Currency,
    Text: OutputTypeEnum.Text
  },
  Password: {
    Default: OutputTypeEnum.Password,
    Password: OutputTypeEnum.Password,
    Text: OutputTypeEnum.Text
  },
  Percent: {
    Default: OutputTypeEnum.Percent,
    Percent: OutputTypeEnum.Percent
  },
  Phone: {
    Default: OutputTypeEnum.Phone,
    Phone: OutputTypeEnum.Phone,
    Text: OutputTypeEnum.Text
  },
  Qty: {
    Default: OutputTypeEnum.Integer
  },
  Select: {
    Default: OutputTypeEnum.Text,
    Text: OutputTypeEnum.Text
  },
  Text: {
    Default: OutputTypeEnum.Text,
    Text: OutputTypeEnum.Text
  },
  TextArea: {
    Default: OutputTypeEnum.TextArea,
    TextArea: OutputTypeEnum.TextArea,
    Text: OutputTypeEnum.Text
  },
  Time: {
    Default: OutputTypeEnum.ShortTime
  },
  Url: {
    Default: OutputTypeEnum.Url,
    Url: OutputTypeEnum.Url
  }
}

/**
 * output description & example
 * 
 * Boolean
 * > Boolean: "boolean"
 *   show as text.
 *   see details: IsActive key in "@/services/App/AppCatalog.js"
 * 
 * Date
 * use Indonesian format (day/month/year).
 * > ShortDate: "sdate"
 *   Jan 31, 2000 display as 31/01/2000 (format: DD/MM/YYYY)
 * > MediumDate: "mdate"
 *   Jan 31, 2000 display as 31-Jan-2000 (format: DD-MMM-YYYY)
 * > LongDate: "ldate"
 *   Jan 31, 2000 display as 31 Januari 2000 (format: DD MMMM YYYY)
 * 
 * Decimal
 * use Indonesian format (thousand separator: dot-char, decimal separator: comma-char).
 * > Decimal: "dec"
 *   12500 display as 12.500
 *   12500.7 display as 12.500,7
 *   12500.123 display as 12.500,123
 * > Decimal2D: "dec2"
 *   similar to Decimal, use max 2 digits decimal (discard 3rd digit and so on).
 *   12500 display as 12.500,00
 *   12500.7 display as 12.500,70
 *   12500.128 display as 12.500,12
 * > Accounting: "acc"
 *   similar to Integer.Accounting.
 * > Accounting2D: "acc2"
 *   similar to Integer.Accounting2D.
 * > Currency: "curr",
 *   similar to Integer.Currency.
 * 
 * Email
 * > Email: "email"
 *   show standard email, with link mailto.
 * 
 * ID
 * > ID: "id"
 *   show nothing (hidden field).
 * > ForeignKey: "fk"
 *   show field with link to related record.
 *   required prop: RelatedLabel, RelatedID, RelatedModule.
 *   see: "@/services/Input/DataCatalog.js"
 * 
 * Integer
 * > Integer: "int"
 *   0 display as 0
 *   12500.7 display as 12.500
 *   -12500.128 display as -12.500
 * > Accounting: "acc"
 *   similar to Currency, displays zeros as dashes and negative numbers in parentheses.
 *   0 display as -
 *   12500.7 display as Rp 12.500
 *   -12500.128 display as (Rp 12.500)
 * > Accounting2D: "acc2"
 *   similar to Accounting, use max 2 digits decimal (discard 3rd digit and so on).
 *   0 display as -
 *   12500 display as Rp 12.500,00
 *   12500.7 display as Rp 12.500,70
 *   -12500.128 display as (Rp 12.500,12)
 * > Currency: "curr"
 *   similar to Integer, displays currency-sign (Rp) at beginning.
 *   0 display as Rp 0
 *   12500.7 display as Rp 12.500
 *   -12500.128 display as -Rp 12.500
 * 
 * Password
 * > Password: "password"
 *   show tripe-asterisk-mark (***).
 * 
 * Search:
 * > Search: "search"
 *   show initial value.
 * 
 * Text
 * > Text: "text"
 *   show initial value.
 * 
 * TextArea:
 * > TextArea: "textarea"
 *   show initial value.
 *   when show in html element, auto-convert new-line as line-break-tag <br/>.
 * 
 * Url
 * > Url: "url"
 *   show as router-link-tag for internal path (dynamic), or
 *   show as static link for external path.
 **/