import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  DestinationWarehouseID: {
    Label: AppModule.Warehouse.FullName + " " 
      + AppCatalog.Field.Destination.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "DestinationWarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "DestinationWarehouseIsActive",
    UnequalTo: "SourceWarehouseID"
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  SourceWarehouseID: {
    Label: AppModule.Warehouse.FullName + " Awal",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "SourceWarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "SourceWarehouseIsActive",
  },
  StockTrfNumber: {
    Label: AppCatalog.Field.Number.Label + " Transfer",
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 45,
    Highlight: true
  },
  
  // validation
  StockMissingQty: {
    Label: AppCatalog.Field.QtyStock.Label,
    Type: AppCatalog.Field.QtyStock.Type,
    MaxValue: 0
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Transfer",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}