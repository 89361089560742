import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StcvEnum from "./StcvEnum.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  /*** related ***/

  StockConvItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.StockConv_Act_Details,
    Act_ExportDetails: AppModuleAttr.StockConv_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.StockConv_Act_ExportList,
    Act_List: AppModuleAttr.StockConv_Act_List,
    Act_New: AppModuleAttr.StockConv_Act_New,
    Act_PrintDetails: AppModuleAttr.StockConv_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.StockConv_Act_PrintList,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  IsRaw: StcvEnum.IsRaw,

  Search: {
    ListFields: ["ConvNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      ConvNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // defined by system
      Date: App.In.getDateToday(),
      TotalRaw: 0,
      TotalFinish: 0,
      // UI validation
      InvalidItems_byStock: null,
      StockMissingQty: 0
    };
  },
  createItem() {
    return {
      // user input
      ItemID: null,
      ItemName: "",
      PackagingName: "",
      Qty: "",
      // by system
      SKU: "",
      StockPcsQty: 0,
      PackagingQty: 1,
      SellPrice: 0,
      PackagingOptions: null,
      // computed
      StockQty: 0,
      Packaging: "",
      TotalQty: 0,
      SubTotal: 0,
      // error
      Errors: [],
      ErrorsColl: {}
    }
  },

  setItemByStock(userItem, stockDetails, itemDetails) {
    // user input
    userItem.ItemID = itemDetails.ID;
    userItem.ItemName = itemDetails.Name;
    userItem.PackagingName = stockDetails.PackagingName;
    // defined by system
    userItem.SKU = itemDetails.SKU;
    userItem.StockPcsQty = stockDetails.QuantityPcs;
    userItem.PackagingQty = stockDetails.PackagingQty;
    userItem.SellPrice = stockDetails.SellPrice;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemDetails.PackagingList
    );
  },

  updateDetailsByItemsFinish(userData, userItemsFinish) {
    userData.TotalFinish = 0;

    for (const userItem of userItemsFinish) {
      userData.TotalFinish += userItem.SubTotal;
    }
  },
  updateDetailsByItemsRaw(userData, userItemsRaw) {
    userData.TotalRaw = 0;

    for (const userItem of userItemsRaw) {
      userData.TotalRaw += userItem.SubTotal;
    }
  },
  updateItem(userItem) {
    userItem.StockQty = App.Data.getQtyByPackaging(
      userItem.StockPcsQty, userItem.PackagingQty
    );
    userItem.Packaging = App.Data.getPackaging(userItem.PackagingName,
      userItem.PackagingQty);
    userItem.TotalQty = App.Data.getTotalQty(userItem.Qty,
      userItem.PackagingQty);
    userItem.SubTotal = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
  },
  updateItemByPackaging(userItem) {
    if (userItem.PackagingOptions) {
      const packagingDetails = ItemModel.getPackagingData(
        userItem.PackagingOptions.rows, userItem.PackagingName
      );

      if (packagingDetails) {
        // defined by system
        userItem.PackagingQty = packagingDetails.Qty;
        userItem.SellPrice = packagingDetails.SellPrice;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingQty
    );
  },

  validateItemsByStock(userData, userItemsRaw) {
    // reset indicator
    userData.StockMissingQty = 0;

    userData.InvalidItems_byStock = null;
    let invalidItems = [];

    const fieldKeys = "ItemID";
    const fieldValues = [
      { field: "ItemName", type: App.Pivot.Type.FIRST },
      { field: "TotalQty", type: App.Pivot.Type.SUM },
      { field: "StockPcsQty", type: App.Pivot.Type.FIRST }
    ];
    const pivotItems = App.Pivot.create(userItemsRaw, fieldKeys, fieldValues);

    for (const pivotItem of pivotItems) {
      if (pivotItem.TotalQty > pivotItem.StockPcsQty) {
        userData.StockMissingQty = 1;
        invalidItems.push(
          App.Data.getInvalidStockError(
            pivotItem.TotalQty,
            pivotItem.StockPcsQty,
            pivotItem.ItemName
          )
        );
      }
    }

    if (invalidItems.length > 0) {
      userData.InvalidItems_byStock = invalidItems;
    }
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      ConvNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ConvNumber),
      Date: App.Out.getDateString(userData.Date),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        ItemID: userItem.ItemID,
        ItemName: App.Out.getString(userItem.ItemName),
        PackagingName: userItem.PackagingName,
        PackagingQty: userItem.PackagingQty,
        Qty: App.Out.getInteger(userItem.Qty),
        SellPrice: App.Out.getInteger(userItem.SellPrice)
      });
    }

    return resultItems;
  }
}