<template>
  <kst-container v-if="theHasAccess" class="px-5 py-5 kst-section-summary">
    <kst-skeleton :active="isLoading"/>
    <kst-failure init-mode :items="failureInit"/>

    <template v-if="!failureInit">
      <nav v-if="!isLoading" class="level">
        <div class="level-left">
          <div class="level-item">
            <nav class="level is-mobile ks-is-fullwidth">
              <div class="level-left is-align-items-start">
                <div class="level-item mr-5 ks-icon-container-xl"
                  :class="iconBgStyle"
                >
                  <span class="icon is-medium">
                    <i :class="icon + iconStyle"/>
                  </span>
                </div>
                <div class="level-item is-block">
                  <div class="has-text-weight-bold mb-3">
                    <div>
                      <kst-value class="title is-5"
                        :field="field" :data="data" :catalog="catalog"
                      />
                    </div>
                  </div>
                  <div class="has-text-weight-bold has-text-grey-light is-size-5">
                    {{ title }}
                  </div>
                  <div class="is-italic" v-if="helpTitle">
                    {{ helpTitle }} <span class="has-text-weight-semibold has-text-danger">{{ helpValue }}</span>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div class="level-right">
          <kst-button action-mode
            class="is-primary"
            :has-access="hasButtonAccess"
            :iconLeft="buttonIcon"
            :label="titleButton"
            :module="module"
            :moduleType="moduleType"
            :urlParams="urlParams"
          />
        </div>
      </nav>
    </template>
  </kst-container>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";

export default {
  mixins: [RouterMixin],
  props: {
    // button
    buttonIcon: String,
    hasButtonAccess: Boolean,
    module: Object,
    moduleType: Object,
    titleButton: String,
    urlParams: String,
    // result
    catalog: Object,
    data: Object,
    field: String,
    // other
    hasAccess: {
      type: Boolean,
      default: null
    },
    helpTitle: String,
    helpValue: String,
    icon: String,
    iconBgStyle: String,
    iconStyle: String,
    isLoading: Boolean,
    failureInit: [Array, String],
    title: String
  },
  computed: {
    theHasAccess() {
      return this.hasAccess === false ? false : true;
    }
  }
}
</script>