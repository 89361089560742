<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :warehouseOptions="warehouseOptions"
    />
    <Delivery
      :userData="userData" 
    />
    <Items
      :access="access"
      :userData="userData"
      :userItems="userItems"
    />

    <div class="columns">
      <div class="column is-half">
        <Details2
          :userData="userData"
        />
      </div>
      <template v-if="access.Fld_Amount">
        <div class="column is-half">
          <Total :data="userData"/>
        </div>
      </template>
    </div>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import Delivery from "./PurE_Delivery.vue";
import Details from "./PurE_Details.vue";
import Details2 from "./PurE_Details2.vue";
import Items from "./PurE_Items.vue";
import Total from "./PurE_Total.vue";

export default {
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Total
  },
  mixins: [InputPageMixin],
  data: () => ({
    access: {},
    userData: {},
    userItems: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.Edit.setItemsByData(this.userItems, data.Details.Items);
      Model.Edit.setDetailsByData(this.userData, data.Details,
        this.warehouseOptions.rows, data.ReceiveCount
      );
      Model.updateDetails(this.userData, this.userItems);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData = Model.createDetails();
      this.userItems = [];
    }
  }
}
</script>