<template>
  <div class="block">
    <kst-output field="PaymentTypeCash" :data="userData" :catalog="Catalog"/>
    <kst-input field="CashReceived" :data="userData" :catalog="Catalog">
      <template #left>
        <div class="control">
          <kst-button fullReceivedMode @click="handlePaymentValue_FullReceived"/>
        </div>
      </template>
    </kst-input>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SlrcCatalog.js";
import Model from "../SlrcModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handlePaymentValue_FullReceived() {
      Model.New.setDetailsByFullReceived(this.userData);
    }
  }
}
</script>