<template>
  <kst-section info-mode collapsed
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help="sectionHelp"
    :help-collapsed="sectionHelp"
  >
    <template v-if="rtnReceiveList.length > 0">
      <RtnReceiveRelatedList2 :dataList="rtnReceiveList"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../PurCatalog.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";

import RtnReceiveRelatedList2 from "../../RtnReceive/RelatedList2/RrcvRL2.vue"

export default {
  components: {
    RtnReceiveRelatedList2
  },
  props: {
    rtnReceiveList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.rtnReceiveList.length > 0;
    },
    sectionHelp() {
      return Model.Delete.getRtnReceiveSectionHelp(this.rtnReceiveList);
    },
    sectionTitle() {
      return Info.Section.RtnReceive.Title;
    }
  }
}
</script>