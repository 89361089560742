import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  CategoryID: {
    Label: AppCatalog.Field.Category.Label,
    ExportLabel: AppCatalog.Field.Name.Label + " " +
      AppCatalog.Field.Category.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "CategoryName"
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  ExpenseNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.Expense.FullName,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  SubCategoryID: {
    Label: AppModule.ExpenseCategory.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " +
      AppModule.ExpenseCategory.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "SubCategoryName"
  },
  SubCategoryName: {
    Label: AppModule.ExpenseCategory.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    MinValue: AppCatalog.Field.PaymentValue.MinValue,
    MaxLength: AppCatalog.Field.PaymentValue.MaxLength,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    Required: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  WarehouseName: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.Expense.FullName,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}