<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Ref :details="details"/>
        <Details :details="details"/>
        <PackagingList :details="details"/>
        <Variant :details="details"/>
      </b-tab-item>
      <b-tab-item :label="tabs.RefList">
        <ItemRelatedList :dataList="refList" :refId="refId"/>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import Details from "./ItemD_Details.vue";
import PackagingList from "./ItemD_PackagingList.vue";
import Ref from "./ItemD_Ref.vue";
import Variant from "./ItemD_Variant.vue";
import ItemRelatedList from "../RelatedList/ItemRL.vue";

export default {
  components: {
    Details,
    PackagingList,
    Ref,
    Variant,
    ItemRelatedList
  },
  data: () => ({
    actions: [],
    details: {},
    refList: [],
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      RefList: Info.Tabs.RefList
    }
  }),
  computed: {
    refId() {
      return this.details.RefID !== null ? this.details.RefID : this.details.ID;
    }
  },
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      this.refList = data.RefList;
    },

    /*** custom ***/

    resetData(id) {
      const access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.activeTab = 0;
      this.actions = Model.Details.getActions(id, access);
    }
  }
}
</script>