<template>
  <kst-form v-if="purchaseId" no-bezel class="block">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :has-access="access.Act_New"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
        :urlParams="urlParams"
      />
    </template>
  </kst-form>
</template>

<script>
import Model from "../RcvModel.js";

export default {
  props: {
    access: Object,
    purchaseId: Number
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.PurchaseID]: this.purchaseId
      });
    }
  }
}
</script>