import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.List
    }
  },
  Chart: {
    Datasets: {
      Label: AppCatalog.Info.Profit + " Penjualan",
      BackgroundColor: "#42b983"
    },
    Height: AppCatalog.Chart.Canvas.Height,
    ScaleLabel: {
      FontColor: "black",
      FontSize: 20,
      FontStyle: "bold"
    },
    Threshold: {
      BorderColor: "red",
      BorderWidth: 2,
      DrawTime: "afterDraw",
      Mode: "horizontal",
      ScaleID: "y-axis-0",
      Type: "line"
    },
    Type: "bar"
  },
  Export: {
    Excel: {
      // file
      SheetName: {
        Report: AppModule.ProfitLoss.FullName
      },
      // ui
      Option: {
        Report: AppModule.ProfitLoss.FullName
      }
    }
  },
  Section: {
    Summary: {
      Title: AppCatalog.Info.NettProfit
    }
  },
  Transaction: {
    InvoiceHpp: AppCatalog.Field.Hpp.Label + " Penjualan"
  }
}