<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StopItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="StopItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StopItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StopItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="StopItemCatalog"/>
      </kst-value>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="StopItemCatalog.HppTotal.Label"
      >
        <kst-value
          :field="column.field" :data="row" :catalog="StopItemCatalog"
        />
      </b-table-column>
    </template>
  </kst-print-table>
</template>

<script>
import StopItemCatalog from "../StopItemCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    StopItemCatalog: StopItemCatalog
  })
}
</script>