<template>
  <div class="block">
    <kst-input 
      field="InvoiceDate" 
      :data="userData" 
      :catalog="Catalog"
      :customValidations="invoiceDateValidations"
    />

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";

export default {
  props: {
    invoiceValidation: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    invoiceDateValidations() {
      return Model.EditDate.validationInvoiceDate(this.invoiceValidation);
    },
  }
}
</script>