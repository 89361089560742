<template>
  <div class="kstmod-stockreduction-relatedlist">
    <template v-if="action">
      <template v-if="access.Act_New">
        <Action />
      </template>
    </template>

    <Rows :access="access" :dataList="dataList"/>
  </div>
</template>

<script>
import Model from "../StrdModel.js";

import Rows from "./StrdRL_Rows.vue";
import Action from "../RelatedListAction/StrdRLAction.vue";

export default {
  components: {
    Action,
    Rows
  },
  props: {
    action: Boolean,
    dataList: Array
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      for (const row of this.dataList) {
        for (const item of row.Items) {
          Model.updateItem(item);
        }
      }
    }
  }
}
</script>