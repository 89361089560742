<template>
  <kst-table view-mode
    :action="Model.RelatedList.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column
      cell-class="ks-col-image"
      v-slot="{ column, row }"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-barcode"
      field="Barcode"
      :label="Catalog.Barcode.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="DefaultSellPrice"
      :label="Catalog.DefaultSellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      cell-class="ks-col-variant"
      field="Variant1"
      :label="Catalog.Variant1.Label"
    >
      {{ getVariantType1(row) }}
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      cell-class="ks-col-variant"
      field="Variant2"
      :label="Catalog.Variant2.Label"
    >
      {{ getVariantType2(row) }}
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.RelatedList.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";

export default {
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  methods: {
    getVariantType1(row) {
      return Model.getVariantSet1(row);
    },
    getVariantType2(row) {
      return Model.getVariantSet2(row);
    }
  }
}
</script>