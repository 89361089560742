<template>
  <kst-section items-mode class="mt-5">
    <StockSearchPcs sell-price hpp-per-pcs
      ref="mdlStockSearch"
      @submit="handleItem_Submit"
    />

    <template #top-right>
      <template v-if="userData.SourceWarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          :label="Model.StockTrfItem.Module.FullName"
          :moduleType="Model.StockTrfItem.ModuleType.New"
          @click="handleItem_Add"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <template v-if="userData.SourceWarehouseID !== null">
          <kst-button action-mode
            class="is-primary mt-4"
            :label="Model.StockTrfItem.Module.FullName"
            :moduleType="Model.StockTrfItem.ModuleType.New"
            @click="handleItem_Add"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.SourceWarehouseID !== null">
      <kst-failure data-mode class="mb-4" :items="failureItems"/>
      <List :userData="userData" :userItems="userItems"/>

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="mt-4" :items="userData.Errors"/>
    </template>
  </kst-section>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import Catalog from "../SttrCatalog.js";
import Model from "../SttrModel.js";
import Services from "../../../services/Api/StockTrfServices.js";

import List from "./SttrN_Items_List.vue";
import StockSearchPcs from "../../../components/Stock/SearchPcs/StkScP.vue";

export default {
  mixins: [AxiosMixin, LoadingMixin],
  components: {
    List,
    StockSearchPcs
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    itemLoading: null,
    failureItems: null
  }),
  methods: {
    loadItemDetails(itemId, item) {
      this.failureItems = null;
      this.itemLoading = this.openLoading();

      const config = Services.getRelatedItemDetails(itemId);
      this.runAxios(config, item,
        this.loadItemDetails_Success,
        this.loadItemDetails_Fault,
        this.loadItemDetails_Fault
      );
    },
    loadItemDetails_End() {
      this.closeLoading(this.itemLoading);
      this.itemLoading = null;
    },
    loadItemDetails_Success(data, item) {
      Model.New.setItemByItemDetails(item, data.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);
      this.loadItemDetails_End();
    },
    loadItemDetails_Fault(message) {
      this.failureItems = message;
      this.loadItemDetails_End();
    },

    handleItem_Add() {
      this.failureItems = null;
      this.$refs.mdlStockSearch.show(
        this.userData.SourceWarehouseID,
        Services.getRelatedStockList
      );
    },
    handleItem_Submit(row) {
      let item = Model.createItem();
      Model.New.setItemByStock(item, row);
      this.loadItemDetails(row.ItemID, item);
    }
  }
}
</script>