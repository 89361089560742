<template>
  <kst-page-print-details
    @init="handleInit"
  >
    <kst-barcode
      :value="details.Barcode"
      :label="details.Name"
    />
  </kst-page-print-details>
</template>

<script>
import Services from "../../../services/Api/ItemServices.js";

export default {
  data: () => ({
    details: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintBarcodeData(id);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
    },

    /*** custom ***/

    resetData() {
      this.details = {};
    }
  }
}
</script>

<style lang="css" src="./_layout.css"/>