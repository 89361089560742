<template>
  <kst-page-print pageFooter
    v-slot="{ title }"
    :catalog="DaiSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <Items
      class="block"
      :dataList="dataList"
      :dataSummary="dataSummary"
      :searchData="searchData"
    />
  </kst-page-print>
</template>

<script>
import DaiSearchCatalog from "../DaiSearchCatalog.js";
import Model from "../DaiModel.js";
import Services from "../../../services/Api/DailyServices.js";

import Items from "./DaiP_Items.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Items,
    Company
  },
  data: () => ({
    DaiSearchCatalog: DaiSearchCatalog,
    Model: Model,
    companyData: {},
    dataList: [],
    dataSummary: {},
    searchData: {
      Date: null,
      SearchResultID: Model.SearchResult.HasTransaction.ID,
      WarehouseID: null
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;
      this.dataSummary = data.Summary;
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.dataList = [];
      this.dataSummary = {};
    },

    resetSearch() {
      this.searchData.Date = this.$kst.In.getDateToday();
      this.searchData.SearchResultID = Model.SearchResult.HasTransaction.ID;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        date: this.$kst.Search.getValue(this.searchData.Date),
        searchresult: this.$kst.Search.getValue(this.searchData.SearchResultID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID)
      };
    }
  }
}
</script>