import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label,
    Type: AppCatalog.Field.Barcode.Type,
    MaxLength: 50,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  DefaultSellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    Required: true,
    Highlight: true
  },
  LimitQty: {
    Label: AppCatalog.Field.LimitQty.Label,
    Type: AppCatalog.Field.LimitQty.Type,
    ReadOnly: true
  },
  Quantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    MaxLength: 45,
    Required: true,
    Highlight: true
  },
  WarehouseName: {
    Label: AppModule.Warehouse.Name,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
}