import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./ExpcModel_Body.js";
import ModelEdit from "./ExpcModel_Edit.js";
import ModelNew from "./ExpcModel_New.js";

export default {
  // required in model
  Module: AppModule.ExpenseCategory,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    New: AppModuleType.New
  },

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  IsActive: ModelBody.IsActive,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.IsActive);
  },
  createOptions(dataList, optionAllText) {
    return ModelBody.createOptions(dataList, optionAllText);
  },
  createCategoryOptions(categoryList, optionAllText) {
    return ModelBody.createCategoryOptions(categoryList, optionAllText);
  },
  createIsActiveOptions(optionAllText) {
    return ModelBody.createIsActiveOptions(optionAllText, ModelBody.IsActive);
  },

  getDefault(dataOptions, defaultId) {
    return ModelBody.getDefault(dataOptions, defaultId);
  },
  getCategoryDefault(categoryOptions, defaultId) {
    return ModelBody.getCategoryDefault(categoryOptions, defaultId);
  },

  populateData(userData) {
    return ModelBody.populateDetails(userData);
  },

  /*** method - new ***/

  New: {
    setDetailsByCategoryOptions(userData, categoryOptions) {
      ModelNew.setDetailsByCategoryOptions(userData, categoryOptions);
    }
  },

  /*** method - edit ***/

  Edit: {
    setDetailsByData(userData, details) {
      ModelEdit.setDetailsByData(userData, details);
    }
  },
}