<template>
  <kst-table action paginated view-mode
    :data="items" 
    :selected.sync="userData.selected"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="ReceiveNumber"
      :label="Catalog.ReceiveNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VendorID"
      :label="Catalog.VendorID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-ref"
      field="PurchasingNumber"
      :label="Catalog.PurchasingNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="RcvItemCatalog.PackagingName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog">
        ({{ row.PackagingValue }})
      </kst-value>
    </b-table-column>

    <b-table-column numeric sortable
      cell-class="ks-col-price has-text-weight-bold"
      field="BuyPrice"
      :label="RcvItemCatalog.BuyPrice.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcvItemCatalog"/>
    </b-table-column>

    <template #action="{ row }">
      <PriceIcon :row="row" :buyPrice="buyPrice"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import RcvItemCatalog from "../RcvItemCatalog.js";

import PriceIcon from "./RcvScLPrice_List_Icon.vue";

export default {
  components: {
    PriceIcon
  },
  props: {
    buyPrice: Number,
    items: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    RcvItemCatalog: RcvItemCatalog
  })
}
</script>