<template>
  <table class="ks-is-fullwidth">
    <tr>
      <td>
        {{ Catalog.Total.Label }}
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="Total" 
          :data="details" :catalog="Catalog"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ Catalog.TotalItems.Label }}
      </td>
      <td class="is-flex is-justify-content-end">
        <kst-value class="print-thermal-size ks-space-right"
          field="TotalItems" :data="details" :catalog="Catalog"
        />
        {{ Catalog.TotalItems.HelpLabel }}
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "../PurCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>