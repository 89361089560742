import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./PackagingCatalog.js";

export default {
  // required in model
  Module: AppModule.ItemPackaging,

  /*** related ***/

  /*** property ***/

  IsActive: {
    Active: {
      ID: 1,
      Label: App.Enum.Status.Active.Label
    },
    InActive: {
      ID: 0,
      Label: App.Enum.Status.InActive.Label
    }
  },

  FirstPackaging: {
    Name: AppCatalog.PackagingPcs.Name,
    Qty: AppCatalog.PackagingPcs.Qty
  },

  /*** method ***/

  createDetails(userData, isFirst) {
    return isFirst
      ? this.helper.createFirstDetails(userData, this.FirstPackaging)
      : this.helper.createDetails(userData);
  },
  createOptions(userPackagingList) {
    return this.helper.createOptions(userPackagingList);
  },
  createActiveOptions(userPackagingList) {
    return this.helper.createActiveOptions(userPackagingList);
  },
  createDefaultOptions(userPackagingList) {
    return this.helper.createDefaultOptions(userPackagingList);
  },

  setListByData(userPackagingList, userData, details) {
    this.helper.setListByData(userPackagingList, userData, details);
  },

  setList_NewRef(userPackagingList, userData, refDetails) {
    this.helper.setList_NewRef(userPackagingList, userData, refDetails);
  },

  getData(userPackagingList, name) {
    return this.helper.getData(userPackagingList, name);
  },

  getErrors(userPackagingList) {
    return this.helper.getErrors(userPackagingList, this.IsActive);
  },

  setErrors(userPackaging, field, errors) {
    this.helper.setErrors(userPackaging, field, errors);
  },

  helper: {
    createDetails(userData) {
      const id = userData.NextPackagingID;
      userData.NextPackagingID--;

      return {
        ID: id,
        Name: "",
        Qty: "",
        SellPrice: "",
        Weight: "",
        IsActive: true,
        // error
        Errors: [],
        ErrorsColl: {},
        // UI validation
        Name_Valid: true
      };
    },
    createFirstDetails(userData, firstPackaging) {
      let details = this.createDetails(userData);
      details.Name = firstPackaging.Name;
      details.Qty = App.In.getInteger(firstPackaging.Qty);

      return details;
    },
    createOptions(userPackagingList) {
      const packagingList = [];

      for (const row of userPackagingList) {
        row.Label = App.Data.getPackaging(row.Name, row.Qty);
        packagingList.push(row);
      }

      return App.In.getSelectOptions("Name", "Label", packagingList);
    },
    createActiveOptions(userPackagingList) {
      const packagingList = [];

      for (const row of userPackagingList) {
        if (row.IsActive) {
          row.Label = App.Data.getPackaging(row.Name, row.Qty);
          packagingList.push(row);  
        }
      }

      return App.In.getSelectOptions("Name", "Label", packagingList);
    },
    createDefaultOptions(userPackagingList) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      // populate: items
      let items = [];
      for (let row of userPackagingList) {
        if (row.IsActive) {
          items.push(row);
        }
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },

    setListByData(userPackagingList, userData, details) {
      let newDetails;
      App.Array.truncate(userPackagingList);

      for (const row of details.PackagingList) {
        newDetails = this.createDetails(userData);

        newDetails.ID = App.In.getInteger(row.ID);
        newDetails.Name = App.In.getString(row.Name);
        newDetails.Qty = App.In.getInteger(row.Qty);
        newDetails.SellPrice = App.In.getInteger(row.SellPrice);
        newDetails.Weight = App.In.getInteger(row.Weight);
        newDetails.IsActive = App.In.getBoolean(row.IsActive);

        userPackagingList.push(newDetails);
      }
    },

    setList_NewRef(userPackagingList, userData, refDetails) {
      let newDetails;

      for (const row of refDetails.PackagingList) {
        newDetails = this.createDetails(userData);

        newDetails.Name = App.In.getString(row.Name);
        newDetails.Qty = App.In.getInteger(row.Qty);
        newDetails.SellPrice = App.In.getInteger(row.SellPrice);
        newDetails.Weight = App.In.getInteger(row.Weight);

        userPackagingList.push(newDetails);

        if (row.ID === refDetails.DefaultPackagingID) {
          userData.DefaultPackagingID = newDetails.ID;
        }
      }
    },

    getData(userPackagingList, name) {
      return App.Array.searchItem(userPackagingList, "Name", name);
    },

    getErrors(userPackagingList, isActiveEnum) {
      let result = { active: false, error: [] };
      let errors = [];
      let packagingNameList = [];
      let hasActive = false;

      for (let packaging of userPackagingList) {
        // validate: Name
        if (packagingNameList.includes(packaging.Name)) {
          packaging.Name_Valid = false;
        }
        else {
          packaging.Name_Valid = true;
          packagingNameList.push(packaging.Name);
        }

        // validate: Name
        if (packaging.IsActive) {
          hasActive = true;
        }
      }

      result.active = hasActive;

      // validate: has active packaging
      if (!hasActive) {
        errors.push(AppCatalog.Message.MustHave + " " +
          AppModule.ItemPackaging.FullName + " yang " + isActiveEnum.Active.Label
        );
      }

      result.error = errors;

      return result;
    },

    setErrors(userPackaging, field, errors) {
      // populate error in current field
      userPackaging.ErrorsColl[field] = [];

      for (const error of errors) {
        if (error !== App.Vee.ValidRule) {
          userPackaging.ErrorsColl[field].push(error);
        }
      }

      // populate error in all fields
      let fieldErrors, newErrors = [];
      for (const fieldName in userPackaging.ErrorsColl) {
        fieldErrors = userPackaging.ErrorsColl[fieldName];

        if (Array.isArray(fieldErrors)) {
          newErrors = newErrors.concat(fieldErrors);
        }
      }

      // custom error message
      if (!userPackaging.Name_Valid) {
        newErrors.push(Catalog.Name.Label + " " + AppCatalog.Message.InUse);
      }

      userPackaging.Errors = newErrors;
    }
  }
}