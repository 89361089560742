import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../InvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.SONumber.Label + ", atau " +
      Catalog.PONumber.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  },

  Client: {
    Label: Catalog.ClientID.Label,
    Type: Catalog.Client.Type,
    MaxLength: Catalog.Client.MaxLength
  },
  Date: {
    Label: Catalog.InvoiceDate.Label,
    Type: Catalog.InvoiceDate.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true
  }
}