import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import RccdEx from "./Export/RccdEx.vue";
import RccdP from "./Print/RccdP.vue";
import RccdR from "./Report/RccdR.vue";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.RecCard)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RecCard, AppModuleType.Export),
        name: RouterModel.getName(AppModule.RecCard, AppModuleType.Export),
        component: RccdEx,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecCard, AppModuleType.Export),
          module: RouterModel.getModule(AppModule.RecCard),
          moduleattr: AppModuleAttr.RecCard_Act_Export,
          moduletype: AppModuleType.Export
        }
      },
      {
        path: RouterModel.getPath(AppModule.RecCard),
        name: RouterModel.getName(AppModule.RecCard),
        component: RccdR,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecCard),
          module: RouterModel.getModule(AppModule.RecCard),
          moduleattr: AppModuleAttr.RecCard_Act_Report,
          moduletype: AppModuleType.Report,
          // actions
          export: {
            module: RouterModel.getModule(AppModule.RecCard),
            moduleattr: AppModuleAttr.RecCard_Act_Export,
            moduletype: AppModuleType.Export
          },
          print: {
            module: RouterModel.getModule(AppModule.RecCard),
            moduleattr: AppModuleAttr.RecCard_Act_Print,
            moduletype: AppModuleType.Print
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RecCard, AppModuleType.Print),
        name: RouterModel.getName(AppModule.RecCard, AppModuleType.Print),
        component: RccdP,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RecCard, AppModuleType.Print),
          module: RouterModel.getModule(AppModule.RecCard),
          moduleattr: AppModuleAttr.RecCard_Act_Print,
          moduletype: AppModuleType.Print
        }
      }
    ];
  }
}