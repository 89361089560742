import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.receive;

export default {
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getEditDateData(id, currentDate) {
    const params = { id, currentdate: currentDate }
    const attributes = ["id", "currentdate"];
    const urlParams = App.Out.getParams(params, attributes);
    
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdatedata" + urlParams
    };
  },
  getExportDetails(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails?id=" + id
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "vendor", "vendorname",
      "warehouse", "search", "field", "withitems"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "vendor", "vendorname",
      "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "vendor", "vendorname",
      "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData(params) {
    const attributes = ["purchase"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata" + urlParams
    };
  },
  getPrintDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "vendor", "vendorname",
      "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getRelatedItemDetails(itemId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/details?item=" + itemId
    };
  },
  getRelatedPurchaseDetails(purchaseId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/purchase/details?purchase=" + purchaseId
    };
  },
  getRelatedPurchaseList(params) {
    const attributes = ["warehouse", "startdate", "enddate", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/purchase/list" + urlParams
    };
  },
  getRelatedReceivePriceList(params) {
    const attributes = ["item", "startdate", "enddate", "vendor", "vendorname",
      "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/receive-price/list" + urlParams
    };
  },
  getRelatedStockList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stock/list" + urlParams
    };
  },
  getRelatedVendorAddressList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendor-address/list" + urlParams
    };
  },
  getRelatedVendorItemList(params) {
    const attributes = ["vendor"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendoritem/list" + urlParams
    };
  },
  getRelatedVendorList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendor/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  },
  putDate(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/date",
      data: data
    };
  }
}