<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <ItemsRaw
          :access="access"
          :details="details"
          :itemsRaw="details.ItemsRaw"
        />
        <ItemsFinish
          :access="access"
          :details="details"
          :itemsFinish="details.ItemsFinish"
        />
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import Details from "./StcvD_Details.vue";
import ItemsFinish from "./StcvD_ItemsFinish.vue";
import ItemsRaw from "./StcvD_ItemsRaw.vue";

export default {
  components: {
    Details,
    ItemsFinish,
    ItemsRaw
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;

      for (const item of this.details.ItemsFinish) {
        Model.updateItem(item);
      }
      for (const item of this.details.ItemsRaw) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.activeTab = 0;

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>