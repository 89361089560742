<template>
  <kst-page-export
    :catalog="StcdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :searchData="searchData"
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithItemDetails"
      :label="Info.Export.Excel.Option.ItemDetails"
    />
    <kst-check
      v-model="userData.WithStockList"
      :label="Info.Export.Excel.Option.StockList"
    />
    <kst-check
      v-model="userData.WithTransSummary"
      :label="Info.Export.Excel.Option.TransSummary"
    />
    <kst-check
      v-model="userData.WithTransList"
      :label="Info.Export.Excel.Option.TransList"
    />
  </kst-page-export>
</template>

<script>
import Info from "../StcdInfo.js";
import Model from "../StcdModel.js";
import StcdSearchCatalog from "../StcdSearchCatalog.js";
import Services from "../../../services/Api/StockCardServices.js";

import Export from "./StcdEx_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    StcdSearchCatalog: StcdSearchCatalog,
    access: {},
    searchData: {
      Date: null,
      ItemID: null,
      WarehouseID: null
    },
    userData: {
      WithItemDetails: true,
      WithStockList: true,
      WithTransSummary: true,
      WithTransList: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = {
        item: this.$kst.Out.getInteger(this.searchData.ItemID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        withstocklist: this.$kst.Out.getBoolean(this.userData.WithStockList),
        withtransactionsummary: this.$kst.Out.getBoolean(
          this.userData.WithTransSummary),
        withtransactionlist: this.$kst.Out.getBoolean(
          this.userData.WithTransList)
      };

      const config = Services.getExport(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithItemDetails = true;
      this.userData.WithStockList = true;
      this.userData.WithTransSummary = true;
      this.userData.WithTransList = true;
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>