<template>
  <div
    v-if="anySlotVisible"
    class="kst-field field is-relative is-horizontal kst-input mb-2"
  >
    <div class="field-label is-normal">
      <slot name="label"/>
    </div>
    <template v-if="defaultSlotVisible">
      <div class="field-body">
        <div class="ks-is-fullwidth ">
          <div class="field">
            <div class="control">
              <slot />
            </div>
            <kst-errors-helper :items="errors"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SlotMixins from "../../../mixins/SlotMixins.js";

export default {
  mixins: [SlotMixins],
  props: {
    errors: [Array, String]
  },
  computed: {
    anySlotVisible() {
      return this.defaultSlotVisible || this.labelSlotVisible;
    },
    defaultSlotVisible() {
      return this.slotHasContent("default");
    },
    labelSlotVisible() {
      return this.slotHasContent("label");
    }
  }
}
</script>