import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./SpadModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " +
            AppModule.SpecialPriceAdj.SheetName
        },
        Option: {
          List: AppModuleType.List.FullName + " " +
            AppModule.SpecialPriceAdj.FullName,
          SpadItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin ingin menghapus " + AppModule.Item.FullName + "?"
  },
  Section: {
    Scanner: {
      Title: AppCatalog.Section.Scanner + " " + AppCatalog.Field.Barcode.Label,
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}