import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    Required: true,
    MaxLength: 100,
    Highlight: true
  }
}