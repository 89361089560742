<template>
  <div>
    <template v-for="transGroup in transGroupObj">
      <div :key="transGroup.WarehouseID" class="is-flex block mb-2">
        <div class="ks-space-right has-text-weight-semibold">
          {{ StcdTransListCatalog.WarehouseID.Label }}:
        </div>
        <kst-value field="WarehouseID" :data="transGroup" :catalog="StcdTransListCatalog"/>
      </div>

      <kst-table action paginated view-mode
        class="block"
        :data="transGroup.TransactionList"
        :key="transGroup.WarehouseID + 'table'"
      >
        <b-table-column sortable
          v-slot="{ column, row }"
          cell-class="ks-col-shortdatetime"
          field="CreatedDate"
          :label="StcdTransListCatalog.CreatedDate.Label"
        >
          <kst-value :field="column.field" :data="row" :catalog="StcdTransListCatalog"/>
        </b-table-column>

        <b-table-column sortable
          v-slot="{ row }"
          field="ActivityTypeID"
          :label="StcdTransListCatalog.ActivityTypeName.Label"
        >
          {{ getActivityTypeName(row) }}
        </b-table-column>

        <b-table-column numeric sortable
          v-slot="{ column, row }"
          cell-class="ks-col-qty-in-out"
          field="Changes"
          :label="StcdTransListCatalog.Changes.Label"
        >
          <template v-if="row[column.field] === 0">
            -
          </template>
          <template v-else>
            <kst-value :field="column.field" :data="row" :catalog="StcdTransListCatalog"/>
          </template>
        </b-table-column>

        <template v-if="access.Glob_HPP">
          <b-table-column numeric sortable
            v-slot="{ column, row }"
            cell-class="ks-col-hpptotal-in-out"
            field="HppChanges"
            :label="StcdTransListCatalog.HppChanges.Label"
          >
            <kst-value :field="column.field" :data="row" :catalog="StcdTransListCatalog"/>
          </b-table-column>
        </template>

        <b-table-column numeric sortable
          v-slot="{ column, row }"
          cell-class="ks-col-qty"
          field="QuantityAfter"
          :label="StcdTransListCatalog.QuantityAfter.Label"
        >
          <template v-if="row[column.field] === 0">
            -
          </template>
          <template v-else>
            <kst-value :field="column.field" :data="row" :catalog="StcdTransListCatalog"/>
          </template>
        </b-table-column>

        <template v-if="access.Glob_HPP">
          <b-table-column numeric sortable
            v-slot="{ column, row }"
            cell-class="ks-col-hpptotal"
            field="HppAfter"
            :label="StcdTransListCatalog.HppAfter.Label"
          >
            <kst-value :field="column.field" :data="row" :catalog="StcdTransListCatalog"/>
          </b-table-column>
        </template>

        <template #action="{ row }">
          <template v-if="getHasAccess(row)">
            <kst-tooltip details-mode hover-only v-if="row.TransactionID !== null">
              <kst-button action-mode
                class="ks-mt--2"
                :class="Info.Button.Transaction.Class"
                :label="Info.Button.Transaction.Label"
                :module="getModule(row)"
                :moduleType="Info.Button.Transaction.ModuleType"
                :urlParams="getUrlParams(row)"
              />
            </kst-tooltip>
          </template>
        </template>
      </kst-table>
    </template>
  </div>
</template>

<script>
import Info from "../StcdInfo.js";
import StcdTransListCatalog from "../StcdTransListCatalog.js";
import Model from "../StcdModel.js";

export default {
  props: {
    access: Object,
    transGroupObj: Object
  },
  data: () => ({
    Info: Info,
    StcdTransListCatalog: StcdTransListCatalog
  }),
  methods: {
    getActivityTypeName(row){
      return Model.getTransactionActivityTypeName(row);
    },
    getHasAccess(row) {
      return Model.getTransactionHasAccess(row, this.access);
    },
    getModule(row) {
      return Model.getTransactionModule(row);
    },
    getUrlParams(row) {
      return Model.getTransactionUrlParams(row);
    }
  }
}
</script>