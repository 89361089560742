<template>
  <kst-section :title="Info.Section.Address.Title">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Model.VendorAddress.Module.FullName"
        :moduleType="Model.VendorAddress.ModuleType.New"
        @click="handleAdd_address"
      />
    </template>

    <List :userAddressList="userAddressList"/>
  </kst-section>
</template>

<script>
import Info from "../VenInfo.js";
import Model from "../VenModel.js";

import List from "./VenN_AddressList_List.vue";

export default {
  components: {
    List
  },
  props: {
    userAddressList: Array
  },
  data: () => ({
    Info: Info,
    Model: Model
  }),
  methods: {
    handleAdd_address() {
      const address = Model.createAddress();
      this.userAddressList.push(address);
    }
  }
}
</script>