import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./WhsModel_Body.js";
import ModelEdit from "./WhsModel_Edit.js";

export default {
  // required in model
  Module: AppModule.Warehouse,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    List: AppModuleType.List,
    New: AppModuleType.New
  },

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  IsActive: ModelBody.IsActive,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.IsActive);
  },
  createOptions(dataList, optionAllText, optionSomeText) {
    return ModelBody.createOptions(dataList, optionAllText, optionSomeText);
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.IsActive);
  },

  getDefault(dataList, defaultId) {
    return ModelBody.getDefault(dataList, defaultId);
  },

  populateData(userData) {
    return ModelBody.populateDetails(userData);
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, warehouseData) {
      ModelEdit.setDetailsByData(userData, warehouseData);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit;
    }
  },
}