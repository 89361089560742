import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import DbpyD from "./Details/DbpyD.vue";
import DbpyES from "./EditStatus/DbpyES.vue";
import DbpyExD from "./ExportDetails/DbpyExD.vue";
import DbpyExL from "./ExportList/DbpyExL.vue";
import DbpyL from "./List/DbpyL.vue";
import DbpyN from "./New/DbpyN.vue";
import DbpyPD from "./PrintDetails/DbpyPD.vue";
import DbpyPL from "./PrintList/DbpyPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.DebtPay, AppModuleType.Details),
    EditStatus: RouterModel.getPath(AppModule.DebtPay, AppModuleType.EditStatus),
    ExportDetails: RouterModel.getPath(AppModule.DebtPay, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.DebtPay, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.DebtPay, AppModuleType.List),
    New: RouterModel.getPath(AppModule.DebtPay, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.DebtPay, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.DebtPay, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.Details),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.Details),
        component: DbpyD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.EditStatus),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.EditStatus),
        component: DbpyES,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.EditStatus),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_EditStatus,
          moduletype: AppModuleType.EditStatus,
          cancel: {
            url: RouterModel.getPath(AppModule.DebtPay, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.DebtPay, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.ExportDetails),
        component: DbpyExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.ExportList),
        component: DbpyExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.List),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.List),
        component: DbpyL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.List),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.DebtPay),
            moduleattr: AppModuleAttr.DebtPay_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.DebtPay),
            moduleattr: AppModuleAttr.DebtPay_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.DebtPay),
            moduleattr: AppModuleAttr.DebtPay_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.New),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.New),
        component: DbpyN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.New),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.DebtPay, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.DebtPay, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.PrintDetails),
        component: DbpyPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtPay, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.DebtPay, AppModuleType.PrintList),
        component: DbpyPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtPay, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.DebtPay),
          moduleattr: AppModuleAttr.DebtPay_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}