<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SlrcCatalog.js";
import Info from "../SlrcInfo.js";
import Model from "../SlrcModel.js";
import SlrcInvCatalog from "../SlrcInvCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.RecapNumber);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.CashierID.Label);
      this.addColumnHeader(Catalog.CashierID);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.CashReceived);
      this.addColumnHeader(Catalog.Comment);

      if (this.userData.WithInvoice) {
        this.addColumnHeader(SlrcInvCatalog.SONumber);
        this.addColumnHeader(SlrcInvCatalog.InvoiceDate);
        this.addColumnHeader_ID(SlrcInvCatalog.ClientID.Label);
        this.addColumnHeader(SlrcInvCatalog.Client);
        this.addColumnHeader(SlrcInvCatalog.ClientAddress);
        this.addColumnHeader(SlrcInvCatalog.PONumber);
        this.addColumnHeader(SlrcInvCatalog.InvoiceTotal);
        this.addColumnHeader(SlrcInvCatalog.PaymentTypeName);
        this.addColumnHeader(SlrcInvCatalog.Status);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("RecapNumber", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("CashierID", row, Catalog);
        this.addCellRelatedValue("CashierID", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellValue("CashReceived", row, Catalog);
        this.addCellValue("Comment", row, Catalog);
        
        if (this.userData.WithInvoice) {
          this.addCellValue("SONumber", row, SlrcInvCatalog);
          this.addCellValue("InvoiceDate", row, SlrcInvCatalog);
          this.addCellValue("ClientID", row, SlrcInvCatalog);
          this.addCellValue("Client", row, SlrcInvCatalog);
          this.addCellValue("ClientAddress", row, SlrcInvCatalog);
          this.addCellValue("PONumber", row, SlrcInvCatalog);
          this.addCellValue("InvoiceTotal", row, SlrcInvCatalog);
          this.addCellValue("PaymentTypeName", row, SlrcInvCatalog);
          this.addCellStatus(row.Status, Model.Status);    
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>