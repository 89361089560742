import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  AdjNumber: {
    Label: AppCatalog.Field.Number.Label + " Penyesuaian",
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // validation
  StockMissingQty: {
    Label: AppCatalog.Field.QtyStock.Label,
    Type: AppCatalog.Field.QtyStock.Type,
    MaxValue: 0
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Penyesuaian",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}