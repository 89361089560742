import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  CashierID: {
    Label: AppCatalog.Field.Cashier.Label,
    ExportLabel: AppCatalog.Field.Name.Label + " " +
      AppCatalog.Field.Cashier.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "CashierName",
    RelatedModule: AppModule.UserList,
    RelatedIsActive: "CashierIsActive"
  },
  CashReceived: {
    Label: AppCatalog.Field.CashReceived.Label,
    Type: AppCatalog.Field.CashReceived.Type,
    MinValue: AppCatalog.Field.CashReceived.MinValue,
    MaxLength: AppCatalog.Field.CashReceived.MaxLength,
    Output: AppCatalog.Field.CashReceived.Output,
    Required: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 1000
  },
  Date: {
    Label:  AppCatalog.Field.Date.Label,
    PrintLabel:  AppCatalog.Field.Date.Label + " direkap",
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  ID: {
    Label: AppCatalog.Field.Number.Label + " Rekap",
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "RecapNumber",
    RelatedModule: AppModule.SalesRecap
  },
  RecapNumber: {
    Label: AppCatalog.Field.Number.Label + " Rekap",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: AppCatalog.Field.Number.MaxLength,
    Highlight: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  InvoiceRecordTypeID: {
    Label: AppCatalog.Field.RecordTypeID.AltLabel + " " + AppModule.Invoice.FullName,
    Type: AppCatalog.Field.RecordTypeID.Type,
    Input: AppCatalog.Field.RecordTypeID.Input
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Rekap" ,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  PaymentTypeCash: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Cash.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  }
}