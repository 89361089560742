<template>
  <div>
    <VendorSearchList
      @init="handleVendor_Init"
      @submit="handleVendor_Submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-narrow">
        <kst-output addons floating-label
          field="VendorID" :data="searchData" :catalog="DbcdSearchCatalog"
        >
          <template #right>
            <div class="control">
              <template v-if="searchData.VendorID === null">
                <kst-tooltip search-mode hover-only :content="DbcdSearchCatalog.VendorID.Label">
                  <kst-button search-mode @click="handleSearch_Vendor"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="DbcdSearchCatalog.VendorID.Label">
                  <kst-button clear-mode @click="handleClear_Vendor"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="TypeID" :data="searchData" :catalog="DbcdSearchCatalog"
          :options="typeOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="DbcdSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="TransactionDate" :data="searchData" :catalog="DbcdSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Model from "../DbcdModel.js";
import DbcdSearchCatalog from "../DbcdSearchCatalog.js";
import Services from "../../../services/Api/DebtCardServices.js";

import VendorSearchList from "../../Vendor/SearchList/VenScL.vue";

export default {
  components: {
    VendorSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    DbcdSearchCatalog: DbcdSearchCatalog,
    // func
    showVendorFunc: null
  }),
  computed: {
    typeOptions() {
      return Model.createTypeOptions(DbcdSearchCatalog.TypeID.Label);
    }
  },
  methods: {
    /*** by vendor modal ***/

    handleVendor_Init(show) {
      this.showVendorFunc = show;
    },
    handleVendor_Submit(vendorData) {
      this.searchData.VendorID = vendorData.ID;
      this.searchData.VendorName = vendorData.Name;

      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_Vendor() {
      this.showVendorFunc(Services.getRelatedVendorList);
    },
    handleClear_Vendor() {
      this.searchData.VendorID = null;
      this.searchData.VendorName = null;

      this.handleInput();
    }
  }
}
</script>