<template>
  <div class="control is-expanded kst-value">
    <template v-if="theType === OutputTypeEnum.TextArea">
      <div class="ks-text-area">{{ theValue }}</div>
    </template>
    <template v-else>
      <template v-if="ellipsis">
        <div class="is-hidden-mobile">
          <template v-if="catalog[field].Highlight">
            <template v-if="hasValue">
              <div class="ks-ellipsis is-mobile" :class="theClass" v-html="theValue">
                {{ thePostfixSign }}
              </div>
            </template>
          </template>
          <template v-else>
            <template v-if="hasValue">
              <div class="ks-ellipsis is-mobile" :class="theClass">
                {{ theValue }}{{ thePostfixSign }}
              </div>
            </template>
          </template>

          <template v-if="hasValue">
            <b-tooltip always multilined
              class="ks-ellipsis-tooltip ks-is-fullwidth"
              position="is-bottom"
              type="is-info"
              :label="theTooltipValue + thePostfixSign"
            />
          </template>
        </div>

        <template v-if="catalog[field].Highlight">
          <template v-if="hasValue">
            <div class="is-hidden-tablet" :class="theClass" v-html="theValue">
              {{ thePostfixSign }}
            </div>
          </template>
        </template>
        <template v-else>
          <template v-if="hasValue">
            <div class="is-hidden-tablet" :class="theClass">
              {{ theValue }}{{ thePostfixSign }}
            </div>
          </template>
        </template>
      </template>
      <template v-else-if="printMode">
        <div :class="theClass">{{ theValueRaw }}</div>
      </template>
      <template v-else>
        <template v-if="catalog[field].Highlight">
          <div :class="theClass" v-html="theValue">{{ thePostfixSign }}</div>
        </template>
        <template v-else>
          <div :class="theContainerClass">
            <div class="ks-line-break" :class="theClass">
              <template v-if="hasValue">{{ theValue }}{{ thePostfixSign }}{{ theWhiteSpace }}</template>
            </div>

            <slot />
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";
import ValueMixin from "@/mixins/ValueMixin.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import OutputTypeEnum from "@/services/Input/OutputTypeEnum.js";

export default {
  mixins: [SlotMixins, ValueMixin],
  props: {
    ellipsis: Boolean,
    // mode: ONLY select 1 (one)
    printMode: Boolean
  },
  data: () => ({
    OutputTypeEnum: OutputTypeEnum
  }),
  computed: {
    hasValue() {
      return this.theValue !== null;
    },
    theContainerClass() {
      return this.slotHasContent("default") ? "field has-addons" : "";
    },
    thePostfixSign() {
      if (this.theType === OutputTypeEnum.Percent) {
        return AppCatalog.PercentSign;
      }

      return "";
    },
    theWhiteSpace() {
      return this.slotHasContent("default") ? " " : "";
    }
  }
}
</script>