<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :title="Info.Dialog.SearchListPrice.Title"
    :width="1000"
    @init="handleModal_Init"
    @load="handleModal_Load"
    @reset="handleModal_Reset"
    @submit="handleModal_Submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :items="data.ReceiveList"
        :userData="userData"
        :buyPrice="buyPrice"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AppModule from "../../../services/App/AppModule.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Info from "../RcvInfo.js"
import Model from "../RcvModel.js";

import List from "./RcvScLPrice_List.vue";
import Search from "./RcvScLPrice_Search.vue";

export default {
  mixins: [SnackbarMixin],
  components: {
    List,
    Search
  },
  data: () => ({
    Info: Info,
    Model: Model,
    searchData: {
      Search: "",
      DispatchID: null,
      VendorID: null,
      Vendor: "",
      Date: null
    },
    buyPrice: 0,
    packagingList: [],
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(dispatchId, vendorId, vendorName, buyPrice, packagingList, getListService) {
      this.searchData.DispatchID = dispatchId;
      this.searchData.VendorID = vendorId;
      this.searchData.Vendor = vendorName;
      this.buyPrice = buyPrice;
      this.packagingList = packagingList;

      this.getListService = getListService;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_Load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_Reset(loadList) {
      this.init_Data();
      this.handleModal_Load(loadList);
    },

    handleModal_Submit(selected) {
      let isFound = false;
      for (const packaging of this.packagingList) {
        if (packaging.Name === selected.PackagingName) {
          isFound = true;
          break;
        }
      }

      if (!isFound) {
        this.notifyFormError(false, AppModule.ItemPackaging.FullName + " " +
          selected.PackagingName + " " + AppCatalog.Message.NotAvailable
        );
        return;
      }

      this.$emit(this.$kst.Enum.Event.Submit, selected);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.Vendor) : null;

      return {
        item: this.$kst.Out.getInteger(this.searchData.DispatchID),
        vendor: vendorId,
        vendorname: vendorName,
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    },

    init_Data() {
      // searchData
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getThreeMonthDay();
    }
  }
}
</script>