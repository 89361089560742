<template>
  <kst-table action input-mode :data="userPackagingList">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="PackagingCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :index="index" @input="handleName" @error="handleErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="PackagingCatalog.Qty.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :disabled="index === 0" :index="index" @error="handleErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="PackagingCatalog.SellPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :index="index" @error="handleErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-weight-full"
      field="Weight"
      :label="PackagingCatalog.Weight.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="PackagingCatalog"
        :index="index" @error="handleErrors"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.ItemPackaging.Module.FullName">
        <template v-if="index !== 0">
          <kst-button delete-mode is-round @click="handleDelete(index)"/>
        </template>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "@/mixins/DialogMixin.js";
import Info from "../ItemInfo.js";
import PackagingCatalog from "../PackagingCatalog.js";
import Model from "../ItemModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userPackagingList: Array
  },
  data: () => ({
    PackagingCatalog: PackagingCatalog,
    Model: Model,
    selectedIndex: null
  }),
  methods: {
    handleName() {
      Model.updatePackagingsErrors(this.userData, this.userPackagingList);
    },

    handleDelete(index) {
      this.selectedIndex = index;
      this.showConfirm(
        Info.Message.New_Packaging_ConfirmDelete,
        this.handleDelete_Confirm,
        this.handleDelete_Cancel
      );
    },
    handleDelete_Confirm() {
      this.userPackagingList.splice(this.selectedIndex, 1);
      this.selectedIndex = null;
      Model.updateDefaultPackaging(this.userData, this.userPackagingList);
    },
    handleDelete_Cancel() {
      this.selectedIndex = null;
    },

    handleErrors(errors, field, index) {
      Model.setPackagingErrors(this.userPackagingList[index], field, errors);
    }
  }
}
</script>