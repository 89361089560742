<template>
  <kst-section :title="Info.Section.CompanyProfile.Title">
    <kst-input field="CompanyName" :data="userData" :catalog="Catalog"/>
    <kst-input field="CompanyAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="CompanyPhone" :data="userData" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../WhsCatalog.js";
import Info from "../WhsInfo.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
  })
}
</script>