import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import ModelBody from "./OmzModel_Body.js";
import InvoiceEnum from "../Invoice/InvEnum.js";

export default {
  getSummary(transactionDetails, access, searchData) {
    let result = ModelBody.createSummary();

    let newTransRow;
    const summaryObj = ModelBody.getTransactionSummary(transactionDetails);

    newTransRow = this.getSummary_getInvoice(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getPos(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getRtnInvoice(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    for (const row of result.TransactionList) {
      if (row.AddTotal !== null) {
        result.Total += row.AddTotal;
      }

      if (row.SubTotal !== null) {
        result.Total -= row.SubTotal;
      }
    }

    return result;
  },
  getSummary_getInvoice(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.Invoice.Code];

    const addSearch = {
      Search: "",
      Client: "",
      ClientID: null, 
      InvoiceDate: searchData.Date,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID,
      RecordTypeID: InvoiceEnum.RecordType.Invoice.ID
    };
    const addParams = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return this.getSummary_createTransRow(
      access.Invoice_Act_List, AppModule.Invoice.FullName, AppModule.Invoice,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, null, null
    );
  },
  getSummary_getPos(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.Pos.Code];

    const addSearch = {
      Search: "",
      Client: "",
      ClientID: null, 
      InvoiceDate: searchData.Date,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID,
      RecordTypeID: InvoiceEnum.RecordType.Pos.ID
    };
    const addParams = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return this.getSummary_createTransRow(
      access.Invoice_Act_List, AppModule.Pos.FullName, AppModule.Invoice,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, null, null
    );
  },
  getSummary_getRtnInvoice(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.RtnInvoice.Code];

    const addSearch = {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const addParams = ["Search", "ClientID", "ClientName", "Date", "WarehouseID"];

    return this.getSummary_createTransRow(
      access.RtnInvoice_Act_List, AppModule.RtnInvoice.FullName,
      AppModule.RtnInvoice,
      transSummary.AddTotal, null, null,
      transSummary.SubTotal, addSearch, addParams
    );
  },
  getSummary_createTransRow(hasAccess, moduleName, module, addTotal,
    addSearch, addParams, subTotal, subSearch, subParams
  ) {
    return {
      HasAccess: hasAccess,
      Label: moduleName,
      Module: module,
      AddTotal: addTotal,
      AddSearch: addSearch,
      AddParams: addParams,
      SubTotal: subTotal,
      SubSearch: subSearch,
      SubParams: subParams
    };
  }
}