import AppCatalog from "./AppCatalog.js";

const OPTION_ALL = -1;
const OPTION_NONE = -2;
const OPTION_SOME = -3;
const LABEL_ALL = AppCatalog.Message.All + " ";
const LABEL_NONE = AppCatalog.Message.Without + " ";
const LABEL_SOME = AppCatalog.Message.Some + " ";

export default {
  OptionAll: OPTION_ALL,
  OptionNone: OPTION_NONE,
  OptionSome: OPTION_SOME,
  LabelAll: LABEL_ALL,
  LabelNone: LABEL_NONE,
  LabelSome: LABEL_SOME,

  getValue(value, defaultValue) {
    const newValue = parseInt(value);
    return (newValue === OPTION_ALL || newValue === OPTION_NONE ? defaultValue : value);
  },

  /**
   * Create options list for a select field.
   * @param {Array} items
   * @param {Object} options
   * options keys:
   * (required)
   *  {String} id - key in items which will be used as value of selected item
   *  {String} label - key in items which is used as label in selection
   * (optional)
   *  {Boolean} allActive - set true to add ALL option as first option in selection
   *  {String} allText - label of ALL option (required if you set allActive as true)
   * (optional)
   *  {Boolean} someActive - set true to add SOME option as first 
   *    after allActive option in selection
   *  {String} someText - label of SOME option (required if you set someActive as true)
   */
  createList(items, options) {
    if (options === undefined) {
      options = {};
    }

    const {
      id, 
      label, 
      allActive,
      allText,
      nonActive,
      nonText,
      someActive,
      someText 
    } = options;

    let filterItems = [];

    if (allActive) {
      filterItems.push({
        [id]: OPTION_ALL,
        [label]: LABEL_ALL + allText
      });
    }
    if (nonActive) {
      filterItems.push({
        [id]: OPTION_NONE,
        [label]: LABEL_NONE + nonText
      });
    }
    if (someActive) {
      filterItems.push({
        [id]: OPTION_SOME,
        [label]: LABEL_SOME + someText
      });
    }

    return filterItems.concat(items);
  }
}