<template>
  <div class="block">
    <VendorSearchList
      @init="handleVendor_Init"
      @submit="handleVendor_Submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="PayNumber"
    />

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="VendorName"
          :data="userData"
          :disabled="vendorDisabled"
          :catalog="Catalog"
        />
      </template>
      <template #right v-if="!userData.IsVerified">
        <div class="control">
          <template v-if="userData.VendorID === null">
            <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button search-mode @click="handleSearch_Vendor"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button clear-mode @click="handleClear_Vendor"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>

    <kst-input
      field="PaymentTypeName" :data="userData" :catalog="Catalog"
      :options="paymentOptions"
    />
    <kst-input field="PaymentValue" :data="userData" :catalog="Catalog"
      :disabled="userData.IsVerified"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />

    <kst-field :errors="invalidIsVerified">
      <template v-if="userData.IsVerified">
        <kst-input class="is-hidden"
          field="ReceiveTotal" :data="userData" :catalog="Catalog"
          @error="handleError_ReceiveTotal"
        />
        <kst-button resetMode @click="handleReset"/>
      </template>
      <template v-else>
        <kst-button verifyMode @click="handleVerify"/>
      </template>
    </kst-field>
  </div>
</template>

<script>
import Catalog from "../DbpyCatalog.js";
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";
import Services from "../../../services/Api/DebtPayServices.js";

import VendorSearchList from "../../Vendor/SearchList/VenScL.vue";

export default {
  components:{
    VendorSearchList
  },
  props: {
    userData: Object,
    paymentOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showVendorFunc: null
  }),
  computed: {
    vendorDisabled() {
      return this.userData.IsVerified ? true : this.userData.VendorID !== null;
    },
    invalidIsVerified() {
      return this.userData.IsVerified ? null : Info.Message.New_IsVerified;
    }
  },
  methods: {
    /*** by vendor modal ***/

    handleVendor_Init(show) {
      this.showVendorFunc = show;
    },
    handleVendor_Submit(row) {
      Model.New.setDetailsByVendor(this.userData, row);
    },

    /*** custom ***/

    handleSearch_Vendor() {
      this.showVendorFunc(Services.getRelatedVendorList);
    },
    handleClear_Vendor() {
      Model.New.clearDetailsByVendor(this.userData);
    },

    handleError_ReceiveTotal(errors) {
      this.userData.ReceiveTotalIsValid = errors.length === 0;
    },

    handleReset() {
      this.userData.IsVerified = false;
    },
    handleVerify() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>