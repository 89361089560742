<template>
  <div>
    <div class="is-size-3">{{ data.name }}</div>
    <div>{{ data.address }}</div>
    <div>Telp. {{ data.phone }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  }
}
</script>