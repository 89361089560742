<template>
  <div class="columns mt-0 pr-4">
    <ClientSearchListAddress
      @init="handleClient_init"
      @submit="handleClient_submit"
    />

    <div class="column pt-1">
      <LayoutBrand />
    </div>
    <div class="column is-narrow mt-2">
      <kst-input floating-label class="ks-col-shortdate-icon"
        field="InvoiceDate" :data="userData" :catalog="Catalog" 
      />
    </div>
    <div class="column is-narrow mt-2">
      <kst-input floating-label
        field="WarehouseID" :data="userData" :catalog="Catalog"
        :options="warehouseOptions" @input="handleWarehouse"
      />
    </div>
    <div class="column is-narrow mt-2">
      <kst-output control-off status-off field-only
        class="ks-col-client-search"
        field="ClientID" :data="userData" :catalog="Catalog"
      >
        <template #default>
          <kst-input addons expanded floating-label
            field="Client"
            :data="userData"
            :catalog="Catalog"
            :disabled="userData.ClientID !== null"
          />
        </template>

        <template #right>
          <div class="control">
            <template v-if="!userData.ClientID">
              <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button search-mode @click="handleSearch_client"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button clear-mode @click="handleClear_client"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </kst-output>
    </div>
    <div class="column is-narrow mt-2">
      <kst-input floating-label class="ks-col-phone"
        field="ClientPhone" :data="userData" :catalog="Catalog"
      />
    </div>
    <div class="column is-narrow mt-2">
      <kst-input floating-label class="ks-col-ref"
        field="PONumber" :data="userData" :catalog="Catalog"
      />
    </div>
    <div class="column">
      <div class="is-flex is-justify-content-flex-end">
        <User />
        <LayoutCompany />
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../PosCatalog.js";
import Model from "../PosModel.js";
import Services from "../../../services/Api/PosServices.js";

import ClientSearchListAddress from "../../Client/SearchListAddress/CliScLAddress.vue";
import LayoutBrand from "../../Layout/Layout_Nav_Brand.vue";
import LayoutCompany from "../../Layout/Layout_Nav_Company.vue";
import User from "../../Layout/Layout_Nav_User.vue";

export default {
  components: {
    ClientSearchListAddress,
    LayoutBrand,
    LayoutCompany,
    User
  },
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showClientFunc: null
  }),
  methods: {
    /*** by client modal ***/

    handleClient_init(show) {
      this.showClientFunc = show;
    },
    handleClient_submit(row) {
      Model.setDetailsByClient(this.userData, row);
    },

    /*** custom ***/

    handleSearch_client() {
      this.showClientFunc(Services.getRelatedClientList);
    },
    handleClear_client() {
      Model.clearDetailsByClient(this.userData);
    },

    handleWarehouse() {
      this.$emit(this.$kst.Enum.Event.Callback);
    }
  }
}
</script>