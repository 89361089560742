export default {
  /*** related ***/

  /*** property ***/

  Search: {
    Fields: ["PayNumber"]
  },

  /*** method ***/
}