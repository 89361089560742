<template>
  <div>
    <kst-input field="DiscType" :data="userData" :catalog="Catalog"
      :options="discTypeOptions" @input="handleDiscType"
    >
      <template #right>
        <template v-if="userData.DiscType === Model.DiscType.Value.ID">
          <kst-input addons expanded floating-label
            field="DiscValue" :data="userData" :catalog="Catalog"
            @input="handleDiscValue"
          />
        </template>
        <template v-else-if="userData.DiscType === Model.DiscType.Percent.ID">
          <kst-input addons expanded floating-label
            field="DiscPercent" :data="userData" :catalog="Catalog"
            @input="handleDiscPercent"
          />
        </template>
        <template v-else>
          <div class="field control is-expanded">
            <input class="input is-invisible"/>
          </div>
        </template>
      </template>
      <template #info>
        <template v-if="userData.ClientDiscPercent_MessageCredit !== 0">
          <div class="has-text-danger">{{ Info.Message.New_ClientDiscPercent }}</div>
        </template>
      </template>
    </kst-input>

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  computed: {
    discTypeOptions() {
      return Model.createDiscTypeOptions();
    }
  },
  methods: {
    handleDiscType() {
      if (this.userData.ClientDiscPercent_MessageCredit !== 0) {
        this.userData.ClientDiscPercent_MessageCredit--;
      }

      Model.normalizeDetailsByDisc(this.userData);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscValue() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscPercent() {
      if (this.userData.ClientDiscPercent_MessageCredit !== 0) {
        this.userData.ClientDiscPercent_MessageCredit--;
      }

      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>