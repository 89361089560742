import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StopD from "./Details/StopD.vue";
import StopExL from "./ExportList/StopExL.vue";
import StopL from "./List/StopL.vue";
import StopN from "./New/StopN.vue";
import StopPD from "./PrintDetails/StopPD.vue";
import StopPI from "./PrintItems/StopPI.vue";
import StopPL from "./PrintList/StopPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockOpname, AppModuleType.Details),
    ExportList: RouterModel.getPath(AppModule.StockOpname, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.StockOpname, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockOpname, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintDetails),
    PrintItems: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintItems),
    PrintList: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.Details),
        component: StopD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.ExportList),
        component: StopExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.List),
        component: StopL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.StockOpname),
            moduleattr: AppModuleAttr.StockOpname_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          print: {
            module: RouterModel.getModule(AppModule.StockOpname),
            moduleattr: AppModuleAttr.StockOpname_Act_PrintItems,
            moduletype: AppModuleType.PrintItems,
          },
          new: {
            module: RouterModel.getModule(AppModule.StockOpname),
            moduleattr: AppModuleAttr.StockOpname_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.StockOpname),
            moduleattr: AppModuleAttr.StockOpname_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.New),
        component: StopN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.New),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.StockOpname, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.StockOpname, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.PrintDetails),
        component: StopPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintItems),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.PrintItems),
        component: StopPI,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.PrintItems),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_PrintItems,
          moduletype: AppModuleType.PrintItems
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockOpname, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.StockOpname, AppModuleType.PrintList),
        component: StopPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockOpname, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.StockOpname),
          moduleattr: AppModuleAttr.StockOpname_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}