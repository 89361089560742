import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    Sales: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      Module: AppModule.Sales,
      ModuleType: AppModuleType.Details
    },
    Summary: {
      Title: AppCatalog.Info.Summary,
      ModuleType: AppModuleType.List
    }
  },
  Dialog: {
    Summary: {
      Class: AppCatalog.ClassPrefix.Module + "-" + AppModule.StockAvailability.Class + "-" +
        AppModuleType.List.Class,
      Title: AppCatalog.Info.Summary
    }
  }
}