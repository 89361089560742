<template>
  <kst-section :title="title">
    <List
      :userModule="userModule"
      :userModuleAttrList="userModuleAttrList"
      :moduleList="moduleList"
    />

    <template v-if="userModule.checkedRows.length === 0">
      <ValidationProvider v-slot="{ errors }" name="userModule" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-section>
</template>

<script>
import Info from "../AcsInfo.js";

import List from "./AcsN_ModuleList_List.vue";

export default {
  components: {
    List
  },
  props: {
    userModule: Object,
    userModuleAttrList: Array,
    moduleList: Array
  },
  computed: {
    title() {
      return Info.Section.Modules.Title +
        " (" + this.userModule.checkedRows.length + " terpilih)";
    }
  }
}
</script>