<template>
  <kst-modal-input
    :title="Info.Dialog.EditSellPrice.Title"
    :width="500"
    @init="handleInit"
    @submit="handleSubmit"
  >
    <kst-input
      ref="txtSellPrice_Edit"
      field="SellPrice" :data="editData" :catalog="PosItemCatalog"
    />
  </kst-modal-input>
</template>

<script>
import Info from "../PosInfo.js";
import Model from "../PosModel.js";
import PosItemCatalog from "../PosItemCatalog.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    itemIndex: Number
  },
  data: () => ({
    Info: Info,
    PosItemCatalog: PosItemCatalog,
    editData: {
      SellPrice: ""
    },
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(sellPrice) {
      this.init_Data(sellPrice);
      this.showFunc();

      this.$nextTick(() => {
        this.$refs.txtSellPrice_Edit.focus();
      });
    },

    /*** by modal ***/

    handleInit(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleSubmit() {
      const userItem = this.userItems[this.itemIndex];
      Model.setItemByEditSellPrice(userItem, this.editData.SellPrice);
      Model.updateItem(userItem);
      Model.updateDetails(this.userData, this.userItems);

      this.hideFunc();
    },

    /*** custom ***/

    init_Data(sellPrice) {
      this.editData.SellPrice = sellPrice ? this.$kst.In.getInteger(sellPrice) : "";
    }
  }
}
</script>