import RouterModel from "@/router/RouterModel.js";
import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";

import Home from "./Home/Home.vue";
import Features from "./Features/Features.vue";
import ContactUs from "./ContactUs/ContactUs.vue";
import TNC from "./TNC/TNC.vue";
import Policy from "./Policy/Policy.vue";
import Stories from "./Stories/Stories.vue";
import FAQ from "./FAQ/FAQ.vue";
import Price from "./Price/Price.vue";
import Inspiration from "./Inspiration/Inspiration.vue";

export default {
  Path: {
    Home: App.Env.WebsiteUrl.Home
  },

  getRootRoutes() {
    return [{
      path: RouterModel.getPath(AppModule.Inspiration),
      name: RouterModel.getName(AppModule.Inspiration),
      component: Inspiration,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Inspiration),
        module: RouterModel.getModule(AppModule.Inspiration)
      }
    },{
      path: RouterModel.getPath(AppModule.FAQ),
      name: RouterModel.getName(AppModule.FAQ),
      component: FAQ,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.FAQ),
        module: RouterModel.getModule(AppModule.FAQ)
      }
    },{
      path: RouterModel.getPath(AppModule.Stories),
      name: RouterModel.getName(AppModule.Stories),
      component: Stories,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Stories),
        module: RouterModel.getModule(AppModule.Stories)
      }
    },{
      path: RouterModel.getPath(AppModule.Policy),
      name: RouterModel.getName(AppModule.Policy),
      component: Policy,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Policy),
        module: RouterModel.getModule(AppModule.Policy)
      }
    },{
      path: RouterModel.getPath(AppModule.TNC),
      name: RouterModel.getName(AppModule.TNC),
      component: TNC,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.TNC),
        module: RouterModel.getModule(AppModule.TNC)
      }
    },{
      path: RouterModel.getPath(AppModule.ContactUs),
      name: RouterModel.getName(AppModule.ContactUs),
      component: ContactUs,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.ContactUs),
        module: RouterModel.getModule(AppModule.ContactUs)
      }
    },{
      path: RouterModel.getPath(AppModule.Features),
      name: RouterModel.getName(AppModule.Features),
      component: Features,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Features),
        module: RouterModel.getModule(AppModule.Features)
      }
    },{
      path: RouterModel.getPath(AppModule.Home),
      name: RouterModel.getName(AppModule.Home),
      component: Home,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Home),
        module: RouterModel.getModule(AppModule.Home)
      }
    },{
      path: RouterModel.getPath(AppModule.Price),
      name: RouterModel.getName(AppModule.Price),
      component: Price,
      meta: {
        title: RouterModel.getNavSubTitle(AppModule.Price),
        module: RouterModel.getModule(AppModule.Price)
      }
    }];
  }
}