import AppCatalog from "../../../services/App/AppCatalog.js";
import AppModule from "../../../services/App/AppModule.js";
import Catalog from "../ExpcCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      AppModule.ExpenseCategory.FullName,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },

  CategoryID: {
    Label: Catalog.CategoryID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.Category.Param + AppCatalog.Field.ID.Param
  },
  IsActive: {
    Label: Catalog.IsActive.Label,
    Type: AppCatalog.Field.IsActive.SearchType,
    Input: AppCatalog.Field.IsActive.SearchInput,
    Param: AppCatalog.Field.IsActive.Param
  }
}