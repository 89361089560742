<template>
  <kst-modal-input confirmMode
    :title="Info.Button.ItemImport.Title"
    :width="1000"
    :isDisabledSubmit="isDisabledSubmit"
    @init="handleInit"
    @submit="handleSubmit"
  >
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-load-data activeOnLoad @init="handleLoad_init">
      <kst-upload mxauto
        :label="Info.Input.ItemImport.Title"
        field="File"
        :data="userData"
        @input="handleUpload_input"
        @error="handleUpload_error"
      />
    </kst-load-data>

    <template v-if="itemInvalidList.length > 0 || itemValidList.length > 0">
      <kst-section 
        :title="Info.Section.Import.Summary"
        class="mt-4"
      >
        <SummaryList
          :invalidLength="itemInvalidList.length"
          :validLength="itemValidList.length"
        />
      </kst-section>
    </template>

    <template v-if="itemInvalidList.length > 0">
      <kst-section 
        :title="Info.Section.Import.InvalidData"
        class="mt-4"
      >
        <InvalidList
          :list="itemInvalidList"
        />
      </kst-section>
    </template>

    <kst-errors class="mt-4" :items="this.errors"/>
  </kst-modal-input>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StopCatalog.js";
import Info from "../StopInfo.js";
import Model from "../StopModel.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import InvalidList from "./StopIIm_InvalidList.vue";
import SummaryList from "./StopIIm_SummaryList.vue";

export default {
  mixins: [XlsxMixin],
  components: {
    InvalidList,
    SummaryList
  },
  computed: {
    detailsWarning() {
      return [
        Info.Message.New_ImportSupportedFile,
        Info.Message.New_ImportMaxFileSize
      ];
    }
  },
  data: () => ({
    Catalog: Catalog,
    Info,
    userData: {
      File: null,
      // params
      WarehouseID: null
    },
    errors: [],
    itemInvalidList: [],
    itemValidList: [],
    sheetRowList: [],
    summaryList: [],
    withPackaging: true,
    withStock: true,
    isDisabledSubmit: true,
    // func
    loadData: null,
    hideFunc: null,
    showFunc: null
  }),
  props: {
    userItems: Array
  },
  methods: {
    /*** public method ***/

    show(warehouseID) {
      this.userData.File = null;
      this.userData.WarehouseID = warehouseID;
      this.resetData();

      this.showFunc();
    },

    /*** by modal ***/

    handleInit(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleUpload_input(file, fileText) {
      this.resetData();

      // verify file & template
      if (!Model.ItemImport.validateFileTemplate(
        this.errors, this.sheetRowList, file, fileText)
      ) {
        this.userData.File = null;
      } else {
        const options = this.getListOptions();
        const config = Services.getRelatedItemList(options);
        this.loadData(config, this.handleUpload_input_success, true);
      }
    },
    handleUpload_input_success(data) {
      // verify row data
      if (!Model.ItemImport.validateRowByItem(
        this.itemValidList, this.itemInvalidList, this.sheetRowList, data.List)
      ) {
        this.userData.File = null;
      } else {
        this.isDisabledSubmit = false;
      }
    },

    handleUpload_error(error) {
      this.errors = error;
      this.userData.File = null;
    },

    handleSubmit() {
      this.$emit(this.$kst.Enum.Event.Submit, this.itemValidList);
      this.resetData();
      this.hideFunc();
    },

    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;
    },

    /** custom */

    resetData() {
      this.errors = [];
      this.itemInvalidList = [];
      this.itemValidList = [];
      this.sheetRowList = [];
      this.summaryList = [];
      this.isDisabledSubmit = true;
    },

    getListOptions() {
      return {
        warehouse: this.$kst.Out.getInteger(this.userData.WarehouseID),
        withpackaging: this.$kst.Out.getBoolean(this.withPackaging),
        withstock: this.$kst.Out.getBoolean(this.withStock)
      };
    }
  }
}
</script>