import App from "../services/App/App.js";
import InputType from "../services/Input/InputType.js";

const SEPARATOR_INDEX = "-";

export default {
  getUrlQuery(queryObj, field, value, catalog, index) {
    const queryIndex = index === undefined || index === null ? null : index;
    const queryName = queryIndex === null
      ? catalog[field].Param
      : catalog[field].Param + SEPARATOR_INDEX + index;

    if (value === undefined || value === null || value === "") {
      queryObj[queryName] = "";
      return;
    }

    const currentValue = queryIndex !== null ? value[index] : value;

    if (catalog[field].Type === InputType.Boolean) {
      queryObj[queryName] = currentValue
        ? App.Enum.ParamValue.Boolean.True
        : App.Enum.ParamValue.Boolean.False;
    }
    else if (catalog[field].Type === InputType.Date) {
      queryObj[queryName] = App.Out.getDateString(currentValue);
    }
    else if (catalog[field].Type === InputType.DateTime) {
      // coming soon
    }
    else if (catalog[field].Type === InputType.Password) {
      // not available
    }
    else if (catalog[field].Type === InputType.TreeSelect) {
      // not available
    }
    else {
      // InputType: Decimal, Email, ID, Integer, Percent, Phone, Qty,
      //  Select, Text, TextArea, Url
      queryObj[queryName] = currentValue;
    }
  },
  setUrlQuery(routeQuery, search, field, catalog, index) {
    const queryIndex = index === undefined || index === null ? null : index;
    const queryName = queryIndex === null
      ? catalog[field].Param
      : catalog[field].Param + SEPARATOR_INDEX + index;

    if (!Object.prototype.hasOwnProperty.call(routeQuery, queryName)) {
      if (queryIndex === null) {
        search[field] = null;
      }
      return false;
    }

    let minValue = null;

    // normalize: minValue
    if (Object.prototype.hasOwnProperty.call(catalog[field], "MinValue")) {
      minValue = catalog[field].MinValue;
    }

    let value = routeQuery[queryName];

    // normalize: value
    if (catalog[field].Type === InputType.Boolean) {
      value = value === App.Enum.ParamValue.Boolean.True
        ? true : false;
    }
    else if (catalog[field].Type === InputType.Date) {
      value = App.In.getDate(value);
    }
    else if (catalog[field].Type === InputType.DateTime) {
      value = App.In.getDateTime(value);
    }
    else if (catalog[field].Type === InputType.Decimal) {
      value = App.In.getDecimal(value);
    }
    else if (catalog[field].Type === InputType.ID) {
      value = App.In.getInteger(value, minValue);
    }
    else if (catalog[field].Type === InputType.Percent) {
      value = App.In.getDecimal(value);
    }
    else if (catalog[field].Type === InputType.Qty) {
      value = App.In.getInteger(value, minValue);
    }
    else if (catalog[field].Type === InputType.Select) {
      value = App.In.getInteger(value, minValue);
    }
    // else: do nothing
    // InputType: Email, Password, Phone, Text, TextArea, Url, TreeSelect

    // non-array field
    if (queryIndex === null) {
      search[field] = value;
    }
    // array field
    else {
      if (!Object.prototype.hasOwnProperty.call(search, field)) {
        search[field] = [];
      }

      if (!Array.isArray(search[field])) {
        search[field] = [];
      }

      search[field][index] = value;
    }

    return true;
  }
}