import AppCatalog from "@/services/App/AppCatalog.js";
import AppModule from "@/services/App/AppModule";

export default {
  Copyright: AppCatalog.Copyright,
  Copyright2: AppCatalog.Copyright2,
  Description: AppCatalog.Description,
  Welcome: "Hello, Welcome",
  User: {
    HelpText: AppModule.SignOut.FullName,
    HelpSize: "is-small"
  },
  SignOut: {
    Label: AppModule.SignOut.FullName,
    Icon: "mdi mdi-logout-variant mdi-18px"
  }
}