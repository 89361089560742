import App from "../../services/App/App.js";
import ModelBody from "./RcvModel_Body.js";
import ModelDetails from "./RcvModel_Details.js";
import ModelEdit from "./RcvModel_Edit.js";
import ModelEditDate from "./RcvModel_EditDate.js";
import ModelNew from "./RcvModel_New.js";
import ModelRelatedList from "./RcvModel_RelatedList.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import PurchaseEnum from "../Purchase/PurEnum.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  ReceiveItem: ModelBody.ReceiveItem,
  // other
  DebtPay: ModelBody.DebtPay,
  Purchase: ModelBody.Purchase,
  PurchaseItem: ModelBody.PurchaseItem,

  /***  property  ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  StatusPurchasingIsActive: ModelBody.StatusPurchasingIsActive,
  // related
  ReceiveVendorSourceID: ModelBody.ReceiveVendorSourceID,

  /*** methods ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem(userData) {
    return ModelBody.createItem(userData);
  },

  setDetailsByCompanySetting(userData, companySetting) {
    ModelBody.setDetailsByCompanySetting(userData, companySetting);
  },
  setDetailsByVendor(userData, vendorDetails) {
    ModelBody.setDetailsByVendor(userData, vendorDetails);
  },

  setItemByPrice(userItem, priceData) {
    ModelBody.setItemByPrice(userItem, priceData);
  },
  setItemByStock(userItem, itemData) {
    ModelBody.setItemByStock(userItem, itemData);
  },
  setItemByVendorItem(userItem, vendorItemData) {
    ModelBody.setItemByVendorItem(userItem, vendorItemData);
  },
  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  getDebtPaySectionHelp(userData) {
    return ModelBody.getDebtPaySectionHelp(userData);
  },
  getDateDisabled(){
    return ModelBody.getDateDisabled();
  },
  getPurchaseSectionHelp(userData) {
    return ModelBody.getPurchaseSectionHelp(userData);
  },
  getReceiveSectionHelp(data) {
    return ModelBody.getReceiveSectionHelp(data);
  },

  clearDetailsByVendor(userData) {
    ModelBody.clearDetailsByVendor(userData);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(item) {
    ModelBody.updateItemByPackaging(item);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(details, item) {
      return ModelDetails.getItemInfo(details, item);
    },

    updateDebtData(debtData, details) {
      ModelDetails.updateDebtData(debtData, details);
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByEditData(userData, editDetails) {
      ModelEdit.setDetailsByEditData(userData, editDetails);
    },

    setItemByPurchase(userItem, purchaseItem) {
      ModelEdit.setItemByPurchase(userItem, purchaseItem);
    },
    setItemsByEditData(userData, userItems, details, purchaseItems, returnItemSummaryList) {
      ModelEdit.setItemsByEditData(userData, userItems, details,
        purchaseItems, returnItemSummaryList
      );
    },

    getItemInfo(item) {
      return ModelEdit.getItemInfo(item);
    },

    compareData(userData, userItems, receiveData) {
      return ModelEdit.compareData(userData, userItems, receiveData);
    },

    validationDueDate(userData) {
      return ModelEdit.validationDueDate(userData);
    },

    /*** external ***/

    getPurchaseItemsForSelection(purchaseItems, userItems) {
      return ModelEdit.getPurchaseItemsForSelection(purchaseItems, userItems);
    },
    
    getPurchaseItems(purchaseItemList, receiveItems) {
      return ModelEdit.getPurchaseItems(purchaseItemList, receiveItems);
    }
  },

  /*** method - EditDate ***/

  EditDate: {
    setDetailsByData(userData, receiveData) {
      ModelEditDate.setDetailsByData(userData, receiveData);
    },

    populateData(userData) {
      return ModelEditDate.populateDetails(userData);
    },

    validationReceiveDate(data) {
      return ModelEditDate.validationReceiveDate(data);
    },
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByFullPayment(userData) {
      ModelNew.setDetailsByFullPayment(userData);
    },
    setDetailsByPaymentList(userData, paymentList) {
      ModelNew.setDetailsByPaymentList(userData, paymentList);
    },
    setDetailsByPurchase(userData, purchaseData, vendorDetails) {
      ModelNew.setDetailsByPurchase(userData, purchaseData, vendorDetails);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    setItemsByPurchase(userData, userItems, purchaseItems) {
      ModelNew.setItemsByPurchase(userData, userItems, purchaseItems);
    },
    setItemByScanner(userItem, itemData, qty) {
      ModelNew.setItemByScanner(userItem, itemData, qty);
    },
    setItemByPurchase(userItem, purchaseItem) {
      ModelNew.setItemByPurchase(userItem, purchaseItem);
    },

    getItemInfo(item) {
      return ModelNew.getItemInfo(item);
    },

    validationDate(userData) {
      return ModelNew.validationDate(userData);
    },
    validationDueDate() {
      return ModelNew.validationDueDate();
    },

    /*** external ***/

    getPurchaseItemsForSelection(purchaseItems, userItems) {
      return ModelNew.getPurchaseItemsForSelection(purchaseItems, userItems);
    },
  
    updatePurchaseItems(purchaseData) {
      ModelNew.updatePurchaseItems(purchaseData);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getItemInfo(item) {
      return ModelRelatedList.getItemInfo(item);
    }
  },

  /*** external ***/

  PurchaseStatus: PurchaseEnum.Status,

  getPaymentOptions(paymentList, optionAllText, optionNonText) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText,
      optionNonText
    );
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}