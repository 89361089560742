<template>
  <div class="block">
    <VendorSearchAddress
      @init="handleVendor_Init"
      @submit="handleVendor_Submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      dateField="Date"
      numberField="PONumber"
    />

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Vendor"
          :disabled="userData.VendorID !== null || userData.ReceivingCount !== 0"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <template v-if="userData.ReceivingCount === 0">
          <div class="control">
            <template v-if="userData.VendorID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button search-mode @click="handleSearch_Vendor"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button clear-mode @click="handleClear_Vendor"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions" @input="handleWarehouse"
    />
  </div>
</template>

<script>
import Catalog from "../PurCatalog.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import VendorSearchAddress from "../../Vendor/SearchListAddress/VenScLAddress.vue";

export default {
  components: {
    VendorSearchAddress
  },
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showFunc: null
  }),
  methods: {
    /*** by vendor modal ***/

    handleVendor_Init(show) {
      this.showFunc = show;
    },
    handleVendor_Submit(row) {
      Model.setDetailsByVendor(this.userData, row);
    },

    /*** by warehouse ***/

    handleWarehouse() {
      Model.setDetailsByWarehouse(this.userData, this.warehouseOptions);
    },

    /*** custom ***/

    handleSearch_Vendor() {
      this.showFunc(Services.getRelatedVendorAddressList);
    },
    handleClear_Vendor() {
      Model.clearDetailsByVendor(this.userData);
    }
  }
}
</script>