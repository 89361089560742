<template>
  <div>
    <div class="columns mb-0">
      <div class="column">
        {{ Catalog.TotalBruto.Label }}
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value field="TotalBruto" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <kst-input field-only
          field="DiscType" :data="userData" :catalog="Catalog"
          :options="discOptions" @input="handleDiscType"
        />
        <template v-if="userData.DiscType === Model.DiscType.Percent.ID">
          <kst-input field-only
            field="DiscPercent" :data="userData" :catalog="Catalog"
            @input="handleDiscPercent"
          />
        </template>
        <template v-if="userData.DiscType === Model.DiscType.Value.ID">
          <kst-input field-only
            field="DiscValue" :data="userData" :catalog="Catalog"
            @input="handleDiscValue"
          />
        </template>
      </div>
      <div class="column is-flex is-align-items-center is-justify-content-flex-end">
        <div
          class="has-text-weight-semibold has-text-right"
          :class="userData.DiscType === Model.DiscType.None.ID ? '' : 'has-text-danger'"
        >
          <kst-value field="TotalDisc" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        {{ Catalog.Total.Label }}
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value class="is-size-4"
            field="Total" :data="userData" :catalog="Catalog"
          />
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        {{ Catalog.TotalQty.Label }}
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value field="TotalQty" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>

    <div class="divider my-2"/>

    <div class="has-text-weight-semibold">
      {{ Info.Section.Payment.Title }}
    </div>
    <div class="columns mb-0">
      <div class="column">
        <div class="k-control-py">
          {{ Catalog.PaymentTypeName.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only
          field="PaymentTypeName" :data="userData" :catalog="Catalog"
          :options="paymentOptions"
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <div class="k-control-py">
          {{ Catalog.PaymentValue.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only
          field="PaymentValue" :data="userData" :catalog="Catalog"
          @input="handlePaymentValue"
        />
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        {{ Catalog.PaymentReturn.Label }}
      </div>
      <div class="column">
        <div class="has-text-weight-semibold has-text-right">
          <kst-value field="PaymentReturn" :data="userData" :catalog="Catalog"/>
        </div>
      </div>
    </div>
    <div class="columns mb-0">
      <div class="column">
        <div class="k-control-py">
          {{ Catalog.Comment.Label }}
        </div>
      </div>
      <div class="column">
        <kst-input field-only
          field="Comment" :data="userData" :catalog="Catalog"
        />
      </div>
    </div>
    <div class="has-text-right">
      <kst-button submit-mode @click="handleSubmit"/>
    </div>
  </div>
</template>

<script>
import Catalog from "../PosCatalog.js";
import Info from "../PosInfo.js";
import Model from "../PosModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    paymentOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  computed: {
    discOptions() {
      return Model.createDiscTypeOptions();
    }
  },
  methods: {
    handleDiscType() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscValue() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleDiscPercent() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handlePaymentValue() {
      Model.updateDetails(this.userData, this.userItems);
    },
    handleSubmit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>