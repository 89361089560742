import App_Sort_Body from "./App_Sort_Body.js";

export default {
  // summarize type
  Case: App_Sort_Body.Case,
  Key: App_Sort_Body.Key,
  Order: App_Sort_Body.Order,

  /**
   * Sort an array based on a field.
   * @param {Array} data - array of object
   * @param {Array} fieldKeys
   */
  begin(items, fieldKeys) {
    let fieldList = [];
  
    if (Array.isArray(fieldKeys)) {
      for (const row of fieldKeys) {
        fieldList.push(App_Sort_Body.addField(row));
      }
    }
  
    items.sort(App_Sort_Body.compare(fieldList));  
  }
}