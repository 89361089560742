import App from "../../services/App/App.js";

export default {
  getPivotSummaryObj_compare() {
    return function innerSort(a, b) {
      let valueA = a.toUpperCase();
      let valueB = b.toUpperCase();
  
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      }
      else if (valueA < valueB) {
        comparison = -1;
      }
  
      return comparison;
    }
  },
  
  getPivotSummaryObj(dataListByClient)  {
    const fieldKeys = [ "ClientName", "DispatchName" ];
    const fieldValues = [
      { field: "TotalQty", type: App.Pivot.Type.SUM },
    ];
    const summaryKeyList = [ "ClientName", "DispatchName" ];

    const summaryObj = App.Pivot.createObj(
      dataListByClient, fieldKeys, fieldValues, summaryKeyList
    );

    summaryObj.SummaryObj.DispatchName.sort(this.getPivotSummaryObj_compare());
    summaryObj.SummaryObj.ClientName.sort(this.getPivotSummaryObj_compare());

    return summaryObj;
  }
}