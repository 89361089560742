import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RrcvCatalog.js"
import RrcvItemCatalog from "./RrcvItemCatalog.js";
import Model from "./RrcvModel.js";

export default {
  Button: {
    ReceiveItem: {
      Label: AppModule.Item.FullName + " " + Model.Receive.Module.FullName
    }
  },
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.RtnReceive.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.RtnReceive.FullName,
          RtnReceiveItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_RtnReceiveFields:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ", " +
      Catalog.VendorID.Label + " dan " + Catalog.VendorAddress.Label + ".",
    Edit_ReceiveFields:
      "Tidak diperkenankan mengubah " + Catalog.ReceiveID.Label + " dan " +
      Catalog.ReceiveDate.Label + ".",
    Edit_ReceiveItem:
      "Tidak diperkenankan mengubah " + RrcvItemCatalog.DispatchID.ColumnLabel + ", " +
      RrcvItemCatalog.Packaging.Label + " dan " +
      RrcvItemCatalog.BuyPrice.Label + ".",
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.RtnReceiveItem.Module.FullName + "?",
    New_Receive:
      "Memilih / menghapus " + Model.Receive.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + ".",
    New_ReceiveBlank:
      "Harap memilih " + Catalog.ReceiveNumber.Label + " terlebih dahulu."
  },
  Section: {
    Receive: {
      Title: Model.Receive.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}