<template>
  <kst-section items-mode>
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="SpadItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="SpadItemCatalog.Packaging.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="SpadItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="SpadItemCatalog.SellPrice.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>
    
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-specialpricename"
        field="SpecialPriceID"
        :label="SpadItemCatalog.SpecialPriceID.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="Total"
        :label="SpadItemCatalog.Total.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="SpadItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Model from "../SpadModel.js";
import SpadItemCatalog from "../SpadItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    SpadItemCatalog: SpadItemCatalog,
    Model: Model
  })
}
</script>