import AppEnv from "./App_Env.js";
import RouterModel from "@/router/RouterModel.js";

/**
 * target: app routes/router
 * > for API, see "./App_Output.js"
 * > for keys in params, see Param in "./App_Enum.js"
 */
export default {
  getFullPath(module, moduleType, params) {
    return AppEnv.App.BaseUrl + RouterModel.getPath(module, moduleType) +
      (params ? params : "");
  },
  getParams(params, withoutQueryMark) {
    let urlParams = "", value;
    for (const key in params) {
      if (urlParams !== "") {
        urlParams += "&";
      }

      value = params[key];
      if (value === undefined || value === null) {
        value = "";
      }
      urlParams += key + "=" + value;
    }

    return withoutQueryMark === true ? urlParams : "?" + urlParams;
  },
  getPath(module, moduleType, params) {
    return RouterModel.getPath(module, moduleType) + (params ? params : "");
  },
}