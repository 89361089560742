<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StrdCatalog.js";
import Info from "../StrdInfo.js";
import Model from "../StrdModel.js";
import StrdItemCatalog from "../StrdItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details, data.SpecialPriceCount);

      if (this.userData.WithHpp) {
        this.createSheet();
        this.addHpp(data.Details.Items);
      }

      this.download(fileName);
    },

    addDetails(details, specialPriceCount) {
      this.addDetails_Details(details);
      this.addDetails_Items(details.Items, specialPriceCount);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ReductionNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.TransactionID.Label);
      this.addColumnHeader(Catalog.TransactionID);
      this.addColumnHeader(Catalog.TransactionDate);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("ReductionNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("TransactionID", details, Catalog);
      this.addCellRelatedValue("TransactionID", details, Catalog);
      this.addCellValue("TransactionDate", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
    addDetails_Items(items, specialPriceCount) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.StockReductionItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StrdItemCatalog.DispatchID.Label);
      this.addColumnHeader(StrdItemCatalog.DispatchID);
      this.addColumnHeader(StrdItemCatalog.SKU);
      this.addColumnHeader(StrdItemCatalog.Packaging);
      this.addColumnHeader(StrdItemCatalog.Qty);
      this.addColumnHeader(StrdItemCatalog.SellPrice);

      if (specialPriceCount > 0) {
        this.addColumnHeader_ID(StrdItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(StrdItemCatalog.SpecialPriceID);
      }

      this.addColumnHeader(StrdItemCatalog.Total);

      // value
      for ( const row of items ) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, StrdItemCatalog);
        this.addCellRelatedValue("DispatchID", row, StrdItemCatalog);
        this.addCellValue("SKU", row, StrdItemCatalog);
        this.addCellValue("Packaging", row, StrdItemCatalog);
        this.addCellValue("Qty", row, StrdItemCatalog);
        this.addCellValue("SellPrice", row, StrdItemCatalog);

        if (specialPriceCount > 0) {
          this.addCellValue("SpecialPriceID", row, StrdItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, StrdItemCatalog);
        }

        this.addCellValue("Total", row, StrdItemCatalog);
      }
    },
    addHpp(items) {
      this.addHpp_Items(items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Hpp);
    },
    addHpp_Items(items) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StrdItemCatalog.DispatchID.Label);
      this.addColumnHeader(StrdItemCatalog.DispatchID);
      this.addColumnHeader(StrdItemCatalog.SKU);
      this.addColumnHeader(StrdItemCatalog.Packaging);
      this.addColumnHeader(StrdItemCatalog.Qty);
      this.addColumnHeader(StrdItemCatalog.HppTotal);

      // value
      for ( const row of items ) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, StrdItemCatalog);
        this.addCellRelatedValue("DispatchID", row, StrdItemCatalog);
        this.addCellValue("SKU", row, StrdItemCatalog);
        this.addCellValue("Packaging", row, StrdItemCatalog);
        this.addCellValue("Qty", row, StrdItemCatalog);
        this.addCellValue("HppTotal", row, StrdItemCatalog);
      }
    }
  }
}
</script>