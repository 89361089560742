<template>
  <kst-table view-mode :data="setImportSummaryList">
    <b-table-column
      v-slot="{ column, row }"
      field="Info"
      :label="Catalog.Info.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      cell-class="has-text-weight-bold"
      v-slot="{ column, row }"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../StopItemImportCatalog.js";
import Info from "../StopInfo.js";

export default {
  props: {
    invalidLength: Number,
    validLength: Number
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    setImportSummaryList() {
      let resultList = [];

      resultList.push({
        Info: Info.Section.Import.ValidData,
        Total: this.validLength
      });

      if (this.invalidLength > 0) {
        resultList.push({
          Info: Info.Section.Import.InvalidData,
          Total: this.invalidLength
        });
      }

      resultList.push({
        Info: Catalog.Total.Label,
        Total: this.validLength + this.invalidLength
      });

      return resultList;
    },
  }
}
</script>