<template>
  <kst-page-export
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check
      v-model="userData.WithReceiveList"
      :label="Info.Export.List.Excel.Option.ReceiveList"
    />
  </kst-page-export>
</template>

<script>
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";
import SearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/DebtPayServices.js";

import Export from "./DbpyExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    SearchCatalog: SearchCatalog,
    searchData: {
      Search: "",
      Date: null,
      PaymentTypeName: null,
      StatusID: null,
      VendorID: null,
      VendorName: "",
      WarehouseID: null
    },
    userData: {
      WithList: true,
      WithReceiveList: true
    },
    searchParams: ["Search", "Date", "PaymentTypeName", "StatusID", "VendorID",
      "VendorName", "WarehouseID"],
    // func
    exportExcelFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
      this.userData.WithReceiveList = true;
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      this.searchData.StatusID = this.$kst.Search.OptionAll;
      this.searchData.VendorID = null;
      this.searchData.VendorName = "";
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const paymentType =
        this.$kst.Search.getValue(this.searchData.PaymentTypeName, null) === null
        ? null
        : this.searchData.PaymentTypeName;
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        paymenttype: paymentType,
        status: this.$kst.Search.getValue(this.searchData.StatusID),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.List.Search.Fields,
        withreceivelist: this.$kst.Out.getBoolean(this.userData.WithReceiveList)
      };
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>