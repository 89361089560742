<template>
  <kst-section :title="sectionTitle">
    <template #top-right>
      <kst-value class="title is-5"
        field="Total" :data="summaryData" :catalog="Catalog"
      />
    </template>

    <kst-table view-mode :data="summaryData.TransactionList">
      <b-table-column
        v-slot="{ row }"
        field="Label"
        :label="PlosSummaryCatalog.ModuleName.Label"
      >
        {{ row.Label }}
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="AddTotal"
        :label="PlosSummaryCatalog.AddTotal.Label"
      >
        <template v-if="row.AddTotal !== null">
          <kst-value :field="column.field" :data="row" :catalog="PlosSummaryCatalog"/>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        cell-class="ks-col-action ks-col-action-view-padding"
        field="AddTotal"
      >
        <template v-if="row.AddTotal !== null">
          <kst-button action-mode
            :class="Info.Button.Transaction.Class"
            :hasAccess="row.HasAccess"
            :label="Info.Button.Transaction.Label"
            :module="row.Module"
            :moduleType="Info.Button.Transaction.ModuleType"
            :urlParams="getUrlParams(row.AddSearch, row.AddParams)"
          />
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="SubTotal"
        :label="PlosSummaryCatalog.SubTotal.Label"
      >
        <template v-if="row.SubTotal !== null">
          <kst-value :field="column.field" :data="row" :catalog="PlosSummaryCatalog"/>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        cell-class="ks-col-action ks-col-action-view-padding"
        field="SubTotal"
      >
        <template v-if="row.SubTotal !== null">
          <kst-button action-mode
            :class="Info.Button.Transaction.Class"
            :hasAccess="row.HasAccess"
            :label="Info.Button.Transaction.Label"
            :module="row.Module"
            :moduleType="Info.Button.Transaction.ModuleType"
            :urlParams="getUrlParams(row.SubSearch, row.SubParams)"
          />
        </template>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../PlosCatalog.js";
import Info from "../PlosInfo.js";
import PlosSummaryCatalog from "../PlosSummaryCatalog.js";
import PlosTransSearchCatalog from "../PlosTransSearchCatalog.js";

export default {
  mixins: [RouterMixin],
  props: {
    summaryData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    PlosSummaryCatalog: PlosSummaryCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Summary.Title;
    }
  },
  methods: {
    getUrlParams(search, params) {
      const urlQueryObj = this.getUrlQueryObj(
        search, params, PlosTransSearchCatalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  }
}
</script>