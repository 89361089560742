<template>
  <kst-page-edit autoValidate
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :receiveValidation="receiveValidation"
      :userData="userData"
    />
    <Receive :access="access" :receiveData="receiveData"/>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import Receive from "./RcvED_Receive.vue";
import Details from "./RcvED_Details.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Receive,
    Details
  },
  data: () => ({
    access: {},
    receiveData: {},
    receiveValidation: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();
      
      const currentDate = this.$kst.Out.getDateString(this.userData.CurrentDate);
      const config = Services.getEditDateData(id, currentDate);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.receiveData = data.Details;
      this.receiveValidation = {
        MaxDate: data.MaxDateValidation,
        MinDate: data.MinDateValidation
      };
      
      for (const item of this.receiveData.Items) {
        Model.updateItemReadOnly(item);
      }

      Model.EditDate.setDetailsByData(this.userData, this.receiveData);
    },

    handleSubmit(saveData) {
      let data = Model.EditDate.populateData(this.userData);
      const config = Services.putDate(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.receiveData = {};
      this.receiveValidation = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>