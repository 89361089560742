import { render, staticRenderFns } from "./VenD.vue?vue&type=template&id=65a2e108&scoped=true"
import script from "./VenD.vue?vue&type=script&lang=js"
export * from "./VenD.vue?vue&type=script&lang=js"
import style0 from "./_layout.css?vue&type=style&index=0&id=65a2e108&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a2e108",
  null
  
)

export default component.exports