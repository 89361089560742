<template>
  <kst-section :title="Info.Section.CompanyProfile.Title">
    <kst-output field="CompanyName" :data="details" :catalog="Catalog"/>
    <kst-output field="CompanyAddress" :data="details" :catalog="Catalog"/>
    <kst-output field="CompanyPhone" :data="details" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../WhsCatalog.js";
import Info from "../WhsInfo.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
  }),
}
</script>