<template>
  <kst-page-export-details
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithDetails"
      :label="Info.Export.Details.Option.Details"
    />
    <kst-check
      v-if="access.Delivery_Act_List"
      v-model="userData.WithDeliveryList"
      :label="Info.Export.Details.Option.DeliveryList"
    />
    <kst-check
      v-if="access.RtnInvoice_Act_List"
      v-model="userData.WithRtnInvoiceList"
      :label="Info.Export.Details.Option.RtnInvoiceList"
    />
    <kst-check
      v-if="access.StockReduction_Act_List"
      v-model="userData.WithStockReductionList"
      :label="Info.Export.Details.Option.StockReductionList"
    />
    <kst-check
      v-if="access.Act_List"
      v-model="userData.WithInvoiceReplaceList"
      :label="Info.Export.Details.Option.InvoiceReplaceList"
    />
    <kst-check
      v-if="access.Tab_Profit"
      v-model="userData.WithProfit"
      :label="Info.Export.Details.Option.Profit"
    />
    <kst-check
      v-if="access.SalesRecap_Act_Details"
      v-model="userData.WithSalesRecapDetails"
      :label="Info.Export.Details.Option.SalesRecap"
    />
    <kst-check
      v-if="access.RecPay_Act_List"
      v-model="userData.WithRecPayList"
      :label="Info.Export.Details.Option.RecPayList"
    />
  </kst-page-export-details>
</template>

<script>
import Info from "../InvInfo.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Export from "./InvExD_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    access: {},
    userData: {
      ID: null,
      WithDetails: true,
      WithDeliveryList: true,
      WithProfit: true,
      WithRecPayList: true,
      WithRtnInvoiceList: true,
      WithSalesRecapDetails: true,
      WithStockReductionList: true,
      WithInvoiceReplaceList: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id) {
      this.resetData();
      this.userData.ID = id;
    },

    handleExport(loadExport) {
      const options = {
        withdeliverylist: this.$kst.Out.getBoolean(
          this.userData.WithDeliveryList),
        withprofit: this.$kst.Out.getBoolean(this.userData.WithProfit),
        withrecpaylist: this.$kst.Out.getBoolean(
          this.userData.WithRecPayList),
        withrtninvoicelist: this.$kst.Out.getBoolean(
          this.userData.WithRtnInvoiceList),
        withsalesrecapdetails: this.$kst.Out.getBoolean(
          this.userData.WithSalesRecapDetails),
        withstockreductionlist: this.$kst.Out.getBoolean(
          this.userData.WithStockReductionList),
        withinvoicereplacelist: this.$kst.Out.getBoolean(
          this.userData.WithInvoiceReplaceList)
      };

      const config = Services.getExportDetails(this.userData.ID, options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.ID = null;
      this.userData.WithDetails = true;
      this.userData.WithDeliveryList = this.access.Delivery_Act_List;
      this.userData.WithRecPayList = this.access.RecPay_Act_List;
      this.userData.WithRtnInvoiceList = this.access.RtnInvoice_Act_List;
      this.userData.WithStockReductionList = this.access.StockReduction_Act_List;
      this.userData.WithProfit = this.access.Tab_Profit;
      this.userData.WithSalesRecapDetails = this.access.SalesRecap_Act_Details;
      this.userData.WithInvoiceReplaceList = this.access.Act_List;
    }
  }
}
</script>