<template>
  <kst-section :title="sectionTitle">
    <kst-input field="SelfWarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

export default {
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog,
    Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.SelfService.Title;
    }
  }
}
</script>