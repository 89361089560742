import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Alias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    MaxLength: 20,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  CreditAmount: {
    Label: AppCatalog.Field.CreditAmount.Label,
    Type: AppCatalog.Field.CreditAmount.Type,
    Output: AppCatalog.Field.CreditAmount.Output,
    ReadOnly: AppCatalog.Field.CreditAmount.ReadOnly
  },
  DaysDueDate: {
    Label: AppCatalog.Field.DaysDueDate.Label,
    Type: AppCatalog.Field.DaysDueDate.Type,
    MinValue: AppCatalog.Field.DaysDueDate.MinValue,
    MaxLength: AppCatalog.Field.DaysDueDate.MaxLength,
    Required: true
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    ExportLabel: AppCatalog.Field.DiscPercent.ExportLabel,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  Email: {
    Label: AppCatalog.Field.Email.Label,
    Type: AppCatalog.Field.Email.Type,
    MaxLength: 50,
    Highlight: true
  },
  Mobile: {
    Label: AppCatalog.Field.MobilePhone.Label,
    Type: AppCatalog.Field.MobilePhone.Type,
    MaxLength: 50,
    Highlight: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100,
    Required: true,
    Highlight: true
  },
  OverDueDate: {
    Label: AppCatalog.Field.OverDueDate.Label,
    Type: AppCatalog.Field.OverDueDate.Type,
    ReadOnly: true,
    NullText: AppCatalog.Message.None
  },
  Phone: {
    Label: AppCatalog.Field.Phone.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 50,
    Highlight: true
  },
  RecTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Rec.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  SelfServicePin: {
    Label: AppCatalog.Field.Pin.Label,
    Type: AppCatalog.Field.Pin.Type,
    Required: true
  },
  SelfServiceUrl: {
    Label: AppCatalog.Field.Url.Label,
    ExportLabel: AppCatalog.Field.Url.Label +" "+ AppModule.SalesSelfService.FullName,
    Type: AppCatalog.Field.Url.Type,
    ReadOnly: true
  }
}