<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Invoice
      :userData="userData"
      :warehouseList="warehouseList"
      @reset="handleInvoice_Reset"
      @submit="handleInvoice_Submit"
    />

    <template v-if="userData.SOID">
      <Details :userData="userData"/>
      <Items
        :invoiceData="invoiceData"
        :userData="userData"
        :userItems="userItems"
      />
      <Total :userData="userData"/>
    </template>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../DlvCatalog.js";
import Model from "../DlvModel.js";
import Services from "../../../services/Api/DeliveryServices.js";

import Details from "./DlvN_Details.vue";
import Invoice from "./DlvN_Invoice.vue";
import Items from "./DlvN_Items.vue";
import Total from "./DlvN_Total.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    Details,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    invoiceData: {},
    warehouseList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const invoiceId = this.getUrlParam(Catalog.SOID.Param);
      const options = { invoice: invoiceId };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseList = data.WarehouseList;

      if (data.InvoiceDetails) {
        this.updateByInvoice(data.InvoiceDetails, data.DeliveryItemList);
      }
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** by invoice ***/

    handleInvoice_Reset() {
      this.resetData();
    },
    handleInvoice_Submit(data) {
      this.resetData();
      this.updateByInvoice(data.InvoiceDetails, data.DeliveryItemList);
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.invoiceData = {};
    },

    updateByInvoice(invoiceDetails, deliveryItems) {
      this.invoiceData = invoiceDetails;
      Model.New.updateInvoiceItems(this.invoiceData, deliveryItems);

      Model.setItemsByInvoice(this.userItems, this.invoiceData);
      Model.setDetailsByInvoice(this.userData, this.invoiceData);
    }
  }
}
</script>