import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, deliveryData, statusEnum) {
    userData.ID = deliveryData.ID;

    if (deliveryData.Status === statusEnum.Draft.ID) {
      userData.Status = statusEnum.InDelivery.ID;
      userData.DepartureDate = App.In.getDate(deliveryData.DepartureDate);
    }
    else if (deliveryData.Status === statusEnum.InDelivery.ID) {
      userData.Status = statusEnum.Delivered.ID;
    }
  },

  populateDetails(userData, statusEnum) {
    if (userData.Status === statusEnum.InDelivery.ID) {
      return {
        ID: userData.ID,
        DepartureDate: App.Out.getDateString(userData.DepartureDate),
        Status: userData.Status
      };
    }
    else if (userData.Status === statusEnum.Delivered.ID) {
      return {
        ID: userData.ID,
        ArrivalDate: App.Out.getDateString(userData.ArrivalDate),
        CompletedDate: App.Out.getDateString(userData.CompletedDate),
        Status: userData.Status
      };
    }
  }
}