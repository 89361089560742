import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import Catalog from "./RcpyCatalog.js";
import ModelBody from "./RcpyModel_Body.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByClient(userData, clientData) {
    // user input
    userData.ClientID = clientData.ID;
    userData.ClientName = clientData.Name;
    // by system
    userData.ClientAlias = clientData.Alias;
    userData.ClientCreditAmount = clientData.CreditAmount;
    userData.ClientOverDueDate = clientData.OverDueDate === null
      ? null : clientData.OverDueDate;
    userData.ClientRecTotal = clientData.RecTotal;
  },
  setDetailsByPaymentList(userData, paymentList) {
    userData.PaymentTypeName = PaymentTypeModel.getDefault(paymentList,
      userData.PaymentTypeName);
  },
  setDetailsByDetailsVerified(userData) {
    userData.IsVerified = true;
    App.Array.truncate(userData.SelectedInvoiceList);
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setInvoiceByPaymentValue(userData, userInvoiceList, selectedInvoice) {
    const selectedPaymentValue = App.Out.getInteger(
      selectedInvoice.PaymentValue
    );

    if (selectedPaymentValue === null) {
      const index = userData.SelectedInvoiceList.indexOf(selectedInvoice);
      if (index !== -1) {
        userData.SelectedInvoiceList.splice(index, 1);
      }
    }
    else {
      const index = userData.SelectedInvoiceList.indexOf(selectedInvoice);
      if (index === -1) {
        userData.SelectedInvoiceList.push(selectedInvoice);
      }
    }

    this.updateDetails(userData, userInvoiceList);
  },

  setInvoiceListBySearch(userInvoiceList, invoiceList) {
    App.Array.truncate(userInvoiceList);

    let userInvoice;
    for (const invoice of invoiceList) {
      userInvoice = ModelBody.createInvoice();
      this.setInvoiceListBySearch_setInvoice(userInvoice, invoice);

      userInvoiceList.push(userInvoice);
    }
  },
  setInvoiceListBySearch_setInvoice(userInvoice, invoice) {
    userInvoice.InvoiceID = invoice.ID;
    // by system
    userInvoice.InvoiceNumber = invoice.SONumber;
    userInvoice.InvoiceDate = invoice.InvoiceDate;
    userInvoice.InvoiceBalance = invoice.RecTotal;
    userInvoice.InvoiceStatus = invoice.Status;
    userInvoice.InvoiceDueDate = invoice.DueDate;
    userInvoice.ClientID = invoice.ClientID;
    userInvoice.ClientName = invoice.Client;
    userInvoice.WarehouseID = invoice.WarehouseID;
    userInvoice.WarehouseName = invoice.WarehouseName;
  },
  setInvoiceListByCheck(userData, userInvoiceList, selectedInvoiceList,
    selectedInvoice
  ) {
    if (selectedInvoiceList.includes(selectedInvoice)) {
      let totalSelectedPaymentValue = 0;
      for (const row of selectedInvoiceList) {
        if (row !== selectedInvoice) {
          totalSelectedPaymentValue += App.Out.getInteger(row.PaymentValue);
        }
      }

      const userPaymentValue = App.Out.getInteger(userData.PaymentValue);
      const availPaymentValue = userPaymentValue - totalSelectedPaymentValue;
      if (availPaymentValue === 0) {
        const index = selectedInvoiceList.indexOf(selectedInvoice);
        if (index !== -1) {
          selectedInvoiceList.splice(index, 1);
        }
      }
      else if (availPaymentValue >= selectedInvoice.InvoiceBalance) {
        selectedInvoice.PaymentValue = App.In.getInteger(
          selectedInvoice.InvoiceBalance
        );
      }
      else {
        selectedInvoice.PaymentValue = App.In.getInteger(availPaymentValue);
      }
    }
    else {
      selectedInvoice.PaymentValue = "";
    }

    this.updateDetails(userData, userInvoiceList);
  },
  setInvoiceListByCheckAll(userData, userInvoiceList) {
    let userInvoice, totalSelectedPaymentValue = 0;
    for (userInvoice of userInvoiceList) {
      totalSelectedPaymentValue += App.Out.getInteger(userInvoice.PaymentValue);
    }

    let selectedInvoiceList = [];
    const userPaymentValue = App.Out.getInteger(userData.PaymentValue);
    let availPaymentValue = userPaymentValue - totalSelectedPaymentValue;
    for (userInvoice of userInvoiceList) {
      if (App.Out.getInteger(userInvoice.PaymentValue) !== null) {
        selectedInvoiceList.push(userInvoice);
        continue;
      }

      if (availPaymentValue === 0) {
        // do nothing
      }
      else if (availPaymentValue >= userInvoice.InvoiceBalance) {
        userInvoice.PaymentValue = App.In.getInteger(
          userInvoice.InvoiceBalance
        );
        availPaymentValue -= userInvoice.InvoiceBalance;
        selectedInvoiceList.push(userInvoice);
      }
      else {
        userInvoice.PaymentValue = App.In.getInteger(availPaymentValue);
        availPaymentValue = 0;
        selectedInvoiceList.push(userInvoice);
      }
    }

    userData.SelectedInvoiceList = selectedInvoiceList;
    this.updateDetails(userData, userInvoiceList);
  },
  setInvoiceListByUncheckAll(userData, userInvoiceList) {
    for (let userInvoice of userInvoiceList) {
      userInvoice.PaymentValue = "";
    }

    this.updateDetails(userData, userInvoiceList);
  },

  getInvalidInvoiceTotal(userData) {
    return AppCatalog.Message.MustEqualsTo + " " +
      App.Format.getDecimal(userData.PaymentValue) + " (" +
      Catalog.PaymentValue.Label + ")";
  },

  clearDetailsByClient(userData) {
    // user input
    userData.ClientID = null;
    userData.ClientName = "";
    // by system
    userData.ClientAlias = "";
    userData.ClientCreditAmount = 0;
    userData.ClientOverDueDate = null;
    userData.ClientRecTotal = 0;
  },

  resetInvoiceTotal(userData) {
    userData.InvoiceTotal = 0;
  },

  updateDetails(userData, userInvoiceList) {
    let paymentValue;
    userData.InvoiceTotal = 0;

    for (const userInvoice of userInvoiceList) {
      paymentValue = App.Out.getInteger(userInvoice.PaymentValue);
      userData.InvoiceTotal += paymentValue;
    }
  }
}