<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../PlosCatalog.js";
import Info from "../PlosInfo.js";
import PlosSummaryCatalog from "../PlosSummaryCatalog.js";
import Model from "../PlosModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    searchData: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      const summaryData = Model.Export.getSummary(data.TransactionDetails,
        this.searchData
      );
      this.addReport(summaryData);

      this.download(fileName);
    },

    addReport(summaryData) {
      this.addColumnHeader(Catalog.Total);
      this.addCellValue("Total", summaryData, Catalog);

      this.addRow();
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(PlosSummaryCatalog.ModuleName);
      this.addColumnHeader(PlosSummaryCatalog.AddTotal);
      this.addColumnHeader(PlosSummaryCatalog.SubTotal);

      for (const row of summaryData.TransactionList) {
        this.addRow();

        // value
        this.addSequenceCell();
        this.addCell(row.Label);
        this.addCellValue("AddTotal", row, PlosSummaryCatalog);
        this.addCellValue("SubTotal", row, PlosSummaryCatalog);
      }

      this.addSheet(Info.Export.Excel.SheetName.Report);
    }
  }
}
</script>