import AppCatalog from "../../../services/App/AppCatalog.js";
import AppModule from "../../../services/App/AppModule.js";
import Catalog from "../RcpyCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.PayNumber.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param 
  },

  ClientID: {
    Label: Catalog.ClientID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "ClientName",
    Param: AppModule.Client.Param + AppCatalog.Field.ID.Param
  },
  ClientName: {
    Label: Catalog.ClientID.Label,
    Type: Catalog.ClientName.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: Catalog.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  PaymentTypeName: {
    Label: Catalog.PaymentTypeName.Label,
    Type: Catalog.PaymentTypeName.Type,
    Input: Catalog.PaymentTypeName.Input,
    Param: AppModule.PaymentType.Param
  },
  StatusID: {
    Label: Catalog.StatusID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}