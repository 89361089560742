<template>
<div>
    <Header/>
    <div id="header_space"/>
    <div class="base-background"/>
    <div class="second-background"/>
    <slot/>
    <Footer/>
    <div id="scrollup_button" v-if="showScrollUpButton" @click="scrollToTop()">
      <i class="fas fa-long-arrow-alt-up"></i>
    </div>
</div>
</template>
<script>
import Header from "../Header/Header.vue";
import Footer from "../Footer/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  data: () => ({
    showScrollUpButton: false
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      // Any code to be executed when the window is scrolled
      if(window.scrollY > 100) {
          document.getElementById("content").className = "contentbgscroll";
      } else {
          document.getElementById("content").className = "contentbg";
      }
      if(window.scrollY > 1000) {
          this.showScrollUpButton = true;
      } else {
          this.showScrollUpButton = false;
      }
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style scoped lang="css" src="./_layout.css"/>