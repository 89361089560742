<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-4">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        class="ks-search-warehouse"
        field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <template v-if="status">
      <div class="column is-narrow">
        <kst-input floating-label
          class="ks-search-status-all"
          field="StatusID" :data="searchData" :catalog="SearchCatalog"
          :options="statusOptions" @input="handleInput"
        />
      </div>
    </template>
    <div class="column is-narrow">
      <kst-input floating-label bottom-left
        field="Date" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./SearchCatalog.js";
import Model from "../InvModel.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object,
    // field visibility (alphabetical order)
    status: Boolean
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  computed: {
    statusOptions() {
      return Model.createStatusOptions(SearchCatalog.StatusID.Label);
    }
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>