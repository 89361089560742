<template>
  <kst-table paginated view-mode
    :action="Model.List.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Alias"
      :label="Catalog.Alias.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Mobile"
      :label="Catalog.Mobile.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Phone"
      :label="Catalog.Phone.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Email"
      :label="Catalog.Email.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="OverDueDate"
      :label="Catalog.OverDueDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="DebtTotal"
      :label="Catalog.DebtTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.List.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../VenCatalog.js";
import Model from "../VenModel.js";

export default {
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>