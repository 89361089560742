<template>
  <div>
    <kst-load-data activeOnLoad @init="handleLoad_Init" />
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-input load-on-focus field="Name" :data="userData" :catalog="Catalog"/>
    <kst-output control-off field="Email" :data="userData" :catalog="Catalog"/>
    <kst-input field="Phone" :data="userData" :catalog="Catalog"/>

    <template v-if="userData.AccessIsOwner">
      <kst-output field="AccessName" :data="userData" :catalog="Catalog"/>
    </template>
    <template v-else>
      <kst-input
        field="AccessID" :data="userData" :catalog="Catalog"
        :options="accessOptions" @input="handleInput_AccessID"
      />
    </template>

    <kst-input field="DefaultWarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input field="DefaultModuleID" :data="userData" :catalog="Catalog"
      :options="defaultModuleOptions"
    />
    <kst-input :disabled="userData.AccessIsOwner"
      field="IsActive" :data="userData" :catalog="Catalog"
    />
  </div>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Info from "../UserInfo.js";
import Model from "../UserModel.js";
import Services from "../../../services/Api/UserServices.js";

export default {
  props: {
    userData: Object,
    accessOptions: Object,
    moduleList: Array,
    warehouseOptions: Object,
  },
  data: () => ({
    Catalog: Catalog,
    defaultModuleOptions: {},
    // func
    loadDataFunc: null,
  }),
  computed: {
    detailsWarning() {
      let result = [Info.Message.Edit_StaticEmail]

      if (this.userData.AccessIsOwner) {
        result.push(Info.Message.Edit_IsOwner, Info.Message.Edit_StaticActive);
      }
      
      if (this.userData.IsReachLimit) { 
        result.push(Info.Message.Edit_MaxUsers, Info.Message.Edit_MaxUsers2);
      }
      return result;
    }
  },
  created() {
    this.init();
  },
  methods: {
    handleLoad_Init(loadData) {
      this.loadDataFunc = loadData;
    },
    
    handleInput_AccessID() {
      this.userData.DefaultModuleID = null;
      this.defaultModuleOptions = {};
      this.loadAccessModuleData();
    },

    loadAccessModuleData() {
      const config = Services.getRelatedAccessAccessibility(this.userData.AccessID);

      this.loadDataFunc(config, this.loadAccessModuleData_Success, true);
    },
    loadAccessModuleData_Success(data){
      this.defaultModuleOptions = Model.createDefaultModuleOptions(data.ModuleList);
    },

    init() {
      this.defaultModuleOptions = Model.createDefaultModuleOptions(this.moduleList);
    }
  }
}
</script>