<template>
  <kst-table paginated view-mode
    :action="Model.List.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="StockTrfNumber"
      :label="Catalog.StockTrfNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SourceWarehouseID"
      :label="Catalog.SourceWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DestinationWarehouseID"
      :label="Catalog.DestinationWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.List.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../SttrCatalog.js";
import Model from "../SttrModel.js";

export default {
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>