<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="data"
      dateField="Date"
      numberField="ExpenseNumber"
    />

    <kst-output field="SubCategoryID" :data="data" :catalog="Catalog"/>
    <template v-if="data.WarehouseID === null">
      <kst-output field="WarehouseName" :data="data" :catalog="Catalog"/>
    </template>
    <template v-else>
      <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
    </template>
    <kst-output field="PaymentTypeName" :data="data" :catalog="Catalog"/>
    <kst-output field="Total" :data="data" :catalog="Catalog"/>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-created :data="data"/>
    <kst-last-updated :data="data"/>
  </div>
</template>

<script>
import Catalog from "../ExpCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>