import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.ModuleAttr,
  ModuleType: {
    List: AppModuleType.List
  },

  /*** related ***/

  /*** property ***/

  Type: {
    // group: Field, Section
    Interface: "Tampilan (Tab/Section)",

    // single
    Action: {
      ID: 5,
      Label: "Action"
    },
    Field: {
      ID: 2,
      Label: "Field"
    },
    Global: {
      ID: 1,
      Label: "Global"
    },
    Section: {
      ID: 3,
      Label: "Section"
    },
    Tab: {
      ID: 4,
      Label: "Tab"
    }
  },

  /*** method ***/

  getList(items, moduleList) {
    return this.helper.getList(items, moduleList);
  },

  getList_Edit(items, moduleList, checkedModuleAttrList) {
    return this.helper.getList_Edit(items, moduleList, checkedModuleAttrList);
  },

  getSubLabel(subCode) {
    return this.helper.getSubLabel(subCode);
  },

  hasSelected(actionItems, fieldItems, interfaceItems) {
    return this.helper.hasSelected(actionItems, fieldItems, interfaceItems);
  },

  selectListByModule(items, moduleId) {
    this.helper.selectListByModule(items, moduleId);
  },
  selectListByModuleAllOption(items, moduleId) {
    this.helper.selectListByModuleAllOption(items, moduleId);
  },
  selectListByModuleAttr(items) {
    this.helper.selectListByModuleAttr(items);
  },
  selectAllList(items) {
    this.helper.selectAllList(items);
  },
  unselectListByModule(items, moduleId) {
    this.helper.unselectListByModule(items, moduleId);
  },
  unselectListByModuleNoneOption(items, moduleId) {
    this.helper.unselectListByModuleNoneOption(items, moduleId);
  },
  unselectAllList(items) {
    this.helper.unselectAllList(items);
  },

  /*** external ***/

  helper: {
    getList(items, moduleList) {
      let defaultAttrIdList = [];
      for (const module of moduleList) {
        defaultAttrIdList.push(module.DefaultAttrID);
      }

      for (const item of items) {
        // user selection
        item.IsSelected = false;
        // indicator
        item.IsDefault = defaultAttrIdList.includes(item.ID);
      }

      return items;
    },

    getList_Edit(items, moduleList, checkedModuleAttrList) {
      let defaultAttrIdList = [];
      for (const module of moduleList) {
        defaultAttrIdList.push(module.DefaultAttrID);
      }

      let selectedAttrIdList = [];
      for (const moduleAttr of checkedModuleAttrList) {
        selectedAttrIdList.push(moduleAttr.ModuleAttrID);
      }

      for (const item of items) {
        // user selection
        item.IsSelected = selectedAttrIdList.includes(item.ID);
        // indicator
        item.IsDefault = defaultAttrIdList.includes(item.ID);
      }

      return items;
    },

    getSubLabel(subCode) {
      const appModule = AppModule[subCode];
      return appModule.FullName;
    },

    hasSelected(actionItems, fieldItems, interfaceItems) {
      let item;

      for (item of actionItems) {
        if (item.IsSelected) {
          return true;
        }
      }

      for (item of fieldItems) {
        if (item.IsSelected) {
          return true;
        }
      }

      for (item of interfaceItems) {
        if (item.IsSelected) {
          return true;
        }
      }

      return false;
    },

    selectListByModule(items, moduleId) {
      for (const item of items) {
        if (item.ModuleID === moduleId) {
          if (item.IsDefault) {
            // only select default module attr
            item.IsSelected = true;
            return;
          }
        }
      }
    },
    selectListByModuleAllOption(items, moduleId) {
      for (const item of items) {
        if (item.ModuleID === moduleId) {
          item.IsSelected = true;
        }
      }
    },
    selectListByModuleAttr(items) {
      for (const item of items) {
        if (item.IsDefault) {
          item.IsSelected = true;
          return;
        }
      }
    },
    selectAllList(items) {
      for (const item of items) {
        if (item.IsDefault) {
          item.IsSelected = true;
        }
      }
    },
    unselectListByModule(items, moduleId) {
      for (const item of items) {
        if (item.ModuleID === moduleId) {
          item.IsSelected = false;
        }
      }
    },
    unselectListByModuleNoneOption(items, moduleId) {
      for (const item of items) {
        if (item.ModuleID === moduleId) {
          if (!item.IsDefault) {
            item.IsSelected = false;
          }
        }
      }
    },
    unselectAllList(items) {
      for (const item of items) {
        if (item.ModuleID !== null) {
          item.IsSelected = false;
        }
      }
    }
  }
}