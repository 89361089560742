import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import InvItemCatalog from "./InvItemCatalog.js";
import Model from "./InvModel.js";
import Catalog from "./InvCatalog.js";

export default {
  Button: {
    InvoiceReplace: {
      Label: AppModuleType.New.Name + " " + Model.Module.FullName + " Pengganti",
      Module: AppModule.Invoice,
      ModuleType: {
        New: AppModuleType.New
      }
    }
  },
  Dialog: {
    ItemSearch: {
      Title: AppModuleType.Search.Name + " " + Model.InvoiceItem.Module.FullName +
        " dari " + Model.Module.FullName
    },
    SearchItem: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.InvoiceItem.Module.Class +
        Model.InvoiceItem.ModuleType.SearchList.Class,
      Title: AppModuleType.Search.Name + " " + Model.InvoiceItem.Module.FullName +
        " dari " + Model.Module.FullName
    },
    SearchListPrice: {
      Title: AppModuleType.SearchList.FullName + " " +
        AppCatalog.Field.SellPrice.Label
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          Delivery: Model.Delivery.Module.FullName,
          Profit: AppCatalog.Info.Profit,
          RecPay: Model.RecPay.Module.FullName,
          Return: AppCatalog.Info.Return,
          SalesRecap: Model.SalesRecap.Module.FullName
        }
      },
      // support
      Option: {
        DeliveryList: AppModuleType.List.Name + " " +
          Model.Delivery.Module.FullName,
        Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
        InvoiceReplaceList: AppModuleType.List.Name + " " +
          AppModule.Invoice.FullName + " Pengganti",
        Profit: AppCatalog.Info.Profit,
        RecPayList: AppModuleType.List.Name + " " + Model.RecPay.Module.FullName,
        RtnInvoiceList: AppModuleType.List.Name + " " +
          Model.RtnInvoice.Module.FullName,
        SalesRecap: Model.SalesRecap.Module.FullName,
        StockReductionList: AppModuleType.List.Name + " " +
          Model.StockReduction.Module.FullName
      },
      Section: {
        ProfitInvoice: AppModuleType.List.Name + " " + AppModule.Item.FullName +
          " di " + Model.Module.FullName,
        ProfitRtnInvoice: Model.RtnInvoice.ModuleType.List.Name + " " +
          Model.RtnInvoice.Module.FullName
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Invoice.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Invoice.FullName,
          InvoiceItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Details_NoDelivery:
      Model.Module.FullName + " ini tidak memerlukan " +
      Model.Delivery.Module.FullName + ".",
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.InvoiceItem.Module.FullName + "?",
    Edit_SalesFields:
      "Tidak diperkenankan mengubah " + Catalog.SalesDraftID.Label + " dan " +
      Catalog.DraftDate.Label + ".",
    New_ClientDiscPercent:
      AppCatalog.Field.Disc.Label + " " + AppModule.Client.Name +
      " Diterapkan",
    New_Client_ConfirmDelete: [
      "Yakin menghapus " + Model.Client.Module.FullName + "?",
      "Akan me-reset data " + Model.RecPay.Module.FullName + "."
    ],
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.InvoiceItem.Module.FullName + "?",
    New_Sales:
      "Memilih / menghapus " + Model.Sales.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + "."
  },
  PriceSearch: {
    SellPrice: {
      High: {
        Icon: "mdi mdi-arrow-up-bold",
        IconClass: "has-text-success",
        TooltipClass: "is-success",
        TooltipText: InvItemCatalog.SellPrice.Label + " lebih mahal"
      },
      Low: {
        Icon: "mdi mdi-arrow-down-bold",
        IconClass: "has-text-danger",
        TooltipClass: "is-danger",
        TooltipText: InvItemCatalog.SellPrice.Label + " lebih murah"
      }
    }
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    // general
    Delivery: {
      Title: Model.Delivery.Module.FullName
    },
    InvoiceReplaceList:{
      Title: AppModuleType.List.Name + " " + 
        AppModule.Invoice.FullName + " Pengganti"
    },
    ItemSearch: {
      Title: AppCatalog.Section.SelectOne
    },
    ProfitInvoice: {
      Title: AppModuleType.List.Name + " " + AppModule.Item.FullName + " di " +
        Model.Module.FullName
    },
    ProfitRtnInvoice: {
      Title: Model.RtnInvoice.ModuleType.List.Name + " " +
        Model.RtnInvoice.Module.FullName
    },
    RecPay: {
      Title: Model.RecPay.Module.FullName
    },
    RtnInvoiceList: {
      Title: AppModuleType.List.Name + " " + AppModule.RtnInvoice.FullName
    },
    Sales: {
      Title: "Info " + Model.Sales.Module.FullName
    },
    StockReductionList: {
      Title: AppModuleType.List.Name + " " + AppModule.StockReduction.FullName
    },
    Summary: {
      Title: AppCatalog.Info.Summary + " " + Model.InvoiceItem.Module.FullName
    },

    // by action
    PrintFaktur: {
      Footer: [
        AppCatalog.Field.Comment.Label + ":",
        AppCatalog.Message.Invoice_Statement
      ],

      ClientHeader: {
        Title: "Kepada YTH,"
      },
      Client: {
        Header: "Penerima"
      },
      Seller: {
        Header: "Penjual"
      },
      Sign: {
        FooterTitle: "Tgl."
      }
    }
  },
  Tabs: {
    // by tab order
    Details: AppModuleType.Details.Name,
    DeliveryList: Model.Delivery.ModuleType.List.Name + " " +
      Model.Delivery.Module.FullName,
    Profit: AppCatalog.Info.Profit,
    RecPayList: Model.RecPay.ModuleType.List.Name + " " +
      Model.RecPay.Module.FullName,
    Return: AppCatalog.Info.Return,
    SalesRecapList: AppModule.SalesRecap.FullName
  }
}