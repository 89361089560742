import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StopItemImportCatalog from "./StopItemImportCatalog.js";
import Model from "./StopModel.js";

export default {
  Button: {
    ExportTemplate: {
      Title: AppCatalog.Info.Download + " " + AppCatalog.Info.Template
    },
    ItemImport: {
      Title: " Import " + AppModule.Item.Name
    },
    Summary: {
      Title: AppCatalog.Info.Summary,
      ModuleType: AppModuleType.List
    }
  },
  Dialog: {
    Summary: {
      Class: AppCatalog.ClassPrefix.Module + "-" + AppModule.StockOpname.Class + "-" +
        AppModuleType.New.Class,
      Title: AppCatalog.Info.Summary
    }
  },
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.StockOpname.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.StockOpname.FullName,
          StockOpnameItem: "Dengan " + AppModule.Item.FullName
        }
      }
    },
    New: {
      Excel: {
        FileName: {
          Template:  AppCatalog.Info.Template + " Import " + AppModule.Item.FullName +
            AppCatalog.Xlsx.Default.Extension
        },
        SheetName: {
          TemplateListItem: AppCatalog.Info.Template + " " + AppModule.Item.Name,
          TemplateInstruction: AppCatalog.Info.Instruction
        },
        Template: {
          GeneralInfo: {
            HeaderName: AppCatalog.Info.Description + " Umum",
            Content: [
              "User dapat menggunakan data dari download template, data pada Template Daftar Barang saat download template merupakan barang dengan kemasan default (yaitu qty kemasan adalah 1 pcs)",
              "Jika User menggunakan data barang dari download template, User dapat menghapus baris terkait barang yang tidak akan dilakukan stok opname",
              "User diperkenankan untuk menginput secara mandiri terkait daftar barang yang akan di stok opname (pastikan data yang diinput sudah sesuai dengan format yang diatur)",
              "Mohon tidak mengubah judul kolom pada sheet Template Daftar Barang",
              "Mohon tidak mengubah nama sheet pada file excel ini",
              "Mohon tidak mengurangi atau menambahkan kolom pada file excel ini",
              "Silakan edit pada sheet Template Daftar Barang",
              "Apabila kolom pada qty stok dikosongkan, maka data barang dianggap tidak akan dilakukan stok opname",
            ]
          },
          ColumnInfo: {
            HeaderName: AppCatalog.Info.Description + " Kolom",
            Content: [
              {
                Column: "Kolom",
                DetailList: [
                  "Detail"
                ]
              },
              {
                Column: StopItemImportCatalog.ItemID.Label,
                DetailList: [
                  "Masukkan " + StopItemImportCatalog.ItemID.Label + " yang didapat dari " +
                    "hasil export " + AppModule.Item.Name + " pada menu " + 
                    AppModule.Inventaris.FullName + " - " + AppModule.Item.Name,
                  "Pastikan tidak ada lebih dari 1 " + StopItemImportCatalog.ItemID.Label + 
                    " yang sama (duplikasi " + AppModule.Item.FullName + ")",
                ]
              },
              {
                Column: StopItemImportCatalog.ItemName.Label,
                DetailList: [
                  "Pastikan " + StopItemImportCatalog.ItemName.Label + " sesuai dengan " + 
                    StopItemImportCatalog.ItemID.Label,
                ]
              },
              {
                Column: StopItemImportCatalog.PackagingName.Label,
                DetailList: [
                  "Pastikan untuk memperhatikan " + AppCatalog.Field.Qty.Label + 
                    " pada " + AppModule.ItemPackaging.FullName,
                  "Pastikan memasukkan " + StopItemImportCatalog.PackagingName.Label +
                    " yang tersedia pada " + AppModule.Inventaris.FullName + " - " +
                    AppModule.Item.Name,
                ]
              },
              {
                Column: StopItemImportCatalog.PackagingQty.Label,
                DetailList: [
                  AppCatalog.Field.Qty.Label + " setiap " + 
                    AppModule.ItemPackaging.FullName,
                ]
              },
              {
                Column: StopItemImportCatalog.Qty.Label,
                DetailList: [
                  AppCatalog.Field.Qty.Label + " disini sesuai dengan " + 
                    AppModule.ItemPackaging.FullName + " yang dipilih " + 
                    "(contoh: lusin (12), jika '" + StopItemImportCatalog.Qty.Label + 
                    "' diinput 2, maka " + AppCatalog.Field.Stock.Label + " " +
                    AppModule.Item.FullName + " menjadi 24)",
                ]
              }
            ]
          },
          InputExample: {
            HeaderName: "Contoh Input " + AppCatalog.Info.Template + " " + 
              AppModule.Item.Name,
            Info: AppCatalog.Info.Description,
            Content: [
              "25345",
              "Lotion",
              "lusin",
              "12",
              "3",
              "Jika " + AppCatalog.Field.Qty.Label + " pada " + 
                AppModule.ItemPackaging.FullName + " adalah 12, maka pada sistem " + 
                AppCatalog.Field.Stock.Label + " akan menjadi " + 
                "12 pcs x 3 lusin = 26 pcs",
            ]
          }
        },
      }
    }
  },
  Input: {
    ItemImport: {
      Title: "Klik untuk Upload",
      Error: {
        MaxFileSize: "Mohon pastikan ukuran file tidak lebih dari 2 MB.",
        SupportedFile: "Mohon gunakan format yang sesuai (" + 
          AppCatalog.Xlsx.Default.Extension + ").",
        UnsupportedTemplate: "Mohon gunakan format tabel yang sesuai dengan " + 
          "file pada '" + AppCatalog.Info.Download + " " + 
          AppCatalog.Info.Template + "'."
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.StockOpnameItem.Module.FullName + "?",
    New_ImportSupportedFile: "Format file yang didukung adalah " +
      AppCatalog.Xlsx.Default.Extension + ".",
    New_ImportMaxFileSize: "Maksimal ukuran file upload adalah 2 MB.",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  },
  Section: {
    Import: {
      Summary: AppCatalog.Info.Summary,
      InvalidData: "Data Tidak Valid",
      ValidData: "Data Valid"
    }
  }
}