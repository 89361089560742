import App from "../../services/App/App.js";

export default {
  createStatusOptions(statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Draft.ID, [rowLabel]: statusEnum.Draft.Label },
      { [rowId]: statusEnum.InDelivery.ID, [rowLabel]: statusEnum.InDelivery.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  updateInvoiceItems(invoiceData, deliveryItems) {
    let invoiceItems = [];
    let summaryInvoiceItems = this.updateInvoiceItems_getSummaryInvoiceItems(
      invoiceData.Items, deliveryItems
    );

    for (const summaryInvoiceItem of summaryInvoiceItems) {
      if (summaryInvoiceItem.QtyAvailable > 0) {
        invoiceItems.push(summaryInvoiceItem);

        summaryInvoiceItem.Packaging = App.Data.getPackaging(
          summaryInvoiceItem.PackagingName, summaryInvoiceItem.PackagingValue
        );
      }
    }

    const fieldKeys = [{
      [App.Sort.Key.Field]: "Name",
      [App.Sort.Key.Order]: App.Sort.Order.Asc
    }, {
      [App.Sort.Key.Field]: "PackagingValue",
      [App.Sort.Key.Order]: App.Sort.Order.Asc
    }, {
      [App.Sort.Key.Field]: "PackagingName",
      [App.Sort.Key.Order]: App.Sort.Order.Asc
    }];
    App.Sort.begin(invoiceItems, fieldKeys);

    invoiceData.Items = invoiceItems;
  },
  // based on: InvModel.js: getItemsSummaryByDelivery_Details()
  updateInvoiceItems_getSummaryInvoiceItems(invoiceItems, deliveryItems) {
    let fieldKeys, fieldValues;

    // summary invoice items
    fieldKeys = [ "DispatchID", "Name", "PackagingName" ];
    fieldValues = [
      { field: "PackagingValue", type: App.Pivot.Type.FIRST },
      { field: "RequestedQuantity", type: App.Pivot.Type.SUM },
      // additional
      { field: "SKU", type: App.Pivot.Type.FIRST },
      { field: "DispatchImageSrc", type: App.Pivot.Type.FIRST },
      { field: "DispatchWeight", type: App.Pivot.Type.FIRST },
      { field: "DispatchIsActive", type: App.Pivot.Type.FIRST },
    ];
    const pivotInvoiceItems = App.Pivot.create(
      invoiceItems, fieldKeys, fieldValues
    );

    let conditions, deliveryItem, pivotInvoiceItem;
    for (pivotInvoiceItem of pivotInvoiceItems) {
      conditions = {
        DispatchID: pivotInvoiceItem.DispatchID,
        PackagingName: pivotInvoiceItem.PackagingName
      };
      deliveryItem = App.Array.getItem2(deliveryItems, conditions);

      if (deliveryItem) {
        pivotInvoiceItem.QtyAvailable =
          pivotInvoiceItem.RequestedQuantity - deliveryItem.Quantity;
      }
      else {
        pivotInvoiceItem.QtyAvailable = pivotInvoiceItem.RequestedQuantity;
      }
    }

    return pivotInvoiceItems;
  }
}