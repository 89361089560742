import App from "../../services/App/App.js";
import ChartDay from "./OmzModel_Report_ChartDay.js";
import ChartMonth from "./OmzModel_Report_ChartMonth.js";
import Info from "./OmzInfo.js";
import Summary from "./OmzModel_Report_Summary.js";

export default {
  getChart(transactionDetails, searchData) {
    if (searchData.Date[0].getMonth() === searchData.Date[1].getMonth()) {
      return ChartDay.getChart(transactionDetails);
    }
    else {
      const dateDiff = App.Data.getDateDiffInDays(searchData.Date[0],
        searchData.Date[1], true
      );

      if (dateDiff <= 30) {
        return ChartDay.getChart(transactionDetails);
      }
      else {
        return ChartMonth.getChart(transactionDetails);
      }
    }
  },

  getInvoiceSummary(invoiceDetails) {
    let resultList = [];

    resultList.push({
      Name: Info.Section.InvoiceSummary.SellPrice,
      Total: invoiceDetails.SellPrice
    });

    resultList.push({
      Name: Info.Section.InvoiceSummary.SpecialPrice,
      Total: invoiceDetails.SpecialPrice
    });

    resultList.push({
      Name: Info.Section.InvoiceSummary.TotalDisc,
      Total: invoiceDetails.TotalDisc
    });

    return resultList;
  },

  getSummary(transactionDetails, access, searchData) {
    return Summary.getSummary(transactionDetails, access, searchData);
  }
}