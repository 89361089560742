import App from "../../services/App/App.js";
import ModelBody from "./InvModel_Body.js";
import ModelDetails from "./InvModel_Details.js";
import ModelEdit from "./InvModel_Edit.js";
import ModelEditDate from "./InvModel_EditDate.js";
import ModelExportDetails from "./InvModel_ExportDetails.js";
import ModelNew from "./InvModel_New.js";
import ModelPrintThermal from "./InvModel_PrintThermal.js";
import ModelRelatedList from "./InvModel_RelatedList.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  InvoiceItem: ModelBody.InvoiceItem,
  // other
  Client: ModelBody.Client,
  Delivery: ModelBody.Delivery,
  RecPay: ModelBody.RecPay,
  RtnInvoice: ModelBody.RtnInvoice,
  Sales: ModelBody.Sales,
  SalesItem: ModelBody.SalesItem,
  SalesRecap: ModelBody.SalesRecap,
  StockReduction: ModelBody.StockReduction,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  DiscType: ModelBody.DiscType,
  RecordType: ModelBody.RecordType,
  Search: ModelBody.Search,
  Status: ModelBody.Status,
  StatusSalesDraftIsActive: ModelBody.StatusSalesDraftIsActive,
  StatusPos: ModelBody.StatusPos,
  // related:
  InvoiceClientSourceID: ModelBody.InvoiceClientSourceID,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.DiscType);
  },
  createItem(userData) {
    return ModelBody.createItem(userData);
  },
  createRecordTypeOptions(optionAllText) {
    return ModelBody.createRecordTypeOptions(optionAllText, ModelBody.RecordType);
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },
  createDiscTypeOptions(optionAllText) {
    return ModelBody.createDiscTypeOptions(optionAllText, ModelBody.DiscType);
  },
  createSpecialPriceOptions(stockSpecialPriceList, optionNonText) {
    return ModelBody.createSpecialPriceOptions(stockSpecialPriceList,
      optionNonText
    );
  },

  setDetailsByCompanySetting(userData, companySetting) {
    ModelBody.setDetailsByCompanySetting(userData, companySetting);
  },
  setDetailsBySpecialPrice(userData, specialPriceCount) {
    ModelBody.setDetailsBySpecialPrice(userData, specialPriceCount);
  },

  normalizeDetailsByDisc(userData) {
    ModelBody.normalizeDetailsByDisc(userData, ModelBody.DiscType);
  },

  setDetailsByStatus(details) {
    ModelBody.setDetailsByStatus(details, ModelBody.Status);
  },
  setItemByPrice(userItem, priceData) {
    ModelBody.setItemByPrice(userItem, priceData);
  },
  setItemByStock(userItem, stockDetails, itemDetails, stockSpecialPriceList) {
    ModelBody.setItemByStock(userItem, stockDetails, itemDetails,
      stockSpecialPriceList
    );
  },
  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  getItemsForSelection(items, salesItems) {
    return ModelBody.getItemsForSelection(items, salesItems);
  },

  getInvalidItems(details) {
    return ModelBody.getInvalidItems(details);
  },

  getRecPaySectionHelp(userData) {
    return ModelBody.getRecPaySectionHelp(userData);
  },
  getDeliverySectionHelp(data) {
    return ModelBody.getDeliverySectionHelp(data);
  },
  getSalesSectionHelp(data) {
    return ModelBody.getSalesSectionHelp(data);
  },
  getInvoiceSectionHelp(data) {
    return ModelBody.getInvoiceSectionHelp(data);
  },

  getInvoiceDateDisabled(){
    return ModelBody.getInvoiceDateDisabled();
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems, ModelBody.DiscType);
  },
  updateDetailsByDelivery(userData) {
    ModelBody.updateDetailsByDelivery(userData);
  },

  updateItem(item) {
    ModelBody.updateItem(item);
  },
  updateItemBySpecialPrice(item) {
    ModelBody.updateItemBySpecialPrice(item);
  },
  updateItemByPackaging(item) {
    ModelBody.updateItemByPackaging(item);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData, ModelBody.DiscType);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  validateItemsByStock(userData, userItems) {
    ModelBody.validateItemsByStock(userData, userItems);
  },

  /*** method - Details ***/

  Details: {
    setDetailsByProfit(details, rtnInvoiceList) {
      ModelDetails.setDetailsByProfit(details, rtnInvoiceList);
    },

    getActions(id, access) {
      return App.Access.getDetailsAvailableActions(
        id, ModelBody.Actions.Details, ModelBody.Module, access
      );
    },
    getItemInfo(details, item) {
      return ModelDetails.getItemInfo(details, item);
    },
    getItemsSummaryByDelivery(details, deliveryItemList) {
      return ModelDetails.getItemsSummaryByDelivery(
        details, deliveryItemList
      );
    },

    updateRecData(recData, invoiceData) {
      ModelDetails.updateRecData(recData, invoiceData);
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByClient(userData, clientData) {
      ModelEdit.setDetailsByClient(userData, clientData);
    },
    setDetailsByData(userData, invoiceData) {
      ModelEdit.setDetailsByData(userData, invoiceData, ModelBody.DiscType);
    },
    setItemsByData(userData, userItems, invoiceData, stockObj,
      stockSpecialPriceObj, salesItems
    ) {
      ModelEdit.setItemsByData(userData, userItems, invoiceData, stockObj,
        stockSpecialPriceObj, salesItems
      );
    },
    setItemBySalesItem(item, salesItemData, stockDetails, stockSpecialPriceList) {
      ModelEdit.setItemBySalesItem(item, salesItemData, stockDetails,
        stockSpecialPriceList
      );
    },

    getItemInfo(item) {
      return ModelEdit.getItemInfo(item);
    },

    getStockByInvoiceItems(invoiceItems) {
      return ModelEdit.getStockByInvoiceItems(invoiceItems);
    },
    getStockSpecialPriceByInvoiceItems(invoiceItems) {
      return ModelEdit.getStockSpecialPriceByInvoiceItems(invoiceItems);
    },

    clearDetailsByClient(userData) {
      ModelEdit.clearDetailsByClient(userData);
    },
  
    normalizeItem(userItem, stockObj, stockSpecialPriceObj) {
      ModelEdit.normalizeItem(userItem, stockObj, stockSpecialPriceObj);
    },

    updateItemsByStockList(userItems, stockList, stockObj) {
      ModelEdit.updateItemsByStockList(userItems, stockList, stockObj);
    },

    compareData(userData, userItems, invoiceData) {
      return ModelEdit.compareData(userData, userItems, invoiceData,
        ModelBody.DiscType
      );
    },

    validationDeliveryDate(data) {
      return ModelEdit.validationDeliveryDate(data);
    },
    validationDueDate(userData) {
      return ModelEdit.validationDueDate(userData);
    },
    validateItemsByDeliveryItems(userData, userItems, deliveryItemList) {
      ModelEdit.validateItemsByDeliveryItems(userData, userItems,
        deliveryItemList
      );
    },

    /*** external ***/

    updateSalesItems(salesData, invoiceItems) {
      ModelEdit.updateSalesItems(salesData, invoiceItems);
    }
  },

  /*** method - EditDate ***/

  EditDate: {
    setDetailsByData(userData, invoiceData) {
      ModelEditDate.setDetailsByData(userData, invoiceData);
    },

    populateData(userData) {
      return ModelEditDate.populateDetails(userData);
    },

    validationInvoiceDate(data) {
      return ModelEditDate.validationInvoiceDate(data);
    },
  },

  /*** method - ExportDetails ***/

  ExportDetails: {
    setDetailsByProfit(details, rtnInvoiceList) {
      ModelExportDetails.setDetailsByProfit(details, rtnInvoiceList)
    },

    getItemsSummaryByDelivery(details, deliveryItemList) {
      return ModelExportDetails.getItemsSummaryByDelivery(
        details, deliveryItemList
      );
    },

    updateRecData(recData, invoiceData) {
      ModelExportDetails.updateRecData(recData, invoiceData);
    },

    /*** external ***/

    updateDeliveryItem(deliveryItem) {
      ModelExportDetails.updateDeliveryItem(deliveryItem);
    },
    updateRtnInvoiceItem(rtnInvoiceItem) {
      ModelExportDetails.updateRtnInvoiceItem(rtnInvoiceItem);
    },
    updateStockReductionItem(stockReductionItem) {
      ModelExportDetails.updateStockReductionItem(stockReductionItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_EditDate ||
        access.Act_PrintDetails ||
        access.Act_PrintFaktur ||
        access.Act_PrintThermal ||
        access.Act_ExportDetails;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByClient(userData, clientData) {
      ModelNew.setDetailsByClient(userData, clientData, ModelBody.DiscType);
    },
    setDetailsByFullPayment(userData) {
      ModelNew.setDetailsByFullPayment(userData);
    },
    setDetailsByPaymentList(userData, paymentList) {
      ModelNew.setDetailsByPaymentList(userData, paymentList);
    },
    setDetailsBySales(userData, salesData, clientDetails) {
      ModelNew.setDetailsBySales(userData, salesData, ModelBody.DiscType,
        clientDetails
      );
    },
    setDetailsByTransaction(userData, transactionData, clientDetails, 
      warehouseDetails
    ) {
      ModelNew.setDetailsByTransaction(userData, transactionData, clientDetails,
        warehouseDetails, ModelBody.DiscType
      );
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    setItemsBySales(userData, userItems, salesItems) {
      ModelNew.setItemsBySales(userData, userItems, salesItems);
    },
    setItemBySalesItem(item, salesItemData, stockDetails, stockSpecialPriceList) {
      ModelNew.setItemBySalesItem(item, salesItemData, stockDetails,
        stockSpecialPriceList
      );
    },

    getItemInfo(item) {
      return ModelNew.getItemInfo(item);
    },

    clearDetailsByClient(userData) {
      ModelNew.clearDetailsByClient(userData);
    },
    clearDetailsByTransaction(userData) {
      ModelNew.clearDetailsByTransaction(userData, ModelBody.DiscType);
    },

    updateItemsByStockList(userItems, stockList) {
      ModelNew.updateItemsByStockList(userItems, stockList);
    },

    validationDeliveryDate(data) {
      return ModelNew.validationDeliveryDate(data);
    },
    validationDueDate() {
      return ModelNew.validationDueDate();
    },
    validationInvoiceDate(userData){
      return ModelNew.validationInvoiceDate(userData);
    },

    /*** external ***/
  
    updateSalesItems(salesData) {
      ModelNew.updateSalesItems(salesData);
    }
  },

  /*** method - PrintThermal ***/

  PrintThermal: {
    updateDetails(data) {
      ModelPrintThermal.updateDetails(data);
    },
    updateItems(items) {
      ModelPrintThermal.updateItems(items);
    }  
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.RelatedList, ModelBody.Module, access
      );
    },
    getItemInfo(item) {
      return ModelRelatedList.getItemInfo(item);
    },

    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_PrintDetails ||
        access.Act_PrintFaktur ||
        access.Act_PrintThermal ||
        access.Act_ExportDetails;
    }
  },

  /*** external ***/

  getPaymentOptions(paymentList, optionAllText, optionNonText, params) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText,
      optionNonText, params
    );
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}