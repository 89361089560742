<template>
  <kst-page-print pageFooter
    :catalog="StkSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ Model.Module.FullName }}</div>
      </div>
    </div>

    <Items class="block" :access="access" :dataList="dataList" />
  </kst-page-print>
</template>

<script>
import Model from "../StkModel.js";
import Services from "../../../services/Api/StockServices.js";
import StkSearchCatalog from "../StkSearchCatalog.js";

import Items from "./StkP_Items.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Items,
    Company
  },
  data: () => ({
    Model: Model,
    StkSearchCatalog: StkSearchCatalog,
    access: {},
    companyData: {},
    dataList: [],
    searchData: {
      Search: "",
      WarehouseID: null,
      LastInvoiceDate: null,
      StatusID: Model.Status.Available.ID
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.companyData = {};
      this.dataList = [];
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      this.searchData.LastInvoiceDate = this.$kst.Search.OptionAll;
      this.searchData.StatusID = Model.Status.Available.ID;
    },

    getListOptions() {
      let result = {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        haslastinvoicedate: this.$kst.Search.getValue(
          this.searchData.LastInvoiceDate),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };

      const statusId = this.$kst.Out.getInteger(this.searchData.StatusID);
      if (statusId === Model.Status.Available.ID) {
        result.hasqty = this.$kst.Out.getBoolean(true);
      }
      else if (statusId === Model.Status.NotAvailable.ID) {
        result.hasnoqty = this.$kst.Out.getBoolean(true);
      }

      return result;
    }
  }
}
</script>