import App from "../../services/App/App.js";
import ModelBody from "./RrcvModel_Body.js";
import ModelEdit from "./RrcvModel_Edit.js";
import ModelNew from "./RrcvModel_New.js";
import ModelRelatedList from "./RrcvModel_RelatedList.js";
import ReceiveModel from "../Receive/RcvModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";
import ModelDetails from "./RrcvModel_Details.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  RtnReceiveItem: ModelBody.RtnReceiveItem,
  // other
  Item: ModelBody.Item,
  Receive: ModelBody.Receive,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setItemErrors(item, field, errors) {
    ModelBody.setItemErrors(item, field, errors);
  },

  getReceiveSectionHelp(data) {
    return ModelBody.getReceiveSectionHelp(data);
  },

  updateDetails(data, item) {
    ModelBody.updateDetails(data, item);
  },
  
  updateItem(item) {
    ModelBody.updateItem(item);
  },

  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItem(item) {
    ModelBody.validateItem(item);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(item) {
      return ModelDetails.getItemInfo(item);
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, detailsData) {
      ModelEdit.setDetailsByData(userData, detailsData);
    },
    setItemsByData(items, rows, receiveItemGroup) {
      ModelEdit.setItemsByData(items, rows, receiveItemGroup);
    },
    setItemByReceiveItem(item, receiveItem) {
      ModelEdit.setItemByReceiveItem(item, receiveItem);
    },

    getItemInfo(item) {
      return ModelEdit.getItemInfo(item);
    },

    updateReceiveItems(receiveItems, returnItems) {
      for (let receiveItem of receiveItems) {
        ReceiveModel.updateItem(receiveItem);
  
        ModelEdit.updateReceiveItems(receiveItem, returnItems);
      }
    },
  
    compareData(userData, userItems, rtnReceiveData) {
      return ModelEdit.compareData(userData, userItems, rtnReceiveData);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByReceive(userData, receiveDetails) {
      ModelNew.setDetailsByReceive(userData, receiveDetails);
    },
    setItemByReceiveItem(item, receiveItem) {
      ModelNew.setItemByReceiveItem(item, receiveItem);
    },

    getItemInfo(item) {
      return ModelNew.getItemInfo(item);
    },

    /*** external ***/

    updateReceiveItems(receiveItems) {
      for (let receiveItem of receiveItems) {
        ReceiveModel.updateItem(receiveItem);
      }
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.RelatedList, ModelBody.Module, access
      );
    },
    getItemInfo(item) {
      return ModelRelatedList.getItemInfo(item);
    }
  },

  /*** related ***/

  getReceiveItems_ForSelection(items, receiveItems) {
    return ModelBody.getReceiveItems_ForSelection(items, receiveItems);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}