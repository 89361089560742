import App from "../../services/App/App.js";
import ModelBody from "./ItemModel_Body.js";
import ModelDelete from "./ItemModel_Delete.js";
import ModelEdit from "./ItemModel_Edit.js";
import ModelNew from "./ItemModel_New.js";
import ModelPackaging from "./ItemModel_Packaging.js";
import ModelType from "./ItemModel_Type.js";
import ModelVariant from "./ItemModel_Variant.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  ItemPackaging: ModelBody.ItemPackaging,
  ItemVariant: ModelBody.ItemVariant,
  // support
  Stock: ModelBody.Stock,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  // Packaging
  PackagingIsActive: ModelPackaging.IsActive,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createPackaging(userData, isFirst) {
    return ModelPackaging.createDetails(userData, isFirst);
  },
  createVariant() {
    return ModelVariant.createDetails();
  },

  createPackagingOptions(userPackagingList) {
    return ModelPackaging.createOptions(userPackagingList);
  },
  createPackagingActiveOptions(userPackagingList) {
    return ModelPackaging.createActiveOptions(userPackagingList);
  },
  createDefaultPackagingOptions(userPackagingList) {
    return ModelPackaging.createDefaultOptions(userPackagingList);
  },
  createTypeOptions(items, optionAllText) {
    return ModelType.createOptions(items, optionAllText);
  },

  setDetailsByRef(userData, refDetails) {
    ModelBody.setDetailsByRef(userData, refDetails);
  },
  setDetailsByTypeList(userData, typeList) {
    ModelBody.setDetailsByTypeList(userData, typeList);
  },
  setPackagingErrors(userPackaging, field, errors) {
    ModelPackaging.setErrors(userPackaging, field, errors);
  },
  setVariantErrors(userVariant, field, errors) {
    ModelVariant.setErrors(userVariant, field, errors);
  },

  getPackagingData(userPackagingList, name) {
    return ModelPackaging.getData(userPackagingList, name);
  },
  getRefSectionHelp(userData) {
    return ModelBody.getRefSectionHelp(userData);
  },
  getSearchListSubTitle(itemList) {
    return ModelBody.getSearchListSubTitle(itemList);
  },
  getSearchList_getIdList(itemList) {
    return ModelBody.getSearchList_getIdList(itemList);
  },
  getVariantSet1(row) {
    return ModelBody.getVariantSet(row, "VariantType1", "VariantName1");
  },
  getVariantSet2(row) {
    return ModelBody.getVariantSet(row, "VariantType2", "VariantName2");
  },

  clearDetailsByRef(userData) {
    ModelBody.clearDetailsByRef(userData);
  },

  updateDefaultPackaging(userData, userPackagingList) {
    ModelBody.updateDefaultPackaging(userData, userPackagingList);
  },
  updatePackagingsErrors(userData, userPackagingList) {
    const packagingResult = ModelPackaging.getErrors(userPackagingList);
    userData.HasActivePackaging = packagingResult.active;
    userData.PackagingsErrors = packagingResult.error;
  },

  populateData(userData, userPackagingList, userVariantList, purchaseItemData) {
    let data = ModelBody.populateDetails(userData, userVariantList,
      purchaseItemData
    );
    data.Packagings = ModelBody.populatePackagings(userPackagingList);
    return data;
  },

  validationVariantName(userVariant) {
    return ModelVariant.validationName(userVariant);
  },
  validationVariantType(userVariant) {
    return ModelVariant.validationType(userVariant);
  },

  /*** method - Delete ***/

  Delete:{
    getItemSectionHelp(deliveryData) {
      return ModelDelete.getItemSectionHelp(deliveryData);
    },

    validationSKU(itemData){
      return ModelDelete.validationSKU(itemData);
    }
  },
  
  /*** method - Details ***/

  Details: {
    getActions(id, access) {
      return App.Access.getDetailsAvailableActions(
        id, ModelBody.Actions.Details, ModelBody.Module, access
      );
    },
    getVariantList(details) {
      let variantList = [];
      ModelVariant.setListByData(variantList, details);
      return variantList;
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, userPackagingList, userVariantList, details) {
      ModelEdit.setDetailsByData(userData, details);
      ModelPackaging.setListByData(userPackagingList, userData, details);
      ModelVariant.setListByData(userVariantList, details);
    }
  },

  /*** method - New ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_ExportDetails ||
        access.Act_PrintBarcode;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByNewPurchasingItem(userData, userPackagingList, userVariantList,
      purchasingItemDetails
    ) {
      ModelNew.setDetailsByNewPurchasingItem(userData, userPackagingList,
        userVariantList, purchasingItemDetails
      );
    },
    setDetailsByNewRef(userData, userPackagingList, userVariantList, refDetails) {
      ModelNew.setDetailsByData_NewRef(userData, userPackagingList,
        userVariantList, refDetails
      );
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_ExportDetails ||
        access.Act_PrintBarcode;
    }
  }
}