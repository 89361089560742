<template>
  <kst-table detailed paginated view-mode
    detail-key="ID"
    :action="Model.List.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-barcode"
      field="Barcode"
      :label="Catalog.Barcode.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="DefaultSellPrice"
      :label="Catalog.DefaultSellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-stock"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.List.getActions(row, access)"/>
    </template>

    <template #detail="{ row }">
      <PackagingList :itemId="row.ID"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";

import PackagingList from "./ItemL_List_PackagingList.vue";

export default {
  components: {
    PackagingList
  },
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>