<template>
  <PageEdit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details :userData="userData"/>
    <SelfService 
      :userData="userData"
      :warehouseOptions="warehouseOptions"
    />
    <Setting
      :userData="userData"
      :invoiceClientSourceOptions="invoiceClientSourceOptions"
      :profitLossRptTypeOptions="profitLossRptTypeOptions"
      :receiveVendorSourceOptions="receiveVendorSourceOptions"
    />
    <LowStockReminder
      :userData="userData"
      :userLowStockEmailList="userLowStockEmailList"
    />
    <DebtReminder
      :userData="userData"
      :userDebtEmailList="userDebtEmailList"
    />
    <RecReminder
      :userData="userData"
      :userRecEmailList="userRecEmailList"
    />
  </PageEdit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

import Info from "../CpInfo.js";
import Model from "../CpModel.js";
import Services from "../../../services/Api/CompanyServices.js";

import PageEdit from "./CpE_PageEdit.vue";
import Details from "./CpE_Details.vue";
import DebtReminder from "./CpE_DebtReminder.vue";
import LowStockReminder from "./CpE_LowStockReminder.vue";
import RecReminder from "./CpE_RecReminder.vue";
import SelfService from "./CpE_SelfService.vue";
import Setting from "./CpE_Setting.vue";

export default {
  components: {
    PageEdit,
    Details,
    DebtReminder,
    LowStockReminder,
    RecReminder,
    SelfService,
    Setting
  },
  mixins: [InputPageMixin,SnackbarMixin],
  data: () => ({
    error: false,
    userData: {},
    userDebtEmailList: [],
    userLowStockEmailList: [],
    userRecEmailList: [],
    invoiceClientSourceOptions: {},
    profitLossRptTypeOptions: {},
    receiveVendorSourceOptions: {},
    warehouseOptions: {}
  }),
  methods: {
    handleInit(loadEdit) {
      this.resetData();

      const config = Services.getEditData();
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.invoiceClientSourceOptions = Model.createSettingOptions(
        data.InvoiceClientSourceList
      );
      this.profitLossRptTypeOptions = Model.createSettingOptions(
        data.ProfitLossRptTypeList
      );
      this.receiveVendorSourceOptions = Model.createSettingOptions(
        data.ReceiveVendorSourceList
      );
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.Edit.setDetailsByData(this.userData, data.Details, data.DetailsSetting);
      Model.Edit.setDetailsByEmailList(
        this.userDebtEmailList, 
        data.DetailsSetting.DebtEmail
      );
      Model.Edit.setDetailsByEmailList(
        this.userLowStockEmailList,
        data.DetailsSetting.LowStockEmail
      );
      Model.Edit.setDetailsByEmailList(
        this.userRecEmailList,
        data.DetailsSetting.RecEmail
      );
      Model.Edit.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      this.handleSubmit_Error(this.userDebtEmailList);
      this.handleSubmit_Error(this.userLowStockEmailList);
      this.handleSubmit_Error(this.userRecEmailList);

      if (this.error) {
        this.notifyFormError(false, Info.Message.Edit_Email_Validation);
        return null;
      }

      const data = Model.populateData(
        this.userData,
        this.userDebtEmailList,
        this.userLowStockEmailList,
        this.userRecEmailList
      );
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_Error(userEmailList) {
      for(let emailObj of userEmailList) {
        if (emailObj.Errors.length > 0) {
          this.error = true;
          break;
        }
      }
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.error = false;
      this.userData = Model.createDetails();
      this.userDebtEmailList = [];
      this.userLowStockEmailList = [];
      this.userRecEmailList = [];
      this.invoiceClientSourceOptions = {};
      this.profitLossRptTypeOptions = {};
      this.receiveVendorSourceOptions = {};
      this.warehouseOptions = {};
    }
  }
}
</script>