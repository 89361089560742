<template>
  <kst-page-print-list pageFooter
    v-slot="{ title }"
    :catalog="InvSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company showImage :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="block" :dataList="dataList"/>
  </kst-page-print-list>
</template>

<script>
import InvSearchCatalog from "../InvSearchCatalog.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import List from "./InvPL_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    Model: Model,
    InvSearchCatalog: InvSearchCatalog,
    companyData: {},
    searchData: {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: null,
      Status: null,
      WarehouseID: null,
      RecordTypeID: null
    },
    dataList: []
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintListData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;

      for (const row of this.dataList) {
        Model.setDetailsByStatus(row);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.dataList = [];
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Client = "";
      this.searchData.ClientID = null;
      this.searchData.InvoiceDate = this.$kst.In.getDefaultDateRange();
      this.searchData.Status = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      this.searchData.RecordTypeID = this.$kst.Search.OptionAll;
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.Client) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[1]),
        client: clientId,
        clientname: clientName,
        status: this.$kst.Search.getValue(this.searchData.Status),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        ispos: this.$kst.Search.getValue(this.searchData.RecordTypeID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>