import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import SlsDL from "./Delete/SlsDL.vue";
import SlsD from "./Details/SlsD.vue";
import SlsE from "./Edit/SlsE.vue";
import SlsExD from "./ExportDetails/SlsExD.vue";
import SlsExL from "./ExportList/SlsExL.vue";
import SlsL from "./List/SlsL.vue";
import SlsN from "./New/SlsN.vue";
import SlsPD from "./PrintDetails/SlsPD.vue";
import SlsPL from "./PrintList/SlsPL.vue";
import SlsPT from "./PrintThermal/SlsPT.vue";

export default {
  Path: {
    Delete: RouterModel.getPath(AppModule.Sales, AppModuleType.Delete),
    Details: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Sales, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Sales, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Sales, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintList),
    PrintThermal: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintThermal)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.Delete),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.Delete),
        component: SlsDL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.Delete),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_Delete,
          moduletype: AppModuleType.Delete,
          cancel: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.List)
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.Details),
        component: SlsD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.Edit),
        component: SlsE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.ExportDetails),
        component: SlsExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.ExportList),
        component: SlsExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.List),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.List),
        component: SlsL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Sales),
            moduleattr: AppModuleAttr.Sales_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Sales),
            moduleattr: AppModuleAttr.Sales_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Sales),
            moduleattr: AppModuleAttr.Sales_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.New),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.New),
        component: SlsN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Sales, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.PrintDetails),
        component: SlsPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.PrintList),
        component: SlsPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Sales, AppModuleType.PrintThermal),
        name: RouterModel.getName(AppModule.Sales, AppModuleType.PrintThermal),
        component: SlsPT,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Sales, AppModuleType.PrintThermal),
          module: RouterModel.getModule(AppModule.Sales),
          moduleattr: AppModuleAttr.Sales_Act_PrintThermal,
          moduletype: AppModuleType.PrintThermal
        }
      }
    ];
  }
}