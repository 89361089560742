<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section :title="sectionTitle">
        <div class="block">
          <ValidationObserver ref="formDetails">
            <kst-input load-on-focus
              field="SelfServicePin" :data="userData" :catalog="Catalog"
            />
          </ValidationObserver>

          <kst-failure save-mode :items="failure"/>
          <kst-errors class="pt-4" :items="errors"/>
        </div>

        <div class="buttons block is-justify-content-end">
          <kst-button signin-mode @click="handleSignIn"/>
        </div>
      </kst-section>
    </div>
  </div>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Catalog from "../ClientCatalog.js";
import Info from "../ClientInfo.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  data: () => ({
    Catalog: Catalog,
    userData: {
      SelfServiceID: null,
      SelfServicePin: ""
    },
    getLoginService: null,
    // loading
    saveLoading: null,
    // error
    errors: null,
    failure: null
  }),
  computed: {
    sectionTitle() {
      return Info.Section.EnterPin.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init, this.setInit);
    },
    init_Data() {
      // loading
      this.saveLoading = null;
      // error
      this.errors = null;
      this.failure = null;
    },

    handleSignIn() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.verifyLogin();
        }
        else {
          this.notifyFormError();
        }
      });
    },

    verifyLogin() {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      const config = this.getLoginService(
        this.userData.SelfServiceID, this.userData.SelfServicePin
      );
      this.runAxios(config, null,
        this.verifyLogin_Success,
        this.verifyLogin_Error,
        this.verifyLogin_Invalid
      );
    },
    verifyLogin_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    verifyLogin_Success() {
      this.verifyLogin_End();
      this.$kst.Session.setSelfServicePin(this.userData.SelfServicePin);

      this.$emit(this.$kst.Enum.Event.Submit);
    },
    verifyLogin_Error(message) {
      this.failure = message;
      this.verifyLogin_End();
    },
    verifyLogin_Invalid(message) {
      this.errors = message;
      this.verifyLogin_End();
      this.notifyFormError();
    },

    setInit(selfServiceId, getLoginService) {
      this.userData.SelfServiceID = selfServiceId;
      this.getLoginService = getLoginService;
    }
  }
}
</script>