<template>
  <label class="label" :class="theClass">
    <slot>{{ text }}</slot>
  </label>
</template>

<script>
export default {
  props: {
    text: String,
    // size
    standard: Boolean
  },
  computed: {
    theClass() {
      return this.standard ? "" : "is-size-5";
    }
  }
}
</script>