<template>
  <div class="kst-print">
    <div class="kst-print-container p-4">
      <kst-skeleton :active="loading"/>

      <template v-if="hasAccess === false">
        <kst-failure access-mode/>
      </template>
      <template v-else-if="error">
        <kst-failure init-mode :items="error"/>
      </template>
      <template v-else>
        <template v-if="!loading">
          <template v-if="headerVisible">
            <div class="kst-print-header">
              <slot name="header"/>
            </div>
          </template>

          <template v-if="bodyVisible">
            <div class="kst-print-body">
              <slot name="default"/>
            </div>
          </template>

          <template v-if="footerVisible">
            <div class="kst-print-footer">
              <slot name="footer"/>
            </div>
          </template>

          <template v-if="!hideEndSlot">
            <div class="kst-print-end">
              <slot name="end"
                :label="labelEnd"
                :timestamp="timestamp"
                :username="username"
              >
                {{ labelEnd }} {{ timestamp }}, {{ username }}
              </slot>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  mixins: [SlotMixins],
  props: {
    error: [String, Array],
    loading: Boolean,
    hideEndSlot: Boolean,
    hasAccess: {
      type: Boolean,
      default: null
    },
  },
  computed: {
    bodyVisible() {
      return this.slotHasContent("default");
    },
    footerVisible() {
      return this.slotHasContent("footer");
    },
    headerVisible() {
      return this.slotHasContent("header");
    },
    labelEnd() {
      return AppCatalog.Print.PrintedAt;
    },
    timestamp() {
      return this.$kst.View.getTimestamp();
    },
    username() {
      return AppCatalog.Print.PrintedBy + this.$kst.Session.getName();
    }
  }
}
</script>