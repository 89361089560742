<template>
  <div>
    <div class="base-background"/>
    <div class="diagonal-background"/>
    <a :href="websiteUrl" class="company-logo">
      <img src="../../../assets/companylogo_ws.png" class="default-logo"/>
      <img src="../../../assets/companylogo.png" class="vertical-logo"/>
    </a>

    <div class="base-container">
      <div class="slideshow-container">
        <Banner />
        <form class="form-container form-container-signin">
          <span class="form-title">Log In</span>
          <div class="form-body form-body-signin">
            <div class="form-group">
              <label for="email" class="main-label">Email / Username *</label>
              <div class="form-inline">
                <input type="text" name="email" id="email" v-model="userData.email">
                <span v-if="errorEmail">
                  <i class="field-status-error mdi mdi-alert"></i>
                  <i class="field-status-message">{{ errorEmail }}</i>
                </span>
              </div>
            </div>
            <div class="form-group">
              <label for="password" class="main-label">Password *</label>
              <div class="form-inline">
                <input :type="password" name="password" id="password"
                  v-model="userData.password"
                >
                <span @click="onShowPassword">
                  <template v-if="isShowPassword">
                    <i class="show-password mdi mdi-eye" :style="marginEye"/>
                  </template>
                  <template v-else>
                    <i class="show-password mdi mdi-eye-off" :style="marginEye"/>
                  </template>
                </span>
                <span v-if="errorPassword">
                  <i class="field-status-error mdi mdi-alert"/>
                  <i class="field-status-message">{{ errorPassword }}</i>
                </span>
              </div>
            </div>
            <!-- <div class="form-group no-underline">
              <label for="password" class="link-label">
                <i>Lupa password? </i>
                <a v-on:click="onClickForgotPassword">Klik di sini</a>
              </label>
            </div> -->
          </div>
          <div v-if="errorMessage">
            <div class="error-message" ref="error">
              <i class="mdi mdi-alert"></i>
              <span>{{ errorMessage }}</span>
            </div>
          </div>
          <div class="form-footer form-footer-signin">
            <div v-if="loading">
              <button class="submit-button" disabled>
                Memproses <i class="mdi mdi-autorenew mdi-spin"/>
              </button>
            </div>
            <div v-else>
              <button class="submit-button" @click.prevent="onClickLogin">
                Masuk
              </button>
            </div>
          </div>
          <p class="sign-section sign-section-signin">
            <i>Belum memiliki akun?</i>
            <a v-on:click="onClickRegister">Daftar</a>
          </p>
        </form>
        <div class="copyright">
          <span>&#169; 2021 Dirigo By 
            <a href="https://www.kst.co.id/" target="_blank">
              <u>PT Kreatifitas Sinergisme Teknoindo</u>
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Banner from "../SecurityBanner.vue";
import WebsiteRouter from "../../Website/WebsiteRouter.js";

import AppModule from "../../../services/App/AppModule.js";
import Model from "../SecurityModel.js";
import Services from "../../../services/Api/SecurityServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Banner
  },
  data: () => ({
    userData: {},
    errorPassword: "",
    errorEmail: "",
    errorMessage: "",
    loading: false,
    isShowPassword: false
  }),
  computed: {
    websiteUrl() {
      return WebsiteRouter.Path.Home;
    },
    password() {
      return this.isShowPassword ? "text" : "password";
    },
    marginEye() {
      return this.errorPassword ? "right:30px;" : "";
    }
  },
  methods: {
    onClickLogin() {
      const valid = this.validate();

      if (valid) {
        this.login();
      }
    },
    // onClickForgotPassword() {
    //   this.redirectTo(AppModule.ForgotPassword.Path);
    // },
    onShowPassword() {
      if (this.isShowPassword) {
        this.isShowPassword = false;
      }
      else {
        this.isShowPassword = true;
      }
    },

    login() {
      this.loading = true;

      const data = Model.populateLogin(this.userData);
      const config = Services.postLogin(data);

      this.runAxios(config, null,
        this.login_success,
        this.login_fault,
        this.login_fault
      );
    },
    login_success(data) {
      this.$kst.Session.setApiVersion(data.ApiVersion);
      this.$kst.Session.setBackDateInterval(data.UserBackDateInterval);
      this.$kst.Session.setCompanyAccessibility(data.CompanyAccessibility);
      this.$kst.Session.setCompanyImageSrc(data.CompanyImageSrc);
      this.$kst.Session.setCompanyName(data.CompanyName);
      this.$kst.Session.setDefaultMenu(data.DefaultModule);
      this.$kst.Session.setDefaultWarehouseID(data.DefaultWarehouseID);
      this.$kst.Session.setModule(data.ModuleList);
      this.$kst.Session.setModuleAttr(data.ModuleAttrList);
      this.$kst.Session.setName(data.UserName);
      this.$kst.Session.setLastChecked(new Date());

      this.redirectToLandingPage();
      this.loading = false;
    },
    login_fault(message) {
      this.loading = false;
      this.errorMessage = message;
    },

    redirectToLandingPage() {
      const defaultMenuId = this.$kst.Session.getDefaultMenu();
      let modulePath = null;

      for (let module of Object.values(AppModule)) {
        if (module.ID === defaultMenuId) {
          modulePath = module.Path;
          break;
        }
      }

      this.redirectTo(modulePath);
    },

    validate() {
      this.errorEmail = "";
      this.errorPassword = "";
      this.errorMessage = "";
      let valid = true;

      if (!this.userData.email) {
        this.errorEmail = "Email tidak boleh kosong";
        valid = false;
      }

      if (!this.userData.password) {
        this.errorPassword = "Password tidak boleh kosong";
        valid = false;
      }

      return valid;
    },
    onClickRegister() {
      this.redirectTo(AppModule.SignUp.Path);
    }
  }
}
</script>

<style scoped lang="css" src="../_layout.css"/>