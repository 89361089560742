<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StcvCatalog.js";
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import StcvItemCatalog from "../StcvItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_details(details);
      this.addDetails_itemsRaw(details.ItemsRaw);
      this.addDetails_itemsFinish(details.ItemsFinish);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ConvNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.TotalRaw);
      this.addColumnHeader(Catalog.TotalFinish);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("ConvNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("TotalRaw", details, Catalog);
      this.addCellValue("TotalFinish", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
    addDetails_itemsRaw(itemsRaw) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.ItemsRaw.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StcvItemCatalog.ItemID.Label);
      this.addColumnHeader(StcvItemCatalog.ItemID);
      this.addColumnHeader(StcvItemCatalog.SKU);
      this.addColumnHeader(StcvItemCatalog.Packaging);
      this.addColumnHeader(StcvItemCatalog.Qty);
      this.addColumnHeader(StcvItemCatalog.TotalQty);
      this.addColumnHeader(StcvItemCatalog.SellPrice);
      this.addColumnHeader(StcvItemCatalog.SubTotal);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StcvItemCatalog.HppTotal);
      }

      // value
      for (const row of itemsRaw) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ItemID", row, StcvItemCatalog);
        this.addCellRelatedValue("ItemID", row, StcvItemCatalog);
        this.addCellValue("SKU", row, StcvItemCatalog);
        this.addCellValue("Packaging", row, StcvItemCatalog);
        this.addCellValue("Qty", row, StcvItemCatalog);
        this.addCellValue("TotalQty", row, StcvItemCatalog);
        this.addCellValue("SellPrice", row, StcvItemCatalog);
        this.addCellValue("SubTotal", row, StcvItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, StcvItemCatalog);
        }
      }
    },
    addDetails_itemsFinish(itemsFinish) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.ItemsFinish.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StcvItemCatalog.ItemID.Label);
      this.addColumnHeader(StcvItemCatalog.ItemID);
      this.addColumnHeader(StcvItemCatalog.SKU);
      this.addColumnHeader(StcvItemCatalog.Packaging);
      this.addColumnHeader(StcvItemCatalog.Qty);
      this.addColumnHeader(StcvItemCatalog.TotalQty);
      this.addColumnHeader(StcvItemCatalog.SellPrice);
      this.addColumnHeader(StcvItemCatalog.SubTotal);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StcvItemCatalog.HppTotal);
      }

      // value
      for (const row of itemsFinish) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ItemID", row, StcvItemCatalog);
        this.addCellRelatedValue("ItemID", row, StcvItemCatalog);
        this.addCellValue("SKU", row, StcvItemCatalog);
        this.addCellValue("Packaging", row, StcvItemCatalog);
        this.addCellValue("Qty", row, StcvItemCatalog);
        this.addCellValue("TotalQty", row, StcvItemCatalog);
        this.addCellValue("SellPrice", row, StcvItemCatalog);
        this.addCellValue("SubTotal", row, StcvItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, StcvItemCatalog);
        }
      }
    }
  }
}
</script>