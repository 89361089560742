<template>
<Layout>
	<div id="features">
		<div id="section1">
			<div id="section1_child1">
				<span class="section_h1">KERJA MUDAH BUKAN LAGI IMPIAN!</span>
				<span class="section_h2"><i>Cari yang Kamu Butuhkan di Sini!</i></span>
			</div>
            <img src="../../../assets/pc.png"/>
		</div>
		<div id="section2">
			<img class="section_title" src="../../../assets/features_header_laporan.png"/>
			<div id="section2_row">
				<div class="section2_row_card section2_row_card_op1" ref="Group_376" @click="showFeature(0);">
					<img class="section2_ic" src="../../../assets/features_ic_s2kartustok.png"/>
					<span>KARTU STOK</span>
				</div>
				<div class="section2_row_card" ref="Component_58___1" @click="showFeature(1);">
					<img class="section2_ic" src="../../../assets/features_ic_s2laporanharian.png"/>
					<span>LAPORAN HARIAN</span>
				</div>
				<div class="section2_row_card" ref="Group_578_" @click="showFeature(2);">
					<img class="section2_ic" src="../../../assets/features_ic_s2labarugi.png"/>
					<span>LABA (RUGI)</span>
				</div>
				<div class="section2_row_card" ref="Group_580" @click="showFeature(3);">
					<img class="section2_ic" src="../../../assets/features_ic_s2omzet.png"/>
					<span>OMZET</span>
				</div>
				<div class="section2_row_card" ref="Group_582" @click="showFeature(4);">
					<img class="section2_ic" src="../../../assets/features_ic_s2pergerakanstok.png"/>
					<span>PERGERAKAN STOK</span>
				</div>
				<div class="section2_row_card" ref="Group_583" @click="showFeature(5);">
					<img class="section2_ic" src="../../../assets/features_ic_s2daftartransaksi.png"/>
					<span>DAFTAR TRANSAKSI</span>
				</div>
			</div>
			<div class="section2_card section2_card_dpb" ref="kartu_stock_desc">
				<div class="section2_card_title">KARTU STOK</div>
				<div class="section2_card_p1">Lihat catatan stok bikin kamu pusing? Kini informasi mengenai barang dan jumlah stok bisa kamu dapatkan tanpa perlu membuang banyak waktu. Dengan Dirigo, kamu bisa melihat jumlah stok barang tokomu dalam periode tertentu cukup dengan mengetikkan nama barangmu.</div>
			</div>
			<div class="section2_card" ref="laporan_harian_desc">
				<div class="section2_card_title">LAPORAN HARIAN</div>
				<div class="section2_card_p1">Sering lupa catat keluar masuknya barang? Dapatkan laporan harian pergerakan stok dari awal hingga tutup toko melalui fitur ini. Rekam jejak barangmu semuanya aman dan tercatat!</div>
			</div>
			<div class="section2_card" ref="laba_rugi_desc">
				<div class="section2_card_title">LABA RUGI</div>
				<div class="section2_card_p1">Harus tunggu tutup buku untuk tahu laba tokomu? Dengan Dirigo kamu bisa cek laporan laba & rugi tokomu di manapun dan kapan pun secara real time. Jangan habiskan waktumu jika Dirigo bisa membantumu!</div>
			</div>
			<div class="section2_card" ref="omzet_desc">
				<div class="section2_card_title">OMZET</div>
				<div class="section2_card_p1">Bersama kami, kamu bisa mendapatkan data penjualan, diskon penjualan, dan retur pada semua toko / gudangmu. Cukup lihat grafik yang kami berikan untuk mendapatkan gambaran tentang omzet tokomu pada periode waktu yang kamu tentukan.</div>
			</div>
			<div class="section2_card" ref="stock_move_desc">
				<div class="section2_card_title">PERGERAKAN STOK</div>
				<div class="section2_card_p1">Wow! Dengan Dirigo, kamu dapat mengecek pergerakan stokmu pada periode waktu yang kamu tentukan dari mana saja! Agar lebih nyaman, kami menyajikan laporan keluar-masuknya stok tokomu dalam bentuk grafik (untuk melihat trend) dan tabel (untuk melihat rinciannya).</div>
			</div>
			<div class="section2_card" ref="list_inv_desc">
				<div class="section2_card_title">DAFTAR TRANSAKSI</div>
				<div class="section2_card_p1">Tak perlu lagi kuatir perihal tidak cocoknya stok barang di toko. Dengan Dirigo, kamu bisa melacak daftar transaksi semua tokomu, loh! Kami juga menyediakan fitur filter untuk memudahkanmu mencari barang tertentu maupun melacak toko tertentu saja.</div>
			</div>
		</div>
		<div id="section3">
			<div id="section3_bg"/>
			<div id="section3_child">
				<img class="section_title2" src="../../../assets/features_header_pos.png"/>
				<div id="section3_child_child">
					<img src="../../../assets/features_img_pos.png"/>
					<div id="section3_child_child_child2">
						<span class="section_h1">SISTEM POS MODERN</span>
						<span class="section_h2">Antrian panjang di kasir? Tidak perlu kuatir lagi! Sistem Dirigo yang modern akan meningkatkan efisiensi tokomu.</span>
					</div>
				</div>
			</div>
		</div>
		<div id="section4">
			<div id="section4_bg"/>
			<div id="section4_child">
				<img class="section_title3" src="../../../assets/features_header_finansial.png"/>			
				<div id="section4_child_child">
					<div id="section4_child_child_child">
						<span class="section_h1">LACAK PENGELUARAN ANDA</span>
						<span class="section_h2">Tidak perlu lagi kebingungan melacak laporan pengeluaranmu! Kami akan lakukan itu untuk kamu.</span>
					</div>
					<img src="../../../assets/features_img_finansial.png"/>
				</div>
			</div>
		</div>
		<div id="section5">
			<img class="section_title4" src="../../../assets/features_header_inventaris.png"/>			
			<div class="section5_card" @click="expandInventaris(0);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic" src="../../../assets/features_ic_daftarbarang.png"/>
					<span>DAFTAR BARANG</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="daftar_barang">
					<span class="section5_card_ic"/>
					<p>Dapatkan informasi barang yang kamu miliki dengan cepat menggunakan kata kunci. Kamu juga bisa melihat semua barang yang tokomu miliki, loh!</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(1);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic2" src="../../../assets/features_ic_penyesuaianstok.png"/>
					<span>STOK OPNAME</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="penyesuaian_stok">
					<span class="section5_card_ic2"/>
					<p>Jumlah stok berbeda antara realita & yang ditampilkan? Tak perlu pusing! Cukup gunakan fitur penyesuaian stok kami.</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(2);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic" src="../../../assets/features_ic_pengeluaranstok.png"/>
					<span>PENGELUARAN STOK</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="pengeluaran_stok">
					<span class="section5_card_ic"/>
					<p>Lihat daftar pengeluaran stokmu dalam satu klik saja. Kami juga sediakan filter yang dapat membantumu mencari data yang kamu butuhkan.</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(3);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic" src="../../../assets/features_ic_transferbarang.png"/>
					<span>TRANSFER BARANG</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="transfer_barang">
					<span class="section5_card_ic"/>
					<p>Dengan Dirigo, perpindahan barang antar toko atau gudang tidak lagi merepotkan. Cukup gunakan fitur ini untuk transfer barang antar toko/gudang.</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(4);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic" src="../../../assets/features_ic_penutupanstok.png"/>
					<span>PENUTUPAN STOK</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="penutupan_stok">
					<span class="section5_card_ic"/>
					<p>Fitur ini bisa membantu kamu mencari data penutupan stok tokomu, lho! Perlu export data atau print? Tinggal klik!</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(5);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic2" src="../../../assets/features_ic_penyesuaianhpp.png"/>
					<span>PENYESUAIAN HPP</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="penyesuaian_hpp">
					<span class="section5_card_ic2"/>
					<p>Kami tahu HPP bisa berubah seiring waktu. Oleh karena itu, kami sediakan fitur ini agar HPP barang tokomu bisa diperbaharui kapan pun!</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(6);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic" src="../../../assets/features_ic_persediaanstok.png"/>
					<span>PERSEDIAAN STOK</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="persediaan_stok">
					<span class="section5_card_ic"/>
					<p>Tak perlu lagi kuatir ada stok barang yang tidak tersedia. Melalui fitur ini, kamu bisa lihat kekurangan stok yang harus kamu penuhi.</p>
				</div>
			</div>
			<div class="section5_card" @click="expandInventaris(7);">
				<div class="section5_card_child_title">
					<img class="section5_card_ic2" src="../../../assets/features_ic_penyesuaianhargaspesial.png"/>
					<span>PENYESUAIAN HARGA SPESIAL</span>
					<div class="section5_card_arrow"><i class="fas fa-chevron-down"></i></div>
				</div>
				<div class="section5_card_child_p1" ref="penyesuaian_harga_spesial">
					<span class="section5_card_ic2"/>
					<p>Promosi melalui diskon? Jangan biarkan teknologi menghalangimu! Pakai Dirigo, ini tak jadi perkara!</p>
				</div>
			</div>
		</div>
		<div id="section6">
			<img class="section_title4" src="../../../assets/features_header_pembelian.png"/>
			<div id="section6_child">
				<img src="../../../assets/features_img_pembelian.png"/>
				<div id="section6_child_child">
					<div class="section6_child_child_card">
						<img src="../../../assets/features_ic_s6pembelianstok.png"/>
						<div class="section6_child_child_card_col">
							<div class="section6_child_child_card_col_title">PEMBELIAN STOK</div>
							<div class="section6_child_child_card_col_p1">Bersama Dirigo, data pembelian stok dapat kamu tampilkan & print dalam hitungan detik!</div>
						</div>
					</div>
					<div class="section6_child_child_card">
						<img src="../../../assets/features_ic_s6penerimaanstok.png"/>
						<div class="section6_child_child_card_col">
							<div class="section6_child_child_card_col_title">PENERIMAAN STOK</div>
							<div class="section6_child_child_card_col_p1">Telusuri jejak penerimaan stok tokomu dengan Dirigo. Psst, bisa input barang pakai scanner loh!</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="section7">
			<img class="section_title4" src="../../../assets/features_header_penjualan.png"/>
			<div id="section7_child">
				<div id="section7_child_child1">
					<div class="section7_child_child_card section7_child_child_card_margin1 section7_child_child_card_grid1">
						<div class="section7_child_child_card_title">PENJUALAN STOK (SO)</div>
						<div class="section7_child_child_card_p1">Catatan manual? Sudah nggak jaman. Dirigo akan membantu kamu mencatat rekam jejak penjualan stokmu!</div>
					</div>
					<div class="section7_child_child_card section7_child_child_card_grid2">
						<div class="section7_child_child_card_title">NOTA PENJUALAN</div>
						<div class="section7_child_child_card_p1">Nggak perlu lagi menghabiskan waktu berjam-jam untuk mencari nota penjualan tokomu. Tingkatkan efisiensi kerjamu dengan bantuan kami.</div>
					</div>
					<div class="section7_child_child_card section7_child_child_card_margin1 section7_child_child_card_grid3">
						<div class="section7_child_child_card_title">REKAP PENJUALAN</div>
						<div class="section7_child_child_card_p1">Rekap per kasir  maupun langsung semua kasir, semuanya bisa dengan Dirigo!</div>
					</div>
				</div>
				<div id="section7_child_child2">
					<img src="../../../assets/features_img_penjualan.png"/>
				</div>
				<div id="section7_child_child3">
					<div class="section7_child_child_card section7_child_child_card_margin2">
						<div class="section7_child_child_card_title">RETUR PENJUALAN</div>
						<div class="section7_child_child_card_p1">Retur Penjualan memang bikin pusing, tapi tidak perlu tambah tingkat kesusahannya dengan pencatatan manual. Kami bantu catatkan untuk kamu.</div>
					</div>
					<div class="section7_child_child_card">
						<div class="section7_child_child_card_title">PENGIRIMAN</div>
						<div class="section7_child_child_card_p1">Pencatatan pengiriman barang tidak lagi ribet dengan bantuan kami. Kamu bisa cek semua pengiriman tokomu dalam periode waktu yang kamu tentukan sendiri.</div>
					</div>
				</div>
			</div>
		</div>
		<div id="section8">
			<div id="section8_child">
				<div id="section8_child_child">
					<img class="section_title5 section8_title_desktop" src="../../../assets/features_header_manajemenkontak.png"/>
					<span class="section_h1 section8_child_child_width">TIDAK LAGI RUMIT!</span>
					<span class="section_h2 section8_child_child_width">Manajemen Vendor, Pelanggan, dan Gudang yang sebelumnya banyak menyita waktumu kini dapat kamu selesaikan dengan cepat!</span>
				</div>
				<img id="section8_child_img" src="../../../assets/features_img_manajemenkontak.png"/>
				<img class="section_title5 section8_title_mobile" src="../../../assets/features_header_manajemenkontak.png"/>
				
			</div>
		</div>
		<div id="section9">
			<img class="section_title6" src="../../../assets/features_header_pengaturan.png"/>
			<div id="section9_child">
				<img src="../../../assets/features_img_pengaturan.png"/>
				<div id="section9_child_child2">
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9daftarpengguna.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">DAFTAR PENGGUNA</div>
							<div class="section9_child_child2_card_col_p1">Punya banyak karyawan yang punya peran berbeda-beda? Sistem kami bisa akomodasi kebutuhanmu.</div>
						</div>
					</div>
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9penomoranotomatis.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">PENOMORAN OTOMATIS</div>
							<div class="section9_child_child2_card_col_p1">Penomor nota, pembukuan, dan lain-lainnya buat kepalamu pusing? Kami sediakan fitur otomatis!</div>
						</div>
					</div>
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9klasifikasipengeluaran.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">KLASIFIKASI PENGELUARAN</div>
							<div class="section9_child_child2_card_col_p1">Bingung pengeluaranmu campur aduk? Atur klasifikasi pengeluaran solusinya!</div>
						</div>
					</div>
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9pengaturanhargaspesial.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">PENGATURAN HARGA SPESIAL</div>
							<div class="section9_child_child2_card_col_p1">Punya macam-macam jenis diskon? Tak perlu input satu per satu! Dengan fitur ini kamu bisa simpan beberapa macam kategori harga spesial untuk tokomu.</div>
						</div>
					</div>
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9jenispembayaran.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">JENIS PEMBAYARAN</div>
							<div class="section9_child_child2_card_col_p1">Pakai electronic money? Klasifikasi jenis pembayaran jadi mudah dengan adanya fitur Dirigo yang satu ini!</div>
						</div>
					</div>
					<div class="section9_child_child2_card">
						<img src="../../../assets/features_ic_s9akses.png"/>
						<div class="section9_child_child2_card_col">
							<div class="section9_child_child2_card_col_title">AKSES</div>
							<div class="section9_child_child2_card_col_p1">Beda karyawan, beda peran, beda akses? Tak jadi masalah! Fitur ini memungkinkan kamu mengatur hal tersebut!</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="section10">
			<img src="../../../assets/features_img_question.png"/>
			<div id="section10_child2">
				<span class="section_h1">GALAU BUKAN JAWABAN</span>
				<span class="section_h2">Di saat kamu galau, ada ribuan kompetitormu sedang berjuang mengembangkan usahanya!</span>
				<div @click="goToTargetView('signup')" id="section10_child2_button">Coba Gratis</div>
			</div>
		</div>
		
	</div>
</Layout>
</template>
<script>
import Layout from "../Layout/Layout.vue";

export default {
	components: {
		Layout
	},
	data: () => ({
		cmps: [],
		inventaris: [],
		stateIndex: 0,
		stateSelected: false
	}),
	mounted() {
    this.init()
  },
	methods: {
		init() {
			this.cmps = [{
				btn: this.$refs.Group_376,
				dsc: this.$refs.kartu_stock_desc,
			},{
				btn: this.$refs.Component_58___1,
				dsc: this.$refs.laporan_harian_desc
			},{
				btn: this.$refs.Group_578_,
				dsc: this.$refs.laba_rugi_desc
			},{
				btn: this.$refs.Group_580,
				dsc: this.$refs.omzet_desc
			},{
				btn: this.$refs.Group_582,
				dsc: this.$refs.stock_move_desc
			},{
				btn: this.$refs.Group_583,
				dsc: this.$refs.list_inv_desc
			}];
			this.inventaris = [{
				container: this.$refs.Group_435,
				background: this.$refs.Rectangle_179,
				content: this.$refs.daftar_barang
			},{
				container: this.$refs.Group_434,
				background: this.$refs.Rectangle_180,
				content: this.$refs.penyesuaian_stok
			},{
				container: this.$refs.Group_735, //pengeluaran stok
				background: this.$refs.Rectangle_312,
				content: this.$refs.pengeluaran_stok
			},{
				container: this.$refs.Group_433,
				background: this.$refs.Rectangle_181,
				content: this.$refs.transfer_barang
			},{
				container: this.$refs.Group_737, //penutupan stok
				background: this.$refs.Rectangle_315,
				content: this.$refs.penutupan_stok
			},{
				container: this.$refs.Group_432,
				background: this.$refs.Rectangle_182,
				content: this.$refs.penyesuaian_hpp
			},{
				container: this.$refs.Group_739, //persediaan stok
				background: this.$refs.Rectangle_317,
				content: this.$refs.persediaan_stok
			},{
				container: this.$refs.Group_740, //pengeluaran harga spes
				background: this.$refs.Rectangle_318,
				content: this.$refs.penyesuaian_harga_spesial
			}];
		},
		expandInventaris(index) {
			if(this.stateIndex !== index) {
				this.stateSelected = false;
			}
			this.stateIndex = index;
			if(this.stateSelected == false) {
				this.stateSelected = true;
			} else {
				this.stateSelected = false;
			}
			for(let i in this.inventaris) {
				// this.inventaris[i].container.style.height = this.stateIndex == i && this.stateSelected ? "200px" : "120px";
				// this.inventaris[i].background.style.height = this.stateIndex == i && this.stateSelected ? "200px" : "120px";
				this.inventaris[i].content.style.display = this.stateIndex == i && this.stateSelected ? "flex" : "none";
			}
		},
		showFeature(index) {
			for(let i in this.cmps) {
				this.cmps[i].btn.style.opacity = i == index ? "1" : "0.6";
				this.cmps[i].dsc.style.display = i == index ? "block" : "none";
			}
		},
		goToTargetView(goto) {
			if(typeof goto == "string") {
				window.location.href = goto;
			}
		}
	}
}
</script>
<style scoped lang="css" src="./_features.css"/>