import App from "../../services/App/App.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ExpcEnum from "./ExpcEnum.js";

export default {
  /*** related ***/

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.ExpenseCategory_Act_Details,
    Act_Edit: AppModuleAttr.ExpenseCategory_Act_Edit,
    Act_List: AppModuleAttr.ExpenseCategory_Act_List,
    Act_New: AppModuleAttr.ExpenseCategory_Act_New
  },

  Actions: {
    Details: [
      AppModuleType.Edit
    ]
  },

  Search: {
    Fields: ["Name"]
  },

  IsActive: ExpcEnum.IsActive,

  /*** method ***/

  createDetails(isActiveEnum) {
    return {
      ID: null,
      // user input
      CategoryID: null,
      Name: "",
      IsActive: App.In.getBoolean(isActiveEnum.Active.ID)
    };
  },
  createOptions(dataList, optionAllText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createCategoryOptions(categoryList, optionAllText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(categoryList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createIsActiveOptions(optionAllText, isActiveEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: isActiveEnum.Active.ID, [rowLabel]: isActiveEnum.Active.Label },
      { [rowId]: isActiveEnum.InActive.ID, [rowLabel]: isActiveEnum.InActive.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  getDefault(dataOptions, defaultId) {
    if (dataOptions === undefined || dataOptions === null) {
      return null;
    }
    if (!Object.prototype.hasOwnProperty.call(dataOptions, "rows")) {
      return null;
    }

    const dataList = dataOptions.rows;
    const defaultRow = App.Array.searchItem(dataList, "ID", defaultId);

    if (defaultRow !== null) {
      return defaultId;
    }
    else {
      return dataList.length > 0 ? dataList[0].ID : null;
    }
  },
  getCategoryDefault(categoryOptions, defaultId) {
    if (categoryOptions === undefined || categoryOptions === null) {
      return null;
    }
    if (!Object.prototype.hasOwnProperty.call(categoryOptions, "rows")) {
      return null;
    }

    const dataList = categoryOptions.rows;
    const defaultRow = App.Array.searchItem(dataList, "ID", defaultId);

    if (defaultRow !== null) {
      return defaultId;
    }
    else {
      return dataList.length > 0 ? dataList[0].ID : null;
    }
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      CategoryID: userData.CategoryID,
      Name: App.Out.getString(userData.Name),
      IsActive: App.Out.getBoolean(userData.IsActive)
    };
  }
}