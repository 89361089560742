<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="HpadItemCatalog.DispatchID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="HpadItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="HpadItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="HpadItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="HpadItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="HppTotal"
      :label="HpadItemCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="HpadItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import HpadItemCatalog from "../HpadItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    HpadItemCatalog: HpadItemCatalog
  })
}
</script>