<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="data"
      dateField="Date"
      numberField="ClosingNumber"
    />

    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-created :data="data"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StclCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>