/**
 * Name - used in menu label (route)
 * FullName - used in other than menu, such as title
 */
export default {
  Dashboard: {
    ID: 1,
    Name: "Dashboard",
    FullName: "Dashboard",
    FileName: "Dashboard",
    Code: "Dashboard",
    Class: "dashboard",
    Path: "dashboard",
    Param: "dash",
    IsAuthenticated: true
    // ShortFileName: "Dash"
  },

  // Report
  Report: {
    ID: null,
    Name: "Laporan",
    FullName: "Laporan"
    // Param: not available in dirigo
    // ShortFileName: "Rpt"
  },
  StockCard: {
    ID: 3,
    Name: "Kartu Stok",
    FullName: "Laporan Kartu Stok",
    FileName: "Laporan Kartu Stok",
    Code: "StockCard",
    Class: "stockcard",
    Path: "stockcard",
    Param: "stcd",
    IsAuthenticated: true
    // ShortFileName: "Stcd"
  },
  ProfitLoss: {
    ID: 4,
    Name: "Laba (Rugi)",
    FullName: "Laporan Laba Rugi",
    FileName: "Laporan Laba Rugi",
    Code: "ProfitLoss",
    Class: "profitloss",
    Path: "profitloss",
    Param: "plos",
    IsAuthenticated: true
    // ShortFileName: "Plos"
  },
  Omzet: {
    ID: 6,
    Name: "Omzet",
    FullName: "Laporan Omzet",
    FileName: "Laporan Omzet",
    Code: "Omzet",
    Class: "omzet",
    Path: "omzet",
    Param: "omz",
    IsAuthenticated: true
    // ShortFileName: "Omz"
  },
  Transaction: {
    ID: 22,
    Name: "Daftar Transaksi",
    FullName: "Daftar Transaksi",
    FileName: "Daftar Transaksi",
    Code: "Transaction",
    Class: "transaction",
    Path: "transaction",
    Param: "trx",
    IsAuthenticated: true
    // ShortFileName: "Trx"
  },
  DailyReport: {
    ID: 39,
    Name: "Laporan Harian",
    FullName: "Laporan Harian",
    FileName: "Laporan Harian",
    Code: "DailyReport",
    Class: "dailyreport",
    Path: "dailyreport",
    Param: "dai",
    IsAuthenticated: true
    // ShortFileName: "Dai"
  },
  ReportStockAdj: {
    ID: 5,
    Name: "Pergerakan Stok",
    FullName: "Laporan Pergerakan Stok",
    FileName: "Laporan Pergerakan Stok",
    Code: "ReportStockAdj",
    Class: "stockadj",
    Path: "stockadj",
    Param: "stad",
    IsAuthenticated: true
    // ShortFileName: "Stad"
  },

  Pos: {
    ID: 21,
    Name: "POS",
    FullName: "POS",
    FileName: "POS",
    Code: "Pos",
    Class: "pos",
    Path: "pos",
    Param: "pos",
    IsAuthenticated: true
    // ShortFileName: "Pos"
  },

  // Finance
  Finance: {
    ID: null,
    Name: "Finansial",
    FullName: "Finansial"
    // Param: not available in dirigo
    // ShortFileName: "Fin"
  },
  Expense: {
    ID: 20,
    Name: "Pengeluaran",
    FullName: "Pengeluaran",
    FileName: "Pengeluaran",
    Code: "Expenses",
    Class: "expense",
    Path: "expense",
    Param: "exp",
    IsAuthenticated: true
    // ShortFileName: "Exp"
  },

  // Inventory
  Inventaris: {
    ID: null,
    Name: "Inventaris",
    FullName: "Inventaris"
    // Param: not available in dirigo
    // ShortFileName: "Invt"
  },
  Item: {
    ID: 7,
    Name: "Daftar Barang",
    FullName: "Barang",
    FileName: "Barang",
    Code: "Item",
    Class: "item",
    Path: "item",
    Param: "item",
    IsAuthenticated: true
    // ShortFileName: "Item"
  },
  LowStock: {
    ID: 52,
    Name: "Daftar Stok Rendah",
    FullName: "Stok Rendah",
    FileName: "Stok Rendah",
    Code: "LowStock",
    Class: "lowstock",
    Path: "lowstock",
    Param: "low",
    IsAuthenticated: true
    // ShortFileName: "Lowstk"
  },
  StockConv: {
    ID: 50,
    Name: "Konversi Stok",
    FullName: "Konversi Stok",
    FileName: "Konversi Stok",
    Code: "StockConv",
    Class: "stockconv",
    Path: "stockconv",
    Param: "stcv",
    IsAuthenticated: true
    // ShortFileName: "Stcv"
  },
  StockOpname: {
    ID: 33,
    Name: "Stok Opname",
    FullName: "Stok Opname",
    FileName: "Stok Opname",
    Code: "StockOpname",
    Class: "stockopname",
    Path: "stockopname",
    Param: "stop",
    IsAuthenticated: true
    // ShortFileName: "Stop"
  },
  StockReduction: {
    ID: 32,
    Name: "Pengeluaran Stok",
    FullName: "Pengeluaran Stok",
    FileName: "Pengeluaran Stok",
    Code: "StockReduction",
    Class: "stockreduction",
    Path: "stockreduction",
    Param: "strd",
    IsAuthenticated: true
    // ShortFileName: "Strd"
  },
  StockTrf: {
    ID: 9,
    Name: "Transfer Barang",
    FullName: "Transfer Barang",
    FileName: "Transfer Barang",
    Code: "StockTrf",
    Class: "stocktrf",
    Path: "stocktrf",
    Param: "sttr",
    IsAuthenticated: true
    // ShortFileName: "Sttr"
  },
  StockClosing: {
    ID: 26,
    Name: "Penutupan Stok",
    FullName: "Penutupan Stok",
    FileName: "Penutupan Stok",
    Code: "StockClosing",
    Class: "stockclosing",
    Path: "stockclosing",
    Param: "stcl",
    IsAuthenticated: true
    // ShortFileName: "Stcl"
  },
  HppAdj: {
    ID: 10,
    Name: "Penyesuaian HPP",
    FullName: "Penyesuaian HPP",
    FileName: "Penyesuaian HPP",
    Code: "HppAdj",
    Class: "hppadj",
    Path: "hppadj",
    Param: "hpad",
    IsAuthenticated: true
    // ShortFileName: "Hpad"
  },
  StockAvailability: {
    ID: 24,
    Name: "Persediaan Stok SO",
    FullName: "Persediaan Stok SO",
    FileName: "Persediaan Stok SO",
    Code: "StockAvailability",
    Class: "stockavailability",
    Path: "stockavailability",
    Param: "stav",
    IsAuthenticated: true
    // ShortFileName: "Stav"
  },
  SpecialPriceAdj: {
    ID: 27,
    Name: "Penyesuaian Harga Spesial",
    FullName: "Penyesuaian Harga Spesial",
    FileName: "Penyesuaian Harga Spesial",
    SheetName: "Penyesuaian Harga Sp",
    Code: "SpecialPriceAdj",
    Class: "specialpriceadj",
    Path: "specialpriceadj",
    Param: "spad",
    IsAuthenticated: true
    // ShortFileName: "Spad"
  },

  // Purchasing
  Buy: {
    ID: null,
    Name: "Pembelian",
    FullName: "Pembelian"
    // Param: not available in dirigo
    // ShortFileName: "Buy"
  },
  Purchase: {
    ID: 12,
    Name: "Purchase Order",
    FullName: "Purchase Order",
    FileName: "Purchase Order",
    Code: "Purchase",
    Class: "purchase",
    Path: "purchase",
    Param: "pur",
    IsAuthenticated: true
    // ShortFileName: "Pur"
  },
  Receive: {
    ID: 13,
    Name: "Penerimaan Stok",
    FullName: "Penerimaan Stok",
    FileName: "Penerimaan Stok",
    Code: "Receive",
    Class: "receive",
    Path: "receive",
    Param: "rcv",
    IsAuthenticated: true
    // ShortFileName: "Rcv"
  },
  RtnReceive: {
    ID: 51,
    Name: "Retur Penerimaan",
    FullName: "Retur Penerimaan Stok",
    FileName: "Retur Penerimaan Stok",
    Code: "RtnReceive",
    Class: "rtnreceive",
    Path: "rtnreceive",
    Param: "rrcv",
    IsAuthenticated: true
    // ShortFileName: "Rrcv"
  },

  // Debt
  DebtGroup: {
    ID: null,
    Name: "Hutang",
    FullName: "Hutang"
    // Param: not available in dirigo
    // ShortFileName: not available in dirigo
  },
  DebtCard: {
    ID: 47,
    Name: "Kartu Hutang",
    FullName: "Kartu Hutang",
    FileName: "Kartu Hutang",
    Code: "DebtCard",
    Class: "debtcard",
    Path: "debtcard",
    Param: "dbcd",
    IsAuthenticated: true
    // ShortFileName: "Dbcd"
  },
  Debt: {
    ID: 48,
    Name: "Hutang",
    FullName: "Hutang",
    FileName: "Hutang",
    Code: "Debt",
    Class: "debt",
    Path: "debt",
    Param: "debt",
    IsAuthenticated: true
    // ShortFileName: "Debt"
  },
  DebtPay: {
    ID: 49,
    Name: "Pembayaran Hutang",
    FullName: "Pembayaran Hutang",
    FileName: "Pembayaran Hutang",
    Code: "DebtPay",
    Class: "debtpay",
    Path: "debtpay",
    Param: "dbpy",
    IsAuthenticated: true
    // ShortFileName: "Dbpy"
  },

  // Sales
  Sell: {
    ID: null,
    Name: "Penjualan",
    FullName: "Penjualan"
    // Param: not available in dirigo
    // ShortFileName: "Sell"
  },
  Sales: {
    ID: 14,
    Name: "Sales Order",
    FullName: "Sales Order",
    FileName: "Sales Order",
    Code: "Sales",
    Class: "salesorder",
    Path: "salesorder",
    Param: "sls",
    IsAuthenticated: true
    // ShortFileName: "Sls"
  },
  Invoice: {
    ID: 15,
    Name: "Nota Penjualan",
    FullName: "Nota Penjualan",
    FileName: "Nota Penjualan",
    Code: "Invoice",
    Class: "invoice",
    Path: "invoice",
    Param: "inv",
    IsAuthenticated: true
    // ShortFileName: "Inv"
  },
  RtnInvoice: {
    ID: 16,
    Name: "Retur Penjualan",
    FullName: "Retur Penjualan",
    FileName: "Retur Penjualan",
    Code: "RtnInvoice",
    Class: "rtninvoice",
    Path: "rtninvoice",
    Param: "rinv",
    IsAuthenticated: true
    // ShortFileName: "Rinv"
  },
  Delivery: {
    ID: 17,
    Name: "Pengiriman",
    FullName: "Pengiriman",
    FileName: "Pengiriman",
    Code: "Delivery",
    Class: "delivery",
    Path: "delivery",
    Param: "dlv",
    IsAuthenticated: true
    // ShortFileName: "Dlv"
  },
  SalesRecap: {
    ID: 28,
    Name: "Rekap Penjualan",
    FullName: "Rekap Penjualan",
    FileName: "Rekap Penjualan",
    Code: "SalesRecap",
    Class: "salesrecap",
    Path: "salesrecap",
    Param: "slrc",
    IsAuthenticated: true
    // ShortFileName: "Slrc"
  },

  // Rec
  RecGroup: {
    ID: null,
    Name: "Piutang",
    FullName: "Piutang"
    // Param: not available in dirigo
    // ShortFileName: not available in dirigo
  },
  RecCard: {
    ID: 44,
    Name: "Kartu Piutang",
    FullName: "Kartu Piutang",
    FileName: "Kartu Piutang",
    Code: "RecCard",
    Class: "reccard",
    Path: "reccard",
    Param: "rccd",
    IsAuthenticated: true
    // ShortFileName: "Rccd"
  },
  Rec: {
    ID: 45,
    Name: "Piutang",
    FullName: "Piutang",
    FileName: "Piutang",
    Code: "Rec",
    Class: "rec",
    Path: "rec",
    Param: "rec",
    IsAuthenticated: true
    // ShortFileName: "Rec"
  },
  RecPay: {
    ID: 46,
    Name: "Pembayaran Piutang",
    FullName: "Pembayaran Piutang",
    FileName: "Pembayaran Piutang",
    Code: "RecPay",
    Class: "recpay",
    Path: "recpay",
    Param: "rcpy",
    IsAuthenticated: true
    // ShortFileName: "Rcpy"
  },

  // Contact
  Contact: {
    ID: null,
    Name: "Manajemen Kontak",
    FullName: "Manajemen Kontak"
    // Param: not available in dirigo
    // ShortFileName: "Cont"
  },
  Vendor: {
    ID: 11,
    Name: "Vendor",
    FullName: "Vendor",
    FileName: "Vendor",
    Code: "Vendor",
    Class: "vendor",
    Path: "vendor",
    Param: "ven",
    IsAuthenticated: true
    // ShortFileName: "Ven"
  },
  Client: {
    ID: 18,
    Name: "Pelanggan",
    FullName: "Pelanggan",
    FileName: "Pelanggan",
    Code: "Client",
    Class: "client",
    Path: "client",
    Param: "cli",
    IsAuthenticated: true
    // ShortFileName: "Cli"
  },
  Warehouse: {
    ID: 19,
    Name: "Gudang",
    FullName: "Gudang",
    FileName: "Gudang",
    Code: "Warehouse",
    Class: "warehouse",
    Path: "warehouse",
    Param: "whs",
    IsAuthenticated: true
    // ShortFileName: "Whs"
  },

  // Setting
  Settings: {
    ID: null,
    Name: "Pengaturan",
    FullName: "Pengaturan"
    // Param: not available in dirigo
    // ShortFileName: "Stg"
  },
  CompanyProfile: {
    ID: 43,
    Name: "Profil Perusahaan",
    FullName: "Profil Perusahaan",
    FileName: "Profil Perusahaan",
    Code: "Company",
    Class: "company",
    Path: "company",
    Param: "cp",
    IsAuthenticated: true
    // ShortFileName: "Cp"
  },
  UserList: {
    ID: 37,
    Name: "Daftar Pengguna",
    FullName: "Pengguna",
    FileName: "Pengguna",
    Code: "User",
    Class: "user",
    Path: "user",
    Param: "user",
    IsAuthenticated: true
    // ShortFileName: "User"
  },
  Access: {
    ID: 42,
    Name: "Daftar Akses",
    FullName: "Akses",
    FileName: "Akses",
    Code: "Access",
    Class: "access",
    Path: "access",
    Param: "acs",
    IsAuthenticated: true
    // ShortFileName: "Acs"
  },
  AutoNumber: {
    ID: 40,
    Name: "Penomoran Otomatis",
    FullName: "Penomoran Otomatis",
    FileName: "Penomoran Otomatis",
    Code: "AutoNumber",
    Class: "autonumber",
    Path: "autonumber",
    Param: "anum",
    IsAuthenticated: true
    // ShortFileName: "Anum"
  },
  ExpenseCategory: {
    ID: 30,
    Name: "Klasifikasi Pengeluaran",
    FullName: "Klasifikasi Pengeluaran",
    FileName: "Klasifikasi Pengeluaran",
    Code: "ExpenseCategory",
    Class: "expensecategory",
    Path: "expensecategory",
    Param: "expc",
    IsAuthenticated: true
    // ShortFileName: "Expc"
  },
  SpecialPrice: {
    ID: 31,
    Name: "Harga Spesial",
    FullName: "Harga Spesial",
    FileName: "Harga Spesial",
    Code: "SpecialPrice",
    Class: "specialprice",
    Path: "specialprice",
    Param: "sp",
    IsAuthenticated: true
    // ShortFileName: "Sp"
  },
  PaymentType: {
    ID: 41,
    Name: "Jenis Pembayaran",
    FullName: "Jenis Pembayaran",
    FileName: "Jenis Pembayaran",
    AltFullName: "Jenis Bayar",
    Code: "PaymentType",
    Class: "paymenttype",
    Path: "paymenttype",
    Param: "payt",
    IsAuthenticated: true
    // ShortFileName: "Payt"
  },

  // Support - Security
  ForgotPassword: {
    ID: null,
    Name: "Lupa Password",
    FullName: "Lupa Password",
    Path: "forgotpassword",
    IsAuthenticated: false
    // ShortFileName: "Fpwd"
  },
  SignIn: {
    ID: null,
    Name: "Masuk",
    FullName: "Masuk",
    Path: "signin",
    IsAuthenticated: false
    // ShortFileName: "Sgin"
  },
  SignOut: {
    ID: null,
    Name: "Keluar",
    FullName: "Keluar",
    Path: "signout",
    IsAuthenticated: false
    // ShortFileName: "Sgou"
  },
  SignUp: {
    ID: null,
    Name: "Registrasi",
    FullName: "Registrasi",
    Path: "signup",
    IsAuthenticated: false
    // ShortFileName: "Sgup"
  },
  
  // Support - Website
  ContactUs: {
    ID: null,
    Name: "Kontak Kami",
    FullName: "Kontak Kami",
    Path: "contactus",
    IsAuthenticated: false
  },
  FAQ: {
    ID: null,
    Name: "FAQ",
    FullName: "FAQ",
    Path: "faq",
    IsAuthenticated: false
  },
  Features: {
    ID: null,
    Name: "Fitur",
    FullName: "Fitur",
    Path: "features",
    IsAuthenticated: false
  },
  Home: {
    ID: null,
    Name: "Beranda",
    FullName: "Beranda",
    Path: "home",
    IsAuthenticated: false
  },
  Policy: {
    ID: null,
    Name: "Kebijakan Privasi",
    FullName: "Kebijakan Privasi",
    Path: "policy",
    IsAuthenticated: false
  },
  Stories: {
    ID: null,
    Name: "Cerita",
    FullName: "Cerita",
    Path: "stories",
    IsAuthenticated: false
  },
  TNC: {
    ID: null,
    Name: "Syarat & Ketentuan",
    FullName: "Syarat & Ketentuan",
    Path: "termandcondition",
    IsAuthenticated: false
  },
  Verification: {
    ID: null,
    Name: "Verifikasi",
    FullName: "Verifikasi",
    Path: "verification",
    IsAuthenticated: false
  },
  Price: {
    ID: null,
    Name: "Price",
    FullName: "Price",
    Path: "price",
    IsAuthenticated: false
  },
  Inspiration: {
    ID: null,
    Name: "Inspirasi",
    FullName: "Inspirasi",
    Path: "inspiration",
    IsAuthenticated: false
  },

  // Support - Other
  BestSellingItems: {
    ID: null,
    Name: "Barang Terlaris",
    FullName: "Barang Terlaris",
    Code: "BestSellingItems",
    Path: "bestsellingitems",
    IsAuthenticated: true
    // ShortFileName: "Bsi"
  },
  ClientAddress: {
    ID: null,
    Name: "Alamat",
    FullName: "Alamat",
    Path: "clientaddress",
    IsAuthenticated: true
    // ShortFileName: "Clia"
  },
  CompanySettingType: {
    ID: null,
    Name: "Tipe Pengaturan Perusahaan",
    FullName: "Tipe Pengaturan Perusahaan",
    Path: "companysettingtype",
    IsAuthenticated: true
    // ShortFileName: "Cst"
  },
  GlobalSearch: {
    ID: null,
    Name: "Global Search",
    FullName: "Global Search",
    Path: "globalsearch",
    IsAuthenticated: true
    // ShortFileName: "Gsrc"
  },
  Image: {
    ID: null,
    Name: "Gambar",
    FullName: "Gambar",
    Path: "image",
    IsAuthenticated: true
    // ShortFileName: "Img"
  },
  InitialData: {
    ID: null,
    Name: "Data Awal",
    FullName: "Data Awal",
    Path: undefined, // not available
    IsAuthenticated: true
  },
  ItemPackaging: {
    ID: null,
    Name: "Kemasan",
    FullName: "Kemasan",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: "Pkg"
  },
  ItemType: {
    ID: null,
    Name: "Tipe",
    FullName: "Tipe",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: not available in dirigo
  },
  ItemVariant: {
    ID: null,
    Name: "Variant",
    FullName: "Variant",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: not available in dirigo
  },
  Module: {
    ID: null,
    Name: "Menu",
    FullName: "Menu",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: "Mod"
  },
  ModuleAttr: {
    ID: null,
    Name: "Atribut",
    FullName: "Atribut",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: "Matt"
  },
  ModuleGroup: {
    ID: null,
    Name: "Menu Grup",
    FullName: "Menu Grup",
    // Path: not available in dirigo
    IsAuthenticated: true
    // ShortFileName: "Mgrp"
  },
  ModulePackage: {
    ID: null,
    Name: "Paket Modul",
    FullName: "Paket Modul",
    // Path: not available in dirigo
    IsAuthenticated: true
  },
  RecRepair: {
    ID: null,
    Name: "Perbaikan Piutang",
    FullName: "Perbaikan Piutang",
    Path: undefined, // not available
    IsAuthenticated: true
  },
  Stock: {
    ID: null,
    Name: "Stok Gudang",
    FullName: "Stok Gudang",
    FileName: "Stok Gudang",
    Code: "Stock",
    Class: "stock",
    Path: "stock",
    IsAuthenticated: true
    // ShortFileName: "Stk"
  },
  StockRepair: {
    ID: null,
    Name: "Perbaikan Stok",
    FullName: "Perbaikan Stok",
    Path: undefined, // not available
    IsAuthenticated: true
  },
  StockSpecialPrice: {
    ID: null,
    Name: "Stok Harga Spesial",
    FullName: "Stok Harga Spesial",
    Code: "StockSpecialPrice",
    Class: "stockspecialprice",
    Path: "stockspecialprice",
    IsAuthenticated: true
    // ShortFileName: "StSp"
  },
  Subscription: {
    ID: null,
    Name: "Berlangganan",
    FullName: "Berlangganan",
    // Path: not available in dirigo
    IsAuthenticated: true
  },
  TopBuyer: {
    ID: null,
    Name: "Top Buyer",
    FullName: "Top Buyer",
    Code: "TopBuyer",
    Path: "topbuyer",
    IsAuthenticated: true
    // ShortFileName: "Tbuy"
  },
  TransactionType: {
    ID: null,
    Name: "Jenis Transaksi",
    FullName: "Jenis Transaksi",
    Code: "TransactionType",
    Path: "transactiontype",
    IsAuthenticated: true,
    Param: "trxt",
    // ShortFileName: null
  },
  Variant: {
    ID: null,
    Name: "Variant",
    FullName: "Variant",
    Path: "variant",
    IsAuthenticated: true
    // ShortFileName: "Var"
  },
  VendorAddress: {
    ID: null,
    Name: "Alamat",
    FullName: "Alamat",
    Path: "vendoraddress",
    IsAuthenticated: true
    // ShortFileName: "Vena"
  },
  VendorItem: {
    ID: null,
    Name: "Barang Vendor",
    FullName: "Barang Vendor",
    Path: "vendoritem",
    IsAuthenticated: true
    // ShortFileName: "Veni"
  },
  Version: {
    ID: null,
    Name: "Version",
    FullName: "Version",
    Code: "Version",
    Path: "version",
    IsAuthenticated: true
    // ShortFileName: "Ver"
  },

  // Public
  SalesSelfService: {
    ID: null,
    Name: "Layanan Pemesanan Mandiri",
    FullName: "Layanan Pemesanan Mandiri",
    Code: "SO",
    Class: "so",
    Path: "so",
    IsAuthenticated: false
    // ShortFileName: "Sls" (copy from: Sales)
  }
}