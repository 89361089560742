<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <Details :details="details"/>
    <TransactionSetting :details="details"/>
  </kst-page-details>
</template>

<script>
import Model from "../UserModel.js";
import Services from "../../../services/Api/UserServices.js";

import Details from "./UserD_Details.vue";
import TransactionSetting from "./UserD_TransactionSetting.vue";

export default {
  components: {
    Details,
    TransactionSetting
  },
   data: () => ({
    actions: [],
    details: {}
  }),
  methods: {
    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
    },

    /*** custom ***/

    resetData(id) {
      const access = this.$kst.Access.create(Model.Access);
      this.details = {};

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, access
      );
    }
  }
}
</script>