<template>
  <div>
    <div class="base-background"></div>
    <div class="second-background"></div>
    <div class="company-logo">
      <img src="../../../assets/companylogo.png" class="default-logo" />
    </div>
    <div class="content">
      <h1>KEBIJAKAN PRIVASI</h1>
      <article>
        <h4>PERHATIAN</h4>
        <p class="attention">Mohon baca seluruh ketentuan penggunaan Dirigo sebelum melakukan registrasi dan menggunakan layanan Dirigo. Menekan tombol “Daftar” berarti Pengguna telah membaca dan menyetujui semua informasi yang tertera pada halaman ini.</p>
        <p>Bagian Kebijakan Privasi ini berisikan informasi mengenai pengolahan dan penggunaan data pribadi Anda (Pengguna layanan Dirigo / Pengguna) yang dilakukan oleh Dirigo. </p>
        <h5>1. DEFINISI</h5>
        <p>Bagian ini menjelaskan kata atau istilah tertentu yang tercantum dalam Kebijakan Privasi.</p>
        <ol>
          <li><b>KST</b> - PT Kreatifitas Sinergisme Teknoindo - atau dapat disebut juga Pengembang – adalah perusahaan yang membuat dan mengembangkan Dirigo dan berperan sebagai pemilik dan pengelola sah semua layanan Dirigo;</li>
          <li><b>Dirigo</b> adalah layanan teknologi yang dibuat dan dikembangkan oleh KST (dapat diakses pada <i>www.dirigo.id</i>) yang berguna untuk membantu pengelolaan kasir dan stok pada toko, atau jenis badan usaha lainnya, serta membantu memberikan laporan usaha sehingga pengguna dapat dimudahkan dalam mengelola usaha;</li>
          <li><b>Pengguna / User</b> adalah perorangan atau badan usaha atau perusahaan yang menggunakan fasilitas dan layanan Dirigo;</li>
          <li><b>Kebijakan Privasi</b> adalah bagian yang menjelaskan pengolahan dan penggunaan data privasi pengguna yang dilakukan oleh PT Kreatifitas Sinergisme Teknoindo;</li>
          <li><b>Data Pribadi</b> (berdasarkan Peraturan Menteri Komunikasi dan Informatika tentang Perlindungan Data Pribadi dalam Sistem Eleketronik No 20 tahun 2016) adalah  data perseorangan tertentu yang disimpan, dirawat, dan dijaga kebenaran serta dilindungi kerahasiaannya.</li>
        </ol>
        <h5>2. PENGUMPULAN DATA PRIBADI</h5>
        <ol>
          <li>Pengumpulan data pribadi pertama kali dilakukan pada saat pengguna mendaftarkan diri untuk berlangganan Dirigo. Data pribadi yang diminta adalah nama toko, nama pemilik, alamat e-mail, dan alamat toko;</li>
          <li>Dirigo dapat sewaktu-waktu meminta informasi tambahan dari Pengguna demi memberikan layanan yang lebih baik. Apabila Pengguna tidak bersedia memberikan dan hal tersebut dirasa dapat mengganggu jalannya sistem, Dirigo berhak memblokir atau membekukan akun yang terdaftar;</li>
          <li>Pengguna bertanggung jawab penuh terhadap kebenaran informasi yang diberikan kepada Dirigo. Apabila terjadi permasalahan terkait pemalsuan atau ketidaktepatan informasi, maka seluruh tanggung jawab ada pada pihak Pengguna dan Dirigo berhak memberikan sanksi yang dirasa diperlukan;</li>
          <li>Dirigo dapat sewaktu-waktu meminta informasi tambahan dari Pengguna demi memberikan layanan yang lebih baik. Apabila Pengguna tidak bersedia memberikan dan hal tersebut dirasa dapat mengganggu jalannya sistem, Dirigo berhak memblokir atau membekukan akun yang terdaftar;</li>
        </ol>
        <h5>3. PENGOLAHAN DATA PRIBADI</h5>
        <ol>
          <li>Semua privasi yang telah Pengguna percayakan akan digunakan dan diolah untuk :
            <ul>
              <li>Memperbaiki dan mengembangkan layanan Dirigo;</li>
              <li>Membuat fitur-fitur baru demi kepentingan layanan Dirigo yang lebih baik;</li>
              <li>Mencegah permasalahan yang mungkin timbul di kemudian hari terkait layanan yang diberikan;</li>
              <li>Menjalin komunikasi yang baik antara Dirigo dan Pengguna;</li>
              <li>Menghubungi Pengguna apabila ada kepentingan yang mendesak atau informasi yang hendak disampaikan;</li>
              <li>Melakukan penelitian/analisis/pengujian terhadap suatu produk demi meningkatkan layanan yang diberikan Dirigo;</li>
              <li>Keperluan investigasi penegak hukum HANYA apabila dirasa ada transaksi-transaksi atau data yang mencurigakan dan mengindikasikan terjadinya hal-hal yang melanggar norma hukum;</li>
              <li>Selain kepentingan-kepentingan yang tercantum di atas, Dirigo hanya berhak menggunakan data privasi Pengguna untuk kepentingan lain setelah melakukan konfirmasi ulang pada Pengguna.</li>
            </ul>
          </li>
          <li>Dirigo akan melakukan pemusnahan seluruh data Pengguna ketika Pengguna berhenti menggunakan layanan Dirigo.</li>
        </ol>
      </article>
    </div>
    <div id="scrollup_button" v-if="showScrollUpButton" @click="scrollToTop()">
      <i class="fas fa-long-arrow-alt-up"></i>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    showScrollUpButton: false
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      // Any code to be executed when the window is scrolled
      if(window.scrollY > 1000) {
          this.showScrollUpButton = true;
      } else {
          this.showScrollUpButton = false;
      }
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style scoped lang="css" src="./_policy.css"/>