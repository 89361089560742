<template>
  <kst-section items-mode>
    <div class="columns">
      <div class="column">
        <Search
          :userData="userData"
          :warehouseOptions="warehouseOptions"
          @submit="handleSearch_Submit"
        />
      </div>
      <div class="column">
        <SpecialPrice
          :supportData="supportData"
          :specialPriceOptions="specialPriceOptions"
          @submit="handleSpecialPrice_Submit"
        />
      </div>
    </div>

    <List
      :userData="userData"
      :userItems="userItems"
    />
  </kst-section>
</template>

<script>
import Model from "../StclModel.js";

import List from "./StclN_Items_List.vue";
import Search from "./StclN_Items_Search.vue";
import SpecialPrice from "./StclN_Items_SpecialPrice.vue";

export default {
  components: {
    List,
    Search,
    SpecialPrice
  },
  props: {
    userData: Object,
    userItems: Array,
    specialPriceOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    supportData: {
      SpecialPriceID: null
    }
  }),
  mounted() {
    this.supportData.SpecialPriceID = this.specialPriceOptions.rows[0].ID;
  },
  methods: {
    handleSearch_Submit(stockList) {
      Model.setItemsByStockList(this.userItems, stockList,
        this.$kst.Search.getValue(this.supportData.SpecialPriceID, null),
        this.specialPriceOptions
      );
      Model.updateDetails(this.userData, this.userItems);
    },

    handleSpecialPrice_Submit() {
      const specialPriceId = this.$kst.Search.getValue(
        this.supportData.SpecialPriceID, null
      );

      Model.updateItemsBySpecialPrice(this.userItems, specialPriceId);
      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>