<template>
  <div v-if="isMutiaraBali" class="mb-2">
    <div class="is-size-5 has-text-centered">{{ name }}</div>
  </div>
</template>

<script>
import AppCatalog from '../../../services/App/AppCatalog';

export default {
  props: {
    companyData: Object,
    name: String
  },
  computed: {
    isMutiaraBali() {
      return this.companyData.id === AppCatalog.Company.MutiaraBali.CompanyID;
    }
  }
}
</script>