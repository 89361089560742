<template>
  <kst-section items-mode>
    <InvoiceSearchItem
      :items="invoiceItems"
      @init="handleInvoiceItem_Init"
      @submit="handleInvoiceItem_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Model.InvoiceItem.Label"
        :moduleType="Model.InvoiceItem.ModuleType.New"
        @click="handleAdd_InvoiceItem"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <kst-button action-mode
          class="is-primary"
          :label="Model.InvoiceItem.Label"
          :moduleType="Model.InvoiceItem.ModuleType.New"
          @click="handleAdd_InvoiceItem"
        />
      </template>
    </template>

    <List
      :userData="userData"
      :userItems="userItems"
    />
  </kst-section>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Model from "../DlvModel.js";

import List from "./DlvN_Items_List.vue";
import InvoiceSearchItem from "../../Invoice/SearchItem/InvScI.vue";

export default {
  components: {
    List,
    InvoiceSearchItem
  },
  mixins: [DialogMixin],
  props: {
    invoiceData: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    selectedIndex: null,
    invoiceItems: [],
    // func
    showInvoiceItemFunc: null,
  }),
  methods: {
    /*** by sales item modal ***/

    handleInvoiceItem_Init(show) {
      this.showInvoiceItemFunc = show;
    },
    handleInvoiceItem_Submit(invoiceItemData) {
      let userItem = Model.createItem(this.userData);
      Model.setItemByInvoiceItem(userItem, invoiceItemData);
      Model.updateItem(userItem);
      this.userItems.push(userItem);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_InvoiceItem() {
      this.invoiceItems = Model.getInvoiceItemsForSelection(
        this.invoiceData.Items, this.userItems
      );
      this.showInvoiceItemFunc();
    }
  }
}
</script>