<template>
  <kst-container v-if="hasAccess" class="kst-dashboard-list px-5 py-5">
    <nav class="level is-align-items-start">
      <div class="level-left is-block">
        <div class="has-text-weight-bold block">{{ info.Title }}</div>
        <template v-if="topLeftSlotVisible">
          <template v-if="topLeftSlotVisible2">
            <div class="block">
              <slot name="top-left"/>
            </div>
          </template>
        </template>
      </div>
      <template v-if="info.Button">
        <div class="level-right">
          <kst-button action-mode
            class="is-primary"
            :iconLeft="info.Button.Icon"
            :label="info.Button.Title"
            :module="info.Button.Module"
            :moduleType="info.Button.ModuleType"
          />
        </div>
      </template>
    </nav>

    <kst-skeleton :active="isLoading"/>
    <kst-failure init-mode :items="failureInit"/>

    <template v-if="defaultSlotVisible">
      <slot />
    </template>
  </kst-container>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SlotMixins from "../../../mixins/SlotMixins.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadList, set and do reload (as needed)
 */
export default {
  mixins: [AxiosMixin, RouterMixin, SlotMixins],
  props: {
    /**
     * info contains key:
     * - Title
     * - Button: Icon, Title, Module, ModuleType
     */
    info: Object,
    hasAccess: Boolean
  },
  data: () => ({
    // loading
    isLoading: true,
    // slot
    defaultSlotVisible: false,
    topLeftSlotVisible: false,
    // error
    failureInit: null
  }),
  computed: {
    topLeftSlotVisible2() {
      return this.slotHasContent("top-left");
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_Data();
      if (this.init_Validate()) {
        this.$emit(this.$kst.Enum.Event.Init,
          this.loadList,
          this.reload
        );
      }
    },
    init_Data() {
      // loading
      this.isLoading = true;
      // slot
      this.defaultSlotVisible = false;
      this.topLeftSlotVisible = false;
      // error
      this.failureInit = null;
    },
    init_Validate() {
      // check: access
      if (this.hasAccess !== true) {
        this.init_Failed();
        return false;
      }

      return true;
    },
    init_Success() {
      this.isLoading = false;
      this.defaultSlotVisible = true;
      this.topLeftSlotVisible = true;
    },
    init_Failed() {
      this.isLoading = false;
    },

    /*** custom ***/

    reload() {
      this.init();
    },

    loadList(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadList_Success,
        this.loadList_Fault,
        this.loadList_Fault
      );
    },
    loadList_Success(data, successCallback) {
      successCallback(data);
      this.init_Success();
    },
    loadList_Fault(message) {
      this.failureInit = message;
      this.init_Failed();
    }
  }
}
</script>