<template>
  <kst-container class="kstmod-moduleattr-relatedinput">
    <kst-section :title="title">
      <template #top-right>
        <template v-if="buttonsVisible">
          <div class="buttons">
            <kst-button check-all-mode
              @click="handleCheckAll"
            />
            <kst-button uncheck-all-mode
              @click="handleUncheckAll"
            />
          </div>
        </template>
      </template>

      <Section
        :module-attr-list="moduleAttrList"
        :module-id="moduleId"
        :sub-code="null"
        @input="handleInput"
      />

      <template v-for="item of subCodeList">
        <div :key="item">
          <kst-divider />
          <div class="label">
            ({{ getSubLabel(item) }})
          </div>

          <Section
            :module-attr-list="moduleAttrList"
            :module-id="moduleId"
            :sub-code="item"
            @input="handleInput"
          />
        </div>
      </template>
    </kst-section>
  </kst-container>
</template>

<script>
import Model from "../ModuleAttrModel.js";

import Section from "./MattReI_Section.vue";

export default {
  components: {
    Section
  },
  props: {
    moduleAttrList: Array,
    moduleId: Number
  },
  data: () => ({
    buttonsVisible: false,
    subCodeList: []
  }),
  computed: {
    title() {
      return Model.ModuleType.List.Name + " " + Model.Module.FullName
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.buttonsVisible = false;

      for (const moduleAttr of this.moduleAttrList) {
        if (moduleAttr.ModuleID === this.moduleId) {
          // populate: SubCode
          if (moduleAttr.SubCode !== null) {
            if (!this.subCodeList.includes(moduleAttr.SubCode)) {
              this.subCodeList.push(moduleAttr.SubCode);
            }
          }

          // if: set buttons visibility (must have module attr other than default)
          if (!moduleAttr.IsDefault) {
            this.buttonsVisible = true;
          }
        }
      }
    },

    handleCheckAll() {
      Model.selectListByModuleAllOption(this.moduleAttrList, this.moduleId);
      this.$emit(this.$kst.Enum.Event.Submit, this.moduleId);
    },
    handleUncheckAll() {
      Model.unselectListByModuleNoneOption(this.moduleAttrList, this.moduleId);
    },
    handleInput(item) {
      this.$emit(this.$kst.Enum.Event.Input, item);
    },

    getSubLabel(subCode) {
      return Model.getSubLabel(subCode);
    }
  }
}
</script>