import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";
import OutputType from "../../services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Client: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 500,
    Required: true
  },
  ClientAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client,
    Help: "Pilih dari master " + AppModule.Client.FullName + ", atau ketik manual.",
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 1000
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  DeliveryDate: {
    Label: AppCatalog.Field.Date.Label + " Kirim",
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    PrintLabel: AppCatalog.Field.DiscPercent.PrintLabel,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  DiscValue: {
    Label: AppCatalog.Field.DiscValue.Label,
    ExportLabel: AppCatalog.Field.DiscValue.ExportLabel,
    PrintLabel: AppCatalog.Field.DiscValue.PrintLabel,
    Type: AppCatalog.Field.DiscValue.Type,
    MaxLength: AppCatalog.Field.DiscValue.MaxLength,
    MinValue: AppCatalog.Field.DiscValue.MinValue,
    MaxValue: "TotalBruto",
    Output: AppCatalog.Field.DiscValue.Output,
    Required: true
  },
  DueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    Required: true,
    RequiredHide: true
  },
  ID: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "SONumber",
    RelatedModule: AppModule.Invoice,
    Param: AppModule.Invoice.Param + AppCatalog.Field.ID.Param
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    PrintLabel: AppCatalog.Field.Date.Label + " Transaksi",
    PrintFakturLabel: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    Required: true,
  },
  IsNeedDelivery: {
    Label: "Perlu Pengiriman?",
    Type: InputType.Boolean,
    Output: OutputType.Boolean.YesNo
  },
  IsPos: {
    Label: AppCatalog.Field.RecordTypeID.Label,
    Type: AppCatalog.Field.RecordTypeID.Type,
    ReadOnly: true
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input
  },
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.Output,
    MaxValue: ["Total", AppCatalog.Field.Total.Label],
    MinValue: AppCatalog.Field.PaymentValue.MinValue,
    Required: true
  },
  PONumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Reference.Type,
    MaxLength: 50,
    Highlight: true
  },
  ReturnInvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " " + AppModule.RtnInvoice.FullName
  },
  SalesDraftID: {
    Label: AppCatalog.Field.Number.Label + " SO",
    ExportLabel: AppModule.Sales.FullName,
    HeaderLabel: AppModule.Sales.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "DraftNumber",
    RelatedModule: AppModule.Sales,
    Param: AppModule.Sales.Param + AppCatalog.Field.ID.Param
  },
  SalesDraftIsActive: {
    Label: "Status Penghapusan SO",
    Type: AppCatalog.Field.Status.Type,
    ReadOnly: true
  },
  SalesRecapID: {
    Label: AppModule.SalesRecap.FullName,
    NullText: AppCatalog.Message.TableEmpty
  },
  SONumber: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: AppCatalog.Field.Number.MaxLength,
    Highlight: true
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  TotalBruto: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  TotalDisc: {
    Label: AppCatalog.Field.TotalDisc.Label,
    Type: AppCatalog.Field.TotalDisc.Type,
    ReadOnly: AppCatalog.Field.TotalDisc.ReadOnly,
    Output: AppCatalog.Field.TotalDisc.Output
  },
  TransactionID: {
    Label: AppModule.Invoice.FullName + " " + AppCatalog.Message.Old,
    ExportLabel: AppCatalog.Field.Number.Label + " " +
      AppModule.Invoice.FullName + " " + AppCatalog.Message.Old,
    AltLabel: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.ID.Type,
    RelatedValue: "TransactionNumber",
    RelatedModule: AppModule.Invoice,
    Param: AppModule.Transaction.Param + AppCatalog.Field.ID.Param
  },
  TransactionDate: {
    Label: AppCatalog.Field.Date.Label,
    ExportLabel: AppCatalog.Field.Date.Label + " " +
      AppModule.Invoice.FullName + " " + AppCatalog.Message.Old,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  TransactionNumber: {
    Label: AppModule.Invoice.FullName + " " + AppCatalog.Message.Old,
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  ClientAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  ClientCreditAmount: {
    Label: AppCatalog.Field.CreditAmount.Label,
    Type: AppCatalog.Field.CreditAmount.Type,
    Output: AppCatalog.Field.CreditAmount.Output,
    ReadOnly: AppCatalog.Field.CreditAmount.ReadOnly
  },
  ClientOverDueDate: {
    Label: AppCatalog.Field.OverDueDate.Label,
    Type: AppCatalog.Field.OverDueDate.Type,
    ReadOnly: true,
    NullText: AppCatalog.Message.None
  },
  ClientRecTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Rec.FullName + " " +
      AppModule.Client.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  DiscType: {
    Label: AppCatalog.Field.DiscType.Label,
    Type: AppCatalog.Field.DiscType.Type
  },
  DraftDate: {
    Label: AppCatalog.Field.Date.Label,
    ExportLabel: AppCatalog.Field.Date.Label + " SO",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  PaymentReturn: {
    Label: AppCatalog.Field.PaymentReturn.Label,
    Type: AppCatalog.Field.PaymentReturn.Type,
    Output: AppCatalog.Field.PaymentReturn.Output,
    ReadOnly: true
  },
  RecTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Rec.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  TotalItems: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    HelpLabel: "item",
    Type: InputType.Integer,
    ReadOnly: true
  },

  // validation
  StockMissingQty: {
    Label: AppCatalog.Field.QtyStock.Label,
    Type: AppCatalog.Field.QtyStock.Type,
    MaxValue: 0
  },
  DeliveryMissingQty: {
    Label: AppCatalog.Field.Qty.Label + " Pengiriman",
    Type: AppCatalog.Field.Qty.Type,
    MaxValue: 0
  }
}