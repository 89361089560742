import App from "../../services/App/App.js";

export default {
  /*** method ***/

  setDetailsByData(userData, userDetails, isReachLimit) {
    userData.ID = userDetails.ID;
    // user input
    userData.Name = App.In.getString(userDetails.Name);
    userData.Email = App.In.getString(userDetails.Email);
    userData.Phone = App.In.getString(userDetails.Phone);
    userData.AccessID = userDetails.Access.AccessID;
    userData.AccessName = App.In.getString(userDetails.Access.AccessName);
    userData.DefaultModuleID = userDetails.Access.DefaultModuleID;
    userData.DefaultWarehouseID = userDetails.Access.DefaultWarehouseID;
    userData.BackDateInterval = App.Out.getInteger(
      userDetails.Access.BackDateInterval);
    userData.IsActive = App.In.getBoolean(userDetails.IsActive);
    // defined by system
    userData.AccessIsOwner = App.In.getBoolean(userDetails.Access.AccessIsOwner);
    userData.IsReachLimit = isReachLimit;
  }
}