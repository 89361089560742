<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SpadCatalog.js";
import Info from "../SpadInfo.js";
import Model from "../SpadModel.js";
import SpadItemCatalog from "../SpadItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.AdjNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.Total);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(SpadItemCatalog.DispatchID.Label);
        this.addColumnHeader(SpadItemCatalog.DispatchID);
        this.addColumnHeader(SpadItemCatalog.Packaging);
        this.addColumnHeader(SpadItemCatalog.Qty);
        this.addColumnHeader(SpadItemCatalog.TotalQty);
        this.addColumnHeader_ID(SpadItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(SpadItemCatalog.SpecialPriceID);
        this.addColumnHeader(SpadItemCatalog.SpecialPriceDiscPercent);
        this.addColumnHeader(SpadItemCatalog.SellPrice);
        this.addColumnHeader(SpadItemCatalog.Total);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("AdjNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("Total", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, SpadItemCatalog);
          this.addCellRelatedValue("DispatchID", row, SpadItemCatalog);
          this.addCellValue("Packaging", row, SpadItemCatalog);
          this.addCellValue("Qty", row, SpadItemCatalog);
          this.addCellValue("TotalQty", row, SpadItemCatalog);
          this.addCellValue("SpecialPriceID", row, SpadItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, SpadItemCatalog);
          this.addCellValue("SpecialPriceDiscPercent", row, SpadItemCatalog);
          this.addCellValue("SellPrice", row, SpadItemCatalog);
          this.addCellValue("Total", row, SpadItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>