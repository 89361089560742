<template>
  <kst-table view-mode
    :action="hasAccessAction"
    :data="items"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PayNumber"
      :label="Catalog.PayNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-if="warehouseField"
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-if="clientField"
      v-slot="{ column, row }"
      field="VendorName"
      :label="Catalog.VendorID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="Catalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="ReceivePaymentValue"
      :label="DbpyRcvCatalog.ReceivePaymentValue.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbpyRcvCatalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="StatusID"
      :label="Catalog.StatusID.Label"
    >
      <kst-status :value="row[column.field]" :statusEnum="Model.StatusID"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.RelatedList.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../DbpyCatalog.js";
import Model from "../DbpyModel.js";
import DbpyRcvCatalog from "../DbpyRcvCatalog.js";

export default {
  props: {
    access: Object,
    items: Array,
    // field visibility (order by name)
    clientField: Boolean,
    warehouseField: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    DbpyRcvCatalog: DbpyRcvCatalog,
    Model: Model
  }),
  computed: {
    hasAccessAction() {
      return this.access.Act_Details ||
        this.access.Act_EditStatus ||
        this.access.Act_PrintDetails ||
        this.access.Act_ExportDetails;
    }
  }
}
</script>