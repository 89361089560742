<template>
  <div v-if="theVisible" class="kst-info">
    <b-message type="is-info" :closable="closable">
      <template #header>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <kst-icon icon-class="has-text-white" info-mode/>
          </div>
          <div class="column pl-0">
            {{ AppCatalog.Message.InfoOnForm }}
          </div>
        </div>
      </template>
      <div class="content">
        <ul>
          <li
            v-for="(message, index) of items"
            :key="index"
            class="has-text-left"
            v-html="message"
          />
        </ul>
      </div>
    </b-message>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    items: Array,
    closable: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getVisible() {
      if (this.items === undefined || this.items === null) {
        return false;
      }
      else if (!Array.isArray(this.items)) {
        return false;
      }
      else if (this.items.length === 0) {
        return false;
      }

      return true;
    }
  }
}
</script>