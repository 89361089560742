<template>
  <kst-actions has-default slim-size :actions="actions"/>
</template>

<script>
export default {
  props: {
    actions: Array
  }
}
</script>