import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";
import PosItemCatalog from "./PosItemCatalog.js";

export default {
  Module: AppModule.Pos,

  Item: {
    Module: AppModule.Item
  },

  Search: {
    SearchFields: ["Name", "SKU", "Barcode"]
  },

  DiscType: {
    None: {
      ID: 0,
      Label: "Tanpa Diskon",
    },
    Value: {
      ID: 1,
      Label: "Diskon (Rp)",
    },
    Percent: {
      ID: 2,
      Label: "Diskon (%)",
    }
  },

  /*** method ***/

  createDetails() {
    return this.helper.createDetails(this.DiscType);
  },
  createItem() {
    return this.helper.createItem();
  },
  createDiscTypeOptions(optionAllText) {
    return this.helper.createDiscTypeOptions(optionAllText, this.DiscType);
  },

  setDetailsByClient(userData, clientData) {
    this.helper.setDetailsByClient(userData, clientData);
  },
  setDetailsByPaymentList(userData, paymentList) {
    return this.helper.setDetailsByPaymentList(userData, paymentList);
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    return this.helper.setDetailsByWarehouseList(userData, warehouseList);
  },

  clearDetailsByClient(userData) {
    this.helper.clearDetailsByClient(userData);
  },

  setItemBySpecialPrice_existing(userItem, qty) {
    return this.helper.setItemBySpecialPrice_existing(userItem, qty);
  },
  setItemBySpecialPrice_new(userItem, stockSpecialPriceData, qty) {
    return this.helper.setItemBySpecialPrice_new(
      userItem, stockSpecialPriceData, qty
    );
  },
  setItemByEditSellPrice(userItem, sellPrice) {
    this.helper.setItemByEditSellPrice(userItem, sellPrice);
  },
  setItemErrors(item, field, errors) {
    item.Errors = App.Vee.getRowErrors(item, field, errors);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },

  updateDetails(userData, userItems) {
    return this.helper.updateDetails(userData, userItems, this.DiscType);
  },
  updateDetailsByPrintThermal(data) {
    this.helper.updateDetailsByPrintThermal(data);
  },

  updateItem(userItem) {
    return this.helper.updateItem(userItem);
  },
  updateItemsByPrintThermal(items) {
    this.helper.updateItemsByPrintThermal(items);
  },

  populateData(userData, userItems) {
    let result = this.helper.populateDetails(userData, this.DiscType);
    result.Items = this.helper.populateItems(userItems);
    return result;
  },

  /*** external ***/

  getPaymentOptions(paymentList, optionAllText) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText);
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper : {
    createDetails(discEnum) {
      return {
        // user input
        WarehouseID: App.Session.getDefaultWarehouseID(),
        InvoiceDate: App.In.getDateToday(),
        PONumber: "",
        ClientID: null,
        Client: "POS",
        ClientPhone: "",
        DiscType: discEnum.None.ID,
        DiscValue: null,
        DiscPercent: null,
        Comment: null,
        PaymentTypeName: null,
        PaymentValue: "",
        // by system
        ClientAlias: null,
        ClientAddress: null,
        // computed
        TotalQty: 0,
        TotalBruto: 0,
        TotalDisc: 0,
        Total: 0,
        PaymentReturn: 0
      };
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        RequestedQuantity: "0",
        // by system
        Name: null,
        SKU: "",
        PackagingName: null,
        PackagingValue: 1,
        SellPrice: 0,
        DispatchSellPrice: 0,
        SpecialPriceID: null,
        SpecialPriceName: "",
        SpecialPriceDiscPercent: 0,
        UseSpvPassword: false,
        // computed
        Price: 0,
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },
    createDiscTypeOptions(optionAllText, discTypeEnum) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
        { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
        { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    },
  
    setDetailsByClient(userData, clientData) {
      // user input
      userData.ClientID = clientData.ID;
      userData.Client = App.In.getString(clientData.Name);
      userData.ClientPhone = App.In.getString(clientData.ContactPhone);
      // defined by system
      userData.ClientAlias = clientData.Alias;
      userData.ClientAddress = App.In.getString(clientData.Address);
    },
    setDetailsByPaymentList(userData, paymentList) {
      userData.PaymentTypeName = PaymentTypeModel.getDefault(paymentList);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      userData.WarehouseID = WarehouseModel.getDefault(
        warehouseList, userData.WarehouseID
      );
    },

    clearDetailsByClient(userData) {
      userData.ClientID = null;
      userData.Client = "POS";
      userData.ClientPhone = "";
      // defined by system
      userData.ClientAlias = "";
      userData.ClientAddress = "";
    },

    setItemBySpecialPrice_existing(userItem, qty) {
      const userQty = parseInt(userItem.RequestedQuantity) + parseInt(qty);
      userItem.RequestedQuantity = App.In.getInteger(userQty);
    },
    setItemBySpecialPrice_new(userItem, stockSpecialPriceData, qty) {
      userItem.DispatchID = stockSpecialPriceData.ItemID;
      userItem.RequestedQuantity = App.In.getInteger(qty);
      // defined by system
      userItem.Name = stockSpecialPriceData.Name;
      userItem.SKU = stockSpecialPriceData.SKU;
      userItem.PackagingName = stockSpecialPriceData.PackagingName;
      userItem.SellPrice = stockSpecialPriceData.SellPriceNetto;
      userItem.DispatchSellPrice = stockSpecialPriceData.SellPrice;
      userItem.SpecialPriceID = stockSpecialPriceData.SpecialPriceID;
      userItem.SpecialPriceName = stockSpecialPriceData.SpecialPriceID === null
        ? "" : stockSpecialPriceData.SpecialPriceName;
      userItem.SpecialPriceDiscPercent =
        stockSpecialPriceData.SpecialPriceID === null
        ? 0
        : stockSpecialPriceData.DiscPercent;
    },
    setItemByEditSellPrice(userItem, sellPrice) {
      userItem.SellPrice = App.In.getInteger(sellPrice);
      userItem.UseSpvPassword = true;
    },

    getItemInfo(item) {
      let infoList = [];

      // SKU
      infoList.push(PosItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, PosItemCatalog)
      );

      // Barcode
      infoList.push(PosItemCatalog.Barcode.Label + ": " +
        App.Value.getValue("Barcode", item, PosItemCatalog)
      );

      return infoList;
    },

    updateDetails(userData, userItems, discTypeEnum) {
      userData.TotalQty = 0;
      userData.TotalBruto = 0;

      // populate total by items
      for (let userItem of userItems) {
        userData.TotalQty += App.Out.getInteger(userItem.RequestedQuantity);
        userData.TotalBruto += userItem.Price;
      }

      // TotalDisc
      if (userData.DiscType === discTypeEnum.Value.ID) {
        userData.TotalDisc = App.JS.parseInt(userData.DiscValue);
      }
      else if (userData.DiscType === discTypeEnum.Percent.ID) {
        userData.TotalDisc = App.Data.getDiscPercent_Value(
          userData.TotalBruto, userData.DiscPercent
        );
      }
      else {
        userData.TotalDisc = 0;
      }

      userData.Total = userData.TotalBruto - userData.TotalDisc;
      userData.PaymentReturn = App.JS.parseInt(userData.PaymentValue) - userData.Total;
    },
    updateDetailsByPrintThermal(data) {
      data.PaymentReturn = data.PaymentValue - data.Total;

      data.TotalItems = 0;
      for (const item of data.Items) {
        data.TotalItems += item.TotalRequestedQuantity;
      }
    },

    updateItem(userItem) {
      userItem.Price = App.Data.getTotal(
        userItem.RequestedQuantity, userItem.SellPrice
      );
    },
    updateItemsByPrintThermal(items) {
      for (const item of items) {
        item.DiscValue = (item.DispatchSellPrice - item.SellPrice)
          * item.TotalRequestedQuantity;
        item.TotalBruto = item.DispatchSellPrice * item.TotalRequestedQuantity;
      }
    },

    populateDetails(userData, discTypeEnum) {
      return {
        WarehouseID: userData.WarehouseID,
        InvoiceDate: App.Out.getDateString(userData.InvoiceDate),
        PONumber: App.Out.getString(userData.PONumber),
        ClientID: userData.ClientID,
        Client: App.Out.getString(userData.Client),
        ClientAddress: userData.ClientAddress,
        ClientPhone: App.Out.getString(userData.ClientPhone),
        DiscValue: userData.DiscType === discTypeEnum.Value.ID
          ? parseInt(userData.DiscValue) : null,
        DiscPercent: userData.DiscType === discTypeEnum.Percent.ID
          ? parseFloat(userData.DiscPercent) : null,
        PaymentTypeName: userData.PaymentTypeName,
        PaymentValue: parseInt(userData.PaymentValue),
        Comment: App.Out.getString(userData.Comment)
      };
    },
    populateItems(userItems) {
      let resultItems = [];

      for (const userItem of userItems) {
        resultItems.push({
          DispatchID: userItem.DispatchID,
          Name: App.Out.getString(userItem.Name),
          PackagingName: userItem.PackagingName,
          PackagingValue: userItem.PackagingValue,
          RequestedQuantity: App.Out.getInteger(userItem.RequestedQuantity),
          SellPrice: App.Out.getInteger(userItem.SellPrice),
          DispatchSellPrice: userItem.DispatchSellPrice,
          SpecialPriceID: userItem.SpecialPriceID,
          SpecialPriceName: userItem.SpecialPriceID === null
            ? null : userItem.SpecialPriceName,
          SpecialPriceDiscPercent: userItem.SpecialPriceID === null
            ? null : userItem.SpecialPriceDiscPercent,
          UseSpvPassword: userItem.UseSpvPassword
        });
      }

      return resultItems;
    }
  }
}