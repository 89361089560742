<template>
  <div class="block">
    <kst-output field="StatusID" :data="userData" :catalog="Catalog">
      <template #left>
        <kst-output floating-label
          :label="Catalog.StatusID.OldLabel"
          field="StatusID" :data="recPayData" :catalog="Catalog"
        />
        <kst-icon arrowRightMode class="k-control-py px-2"/>
      </template>
      <template #default>
        <kst-output addons expanded floating-label
          :label="Catalog.StatusID.NewLabel"
          field="StatusID" :data="userData" :catalog="Catalog"
        />
      </template>
    </kst-output>

    <kst-input field="VoidComment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../RcpyCatalog.js";

export default {
  props: {
    recPayData: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>