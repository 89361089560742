import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 100
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  PayNumber: {
    Label: AppCatalog.Field.Number.Label + " Pembayaran",
    PrintLabel: AppCatalog.Field.Number.Label + " Bayar",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    PrintLabel: AppModule.PaymentType.AltFullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MinValue: AppCatalog.Field.PaymentValue.MinValue,
    Required: true
  },
  StatusID: {
    Label: AppCatalog.Field.Status.Label,
    NewLabel: AppCatalog.Field.Status.Label + " " + AppCatalog.Message.New,
    OldLabel: AppCatalog.Field.Status.Label + " " + AppCatalog.Message.Old,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor
  },
  VendorName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100,
    Required: true,
    Highlight: true
  },
  VoidByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    ExportLabel: AppCatalog.Field.CreatedBy.Label + " " + App.Enum.Status.Void.Label,
    PrintLabel: AppCatalog.Field.CreatedBy.Label + " " + App.Enum.Status.Void.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: "VoidByName",
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  VoidComment: {
    Label: AppCatalog.Field.Reason.Label + " " + App.Enum.Status.Void.Label,
    Type: AppCatalog.Field.Reason.Type,
    MaxLength: 100,
    Required: true
  },
  VoidDate: {
    Label: AppCatalog.Field.CreatedDate.Label,
    ExportLabel: AppCatalog.Field.CreatedDate.Label + " " + App.Enum.Status.Void.Label,
    PrintLabel: AppCatalog.Field.CreatedDate.Label + " " + App.Enum.Status.Void.Label,
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    NullText: AppCatalog.Message.Some + " " + AppModule.Warehouse.FullName,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Pembayaran",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  ReceiveTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Receive.FullName,
    PrintLabel: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    // ReadOnly: exclude
    Output: AppCatalog.Field.Total.Output,
    MinValue: AppCatalog.Field.Total.MinValue,
    IsValue: [
      "PaymentValue",
      AppCatalog.Field.PaymentValue.Label
    ]
  },
  VendorAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  VoidBy: {
    Label: AppCatalog.Field.CreatedBy.Label + " " + App.Enum.Status.Void.Label,
    Type: AppCatalog.Field.CreatedBy.Type
  }
}