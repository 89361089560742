import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./DlvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.LogisticsNumber.Label + ", atau " +
      Catalog.VehicleIDNumber.Label + ", atau " +
      Catalog.Destination.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },

  ClientID: {
    Label: Catalog.ClientID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "Client",
    Param: AppModule.Client.Param + AppCatalog.Field.ID.Param
  },
  Client: {
    Label: Catalog.ClientID.Label,
    Type: Catalog.Client.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  DepartureDate: {
    Label: Catalog.DepartureDate.Label,
    Type: Catalog.DepartureDate.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  Status: {
    Label: Catalog.Status.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}