<template>
  <kst-table action paginated view-mode :data="items">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ModuleGroupName"
      :label="Catalog.ModuleGroupName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../CpModuleCatalog.js";
import Model from "../CpModel.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>