<template>
  <kst-page-list
    :catalog="WhsSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../WhsModel.js";
import WhsSearchCatalog from "../WhsSearchCatalog.js";
import Services from "../../../services/Api/WarehouseService.js";

import Search from "./WhsL_Search.vue";
import List from "./WhsL_List.vue";

export default {
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    WhsSearchCatalog: WhsSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      IsActive: null
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options);
      loadListData(config);
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.IsActive = this.$kst.Search.OptionAll;
    },

    getListOptions() {
      return {
        active: this.$kst.Search.getValue(this.searchData.IsActive),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>