<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details 
      :userData="userData"
    />
    <ModuleAttrRelatedGlobalInput
      :moduleAttrList="userModuleAttrList"
    />
    <ModuleList
      :userModule="userModule"
      :userModuleAttrList="userModuleAttrList"
      :moduleList="moduleList"
    />
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../AcsModel.js";
import Services from "../../../services/Api/AccessServices.js";

import Details from "./AcsE_Details.vue";
import ModuleList from "./AcsE_ModuleList.vue";
import ModuleAttrRelatedGlobalInput from "../../ModuleAttr/RelatedGlobalInput/MattReGI.vue";

export default {
  components: {
    Details,
    ModuleList,
    ModuleAttrRelatedGlobalInput
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userModule: {
      checkedRows: []
    },
    userModuleAttrList: [],
    moduleList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.moduleList = data.ModuleList;

      Model.Edit.setDetailsByData(this.userData, data.Details, data.HasUser);
      this.userModuleAttrList = Model.Edit.getModuleAttr(
        data.ModuleAttrList, this.moduleList, data.Details.ModuleAttrList
      );
      Model.Edit.setModulesByData(
        this.userModule.checkedRows, data.Details.ModuleList, this.moduleList
      );
    },

    handleSubmit(saveData) {
      const data = Model.populateData(
        this.userData, this.userModule.checkedRows, this.userModuleAttrList
      );
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userModule.checkedRows = [];
      this.userModuleAttrList = [];
      this.moduleList = [];
    }
  }
}
</script>