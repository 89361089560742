<template>
  <div class="kstmod-receive-relatedlist">
    <Action :access="access" :purchaseId="purchaseId"/>
    <Row
      :access="access"
      :detailed="detailed"
      :items="items"
    />
  </div>
</template>

<script>
import Model from "../RcvModel.js";

import Action from "./RcvRL_Action.vue";
import Row from "./RcvRL_Row.vue";

export default {
  components: {
    Action,
    Row
  },
  props: {
    detailed: Boolean,
    items: Array,
    purchaseId: Number
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      if (this.items) {
        this.updateList();
      }
    },

    updateList() {
      if (!this.detailed) {
        return;
      }

      for (const row of this.items) {
        for (const item of row.Items) {
          Model.updateItemReadOnly(item);
        }
      }
    }
  }
}
</script>