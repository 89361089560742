<template>
  <kst-section info-mode collapsed
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help="sectionHelp"
    :help-collapsed="sectionHelp"
  >
    <template v-if="rtnInvoiceList.length > 0">
      <RtnInvoiceRelatedList2 :dataList="rtnInvoiceList"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";

import RtnInvoiceRelatedList2 from "../../RtnInvoice/RelatedList2/RinvRL2.vue"

export default {
  components: {
    RtnInvoiceRelatedList2
  },
  props: {
    rtnInvoiceList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.rtnInvoiceList.length > 0;
    },
    sectionHelp() {
      return Model.Delete.getRtnInvoiceSectionHelp(this.rtnInvoiceList);
    },
    sectionTitle() {
      return Info.Section.RtnInvoice.Title;
    }
  }
}
</script>