<template>
  <kst-page-list
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :paymentOptions="paymentOptions"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :items="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../RcpyModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/RecPayServices.js";

import List from "./RcpyL_List.vue";
import Search from "./RcpyL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    access: {},
    searchData: {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: null,
      PaymentTypeName: null,
      StatusID: null,
      WarehouseID: null
    },
    searchParams: ["Search", "ClientID", "ClientName", "Date",
      "PaymentTypeName", "StatusID", "WarehouseID"],
    paymentOptions: {},
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      if (this.searchData.ClientID !== null) {
        this.searchData.ClientName = this.$kst.In.getString(
          data.Search.ClientName
        );
      }

      this.paymentOptions = Model.getPaymentOptions(data.PaymentTypeList,
        SearchCatalog.PaymentTypeName.Label
      );
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, SearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },
    handleReset() {
      this.resetSearch();
    },

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.paymentOptions = {};
      this.warehouseOptions = {};

      this.normalizeSearch();
    },
    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      this.searchData.StatusID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;
      const paymentType =
        this.$kst.Search.getValue(this.searchData.PaymentTypeName, null) === null
        ? null
        : this.searchData.PaymentTypeName;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        client: clientId,
        clientname: clientName,
        paymenttype: paymentType,
        status: this.$kst.Search.getValue(this.searchData.StatusID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.List.Search.Fields
      };
    }
  }
}
</script>