import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  // required in model
  Module: AppModule.ItemVariant,

  /*** related ***/

  /*** property ***/

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },

  setListByData(userVariantList, details) {
    this.helper.setListByData(userVariantList, details);
  },

  setList_NewRef(userVariantList, refDetails) {
    this.helper.setList_NewRef(userVariantList, refDetails);
  },
  setErrors(userVariant, field, errors) {
    userVariant.Errors = App.Vee.getRowErrors(userVariant, field, errors);
  },

  validationName(userVariant) {
    const value = App.Out.getString(userVariant.Type);
    return value === null ? {} : { required: true };
  },
  validationType(userVariant) {
    const value = App.Out.getString(userVariant.Name);
    return value === null ? {} : { required: true };
  },

  helper: {
    createDetails() {
      return {
        Type: "",
        Name: "",
        // error
        Errors: [],
        ErrorsColl: {}
      };
    },

    setListByData(userVariantList, details) {
      let newDetails;
      App.Array.truncate(userVariantList);

      // variant-set #1
      newDetails = this.createDetails();
      newDetails.Type = details.VariantType1;
      newDetails.Name = details.VariantName1;
      userVariantList.push(newDetails);

      // variant-set #2
      newDetails = this.createDetails();
      newDetails.Type = details.VariantType2;
      newDetails.Name = details.VariantName2;
      userVariantList.push(newDetails);
    },

    setList_NewRef(userVariantList, refDetails) {
      let newDetails;

      // variant-set #1
      newDetails = this.createDetails();
      newDetails.Type = refDetails.VariantType1;
      newDetails.Name = refDetails.VariantName1;
      userVariantList.push(newDetails);

      // variant-set #2
      newDetails = this.createDetails();
      newDetails.Type = refDetails.VariantType2;
      newDetails.Name = refDetails.VariantName2;
      userVariantList.push(newDetails);
    }
  }
}