<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="950"
    @init="handleModal_init"
    @load="handleModal_load"
    @reset="handleModal_reset"
    @submit="handleModal_submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :dataList="data.ItemList"
        :userData="userData"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import Model from "../ItemModel.js";

import List from "./ItemScLNewItem_List.vue";
import Search from "./ItemScLNewItem_Search.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    searchData: {
      Search: ""
    },
    purchaseItemId: null,
    getListService: null,
    putService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(purchaseItemId, search, getListService, putService) {
      this.purchaseItemId = purchaseItemId;
      this.searchData.Search = search;
      this.putService = putService;
      this.getListService = getListService;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_reset(loadList) {
      this.init_Data();
      this.handleModal_load(loadList);
    },

    handleModal_submit(selected, loadPutService) {
      const config = this.putService(this.purchaseItemId, selected.ID);
      loadPutService(config, this.handleModal_submit_success);
    },
    handleModal_submit_success() {
      this.$emit(this.$kst.Enum.Event.Submit);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchFields
      };
    },

    init_Data() {
      // no reset
    }
  }
}
</script>