<template>
  <kst-page-print pageFooter
    v-slot="{ title }"
    :catalog="StcdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List :access="access" :transGroupObj="transGroupObj"/>
  </kst-page-print>
</template>

<script>
import Model from "../StcdModel.js";
import StcdSearchCatalog from "../StcdSearchCatalog.js";
import Services from "../../../services/Api/StockCardServices.js";

import List from "./StcdP_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    StcdSearchCatalog: StcdSearchCatalog,
    Model: Model,
    access: {},
    companyData: {},
    transGroupObj: {},
    searchData: {
      Date: null,
      ItemID: null,
      WarehouseID: null
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.access = this.$kst.Access.create(Model.Access);
      this.companyData = data.CompanyDetails;
      this.transGroupObj = Model.getTransactionGroupObj(data.TransactionList);
    },

    /*** custom ***/

    resetData() {
      this.access = {};
      this.companyData = {};
      this.transGroupObj = {};
    },
    resetSearch() {
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.ItemID = null;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      // support
      this.searchData.ItemName = null;
    },
    getListOptions() {
      return {
        item: this.$kst.Out.getInteger(this.searchData.ItemID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1])
      };
    }
  }
}
</script>