import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  address: {
    Label: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 150,
  },
  email: {
    Label: AppCatalog.Field.Email.Label,
    Type: AppCatalog.Field.Email.Type,
    MaxLength: 100,
    Required: true,
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true,
  },
  name: {
    Label: AppCatalog.Field.Name.Label + " Perusahaan",
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 80,
    Required: true,
  },
  NextSubscription: {
    Label: "Langganan Berikutnya",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true,
  },
  owner: {
    Label: AppCatalog.Field.Name.Label + " Pemilik",
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100,
    Required: true
  },
  phone: {
    Label: AppCatalog.Field.Phone.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 100,
    Required: true
  },
  spv_passwd: {
    Label: AppCatalog.Field.Password.Label + " SPV",
    Type: AppCatalog.Field.Password.Type,
    MaxLength: 20,
    Required: true
  },
  Subscription: {
    Label: AppModule.Subscription.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "SubscriptionName"
  },

  // setting
  DebtDueReminderDays: {
    Label: AppCatalog.Field.DebtDueReminderDays.Label,
    Type: AppCatalog.Field.DebtDueReminderDays.Type,
    MinValue: AppCatalog.Field.DebtDueReminderDays.MinValue,
    Required: true
  },
  DebtEmailLastSent: {
    Label: AppCatalog.Field.DebtEmailLastSent.Label,
    Type: AppCatalog.Field.DebtEmailLastSent.Type,
    ReadOnly: true
  },
  DebtEmailSentTime: {
    Label: AppCatalog.Field.DebtEmailSentTime.Label,
    Type: AppCatalog.Field.DebtEmailSentTime.Type
  },
  InvoiceClientSourceID: {
    Label: AppCatalog.Field.Source.Label + " " + AppModule.Client.FullName +
      " di " + AppModule.Invoice.FullName,
    Type: AppCatalog.Field.Source.Type,
    Input: AppCatalog.Field.Source.Input,
    Required: true,
    RelatedValue: "InvoiceClientSourceName"
  },
  InvoicePrintNote: {
    Label: AppCatalog.Field.Comment.Label + " Tambahan untuk " + 
      AppModuleType.Print.FullName + " " + AppModule.Invoice.FullName,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  LowStockEmailLastSent: {
    Label: AppCatalog.Field.LowStockEmailLastSent.Label,
    Type: AppCatalog.Field.LowStockEmailLastSent.Type,
    ReadOnly: true
  },
  ProfitLossRptTypeID: {
    Label: AppCatalog.Field.Type.Label + " " + AppModule.ProfitLoss.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "ProfitLossRptTypeName"
  },
  RecDueReminderDays: {
    Label: AppCatalog.Field.RecDueReminderDays.Label,
    Type: AppCatalog.Field.RecDueReminderDays.Type,
    MinValue: AppCatalog.Field.RecDueReminderDays.MinValue,
    Required: true
  },
  ReceiveVendorSourceID: {
    Label: AppCatalog.Field.Source.Label + " " + AppModule.Vendor.FullName +
      " di " + AppModule.Receive.FullName,
    Type: AppCatalog.Field.Source.Type,
    Input: AppCatalog.Field.Source.Input,
    Required: true,
    RelatedValue: "ReceiveVendorSourceName"
  },
  RecEmailLastSent: {
    Label: AppCatalog.Field.RecEmailLastSent.Label,
    Type: AppCatalog.Field.RecEmailLastSent.Type,
    ReadOnly: true
  },
  RecEmailSentTime: {
    Label: AppCatalog.Field.RecEmailSentTime.Label,
    Type: AppCatalog.Field.RecEmailSentTime.Type
  },
  SelfWarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "SelfWarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "SelfWarehouseIsActive"
  },
}