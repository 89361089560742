<template>
  <kst-table input-mode :data="dataList">
    <b-table-column
      v-slot="{ column, row }"
      field="Type"
      :label="VariantCatalog.Type.Label"
    >
      <kst-output error-off field-only
        :field="column.field" :data="row" :catalog="VariantCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="VariantCatalog.Name.Label"
    >
      <kst-output error-off field-only
        :field="column.field" :data="row" :catalog="VariantCatalog"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import VariantCatalog from "../VariantCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    VariantCatalog: VariantCatalog
  })
}
</script>