<template>
  <div>
    <template v-if="access.RtnReceive_Act_List">
      <kst-section :title="rtnReceiveListSectionTitle">
        <template #top-right>
          <RtnReceiveRelatedListAction :receiveId="details.ID"/>
        </template>

        <RtnReceiveRelatedList :dataList="returnReceiveList"/>
      </kst-section>
    </template>
  </div>
</template>

<script>
import Info from "../RcvInfo.js";

import RtnReceiveRelatedList from "../../RtnReceive/RelatedList/RrcvRL.vue";
import RtnReceiveRelatedListAction from "../../RtnReceive/RelatedListAction/RrcvRLAction.vue";

export default {
  components: {
    RtnReceiveRelatedList,
    RtnReceiveRelatedListAction,
  },
  props: {
    access: Object,
    details: Object,
    returnReceiveList: Array
  },
  computed: {
    rtnReceiveListSectionTitle() {
      return Info.Section.RtnReceiveList.Title;
    },
  }
}
</script>