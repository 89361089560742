<template>
  <div>
    <form novalidate @submit.prevent="debounceSearch">
      <div class="columns mb-0">
        <div class="column">
          <kst-input floating-label ref="txtSearch"
            field="Search" :data="searchData" :catalog="SearchCatalog"
            @input="handleInput"
          />
        </div>
      </div>

      <div class="column is-hidden">
        <input type="submit" value="Cari">
      </div>
    </form>

    <kst-load-data loadingElement @init="handleLoad_init">
      <div
        class="list-pos-items"
        v-infinite-scroll="loadMore"
      >
        <div class="columns is-multiline">
          <div class="column is-half"
            v-for="(item, index) in resultList"
            :key="item.ItemID + '#' + item.SpecialPriceID"
          >
            <div
              class="card-pos-item kstmod-pos-new__search-card"
              v-on:click="handleResult_click(item, index)"
            >
              <div class="card-info">
                <kst-tooltip info-mode size="is-small" :position="getPosition(index)">
                  <template #content>
                    <kst-list info-mode :items="Model.getItemInfo(item)"/>
                  </template>
                  <kst-icon info-mode/>
                </kst-tooltip>
              </div>

              <template v-if="item.SpecialPriceID !== null">
                <div class="ribbon-wrapper-red">
                  <div class="ribbon-red">
                    <kst-value
                      field="SpecialPriceID" :data="item" :catalog="SearchCatalog"
                    />
                  </div>
                </div>
              </template>

              <kst-image hide-preview read-only thumbnail :src="item.ImageSrc"/>
              <h5><kst-value field="ItemID" :data="item" :catalog="SearchCatalog"/></h5>
              <div class="divider" />

              <div class="columns mb-0">
                <div class="column pr-0">
                  <div class="is-12">
                    {{ SearchCatalog.Quantity.Label }}
                  </div>
                </div>
                <div class="column pl-0">
                  <div class="has-text-weight-semibold is-12">
                    <kst-value field="Quantity" :data="item" :catalog="SearchCatalog"/>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column pr-0">
                  <div class="is-12">
                    {{ SearchCatalog.SellPrice.Label }}
                  </div>
                </div>

                <template v-if="item.SpecialPriceID !== null">
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12 has-text-danger">
                      <kst-value
                        field="SellPriceNetto" :data="item" :catalog="SearchCatalog"
                      />
                    </div>
                    <div class="has-text-grey ks-text-line-through">
                      <kst-value class="is-size-7"
                        field="SellPrice" :data="item" :catalog="SearchCatalog"
                      />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="column pl-0">
                    <div class="has-text-weight-semibold is-12">
                      <kst-value
                        field="SellPrice" :data="item" :catalog="SearchCatalog"
                      />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </kst-load-data>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import SearchMixin from "../../../mixins/SearchMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import SearchCatalog from "./SearchCatalog.js";
import Model from "../PosModel.js";
import Services from "../../../services/Api/PosServices.js";

const DEFAULT_QTY = 1;

export default {
  mixins: [SearchMixin, SnackbarMixin],
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    SearchCatalog: SearchCatalog,
    searchData: {
      Search: ""
    },
    items: [],
    // tabs
    activeTab: 0,
    // infininte scroll
    resultList: [],
    limit: AppCatalog.InfiniteScroll.PerPage,
    // func
    loadData: null
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
    this.setFocusSearch();
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  created() {
    this.init();
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;
      this.loadStockSpecialPrice();
    },

    /*** search ***/

    handleInput() {
      this.handleSearch();
    },

    /*** search - result ***/

    handleResult_click(item, index) {
      let userItemIndex = this.addItem(item);

      this.$nextTick(() => {
        this.$emit(this.$kst.Enum.Event.Input,
          this.$jquery(".kstmod-pos-new__search-card").eq(index),
          userItemIndex
        );
      });
    },

    addItem(stockSpecialPriceData) {
      let itemIdx = this.userItems.findIndex(unit =>
        unit.DispatchID === stockSpecialPriceData.ItemID &&
        unit.SpecialPriceID === stockSpecialPriceData.SpecialPriceID
      );

      if (itemIdx !== -1) {
        let item = this.userItems[itemIdx];
        Model.setItemBySpecialPrice_existing(item, DEFAULT_QTY);
        Model.updateItem(item);
      }
      else {
        let item = Model.createItem();
        Model.setItemBySpecialPrice_new(
          item, stockSpecialPriceData, DEFAULT_QTY
        );
        Model.updateItem(item);
        this.userItems.push(item);
        itemIdx = this.userItems.length - 1;
      }

      Model.updateDetails(this.userData, this.userItems);

      return itemIdx;
    },

    loadMore() {
      if (this.items.length > 0) {
        const append = this.items.slice(
          this.resultList.length, this.resultList.length + this.limit
        );
        this.resultList = this.resultList.concat(append);
      }
    },

    /*** control ***/

    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F3:
          this.setFocusSearch();
          event.preventDefault();
          break;
      }
    },

    setFocusSearch() {
      this.activeTab = 1;
      this.$refs.txtSearch.focus();
    },

    /*** custom ***/

    init() {
      this.$emit(this.$kst.Enum.Event.Init, this.reload);
    },

    reload() {
      this.loadStockSpecialPrice();
    },

    getListOptions() {
      return {
        warehouse: this.userData.WarehouseID,
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchFields
      };
    },

    loadStockSpecialPrice() {
      const options = this.getListOptions();
      const config = Services.getRelatedStockSpecialPriceList(options);
      this.loadData(config, this.loadStockSpecialPrice_success, true);
    },
    loadStockSpecialPrice_success(data) {
      this.items = data.StockSpecialPriceList;

      this.resultList = [];
      this.loadMore();
    },

    submit() {
      this.loadStockSpecialPrice();
    },

    getPosition(index) {
      return (index % 2 === 0) ? "is-right" : "is-bottom";
    }
  }
}
</script>