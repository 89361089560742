<template>
  <kst-page-list
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>
    
    <template #search-result="{ data }">
      <List
        :access="access"
        :items="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../PaytModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/PaymentTypeServices.js";

import List from "./PaytL_List.vue";
import Search from "./PaytL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    SearchCatalog: SearchCatalog,
     searchData: {
      Search: "",
    },
    searchParams: ["Search"],
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config);
    },
    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },
    handleReset() {
      this.resetSearch();
    },
    
    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
    },
    resetSearch() {
      this.searchData.Search = "";
    },

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields,
      };
    },
  }
}
</script>