import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ReductionNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.StockReduction.FullName,
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  TransactionID: {
    Label: AppCatalog.Field.Reference.Label,
    ExportLabel: AppCatalog.Field.Number.Label + " " +
      AppCatalog.Field.Reference.Label,
    AltLabel: AppModule.Invoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    RelatedValue: "TransactionNumber",
    RelatedModule: AppModule.Invoice
  },
  TransactionDate: {
    Label: AppCatalog.Field.Date.Label,
    ExportLabel: AppCatalog.Field.Date.Label + " " +
      AppCatalog.Field.Reference.Label,
    PrintLabel: AppCatalog.Field.Date.Label + " " +
      AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  TransactionNumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // validation
  StockMissingQty: {
    Label: AppCatalog.Field.QtyStock.Label,
    Type: AppCatalog.Field.QtyStock.Type,
    MaxValue: 0
  },

  // support
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppModule.StockReduction.FullName,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}