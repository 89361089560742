<template>
  <kst-print-table :data="invoiceList">
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="RcpyInvCatalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="InvoiceID"
      :label="RcpyInvCatalog.InvoiceID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="ClientID"
      :label="RcpyInvCatalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="RcpyInvCatalog.WarehouseID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="PaymentValue"
      :label="RcpyInvCatalog.PaymentValue.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RcpyInvCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import RcpyInvCatalog from "../RcpyInvCatalog.js";

export default {
  props: {
    invoiceList: Array
  },
  data: () => ({
    RcpyInvCatalog: RcpyInvCatalog
  })
}
</script>