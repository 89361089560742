<template>
  <kst-section search-mode class="pb-0">
    <form novalidate @submit.prevent="debounceSearch">
      <div class="columns my-0 is-multiline">
        <div class="column is-narrow">
          <kst-input floating-label
            field="WarehouseID" :data="userData" :catalog="Catalog"
            :options="warehouseOptions" @input="handleInput"
          />
        </div>

        <div class="column is-narrow is-hidden">
          <input type="submit" value="Cari">
        </div>
      </div>
    </form>
  </kst-section>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SearchMixin from "../../../mixins/SearchMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Catalog from "../StclCatalog.js";
import Services from "../../../services/Api/StockClosingServices.js";

export default {
  mixins: [AxiosMixin, LoadingMixin, SearchMixin, SnackbarMixin],
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // support debounce
    loadCounter: 0
  }),
  created() {
    this.initSearch(this.userData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.loadList();
    },

    loadList() {
      this.loadCounter++;
      this.detailsLoading = this.openLoading();

      const config = Services.getRelatedStockList(this.userData.WarehouseID);
      this.runAxios(config, this.loadCounter,
        this.loadList_Success,
        this.loadList_Fault,
        this.loadList_Fault
      );
    },
    loadList_End() {
      this.closeLoading(this.detailsLoading);
      this.detailsLoading = null;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.$emit(this.$kst.Enum.Event.Submit, data.StockList);
      this.loadList_End();
    },
    loadList_Fault(message) {
      this.openSnackbar({
        message: message,
        errorType: true
      });
      this.loadList_End();
    }
  }
}
</script>