import AppCatalog from "./AppCatalog.js";
import AppEnum from "./App_Enum.js";
import AppValue from "./App_Value.js";
import Catalog from "./App_Data_Catalog.js";

/**
 * Convert:
 * - from: value (source: user input)
 * - to: value (computed property)
 */
export default {
  /**
   * Add number of days in datetime
   * @param {Date} value - datetime
   * @param {Number} days 
   */
  addDaysToDate(value, days) {
    let newValue = value === undefined || value === null
      ? new Date()
      : new Date(value);
    newValue.setDate(newValue.getDate() + parseInt(days));
    return newValue;
  },
  getActivityTypeName(activityTypeId) {
    switch (activityTypeId) {
      // order by ActivityType.ID
      case AppEnum.ActivityType.Receive.ID:
        return AppEnum.ActivityType.Receive.Module.FullName;
      case AppEnum.ActivityType.Invoice.ID:
        return AppEnum.ActivityType.Invoice.Module.FullName;
      case AppEnum.ActivityType.StockTrf.ID:
        return AppEnum.ActivityType.StockTrf.Module.FullName;
      case AppEnum.ActivityType.StockOpname.ID:
        return AppEnum.ActivityType.StockOpname.Module.FullName;
      case AppEnum.ActivityType.RtnInvoice.ID:
        return AppEnum.ActivityType.RtnInvoice.Module.FullName;
      case AppEnum.ActivityType.HppAdj.ID:
        return AppEnum.ActivityType.HppAdj.Module.FullName;
      case AppEnum.ActivityType.StockReduction.ID:
        return AppEnum.ActivityType.StockReduction.Module.FullName;
      case AppEnum.ActivityType.StockRepair.ID:
        return AppEnum.ActivityType.StockRepair.Module.FullName;
      case AppEnum.ActivityType.InitialData.ID:
        return AppEnum.ActivityType.InitialData.Module.FullName;
      case AppEnum.ActivityType.StockConv.ID:
        return AppEnum.ActivityType.StockConv.Module.FullName;
      case AppEnum.ActivityType.RtnReceive.ID:
        return AppEnum.ActivityType.RtnReceive.Module.FullName;
      default: 
        return "";
    }
  },
  /**
   * Get difference between 2 dates, in days
   * @param {*} value1 
   * @param {*} value2 
   * @param {Boolean} isAllDayCounted
   * - true: Jan 1, 2000 - Jan 5, 2000 returns 5
   * - false: Jan 1, 2000 - Jan 5, 2000 returns 4
   * @returns {Number} total
   */
  getDateDiffInDays(value1, value2, isAllDayCounted) {
    const date1 = new Date(value1);
    const date2 = new Date(value2);
    const diffTime = Math.abs(date2 - date1); // in milisecond
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return isAllDayCounted ? diffDays + 1 : diffDays;
  },
  getDiscPercent_Value(value, percent) {
    return parseInt(value * percent / AppCatalog.PercentDec100);
  },
  getFixedDecimal(value, decimalNumber) {
    if (value === undefined || value === null) {
      return null;
    }
  
    let newDecimalNumber = decimalNumber
      ? decimalNumber
      : AppCatalog.DecimalNumber;
  
    return parseFloat(Number(value).toFixed(newDecimalNumber));
  },
  getHppTotal(qty, hppPerPcs) {
    let hppTotal = parseInt(qty) * parseFloat(hppPerPcs);
    return (isNaN(hppTotal) ? 0 : hppTotal);
  },
  // when exceed stock qty
  getInvalidStockError(userQty, stockQty, itemName, label) {
    const stockLabel = label ? label : AppCatalog.Field.Stock.Label;
  
    const data = {
      Qty: userQty,
      Stock: stockQty
    };

    return stockLabel + " " + itemName +
      " tidak mencukupi (dibutuhkan " +
      AppValue.getValue("Qty", data, Catalog) +
      ", tetapi hanya tersedia " +
      AppValue.getValue("Stock", data, Catalog) + ").";
  },
  getPackaging(packagingName, packagingValue) {
    return packagingName + " (" + packagingValue + ")";
  },
  getQtyByPackaging(qty, packagingValue) {
    // i.e. stock 50pcs in dozen (12) = 4 dozen (12)
    let value = parseInt(qty) / packagingValue;
    return parseInt(value);
  },
  getStatusClass(value, statusEnum) {
    for (const status in statusEnum) {
      if (statusEnum[status].ID === value) {
        return statusEnum[status].Class;
      }
    }
    return null;
  },
  getStatusLabel(value, statusEnum) {
    for (const status in statusEnum) {
      if (statusEnum[status].ID === value) {
        return statusEnum[status].Label;
      }
    }
    return null;
  },
  getTotal(qty, price) {
    let total = parseInt(qty) * parseInt(price);
    return (isNaN(total) ? 0 : total);
  },
  getTotalQty(qty, packagingValue) {
    let totalQty = parseInt(qty) * packagingValue;
    return (isNaN(totalQty) ? 0 : totalQty);
  },
  getTotalWeight(qty, weight) {
    let totalWeight = parseInt(qty) * weight;
    return (isNaN(totalWeight) ? 0 : totalWeight);
  },
  getValueProrate(value, totalSingle, totalAll) {
    return parseInt(totalSingle * value / totalAll);
  },
  hasProp(value, propName) {
    return Object.prototype.hasOwnProperty.call(value, propName);
  },
  isModuleType_hideActionInMobile(moduleType) {
    return moduleType.Name.includes("Print") ||
      moduleType.Name.includes("Export");
  },
  isTrue(value) {
    if (value === true || value === 1 || value === "1") {
      return true;
    }
    return false;
  },
  isUnlimitedUser(value) {
    return (value === null ? true : false);
  },
  normalizeArray(value) {
    return (value === undefined || value === null) ? [] : value;
  },
  normalizeObject(value) {
    return (value === undefined || value === null) ? {} : value;
  }
}