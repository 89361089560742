import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, expenseData) {
    userData.ID = expenseData.ID;
    // user input
    userData.ExpenseNumber = App.In.getString(expenseData.ExpenseNumber);
    userData.IsAutoNumber = false;
    userData.Date = App.In.getDate(expenseData.Date);
    userData.SubCategoryID = expenseData.SubCategoryID;
    userData.WarehouseID = expenseData.WarehouseID;
    userData.PaymentTypeName = expenseData.PaymentTypeName;
    userData.Total = App.In.getInteger(expenseData.Total);
    userData.Comment = App.In.getString(expenseData.Comment);
  }
}