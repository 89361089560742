import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import ItemDL from "./Delete/ItemDL.vue";
import ItemD from "./Details/ItemD.vue";
import ItemE from "./Edit/ItemE.vue";
import ItemExD from "./ExportDetails/ItemExD.vue";
import ItemExL from "./ExportList/ItemExL.vue";
import ItemN from "./New/ItemN.vue";
import ItemL from "./List/ItemL.vue";
import ItemPL from "./PrintList/ItemPL.vue";
import ItemPB from "./PrintBarcode/ItemPB.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Item, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Item, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Item, AppModuleType.ExportList),
    New: RouterModel.getPath(AppModule.Item, AppModuleType.New),
    List: RouterModel.getPath(AppModule.Item, AppModuleType.List),
    Select: RouterModel.getPath(AppModule.Item, AppModuleType.Select),
    PrintBarcode: RouterModel.getPath(AppModule.Item, AppModuleType.PrintBarcode),
    PrintList: RouterModel.getPath(AppModule.Item, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Delete),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Delete),
        component: ItemDL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Delete),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_Delete,
          moduletype: AppModuleType.Delete,
          cancel: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.List)
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Details),
        component: ItemD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Item, AppModuleType.Edit),
        component: ItemE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Item, AppModuleType.ExportDetails),
        component: ItemExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Item, AppModuleType.ExportList),
        component: ItemExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.New),
        name: RouterModel.getName(AppModule.Item, AppModuleType.New),
        component: ItemN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Item, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.List),
        name: RouterModel.getName(AppModule.Item, AppModuleType.List),
        component: ItemL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Item),
            moduleattr: AppModuleAttr.Item_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Item),
            moduleattr: AppModuleAttr.Item_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Item),
            moduleattr: AppModuleAttr.Item_Act_PrintList,
            moduletype: AppModuleType.PrintList
          },
          related: {
            module: RouterModel.getModule(AppModule.Stock),
            moduleattr: AppModuleAttr.Item_Stock_Act_List,
            moduletype: AppModuleType.List
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Item, AppModuleType.PrintList),
        component: ItemPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Item, AppModuleType.PrintBarcode),
        name: RouterModel.getName(AppModule.Item, AppModuleType.PrintBarcode),
        component: ItemPB,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Item, AppModuleType.PrintBarcode),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Act_PrintBarcode,
          moduletype: AppModuleType.PrintBarcode
        }
      }
    ];
  }
}