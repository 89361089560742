<template>
  <kst-section items-mode>
    <StockSearchListPcs sellPrice weight
      @init="handleStock_init"
      @submit="handleStock_submit"
    />

    <Summary
      :userItems="userItems"
      @init="handleSummary_init"
    />

    <ExportTemplate
      :userData="userData"
      @init="handleExportTemplate_init"
    />

    <ItemImport
      :userItems="userItems"
      @init="handleImport_init"
      @submit="handleImport_submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary mr-2"
          :disabled="isDisabledSummary"
          :label="Info.Button.Summary.Title"
          :moduleType="Info.Button.Summary.ModuleType"
          @click="handleSummary_click"
        />

        <kst-button action-mode
          class="is-warning mr-2"
          :label="Info.Button.ExportTemplate.Title"
          :moduleType="Model.StockOpnameItem.ModuleType.ExportTemplate"
          @click="handleExport_template"
        />

        <kst-button action-mode
          class="is-primary mr-2"
          :label="Info.Button.ItemImport.Title"
          :moduleType="Model.StockOpnameItem.ModuleType.ItemImport"
          @click="handleImport_item"
        />

        <kst-button action-mode
          class="is-primary"
          :label="Model.StockOpnameItem.Module.FullName"
          :moduleType="Model.StockOpnameItem.ModuleType.New"
          @click="handleAdd_item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            :label="Model.StockOpnameItem.Module.FullName"
            :moduleType="Model.StockOpnameItem.ModuleType.New"
            @click="handleAdd_item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List :userItems="userItems"/>
    </template>
  </kst-section>
</template>

<script>
import Info from "../StopInfo.js";
import Model from "../StopModel.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import Summary from "./StopN_Items_Summary.vue";
import ExportTemplate from "../ExportTemplate/StopExT.vue";
import ItemImport from "../ItemImport/StopIIm.vue";
import List from "./StopN_Items_List.vue";
import StockSearchListPcs from "../../Stock/SearchListPcs/StkScLPcs.vue";

export default {
  components: {
    ExportTemplate,
    ItemImport,
    List,
    StockSearchListPcs,
    Summary
  },
  props: {
    userData: Object,
    userItems: Array
  },
  computed: {
    isDisabledSummary() {
      return this.userItems.length < 1;
    }
  },
  data: () => ({
    Info: Info,
    Model: Model,
    // func
    showExportTemplateFunc: null,
    showItemImportFunc: null,
    showStockFunc: null,
    showSummaryFunc: null
  }),
  methods: {
    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_submit(stockData, itemData) {
      let item = Model.createItem();
      Model.New.setItemByStock(item, stockData, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);
    },

    /*** by summary modal ***/

    handleSummary_init(show) {
      this.showSummaryFunc = show;
    },

    /*** by item import modal ***/

    handleImport_init(show) {
      this.showItemImportFunc = show;
    },

    handleImport_submit(itemValidList) {
      this.userItems.push(...itemValidList);
    },

    /*** by export template ***/

    handleExportTemplate_init(show) {
      this.showExportTemplateFunc = show;
    },

    /*** custom ***/

    handleSummary_click() {
      this.showSummaryFunc(this.userData.WarehouseID);
    },

    handleImport_item() {
      this.showItemImportFunc(this.userData.WarehouseID);
    },

    handleExport_template() {
      this.showExportTemplateFunc();
    },

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    }
  }
}
</script>