import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Item,
  ModuleType: {
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  /*** property ***/

  Search: {
    Fields: ["Name", "SKU"]
  },

  /*** method ***/

  helper: {}
}