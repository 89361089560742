import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Address: {
    Label: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200,
    Required: true,
    Highlight: true
  },
  ContactEmail: {
    Label: AppCatalog.Field.Email.Label,
    ExportLabel: AppCatalog.Field.Email.Label +" "+ AppCatalog.Field.PIC.Label,
    Type: AppCatalog.Field.Email.Type,
    MaxLength: 50,
    Highlight: true
  },
  ContactMobile: {
    Label: AppCatalog.Field.MobilePhone.Label,
    ExportLabel: AppCatalog.Field.MobilePhone.Label +" "+ AppCatalog.Field.PIC.Label, 
    Type: AppCatalog.Field.MobilePhone.Type,
    MaxLength: 50,
    Highlight: true
  },
  ContactName: {
    Label: AppCatalog.Field.PIC.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100
  },
  ContactPhone: {
    Label: AppCatalog.Field.Phone.Label,
    ExportLabel: AppCatalog.Field.Phone.Label +" "+ AppCatalog.Field.PIC.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 50,
    Highlight: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label + " " + AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 100,
    Required: true
  },
  
  // Support
  AddressName: {
    Label: AppCatalog.Field.Name.Label + " " + AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  }
}