<template>
  <kst-section items-mode>
    <StockSearchListPcs newItem
      @init="handleStock_Init"
      @newitem="handleStock_NewItem"
      @submit="handleStock_Submit"
    />
    <PriceSearch 
      @init="handlePrice_Init" 
      @submit="handlePrice_Submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          :label="Model.PurchaseItem.Module.FullName"
          :moduleType="Model.PurchaseItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          :label="Model.PurchaseItem.Module.FullName"
          :moduleType="Model.PurchaseItem.ModuleType.New"
          @click="handleAdd_Item"
        />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List
        :access="access"
        :userData="userData"
        :userItems="userItems"
        @callback="handleList_Callback"
      />
    </template>
  </kst-section>
</template>

<script>
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import List from "./PurN_Items_List.vue";
import PriceSearch from "../../Receive/SearchListPrice/RcvScLPrice.vue";
import StockSearchListPcs from "../../Stock/SearchListPcs/StkScLPcs.vue";

export default {
  components: {
    List,
    PriceSearch,
    StockSearchListPcs
  },
  props: {
    access: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    rowIndex: null,
    // func
    showPriceFunc: null,
    showStockFunc: null,
  }),
  methods: {
    /*** by price modal ***/

    handlePrice_Init(show) {
      this.showPriceFunc = show;
    },
    handlePrice_Submit(row) {
      let userItem = this.userItems[this.rowIndex];
      Model.setItemByPrice(userItem, row);
      Model.updateItem(userItem);
    },

    /*** by stock modal ***/

    handleStock_Init(show) {
      this.showStockFunc = show;
    },
    handleStock_NewItem(itemName){
      let newItem = Model.createItem();
      Model.setItemByNewItem(newItem, itemName);
      Model.updateItem(newItem);
      this.userItems.push(newItem);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleStock_Submit(stockData, itemData) {
      let item = Model.createItem();
      Model.setItemByStock(item, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_Item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    },

    handleList_Callback(index) {
      this.rowIndex = index;
      const userItem = this.userItems[index];
      const buyPrice = this.$kst.Out.getInteger(userItem.BuyPrice) /
        userItem.PackagingValue;

      this.showPriceFunc(
        userItem.DispatchID,
        this.userData.VendorID,
        this.userData.Vendor,
        buyPrice,
        userItem.PackagingOptions.rows,
        Services.getRelatedReceivePriceList
      );
    }
  }
}
</script>