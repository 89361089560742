import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import ModelBody from "./PaytModel_Body.js";
import ModelEdit from "./PaytModel_Edit.js";

export default {
  // required in model
  Module: AppModule.PaymentType,
  ModuleType: {
    Edit: AppModuleType.Edit,
    List: AppModuleType.List
  },
  
  
  /*** related ***/
  
  /*** property ***/
  
  PaymentTypeID: ModelBody.PaymentTypeID,
  Search: ModelBody.Search,
  
  /*** method ***/

  createOptions(dataList, optionAllText, optionNonText, params) {
    return ModelBody.createOptions(dataList, optionAllText, optionNonText,
      params, ModelBody.PaymentTypeID);
  },

  getDefault(items, name) {
    return ModelBody.getDefault(items, name);
  },

  populateData(data) {
    let resultList = ModelBody.populateList(data);
    return { 
      List: resultList
    };
  },

  /*** method - Edit ***/

  Edit: {
    setListByData(checkedRows, detailsPaymentTypeList, paymentTypeList) {
      ModelEdit.setListByData(checkedRows, detailsPaymentTypeList, 
        paymentTypeList
      );
    },
  },

  /*** external ***/
}