<template>
  <div>
    <VendorSearchList
      @init="handleVendor_Init"
      @submit="handleVendor_Submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-4">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="RrcvSearchCatalog"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-output addons floating-label 
          field="VendorID" :data="searchData" :catalog="RrcvSearchCatalog"
        >
          <template #default>
            <kst-input floating-label load-on-focus
              field="VendorName"
              :data="searchData"
              :disabled="vendorDisabled"
              :catalog="RrcvSearchCatalog"
              @input="handleInput"
            />
          </template>
          <template #right>
            <div class="control">
              <template v-if="searchData.VendorID === null">
                <kst-tooltip search-mode hover-only :content="RrcvSearchCatalog.VendorID.Label">
                  <kst-button search-mode @click="handleSearch_Vendor"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="RrcvSearchCatalog.VendorID.Label">
                  <kst-button clear-mode @click="handleClear_Vendor"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="RrcvSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="RrcvSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RrcvSearchCatalog from "../RrcvSearchCatalog.js";
import Services from "../../../services/Api/ReturnReceiveServices.js";

import VendorSearchList from "../../Vendor/SearchList/VenScL.vue";

export default {
  components:{
    VendorSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    RrcvSearchCatalog: RrcvSearchCatalog,
    // func
    showVendorFunc: null
  }),
  computed: {
    vendorDisabled() {
      return this.searchData.VendorID !== null;
    }
  },
  methods: {
    /*** by vendor modal ***/

    handleVendor_Init(show) {
      this.showVendorFunc = show;
    },
    handleVendor_Submit(data) {
      this.searchData.VendorID = data.ID;
      this.searchData.VendorName = data.Name;
      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_Vendor() {
      this.showVendorFunc(Services.getRelatedVendorList);
    },
    handleClear_Vendor() {
      this.searchData.VendorID = null;
      this.searchData.VendorName = "";

      this.handleInput();
    }
  }
}
</script>