<template>
  <div class="field is-relative is-horizontal kst-output" :class="theFieldClass">
    <template v-if="floatingLabel">
      <label class="field-label label has-text-left ks-cursor-pointer">
        {{ theLabel }}
        <template v-if="!printMode">
          <template v-if="catalog[field].Required && !catalog[field].RequiredHide">
            <b-tag
              style="line-height: normal !important; height: auto !important;"
              :type="AppCatalog.Input.Required.Type"
            >
              {{ AppCatalog.Input.Required.Text }}
            </b-tag>
          </template>
        </template>
      </label>
    </template>
    <template v-else>
      <div v-if="!fieldOnly" class="field-label is-normal">
        <label class="label has-text-left ks-cursor-pointer">
          {{ theLabel }}
          <template v-if="!printMode">
            <b-tag
              v-if="catalog[field].Required && !catalog[field].RequiredHide"
              :type="AppCatalog.Input.Required.Type"
            >
              {{ AppCatalog.Input.Required.Text }}
            </b-tag>
          </template>

          <slot name="tooltips"/>
        </label>

        <template v-if="helpIsVisible">
          <p class="help has-text-left">
            {{ getHelp() }}
          </p>
        </template>
      </div>
    </template>

    <div class="field-body">
      <div class="ks-is-fullwidth">
        <b-field>
          <slot name="left" />

          <slot name="status">
            <template v-if="!boxOff">
              <template v-if="!theUseNullText">
                <template v-if="!printMode">
                  <template v-if="!statusOff">
                    <template v-if="catalog[field].RelatedIsActive">
                      <div class="control">
                        <div class="field input px-1 ks-output" readonly>
                          <kst-tooltip :content="theStatusContent" :type="theStatusType">
                            <kst-icon status-mode :value="data[catalog[field].RelatedIsActive]"/>
                          </kst-tooltip>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </slot>

          <slot name="default">
            <div class="control is-expanded">
              <template v-if="boxOff">
                <template v-if="theType === OutputTypeEnum.TextArea">
                  <div class="ks-text-area">{{ theValueOutput }}</div>
                </template>
                <template v-else>
                  <div class="is-hidden-mobile">
                    <div class="k-control-py" :class="theClass">{{ theValueOutput }}</div>
                  </div>

                  <div class="is-hidden-tablet" :class="theClass">{{ theValueOutput }}</div>
                </template>
              </template>
              <template v-else-if="printMode">
                <div :class="theClass">{{ theValueOutput }}</div>
              </template>
              <template v-else>
                <template v-if="theType === OutputTypeEnum.TextArea">
                  <textarea readonly
                    class="textarea ks-output"
                    :rows="AppCatalog.Output.TextArea.Rows"
                    :value="theValueOutput"
                  />
                </template>
                <template v-else>
                  <input type="text" class="input ks-output" :value="theValueOutput" readonly/>
                </template>
              </template>
            </div>
          </slot>

          <slot name="right"/>

          <slot name="control">
            <template v-if="!controlOff">
              <template v-if="!boxOff">
                <template v-if="!theUseNullText">
                  <template v-if="!printMode">
                    <template v-if="theType === OutputTypeEnum.Email">
                      <div class="control">
                        <kst-tooltip mail-mode hover-only :content="theValueOutput">
                          <kst-button mail-mode :disabled="!theValueOutput" :href="theValueOutput"/>
                        </kst-tooltip>
                      </div>
                    </template>
                    <template v-else-if="theType === OutputTypeEnum.ID">
                      <template v-if="catalog[field].RelatedModule">
                        <div class="control">
                          <kst-tooltip related-mode hover-only :module="catalog[field].RelatedModule">
                            <kst-button action-mode
                              label=""
                              :class="AppCatalog.Output.Related.Class"
                              :disabled="!data[field]"
                              :module="catalog[field].RelatedModule"
                              :moduleType="AppModuleType.Details"
                              :urlParams="theUrlParams"
                            />
                          </kst-tooltip>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="theType === OutputTypeEnum.Percent">
                      <div class="control">
                        <div class="input ks-output" readonly>
                          {{ AppCatalog.PercentSign }}
                        </div>
                      </div>
                    </template>
                    <template v-if="theType === OutputTypeEnum.Phone">
                      <div class="control">
                        <kst-tooltip phone-mode hover-only :content="theValueOutput">
                          <kst-button phone-mode :disabled="!theValueOutput" :href="theValueOutput"/>
                        </kst-tooltip>
                      </div>
                    </template>
                    <template v-else-if="theType === OutputTypeEnum.Url">
                      <div class="control">
                        <kst-tooltip url-mode hover-only>
                          <kst-button url-mode :disabled="!theValueOutput" :href="theValueOutput"/>
                        </kst-tooltip>
                      </div>
                    </template>
                  </template>
                </template>
              </template>
            </template>
          </slot>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import ValueMixin from "@/mixins/ValueMixin.js";
import AppCatalog from "@/services/App/AppCatalog.js";
import AppModuleType from "@/services/App/AppModuleType.js";
import OutputTypeEnum from "@/services/Input/OutputTypeEnum.js";

export default {
  mixins: [ValueMixin],
  props: {
    label: String,
    addons: Boolean,
    boxOff: Boolean,
    controlOff: Boolean,
    expanded: Boolean,
    fieldOnly: Boolean,
    floatingLabel: Boolean,
    statusOff: Boolean,
    helpOff: Boolean,
    // mode: ONLY select 1 (one)
    printMode: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog,
    OutputTypeEnum: OutputTypeEnum,
    AppModuleType: AppModuleType
  }),
  computed: {
    helpIsVisible() {
      return this.getHelpIsVisible();
    },

    theFieldClass() {
      return this.getFieldClass();
    },
    theLabel() {
      return this.getLabel();
    },
    theUrlParams() {
      return this.getUrlParams();
    },
    theStatusContent() {
      return this.getStatusContent();
    },
    theStatusType() {
      return this.getStatusType();
    }
  },
  methods: {
    getHelp() {
      return this.$kst.Convert.arrayToString(
        this.catalog[this.field].Help, this.$kst.Enum.Breakline.Text
      );
    },

    getHelpIsVisible() {
      if (this.printMode) {
        return false;
      }

      if (this.helpOff === true) {
        return false;
      }

      if (this.catalog[this.field].Help) {
        return true;
      }
      return false;
    },

    getFieldClass() {
      let fieldClass = "";

      if (this.addons) {
        fieldClass += "control ";
      }
      if (this.expanded) {
        fieldClass += "is-expanded ";
      }
      if (this.floatingLabel) {
        fieldClass += "is-floating-label ";
      }

      return fieldClass;
    },
    getLabel() {
      if (this.printMode) {
        if (this.catalog[this.field].PrintLabel) {
          return this.catalog[this.field].PrintLabel;
        }
      }

      if (this.label !== undefined) {
        return this.label;
      }

      return this.catalog[this.field].Label;
    },
    getUrlParams() {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: this.data[this.field]
      });
    },
    getStatusContent() {
      const relatedField = this.catalog[this.field].RelatedIsActive;
      return this.catalog[this.field].Label + " " +
        this.$kst.Format.getActiveInactive(this.data[relatedField]);
    },
    getStatusType() {
      const relatedField = this.catalog[this.field].RelatedIsActive;
      if (this.$kst.Data.isTrue(this.data[relatedField])) {
        return this.$kst.Buefy.Type.Success;
      }
      return this.$kst.Buefy.Type.Danger;
    }
  }
}
</script>