import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label + " [F1]",
    Type: AppCatalog.Field.Barcode.Type,
    MaxLength: AppCatalog.Field.Barcode.MaxLength
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label + " [F2]",
    ErrorLabel: AppCatalog.Field.Qty.Label,
    Type: InputType.Qty
  },

  // support
  ItemName: {
    Label: AppModule.Item.FullName + " terpilih",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  }
}