<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StrdItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="StrdItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StrdItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="StrdItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="StrdItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
    </b-table-column>

    <template v-if="data.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-specialpricename"
        field="SpecialPriceID"
      >
        <template v-if="row.SpecialPriceID !== null">
          <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
        </template>
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StrdItemCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StrdItemCatalog from "../StrdItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    StrdItemCatalog: StrdItemCatalog
  })
}
</script>