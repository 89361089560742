<template>
  <kst-section search-mode class="pb-0" :title="Info.Section.SpecialPrice.Title">
    <div class="columns my-0 is-mobile">
      <div class="column">
        <kst-input floating-label
          field="SpecialPriceID" :data="supportData" :catalog="StclItemCatalog"
          :options="specialPriceOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-tooltip info-mode position="is-left">
          <template #content>
            {{ Info.Message.ApplySpecialPrice }}
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </div>
    </div>
  </kst-section>
</template>

<script>
import Info from "../StclInfo.js";
import StclItemCatalog from "../StclItemCatalog.js";

export default {
  props: {
    supportData: Object,
    specialPriceOptions: Object
  },
  data: () => ({
    Info: Info,
    StclItemCatalog: StclItemCatalog,
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>