<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-4">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="WhsSearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="IsActive" :data="searchData" :catalog="WhsSearchCatalog"
        :options="statusOptions" @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import Model from "../WhsModel.js";
import WhsSearchCatalog from "../WhsSearchCatalog.js";

export default {
  props: {
    searchData: Object
  },
  data: () => ({
    WhsSearchCatalog: WhsSearchCatalog
  }),
  computed:{
    statusOptions() {
      return Model.createStatusOptions(WhsSearchCatalog.IsActive.Label);
    }
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>