<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section class="is-half" :title="InvProfitCatalog.Profit.Label">
        <template #top-right>
          <kst-title>{{ profit }}</kst-title>
        </template>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvProfitCatalog.TotalBruto.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalBruto" :data="details" :catalog="InvProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvProfitCatalog.TotalDisc.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalDisc" :data="details" :catalog="InvProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvProfitCatalog.TotalReturn.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="TotalReturn" :data="details" :catalog="InvProfitCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ InvProfitCatalog.HppTotalNetto.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="HppTotalNetto" :data="details" :catalog="InvProfitCatalog"/>
            </div>
          </div>
        </div>
      </kst-section>
    </div>
    <div class="column is-half is-hidden-mobile"/>
  </div>
</template>

<script>
import InvProfitCatalog from "../InvProfitCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    InvProfitCatalog: InvProfitCatalog
  }),
  computed: {
    profit() {
      return this.$kst.Value.getValue("Profit", this.details, InvProfitCatalog);
    }
  }
}
</script>