<template>
  <div class="kst-barcode">
    <barcode 
      :value="value"
      :height="theHeight"
      :width="theWidth"
    />
    <slot name="default">
      <div class="has-text-centered is-uppercase is-size-6">
        <strong>{{ label }}</strong>
      </div>
    </slot>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    value: String,
    height: Number,
    width: Number,
    label: String
  },
  computed: {
    theHeight() {
      if (this.height) {
        return this.height;
      }

      return AppCatalog.Barcode.Height;
    },
    theWidth() {
      if (this.width) {
        return this.width;
      }
      return AppCatalog.Barcode.Width;
    }
  }
}
</script>