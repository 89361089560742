export default {
  Title: {
    CompanyRegistration: "Daftar Perusahaan",
    UserRegistration: "Daftar User"
  },
  Message: {
    Password: {
      Empty: "Password tidak boleh kosong",
      InvalidMinimum: "Panjang password minimal harus 8 karakter",
      InvalidCharacter: "Password harus terdiri dari huruf, angka, dan symbol"
    },
    InvalidState: "Password anda sudah diatur, halaman ini tidak bisa digunakan kembali",
    RegistrationCompleted: "Proses registrasi anda sudah berhasil",
    VerifyEmailError: "Email anda belum diverifikasi, silakan verifikasi terlebih dahulu untuk menyelesaikan registrasi",
    VerifyEmailInfo: "Untuk melanjutkan registrasi, silakan cek email anda untuk verifikasi"
  }
}