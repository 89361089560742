<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="details"
      dateField="InvoiceDate"
      numberField="SONumber"
    />

    <template v-if="details.TransactionID !== null">
      <kst-output field="TransactionID" :data="details" :catalog="Catalog">
        <template #left>
          <kst-output addons controlOff floating-label
            :label="Catalog.TransactionID.AltLabel"
            field="TransactionID" :data="details" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="TransactionDate" :data="details" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>

    <template v-if="details.ClientID">
      <kst-output field="ClientID" :data="details" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="details" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="Client" :data="details" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="details" :catalog="Catalog">
        <kst-output addons expanded floating-label
          field="Client" :data="details" :catalog="Catalog"
        />
      </kst-output>
    </template>

    <kst-output field="ClientAddress" :data="details" :catalog="Catalog"/>
    <kst-output field="PONumber" :data="details" :catalog="Catalog"/>
    <kst-output field="Comment" :data="details" :catalog="Catalog"/>
    <kst-output field="Status" :data="details" :catalog="Catalog">
      <template #left v-if="details.IsPos">
        <div class="k-control-py ks-col-status mr-4">
          <kst-status :value="statusValue" :statusEnum="statusEnum"/>
        </div>
      </template>
    </kst-output>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
    <kst-output field="WarehouseID" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    statusEnum() {
      return Model.StatusPos;
    },
    statusValue() {
      return Model.StatusPos.Pos.ID;
    }
  }
}
</script>