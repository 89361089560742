<template>
  <div>
    <div class="mb-2">Excel with Data Catalog (i.e. Invoice List Page):</div>
    <kst-button action-mode
      class="is-primary"
      tag="button"
      :module="Model.Module"
      :moduleType="Model.ModuleType.Export"
      @click="handleExport"
    />
  </div>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Model from "../TestModel.js";
import Sample from "./TestExcelSample.js";
import InvCatalog from "../../Invoice/InvCatalog.js";
import InvModel from "../../Invoice/InvModel.js";

export default {
  mixins: [XlsxMixin],
  data: () => ({
    Model: Model
  }),
  methods: {
    handleExport() {
      this.createBook();
      this.createSheet();

      this.addList();
      this.download("list catalog excel.xlsx");
    },

    addList() {
      this.addSequenceHeader();
      this.addColumnHeader(InvCatalog.ID.Label);
      this.addColumnHeader(InvCatalog.InvoiceDate.Label);
      this.addColumnHeader(InvCatalog.SONumber.Label);
      this.addColumnHeader(InvCatalog.WarehouseName.Label);
      this.addColumnHeader(InvCatalog.Client.Label);
      this.addColumnHeader(InvCatalog.PONumber.Label);
      this.addColumnHeader(InvCatalog.Total.Label);
      this.addColumnHeader(InvCatalog.Status.Label);

      for (const row of Sample.Rows) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("ID", row, InvCatalog);
        this.addCellValue("InvoiceDate", row, InvCatalog);
        this.addCellValue("SONumber", row, InvCatalog);
        this.addCellValue("WarehouseName", row, InvCatalog);
        this.addCellValue("Client", row, InvCatalog);
        this.addCellValue("PONumber", row, InvCatalog);
        this.addCellValue("Total", row, InvCatalog);
        this.addCellStatus(row.Status, InvModel.Status);
      }

      this.addSheet("List");
    }
  }
}
</script>