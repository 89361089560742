<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :data="data"/>
        <Items :data="data" :items="data.Items"/>
        <Total :data="data"/>
      </b-tab-item>
      <b-tab-item v-if="access.Glob_HPP" :label="tabs.HppDetails">
        <HppItems :items="data.Items"/>
        <HppTotal :data="data"/>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../StrdInfo.js";
import Model from "../StrdModel.js";
import Services from "../../../services/Api/StockReductionServices.js";

import Details from "./StrdD_Details.vue";
import HppItems from "./StrdD_HppItems.vue";
import HppTotal from "./StrdD_HppTotal.vue";
import Items from "./StrdD_Items.vue";
import Total from "./StrdD_Total.vue";

export default {
  components: {
    Details,
    HppItems,
    HppTotal,
    Items,
    Total
  },
  data: () => ({
    access: {},
    actions: [],
    data: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      HppDetails: Info.Tabs.HppDetails
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.data = data.Details;
      Model.Details.setDetailsByHppCalculation(this.data);
      Model.setDetailsBySpecialPrice(this.data, data.SpecialPriceCount);

      for (const item of this.data.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.data = {};

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>