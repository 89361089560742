import App from "../../services/App/App";
import Info from "./SecurityInfo.js";

export default {
  clearSession(){
    App.Session.clear();
  },

  validateCompany(value) {
    value = value || "";
    let result;
    const reg = /^[A-Za-z0-9. ]+$/;
    if(!reg.test(value)) {
      result = { error: "Nama tidak valid" }
    }
    // required
    if(!value) {
      result = { error: "Perusahaan tidak boleh kosong" }
    }
    return result;
  },
  validateName(value) {
    value = value || "";
    let result;
    const reg = /^[A-Za-z ]+$/;
    if(!reg.test(value)) {
      result = { error: "Nama tidak valid" }
    }
    // required
    if(!value) {
      result = { error: "Nama tidak boleh kosong" }
    }
    return result;
  },
  validateEmail(value) {
    value = value || "";
    let result;
    const reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!reg.test(value)) {
      result = { error: "Email tidak valid" }
    }
    // required
    if(!value) {
      result = { error: "Nama tidak boleh kosong" }
    }
    return result;
  },
  validatePhone(value) {
    value = value || "";
    let result;
    const reg = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    if(!reg.test(value)) {
      result = { error: "Nomor handphone tidak valid" }
    }
    // required
    if(!value) {
      result = { error: "Nomor handphone tidak boleh kosong" }
    }
    return result;
  },
  validatePassword(value) {
    value = value || "";
    let result = [];

    // required
    if(!value) {
      result.push(Info.Message.Password.Empty);
      return result;
    }

    if(value.length < 8) {
      result.push(Info.Message.Password.InvalidMinimum);
    } 
    if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
      result.push(Info.Message.Password.InvalidCharacter);
    }

    return result;
  },
  validateRetypePassword(password1, password2, required) {
    let result;
    if(password1 !== password2) {
      result = { error: "Password tidak cocok" }
    }
    if(required && !password2) {
      result = { error: "Password tidak boleh kosong" }
    }
    return result;
  },
  getErrorMessage(errors) {
    let error, message = "";

    for (error of errors) {
      if (message !== "") {
        message += App.Enum.Breakline.Text;
      }

      message += error;
    }

    return message;
  },
  getUpdatePasswordData(data) {
    return {
      password: data.password,
      user_metadata: {
        password_changed: "1"
      }
    }
  },
  getResendEmail(userID) {
    return {
      UserID: userID
    }
  },
  populateData(data) {
    return {
      company: App.Out.getString(data.company),
      owner: App.Out.getString(data.user),
      phone: App.Out.getString(data.phone),
      email: App.Out.getString(data.email),
      password: App.Out.getString(data.password),
      address: App.Out.getString(data.address),
      recaptchaToken: App.Out.getString(data.recaptchaToken)
    }
  },
  populateDataIsVerified(data) {
    return {
      ID: App.Out.getInteger(data.id)
    }
  },
  populateLogin(userData){
    return {
      email: App.Out.getString(userData.email),
      password: App.Out.getString(userData.password)
    }
  }
}