import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  /*** related ***/

  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      ExportList: AppModuleType.ExportList
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.SalesRecap_Act_Details,
    Act_ExportDetails: AppModuleAttr.SalesRecap_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.SalesRecap_Act_ExportList,
    Act_List: AppModuleAttr.SalesRecap_Act_List,
    Act_New: AppModuleAttr.SalesRecap_Act_New,
    Act_PrintDetails: AppModuleAttr.SalesRecap_Act_PrintDetails,
    // related
    Invoice_Act_List: AppModuleAttr.Invoice_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    Fields: ["RecapNumber"],
    ListParams: ["Search", "CashierID", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails(){
    return {
      // user input
      WarehouseID: null,
      CashierID: null,
      RecapNumber: "",
      IsAutoNumber: true,
      CashReceived: "",
      Comment: "",
      // support
      InvoiceRecordTypeID: App.Search.OptionAll,
      // by system
      Date: App.In.getDateToday(),
      PaymentTypeCash: 0,
      Total: 0
    };
  },

  setDetailsByPaymentTypeCash(userData, cashValue) {
    userData.PaymentTypeCash = cashValue;
  },

  updateDetails(userData, userInvoiceList) {
    let total = 0;

    for (let userInvoice of userInvoiceList) {
      total += userInvoice.Total;
    }
    userData.Total = total;
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      CashierID: userData.CashierID,
      RecapNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.RecapNumber),
      Date: App.Out.getDateString(userData.Date),
      CashReceived: App.Out.getInteger(userData.CashReceived),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateInvoices(userInvoiceList) {
    let resultList = [];
    for (const userInvoice of userInvoiceList) {
      resultList.push({
        ID: userInvoice.ID,
        // support validation
        SONumber: userInvoice.SONumber,
        Total: App.Out.getInteger(userInvoice.Total)
      });
    }

    return resultList;
  }
}