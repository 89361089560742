<template>
  <kst-table paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="DispatchImageSrc"
      :label="SlsItemCatalog.DispatchImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="SlsItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="SlsItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="QtyAvailable"
      :label="SlsItemCatalog.QtyAvailable.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="SlsItemCatalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="SlsItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import SlsItemCatalog from "../SlsItemCatalog.js";

export default {
  props: {
    items: Array,
    userData: Object
  },
  data: () => ({
    SlsItemCatalog: SlsItemCatalog
  })
}
</script>