<template>
  <kst-table paginated view-mode
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../PaytCatalog.js";

export default {
  props: {
    items: Array,
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>