import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import WhsD from "./Details/WhsD.vue";
import WhsE from "./Edit/WhsE.vue";
import WhsExL from "./ExportList/WhsExL.vue";
import WhsL from "./List/WhsL.vue";
import WhsN from "./New/WhsN.vue";
import WhsPL from "./PrintList/WhsPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Edit),
    ExportList: RouterModel.getPath(AppModule.Warehouse, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Warehouse, AppModuleType.New),
    PrintList: RouterModel.getPath(AppModule.Warehouse, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.Details),
        component: WhsD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.Edit),
        component: WhsE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.ExportList),
        component: WhsExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.List),
        component: WhsL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Warehouse),
            moduleattr: AppModuleAttr.Warehouse_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Warehouse),
            moduleattr: AppModuleAttr.Warehouse_Act_New,
            moduletype: AppModuleType.New, 
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Warehouse),
            moduleattr: AppModuleAttr.Warehouse_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.New),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.New),
        component: WhsN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Warehouse, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Warehouse, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Warehouse, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Warehouse, AppModuleType.PrintList),
        component: WhsPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Warehouse, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Warehouse),
          moduleattr: AppModuleAttr.Warehouse_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ]
  }
}