<template>
  <div class="block">
    <ClientSearchListAddress
      @init="handleClient_Init"
      @submit="handleClient_Submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      dateField="Date"
      numberField="DraftNumber"
    />

    <kst-output control-off status-off
      field="ClientID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="ClientName"
          :disabled="userData.ClientID !== null || userData.InvoiceCount !== 0"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <template v-if="userData.InvoiceCount === 0">
          <div class="control">
            <template v-if="userData.ClientID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button search-mode @click="handleSearch_Client"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button clear-mode @click="handleClear_Client"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="ClientAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="PONumber" :data="userData" :catalog="Catalog"/>
    <kst-output control-off status-off
      field="WarehouseID" :data="userData" :catalog="Catalog"
    />
  </div>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import ClientSearchListAddress from "../../Client/SearchListAddress/CliScLAddress.vue";

export default {
  components: {
    ClientSearchListAddress
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    // func
    showFunc: null
  }),
  methods: {
    /*** by client modal ***/

    handleClient_Init(show) {
      this.showFunc = show;
    },
    handleClient_Submit(row) {
      Model.setDetailsByClient(this.userData, row);
    },

    /*** custom ***/

    handleSearch_Client() {
      this.showFunc(Services.getRelatedClientAddressList);
    },
    handleClear_Client() {
      Model.clearDetailsByClient(this.userData);
    }
  }
}
</script>