<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Purchase :details="details"/>
        <Details :details="details"/>
        <Items :access="access" :details="details" :items="details.Items"/>
        <template v-if="access.Fld_Amount">
          <Total :details="details"/>
        </template>
      </b-tab-item>
      <template v-if="access.RtnReceive_Act_List">
        <b-tab-item :label="tabs.Return">
          <Return
            :access="access"
            :details="details"
            :returnReceiveList="returnReceiveList"
          />
        </b-tab-item>
      </template>
      <template v-if="debtData !== null">
        <b-tab-item :label="tabs.DebtPayList" v-if="access.DebtPay_Act_List">
          <DebtPay :debtData="debtData"/>
        </b-tab-item>
      </template>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import DebtPay from "./RcvD_DebtPay.vue";
import Details from "./RcvD_Details.vue";
import Items from "./RcvD_Items.vue";
import Purchase from "./RcvD_Purchase.vue";
import Return from "./RcvD_Return.vue";
import Total from "./RcvD_Total.vue";

export default {
  components: {
    DebtPay,
    Details,
    Items,
    Purchase,
    Return,
    Total
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    debtData: {},
    returnReceiveList: [],
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      DebtPayList: Info.Tabs.DebtPayList,
      Return: Info.Tabs.Return
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
      for (const item of this.details.Items) {
        Model.updateItemReadOnly(item);
      }

      if (Object.prototype.hasOwnProperty.call(data, "DebtPay")) {
        this.debtData = data.DebtPay;
        Model.Details.updateDebtData(this.debtData, this.details);
      }

      if (this.access.RtnReceive_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "RtnReceiveList")) {
          this.returnReceiveList = data.RtnReceiveList;
        }
        else {
          this.access.RtnReceive_Act_List = false;
        }
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.debtData = null;
      this.returnReceiveList = [];

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>