import App from "../../../services/App/App.js";

export default {
  /*** property ***/

  DateRange: {
    Today: {
      ID: 1, 
      Label: "Hari ini"
    },
    Yesterday: {
      ID: 2, 
      Label: "Kemarin"
    },
    LastWeek: {
      ID: 3, 
      Label: "7 Hari Terakhir"
    },
    Last30Days: {
      ID: 4, 
      Label: "30 Hari Terakhir"
    },
    ThisMonth: {
      ID: 5, 
      Label: "Bulan Ini"
    },
    LastMonth: {
      ID: 6, 
      Label: "Bulan Kemarin"
    },
    Last3Month: {
      ID: 7, 
      Label: "3 Bulan Kemarin"
    },
    Last6Month: {
      ID: 8, 
      Label: "6 Bulan Kemarin"
    }
  },

  OptionKey: {
    ID: "ID",
    Label: "Label",
    StartDate: "StartDate",
    EndDate: "EndDate"
  },

  /*** method ***/

  createDateRangeOptions() {
    return this.helper.createDateRangeOptions(this.DateRange, this.OptionKey);
  },

  helper: {
    createDateRangeOptions(dateRangeEnum, optionKeyEnum) {
      const todayDate = App.In.getDateToday();
      const yesterday = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 1);
      const last7DayDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 6);
      const last30DayDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 29);
      const firstDayThisMonthDate = new Date(todayDate.getFullYear(),
        todayDate.getMonth(), 1);
      const firstDayLastMonthDate = new Date(todayDate.getFullYear(),
        todayDate.getMonth() - 1, 1);
      const lastDayLastMonthDate = new Date(firstDayThisMonthDate.getFullYear(),
        firstDayThisMonthDate.getMonth(), 0);
      const lastDay3MonthDate = new Date(firstDayThisMonthDate.getFullYear(),
        firstDayThisMonthDate.getMonth() - 2, 0);
      const day3MonthDate = todayDate.getDate() < lastDay3MonthDate.getDate()
        ? new Date(firstDayThisMonthDate.getFullYear(),
          firstDayThisMonthDate.getMonth() - 3, todayDate.getDate())
        : lastDay3MonthDate;
      const lastDay6MonthDate = new Date(firstDayThisMonthDate.getFullYear(),
        firstDayThisMonthDate.getMonth() - 5, 0);
      const day6MonthDate = todayDate.getDate() < lastDay6MonthDate.getDate()
        ? new Date(firstDayThisMonthDate.getFullYear(),
          firstDayThisMonthDate.getMonth() - 6, todayDate.getDate())
        : lastDay6MonthDate;

      const items = [
        {
          [optionKeyEnum.ID]: dateRangeEnum.Today.ID,
          [optionKeyEnum.Label]: dateRangeEnum.Today.Label,
          [optionKeyEnum.StartDate]: todayDate,
          [optionKeyEnum.EndDate]: todayDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.Yesterday.ID,
          [optionKeyEnum.Label]: dateRangeEnum.Yesterday.Label,
          [optionKeyEnum.StartDate]: yesterday,
          [optionKeyEnum.EndDate]: yesterday
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.LastWeek.ID,
          [optionKeyEnum.Label]: dateRangeEnum.LastWeek.Label,
          [optionKeyEnum.StartDate]: last7DayDate,
          [optionKeyEnum.EndDate]: todayDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.Last30Days.ID,
          [optionKeyEnum.Label]: dateRangeEnum.Last30Days.Label,
          [optionKeyEnum.StartDate]: last30DayDate,
          [optionKeyEnum.EndDate]: todayDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.ThisMonth.ID,
          [optionKeyEnum.Label]: dateRangeEnum.ThisMonth.Label,
          [optionKeyEnum.StartDate]: firstDayThisMonthDate,
          [optionKeyEnum.EndDate]: todayDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.LastMonth.ID,
          [optionKeyEnum.Label]: dateRangeEnum.LastMonth.Label,
          [optionKeyEnum.StartDate]: firstDayLastMonthDate,
          [optionKeyEnum.EndDate]: lastDayLastMonthDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.Last3Month.ID,
          [optionKeyEnum.Label]: dateRangeEnum.Last3Month.Label,
          [optionKeyEnum.StartDate]: day3MonthDate,
          [optionKeyEnum.EndDate]: todayDate
        },
        {
          [optionKeyEnum.ID]: dateRangeEnum.Last6Month.ID,
          [optionKeyEnum.Label]: dateRangeEnum.Last6Month.Label,
          [optionKeyEnum.StartDate]: day6MonthDate,
          [optionKeyEnum.EndDate]: todayDate
        }
      ];

      // set: list options
      let listOptions = { id: optionKeyEnum.ID, label: optionKeyEnum.Label };

      // create: select rows
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(optionKeyEnum.ID, optionKeyEnum.Label, selectItems);
    }
  }
}