<template>
  <kst-section 
    :title="sectionTitle"
    :help="sectionHelp"
  >
    <InvoiceSearchList status
      ref="mdlInvoiceSearchList"
      :warehouseList="warehouseList"
      @submit="handleSubmit"
    />

    <kst-number-with-date search-mode
      :catalog="Catalog"
      :data="userData"
      dateField="SalesOrderDate"
      numberField="SalesOrderID"
      @search="handleSearch"
      @clear="handleClear"
    />
    <kst-input class="is-hidden"
      field="SalesOrderID" :data="userData" :catalog="Catalog"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RinvCatalog.js";
import Info from "../RinvInfo.js";
import Services from "../../../services/Api/ReturnInvoiceServices.js";

import InvoiceSearchList from "../../Invoice/SearchList/InvScL.vue";

export default {
  components: {
    InvoiceSearchList
  },
  props: {
    userData: Object,
    warehouseList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionHelp() {
      return this.userData.SalesOrderID
        ? Info.Message.New_Invoice
        : Info.Message.New_InvoiceBlank;
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    }
  },
  methods: {
    handleSubmit(row) {
      this.$emit(this.$kst.Enum.Event.Submit, row);
    },

    handleClear() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch() {
      this.$refs.mdlInvoiceSearchList.show(
        Services.getRelatedInvoiceList,
        Services.getRelatedInvoiceDetails
      );
    }
  }
}
</script>