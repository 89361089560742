import RouterModel from "../../router/RouterModel.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import LowstkL from "./List/LowstkL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.LowStock, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.LowStock, AppModuleType.List),
        name: RouterModel.getName(AppModule.LowStock, AppModuleType.List),
        component: LowstkL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.LowStock, AppModuleType.List),
          module: RouterModel.getModule(AppModule.LowStock),
          moduleattr: AppModuleAttr.LowStock_Act_List,
          moduletype: AppModuleType.List
        }
      }
    ];
  },
}