<template>
  <kst-section collapsible collapsed info-mode
    :title="sectionTitle"
    :help-collapsed="sectionHelpCollapsed"
    :help-expanded="sectionHelpExpanded"
  >
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="userData"
      dateField="SalesOrderDate"
      numberField="SONumber"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RinvCatalog.js";
import Info from "../RinvInfo.js";
import Model from "../RinvModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionHelpCollapsed() {
      return Model.getInvoiceSectionHelp(this.userData);
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    },
    sectionHelpExpanded() {
      return Info.Message.Edit_InvoiceFields;
    }
  }
}
</script>