<template>
  <kst-section :title="Info.Section.RecReminder.Title">
    <kst-output field="RecEmailLastSent" :data="details" :catalog="Catalog"/>
    <kst-output field="RecDueReminderDays" :data="details" :catalog="Catalog"/>
    <kst-output field="RecEmailSentTime" :data="details" :catalog="Catalog"/>

    <List class="mt-5"
      :emailList="details.RecEmail"
    />
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";

import List from "./CpD_RecReminder_List.vue";

export default {
  components: {
    List
  },
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  })
}
</script>