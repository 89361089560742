<template>
  <kst-print-table view-mode :data="summaryData.TransactionList">
    <b-table-column
      v-slot="{ row }"
      field="Label"
      :label="OmzSummaryCatalog.ModuleName.Label"
    >
    {{ row.Label }}
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="AddTotal"
      :label="OmzSummaryCatalog.AddTotal.Label"
    >
      <template v-if="row.AddTotal !== null">
        <kst-value :field="column.field" :data="row" :catalog="OmzSummaryCatalog"/>
      </template>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="SubTotal"
      :label="OmzSummaryCatalog.SubTotal.Label"
    >
      <template v-if="row.SubTotal !== null">
        <kst-value :field="column.field" :data="row" :catalog="OmzSummaryCatalog"/>
      </template>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import OmzSummaryCatalog from "../OmzSummaryCatalog.js";

export default {
  props: {
    summaryData: Object
  },
  data: () => ({
    OmzSummaryCatalog: OmzSummaryCatalog
  })
}
</script>