<template>  
  <kst-section :title="sectionTitle">
    <kst-input field="DeliveryAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="DeliveryDate" :data="userData" :catalog="Catalog"
      :customValidations="deliveryDateValidations"
    />
  </kst-section>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";

export default {
  props: {
    userData: Object,
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    deliveryDateValidations() {
      return Model.validationDeliveryDate(this.userData);
    },
    sectionTitle() {
      return Info.Section.Delivery.Title;
    }
  }
}
</script>