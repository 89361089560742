<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SlrcCatalog.js";
import SlrcInvCatalog from "../SlrcInvCatalog.js";
import Info from "../SlrcInfo.js";
import Model from "../SlrcModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      if (this.userData.WithInvoiceList) {
        if (data.InvoiceList) {
          this.createSheet();
          this.addInvoiceList(data.InvoiceList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader(Catalog.RecapNumber);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.CashierID.Label);
      this.addColumnHeader(Catalog.CashierID);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.CashReceived);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("RecapNumber", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("CashierID", details, Catalog);
      this.addCellRelatedValue("CashierID", details, Catalog);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue("CashReceived", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
  
    addInvoiceList(invoiceList) {
      this.addInvoiceList_Rows(invoiceList);

      this.addSheet(Info.Export.Details.Excel.SheetName.InvoiceList);
    },
    addInvoiceList_Rows(invoiceList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(SlrcInvCatalog.SONumber);
      this.addColumnHeader(SlrcInvCatalog.InvoiceDate);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(SlrcInvCatalog.ClientID.Label);
      this.addColumnHeader(SlrcInvCatalog.ClientAlias);
      this.addColumnHeader(SlrcInvCatalog.ClientID);
      this.addColumnHeader(SlrcInvCatalog.PONumber);
      this.addColumnHeader(SlrcInvCatalog.Total);
      this.addColumnHeader(SlrcInvCatalog.PaymentTypeName);
      this.addColumnHeader(SlrcInvCatalog.Status);

      // value
      for (const row of invoiceList) {
        this.addRow();
        this.addCellValue_ID(row);
        this.addCellValue("SONumber", row, SlrcInvCatalog);
        this.addCellValue("InvoiceDate", row, SlrcInvCatalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, SlrcInvCatalog);
        this.addCellValue("ClientAlias", row, SlrcInvCatalog);
        this.addCellRelatedValue("ClientID", row, SlrcInvCatalog);
        this.addCellValue("PONumber", row, SlrcInvCatalog);
        this.addCellValue("Total", row, SlrcInvCatalog);
        this.addCellValue("PaymentTypeName", row, SlrcInvCatalog);
        this.addCellStatus(row.Status, Model.Status);      
      }
    }
  }
}
</script>