<template>
  <kst-section items-mode class="mt-5">
    <StockSearchListDefault sell-price weight
      @init="handleStock_Init"
      @submit="handleStock_Submit"
    />
    <PriceSearch
      @init="handlePrice_Init"
      @submit="handlePrice_Submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Model.SalesItem.Module.FullName"
          :moduleType="Model.SalesItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <template v-if="userData.WarehouseID !== null">
          <kst-button action-mode
            class="is-primary"
            tag="button"
            :label="Model.SalesItem.Module.FullName"
            :moduleType="Model.SalesItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </template>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List
        :userData="userData"
        :userItems="userItems"
        @callback="handleList_Callback"
      />
    </template>
  </kst-section>
</template>

<script>
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import List from "./SlsN_Items_List.vue";
import PriceSearch from "../../Invoice/SearchListPrice/InvScLPrice.vue";
import StockSearchListDefault from "../../Stock/SearchListDefault/StkScLDefault.vue";

export default {
  components: {
    List,
    PriceSearch,
    StockSearchListDefault
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    rowIndex: null,
    // func
    showPriceFunc: null,
    showStockFunc: null
  }),
  methods: {
    /*** by price modal ***/

    handlePrice_Init(show) {
      this.showPriceFunc = show;
    },
    handlePrice_Submit(row) {
      let userItem = this.userItems[this.rowIndex];
      Model.setItemByPrice(userItem, row);
      Model.updateItem(userItem);
    },

    /*** by stock modal ***/

    handleStock_Init(show) {
      this.showStockFunc = show;
    },
    handleStock_Submit(stockData, itemData) {
      let item = Model.createItem();
      Model.setItemByStock(item, stockData, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_Item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    },

    handleList_Callback(index) {
      this.rowIndex = index;
      const userItem = this.userItems[index];
      const sellPrice = this.$kst.Out.getInteger(userItem.SellPrice) /
        userItem.PackagingValue;

      this.showPriceFunc(
        // dispatchId, clientId, clientName, sellPrice, packagingList, getListService
        userItem.DispatchID,
        this.userData.ClientID,
        this.userData.ClientName,
        sellPrice,
        userItem.PackagingOptions.rows,
        Services.getRelatedInvoicePriceList
      );
    }
  }
}
</script>