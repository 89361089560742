<template>
  <div>
    <kst-list :items="items"/>
  </div>
</template>

<script>
import Catalog from "../ModuleAttrCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>