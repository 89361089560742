import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./StclModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name
        },
        Section: {
          StockClosingItems: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.StockClosing.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.StockClosing.FullName,
          StockClosingItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    ApplySpecialPrice:
      "Terapkan " + AppModule.SpecialPrice.FullName +
        " untuk semua barang di bawah ini."
  },
  Section: {
    SpecialPrice: {
      Title: AppCatalog.Info.Update + " " + AppModule.Item.FullName
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}