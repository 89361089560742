<template>
  <div class="kst-page-print-details" :class="theClass" :style="theStyle">
    <template v-if="reloadAreaVisible">
      <div class="mb-0 px-4 py-2 ks-border-b-light ks-print-hidden">
        <div class="buttons is-justify-content-end">
          <kst-button slim-size reload-mode @click="handleReload"/>

          <template v-if="printVisible">
            <kst-button slim-size print-mode @click="handlePrint"/>
          </template>
        </div>
      </div>
    </template>

    <div class="kst-page-print-details__content p-4">
      <kst-skeleton :active="isLoading"/>

      <template v-if="hasAccess !== true">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <slot :title="theTitle"/>

        <template v-if="pageFooter">
          <slot name="page-footer"
            :label="labelPageFooter"
            :timestamp="timestamp"
            :username="username"
          >
            <div class="mt-2">
              {{ labelPageFooter }} {{ timestamp }}, {{ username }}
            </div>
          </slot>
        </template>
      </template>

      <kst-failure init-mode :items="failureInit"/>
    </div>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadPrint
 * 2. handle loadPrint success-callback
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin],
  props: {
    pageFooter: Boolean,
    width: Number
  },
  data: () => ({
    userData: {
      ID: null // required
    },
    hasAccess: null,
    printVisible: false,
    // loading
    isLoading: true,
    // area
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    // error
    failureInit: null
  }),
  computed: {
    labelPageFooter() {
      return AppCatalog.Print.PrintedAt;
    },
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    timestamp() {
      return this.$kst.View.getTimestamp();
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.module.FullName;
    },
    theStyle() {
      return this.width ? "width: " + this.width + "px" : undefined;
    },
    username() {
      return AppCatalog.Print.PrintedBy + this.$kst.Session.getName();
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(this.$kst.Enum.Event.Init, this.userData.ID, this.loadPrint);
      }
    },
    init_data() {
      this.userData.ID = null;
      this.hasAccess = null;
      this.printVisible = false;
      // loading
      this.isLoading = true;
      // area
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failureInit = null;
    },
    init_validate() {
      // check: access
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess !== true) {
        this.init_Failed();
        return false;
      }

      // check: required url params
      this.userData.ID = this.getUrlParam(this.$kst.Enum.Param.ID);

      if (!this.userData.ID) {
        this.failureInit = [AppCatalog.Message.ParamIncomplete];
        this.init_Failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.printVisible = true;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;
    },
    init_Failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleReload() {
      this.init();
    },

    /*** custom ***/

    loadPrint(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadPrint_success,
        this.loadPrint_fault,
        this.loadPrint_fault,
        null, true
      );
    },
    loadPrint_success(data, successCallback) {
      successCallback(data);
      this.init_success();
    },
    loadPrint_fault(message) {
      this.failureInit = message;
      this.init_Failed();
    },

    handlePrint() {
      window.print();
    }
  }
}
</script>