import App from "../../services/App/App.js";
import ModelBody from "./StclModel_Body.js";
import ModelDetails from "./StclModel_Details.js";
import SpecialPriceModel from "../SpecialPrice/SpModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  StclItem: ModelBody.StclItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    ModelBody.setDetailsBySpecialPrice(userData, specialPriceCount);
  },

  setItemsByStockList(userItems, stockList, specialPriceId, specialPriceOptions) {
    ModelBody.setItemsByStockList(userItems, stockList,
      specialPriceId, specialPriceOptions
    );
  },
  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  getItemInfo(userItem) {
    return ModelBody.getItemInfo(userItem);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },

  updateItemsBySpecialPrice(userItems, specialPriceId) {
    ModelBody.updateItemsBySpecialPrice(userItems, specialPriceId);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemBySpecialPrice(userItem) {
    ModelBody.updateItemBySpecialPrice(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** external ***/

  getSpecialPriceOptions(specialPriceList, optionAllText, optionNonText) {
    return SpecialPriceModel.createOptions(specialPriceList, optionAllText,
      optionNonText
    );
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}