<template>
  <div>
    <kst-output print-mode field="LogisticsNumber" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="VehicleIDNumber" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="WarehouseID" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="SOID" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../DlvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>