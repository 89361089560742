<template>
  <kst-page-export
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
  </kst-page-export>
</template>

<script>
import Info from "../DebtInfo.js";
import Model from "../DebtModel.js";
import SearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/DebtServices.js";

import Export from "./DebtExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    SearchCatalog: SearchCatalog,
    searchData: {
      Search: "",
      VendorID: null, 
      VendorName: "",
      Date: null,
      WarehouseID: null
    },
    userData: {
      WithList: true
    },
    searchParams: ["Search", "VendorID", "VendorName", "Date", "WarehouseID"],
    // func
    exportExcelFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.VendorID = null;
      this.searchData.VendorName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields
      };
    },

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>