import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RinvCatalog.js"
import Model from "./RinvModel.js";
import RinvItemCatalog from "./RinvItemCatalog.js";

export default {
  Button: {
    InvoiceItem: {
      Label: AppModule.Item.FullName + " " + Model.Invoice.Module.FullName
    }
  },
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.RtnInvoice.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.RtnInvoice.FullName,
          RtnInvoiceItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_RtnInvoiceFields:
      "Tidak diperkenankan mengubah " + Catalog.Date.Label + ", " +
      Catalog.ClientID.Label + " dan " + Catalog.ClientAddress.Label + ".",
    Edit_DeletedItem:
      "Tidak diperkenankan menggunakan " + AppCatalog.Info.DeletedItem + ".",
    Edit_InvoiceFields:
      "Tidak diperkenankan mengubah " + Catalog.SalesOrderID.Label + " dan " +
      Catalog.SalesOrderDate.Label + ".",
    Edit_InvoiceItem:
      "Tidak diperkenankan mengubah " + RinvItemCatalog.DispatchID.ColumnLabel + ", " +
      RinvItemCatalog.Packaging.Label + " dan " +
      RinvItemCatalog.SellPrice.Label + ".",
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.RtnInvoiceItem.Module.FullName + "?",
    New_Invoice:
      "Memilih / menghapus " + Model.Invoice.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + ".",
    New_InvoiceBlank:
      "Harap memilih " + Catalog.SONumber.Label + " terlebih dahulu."
  },
  Section: {
    Invoice: {
      Title: Model.Invoice.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}