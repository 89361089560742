<template>
  <PagePrintDetails page-footer
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
        <div>{{ subtitle }}</div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column">
        <Details :data="data"/>
      </div>
      <div class="column">
        <Client :data="data"/>
      </div>
    </div>

    <Items class="mt-4" :data="data" :items="data.Items"/>
    <Total class="mt-4" :data="data" :items="data.Items" />
  </PagePrintDetails>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Info from "../SalesInfo.js";
import Model from "../SalesModel.js";
import Services from "../../../services/ApiPublic/SalesServices.js";

import Client from "./SlsPD_Client.vue";
import Details from "./SlsPD_Details.vue";
import Items from "./SlsPD_Items.vue";
import Total from "./SlsPD_Total.vue";
// external
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";
import PagePrintDetails from "../../_shared/PagePrintDetails/PagePrintDetails.vue";

export default {
  mixins: [RouterMixin],
  components: {
    Client,
    Details,
    Items,
    Total,
    // external
    Company,
    PagePrintDetails
  },
  data: () => ({
    data: {},
    companyData: {},
    serviceData: {
      SelfServiceID: null,
      SelfServicePin: null
    },
  }),
  computed: {
    title() {
      return Model.Module.FullName;
    },
    subtitle() {
      return Info.Print.Details.SubTitle;
    }
  },
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint, showFailureInit) {
      this.resetData();

      if (this.handleInit_Validate(showFailureInit)) {
        const config = Services.getPrintDetailsData(
          id, this.serviceData.SelfServiceID, this.serviceData.SelfServicePin
        );
        loadPrint(config, this.handleInit_Success);
      }
    },
    handleInit_Validate(showFailureInit) {
      // check: required url params
      this.serviceData.SelfServiceID = this.getUrlParam(
        this.$kst.Enum.Param.ServiceID
      );

      if (!this.serviceData.SelfServiceID) {
        showFailureInit(AppCatalog.Message.ParamIncomplete);
        return false;
      }

      // check: pin
      this.serviceData.SelfServicePin = this.$kst.Session.getSelfServicePin();

      return true;
    },
    handleInit_Success(data) {
      this.data = data.Details;
      this.companyData = data.CompanyDetails;
    },

    /*** custom ***/

    resetData() {
      // serviceData
      this.serviceData.SelfServiceID = "";
      this.serviceData.SelfServicePin = "";

      // other
      this.data = {};
      this.companyData = {};
    }
  }
}
</script>