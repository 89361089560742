import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.daily;

export default {
  getExport(params) {
    const attributes = ["date", "warehouse", "searchresult"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/export" + urlParams
    };
  },
  getPrintData(params) {
    const attributes = ["date", "warehouse", "searchresult"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdata" + urlParams
    };
  },
  getReport(params) {
    const attributes = ["date", "warehouse", "searchresult"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/report" + urlParams
    };
  },
  getReportData(params) {
    const attributes = ["date", "warehouse", "searchresult"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/reportdata" + urlParams
    };
  }
}