<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../SttrCatalog.js";
import Info from "../SttrInfo.js";
import Model from "../SttrModel.js";
import SttrItemCatalog from "../SttrItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.StockTrfNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.SourceWarehouseID.Label);
      this.addColumnHeader(Catalog.SourceWarehouseID);
      this.addColumnHeader_ID(Catalog.DestinationWarehouseID.Label);
      this.addColumnHeader(Catalog.DestinationWarehouseID);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(SttrItemCatalog.DispatchID.Label);
        this.addColumnHeader(SttrItemCatalog.DispatchID);
        this.addColumnHeader(SttrItemCatalog.Packaging);
        this.addColumnHeader(SttrItemCatalog.Qty);
        this.addColumnHeader(SttrItemCatalog.TotalQty);
        if (this.access.Glob_HPP) {
          this.addColumnHeader(SttrItemCatalog.HppTotal);
        }
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("StockTrfNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("SourceWarehouseID", row, Catalog);
        this.addCellRelatedValue("SourceWarehouseID", row, Catalog);
        this.addCellValue("DestinationWarehouseID", row, Catalog);
        this.addCellRelatedValue("DestinationWarehouseID", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, SttrItemCatalog);
          this.addCellRelatedValue("DispatchID", row, SttrItemCatalog);
          this.addCellValue("Packaging", row, SttrItemCatalog);
          this.addCellValue("Qty", row, SttrItemCatalog);
          this.addCellValue("TotalQty", row, SttrItemCatalog);
          if (this.access.Glob_HPP) {
            this.addCellValue("HppTotal", row, SttrItemCatalog);
          }
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>