<template>
  <kst-container class="kst-page-list" :class="theClass">
    <nav class="level mb-0 p-5 ks-border-b-light">
      <div class="level-left">
        <div>
          <kst-title class="m-0">{{ theTitle }}</kst-title>
          <template v-if="subtitle">
            <kst-subtitle class="m-0">{{ subtitle }}</kst-subtitle>
          </template>
        </div>
      </div>
      <div class="level-right" v-if="reloadAreaVisible || actionAreaVisible">
        <div class="buttons">
          <template v-if="reloadAreaVisible">
            <kst-button slim-size reload-mode @click="handleReload"/>
          </template>
          <template v-if="actionAreaVisible">
            <template v-if="hasNewAction">
              <kst-button action-mode full-label slim-size
                class="is-primary"
                :module="newModule"
                :moduleType="newModuleType"
              />
            </template>
            <template v-if="hasPrintAction">
              <kst-button action-mode slim-size
                class="is-primary"
                :module="printModule"
                :moduleType="printModuleType"
              />
            </template>
            <template v-if="hasEditAction">
              <kst-button action-mode full-label slim-size
                class="is-primary"
                :module="editModule"
                :moduleType="editModuleType"
              />
            </template>
            <template v-if="hasRelatedAction">
              <kst-button action-mode full-label slim-size
                class="is-primary"
                :module="relatedModule"
                :moduleType="relatedModuleType"
              />
            </template>
          </template>
        </div>
      </div>
    </nav>

    <div class="kst-page-list__content p-5">
      <kst-skeleton :active="isLoading"/>

      <template v-if="hasAccess !== true">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <Search v-if="search"
          @reset="handleSearch_reset"
          @submit="handleSearch_submit"
        >
          <template #default="{ handleInput }">
            <slot name="search" :handleInput="handleInput"/>
          </template>
        </Search>

        <kst-section search-result-mode
          :help="resultHelp"
        >
          <template #top-left>
            <template v-if="!isListLoading">
              <kst-button slim-size refresh-mode @click="handleRefresh"/>
            </template>
          </template>

          <template #top-right v-if="topSlotVisible">
            <slot name="search-result-buttons">
              <template v-if="hasPrintListAction || hasExportListAction">
                <template v-if="!isListLoading">
                  <div class="buttons">
                    <slot name="search-result-buttons-before"/>

                    <template v-if="hasPrintListAction">
                      <kst-button action-mode slim-size
                        class="is-primary"
                        :module="printListModule"
                        :moduleType="printListModuleType"
                        :urlParams="urlParams"
                      />
                    </template>
                    <template v-if="hasExportListAction">
                      <kst-button action-mode slim-size
                        class="is-primary"
                        :module="exportListModule"
                        :moduleType="exportListModuleType"
                        :urlParams="urlParams"
                      />
                    </template>

                    <slot name="search-result-buttons-after"/>
                  </div>
                </template>
              </template>
            </slot>
          </template>

          <kst-skeleton :active="isListLoading"/>
          <kst-failure list-mode :items="failureList"/>

          <template v-if="searchResultSlotVisible">
            <slot
              name="search-result"
              :data="data"
            />
          </template>

          <slot name="search-result-footer"/>
        </kst-section>
      </template>

      <kst-failure init-mode :items="failureInit"/>
    </div>
  </kst-container>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import AppModuleType from "../../../services/App/AppModuleType.js";

import Search from "./PageList_Search.vue";

/**
 * flow (how to use):
 * 1. handle Init event and do loadListData
 * 2. handle Reset and Load event, do loadList
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  components: {
    Search
  },
  props: {
    catalog: Object,
    params: Array,
    resultHelp: String,
    search: Object,
    subtitle: String
  },
  data: () => ({
    AppModuleType: AppModuleType,
    hasAccess: false,
    isInitialized: false,
    data: {},
    // loading
    isLoading: true,
    isListLoading: true,
    // support debounce
    loadSuccessCallback: null,
    loadCounter: 0,
    // area
    actionAreaVisible: false,
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    searchResultSlotVisible: false,
    topSlotVisible: false,
    // error
    failureInit: null,
    failureList: null
  }),
  computed: {
    exportListModule(){
      return this.$route.meta.exportlist
        ? this.$route.meta.exportlist.module : null;
    },
    exportListModuleType(){
      return this.$route.meta.exportlist
        ? this.$route.meta.exportlist.moduletype : null;
    },
    hasExportListAction() {
      if (this.$route.meta.exportlist) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.exportlist.moduleattr);
      }

      return false;
    },
    hasNewAction() {
      if (this.$route.meta.new) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.new.moduleattr);
      }

      return false;
    },
    hasPrintAction() {
      if (this.$route.meta.print) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.print.moduleattr);
      }

      return false;
    },
    hasEditAction() {
      if (this.$route.meta.edit) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.edit.moduleattr);
      }

      return false;
    },
    hasPrintListAction() {
      if (this.$route.meta.printlist) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.printlist.moduleattr);
      }

      return false;
    },
    hasRelatedAction() {
      if (this.$route.meta.related) {
        const moduleAttrList = this.$kst.Session.getModuleAttr();
        return moduleAttrList.includes(this.$route.meta.related.moduleattr);
      }

      return false;
    },
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    editModuleType(){
      return this.$route.meta.edit ? this.$route.meta.edit.moduletype : null;
    },
    editModule(){
      return this.$route.meta.edit ? this.$route.meta.edit.module : null;
    },
    newModuleType(){
      return this.$route.meta.new ? this.$route.meta.new.moduletype : null;
    },
    newModule(){
      return this.$route.meta.new ? this.$route.meta.new.module : null;
    },
    path() {
      return this.$route.path;
    },
    printModuleType(){
      return this.$route.meta.print ? this.$route.meta.print.moduletype : null;
    },
    printModule(){
      return this.$route.meta.print ? this.$route.meta.print.module : null;
    },
    printListModule(){
      return this.$route.meta.printlist
        ? this.$route.meta.printlist.module : null;
    },
    printListModuleType(){
      return this.$route.meta.printlist
        ? this.$route.meta.printlist.moduletype : null;
    },
    relatedModule(){
      return this.$route.meta.related ? this.$route.meta.related.module : null;
    },
    relatedModuleType(){
      return this.$route.meta.related ? this.$route.meta.related.moduletype : null;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    },
    urlParams() {
      const urlQueryObj = this.getUrlQueryObj(
        this.search, this.params, this.catalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  },
  created() {
    this.init();
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        if (!this.isInitialized) {
          this.init_data();
        }

        if (this.hasAccess) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.populateUrlQuery(this.search, this.params, this.catalog);
            this.handler_load()
          }
          else if(!this.search){ 
            this.handler_load()
          }
        }
      }
    }
  },
  methods: {
    /*** page template ***/

    init() {
      if (!this.isInitialized) {
        this.init_data();
      }

      if (this.init_validate()) {
        if (Object.keys(this.$route.query).length === 0 
          && this.search
        ) {
          this.updatePageParams();
        }
      }
    },
    init_data() {
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);
      this.isInitialized = true;
      // loading
      this.isLoading = true;
      this.isListLoading = true;
      // support debounce
      this.loadCounter = 0;
      // area
      this.actionAreaVisible = false;
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      this.searchResultSlotVisible = false;
      this.topSlotVisible = false;
      // error
      this.failureInit = null;
      this.failureList = null;
    },
    init_validate() {
      // check: access
      if (this.hasAccess !== true) {
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.isListLoading = false;
      this.actionAreaVisible = true;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;
      this.searchResultSlotVisible = true;
      this.topSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
      this.actionAreaVisible = true;
      this.reloadAreaVisible = true;
    },
    handler_load() {
      if (this.isLoading) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadListData);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Load, this.loadList);
      }
    }, 
    handleReload() {
      this.isInitialized = false;
      this.init();

      if (this.hasAccess) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadListData);
      }
    },

    handleSearch_reset() {
      this.$emit(this.$kst.Enum.Event.Reset);
      this.updatePageParams();
    },
    handleSearch_submit() {
      let fields = [];
      const newQuery = this.getUrlQueryObj(this.search, this.params, this.catalog);
      const oldQuery = this.$route.query;

      for (const key in newQuery) {
        if(!fields.includes(key)) {
          fields.push(key);
        }
      }

      for (const key in oldQuery) {
        if(!fields.includes(key)) {
          fields.push(key);
        }
      }

      if (this.$kst.Compare.validateObject(newQuery, oldQuery, fields)) {
        this.handleRefresh();
      } else {
        this.updatePageParams();
      }
    },

    handleRefresh() {
      this.$emit(this.$kst.Enum.Event.Load, this.loadList);
    },

    /*** custom ***/

    loadListData(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadListData_success,
        this.loadListData_fault,
        this.loadListData_fault,
        null, true
      );
    },
    loadListData_success(data, successCallback) {
      this.data = data;
      if (successCallback) {
        successCallback(data);
      }

      this.init_success();
    },
    loadListData_fault(message) {
      this.failureInit = message;
      this.init_failed();
    },

    loadList(config, successCallback) {
      this.loadSuccessCallback = successCallback ? successCallback : null;
      this.loadCounter++;
      this.failureList = null;
      this.isListLoading = true;
      this.searchResultSlotVisible = false;
      this.topSlotVisible = false;
      this.data = {};

      this.runAxios(config, this.loadCounter,
        this.loadList_success,
        this.loadList_fault,
        this.loadList_fault
      );
    },
    loadList_end() {
      this.isListLoading = false;
    },
    loadList_success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }
      if (this.loadSuccessCallback) {
        this.loadSuccessCallback(data);
      }

      this.data = data;
      this.loadList_end();
      this.searchResultSlotVisible = true;
      this.topSlotVisible = true;
    },
    loadList_fault(message) {
      this.failureList = message;
      this.loadList_end();
    },

    updatePageParams() {
      this.updateUrlQuery(this.path, this.search, this.params, this.catalog);
    }
  }
}
</script>