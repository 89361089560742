<template>
  <kst-section collapsible collapsed
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
  >
    <kst-input field="IsNeedDelivery" :data="userData" :catalog="Catalog"
      @input="handleIsNeedDelivery_input"
    />
    <template v-if="userData.IsNeedDelivery">
      <kst-input field="DeliveryDate" :data="userData" :catalog="Catalog"
        :customValidations="deliveryDateValidations"
      />
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    deliveryDateValidations() {
      return Model.New.validationDeliveryDate(this.userData);
    },
    sectionTitle() {
      return Info.Section.Delivery.Title;
    },
    sectionHelp() {
      return Model.getDeliverySectionHelp(this.userData);
    }
  },
  methods: {
    handleIsNeedDelivery_input() {
      Model.updateDetailsByDelivery(this.userData);
    }
  }
}
</script>