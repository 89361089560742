<template>
  <kst-table view-mode :data="list">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="ItemID"
      :label="Catalog.ItemID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ItemName"
      :label="Catalog.ItemName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PackagingName"
      :label="Catalog.PackagingName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="PackagingQty"
      :label="Catalog.PackagingQty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="Qty"
      :label="Catalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Message"
      :label="Catalog.Message.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../StopItemImportCatalog.js";

export default {
  props: {
    list: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>