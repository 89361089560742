<template>
  <div>
    <Summary :summaryData="summaryData"/>

    <kst-section>
      <form novalidate @submit.prevent="debounceSearch">
        <div class="columns is-mobile">
          <div class="column is-half pt-0">
            <kst-input field="ValueAvg" :data="chartData" :catalog="PlosChartCatalog"
              @input="handleInput"
            />
          </div>
        </div>

        <div class="is-hidden">
          <input type="submit" value="Cari" class="is-hidden">
        </div>
      </form>

      <template v-if="chartEnabled">
        <Chart
          :chartData="chartData"
          :searchData="searchData"
        />
      </template>
    </kst-section>
  </div>
</template>

<script>
import SearchMixin from "../../../mixins/SearchMixin.js";
import PlosChartCatalog from "../PlosChartCatalog.js";

import Chart from "./PlosR_Result_Chart.vue";
import Summary from "./PlosR_Result_Summary.vue";

export default {
  mixins: [SearchMixin],
  components: {
    Chart,
    Summary
  },
  props: {
    chartData: Object,
    summaryData: Object,
    searchData: Object
  },
  data: () => ({
    PlosChartCatalog: PlosChartCatalog,
    chartEnabled: true
  }),
  created() {
    this.initSearch(this.chartData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.handleValueAvg();
    },

    handleValueAvg() {
      if (this.chartCanvas !== null) {
        this.chartEnabled = false;

        this.$nextTick(() => {
          this.chartEnabled = true;
        });
      }
    }
  }
}
</script>