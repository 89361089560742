import App from "../services/App/App.js";

export default {
  methods: {
    /**
     * run http requests (promise based)
     * @param {Object} config - request metadata
     * - {String} method (required)
     * - {String} url (required)
     * - {String} param (optional)
     * - {Object} data (required for post/put method)
     * - method enumeration, see: Axios.Method in "@/services/App/App_Enum.js"
     * - param creation, use: getParams in "@/services/App/App_Output.js"
     * @param {any} params - to be returned in all callbacks
     * @param {Object} successCallback
     * @param {Object} errorCallback
     * @param {Object} invalidCallback
     * @param {Object} callback
     * @param {Boolean} isLoadPage
     */
    runAxios(config, params, successCallback, errorCallback, invalidCallback, 
      callback, isLoadPage
    ) {
      // request header
      let headers = {};
      if (isLoadPage) {
        headers[App.Enum.Request.Header.UIVersion] = App.View.getVersion();
      }

      this.axios({
        method: config.method,
        url: config.param ? config.url + config.param : config.url,
        data: config.data,
        withCredentials: true,
        headers: headers
      })
      .then(function (response) {
        const data = response.data;

        if (data.invalid) {
          // handle data invalid (API validation)
          // validation error message (API), i.e. Name is required
          if (invalidCallback) {
            invalidCallback(
              Array.isArray(data.invalid) ? data.invalid : [data.invalid],
              params,
              response
            );
          }
          return;
        }
        else if (data.error) {
          // handle error (API)
          // system generated error (API), i.e. connect ETIMEDOUT
          if (errorCallback) {
            errorCallback(
              Array.isArray(data.error) ? data.error : [data.error],
              params,
              response
            );
          }
          return;
        }
        else {
          // handle success
          if (successCallback) {
            successCallback(response.data, params, response);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (errorCallback) {
          errorCallback([error.message], params, error);
        }
      })
      .then(function () {
        // always executed
        if (callback) {
          callback(params);
        }
      });      
    }
  }
}