<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="SlsItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="SlsItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="SlsItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="SlsItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="SlsItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="SlsItemCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlsItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import SlsItemCatalog from "../SlsItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    SlsItemCatalog: SlsItemCatalog
  })
}
</script>