<template>
  <div class="kst-loading has-text-centered">
    <img src="@/assets/loading.svg" alt="Loading">
    <p class="is-size-5">
      {{ theText }}
    </p>
  </div>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  computed: {
    theText() {
      return AppCatalog.Message.Wait;
    }
  },
}
</script>
