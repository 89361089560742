import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import DbcdEx from "./Export/DbcdEx.vue";
import DbcdP from "./Print/DbcdP.vue";
import DbcdR from "./Report/DbcdR.vue";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.DebtCard)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DebtCard, AppModuleType.Export),
        name: RouterModel.getName(AppModule.DebtCard, AppModuleType.Export),
        component: DbcdEx,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtCard, AppModuleType.Export),
          module: RouterModel.getModule(AppModule.DebtCard),
          moduleattr: AppModuleAttr.DebtCard_Act_Export,
          moduletype: AppModuleType.Export
        }
      },
      {
        path: RouterModel.getPath(AppModule.DebtCard),
        name: RouterModel.getName(AppModule.DebtCard),
        component: DbcdR,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtCard),
          module: RouterModel.getModule(AppModule.DebtCard),
          moduleattr: AppModuleAttr.DebtCard_Act_Report,
          moduletype: AppModuleType.Report,
          // actions
          export: {
            module: RouterModel.getModule(AppModule.DebtCard),
            moduleattr: AppModuleAttr.DebtCard_Act_Export,
            moduletype: AppModuleType.Export
          },
          print: {
            module: RouterModel.getModule(AppModule.DebtCard),
            moduleattr: AppModuleAttr.DebtCard_Act_Print,
            moduletype: AppModuleType.Print
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.DebtCard, AppModuleType.Print),
        name: RouterModel.getName(AppModule.DebtCard, AppModuleType.Print),
        component: DbcdP,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.DebtCard, AppModuleType.Print),
          module: RouterModel.getModule(AppModule.DebtCard),
          moduleattr: AppModuleAttr.DebtCard_Act_Print,
          moduletype: AppModuleType.Print
        }
      }
    ];
  }
}