import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RinvCatalog.js";
import RinvEnum from "./RinvEnum.js";
import RinvItemCatalog from "./RinvItemCatalog.js";

export default {
  // required in model
  Module: AppModule.RtnInvoice,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintList: AppModuleType.PrintList,
    PrintDetails: AppModuleType.PrintDetails,
    PrintThermal: AppModuleType.PrintThermal
  },

  /*** related ***/

  RtnInvoiceItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  // other
  Item: {
    Module: AppModule.Item
  },

  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.RtnInvoice_Act_Details,
    Act_Edit: AppModuleAttr.RtnInvoice_Act_Edit,
    Act_List: AppModuleAttr.RtnInvoice_Act_List,
    Act_New: AppModuleAttr.RtnInvoice_Act_New,
    Act_PrintDetails: AppModuleAttr.RtnInvoice_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.RtnInvoice_Act_PrintList,
    Act_PrintThermal: AppModuleAttr.RtnInvoice_Act_PrintThermal,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.PrintThermal
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.PrintThermal
    ]
  },

  DiscType: RinvEnum.DiscType,

  Search: {
    ListFields: ["SONumber", "ReturnNumber"],
    ListParams: ["Search", "ClientID", "ClientName", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails(discTypeEnum) {
    return {
      ID: null,
      // user input
      WarehouseID: null,
      ReturnNumber: "",
      SalesOrderID: null,
      Comment: "",
      DiscType: discTypeEnum.None.ID,
      IsAutoNumber: true,
      // by system
      SONumber: null,
      SalesOrderDate: null,
      Date: App.In.getDateToday(),
      ClientID: null,
      ClientAlias: null,
      ClientName: null,
      ClientAddress: null,
      DiscValue: "",
      DiscPercent: "",
      // computed
      TotalBruto: 0,
      TotalDisc: 0,
      Total: 0
    };
  },
  createItem() {
    return {
      ID: null,
      // user input
      DispatchID: null,
      Quantity: "",
      // defined by system
      DispatchName: null,
      DispatchIsActive: true,
      SKU: null,
      PackagingName: "",
      PackagingValue: 1,
      SellPrice: "",
      Index: 0,
      // computed
      Packaging: null,
      Total: 0,
      // error
      Errors: [],
      ErrorsColl: [],
      // UI validation
      DispatchID_Valid: true,
      InvoiceItemQty: 0
    }
  },
  createDiscTypeOptions(optionAllText, discTypeEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const dataList = [
      { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
      { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
      { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  setItemErrors(item, field, errors) {
    // populate error in current field
    item.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    // custom error message
    if (!item.DispatchID_Valid) {
      if (!item.DispatchIsActive) {
        newErrors.push(RinvItemCatalog.DispatchID.Label + " tidak aktif");
      }
    }

    item.Errors = newErrors;
  },

  getInvoiceSectionHelp(data) {
    return Catalog.SONumber.Label + 
      ": " + data.SONumber +
      ", " + Catalog.SalesOrderDate.Label + 
      ": " + App.Value.getValue("SalesOrderDate", data, Catalog)
  },

  updateDetails(data, items, discTypeEnum) {
    let total = 0;

    // TotalBruto
    for (const item of items) {
      total += item.Total;
    }
    data.TotalBruto = total;
    
    // TotalDisc
    if (data.DiscType === discTypeEnum.Value.ID) {
      data.TotalDisc = App.JS.parseInt(data.DiscValue);
      total -= data.TotalDisc;
    }
    else if (data.DiscType === discTypeEnum.Percent.ID) {
      data.TotalDisc = App.Data.getDiscPercent_Value(total, data.DiscPercent);
      total -= data.TotalDisc;
    }

    // Total
    data.Total = total;
  },
  updateItem(item) {
    item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    item.Total = App.Data.getTotal(item.Quantity, item.SellPrice);
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItem(item) {
    item.DispatchID_Valid = true;

    if (!item.DispatchIsActive) {
      item.DispatchID_Valid = false;
    }
  },

  populateDetails(userData, discTypeEnum) {
    return {
      ID: userData.ID,
      WarehouseID: userData.WarehouseID,
      ReturnNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ReturnNumber),
      SalesOrderID: userData.SalesOrderID,
      Date: App.Out.getDateString(userData.Date),
      DiscValue: userData.DiscType === discTypeEnum.Value.ID
        ? App.Out.getInteger(userData.DiscValue) : null,
      DiscPercent: userData.DiscType === discTypeEnum.Percent.ID
        ? App.Out.getDecimal(userData.DiscPercent) : null,
      Comment: App.Out.getString(userData.Comment)
    }
  },
  populateItems(items) {
    let resultItems = [];

    for (const item of items) {
      resultItems.push({
        ID: item.ID,
        DispatchID: item.DispatchID,
        DispatchName: item.DispatchName,
        Quantity: App.Out.getInteger(item.Quantity),
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        SellPrice: App.Out.getInteger(item.SellPrice)
      });
    }

    return resultItems;
  },

  /*** related ***/

  getInvoiceItems_ForSelection(items, invoiceItems) {
    let resultItems = [];
    let item;

    for (const invoiceItem of invoiceItems) {
      item = App.Array.searchItem(items, "Index", invoiceItem.Index);
      if (!item) {
        resultItems.push(invoiceItem);
      }
    }

    return resultItems;
  }
}