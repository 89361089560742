<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details :userData="userData"/>
    <Addresses :userAddressList="userAddressList"/>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../VenModel.js";
import Services from "../../../services/Api/VendorService.js";

import Details from "./VenN_Details.vue";
import Addresses from "./VenN_AddressList.vue";

export default {
  components: {
    Details,
    Addresses
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userAddressList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userAddressList);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userAddressList = [];
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>