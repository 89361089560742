<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RccdCatalog.js";
import Info from "../RccdInfo.js";
import Model from "../RccdModel.js";
import RccdTransCatalog from "../RccdTransCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addRecCardList(data.List);

      this.download(fileName);
    },

    addRecCardList(dataList) {
      Model.updateList(dataList);

      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.CreatedDate);
      this.addColumnHeader(Catalog.TypeID);
      this.addColumnHeader(Catalog.TransactionNumber);
      this.addColumnHeader(Catalog.TransactionDate);
      this.addColumnHeader_ID(RccdTransCatalog.TransWarehouseID.Label);
      this.addColumnHeader(RccdTransCatalog.TransWarehouseID);
      this.addColumnHeader_ID(RccdTransCatalog.InvoiceID.Label);
      this.addColumnHeader(RccdTransCatalog.InvoiceID);
      this.addColumnHeader(RccdTransCatalog.InvoiceDate);
      this.addColumnHeader(RccdTransCatalog.InvoiceRecBefore);
      this.addColumnHeader(RccdTransCatalog.InvoiceRecChanges);
      this.addColumnHeader(RccdTransCatalog.InvoiceRecAfter);
      this.addColumnHeader_ID(RccdTransCatalog.ClientID.Label);
      this.addColumnHeader(RccdTransCatalog.ClientID);
      this.addColumnHeader(RccdTransCatalog.ClientRecBefore);
      this.addColumnHeader(RccdTransCatalog.ClientRecChanges);
      this.addColumnHeader(RccdTransCatalog.ClientRecAfter);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("CreatedDate", row, Catalog);
        this.addCellRelatedValue("TypeID", row, Catalog);
        this.addCellValue("TransactionNumber", row, Catalog);
        this.addCellValue("TransactionDate", row, Catalog);
        this.addCellValue("TransWarehouseID", row, RccdTransCatalog);
        this.addCellRelatedValue("TransWarehouseID", row, RccdTransCatalog);
        this.addCellValue("InvoiceID", row, RccdTransCatalog);
        this.addCellRelatedValue("InvoiceID", row, RccdTransCatalog);
        this.addCellValue("InvoiceDate", row, RccdTransCatalog);
        this.addCellValue("InvoiceRecBefore", row, RccdTransCatalog);
        this.addCellValue("InvoiceRecChanges", row, RccdTransCatalog);
        this.addCellValue("InvoiceRecAfter", row, RccdTransCatalog);
        this.addCellValue("ClientID", row, RccdTransCatalog);
        this.addCellRelatedValue("ClientID", row, RccdTransCatalog);
        this.addCellValue("ClientRecBefore", row, RccdTransCatalog);
        this.addCellValue("ClientRecChanges", row, RccdTransCatalog);
        this.addCellValue("ClientRecAfter", row, RccdTransCatalog);
      }

      this.addSheet(Info.Export.Excel.SheetName.RecCard);
    }
  }
}
</script>