import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./HpadModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.HppAdj.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.HppAdj.FullName,
          HppAdjItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.HppAdjItem.Module.FullName + "?"
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}