import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import AcsEnum from "./AcsEnum.js";
import ModuleAttrModel from "../ModuleAttr/ModuleAttrModel.js";

export default {
  /*** related ***/

  AccessModule: {
    Module: AppModule.Module,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  User: {
    Module: AppModule.UserList,
    ModuleType: {}
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Access_Act_Details,
    Act_Edit: AppModuleAttr.Access_Act_Edit,
    Act_List: AppModuleAttr.Access_Act_List,
    Act_New: AppModuleAttr.Access_Act_New
  },

  Actions: {
    Details: [
      AppModuleType.Edit
    ]
  },

  IsActive: AcsEnum.IsActive,

  Search: {
    Fields: ["Name"]
  },

  /*** method ***/

  createDetails(isActiveEnum) {
    return {
      ID: null,
      Name: "",
      IsActive: App.In.getBoolean(isActiveEnum.Active.ID),
      Comment: "",
      // defined by system
      HasUser: false
    };
  },
  createIsActiveOptions(optionAllText, isActiveEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: isActiveEnum.Active.ID, [rowLabel]: isActiveEnum.Active.Label },
      { [rowId]: isActiveEnum.InActive.ID, [rowLabel]: isActiveEnum.InActive.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  selectListByModuleAtrr(userModuleList, moduleList, moduleAttr) {
    const module = App.Array.searchItem(moduleList, "ID", moduleAttr.ModuleID);

    if (!userModuleList.includes(module)) {
      userModuleList.push(module);
    }
  },

  updateModulesBySelection(userModuleAttrList, checkedList, row) {
    // if: check/uncheck all
    if (row === undefined) {
      if (checkedList.length === 0) {
        ModuleAttrModel.unselectAllList(userModuleAttrList);
      }
      else {
        ModuleAttrModel.selectAllList(userModuleAttrList);
      }
    }
    // else: check/uncheck row
    else {
      if (checkedList.includes(row)) {
        ModuleAttrModel.selectListByModule(userModuleAttrList, row.ID);
      }
      else {
        ModuleAttrModel.unselectListByModule(userModuleAttrList, row.ID);
      }
    }
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      Name: App.Out.getString(userData.Name),
      IsActive: App.Out.getBoolean(userData.IsActive),
      Comment: App.Out.getString(userData.Comment)
    }
  },
  populateModules(userModuleList) {
    let resultList = [];

    for (const userModule of userModuleList) {
      resultList.push({
        ModuleID: userModule.ID,
        ModuleName: userModule.Name
      });
    }

    return resultList;
  },
  populateModuleAttrs(userModuleAttrList, userModuleList) {
    let moduleIdList = [], resultList = [];

    for (const userModule of userModuleList) {
      moduleIdList.push(userModule.ID);
    }

    let isValid;
    for (const userModuleAttr of userModuleAttrList) {
      if (userModuleAttr.IsSelected) {
        isValid = false;

        // if: global module attr
        if (userModuleAttr.ModuleID === null) {
          isValid = true;
        }
        else if (moduleIdList.includes(userModuleAttr.ModuleID)) {
          isValid = true;
        }

        if (isValid) {
          resultList.push({
            ModuleAttrID: userModuleAttr.ID,
            ModuleAttrName: userModuleAttr.Name
          });
        }
      }
    }

    return resultList;
  }
}