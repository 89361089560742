<template>
  <kst-form no-bezel class="block" v-if="access.Act_New">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :label="Info.Button.InvoiceReplace.Label"
        :module="Info.Button.InvoiceReplace.Module"
        :moduleType="Info.Button.InvoiceReplace.ModuleType.New"
        :urlParams="urlParams"
      />
    </template>
  </kst-form>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    transactionId: Number
  },
  data: () => ({
    Info: Info,
    Model: Model,
    access: {}
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [Catalog.TransactionID.Param]: this.transactionId
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>