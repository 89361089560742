import Info from "./CpInfo.js";
import ModelBody from "./CpModel_Body.js";
import ModelDetails from "./CpModel_Details.js";
import ModelEdit from "./CpModel_Edit.js";
import ModelSetting from "./CpModel_Setting.js";
import ModelEmailReminder from "./CpModel_EmailReminder.js";
import ModelSpv from "./CpModel_Spv.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  // related
  Price: ModelBody.Price,
  Email: ModelBody.Email,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,

  /*** method ***/

  populateData(userData, userDebtEmailList, userLowStockEmailList, userRecEmailList){
    let data = ModelBody.populateData(userData);
    data.DebtEmail = ModelEmailReminder.populateList(userDebtEmailList);
    data.LowStockEmail = ModelEmailReminder.populateList(userLowStockEmailList);
    data.RecEmail = ModelEmailReminder.populateList(userRecEmailList);
    return data;
  },
  createDetails(){
    return ModelBody.createDetails();
  },
  createEmailDetails() {
    return ModelEmailReminder.createDetails();
  },
  createSettingOptions(settingList, optionAllText) {
    return ModelSetting.createOptions(settingList, optionAllText);
  },
  updateEmailErrors(userEmailList) {
    ModelEmailReminder.getErrors(userEmailList);
  },
  setEmailErrors(userEmailList, index, field, errors) {
    ModelEmailReminder.setErrors(userEmailList, index, field, errors);
  },

  /*** method - spv ***/

  createSpvDetails() {
    return ModelSpv.createDetails();
  },
  populateSpvVerifyData(userData) {
    return ModelSpv.populateVerifyData(userData);
  },

  /*** method - details ***/

  Details: {
    getImageFieldTooltipList() {
      return [
        Info.Message.Image_Tooltips_1,
        Info.Message.Image_Tooltips_2
      ];
    },
    setDetailsByEmailList(details) {
      ModelDetails.setDetailsByEmailList(details);
    }
  },

  /*** method - edit ***/
  
  Edit: {
    getImageFieldTooltipList() {
      return [
        Info.Message.Input + " " + Info.Message.Image_Tooltips_1,
        Info.Message.Image_Tooltips_2,
        Info.Message.Image_Tooltips_3
      ];
    },
    setDetailsByData(userData, details, detailsSetting) {
      ModelEdit.setDetailsByData(userData, details, detailsSetting);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelEdit.setDetailsByWarehouseList(userData, warehouseList);
    },
    setDetailsByEmailList(userEmailList, detailsEmailList) {
      ModelEmailReminder.setDetailsByEmailList(userEmailList, detailsEmailList);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  },
}