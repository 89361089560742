import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import HpadItemCatalog from "./HpadItemCatalog.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    // SKU
    infoList.push(HpadItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", item, HpadItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(HpadItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", item, HpadItemCatalog)
    );
  
    // Packaging
    infoList.push(HpadItemCatalog.Packaging.Label + ": " +
      App.Value.getValue("Packaging", item, HpadItemCatalog)
    );

    return infoList;
  }
}