<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="1000"
    @init="handleModal_Init"
    @load="handleModal_Load"
    @reset="handleModal_Reset"
    @submit="handleModal_Submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :items="data.ItemList"
        :userData="userData"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import Model from "../ItemModel.js";

import Search from "./ItemScL_Search.vue";
import List from "./ItemScL_List.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {
      Search: "",
      // by system
      SelfServiceID: null,
      SelfServicePin: null
    },
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(selfServiceId, selfServicePin, getListService) {
      this.searchData.SelfServiceID = selfServiceId;
      this.searchData.SelfServicePin = selfServicePin;
      this.getListService = getListService;

      this.showFunc();
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_Load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(
        this.searchData.SelfServiceID, this.searchData.SelfServicePin, options
      );
      loadList(config);
    },

    handleModal_Reset(loadList) {
      this.init_Data();
      this.handleModal_Load(loadList);
    },

    handleModal_Submit(selected) {
      this.$emit(this.$kst.Enum.Event.Submit, selected);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields
      };
    },

    init_Data() {
      // searchData
      this.searchData.Search = "";
    }
  }
}
</script>