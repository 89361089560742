<template>
  <kst-table paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      cell-class="ks-col-alias"
      field="Alias"
      :label="Catalog.Alias.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      field="Mobile"
      :label="Catalog.Mobile.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      field="Phone"
      :label="Catalog.Phone.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      field="Email"
      :label="Catalog.Email.Label"
      v-slot="{ column, row }"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../CliCatalog.js";

export default {
  props: {
    items: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>