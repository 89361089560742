<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :categoryOptions="categoryOptions"
      :paymentOptions="paymentOptions"
      :warehouseOptions="warehouseOptions"
    />
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../ExpModel.js";
import Services from "../../../services/Api/ExpenseServices.js";

import Details from "./ExpE_Details.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details
  },
  data: () => ({
    userData: {},
    categoryOptions: {},
    paymentOptions: {},
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.categoryOptions = Model.getExpenseCategoryOptions(
        data.ExpenseSubCatList
      );
      this.paymentOptions = Model.getPaymentOptions(data.PaymentTypeList);
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.Edit.setDetailsByData(this.userData, data.Details);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
    }
  }
}
</script>