<template>
  <div class="kstmod-rtninvoice-relatedlist">
    <template v-if="action">
      <template v-if="access.Act_New">
        <Action :invoiceId="invoiceId"/>
      </template>
    </template>

    <Rows :access="access" :dataList="dataList"/>
  </div>
</template>

<script>
import Model from "../RinvModel.js";

import Rows from "./RinvRL_Rows.vue";
import Action from "../RelatedListAction/RinvRLAction.vue";

export default {
  components: {
    Action,
    Rows
  },
  props: {
    action: Boolean,
    invoiceId: Number,
    dataList: Array
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      for (const row of this.dataList) {
        for (const item of row.Items) {
          Model.updateItem(item);
        }
      }
    }
  }
}
</script>