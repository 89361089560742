<template>
  <kst-container>
    <kst-section list-mode :title="Info.Section.PackagingList.Title">
      <kst-load-data activeOnLoad loadingElement @init="handleLoad_init">
        <List :dataList="dataList"/>
      </kst-load-data>        
    </kst-section>
  </kst-container>
</template>

<script>
import Info from "../ItemInfo.js";
import Services from "../../../services/Api/ItemServices.js";

import List from "./ItemL_List_PackagingList_List.vue";

export default {
  components: {
    List
  },
  props: {
    itemId: Number
  },
  data: () => ({
    Info: Info,
    dataList: [],
    // func
    loadData: null
  }),
  methods: {
    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;
      this.loadList();
    },

    /*** custom ***/

    loadList() {
      const config = Services.getRelatedPackagingList(this.itemId);
      this.loadData(config, this.loadList_success, true);
    },
    loadList_success(data) {
      this.dataList = data.PackagingList;
    }
  }
}
</script>