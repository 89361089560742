<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Purchase :receiveData="receiveData"/>
    <Details :receiveData="receiveData"/>
    <Items :access="access" :receiveData="receiveData" :items="receiveData.Items"/>
    <template v-if="access.Fld_Amount">
      <Total :receiveData="receiveData"/>
    </template>
  </kst-section>
</template>

<script>
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";

import Purchase from "./RcvED_Receive_Purchase.vue";
import Details from "./RcvED_Receive_Details.vue";
import Items from "./RcvED_Receive_Items.vue";
import Total from "./RcvED_Receive_Total.vue";

export default {
  components: {
    Purchase,
    Details,
    Items,
    Total
  },
  props: {
    access: Object,
    receiveData: Object
  },
  computed: {
    sectionHelp() {
      return Model.getReceiveSectionHelp(this.receiveData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    },
  }
}
</script>