import AppModule from "../../services/App/AppModule.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./SlrcCatalog.js";
import Model from "./SlrcModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          InvoiceList: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        },
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          InvoiceList: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + Model.Module.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + Model.Module.FullName,
          Invoice: "Dengan " + AppModule.Invoice.FullName
        }
      }
    }
  },
  Message: {
    New_Warehouse:
      "Tidak diperkenankan mengubah " + Catalog.WarehouseID.Label + ", " +
      "saat sudah memiliki " + Model.Invoice.Module.FullName + ".",
    New_Cashier:
      "Tidak diperkenankan mengubah " + Catalog.CashierID.Label + ", " +
      "saat sudah memiliki " + Model.Invoice.Module.FullName + ".",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Section: {
    Info: {
      Title: AppCatalog.Section.Info
    },
    Invoice: {
      Title: AppModuleType.List.Name + " " + Model.Invoice.Module.FullName
    },
    PaymentTypeSummaryList: {
      Title: AppCatalog.Info.Summary + " " + AppModule.PaymentType.FullName
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    InvoiceList: Model.Invoice.ModuleType.List.Name + " " +
      Model.Invoice.Module.FullName
  }
}