import AppCatalog from "../services/App/AppCatalog.js";
import DialogMixin from "./DialogMixin.js";

export default {
  mixins: [DialogMixin],
  data: () => ({
    routeLeaveEnabled: true
  }),
  beforeRouteLeave(to, from, next) {
    if (this.routeLeaveEnabled) {
      this.showConfirm(AppCatalog.Message.LeavePage, () => {
        next()
      }, () => {
        next(false);
      });
    } else {
      next();
    }
  },
  methods: {
    disableRouteLeave() {
      this.routeLeaveEnabled = false;
    }
  }
}