import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./InvCatalog.js";
import CstEnum from "../CompanySettingType/CstEnum.js";
import InvEnum from "./InvEnum.js";
import InvItemCatalog from "./InvItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  // required in model
  Module: AppModule.Invoice,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    EditDate: AppModuleType.EditDate,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintFaktur: AppModuleType.PrintFaktur,
    PrintList: AppModuleType.PrintList,
    PrintThermal: AppModuleType.PrintThermal,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  InvoiceItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      SearchList: AppModuleType.SearchList
    }
  },

  // other
  Client: {
    Module: AppModule.Client
  },
  Delivery: {
    Module: AppModule.Delivery,
    ModuleType: {
      List: AppModuleType.List
    }
  },
  RecPay: {
    Module: AppModule.RecPay,
    ModuleType: {
      List: AppModuleType.List
    }
  },
  RtnInvoice: {
    Module: AppModule.RtnInvoice,
    ModuleType: {
      List: AppModuleType.List
    }
  },
  Sales: {
    Module: AppModule.Sales,
    ModuleType: {
      List: AppModuleType.List,
      Select: AppModuleType.Select
    }
  },
  SalesItem: {
    Label: AppModule.Item.FullName + " " + AppModule.Sales.FullName,
    ModuleType: {
      New: AppModuleType.New
    }
  },
  SalesRecap: {
    Module: AppModule.SalesRecap,
    ModuleType: {
      List: AppModuleType.List
    }
  },
  StockReduction: {
    Module: AppModule.StockReduction
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Invoice_Act_Details,
    Act_Edit: AppModuleAttr.Invoice_Act_Edit,
    Act_EditDate: AppModuleAttr.Invoice_Act_EditDate,
    Act_ExportDetails: AppModuleAttr.Invoice_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Invoice_Act_ExportList,
    Act_List: AppModuleAttr.Invoice_Act_List,
    Act_New: AppModuleAttr.Invoice_Act_New,
    Act_PrintDetails: AppModuleAttr.Invoice_Act_PrintDetails,
    Act_PrintFaktur: AppModuleAttr.Invoice_Act_PrintFaktur,
    Act_PrintThermal: AppModuleAttr.Invoice_Act_PrintThermal,
    Tab_Profit: AppModuleAttr.Invoice_Tab_Profit,
    Glob_HPP: AppModuleAttr.All_Glob_HPP,
    // related
    Delivery_Act_List: AppModuleAttr.Delivery_Act_List,
    RecPay_Act_List: AppModuleAttr.RecPay_Act_List,
    RtnInvoice_Act_List: AppModuleAttr.RtnInvoice_Act_List,
    SalesRecap_Act_Details: AppModuleAttr.SalesRecap_Act_Details,
    StockReduction_Act_List: AppModuleAttr.StockReduction_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.EditDate,
      AppModuleType.PrintDetails,
      AppModuleType.PrintFaktur,
      AppModuleType.PrintThermal,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.EditDate,
      AppModuleType.PrintDetails,
      AppModuleType.PrintFaktur,
      AppModuleType.PrintThermal,
      AppModuleType.ExportDetails
    ],
    RelatedList: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.EditDate,
      AppModuleType.PrintDetails,
      AppModuleType.PrintFaktur,
      AppModuleType.PrintThermal,
      AppModuleType.ExportDetails
    ]
  },

  DiscType: InvEnum.DiscType,
  RecordType: InvEnum.RecordType,

  Search: {
    ListFields: ["SONumber", "PONumber"],
    ListParams: ["Search", "InvoiceDate", "Status", "WarehouseID",
      "Client", "ClientID", "RecordTypeID"],
    SearchListFields: ["SONumber", "PONumber", "Client"]
  },

  Status: InvEnum.Status,
  StatusSalesDraftIsActive: InvEnum.StatusSalesDraftIsActive,
  StatusPos: InvEnum.StatusPos,

  // related
  InvoiceClientSourceID: CstEnum.InvoiceClientSourceID,

  /*** method ***/

  createDetails(discTypeEnum) {
    return {
      ID: null,
      // user input
      SalesDraftID: null,
      WarehouseID: App.Session.getDefaultWarehouseID(),
      SONumber: "",
      InvoiceDate: App.In.getDateToday(),
      IsAutoNumber: true,
      PONumber: "",
      ClientID: null,
      Client: "",
      ClientAddress: "",
      DiscType: discTypeEnum.None.ID,
      DiscValue: "",
      DiscPercent: "",
      Comment: "",
      PaymentTypeName: null,
      PaymentValue: "",
      DueDate: App.In.getDateToday(),
      IsNeedDelivery: false,
      DeliveryDate: App.In.getDateToday(),
      TransactionID: null,
      // by system
      WarehouseName: null,
      ClientAlias: "",
      ClientCreditAmount: 0,
      ClientOverDueDate: null,
      ClientRecTotal: 0,
      ClientSourceID: CstEnum.InvoiceClientSourceID.MasterClient_FreeText.ID,
      DraftNumber: "",
      DraftDate: null,
      SalesDraftIsActive: false,
      SpecialPriceCount: 0,
      TransactionNumber: null,
      TransactionDate: null,
      TransactionVisible: false,
      // computed
      TotalBruto: 0,
      TotalDisc: 0,
      Total: 0,
      // support
      ItemKeyIndex: 0,
      CurrentDate: App.In.getDateToday(),
      // UI validation
      InvalidItems_vsStock: null,
      StockMissingQty: 0,
      InvalidItems_vsDelivery: null,
      DeliveryMissingQty: 0,
      ClientDiscPercent_MessageCredit: 0 // indicator for showing field message
    };
  },
  createItem(userData) {
    const result = {
      SalesDraftItemID: null,
      SalesDraftItemQty: null,
      // user input
      DispatchID: null,
      Name: "",
      RequestedQuantity: "",
      PackagingName: "",
      SellPrice: "0",
      SpecialPriceID: App.Search.OptionNone,
      // by system
      SKU: "",
      PackagingValue: 1,
      StockQty: 0,
      DispatchSellPrice: 0,
      DispatchIsActive: true,
      PackagingOptions: null,
      DeliveryQuantity: 0,
      SpecialPriceName: "",
      SpecialPriceDiscPercent: 0,
      SpecialPriceOptions: {},
      SpecialPriceQty: 0,
      // computed
      Packaging: "",
      Price: 0,
      TotalRequestedQuantity: 0,
      Stock: 0,
      SpecialPriceStock: 0,
      // support
      Key: userData.ItemKeyIndex,
      // error
      Errors: [],
      ErrorsColl: {},
      // validation
      CustomValidations: {}
    };

    userData.ItemKeyIndex++;
    return result;
  },
  createRecordTypeOptions(optionAllText, recordTypeEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const dataList = [
      { [rowId]: recordTypeEnum.Invoice.ID, [rowLabel]: recordTypeEnum.Invoice.Label },
      { [rowId]: recordTypeEnum.Pos.ID, [rowLabel]: recordTypeEnum.Pos.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
      { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createDiscTypeOptions(optionAllText, discTypeEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: discTypeEnum.None.ID, [rowLabel]: discTypeEnum.None.Label },
      { [rowId]: discTypeEnum.Value.ID, [rowLabel]: discTypeEnum.Value.Label },
      { [rowId]: discTypeEnum.Percent.ID, [rowLabel]: discTypeEnum.Percent.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createSpecialPriceOptions(stockSpecialPriceList, optionNonText) {
    const rowId = "SpecialPriceID";
    const rowLabel = "SpecialPriceName";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionNonText) {
      listOptions.nonActive = true;
      listOptions.nonText = optionNonText;
    }

    // create: select rows
    const selectRows = App.Search.createList(stockSpecialPriceList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  setDetailsByCompanySetting(userData, companySetting) {
    userData.ClientSourceID = companySetting.InvoiceClientSourceID;
  },
  setDetailsBySpecialPrice(userData, specialPriceCount) {
    userData.SpecialPriceCount = specialPriceCount;
  },

  normalizeDetailsByDisc(userData) {
    userData.DiscPercent = App.In.getDecimal(userData.DiscPercent);
  },

  setDetailsByStatus(details, statusEnum) {
    details.StatusName = App.Data.getStatusLabel(details.Status, statusEnum);
  },
  setItemByPrice(userItem, priceData) {
    // user input
    userItem.PackagingName = priceData.PackagingName;
    userItem.SellPrice = App.In.getInteger(priceData.SellPrice);
    // defined by system
    userItem.PackagingValue = priceData.PackagingValue;

    for (const packaging of userItem.PackagingOptions.rows) {
      if (packaging.Name === userItem.PackagingName) {
        userItem.DispatchSellPrice = packaging.SellPrice;
        break;
      }
    }
  },
  setItemByStock(userItem, stockDetails, itemDetails, stockSpecialPriceList) {
    // user input
    userItem.DispatchID = itemDetails.ID;
    userItem.Name = App.In.getString(itemDetails.Name);
    userItem.PackagingName = stockDetails.PackagingName;
    userItem.SellPrice = App.In.getInteger(stockDetails.SellPrice);
    // defined by system
    userItem.SKU = itemDetails.SKU;
    userItem.PackagingValue = stockDetails.PackagingQty;
    userItem.StockQty = stockDetails.QuantityPcs;
    userItem.DispatchSellPrice = stockDetails.SellPrice;
    userItem.DispatchIsActive = App.In.getBoolean(itemDetails.IsActive);
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemDetails.PackagingList
    );
    userItem.SpecialPriceOptions = this.createSpecialPriceOptions(
      stockSpecialPriceList, InvItemCatalog.SpecialPriceID.Label
    );
  },

  getItemsForSelection(items, salesItems) {
    let resultItems = [];
    let item;

    for (const salesItem of salesItems) {
      item = App.Array.searchItem(items, "SalesDraftItemID", salesItem.ID);
      if (!item) {
        resultItems.push(salesItem);
      }
    }

    return resultItems;
  },
  getInvalidItems(details) {
    let items = [];

    if (details.InvalidItems_vsStock) {
      for (const invalidItem of details.InvalidItems_vsStock) {
        let stockLabel = (invalidItem.stockLabel) ? 
          InvItemCatalog.Stock.Label : 
          InvItemCatalog.SpecialPriceStock.Label;

        items.push(
          App.Data.getInvalidStockError(
            invalidItem.TotalRequestedQuantity,
            invalidItem.Stock,
            invalidItem.Name,
            stockLabel
          )
        );
      }
    }

    if (details.InvalidItems_vsDelivery) {
      for (const invalidItem of details.InvalidItems_vsDelivery) {
        items.push(invalidItem.Name + " memiliki kekurangan " +
          Catalog.DeliveryMissingQty.Label + ": " +
          App.Value.getValue("RequestedQuantity", invalidItem, InvItemCatalog) +
          " " + invalidItem.Packaging
        );
      }
    }

    return items;
  },

  getRecPaySectionHelp(userData) {
    return Catalog.DueDate.Label + ": " +
      App.Value.getValue("DueDate", userData, Catalog) +
      ( userData.PaymentTypeName === App.Search.OptionNone 
        ? ""
        : ", " + Catalog.PaymentTypeName.Label + ": " +
          userData.PaymentTypeName + ", " + Catalog.PaymentValue.Label + ": " +
          App.Value.getValue("PaymentValue", userData, Catalog)
      )
    ;
  },
  getDeliverySectionHelp(data) {
    return Catalog.IsNeedDelivery.Label + 
      ": " + App.Value.getValue("IsNeedDelivery", data, Catalog) + 
      (data.IsNeedDelivery 
        ? ", " + Catalog.DeliveryDate.Label + 
          ": " + App.Value.getValue("DeliveryDate", data, Catalog)
        : ""
      );
  },
  getSalesSectionHelp(data) {
    if (data.SalesDraftID !== null && !data.SalesDraftIsActive) {
      return Catalog.SalesDraftID.Label + ": " + data.DraftNumber + 
        " " + AppCatalog.Info.DeletedData;
    }

    return data.SalesDraftID === null
      ? AppCatalog.Message.Without + " " + AppModule.Sales.FullName
      : Catalog.SalesDraftID.Label + ": " + data.DraftNumber + ", " +
        Catalog.DraftDate.Label + ": " +
        App.Value.getValue("DraftDate", data, Catalog);
  },
  getInvoiceSectionHelp(data) {
    return Catalog.SONumber.Label + ": " +
      App.Value.getValue("SONumber", data, Catalog) + ", " +
      Catalog.InvoiceDate.Label + ": " +
      App.Value.getValue("InvoiceDate", data, Catalog);
  },

  getInvoiceDateDisabled() {
    return App.Session.getBackDateInterval() === 0
  },

  updateDetails(userData, userItems, discTypeEnum) {
    let total = 0;

    // TotalBruto
    for (const item of userItems) {
      total += item.Price;
    }
    userData.TotalBruto = total;

    // TotalDisc
    if (userData.DiscType === discTypeEnum.Value.ID) {
      userData.TotalDisc = App.JS.parseInt(userData.DiscValue);
      total -= userData.TotalDisc;
    }
    else if (userData.DiscType === discTypeEnum.Percent.ID) {
      userData.TotalDisc = App.Data.getDiscPercent_Value(
        total, userData.DiscPercent
      );
      total -= userData.TotalDisc;
    }

    // Total
    userData.Total = total;
  },
  updateDetailsByDelivery(userData) {
    if (userData.IsNeedDelivery) {
      if (userData.DeliveryDate === null) {
        userData.DeliveryDate = App.In.getDateToday();
      }
    }
  },

  updateItem(item) {
    item.Packaging = App.Data.getPackaging(
      item.PackagingName, item.PackagingValue
    );
    item.Stock = App.Data.getQtyByPackaging(
      item.StockQty, item.PackagingValue
    );
    item.TotalRequestedQuantity = App.Data.getTotalQty(
      item.RequestedQuantity,
      item.PackagingValue
    );
    item.Price = App.Data.getTotal(
      item.RequestedQuantity,
      item.SellPrice
    );
    item.SpecialPriceStock = App.Data.getQtyByPackaging(
      item.SpecialPriceQty, item.PackagingValue
    );
  },
  updateItemBySpecialPrice(item) {
    if (item.SpecialPriceID === App.Search.OptionNone) {
      item.SpecialPriceName = App.Search.LabelNone +
        InvItemCatalog.SpecialPriceID.Label;
      item.SpecialPriceDiscPercent = 0;
      item.SpecialPriceQty = 0;
      item.SellPrice = item.DispatchSellPrice;
    }
    else {
      const dispatchSpecialPrice = App.Array.searchItem(
        item.SpecialPriceOptions.rows, "SpecialPriceID", item.SpecialPriceID
      );

      item.SpecialPriceName = dispatchSpecialPrice.SpecialPriceName;
      item.SpecialPriceDiscPercent = dispatchSpecialPrice.DiscPercent;
      item.SpecialPriceQty = dispatchSpecialPrice.Qty;

      const specialPriceDiscAmount = App.Data.getDiscPercent_Value(
        item.DispatchSellPrice, item.SpecialPriceDiscPercent
      );
      item.SellPrice = App.In.getInteger(
        item.DispatchSellPrice - specialPriceDiscAmount
      );
    }
  },
  updateItemByPackaging(item) {
    if (item.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        item.PackagingOptions.rows, item.PackagingName
      );

      if (packagingData) {
        // defined by system
        item.PackagingValue = packagingData.Qty;
        item.DispatchSellPrice = packagingData.SellPrice;

        const specialPriceDiscAmount = App.Data.getDiscPercent_Value(
          item.DispatchSellPrice, item.SpecialPriceDiscPercent
        );
        item.SellPrice = App.In.getInteger(
          item.DispatchSellPrice - specialPriceDiscAmount
        );
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData, discTypeEnum) {
    return {
      ID: userData.ID,
      SalesDraftID: userData.SalesDraftID,
      WarehouseID: userData.WarehouseID,
      SONumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.SONumber),
      InvoiceDate: App.Out.getDateString(userData.InvoiceDate),
      PONumber: App.Out.getString(userData.PONumber),
      ClientID: userData.ClientID,
      Client: App.Out.getString(userData.Client),
      ClientAddress: App.Out.getString(userData.ClientAddress),
      DiscValue: (userData.DiscType === discTypeEnum.Value.ID
        ? parseInt(userData.DiscValue) : null),
      DiscPercent: (userData.DiscType === discTypeEnum.Percent.ID
        ? parseFloat(userData.DiscPercent) : null),
      Comment: App.Out.getString(userData.Comment),
      PaymentTypeName: userData.PaymentTypeName === App.Search.OptionNone
        ? null : userData.PaymentTypeName,
      PaymentValue: userData.PaymentTypeName === App.Search.OptionNone
        ? null : App.Out.getInteger(userData.PaymentValue),
      DueDate: App.Out.getDateString(userData.DueDate),
      IsNeedDelivery: App.Out.getBoolean(userData.IsNeedDelivery),
      DeliveryDate: (userData.IsNeedDelivery
        ? App.Out.getDateString(userData.DeliveryDate) : null),
      SalesDraftIsActive: App.Out.getBoolean(userData.SalesDraftIsActive),
      CurrentDate: App.Out.getDateString(userData.CurrentDate),
      TransactionID: userData.TransactionID,
      TransactionNumber: userData.TransactionNumber,
      TransactionDate: App.Out.getDateString(userData.TransactionDate),
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        SalesDraftItemID: userItem.SalesDraftItemID,
        DispatchID: userItem.DispatchID,
        Name: App.Out.getString(userItem.Name),
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        RequestedQuantity: App.Out.getInteger(userItem.RequestedQuantity),
        DispatchSellPrice: userItem.DispatchSellPrice,
        SellPrice: App.Out.getInteger(userItem.SellPrice),
        SpecialPriceID: userItem.SpecialPriceID === App.Search.OptionNone
          ? null : userItem.SpecialPriceID,
        SpecialPriceName: userItem.SpecialPriceID === App.Search.OptionNone
          ? null : userItem.SpecialPriceName,
        SpecialPriceDiscPercent: userItem.SpecialPriceID === App.Search.OptionNone
          ? null : userItem.SpecialPriceDiscPercent
      });
    }

    return resultItems;
  },

  validateItemsByStock(userData, userItems) {
    let activeItems = [];

    // reset indicator
    userData.StockMissingQty = 0;

    userData.InvalidItems_vsStock = null;
    let invalidItems = [];

    // filter: deleted-item
    for (const item of userItems) {
      if (item.DispatchIsActive) {
        activeItems.push(item);
      }
    }

    this.validateItemsByStock_Stock(userData, activeItems, invalidItems);
    this.validateItemsByStock_SpecialPrice(userData, activeItems, invalidItems);

    if (invalidItems.length > 0) {
      userData.InvalidItems_vsStock = invalidItems;
    }
  },
  validateItemsByStock_Stock(userData, activeItems, invalidItems) {
    let validItems = activeItems;

    const fieldKeys = "DispatchID";
    const fieldValues = [
      { field: "TotalRequestedQuantity", type: App.Pivot.Type.SUM },
      { field: "StockQty", type: App.Pivot.Type.FIRST },
      { field: "Name", type: App.Pivot.Type.FIRST },
    ];
    const pivotItems = App.Pivot.create(validItems, fieldKeys, fieldValues);

    let stockQty = 0;
    for (const pivotItem of pivotItems) {
      stockQty = pivotItem.StockQty;
      if (pivotItem.TotalRequestedQuantity > stockQty) {
        userData.StockMissingQty = 1;
        invalidItems.push({
          Name: pivotItem.Name,
          TotalRequestedQuantity: pivotItem.TotalRequestedQuantity,
          Stock: stockQty,
          stockLabel: true
        });
      }
    }
  },
  validateItemsByStock_SpecialPrice(userData, activeItems, invalidItems) {
    let item, validItems = [];
    for (item of activeItems) {
      if (item.SpecialPriceID !== App.Search.OptionNone) {
        validItems.push(item);
      }
    }

    const fieldKeys = ["DispatchID", "SpecialPriceID"];
    const fieldValues = [
      { field: "TotalRequestedQuantity", type: App.Pivot.Type.SUM },
      { field: "SpecialPriceName", type: App.Pivot.Type.FIRST },
      { field: "Name", type: App.Pivot.Type.FIRST },
      { field: "SpecialPriceQty", type: App.Pivot.Type.FIRST },
      { field: "SpecialPriceStock", type: App.Pivot.Type.FIRST },
    ];
    const pivotItems = App.Pivot.create(validItems, fieldKeys, fieldValues);

    let stockQty = 0;
    for (const pivotItem of pivotItems) {
      stockQty = pivotItem.SpecialPriceQty;
      if (pivotItem.TotalRequestedQuantity > stockQty) {
        userData.StockMissingQty = 1;
        invalidItems.push({
          Name: pivotItem.Name + " (" + pivotItem.SpecialPriceName + ")",
          TotalRequestedQuantity: pivotItem.TotalRequestedQuantity, 
          Stock: stockQty
        });
      }
    }
  }
}