import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../RcvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.ReceiveNumber.Label + ", " +
      Catalog.VendorID.Label + " atau " +
      Catalog.PurchasingNumber.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  },

  Date: {
    Label: Catalog.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input
  }
}