<template>
  <kst-section :title="Info.Section.ReceiveList.Title">
    <kst-table input-mode :data="debtPayData.ReceiveList">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-shortdate"
        field="ReceiveDate"
        :label="DbpyRcvCatalog.ReceiveDate.Label"
      >
        <kst-output boxOff field-only
          :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="ReceiveID"
        :label="DbpyRcvCatalog.ReceiveID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="VendorID"
        :label="DbpyRcvCatalog.VendorID.Label"
      >
        <kst-output field-only
          :controlOff="row.VendorID === null"
          :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="WarehouseID"
        :label="DbpyRcvCatalog.WarehouseID.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="PaymentValue"
        :label="DbpyRcvCatalog.PaymentValue.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import DbpyRcvCatalog from "../DbpyRcvCatalog.js";
import Info from "../DbpyInfo.js";

export default {
  props: {
    debtPayData: Object
  },
  data: () => ({
    DbpyRcvCatalog: DbpyRcvCatalog,
    Info: Info
  })
}
</script>