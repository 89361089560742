<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="900"
    @init="handleModal_Init"
    @load="handleModal_Load"
    @reset="handleModal_Reset"
    @submit="handleModal_Submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :access="access"
        :items="data.PurchaseList"
        :userData="userData"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AppModule from "../../../services/App/AppModule.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Model from "../PurModel.js";
import SearchCatalog from "./SearchCatalog.js";

import List from "./PurScL_List.vue";
import Search from "./PurScL_Search.vue";

export default {
  mixins: [SnackbarMixin],
  components: {
    List,
    Search
  },
  props: {
    warehouseList: Array
  },
  data: () => ({
    Model: Model,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {},
    userWarehouseList: [],
    getDetailsService: null,
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getListService, getDetailsService, warehouseList) {
      this.getDetailsService = getDetailsService;
      this.getListService = getListService;
      this.userWarehouseList = warehouseList;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();
    },

    handleModal_Load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_Reset(loadList) {
      this.init_Data();
      this.handleModal_Load(loadList);
    },

    handleModal_Submit(selected, loadDetails) {
      let isFound = false;
      for (const warehouse of this.userWarehouseList) {
        if (warehouse.ID === selected.WarehouseID) {
          isFound = true;
          break;
        }
      }

      if (!isFound) {
        this.notifyFormError(false, AppModule.Warehouse.FullName + " " +
          selected.WarehouseName + " " + AppCatalog.Message.NotAvailable
        );
        return;
      }

      if (this.getDetailsService) {
        const config = this.getDetailsService(selected.ID);
        loadDetails(config, this.handleModal_Submit_Success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit, selected);
        this.hideFunc();
      }
    },
    handleModal_Submit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, data);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      return {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchListFields
      };
    },

    init_Data() {
      this.access = this.$kst.Access.create(Model.Access);

      // warehouse
      this.warehouseOptions = Model.getWarehouseOptions(
        this.warehouseList, SearchCatalog.WarehouseID.Label
      );

      // searchData
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Search.OptionAll;
    }
  }
}
</script>