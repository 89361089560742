<template>
  <kst-page-print-list pageFooter
    v-slot="{ title }"
    :catalog="SlsSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="block" :dataList="dataList"/>
  </kst-page-print-list>
</template>

<script>
import Model from "../SlsModel.js";
import SlsSearchCatalog from "../SlsSearchCatalog.js";
import Services from "../../../services/Api/SalesServices.js";

import List from "./SlsPL_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    Model: Model,
    SlsSearchCatalog: SlsSearchCatalog,
    companyData: {},
    dataList: [],
    searchData: {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: null,
      StatusID: null,
      WarehouseID: null
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintListData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;

      for (const row of this.dataList) {
        Model.setDetailsByStatus(row);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.dataList = [];
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.StatusID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        client: clientId,
        clientname: clientName,
        status: this.$kst.Search.getValue(this.searchData.StatusID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields
      };
    }
  }
}
</script>