import AppCatalog from "../services/App/AppCatalog.js";
import AppModuleType from "../services/App/AppModuleType.js";

/**
 * Excel mixin.
 * - index: start with 0.
 */
export default {
  data: () => ({
    activeBook: null,
    activeIndexCol: null,
    activeIndexRow: null,
    activeIndexSeq: 1,
    activeRow: null,
    activeSheet: null,
    mergeList: []
  }),
  methods: {
    download(fileName) {
      this.$xlsx.writeFile(this.activeBook, fileName);

      // reset
      this.activeBook = null;
      this.activeIndexCol = null;
      this.activeIndexRow = null;
      this.activeRow = null;
      this.activeSheet = null;
      this.mergeList = [];
    },
    resetSequence() {
      this.activeIndexSeq = 1;
    },

    /** book functions **/

    createBook() {
      this.activeBook = this.$xlsx.utils.book_new();
      return this.activeBook;
    },
    getBook() {
      return this.activeBook;
    },

    /** sheet functions **/

    // create blank-sheet, not append to book
    createSheet() {
      this.activeIndexRow = null;
      this.activeIndexCol = null;
      this.activeRow = [];
      this.activeSheet = [this.activeRow];
      this.mergeList = [];

      return this.activeSheet;
    },
    // append active-sheet to active-book
    addSheet(title) {
      let options = { skipHeader: true };
      let sheet = this.$xlsx.utils.json_to_sheet(this.activeSheet, options);

      if (this.mergeList.length > 0) {
        sheet[AppCatalog.Xlsx.WorkSheet.Merges] = this.mergeList;
      }
      
      this.$xlsx.utils.book_append_sheet(this.activeBook, sheet, title);

      // reset
      this.activeIndexRow = null;
      this.activeIndexCol = null;
      this.activeRow = null;
      this.activeSheet = null;
      this.mergeList = [];
    },
    getSheet() {
      return this.activeSheet;
    },
    getSheets() {
      if (this.activeBook === null) {
        return null;
      }
      else {
        return this.activeBook.Sheets;
      }
    },

    /** row functions **/

    addRow() {
      if (this.activeSheet === null) {
        this.createSheet();
      }

      this.activeRow = [];
      this.activeSheet.push(this.activeRow);
      this.activeIndexCol = null;
      this.activeIndexRow++;
    },
    setActiveRow(index) {
      this.activeIndexRow = (index >= this.activeSheet.length) ?
        this.activeSheet.length - 1 : index;
      this.activeRow = this.activeSheet[this.activeIndexRow];
    },
    getRow() {
      return this.activeRow;
    },

    /** cell functions **/

    addCell(cell) {
      if (this.activeRow === null) {
        this.addRow();
      }

      if (this.activeIndexCol === null) {
        this.activeIndexCol = 0;
      } else {
        this.activeIndexCol++;
      }

      if (this.activeIndexCol !== this.activeRow.length) {
        this.activeRow[this.activeIndexCol] = cell;
      } else {
        this.activeRow.push(cell);
      }

      return this.getCellCode(this.activeIndexCol, this.activeIndexRow);
    },
    addCellValue(field, data, catalog) {
      this.addCell(this.$kst.Xlsx.getValue(field, data, catalog));
    },
    addCellValue_ID(data) {
      this.addCell(data.ID);
    },
    addCellValue_CreatedBy(data) {
      this.addCell(data.CreatedByName);
    },
    addCellValue_CreatedDate(data) {
      const catalog = {
        CreatedDate: {
          Type: AppCatalog.Field.CreatedDate.Type,
          Output: AppCatalog.Field.CreatedDate.Output
        }
      };

      this.addCell(this.$kst.Xlsx.getValue("CreatedDate", data, catalog));
    },
    addCellValue_LastUpdatedBy(data) {
      this.addCell(data.LastUpdatedByName);
    },
    addCellValue_LastUpdatedDate(data) {
      const catalog = {
        LastUpdatedDate: {
          Type: AppCatalog.Field.LastUpdatedDate.Type,
          Output: AppCatalog.Field.LastUpdatedDate.Output
        }
      };

      this.addCell(this.$kst.Xlsx.getValue("LastUpdatedDate", data, catalog));
    },
    addCellRelatedValue(field, data, catalog) {
      this.addCell(this.$kst.Xlsx.getRelatedValue(field, data, catalog));
    },
    addCellSection(title) {
      let newTitle = title === undefined || title === null
        ? "" : new String(title);

      // auto add colon punctuation
      const lastChar = newTitle.slice(-1);
      if (newTitle.length > 0) {
        if (lastChar !== ":") {
          newTitle += ":";
        }
      }

      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: newTitle
      });
    },
    /**
     * @param {Object} moduleObj - source: src\services\App\AppModule.js
     */
    addCellSection_items(moduleObj) {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: AppCatalog.Section.Items +
          (moduleObj ? " dari " + moduleObj.FullName : "") + ":"
      });
    },
    /**
     * @param {Object|String} module - object source: src\services\App\AppModule.js
     */
    addCellSection_list(module) {
      let moduleName = "";
      if (module === undefined || module === null) {
        // do nothing
      }
      else {
        if (this.$kst.Type.isString(module)) {
          moduleName += " " + module;
        }
        else {
          moduleName += " " + module.FullName;
        }
      }

      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: AppModuleType.List.FullName + moduleName +
          ":"
      });
    },
    addCellStatus(value, statusEnum) {
      this.addCell(this.$kst.Xlsx.getStatus(value, statusEnum));
    },
    addColumnHeader(value) {
      let title = "";

      if (this.$kst.Type.isString(value)) {
        // custom title
        title = value;
      }
      else {
        // use data catalog
        title = value.ExportLabel ? value.ExportLabel : value.Label;
      }

      this.addCell({ [AppCatalog.Xlsx.Key.Value]: title });
    },
    addColumnHeader_ID(label) {
      this.addCell(AppCatalog.Info.ID + (label ? " " + label : ""));
    },
    addColumnHeader_CreatedBy() {
      this.addCell(AppCatalog.Field.CreatedBy.Label);
    },
    addColumnHeader_CreatedDate() {
      this.addCell(AppCatalog.Field.CreatedDate.Label);
    },
    addColumnHeader_LastUpdatedBy() {
      this.addCell(AppCatalog.Field.LastUpdatedBy.Label);
    },
    addColumnHeader_LastUpdatedDate() {
      this.addCell(AppCatalog.Field.LastUpdatedDate.Label);
    },
    addSequenceCell() {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: this.activeIndexSeq
      });
      this.activeIndexSeq++;
    },
    addSequenceHeader() {
      this.addCell({
        [AppCatalog.Xlsx.Key.Value]: AppCatalog.Xlsx.Default.Sequence
      });
      this.resetSequence();
    },
    moveColumn(number) {
      this.activeIndexCol += number;

      // jump to selected column and fill empty value for default
      if (this.activeIndexCol + 1 > this.activeRow.length) {
        const totalCol = this.activeIndexCol + 1 - this.activeRow.length
        for (let i = 0; i < totalCol; i++) {
          this.activeRow.push("");
        }
      }

      return this.getCellCode(this.activeIndexCol, this.activeIndexRow);
    },
    mergeCol(rowspan) {
      this.merge(rowspan, null);
    },
    mergeRow(colspan) {
      this.merge(null, colspan);
    },
    merge(rowspan, colspan) {
      const start = {
        [AppCatalog.Xlsx.Key.Row]: this.activeIndexRow,
        [AppCatalog.Xlsx.Key.Column]: this.activeIndexCol
      };
    
      const end = {
        [AppCatalog.Xlsx.Key.Row]: this.activeIndexRow + (colspan ? colspan - 1 : 0),
        [AppCatalog.Xlsx.Key.Column]: this.activeIndexCol + (rowspan ? rowspan - 1 : 0)
      };
    
      const merge = {
        [AppCatalog.Xlsx.Position.Start]: start,
        [AppCatalog.Xlsx.Position.End]: end,
      };
    
      this.mergeList.push(merge);
    },

    /** support functions **/

    getColumnCode(indexColumn) {
      return this.$xlsx.utils.encode_col(indexColumn);
    },
    getRowCode(indexRow) {
      return this.$xlsx.utils.encode_row(indexRow);
    },
    getCellCode(indexColumn, indexRow) {
      return this.getColumnCode(indexColumn) + this.getRowCode(indexRow);
    }
  }
}