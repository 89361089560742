<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-module-name"
      field="ModuleName"
      :label="Catalog.ModuleName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="Number"
      :label="Catalog.Number.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseName"
      :label="Catalog.WarehouseName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchName"
      :label="Catalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty-small"
      field="Qty"
      :label="Catalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-packaging-small"
      field="Packaging"
      :label="Catalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price-small"
      field="Price"
      :label="Catalog.Price.Label"
    >
      <template v-if="row.HasAmountAccess">
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </template>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <template v-if="row.HasAmountAccess">
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </template>
    </b-table-column>
    
  </kst-print-table>
</template>

<script>
import Model from "../TrxModel.js";
import Catalog from "../TrxListCatalog.js";

export default {
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Model: Model,
    Catalog: Catalog
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      Model.updateTransactionList(this.dataList, this.access);
    }
  }
}
</script>