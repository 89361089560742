import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  BuyPrice: {
    Label: AppCatalog.Field.BuyPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    Required: true
  },
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  ExpenseAmount: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 200,
    Required: true
  },
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  Quantity: {
    Label: AppCatalog.Field.Qty.Label + " Terima",
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  TotalQuantity: {
    Label: AppCatalog.Field.TotalQty.Label,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },

  // support
  DispatchImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  PurchasingItemQuantity: {
    Label: AppCatalog.Field.Qty.Label + " Pesan",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  QtyAvailable: {
    Label: AppCatalog.Field.Qty.Label + " Beli",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  ReturnItemQuantity: {
    Label: AppCatalog.Field.Qty.Label + " Retur",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
}