import App from "../../services/App/App.js";

export default {
  getPivotSummaryObj_compare() {
    return function innerSort(a, b) {
      let valueA = a.toUpperCase();
      let valueB = b.toUpperCase();
  
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      }
      else if (valueA < valueB) {
        comparison = -1;
      }
  
      return comparison;
    }
  },
  
  getPivotSummaryObj(dataListByVendor)  {
    const fieldKeys = [ "Vendor", "Name" ];
    const fieldValues = [
      { field: "TotalQuantity", type: App.Pivot.Type.SUM },
    ];
    const summaryKeyList = [ "Vendor", "Name" ];

    const summaryObj = App.Pivot.createObj(
      dataListByVendor, fieldKeys, fieldValues, summaryKeyList
    );

    summaryObj.SummaryObj.Name.sort(this.getPivotSummaryObj_compare());
    summaryObj.SummaryObj.Vendor.sort(this.getPivotSummaryObj_compare());

    return summaryObj;
  }
}