import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./SttrModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.StockTrf.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.StockTrf.FullName,
          StockTrfItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.StockTrfItem.Module.FullName + "?",
    PrintDetails_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}