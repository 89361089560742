import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  getComponentClass(module, moduleType) {
    let className = "";

    if (module) {
      className += "-" + module.Class;
    }
    if (moduleType) {
      className += "-" + moduleType.Class;
    }
    if (className !== "") {
      className = AppCatalog.ClassPrefix.Module + className;
    }

    return className;
  },
  hasEventHandler(component, eventName) {
    return Object.prototype.hasOwnProperty.call(component.$listeners, eventName);
  }
}