<template>
  <kst-table input-mode :data="dataList">
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdatetime"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-output boxOff field-only
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="ID"
      :label="Catalog.ID.Label"
    >
      <kst-output field-only
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../RrcvCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>