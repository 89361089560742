import ModelBody from "./StkModel_Body.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  Item: ModelBody.Item,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  LastInvoiceDate: ModelBody.LastInvoiceDate,
  Search: ModelBody.Search,
  Status: ModelBody.Status,

  /*** method ***/

  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },

  createLastInvoiceDateOptions(optionAllText) {
    return ModelBody.createLastInvoiceDateOptions(optionAllText,
      ModelBody.LastInvoiceDate
    );
  },

  getResultSectionHelp(resultData) {
    return ModelBody.getResultSectionHelp(resultData);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}