<template>
  <kst-page-export
    :catalog="PurSearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      :searchData="searchData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check 
      v-model="userData.WithItems"
      :label="Info.Export.List.Excel.Option.PurchaseItem"
    />
    <kst-check 
      v-model="userData.WithItemsByVendor"
      :label="Info.Export.List.Excel.Option.PurchaseItemListByVendor"
    />
  </kst-page-export>
</template>

<script>
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import PurSearchCatalog from "../PurSearchCatalog.js";
import Services from "../../../services/Api/PurchaseServices.js";

import Export from "./PurExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    PurSearchCatalog: PurSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      Status: null,
      Vendor: "",
      VendorID: null,
      WarehouseID: null
    },
    userData: {
      WithList: true,
      WithItems: true,
      WithItemsByVendor: false
    },
    searchParams: ["Search", "Date", "Status", "Vendor", "VendorID",
      "WarehouseID"],
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithList = true;
      this.userData.WithItems = true;
      this.userData.WithItemsByVendor = false;
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.Vendor) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        status: this.$kst.Search.getValue(this.searchData.Status),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields,
        withitems: this.$kst.Out.getBoolean(this.userData.WithItems),
        withitemsbyvendor: this.$kst.Out.getBoolean(this.userData.WithItemsByVendor)
      };
    }
  }
}
</script>