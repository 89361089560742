import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import RinvItemCatalog from "./RinvItemCatalog.js";

export default {
  setDetailsByInvoice(userData, invoiceDetails, discTypeEnum) {
    // user input
    userData.WarehouseID = invoiceDetails.WarehouseID;
    userData.SalesOrderID = invoiceDetails.ID;
    // defined by system
    userData.SONumber = invoiceDetails.SONumber;
    userData.SalesOrderDate = App.In.getDate(invoiceDetails.InvoiceDate);
    userData.ClientID = invoiceDetails.ClientID;
    userData.ClientAlias = invoiceDetails.ClientAlias;
    userData.ClientName = invoiceDetails.Client;
    userData.ClientAddress = invoiceDetails.ClientAddress;

    if (invoiceDetails.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
      userData.DiscValue = App.In.getInteger(invoiceDetails.DiscValue);
    }
    else if (invoiceDetails.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(invoiceDetails.DiscPercent);
    }
  },
  setItemByInvoiceItem(item, invoiceItem) {
    // user input
    item.DispatchID = invoiceItem.DispatchID;
    item.PackagingName = invoiceItem.PackagingName;
    item.Quantity = App.In.getInteger(invoiceItem.QtyAvailable);
    item.SellPrice = App.In.getInteger(invoiceItem.SellPrice);
    // defined by system
    item.DispatchName = invoiceItem.Name;
    item.PackagingValue = invoiceItem.PackagingValue;
    item.SKU = invoiceItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(invoiceItem.DispatchIsActive);
    item.InvoiceItemQty = invoiceItem.QtyAvailable;
    item.Index = invoiceItem.Index;
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    if (item.DispatchIsActive) {
      // SKU
      infoList.push(RinvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RinvItemCatalog)
      );
    }

    return infoList;
  }
}