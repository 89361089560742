<template>
  <div class="block">
    <kst-output field="CategoryID" :data="details" :catalog="Catalog"/>
    <kst-output field="Name" :data="details" :catalog="Catalog"/>
    <kst-output field="IsActive" :data="details" :catalog="Catalog"/>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
  </div>
</template>

<script>
import Catalog from "../ExpcCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>