import AppCatalog from "../services/App/AppCatalog.js";

export default {
  methods: {
    // timeout: in miliseconds, to use default set as = true
    openFullLoading(timeout, callback) {
      const loadingComponent = this.openLoading(null, timeout, callback);
      if (loadingComponent) {
        return loadingComponent;
      }
    },
    openLoading(element, timeout, callback) {
      const container = element ? element.$el : null;
      const loadingComponent = this.$buefy.loading.open({
        container: container
      });

      if (timeout) {
        // set default
        let newTimeout = timeout === true ? AppCatalog.Duration.Loading : timeout;

        setTimeout(() => {
          loadingComponent.close();

          if (callback) {
            callback();
          }
        }, newTimeout);
        return;
      }
      else {
        return loadingComponent;
      }
    },

    closeLoading(component) {
      if (component) {
        component.close();
      }
    }
  }
}