<template>
  <div class="kst-actions">
    <template v-if="hasActions">
      <template v-if="hasDefault">
        <HasDefault
          :actions="actions"
          :fullLabel="fullLabel"
          :slim-size="slimSize"
        />
      </template>
      <template v-else>
        <NoDefault
          :actions="actions"
          :fullLabel="fullLabel"
        />
      </template>
    </template>
  </div>
</template>

<script>
import HasDefault from "./Actions_HasDefault.vue";
import NoDefault from "./Actions_NoDefault.vue";

export default {
  components: {
    HasDefault,
    NoDefault
  },
  props: {
    actions: Array,
    fullLabel: Boolean,
    hasDefault: Boolean,
    // size
    slimSize: Boolean
  },
  computed: {
    hasActions() {
      if (this.actions === undefined || this.actions === null) {
        return false;
      }
      else if (!Array.isArray(this.actions)) {
        return false;
      }
      else if (this.actions.length === 0) {
        return false;
      }

      return true;
    }
  }
}
</script>