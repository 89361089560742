import App from "../services/App/App.js";
import RouterFull from "./RouterFull.js";
// general
import NotFound from "../components/NotFound/NotFound.vue";
import Layout from "../components/Layout/Layout.vue";

export default {
  getRoutes(isFull) {
    let result = [
      {
        path: "/",
        component: Layout,
        children: [
          {
            path: "/",
            name: "Layout"
          },
        ],
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "*",
        name: "Not Found Root",
        component: NotFound
      }
    ];

    // populate: routes based on available menu
    let myMenu = App.Session.getModule();
    let hasMenu = Object.keys(myMenu).length !== 0;
    let fullRoutes = RouterFull.getRoutes();
    let fullRoutesChildren = RouterFull.getRoutesChildren();

    if (isFull) {
      result[0].children.push(...fullRoutesChildren);
      result.push(...fullRoutes);
      return result;
    }

    for (let i = 0; i < fullRoutes.length; i++) {
      if (fullRoutes[i].meta.module.IsAuthenticated) {
        if (hasMenu) {
          if (myMenu.includes(fullRoutes[i].meta.module.ID)) {
            result.push(fullRoutes[i]);
          }
        }
      }
      else {
        result.push(fullRoutes[i]);
      }
    }

    for (let i = 0; i < fullRoutesChildren.length; i++) {
      if (fullRoutesChildren[i].meta.module.IsAuthenticated) {
        if (hasMenu) {
          if (myMenu.includes(fullRoutesChildren[i].meta.module.ID)) {
            result[0].children.push(fullRoutesChildren[i]);
          }
        }
      }
      else {
        result[0].children.push(fullRoutesChildren[i]);
      }
    }

    return result;
  }
}