<template>
  <kst-section items-mode class="mt-5">
    <InvoiceItemSearch
      ref="mdlInvoiceItemSearch"
      :items="invoiceItemsSearch" 
      @submit="handleInvoiceItem_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Info.Button.InvoiceItem.Label"
        :moduleType="Model.ModuleType.New"
        @click="handleAdd_InvoiceItem"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Info.Button.InvoiceItem.Label"
          :moduleType="Model.ModuleType.New"
          @click="handleAdd_InvoiceItem"
        />
      </template>
    </template>

    <kst-warning class="mb-4" :items="itemsWarning"/>

    <List :userData="userData" :userItems="userItems"/>
  </kst-section>
</template>

<script>
import Info from "../RinvInfo.js";
import Model from "../RinvModel.js";

import InvoiceItemSearch from "../../Invoice/ItemSearch/InvoiceItemSearch.vue";
import List from "./RinvE_Items_List.vue";

export default {
  components: {
    InvoiceItemSearch,
    List
  },
  props: {
    invoiceItems: Array,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Info: Info,
    Model: Model,
    invoiceItemsSearch: []
  }),
  computed: {
    itemsWarning() {
      return [
        Info.Message.Edit_InvoiceItem,
        Info.Message.Edit_DeletedItem
      ];
    }
  },
  methods: {
    handleInvoiceItem_Submit(row) {
      let item = Model.createItem();
      Model.Edit.setItemByInvoiceItem(item, row);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleAdd_InvoiceItem() {
      this.invoiceItemsSearch = Model.getInvoiceItems_ForSelection(
        this.userItems, this.invoiceItems
      );
      this.$refs.mdlInvoiceItemSearch.show();
    }
  }
}
</script>