import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./PlosModel_Body.js";

const KeySeparator = "/";

// formula: AccountingBased
export default {
  getChart(transactionDetails) {
    let labelList = [], summaryObj = {};
    let result = ModelBody.createChart();

    this.getChart_populateInvoice(labelList, summaryObj, transactionDetails);
    this.getChart_populateInvoice_hppTotal(labelList, summaryObj, transactionDetails);
    this.getChart_populateHppAdj(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockOpname(labelList, summaryObj, transactionDetails);
    this.getChart_populateRtnInvoice(labelList, summaryObj, transactionDetails);
    this.getChart_populateExpense(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockReduction(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockTrf(labelList, summaryObj, transactionDetails);

    let valueTotal = 0;
    labelList.sort();

    for (const row of labelList) {
      valueTotal += summaryObj[row];

      result.LabelList.push(
        this.getChart_getLabel(row)
      );
      result.ValueList.push(
        App.Data.getFixedDecimal(summaryObj[row])
      );
    }

    result.ValueAvg = labelList.length === 0
      ? 0
      : App.Data.getFixedDecimal(valueTotal / labelList.length);
    result.ValueAvg = App.In.getDecimal(result.ValueAvg);

    return result;
  },
  getChart_populateInvoice(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.InvoiceList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] + row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateInvoice_hppTotal(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.InvoiceHppList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] - row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateHppAdj(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.HppAdjList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] + row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateStockOpname(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.StockOpnameList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] + row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateRtnInvoice(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.RtnInvoiceList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] - row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateExpense(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.ExpenseList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] - row.Total;
      }
      else {
        summaryObj[key] = -1 * row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateStockReduction(labelList, summaryObj, transactionDetails) {
    let key;
    for (const row of transactionDetails.StockReductionList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] - row.Total;
      }
      else {
        summaryObj[key] = -1 * row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_populateStockTrf(labelList, summaryObj, transactionDetails) {
    if (!Object.prototype.hasOwnProperty.call(transactionDetails, "StockTrf")) {
      return;
    }

    let key;
    for (const row of transactionDetails.StockTrf.AddList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] + row.Total;
      }
      else {
        summaryObj[key] = row.Total;
        labelList.push(key);
      }
    }

    for (const row of transactionDetails.StockTrf.SubList) {
      key = this.getChart_getKey(row.Date);

      if (labelList.includes(key)) {
        summaryObj[key] = summaryObj[key] - row.Total;
      }
      else {
        summaryObj[key] = -1 * row.Total;
        labelList.push(key);
      }
    }
  },
  getChart_getKey(dateString) {
    return dateString.substring(0, 4) + KeySeparator + dateString.substring(5, 7);
  },
  getChart_getLabel(key) {
    const keyList = key.split(KeySeparator);
    return AppCatalog.MonthNames[parseInt(keyList[1]) - 1] + " " + keyList[0];
  }
}