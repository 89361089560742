import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import SlsItemCatalog from "./SlsItemCatalog.js";

export default {
  getItemInfo(userItem) {
    let infoList = [];

    // deleted item
    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(SlsItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, SlsItemCatalog)
      );
    }

    // QtySalesOrder
    infoList.push(SlsItemCatalog.QtySalesOrder.Label + ": " +
      App.Value.getValue("QtySalesOrder", userItem, SlsItemCatalog)
    );

    return infoList;
  }
}