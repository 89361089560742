<template>
  <kst-actions :actions="availActions"/>
</template>

<script>
import Model from "../ExpModel.js";

export default {
  props: {
    access: Object,
    row: Object
  },
  data: () => ({
    actions: [
      Model.ModuleType.Details,
      Model.ModuleType.Edit,
      Model.ModuleType.ExportDetails
    ]
  }),
  computed: {
    availActions() {
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: this.row.ID
      });

      return this.$kst.Access.getAvailableActions(
        this.actions, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>