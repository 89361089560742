<template>
  <div>
    <template v-if="autoNumberField">
      <kst-input :field="autoNumberField" :data="data" :catalog="catalog">
        <template #right>
          <kst-input addons control-off floating-label
            class="mr-2"
            v-show="!data[autoNumberField]"
            :field="numberField" :data="data" :catalog="catalog"
            :customValidations="numberValidations"
            @input="handleNumber_Input"
          />
          <kst-input addons control-off expanded floating-label
            :field="dateField" :data="data" :catalog="catalog"
            :customValidations="dateValidations"
            :disabled="dateDisabled"
            @input="handleDate_Input"
          />
        </template>
      </kst-input>
    </template>
    <template v-else>
      <kst-input addons :field="numberField" :data="data" :catalog="catalog">
        <template #right>
          <kst-input addons control-off expanded floating-label
            class="pl-2"
            :field="dateField" :data="data" :catalog="catalog"
            @input="handleDate_Input"
          />
        </template>
      </kst-input>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    catalog: Object,
    data: Object,
    autoNumberField: String,
    dateDisabled: Boolean,
    dateField: String,
    dateValidations: Object,
    numberField: String
  },
  computed: {
    numberValidations() {
      return this.data[this.autoNumberField] ? {} : { required: true };
    }
  },
  methods: {
    handleDate_Input() {
      this.$emit(this.$kst.Enum.Event.Input, this.data[this.dateField], null);
    },
    handleNumber_Input() {
      this.$emit(this.$kst.Enum.Event.Input, null, this.data[this.numberField]);
    }
  }
}
</script>
