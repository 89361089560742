<template>
  <div class="block">
    <kst-output field="Status" :data="userData" :catalog="Catalog">
      <template #left>
        <kst-output floating-label
          :label="Catalog.Status.OldLabel"
          field="Status" :data="deliveryData" :catalog="Catalog"
        />
        <kst-icon arrowRightMode class="k-control-py px-2"/>
      </template>
      <template #default>
        <kst-output addons expanded floating-label
          :label="Catalog.Status.NewLabel"
          field="Status" :data="userData" :catalog="Catalog"
        />
      </template>
    </kst-output>

    <template v-if="userData.Status === Model.Status.InDelivery.ID">
      <kst-input loadOnFocus field="DepartureDate" :data="userData" :catalog="Catalog"/>
    </template>
    <template v-if="userData.Status === Model.Status.Delivered.ID">
      <kst-input loadOnFocus field="ArrivalDate" :data="userData" :catalog="Catalog"/>
      <kst-input field="CompletedDate" :data="userData" :catalog="Catalog"
        :customValidations="completedDateValidations"
      />
    </template>
  </div>
</template>

<script>
import Catalog from "../DlvCatalog.js";
import Model from "../DlvModel.js";

export default {
  props: {
    deliveryData: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    completedDateValidations() {
      return Model.EditStatus.validationCompletedDate(this.userData);
    }
  }
}
</script>