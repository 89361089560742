<template>
  <kst-section info-mode collapsed
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help="sectionHelp"
    :help-collapsed="sectionHelp"
  >
    <template v-if="receiveList.length > 0">
      <ReceiveRelatedList2 :dataList="receiveList"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../PurCatalog.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";

import ReceiveRelatedList2 from "../../Receive/RelatedList2/RcvRL2.vue"

export default {
  components: {
    ReceiveRelatedList2,
  },
  props: {
    receiveList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.receiveList.length > 0;
    },
    sectionHelp() {
      return Model.Delete.getReceiveSectionHelp(this.receiveList);
    },
    sectionTitle() {
      return Info.Section.Receive.Title;
    }
  }
}
</script>