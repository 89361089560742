<template>
  <kst-section collapsible collapsed info-mode
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
  >
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="details"
      dateField="SalesOrderDate"
      numberField="SalesOrderID"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RinvCatalog.js";
import Info from "../RinvInfo.js";
import Model from "../RinvModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionHelp() {
      return Model.getInvoiceSectionHelp(this.details);
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    }
  }
}
</script>