import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  /*** property ***/

  Actions: [
    AppModuleType.EditStatus,
    AppModuleType.PrintDetails,
    AppModuleType.ExportDetails
  ]
}