<template>
  <kst-print-table input-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="RrcvItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="RrcvItemCatalog" />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="RrcvItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RrcvItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="RrcvItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RrcvItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="RrcvItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="BuyPrice"
      :label="RrcvItemCatalog.BuyPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RrcvItemCatalog" />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="SubTotal"
      :label="RrcvItemCatalog.SubTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RrcvItemCatalog" />
    </b-table-column>
  </kst-print-table>
</template>

<script>
import RrcvItemCatalog from "../RrcvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    RrcvItemCatalog: RrcvItemCatalog
  })
}
</script>
