<template>
  <b-dropdown
    v-model="valueId"
    aria-role="list"
    class="kst-dropdown"
    position="is-bottom-left"
    @change="handleChange"
  >
    <template #trigger="{ active }">
      <b-button
        :label="label"
        :type="theType"
        :icon-right="active ? 'mdi mdi-menu-up mdi-24px' : 'mdi mdi-menu-down mdi-24px'"
      />
    </template>

    <template v-for="option in options[Model.InputSelectKey.Rows]">
      <b-dropdown-item
        aria-role="listitem"
        :value="option[options[Model.InputSelectKey.Value]]"
        :key="option[options[Model.InputSelectKey.Value]]"
      >
        {{ option[options[Model.InputSelectKey.Label]] }}
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import Model from "./DropdownModel.js";

export default {
  props: {
    label: String,
    options: {
      /**
       * must contain keys:
       * - see Input keyset in: src\services\App\App_Enum.js
       * {String} value - key that contains value attribue for options in select
       * {String} label - key that contains label attribue for options in select
       * [Array] rows - data for options in select
       **/
      type: Object,
      required: true
    },
    type: String
  },
  data: () => ({
    Model: Model,
    valueId: null
  }),
  computed: {
    theType() {
      return this.type === undefined || this.type === null
        ? "is-link" : this.type;
    }
  },
  methods: {
    handleChange(value) {
      this.$nextTick(() => {
        // reset last selected
        this.valueId = null;
      });

      const rowId = this.options[Model.InputSelectKey.Value];
      const rows = this.options[Model.InputSelectKey.Rows];
      const option = this.$kst.Array.searchItem(rows, rowId, value);
      this.$emit(this.$kst.Enum.Event.Change, option);
    }
  }
}
</script>