import ModelBody from "./RecModel_Body.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Search: ModelBody.Search,
  // related
  Status: ModelBody.Status,

  /*** method ***/

  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },

  setDetailsByStatus(details) {
    ModelBody.setDetailsByStatus(details, ModelBody.Status);
  },

  updateResult(dataList) {
    ModelBody.updateResult(dataList);
  },

  /*** method - List ***/

  List: {
    hasAccessAction(access) {
      return access.Invoice_Act_Details;
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}