<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Info from "../StopInfo.js";
import ItemCatalog from "../../Item/ItemCatalog.js";
import ItemPackagingCatalog from "../../Item/PackagingCatalog.js";
import StopItemCatalog from "../StopItemCatalog.js";
import AppCatalog from "../../../services/App/AppCatalog";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();

      this.createSheet();
      this.addInstruction();

      this.createSheet();
      this.addList(data.List);

      this.download(fileName);

    },

    addInstruction() {
      const generalInfoContent = Info.Export.New.Excel.Template.GeneralInfo.Content;
      const columnInfoContent = Info.Export.New.Excel.Template.ColumnInfo.Content;
      const inputExampleContent = Info.Export.New.Excel.Template.InputExample.Content;
      let idxContent, idxDetail;

      /** General Info */
      this.addRow();
      this.addCell(Info.Export.New.Excel.Template.GeneralInfo.HeaderName);

      for (idxContent = 0; idxContent < generalInfoContent.length; idxContent++) {
        this.addRow();
        this.addCell();

        this.addCell((idxContent + 1) + ". " + generalInfoContent[idxContent]);
      }

      /** Column Info */
      this.addRow();
      this.addRow();
      this.addCell(Info.Export.New.Excel.Template.ColumnInfo.HeaderName);

      let rowContent, contentDetail;

      for (idxContent = 0; idxContent < columnInfoContent.length; idxContent++) {
        this.addRow();
        this.addCell();

        rowContent = columnInfoContent[idxContent];
        contentDetail = rowContent.DetailList;

        this.addCell(rowContent.Column);

        if (contentDetail.length > 1) {
          this.mergeRow(contentDetail.length)
        }

        if (idxContent > 0){
          for(idxDetail = 0; idxDetail < contentDetail.length; idxDetail++) {
            if (idxDetail > 0) {
              this.addRow();
              this.addCell();
              this.addCell();
            }

            this.addCell((idxDetail + 1) + ". " + contentDetail[idxDetail]);
          }
        } else {
          this.addCell(contentDetail[0]);
        }
      }

      /** Input Example  */
      this.addRow();
      this.addRow();
      this.addCell(Info.Export.New.Excel.Template.InputExample.HeaderName);

      this.addRow();
      this.addCell();
      this.add_headerList();
      this.addCell(Info.Export.New.Excel.Template.InputExample.Info);

      this.addRow();
      this.addCell();
      for (idxContent = 0; idxContent < inputExampleContent.length; idxContent++) {
        this.addCell(inputExampleContent[idxContent]);
      }

      this.addSheet(Info.Export.New.Excel.SheetName.TemplateInstruction);
    },

    addList(dataList) {
      // label
      this.add_headerList();

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue("ID", row, ItemCatalog);
        this.addCellValue("Name", row, ItemCatalog);
        this.addCellValue("PackagingPcs", row, ItemCatalog);
        this.addCell(AppCatalog.PackagingPcs.Qty);
      }

      this.addSheet(Info.Export.New.Excel.SheetName.TemplateListItem);
    },

    add_headerList() {
      this.addColumnHeader_ID(ItemCatalog.ID.HeaderLabel);
      this.addColumnHeader(ItemCatalog.ID.Label);
      this.addColumnHeader(ItemPackagingCatalog.Name);
      this.addColumnHeader(ItemPackagingCatalog.Qty);
      this.addColumnHeader(StopItemCatalog.Qty);
    }
  }
}
</script>