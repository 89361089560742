import AppCatalog from "../../services/App/AppCatalog.js";
import Model from "./PosModel.js";
import PosItemCatalog from "./PosItemCatalog.js";

export default {
  Dialog: {
    EditSellPrice: {
      Title: "Input " + PosItemCatalog.SellPrice.Label
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.Item.Module.FullName + "?",
    New_BarcodeInvalid:
      PosItemCatalog.Barcode.Label + " " + AppCatalog.Message.InValid,
  },
  Section: {
    Payment: {
      Title: "Pembayaran"
    }
  },
  Tabs: {
    Scanner: AppCatalog.Section.Scanner,
    Search: "Cari Barang"
  }
}