<template>
  <kst-section collapsed info-mode
    :collapsible="isCollapsible"
    :help-collapsed="sectionHelp"
    :help="sectionHelp"
    :title="sectionTitle"
  >
    <kst-output field="RefID" :data="details" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.details.RefID !== null;
    },
    sectionHelp() {
      return Model.getRefSectionHelp(this.details);
    },
    sectionTitle() {
      return Info.Section.Ref.Title;
    }
  }
}
</script>