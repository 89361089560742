<template>
  <kst-page-print pageFooter
    v-slot="{ title }"
    :catalog="DbcdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="block" :dataList="dataList"/>
  </kst-page-print>
</template>

<script>
import DbcdSearchCatalog from "../DbcdSearchCatalog.js";
import Model from "../DbcdModel.js";
import Services from "../../../services/Api/DebtCardServices.js";

import List from "./DbcdP_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    DbcdSearchCatalog: DbcdSearchCatalog,
    Model: Model,
    companyData: {},
    dataList: [],
    searchData: {
      TransactionDate: null,
      TypeID: null,
      VendorID: null,
      VendorName: null,
      WarehouseID: null
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;

      Model.updateList(this.dataList);
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.dataList = [];
    },

    resetSearch() {
      this.searchData.TransactionDate = this.$kst.In.getDefaultDateRange();
      this.searchData.TypeID = this.$kst.Search.OptionAll;
      this.searchData.VendorID = null;
      this.searchData.VendorName = null;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        type: this.$kst.Search.getValue(this.searchData.TypeID),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.TransactionDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.TransactionDate[1])
      };
    }
  }
}
</script>