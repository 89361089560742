<template>
  <kst-form no-bezel class="block" v-if="access.Act_New">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
        :urlParams="urlParams"
      />
    </template>
  </kst-form>
</template>

<script>
import Catalog from "../RrcvCatalog.js";
import Model from "../RrcvModel.js";

export default {
  props: {
    receiveId: Number
  },
  data: () => ({
    Model: Model,
    access: {}
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [Catalog.ReceiveID.Param]: this.receiveId
      });
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>