import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.user;

export default {
  getRelatedAccessAccessibility(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/access/accessibility?id=" + id
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getEditPasswordData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editpassworddata?id=" + id
    };
  },
  getList(params) {
    const attributes = ["access", "active", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["access", "active", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  },
  putPassword(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/password",
      data: data
    };
  }
}