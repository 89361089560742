import AppCatalog from "./AppCatalog.js";
import App_Data from "./App_Data.js";
import App_Value from "./App_Value.js";
import App_Value_Body from "./App_Value_Body.js";
import App_Xlsx_Body from "./App_Xlsx_Body.js";
import xlsx from "xlsx";

export default {
  getFileName(module, moduleType) {
    // similar to getName() in "@/router/RouterModel.js"
    if (moduleType) {
      const fileName = moduleType.FileName + " " + module.FileName +
        AppCatalog.Xlsx.Default.Extension;
      return fileName.trim();
    }
    return module.FileName + AppCatalog.Xlsx.Default.Extension;
  },
  getRelatedValue(field, data, catalog) {
    if (App_Value.isUseNullText(field, data, catalog)) {
      return catalog[field].NullText;
    }

    const relatedField = catalog[field].RelatedValue;
    return data[relatedField];
  },
  getStatus(value, statusEnum) {
    return { [AppCatalog.Xlsx.Key.Value]: App_Data.getStatusLabel(value, statusEnum) };
  },
  getValue(field, data, catalog) {
    // not supported: IsRange
    if (catalog[field].IsRange) {
      return { [AppCatalog.Xlsx.Key.Value]: data[field] };
    }

    const value = data[field];
    const datatype = App_Value_Body.getType(field, catalog);

    const xlsxFormat = App_Xlsx_Body.getFormat(datatype);
    const xlsxType = App_Xlsx_Body.getType(datatype);
    const xlsxValue = App_Xlsx_Body.getValue(datatype, value);

    let valueObj = {};

    if (xlsxFormat) {
      valueObj[AppCatalog.Xlsx.Key.Format] = xlsxFormat;
    }

    if (xlsxType) {
      valueObj[AppCatalog.Xlsx.Key.Type] = xlsxType;
    }

    valueObj[AppCatalog.Xlsx.Key.Value] = xlsxValue !== null ? xlsxValue : value;

    return valueObj;
  },
  getSheet(fileText, sheetName, withHeader = 1) {
    let result = {}, options = { type: AppCatalog.Xlsx.Options.Array };
    const workbook = xlsx.read(fileText, options);
    const worksheet = workbook.Sheets[sheetName];

    options = { header: withHeader };
    const rowList = xlsx.utils.sheet_to_json(worksheet, options);

    if (withHeader) {
      result.Header = rowList.shift();
    }

    result.RowList = rowList;
    return result;
  }
}