<template>
  <div>
    <template v-if="access.RtnInvoice_Act_List">
      <kst-section :title="rtnInvoiceListSectionTitle">
        <template #top-right>
          <RtnInvoiceRelatedListAction :invoiceId="details.ID"/>
        </template>

        <RtnInvoiceRelatedList :dataList="returnInvoiceList"/>
      </kst-section>
    </template>

    <template v-if="access.StockReduction_Act_List">
      <kst-section :title="stockReductionListSectionTitle">
        <template #top-right>
          <StockReductionRelatedListAction />
        </template>

        <StockReductionRelatedList :dataList="stockReductionList"/>
      </kst-section>
    </template>

    <!-- note: should use transaction/related list -->
    <template v-if="access.Act_List">
      <kst-section :title="invoiceReplaceListSectionTitle">
        <template #top-right>
          <InvoiceRelatedListAction :transactionId="details.ID"/>
        </template>

        <InvoiceRelatedList detailed :dataList="invoiceReplaceList"/>
      </kst-section>
    </template>
  </div>
</template>

<script>
import Info from "../InvInfo.js";

import InvoiceRelatedList from "../../Invoice/RelatedList/InvRL.vue";
import InvoiceRelatedListAction from "../../Invoice/RelatedListAction/InvRLAction.vue";
import RtnInvoiceRelatedList from "../../RtnInvoice/RelatedList/RinvRL.vue";
import RtnInvoiceRelatedListAction from "../../RtnInvoice/RelatedListAction/RinvRLAction.vue";
import StockReductionRelatedList from "../../StockReduction/RelatedList/StrdRL.vue";
import StockReductionRelatedListAction from "../../StockReduction/RelatedListAction/StrdRLAction.vue";

export default {
  components: {
    InvoiceRelatedList,
    InvoiceRelatedListAction,
    RtnInvoiceRelatedList,
    RtnInvoiceRelatedListAction,
    StockReductionRelatedList,
    StockReductionRelatedListAction
  },
  props: {
    access: Object,
    details: Object,
    invoiceReplaceList: Array,
    returnInvoiceList: Array,
    stockReductionList: Array
  },
  computed: {
    invoiceReplaceListSectionTitle() {
      return Info.Section.InvoiceReplaceList.Title;
    },
    rtnInvoiceListSectionTitle() {
      return Info.Section.RtnInvoiceList.Title;
    },
    stockReductionListSectionTitle() {
      return Info.Section.StockReductionList.Title;
    }
  }
}
</script>