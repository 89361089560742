<template>
  <kst-table auto-toggle-details checkable detailed view-mode
    detail-key="ID"
    :checkedRows.sync="userModule.checkedRows"
    :data="moduleList"
    @check="handleCheck"
  >
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="AcsModuleCatalog.Name.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="AcsModuleCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="ModuleGroupName"
      :label="AcsModuleCatalog.ModuleGroupName.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="AcsModuleCatalog"
      />
    </b-table-column>

    <template #detail="{ row }">
      <ModuleAttrRelatedInput
        :moduleAttrList="userModuleAttrList"
        :moduleId="row.ID"
        @submit="handleSubmit(row)"
        @input="handleInput"
      />
    </template>
  </kst-table>
</template>

<script>
import AcsModuleCatalog from "../AcsModuleCatalog.js";
import Model from "../AcsModel.js";

import ModuleAttrRelatedInput from "../../ModuleAttr/RelatedInput/MattReI.vue";

export default {
  components: {
    ModuleAttrRelatedInput
  },
  props: {
    userModule: Object,
    userModuleAttrList: Array,
    moduleList: Array
  },
  data: () => ({
    AcsModuleCatalog: AcsModuleCatalog
  }),
  methods: {
    handleCheck(checkedList, row) {
      Model.updateModulesBySelection(this.userModuleAttrList, checkedList, row);
    },
    handleSubmit(row) {
      if (!this.userModule.checkedRows.includes(row)) {
        this.userModule.checkedRows.push(row);
      }
    },
    handleInput(moduleAttr) {
      Model.selectListByModuleAtrr(
        this.userModule.checkedRows, this.moduleList, moduleAttr
      );
    }
  }
}
</script>