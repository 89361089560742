<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../DebtCatalog.js";
import Info from "../DebtInfo.js";

export default {
  mixins: [XlsxMixin],
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addList(data.List);
      this.download(fileName);
    },

    addList(invoiceList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.PurchasingID.ExportLabel);
      this.addColumnHeader(Catalog.PurchasingID.Label);
      this.addColumnHeader(Catalog.ReceiveNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.DueDate);
      this.addColumnHeader(Catalog.DebtTotal);

      // value
      for (const row of invoiceList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("PurchasingID", row, Catalog);
        this.addCellRelatedValue("PurchasingID", row, Catalog);
        this.addCellValue("ReceiveNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellRelatedValue("VendorID", row, Catalog);
        this.addCellValue("VendorAddress", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellValue("DueDate", row, Catalog);
        this.addCellValue("DebtTotal", row, Catalog);
      }
      
      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>