<template>
  <kst-container class="kst-page-report" :class="theClass">
    <nav class="level mb-0 p-5 ks-border-b-light">
      <div class="level-left">
        <div>
          <kst-title class="m-0">{{ theTitle }}</kst-title>
        </div>
      </div>
      <div class="level-right">
        <template v-if="reloadAreaVisible">
          <kst-button slim-size reload-mode @click="handleReload"/>
        </template>
      </div>
    </nav>

    <div class="kst-page-report__content p-5">
      <kst-skeleton :active="isLoading"/>
      <kst-failure init-mode :items="failureInit"/>

      <template v-if="hasAccess === false">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <Search
          @reset="handleSearch_reset"
          @submit="handleSearch_submit"
        >
          <template #default="{ handleInput }">
            <slot name="search" :handleInput="handleInput"/>
          </template>
        </Search>

        <kst-section search-result-mode v-if="resultVisibile">
          <template #top-left>
            <template v-if="!isReportLoading">
              <kst-button slim-size refresh-mode @click="handleRefresh"/>
            </template>
          </template>

          <template #top-right>
            <slot name="search-result-buttons">
              <template v-if="!isReportLoading">
                <div class="buttons">
                  <template v-if="hasPrintAction">
                    <kst-button action-mode slim-size
                      class="is-primary"
                      :module="printModule"
                      :moduleType="printModuleType"
                      :urlParams="urlParams"
                    />
                  </template>
                  <template v-if="hasExportAction">
                    <kst-button action-mode slim-size
                      class="is-primary"
                      :module="exportModule"
                      :moduleType="exportModuleType"
                      :urlParams="urlParams"
                    />
                  </template>
                </div>
              </template>
            </slot>
          </template>

          <kst-skeleton :active="isReportLoading"/>
          <kst-failure list-mode :items="failureReport"/>

          <template v-if="searchResultSlotVisible">
            <slot
              name="search-result"
              :data="data"
            />
          </template>
        </kst-section>
      </template>
    </div>
  </kst-container>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

import Search from "./PageReport_Search.vue";

/**
 * flow (how to use):
 * 1. handle Init event and do loadReportData
 * 2. handle Reset and Load event, do loadReport
 * 2. handle Loaded event (when loadReport success)
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  components: {
    Search
  },
  props: {
    catalog: Object,
    params: Array,
    resultVisibile: {
      type: Boolean,
      default: true
    },
    search: Object
  },
  data: () => ({
    hasAccess: null,
    isInitialized: false,
    data: {},
    // loading
    isLoading: true,
    isReportLoading: true,
    // support debounce
    loadCounter: 0,
    // area
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    searchResultSlotVisible: false,
    // error
    failureInit: null,
    failureReport: null
  }),
  computed: {
    exportModule(){
      return this.$route.meta.export
        ? this.$route.meta.export.module : null;
    },
    exportModuleType(){
      return this.$route.meta.export
        ? this.$route.meta.export.moduletype : null;
    },
    hasExportAction() {
      if (this.$route.meta.export) {
        const moduleAttr = this.$kst.Session.getModuleAttr();
        return moduleAttr.includes(
          this.$route.meta.export.moduleattr
        );
      }

      return false;
    },
    hasPrintAction() {
      if (this.$route.meta.print) {
        const moduleAttr = this.$kst.Session.getModuleAttr();
        return moduleAttr.includes(
          this.$route.meta.print.moduleattr
        );
      }

      return false;
    },
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    path() {
      return this.$route.path;
    },
    printModule() {
      return this.$route.meta.print
        ? this.$route.meta.print.module : null;
    },
    printModuleType() {
      return this.$route.meta.print
        ? this.$route.meta.print.moduletype : null;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    },
    urlParams() {
      if (!this.resultVisibile) {
        return null;
      }

      const urlQueryObj = this.getUrlQueryObj(
        this.search, this.params, this.catalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  },
  created() {
    this.init();
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        if (!this.isInitialized) {
          this.init_data();
        }

        if (this.hasAccess === true) {
          if (Object.keys(this.$route.query).length !== 0) {
            this.populateUrlQuery(this.search, this.params, this.catalog);
            this.refresh();
          }
          else if(!this.search) {
            this.refresh();
          }
        }
      }
    }
  },
  methods: {
    /*** page template ***/

    init() {
      if (!this.isInitialized) {
        this.init_data();
      }

      if (this.init_validate()) {
        if (Object.keys(this.$route.query).length === 0 && this.search) {
          this.updatePageParams();
        }
      }
    },
    init_data() {
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);
      this.isInitialized = true;
      // loading
      this.isLoading = true;
      this.isReportLoading = true;
      // support debounce
      this.loadCounter = 0;
      // area
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      this.searchResultSlotVisible = false;
      // error
      this.failureInit = null;
      this.failureReport = null;
    },
    init_validate() {
      // check: access
      if (this.hasAccess === false) {
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.isReportLoading = false;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;
      this.searchResultSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleRefresh() {
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess === true) {
        this.$emit(this.$kst.Enum.Event.Load, this.loadReport);
      }
      else {
        this.defaultSlotVisible = false;
      }
    },

    handleReload() {
      this.isInitialized = false;
      this.init();

      if (this.hasAccess === true) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadReportData);
      }
    },

    /*** search section ***/

    handleSearch_reset() {
      this.$emit(this.$kst.Enum.Event.Reset);
      this.updatePageParams();
    },

    handleSearch_submit() {
      this.updatePageParams();
    },

    /*** custom ***/

    loadReportData(config, successCallback) {
      this.data = {};

      this.runAxios(config, successCallback,
        this.loadReportData_success,
        this.loadReportData_fault,
        this.loadReportData_fault,
        null, true
      );
    },
    loadReportData_success(data, successCallback) {
      this.data = data;
      successCallback(data);
      this.init_success();
    },
    loadReportData_fault(message) {
      this.failureInit = message;
      this.init_failed();
    },

    loadReport(config) {
      this.loadCounter++;
      this.failureReport = null;
      this.isReportLoading = true;
      this.searchResultSlotVisible = false;
      this.data = {};

      this.runAxios(config, this.loadCounter,
        this.loadReport_success,
        this.loadReport_fault,
        this.loadReport_fault
      );
    },
    loadReport_end() {
      this.isReportLoading = false;
    },
    loadReport_success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.data = data;
      this.$emit(this.$kst.Enum.Event.Loaded, data);
      this.loadReport_end();
      this.searchResultSlotVisible = true;
    },
    loadReport_fault(message) {
      this.failureReport = message;
      this.loadReport_end();
    },

    refresh() {
      if (this.isLoading) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadReportData);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Load, this.loadReport);
      }
    },

    updatePageParams() {
      this.updateUrlQuery(this.path, this.search, this.params, this.catalog);
    }
  }
}
</script>