<template>
  <kst-page-export-details
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithDetails"
      :label="Info.Export.Details.Excel.Option.Details"
    />
    <template v-if="access.Invoice_Act_List">
      <kst-check
        v-model="userData.WithInvoiceList"
        :label="Info.Export.Details.Excel.Option.InvoiceList"
      />
    </template>
  </kst-page-export-details>
</template>

<script>
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import Export from "./SlsExD_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    access: {},
    userData: {
      ID: null,
      WithDetails: true,
      WithInvoiceList: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id) {
      this.userData.ID = id;

      this.access = this.$kst.Access.create(Model.Access);
      this.userData.WithInvoiceList = this.access.Invoice_Act_List;
    },

    handleExport(loadExport) {
      const config = Services.getExportDetails(this.userData.ID);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>