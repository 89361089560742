import App from "../../services/App/App.js";

export default {
  /*** method ***/

  updateData(data, related) {
    const urlParams = App.Route.getParams({
      [App.Enum.Param.ServiceID]: data.SelfServiceID
    });
    data.SelfServiceUrl = App.Route.getFullPath(
      related.Module, related.ModuleType.New, urlParams
    );
  },

  populateData(userData) {
    return {
      ID: userData.ID,
      SelfServicePin: userData.SelfServicePin
    };
  }
}