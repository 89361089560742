import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./DlvModel.js";
import InvCatalog from "../Invoice/InvCatalog.js";
import InvModel from "../Invoice/InvModel.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Delivery.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Delivery.FullName,
          DeliveryItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.DeliveryItem.Module.FullName + "?",
    New_Invoice:
      "Memilih / menghapus " + Model.Invoice.Module.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + ".",
    Related_NewDelivery:
      "Tidak diperkenankan menambah " + Model.Module.FullName + ", " +
      "saat " + InvCatalog.Status.Label + " dari " + Model.Invoice.Module.FullName +
      " adalah " + InvModel.Status.Closed.Label + "."
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    // general
    Invoice: {
      Title: Model.Invoice.Module.FullName
    },

    // by action
    PrintSJ: {
      ApprovedBy: {
        Header: AppCatalog.InfoSign.Header.ApprovedBy
      },
      CreatedBy: {
        Header: AppCatalog.InfoSign.Header.CreatedBy
      },
      ReceivedBy: {
        Header: AppCatalog.InfoSign.Header.ReceivedBy
      }
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name
  }
}