import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RrcvCatalog.js";
import RrcvItemCatalog from "./RrcvItemCatalog.js";

export default {
  // required in model
  Module: AppModule.RtnReceive,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintList: AppModuleType.PrintList,
    ExportList: AppModuleType.ExportList
  },

  /*** related ***/

  RtnReceiveItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  Receive: {
    Module: AppModule.Receive,
    ModuleType: {
      List: AppModuleType.List,
      Select: AppModuleType.Select
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.RtnReceive_Act_Details,
    Act_Edit: AppModuleAttr.RtnReceive_Act_Edit,
    Act_List: AppModuleAttr.RtnReceive_Act_List,
    Act_New: AppModuleAttr.RtnReceive_Act_New,
    Act_PrintDetails: AppModuleAttr.RtnReceive_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.RtnReceive_Act_PrintList,
    Act_ExportList: AppModuleAttr.RtnReceive_Act_ExportList
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.PrintDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintDetails
    ],
    RelatedList: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintDetails
    ]
  },

  Search: {
    ListFields: ["ReceiveNumber", "ReturnNumber"],
    ListParams: ["Search", "VendorID", "VendorName", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      // user input
      WarehouseID: null,
      ReturnNumber: "",
      ReceiveID: null,
      Comment: "",
      IsAutoNumber: true,
      // by system
      ReceiveNumber: null,
      ReceiveDate: null,
      Date: App.In.getDateToday(),
      VendorID: null,
      VendorAlias: null,
      VendorName: null,
      VendorAddress: null,
      // computed
      Total: 0
    };
  },
  createItem() {
    return {
      ID: null,
      // user input
      DispatchID: null,
      Qty: "",
      // defined by system
      ReceiveItemID: null,
      DispatchName: null,
      DispatchIsActive: true,
      SKU: null,
      PackagingName: "",
      PackagingValue: 1,
      BuyPrice: "",
      // computed
      Packaging: null,
      SubTotal: 0,
      // error
      Errors: [],
      ErrorsColl: [],
      // UI validation
      DispatchID_Valid: true,
      ReceiveItemQty: 0
    }
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      WarehouseID: userData.WarehouseID,
      ReturnNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ReturnNumber),
      ReceiveID: userData.ReceiveID,
      Date: App.Out.getDateString(userData.Date),
      Comment: App.Out.getString(userData.Comment),
      ReceiveNumber: App.Out.getString(userData.ReceiveNumber),
      VendorID: App.Out.getInteger(userData.VendorID),
      VendorName: App.Out.getString(userData.VendorName),
      VendorAddress: App.Out.getString(userData.VendorAddress)
    }
  },
  populateItems(items) {
    let resultItems = [];

    for (const item of items) {
      resultItems.push({
        ID: item.ID,
        ReceiveItemID: item.ReceiveItemID,
        DispatchID: item.DispatchID,
        DispatchName: item.DispatchName,
        Qty: App.Out.getInteger(item.Qty),
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        BuyPrice: App.Out.getInteger(item.BuyPrice)
      });
    }

    return resultItems;
  },
  setItemErrors(item, field, errors) {
    // populate error in current field
    item.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    // custom error message
    if (!item.DispatchID_Valid) {
      if (!item.DispatchIsActive) {
        newErrors.push(RrcvItemCatalog.DispatchID.Label + " tidak aktif");
      }
    }

    item.Errors = newErrors;
  },

  getReceiveSectionHelp(data) {
    return Catalog.ReceiveNumber.Label + 
      ": " + data.ReceiveNumber +
      ", " + Catalog.ReceiveDate.Label + 
      ": " + App.Value.getValue("ReceiveDate", data, Catalog)
  },

  updateDetails(data, items) {
    let total = 0;

    for (const item of items) {
      total += item.SubTotal;
    }
    // Total
    data.Total = total;
  },
  
  updateItem(item) {
    item.Packaging = App.Data.getPackaging(item.PackagingName, item.PackagingValue);
    item.SubTotal = App.Data.getTotal(item.Qty, item.BuyPrice);
  },

  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItem(item) {
    item.DispatchID_Valid = true;

    if (!item.DispatchIsActive) {
      item.DispatchID_Valid = false;
    }
  },

  /*** related ***/

  getReceiveItems_ForSelection(items, receiveItems) {
    let resultItems = [];
    let item;

    for (const receiveItem of receiveItems) {
      item = App.Array.searchItem(items, "ReceiveItemID", receiveItem.ID);
      if (!item) {
        resultItems.push(receiveItem);
      }
    }

    return resultItems;
  }
}