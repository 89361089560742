import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import VenD from "./Details/VenD.vue";
import VenE from "./Edit/VenE.vue";
import VenExD from "./ExportDetails/VenExD.vue";
import VenExL from "./ExportList/VenExL.vue";
import VenPL from "./PrintList/VenPL.vue";
import VenL from "./List/VenL.vue";
import VenN from "./New/VenN.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Vendor, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportList),
    PrintList: RouterModel.getPath(AppModule.Vendor, AppModuleType.PrintList),
    List: RouterModel.getPath(AppModule.Vendor, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Vendor, AppModuleType.New),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.Details),
        component: VenD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.Edit),
        component: VenE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Vendor, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.ExportDetails),
        component: VenExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.ExportList),
        component: VenExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.List),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.List),
        component: VenL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Vendor),
            moduleattr: AppModuleAttr.Vendor_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Vendor),
            moduleattr: AppModuleAttr.Vendor_Act_New,
            moduletype: AppModuleType.New,            
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Vendor),
            moduleattr: AppModuleAttr.Vendor_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.New),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.New),
        component: VenN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Vendor, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Vendor, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Vendor, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Vendor, AppModuleType.PrintList),
        component: VenPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Vendor, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Vendor),
          moduleattr: AppModuleAttr.Vendor_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}