<template>
  <div>
    <div class="columns my-0 is-multiline">
      <div class="column is-2">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="StkSearchCatalog"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="StkSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="LastInvoiceDate" :data="searchData" :catalog="StkSearchCatalog"
          :options="lastInvoiceDateOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="StatusID" :data="searchData" :catalog="StkSearchCatalog"
          :options="statusOptions" @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Model from "../StkModel.js";
import StkSearchCatalog from "../StkSearchCatalog.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    StkSearchCatalog: StkSearchCatalog
  }),
  computed: {
    lastInvoiceDateOptions() {
      return Model.createLastInvoiceDateOptions(StkSearchCatalog.LastInvoiceDate.Label);
    },
    statusOptions() {
      return Model.createStatusOptions(StkSearchCatalog.StatusID.Label);
    }
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>