<template>
  <span>
    <kst-skeleton :active="isLoading"/>

    <template v-if="defaultSlotVisible">
      <slot />
    </template>
  </span>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event, set and do loadData when needed
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  props: {
    activeOnLoad: Boolean,
    // loadingElement = true, not using floating loading
    loadingElement: Boolean,
    loadingOff: Boolean
  },
  data: () => ({
    isLast: false,
    successCallback: null,
    // loading
    dataLoading: null,
    isLoading: false,
    // slot
    defaultSlotVisible: false,
    // support debounce
    loadCounter: 0
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.activeOnLoad) {
        this.defaultSlotVisible = true;
      }

      this.$emit(this.$kst.Enum.Event.Init, this.loadData);
    },

    loadData(config, successCallback, isLast) {
      this.isLast = isLast ? true : false;
      this.defaultSlotVisible = false;
      this.successCallback = successCallback;
      this.loadCounter++;

      if (this.loadingOff) {
        // do nothing
      }
      else if (this.loadingElement) {
        this.isLoading = true;
      }
      else {
        this.dataLoading = this.openLoading();
      }

      this.runAxios(config, this.loadCounter,
        this.loadData_Success,
        this.loadData_Fault,
        this.loadData_Fault
      );
    },
    loadData_End() {
      this.defaultSlotVisible = true;

      if (this.loadingOff) {
        // do nothing
      }
      else if (this.loadingElement) {
        this.isLoading = false;
      }
      else {
        this.closeLoading(this.dataLoading);
        this.dataLoading = null;
      }
    },
    loadData_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.defaultSlotVisible = true;

      this.successCallback(data);
      if (this.isLast) {
        this.loadData_End();
      }
    },
    loadData_Fault(message) {
      this.openSnackbar({
        message: message,
        errorType: true
      });
      this.loadData_End();
    }
  }
}
</script>