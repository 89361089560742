import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import InvoiceModel from "../Invoice/InvModel.js";

export default {
  // required in model
  Module: AppModule.Rec,
  ModuleType: {
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_ExportList: AppModuleAttr.Rec_Act_ExportList,
    Act_List: AppModuleAttr.Rec_Act_List,
    Act_PrintList: AppModuleAttr.Rec_Act_PrintList,
    // related
    Invoice_Act_Details: AppModuleAttr.Invoice_Act_Details
  },

  Search: {
    ListFields: ["SONumber", "PONumber"],
    ListParams: ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID"]
  },

  // related
  Status: InvoiceModel.Status,

  /*** method ***/

  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Open.ID, [rowLabel]: statusEnum.Open.Label },
      { [rowId]: statusEnum.Closed.ID, [rowLabel]: statusEnum.Closed.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  setDetailsByStatus(details, statusEnum) {
    details.StatusName = App.Data.getStatusLabel(details.Status, statusEnum);
  },

  updateResult(dataList) {
    for (const row of dataList) {
      row.UrlParams = App.Route.getParams({
        [AppCatalog.Field.ID.Param]: row.ID
      });
    }
  }
}