<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="AdjNumber"
      :label="Catalog.AdjNumber.Label"
    >
      <kst-value print-mode
        :field="column.field" :data="row" :catalog="Catalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../StopCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>