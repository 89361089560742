<template>
  <kst-modal-confirm
    :title="Info.Modal.Deactivate.Title"
    :width="500"
    @init="handle_Init"
    @submit="handleSubmit"
  >
    {{ Info.Modal.Deactivate.Message }}
  </kst-modal-confirm>
</template>

<script>
import Catalog from "../CliCatalog.js";
import Info from "../CliInfo.js";
import Services from "../../../services/Api/ClientServices.js";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    userData: {
      ID: null
    },
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(id) {
      this.userData.ID = id;

      this.showFunc();
    },

    /*** by modal ***/

    handle_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleSubmit(saveData) {
      const config = Services.deleteSelfService(this.userData.ID);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success(data) {
      this.hideFunc();
      this.$emit(this.$kst.Enum.Event.Submit, data);
    },

    /*** custom ***/

    init_Data() {
      // userData
      this.userData.ID = null;
    }
  }
}
</script>