import App_Input from "./App_Input.js";
import App_Output from "./App_Output.js";

const Key = {
  ApiVersion: "apiVer",
  BackDateInterval: "bdi",
  CompanyAccessibility: "cpAccs",
  CompanyID: "cpID",
  CompanyName: "cpN",
  CompanyImageSrc: "cpImgSrc",
  DefaultMenu: "defMenu",
  DefaultWarehouseID: "defWhsID",
  LastChecked: "lastCk",
  Module: "mdl",
  ModuleAttr: "mdlAttr",
  Name: "nm",
  // self service
  SelfServicePin: "sossPin"
};

export default {
  clear() {
    localStorage.clear();
  },
  companyHasAccess(module) {
    const menuList = this.getCompanyAccessibility();
    return menuList.includes(module.ID);
  },

  getApiVersion() {
    return App_Output.getString(localStorage.getItem(Key.ApiVersion));
  },
  getBackDateInterval() {
    const backDateInterval = App_Output.getInteger(
      localStorage.getItem(Key.BackDateInterval));

    if (backDateInterval === null || backDateInterval < 0) {
      return 0;
    }
    
    return backDateInterval;
  },
  getCompanyAccessibility() {
    const accessibility = App_Output.getString(
      localStorage.getItem(Key.CompanyAccessibility)
    );
    return accessibility === null ? [] : JSON.parse(accessibility);
  },
  getCompanyName() {
    return App_Output.getString(localStorage.getItem(Key.CompanyName));
  },
  getCompanyImageSrc() {
    return App_Output.getString(localStorage.getItem(Key.CompanyImageSrc));
  },
  getDefaultMenu() {
    return App_Output.getInteger(localStorage.getItem(Key.DefaultMenu));
  },
  getDefaultWarehouseID() {
    return App_Output.getInteger(localStorage.getItem(Key.DefaultWarehouseID));
  },
  getLastChecked() {
    return App_Output.getString(localStorage.getItem(Key.LastChecked));
  },
  getModule() {
    const module = App_Output.getString(localStorage.getItem(Key.Module));
    return module === null ? [] : JSON.parse(module);
  },
  getModuleAttr() {
    const moduleAttr = App_Output.getString(localStorage.getItem(Key.ModuleAttr));
    return moduleAttr === null ? [] : JSON.parse(moduleAttr);
  },
  getName() {
    return App_Output.getString(localStorage.getItem(Key.Name));
  },

  setApiVersion(value) {
    localStorage.setItem(Key.ApiVersion, App_Input.getString(value));
  },
  setBackDateInterval(value) {
    localStorage.setItem(Key.BackDateInterval, App_Input.getInteger(value));
  },
  setCompanyAccessibility(value) {
    localStorage.setItem(Key.CompanyAccessibility, JSON.stringify(value));
  },
  setCompanyImageSrc(value) {
    localStorage.setItem(Key.CompanyImageSrc, App_Input.getString(value));
  },
  setCompanyName(value) {
    localStorage.setItem(Key.CompanyName, App_Input.getString(value));
  },
  setDefaultMenu(value) {
    localStorage.setItem(Key.DefaultMenu, App_Input.getInteger(value));
  },
  setDefaultWarehouseID(value) {
    localStorage.setItem(Key.DefaultWarehouseID, App_Input.getInteger(value));
  },
  setLastChecked(value) {
    localStorage.setItem(Key.LastChecked, value);
  },
  setModule(value) {
    localStorage.setItem(Key.Module, JSON.stringify(value));
  },
  setModuleAttr(value) {
    localStorage.setItem(Key.ModuleAttr, JSON.stringify(value));
  },
  setName(value) {
    localStorage.setItem(Key.Name, App_Input.getString(value));
  },

  // self service
  getSelfServicePin() {
    return App_Output.getString(localStorage.getItem(Key.SelfServicePin));
  },
  setSelfServicePin(value) {
    localStorage.setItem(Key.SelfServicePin, App_Input.getString(value));
  }
}