<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="PurItemCatalog.Name.Label"
    >
      <template v-if="row.DispatchID === Model.NewItem.ItemID">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
          :disabled="row.IsClosed" :index="index" @error="handleRowErrors"
        />
      </template>
      <template v-else-if="row.DispatchIsActive">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
          :disabled="row.IsClosed" :index="index" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.Edit.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="PurItemCatalog.PackagingName.Label"
    >
      <template v-if="row.DispatchID === Model.NewItem.ItemID">
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="PurItemCatalog"
        />
      </template>
      <template v-else-if="row.DispatchIsActive">
        <template v-if="row.QtyReceive > 0">
          <kst-output box-off field-only
            field="Packaging" :data="row" :catalog="PurItemCatalog"
          />
        </template>
        <template v-else>
          <kst-input error-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
            :disabled="row.IsClosed" :index="index" :options="row.PackagingOptions"
            @input="handlePackaging(row)" @error="handleRowErrors"
          />
        </template>
      </template>
      <template v-else>
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="PurItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="PurItemCatalog.Quantity.Label"
    >
      <template v-if="row.DispatchID === Model.NewItem.ItemID">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
          :customValidations="row.CustomValidations"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else-if="row.DispatchIsActive">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
          :customValidations="row.CustomValidations"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
        />
      </template>
    </b-table-column>

    <template v-if="access.Fld_Amount">
      <b-table-column numeric
        v-slot="{ column, row, index }"
        cell-class="ks-col-price-button"
        field="BuyPrice"
        :label="PurItemCatalog.BuyPrice.Label"
      >
        <template v-if="row.DispatchID === Model.NewItem.ItemID">
          <kst-input error-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
            :disabled="row.IsClosed" :index="index"
            @input="handleBuyPrice(row)" @error="handleRowErrors"
          />
        </template>
        <template v-else-if="row.DispatchIsActive">
          <kst-input error-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
            :disabled="row.IsClosed" :index="index"
            @input="handleBuyPrice(row)" @error="handleRowErrors"
          >
            <template #right>
              <template v-if="row.DispatchID !== Model.NewItem.ItemID">
                <div class="control">
                  <kst-tooltip search-mode hover-only
                    position="is-bottom"
                    :content="PurItemCatalog.BuyPrice.Label"
                  >
                    <kst-button search-mode @click="handleSearch_Price(index)"/>
                  </kst-tooltip>
                </div>
              </template>
            </template>
          </kst-input>
        </template>
        <template v-else>
          <kst-output box-off field-only
            :field="column.field" :data="row" :catalog="PurItemCatalog"
          />
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="ExpenseAmount"
        :label="PurItemCatalog.ExpenseAmount.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog"
        />
      </b-table-column>
    </template>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-isclosed"
      field="IsClosed"
      :label="PurItemCatalog.IsClosed.Label"
    >
      <template v-if="row.ID !== null">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="PurItemCatalog" :index="index"
          :disabled="isNaN(parseInt(row.Quantity)) || parseInt(row.Quantity) <= row.QtyReceive"
        />
      </template>
    </b-table-column>
    
    <template #action="{ row, index }">
      <template v-if="row.QtyReceive === 0">
        <kst-tooltip delete-mode hover-only :content="Model.PurchaseItem.Module.FullName">
          <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
        </kst-tooltip>
      </template>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    access: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    PurItemCatalog: PurItemCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItemByPackaging(item);
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleBuyPrice(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        null,
        index
      );
    },
    handleRowDelete_Confirm(index) {
      this.userItems.splice(index, 1);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },

    handleSearch_Price(index) {
      this.$emit(this.$kst.Enum.Event.Callback, index);
    }
  }
}
</script>