<template>
  <kst-table view-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="InvItemCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Price"
      :label="InvItemCatalog.Price.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ParentDisc"
      :label="InvItemCatalog.ParentDisc.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="HppTotal"
      :label="InvItemCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Profit"
      :label="InvItemCatalog.Profit.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-percent"
      field="ProfitMargin"
      :label="InvItemCatalog.ProfitMargin.Label"
    >
      <kst-value :class="row.Profit >= 0 ? '' : 'has-text-danger'"
        :field="column.field" :data="row" :catalog="InvItemCatalog"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import InvItemCatalog from "../InvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    InvItemCatalog: InvItemCatalog
  })
}
</script>