<template>
  <kst-page-edit autoValidate
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :invoiceData="invoiceData"
      :invoiceValidation="invoiceValidation"
      :userData="userData"
    />
    <Invoice :invoiceData="invoiceData"/>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Invoice from "./InvED_Invoice.vue";
import Details from "./InvED_Details.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Invoice,
    Details
  },
  data: () => ({
    invoiceData: {},
    invoiceValidation: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const currentDate = this.$kst.Out.getDateString(this.userData.CurrentDate);
      const config = Services.getEditDateData(id, currentDate);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.invoiceData = data.Details;
      this.invoiceValidation = {
        MaxDate: data.MaxDateValidation,
        MinDate: data.MinDateValidation
      };
      
      Model.setDetailsByStatus(this.invoiceData);
      for (const item of this.invoiceData.Items) {
        Model.updateItemReadOnly(item);
      }

      Model.EditDate.setDetailsByData(this.userData, this.invoiceData);
      Model.setDetailsByStatus(this.userData);
    },

    handleSubmit(saveData) {
      let data = Model.EditDate.populateData(this.userData);
      const config = Services.putDate(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.invoiceData = {};
      this.invoiceValidation = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>