<template>
  <Page
    @init="handleInit"
    @save="handleSave"
  >
    <template #header>
      <Header
        :userData="userData"
        :warehouseOptions="warehouseOptions"
        @callback="handleHeader_warehouseCallback"
      />
    </template>

    <template #body>
      <span class="is-hidden">
        <kst-icon add-cart-mode class="kstmod-pos-new__cart_icon"/>
      </span>

      <kst-container class="p-4">
        <div class="columns">
          <div class="column kstmod-pos-new__search">
            <div class="kst-pos-items">
              <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
                <b-tab-item :label="tabs.Scanner">
                  <Scanner
                    :userData="userData"
                    :userItems="userItems"
                    @init="handleScanner_init"
                    @input="handleScanner_input"
                  />
                </b-tab-item>
                <b-tab-item :label="tabs.Search">
                  <Search
                    :userData="userData"
                    :userItems="userItems"
                    @init="handleSearch_init"
                    @input="handleSearch_input"
                  />
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
          <div class="column kstmod-pos-new__cart">
            <Cart
              :userData="userData"
              :userItems="userItems"
            />
          </div>
          <div class="column kstmod-pos-new__checkout">
            <Checkout
              :userData="userData"
              :userItems="userItems"
              :paymentOptions="paymentOptions"
              @submit="handleCheckout_submit"
            />
          </div>
        </div>
      </kst-container>
    </template>
  </Page>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Info from "../PosInfo.js";
import Model from "../PosModel.js";
import Router from "../PosRouter.js";
import Services from "../../../services/Api/PosServices.js";

import Cart from "./PosN_Cart.vue";
import Checkout from "./PosN_Checkout.vue";
import Header from "./PosN_Header.vue";
import Page from "./PosN_Page.vue";
import Scanner from "./PosN_Scanner.vue";
import Search from "./PosN_Search.vue";

export default {
  mixins: [RouterMixin, InputPageMixin],
  components: {
    Cart,
    Checkout,
    Header,
    Page,
    Scanner,
    Search
  },
  data: () => ({
    userData: {},
    userItems: [],
    paymentOptions: {},
    warehouseOptions: {},
    // tabs
    activeTab: 0,
    tabs: {
      Scanner: Info.Tabs.Scanner,
      Search: Info.Tabs.Search
    },
    // func
    reloadScannerFunc: null,
    reloadSearchFunc: null,
    submitFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew, submit) {
      this.submitFunc = submit;
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.paymentOptions = Model.getPaymentOptions(data.PaymentTypeList);
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.setDetailsByPaymentList(this.userData, data.PaymentTypeList);
      Model.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSave(saveData) {
      const data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSave_success);
    },
    handleSave_success(data) {
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: data.id
      });
      this.openTab(Router.Path.PrintThermal + urlParams);

      this.resetAll();
    },

    /*** header ***/

    handleHeader_warehouseCallback() {
      this.reloadScannerFunc();
      this.reloadSearchFunc();
    },

    /*** scanner ***/

    handleScanner_init(reload) {
      this.reloadScannerFunc = reload;
    },
    handleScanner_input(elementItem, userItemIndex) {
      this.animateAddItem(elementItem, userItemIndex);
    },

    /*** search ***/

    handleSearch_init(reload) {
      this.reloadSearchFunc = reload;
    },
    handleSearch_input(elementItem, userItemIndex) {
      this.animateAddItem(elementItem, userItemIndex);
    },

    /*** checkout ***/

    handleCheckout_submit() {
      this.submitFunc();
    },

    /*** custom ***/

    resetAll() {
      this.resetData();
      this.reloadScannerFunc();
      this.reloadSearchFunc();
    },
    resetData() {
      const warehouseId = Object.keys(this.userData).length === 0
        ? null :this.userData.WarehouseID;

      this.userData = Model.createDetails();
      this.userItems = [];

      if (warehouseId !== null) {
        this.userData.WarehouseID = warehouseId;
      }
    },

    animateAddItem(elementItem, userItemIndex) {
      let imgtodrag = elementItem;
      let cartIcon = this.$jquery(".kstmod-pos-new__cart_icon");
      let resultItem = this.$jquery(".kstmod-pos-new__cart_qty").eq(userItemIndex);
      let imgclone = cartIcon.clone()
        .removeClass("kstmod-pos-new__cart_icon")
        .offset({
          top: imgtodrag.offset().top + (imgtodrag.height() / 2),
          left: imgtodrag.offset().left + (imgtodrag.width() / 2)
        })
        .css({
          "position": "absolute",
          "z-index": "100"
        })
        .appendTo(this.$jquery("body"))
        .animate({
            top: resultItem.offset().top + (resultItem.height() / 2),
            left: resultItem.offset().left + (resultItem.width() / 2),
          },
          1000,
          "easeInOutExpo"
        )
      ;

      imgclone.animate(
        {
          "width": 0,
          "height": 0
        },
        () => {
          this.$jquery(imgclone).detach();
        }
      );
    }
  }
}
</script> 

<style scoped lang="css" src="./_layout.css"/>