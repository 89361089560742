<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details :userData="userData"/>
    <CompanyProfile :userData="userData"/>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../WhsModel.js";
import Services from "../../../services/Api/WarehouseService.js";

import Details from "./WhsN_Details.vue";
import CompanyProfile from "./WhsN_CompanyProfile.vue";

export default {
  components:{
    Details,
    CompanyProfile,
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config);
    },
    handleSubmit(saveData) {
      let data = Model.populateData(this.userData);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
    }
  }
}
</script>