<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Details :recPayData="recPayData"/>
    <InvoiceList :recPayData="recPayData"/>
  </kst-section>
</template>

<script>
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";

import Details from "./RcpyES_RecPay_Details.vue";
import InvoiceList from "./RcpyES_RecPay_InvoiceList.vue";

export default {
  components: {
    Details,
    InvoiceList
  },
  props: {
    recPayData: Object
  },
  computed: {
    sectionHelp() {
      return Model.EditStatus.getRecPaySectionHelp(this.recPayData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>