<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-5">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./SearchCatalog.js";

export default {
  props: {
    searchData: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>