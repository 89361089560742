import App from "../../services/App/App.js";
import ModelBody from "./CliModel_Body.js";

export default {
  /*** method ***/

  setDetailsByData(userData, clientData) {
    userData.ID = clientData.ID;
    // user input
    userData.Name = App.In.getString(clientData.Name);
    userData.Alias = App.In.getString(clientData.Alias);
    userData.DaysDueDate = App.In.getInteger(clientData.DaysDueDate);
    userData.Mobile = App.In.getString(clientData.Mobile);
    userData.Phone = App.In.getString(clientData.Phone);
    userData.Email = App.In.getString(clientData.Email);
    userData.Comment = App.In.getString(clientData.Comment);
    userData.DiscPercent = App.In.getDecimal(clientData.DiscPercent);
  },
  setAddressesByData(userAddresses, addressList) {
    App.Array.truncate(userAddresses);

    for (const address of addressList) {
      let userAddress = ModelBody.createAddress();
      this.setAddressesByData_setAddress(userAddress, address);
      userAddresses.push(userAddress);
    }
  },
  setAddressesByData_setAddress(userAddress, addressData) {
    userAddress.ID = addressData.ID;
    // user input
    userAddress.Name = App.In.getString(addressData.Name);
    userAddress.Address = App.In.getString(addressData.Address);
    userAddress.ContactName = App.In.getString(addressData.ContactName);
    userAddress.ContactMobile = App.In.getString(addressData.ContactMobile);
    userAddress.ContactPhone = App.In.getString(addressData.ContactPhone);
    userAddress.ContactEmail = App.In.getString(addressData.ContactEmail);
  }
}