<template>
  <kst-table checkable input-mode paginated
    :checkedRows.sync="userData.SelectedReceiveList"
    :data="userReceiveList"
    :headerCheckable="false"
    @check="handleCheck_Receive"
  >
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="ReceiveDate"
      :label="DbpyRcvCatalog.ReceiveDate.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveNumber"
      :label="DbpyRcvCatalog.ReceiveNumber.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VendorName"
      :label="DbpyRcvCatalog.VendorName.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseID"
      :label="DbpyRcvCatalog.WarehouseID.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="ReceiveBalance"
      :label="DbpyRcvCatalog.ReceiveBalance.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="ReceiveDueDate"
      :label="DbpyRcvCatalog.ReceiveDueDate.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
      />
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row, index }"
      cell-class="ks-col-price"
      field="PaymentValue"
      :label="DbpyRcvCatalog.PaymentValue.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="DbpyRcvCatalog"
        :index="index" @input="handlePaymentValue(row)" @error="handleRowErrors"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import Model from "../DbpyModel.js";
import DbpyRcvCatalog from "../DbpyRcvCatalog.js";

export default {
  props: {
    userData: Object,
    userReceiveList: Array
  },
  data: () => ({
    DbpyRcvCatalog: DbpyRcvCatalog,
    Model: Model
  }),
  methods: {
    handleCheck_Receive(checkedList, row) {
      Model.New.setReceiveListByCheck(this.userData, this.userReceiveList,
        checkedList, row
      );
    },

    handlePaymentValue(row) {
      Model.New.setReceiveByPaymentValue(this.userData, this.userReceiveList,
        row
      );
    },

    handleRowErrors(errors, field, index) {
      Model.New.setReceiveErrors(this.userReceiveList[index], field, errors);
    }
  }
}
</script>