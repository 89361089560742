import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";

export default {
  TypeID: {
    DebtPay: {
      ID: 2,
      Label: AppModule.DebtPay.FullName,
      Module: AppModule.DebtPay,
      ModuleAttr: AppModuleAttr.DebtPay_Act_Details
    },
    Receive: {
      ID: 1,
      Label: AppModule.Receive.FullName,
      Module: AppModule.Receive,
      ModuleAttr: AppModuleAttr.Receive_Act_Details
    },
    RtnReceive: {
      ID: 3,
      Label: AppModule.RtnReceive.FullName,
      Module: AppModule.RtnReceive,
      ModuleAttr: AppModuleAttr.RtnReceive_Act_Details
    },
  }
}