import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  DiscType: {
    None: {
      ID: 0,
      Label: AppCatalog.Message.Without + " " + AppCatalog.Field.Disc.Label
    },
    Value: {
      ID: 1,
      Label: AppCatalog.Field.DiscValue.Label
    },
    Percent: {
      ID: 2,
      Label: AppCatalog.Field.DiscPercent.Label
    }
  },

  IsSelfService: {
    Yes: {
      ID: 1,
      Label: AppCatalog.Info.SalesSelfService,
    },
    No: {
      ID: 0,
      Label: AppCatalog.Info.ShopService,
    }
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label,
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label,
    }
  }
}