import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import SttrEnum from "./SttrEnum.js"
import ItemModel from "../Item/ItemModel.js";

export default {
  // required in model
  Module: AppModule.StockTrf,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/
  
  StockTrfItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.StockTrf_Act_Details,
    Act_ExportList: AppModuleAttr.StockTrf_Act_ExportList,
    Act_List: AppModuleAttr.StockTrf_Act_List,
    Act_New: AppModuleAttr.StockTrf_Act_New,
    Act_PrintDetails: AppModuleAttr.StockTrf_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.StockTrf_Act_PrintList,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails
    ]
  },

  IsActive: SttrEnum.IsActive,

  Search: {
    ListFields: ["StockTrfNumber"],
    ListParams: ["Search", "Date", "DestinationWarehouseID", "SourceWarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      SourceWarehouseID: null,
      DestinationWarehouseID: null,
      StockTrfNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // by system
      Date: App.In.getDateToday(),
      // error
      Errors: [],
      // UI Validation
      StockMissingQty: 0
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      Qty: "",
      PackagingName: "",
      // by system
      DispatchName: "",
      SKU: "",
      DispatchSellPrice: "",
      Stock: 0,
      StockQty: 0,
      TotalQty: 0,
      PackagingValue: 1,
      PackagingOptions: null,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  populateDetails(userData) {
    return {
      SourceWarehouseID: userData.SourceWarehouseID,
      DestinationWarehouseID: userData.DestinationWarehouseID,
      StockTrfNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.StockTrfNumber),
      Comment: App.Out.getString(userData.Comment),
      Date: App.Out.getDateString(userData.Date)
    };
  },
  populateItems(userItems) {
    let resultItems = [];
    for (const row of userItems) {
      resultItems.push({
        DispatchID: row.DispatchID,
        DispatchName: row.DispatchName,
        PackagingName: row.PackagingName,
        PackagingValue: row.PackagingValue,
        Qty: App.Out.getInteger(row.Qty)
      });
    }

    return resultItems;
  },

  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.Stock = App.Data.getQtyByPackaging(
      userItem.StockQty, userItem.PackagingValue
    );
    userItem.TotalQty = App.Data.getTotalQty(
      userItem.Qty, userItem.PackagingValue
    );
  },
  updateItemByPackaging(userItem) {
    if (userItem.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        userItem.PackagingOptions.rows, userItem.PackagingName
      );

      if (packagingData) {
        // by system
        userItem.PackagingValue = packagingData.Qty;
        userItem.DispatchSellPrice = packagingData.SellPrice;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItemsByStock(userData, userItems) {
    // reset indicator
    userData.StockMissingQty = 0;
    App.Array.truncate(userData.Errors);

    const pivotItems = App.Pivot.create(userItems, "DispatchID", [
      { field: "TotalQty", type: App.Pivot.Type.SUM },
      { field: "StockQty", type: App.Pivot.Type.FIRST },
      { field: "DispatchName", type: App.Pivot.Type.FIRST },
    ]);

    for (const row of pivotItems) {
      if (row.TotalQty > row.StockQty) {
        userData.StockMissingQty = 1;

        userData.Errors.push(
          App.Data.getInvalidStockError(
            row.TotalQty, row.StockQty, row.DispatchName
          )
        );
      }
    }
  }
}