import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./SlrcModel_Body.js";
import ModelDetails from "./SlrcModel_Details.js";
import ModelNew from "./SlrcModel_New.js";
import InvoiceEnum from "../Invoice/InvEnum.js";
import InvoiceModel from "../Invoice/InvModel.js";
import UserModel from "../User/UserModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.SalesRecap,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportDetails: AppModuleType.ExportDetails,
    List: AppModuleType.List,
    New: AppModuleType.New,    
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  Invoice: ModelBody.Invoice,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  
  Status: InvoiceEnum.Status,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },

  setDetailsByPaymentTypeCash(userData, cashValue) {
    ModelBody.setDetailsByPaymentTypeCash(userData, cashValue);
  },

  updateDetails(userData, userInvoiceList) {
    return ModelBody.updateDetails(userData, userInvoiceList);
  },

  populateData(userData, userInvoiceList) {
    let result = ModelBody.populateDetails(userData);
    result.InvoiceList = ModelBody.populateInvoices(userInvoiceList);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getPaymentTypeSummaryList(invoiceList) {
      return ModelDetails.getPaymentTypeSummaryList(invoiceList);
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByFullReceived(userData) {
      ModelNew.setDetailsByFullReceived(userData);
    },
    setDetailsByReload(userData) {
      ModelNew.setDetailsByReload(userData);
    },
    setInvoiceListByInvoiceList(userInvoiceList, invoiceList) {
      ModelNew.setInvoiceListByInvoiceList(userInvoiceList, invoiceList);
    }
  },

  /*** external ***/

  InvoiceRecordType: InvoiceEnum.RecordType,

  getCashierOptions(warehouseList, optionAllText) {
    return UserModel.createOptions(warehouseList, optionAllText);
  },

  getInvoiceRecordTypeOptions(optionAllText) {
    return InvoiceModel.createRecordTypeOptions(optionAllText);
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}