import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import AcsD from "./Details/AcsD.vue";
import AcsE from "./Edit/AcsE.vue";
import AcsL from "./List/AcsL.vue";
import AcsN from "./New/AcsN.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Access, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Access, AppModuleType.Edit),
    List: RouterModel.getPath(AppModule.Access, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Access, AppModuleType.New)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Access, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Access, AppModuleType.Details),
        component: AcsD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Access, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Access),
          moduleattr: AppModuleAttr.Access_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Access, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Access, AppModuleType.Edit),
        component: AcsE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Access, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Access),
          moduleattr: AppModuleAttr.Access_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Access, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Access, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Access, AppModuleType.List),
        name: RouterModel.getName(AppModule.Access, AppModuleType.List),
        component: AcsL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Access, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Access),
          moduleattr: AppModuleAttr.Access_Act_List,
          moduletype: AppModuleType.List,
          new: {
            module: RouterModel.getModule(AppModule.Access),
            moduleattr: AppModuleAttr.Access_Act_New,
            moduletype: AppModuleType.New
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Access, AppModuleType.New),
        name: RouterModel.getName(AppModule.Access, AppModuleType.New),
        component: AcsN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Access, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Access),
          moduleattr: AppModuleAttr.Access_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Access, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Access, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  }
}