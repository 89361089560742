import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import Info from "./DashInfo.js";
import ModelBody from "./DashModel_Body.js";
import StkEnum from "../Stock/StkEnum.js";

export default {
  /*** method - BestSellingItems ***/

  getInvoiceUrlObj_byBestSellingItems(searchData) {
    const search = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: searchData.Date,
      RecordTypeID: App.Search.OptionAll,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return {
      Search: search,
      Params: params
    };
  },

  /*** method - Data ***/

  getDataList(transDetails, access, searchData) {
    let resultList = [], result;

    if (access.Sect_Stock) {
      result = this.getDataList_getStock(transDetails, access, searchData);
      if (result !== null) {
        resultList.push(result);
      }
    }

    if (access.Sect_Debt) {
      result = this.getDataList_getDebt(transDetails, access, searchData);
      if (result !== null) {
        resultList.push(result);
      }
    }

    if (access.Sect_DebtOverdue) {
      result = this.getDataList_getDebtOverdue(transDetails, access, searchData);
      if (result !== null) {
        resultList.push(result);
      }
    }

    if (access.Sect_Rec) {
      result = this.getDataList_getRec(transDetails, access, searchData);
      if (result !== null) {
        resultList.push(result);
      }
    }

    if (access.Sect_RecOverdue) {
      result = this.getDataList_getRecOverdue(transDetails, access, searchData);
      if (result !== null) {
        resultList.push(result);
      }
    }

    return resultList.length === 0 ? null : resultList;
  },
  getDataList_getDebt(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "Debt")) {
      return null;
    }

    const search = {
      Search: "",
      VendorID: null,
      VendorName: "",
      Date: transDetails.Debt.DateMin === null
        ? App.In.getDefaultDateRange()
        : [App.In.getDate(transDetails.Debt.DateMin),
            App.In.getDate(transDetails.Debt.DateMax)
          ],
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "VendorID", "VendorName", "Date", "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.Debt_Act_List, AppModule.Debt.FullName, AppModule.Debt,
      transDetails.Debt.DebtTotal, search, params
    );
  },
  getDataList_getDebtOverdue(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "DebtOverdue")) {
      return null;
    }

    const search = {
      Search: "",
      VendorID: null,
      VendorName: "",
      Date: transDetails.DebtOverdue.DateMin === null
        ? App.In.getDefaultDateRange()
        : [App.In.getDate(transDetails.DebtOverdue.DateMin),
            App.In.getDate(transDetails.DebtOverdue.DateMax)
          ],
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "VendorID", "VendorName", "Date", "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.Debt_Act_List, Info.Transaction.DebtOverdue, AppModule.Debt,
      transDetails.DebtOverdue.DebtTotal, search, params
    );
  },
  getDataList_getRec(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "Rec")) {
      return null;
    }

    const search = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: transDetails.Rec.InvoiceDateMin === null
        ? App.In.getDefaultDateRange()
        : [App.In.getDate(transDetails.Rec.InvoiceDateMin),
            App.In.getDate(transDetails.Rec.InvoiceDateMax)
          ],
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.Rec_Act_List, AppModule.Rec.FullName, AppModule.Rec,
      transDetails.Rec.RecTotal, search, params
    );
  },
  getDataList_getRecOverdue(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "RecOverdue")) {
      return null;
    }

    const search = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: transDetails.RecOverdue.InvoiceDateMin === null
        ? App.In.getDefaultDateRange()
        : [App.In.getDate(transDetails.RecOverdue.InvoiceDateMin),
            App.In.getDate(transDetails.RecOverdue.InvoiceDateMax)
          ],
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.Rec_Act_List, Info.Transaction.RecOverdue, AppModule.Rec,
      transDetails.RecOverdue.RecTotal, search, params
    );
  },
  getDataList_getStock(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "Stock")) {
      return null;
    }

    const search = {
      Search: "",
      LastInvoiceDate: App.Search.OptionAll,
      StatusID: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "LastInvoiceDate", "StatusID", "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.Item_Stock_Act_List, AppModule.Stock.FullName, AppModule.Stock,
      transDetails.Stock.HppTotal, search, params
    );
  },

  /*** method - DeathStock ***/

  getStockUrlObj_byDeathStock(searchData) {
    const search = {
      Search: "",
      LastInvoiceDate: StkEnum.LastInvoiceDate.Available.ID,
      StatusID: StkEnum.Status.Available.ID,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "LastInvoiceDate", "StatusID", "WarehouseID"];

    return {
      Search: search,
      Params: params
    };
  },

  /*** method - LowStock ***/

  getLowStockUrlObj(searchData) {
    const search = {
      Search: "",
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "WarehouseID"];

    return {
      Search: search,
      Params: params
    };
  },

  /*** method - StockWithoutInvoice ***/

  getStockUrlObj_byStockWithoutInvoice(searchData) {
    const search = {
      Search: "",
      LastInvoiceDate: StkEnum.LastInvoiceDate.NotAvailable.ID,
      StatusID: StkEnum.Status.Available.ID,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "LastInvoiceDate", "StatusID", "WarehouseID"];

    return {
      Search: search,
      Params: params
    };
  },

  /*** method - TopBuyer ***/

  getInvoiceUrlObj_byTopBuyer(searchData) {
    const search = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: searchData.Date,
      RecordTypeID: App.Search.OptionAll,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return {
      Search: search,
      Params: params
    };
  },
  getRtnInvoiceUrlObj_byTopBuyer(searchData) {
    const search = {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "ClientID", "ClientName", "Date", "WarehouseID"];

    return {
      Search: search,
      Params: params
    };
  },

  /*** method - TransSummary ***/

  getTransSummaryList(transDetails, access, searchData) {
    let resultList = [], result;

    if (access.Sect_ProfitLoss) {
      result = this.getTransSummaryList_getProfitLoss(
        transDetails, access, searchData
      );
      if (result !== null) {
        resultList.push(result);
      }
    }

    if (access.Sect_Invoice) {
      result = this.getTransSummaryList_getInvoice(
        transDetails, access, searchData
      );
      if (result !== null) {
        resultList.push(result);
      }
    }

    return resultList.length === 0 ? null : resultList;
  },
  getTransSummaryList_getInvoice(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "Invoice")) {
      return null;
    }

    const search = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: searchData.Date,
      RecordTypeID: App.Search.OptionAll,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return ModelBody.createSummaryRow(
      access.Invoice_Act_List, AppModule.Invoice.FullName, AppModule.Invoice,
      transDetails.Invoice.Total, search, params
    );
  },
  getTransSummaryList_getProfitLoss(transDetails, access, searchData) {
    if (!Object.prototype.hasOwnProperty.call(transDetails, "ProfitLoss")) {
      return null;
    }

    const search = {
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const params = ["Date", "WarehouseID"];

    return ModelBody.createSummaryRow(
      access.ProfitLoss_Act_Report, Info.Transaction.ProfitLoss, AppModule.ProfitLoss,
      transDetails.ProfitLoss.Total, search, params
    );
  }
}