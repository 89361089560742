<template>
  <div class="field has-addons" :class="theClass">
    <div class="control kst-actions__default-button">
      <kst-button action-mode
        class="is-primary"
        target=""
        :fullLabel="fullLabel"
        :module="actions[0][modulePrefix]"
        :moduleType="actions[0][moduleTypePrefix]"
        :slim-size="slimSize"
        :urlParams="actions[0][urlParamsPrefix]"
      />
    </div>
    <template v-if="actions.length > 1">
      <div class="control" :class="theDropdownClass">
        <kst-action normal-mode :slim-size="slimSize">
          <template v-for="(item, index) in actions">
            <template v-if="index !== 0">
              <kst-action-item :key="index">
                <kst-button action-mode
                  :fullLabel="fullLabel"
                  :module="actions[index][modulePrefix]"
                  :moduleType="actions[index][moduleTypePrefix]"
                  :urlParams="actions[index][urlParamsPrefix]"
                />
              </kst-action-item>
            </template>
          </template>
        </kst-action>
      </div>
    </template>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    actions: Array,
    fullLabel: Boolean,
    // size
    slimSize: Boolean
  },
  computed: {
    modulePrefix() {
      return AppCatalog.Action.Prefix.Module;
    },
    moduleTypePrefix() {
      return AppCatalog.Action.Prefix.ModuleType;
    },
    theClass() {
      return this.totalAlwaysVisibleActions === 0
        ? AppCatalog.Actions.HasDefault.WithoutAlwaysVisible.Class : "";
    },
    theDropdownClass() {
      return this.totalAlwaysVisibleActions === 0
        ? AppCatalog.Button.Mobile.Class : "";
    },
    totalAlwaysVisibleActions() {
      let total = 0, moduleType;

      for (let index = 1; index < this.actions.length; index++) {
        moduleType = this.actions[index][this.moduleTypePrefix];
        if (!this.$kst.Data.isModuleType_hideActionInMobile(moduleType)) {
          total++;
        }
      }

      return total;
    },
    urlParamsPrefix() {
      return AppCatalog.Action.Prefix.UrlParams;
    }
  }
}
</script>