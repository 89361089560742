<template>
  <kst-action :class="theClass">
    <template v-for="(item, index) in actions">
      <kst-action-item :key="index">
        <kst-button action-mode
          :fullLabel="fullLabel"
          :module="actions[index][modulePrefix]"
          :moduleType="actions[index][moduleTypePrefix]"
          :urlParams="actions[index][urlParamsPrefix]"
        />
      </kst-action-item>
    </template>
  </kst-action>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    actions: Array,
    fullLabel: Boolean
  },
  computed: {
    modulePrefix() {
      return AppCatalog.Action.Prefix.Module;
    },
    moduleTypePrefix() {
      return AppCatalog.Action.Prefix.ModuleType;
    },
    theClass() {
      return this.totalAlwaysVisibleActions === 0
        ? AppCatalog.Button.Mobile.Class : "";
    },
    totalAlwaysVisibleActions() {
      let total = 0, moduleType;

      for (let index = 0; index < this.actions.length; index++) {
        moduleType = this.actions[index][this.moduleTypePrefix];
        if (!this.$kst.Data.isModuleType_hideActionInMobile(moduleType)) {
          total++;
        }
      }

      return total;
    },
    urlParamsPrefix() {
      return AppCatalog.Action.Prefix.UrlParams;
    }
  }
}
</script>