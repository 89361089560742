<template>
  <div class="block">
    <kst-input field="name" :data="userData" :catalog="Catalog"/>
    <kst-input field="address" :data="userData" :catalog="Catalog"/>
    <kst-input field="phone" :data="userData" :catalog="Catalog"/>
    <kst-input field="email" :data="userData" :catalog="Catalog"/>
    <kst-input field="owner" :data="userData" :catalog="Catalog"/>
    <kst-output field="ImageSrc" :data="userData" :catalog="Catalog">
      <template slot="tooltips">
        <kst-tooltip class="ml-2" info-mode position="is-bottom">
          <template #content>
            <kst-list :items="imageFieldTooltipList"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>

      <kst-image :src="userData.ImageSrc" :isLocal="userData.ImageIsLocal"
        @input="handleImageInput" @delete="handleImageDelete"/>
    </kst-output>
  </div>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Model from "../CpModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    imageFieldTooltipList() {
      return Model.Edit.getImageFieldTooltipList();
    }
  },
  methods: {
    handleImageInput(imgSrc, imgText) {
      this.userData.ImageSrc = imgSrc;
      this.userData.Image64 = imgText;
      this.userData.ImageIsLocal = true;
    },
    handleImageDelete() {
      this.userData.ImageSrc = "";
      this.userData.Image64 = "";
      this.userData.ImageIsLocal = true;
    }
  }
}
</script>