import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import DlvD from "./Details/DlvD.vue";
import DlvES from "./EditStatus/DlvES.vue";
import DlvExL from "./ExportList/DlvExL.vue";
import DlvL from "./List/DlvL.vue";
import DlvN from "./New/DlvN.vue";
import DlvPL from "./PrintList/DlvPL.vue";
import DlvPSJ from "./PrintSJ/DlvPSJ.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
    EditStatus: RouterModel.getPath(AppModule.Delivery, AppModuleType.EditStatus),
    List: RouterModel.getPath(AppModule.Delivery, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Delivery, AppModuleType.New),
    PrintList: RouterModel.getPath(AppModule.Delivery, AppModuleType.PrintList),
    PrintSJ: RouterModel.getPath(AppModule.Delivery, AppModuleType.PrintSJ)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.Details),
        component: DlvD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.EditStatus),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.EditStatus),
        component: DlvES,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.EditStatus),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_EditStatus,
          moduletype: AppModuleType.EditStatus,
          cancel: {
            url: RouterModel.getPath(AppModule.Delivery, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.ExportList),
        component: DlvExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.List),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.List),
        component: DlvL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Delivery),
            moduleattr: AppModuleAttr.Delivery_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Delivery),
            moduleattr: AppModuleAttr.Delivery_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Delivery),
            moduleattr: AppModuleAttr.Delivery_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.New),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.New),
        component: DlvN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Delivery, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Delivery, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.PrintSJ),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.PrintSJ),
        component: DlvPSJ,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.PrintSJ),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_PrintSJ,
          moduletype: AppModuleType.PrintSJ
        }
      },
      {
        path: RouterModel.getPath(AppModule.Delivery, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Delivery, AppModuleType.PrintList),
        component: DlvPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Delivery, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Delivery),
          moduleattr: AppModuleAttr.Delivery_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}