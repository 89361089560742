import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Client: {
    Label: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.Name,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  ClientAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    PrintLabel: AppCatalog.Field.Date.Label + " Transaksi",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  PONumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Reference.Type,
    ReadOnly: true
  },
  SONumber: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },

  // support
  ClientAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  InvoiceTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Invoice.Name,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  }
}