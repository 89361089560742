<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="StockTrfNumber"
      :label="Catalog.StockTrfNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SourceWarehouseID"
      :label="Catalog.SourceWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DestinationWarehouseID"
      :label="Catalog.DestinationWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../SttrCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>