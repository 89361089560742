<template>
  <div class="ks-is-fullwidth">
    <template v-if="hasComment">
      <kst-divider class="has-background-black"/>
      <kst-value class="is-size-5" field="Comment" :data="details" :catalog="Catalog"/>
      <kst-divider class="has-background-black"/>
    </template>
  </div>
</template>

<script>
import Catalog from "../PurCatalog.js";

export default {
  computed: {
    hasComment() {
      return this.$kst.Out.getString(this.details.Comment) !== null;
    }
  },
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>