import App from "../../services/App/App.js";
import SttrItemCatalog from "./SttrItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.SourceWarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setItemByItemDetails(userItem, itemData) {
    userItem.DispatchName = itemData.Name;
    userItem.SKU = itemData.SKU;

    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemData.PackagingList
    );

    const firstPackaging = userItem.PackagingOptions.rows[0];
    userItem.DispatchSellPrice = firstPackaging.SellPrice;
    userItem.PackagingName = firstPackaging.Name;
    userItem.PackagingValue = firstPackaging.Qty;
  },
  setItemByStock(userItem, stockData) {
    // user input
    userItem.DispatchID = stockData.ItemID;
    // by system
    userItem.StockQty = stockData.Quantity;
  },

  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(SttrItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, SttrItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(SttrItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", userItem, SttrItemCatalog)
    );

    // Stock
    infoList.push(SttrItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", userItem, SttrItemCatalog) + " " +
      userItem.PackagingName
    );

    return infoList;
  }
}