<template>
  <kst-section :title="sectionTitle">
    <template #top-left>
      <template v-if="data.SelfServiceID === null">
        <kst-button action-mode slim-size
          class="is-primary"
          :module="Model.Module"
          :moduleType="Model.ModuleType.Activate"
          @click="handleActivate"
        />
      </template>
      <template v-else>
        <div class="buttons">
          <kst-button action-mode slim-size
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.EditPin"
            @click="handleActivate"
          />
          <kst-button action-mode slim-size
            class="is-primary"
            :module="Model.Module"
            :moduleType="Model.ModuleType.Deactivate"
            @click="handleDeactivate"
          />
        </div>
      </template>
    </template>

    <ActivateSelfService
      @init="handleActivate_Init"
      @submit="handleActivate_Submit"
    />

    <DeactivateSelfService
      @init="handleDeactivate_Init"
      @submit="handleDeactivate_Submit"
    />

    <template v-if="data.SelfServiceID === null">
      <kst-table-empty/>
    </template>
    <template v-else>
      <kst-output field="SelfServiceUrl" :data="data" :catalog="Catalog"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../CliCatalog.js";
import Info from "../CliInfo.js";
import Model from "../CliModel.js";

import ActivateSelfService from "../ActivateSelfService/CliASelfService.vue";
import DeactivateSelfService from "../DeactivateSelfService/CliDASelfService.vue";

export default {
  components: {
    ActivateSelfService,
    DeactivateSelfService
  },
  props: {
    data: Object,
    id: Number
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    // func
    showActivateFunc: null,
    showDeactivateFunc: null
  }),
  computed: {
    sectionTitle() {
      return Info.Section.SelfService.Title;
    }
  },
  methods: {
    /*** by ActivateSelfService ***/

    handleActivate_Init(show) {
      this.showActivateFunc = show;
    },
    handleActivate_Submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },

    /*** by DeactivateSelfService ***/

    handleDeactivate_Init(show) {
      this.showDeactivateFunc = show;
    },
    handleDeactivate_Submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },

    /*** custom ***/

    handleActivate() {
      this.showActivateFunc(this.id, this.data.SelfServiceID);
    },
    handleDeactivate() {
      this.showDeactivateFunc(this.id);
    }
  }
}
</script>