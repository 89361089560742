<template>
  <kst-page-report exportExcel
    :catalog="RccdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <Result
        :searchData="searchData"
        :dataList="data.List"
      />
    </template>
  </kst-page-report>
</template>

<script>
import Model from "../RccdModel.js";
import RccdSearchCatalog from "../RccdSearchCatalog.js";
import Services from "../../../services/Api/RecCardServices.js";

import Result from "./RccdR_Result.vue";
import Search from "./RccdR_Search.vue";

export default {
  components: {
    Result,
    Search
  },
  data: () => ({
    RccdSearchCatalog: RccdSearchCatalog,
    Model: Model,
    searchData: {
      TransactionDate: null,
      ClientID: null,
      ClientName: null,
      TypeID: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadReportData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getReportData(options);
      loadReportData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      if (this.searchData.ClientID !== null) {
        this.searchData.ClientName = this.$kst.In.getString(
          data.Search.ClientName);
      }

      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, RccdSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadReport) {
      const options = this.getListOptions();
      const config = Services.getReport(options);
      loadReport(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.warehouseOptions = {};

      this.normalizeSearch();
    },
    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },
    resetSearch() {
      this.searchData.TransactionDate = this.$kst.In.getDefaultDateRange();
      this.searchData.ClientID = null;
      this.searchData.ClientName = null;
      this.searchData.TypeID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;

      return {
        client: clientId,
        clientname: clientName,
        type: this.$kst.Search.getValue(this.searchData.TypeID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.TransactionDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.TransactionDate[1])
      };
    }
  }
}
</script>