import App from "../../services/App/App.js";
import SlsItemCatalog from "./SlsItemCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(SlsItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, SlsItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(SlsItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", userItem, SlsItemCatalog)
    );

    // Stock
    infoList.push(SlsItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", userItem, SlsItemCatalog) + " " +
      userItem.PackagingName
    );

    return infoList;
  }
}