<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import InvItemCatalog from "../InvItemCatalog.js";
import InvProfitCatalog from "../InvProfitCatalog.js";
import InvRecPayCatalog from "../InvRecPayCatalog.js";
import Model from "../InvModel.js";
import DlvCatalog from "../../Delivery/DlvCatalog.js";
import DlvItemCatalog from "../../Delivery/DlvItemCatalog.js";
import DlvModel from "../../Delivery/DlvModel.js";
import RcpyCatalog from "../../RecPay/RcpyCatalog.js";
import RcpyInvCatalog from "../../RecPay/RcpyInvCatalog.js";
import RcpyModel from "../../RecPay/RcpyModel.js";
import RinvCatalog from "../../RtnInvoice/RinvCatalog.js";
import RinvItemCatalog from "../../RtnInvoice/RinvItemCatalog.js";
import SlrcCatalog from "../../SalesRecap/SlrcCatalog.js";
import StrdCatalog from "../../StockReduction/StrdCatalog.js";
import StrdItemCatalog from "../../StockReduction/StrdItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details, data.SpecialPriceCount);

      if (this.userData.WithDeliveryList) {
        if (Object.prototype.hasOwnProperty.call(data, "DeliveryList")) {
          this.createSheet();
          this.addDeliveryList(
            data.Details, data.DeliveryList, data.DeliveryItemList
          );
        }
      }

      if (this.userData.WithRtnInvoiceList 
        || this.userData.WithStockReductionList 
        || this.userData.WithInvoiceReplaceList
      ) {
        const hasRtnInvoiceList = Object.prototype.hasOwnProperty.call(
          data, "RtnInvoiceList"
        );
        const hasStockReductionList = Object.prototype.hasOwnProperty.call(
          data, "StockReductionList"
        );
        const hasInvoiceReplaceList = Object.prototype.hasOwnProperty.call(
          data, "InvoiceReplaceList"
        );

        if (hasRtnInvoiceList || hasStockReductionList 
          || hasInvoiceReplaceList
        ) {
          this.createSheet();

          if (hasRtnInvoiceList) {
            this.addRtnInvoiceList(data.RtnInvoiceList);
          }

          if (hasRtnInvoiceList & hasStockReductionList) {
            this.addRow();
            this.addRow();
          }

          if (hasStockReductionList) {
            this.addStockReductionList(data.StockReductionList,
              data.SpecialPriceCount
            );
          }

          if( (hasRtnInvoiceList && hasInvoiceReplaceList)
            || (hasStockReductionList && hasInvoiceReplaceList)
          ) {
            this.addRow();
            this.addRow();
          }

          if(hasInvoiceReplaceList){
            this.addInvoiceReplaceList(data.InvoiceReplaceList)
          }

          this.addSheet(Info.Export.Details.Excel.SheetName.Return);
        }
      }

      if (this.userData.WithProfit) {
        if (Object.prototype.hasOwnProperty.call(data, "Profit")) {
          this.createSheet();
          this.addProfit(data.Details, data.Profit.RtnInvoiceList);
        }
      }

      if (this.userData.WithSalesRecapDetails) {
        if (Object.prototype.hasOwnProperty.call(data, "SalesRecapDetails")) {
          this.createSheet();
          this.addSalesRecapDetails(data.SalesRecapDetails);
        }
      }

      if (this.userData.WithRecPayList) {
        if (Object.prototype.hasOwnProperty.call(data, "RecPay")) {
          if (data.RecPay !== null) {
            let recData = data.RecPay;
            Model.ExportDetails.updateRecData(recData, data.Details);

            this.createSheet();
            this.addRecPayList(recData);
          }
        }
      }

      this.download(fileName);
    },

    addDetails(details, specialPriceCount) {
      this.addDetails_details(details);
      this.addDetails_items(details.Items, specialPriceCount);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.SalesDraftID.HeaderLabel);
      this.addColumnHeader(Catalog.SalesDraftID.Label);
      this.addColumnHeader(Catalog.DraftDate);
      this.addColumnHeader(Catalog.SalesDraftIsActive);

      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader_ID(Catalog.TransactionID.Label);
      this.addColumnHeader(Catalog.TransactionID);
      this.addColumnHeader(Catalog.TransactionDate);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientAlias);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.Status);

      this.addColumnHeader(Catalog.IsNeedDelivery);
      this.addColumnHeader(Catalog.DeliveryDate);

      this.addColumnHeader(Catalog.TotalBruto);
      this.addColumnHeader(Catalog.TotalDisc);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("SalesDraftID", details, Catalog);
      this.addCellRelatedValue("SalesDraftID", details, Catalog);
      this.addCellValue("DraftDate", details, Catalog);
      if (details.SalesDraftID !== null) {
        this.addCellStatus(details.SalesDraftIsActive, 
          Model.StatusSalesDraftIsActive);
      } else {
        this.addCell();
      }
      this.addCellValue("SONumber", details, Catalog);
      this.addCellValue("InvoiceDate", details, Catalog);
      this.addCellValue("TransactionID", details, Catalog);
      this.addCellRelatedValue("TransactionID", details, Catalog);
      this.addCellValue("TransactionDate", details, Catalog);
      this.addCellValue("ClientID", details, Catalog);
      this.addCellValue("ClientAlias", details, Catalog);
      this.addCellRelatedValue("ClientID", details, Catalog);
      this.addCellValue("ClientAddress", details, Catalog);
      this.addCellValue("PONumber", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue(details.DiscValue ? "DiscValue" : "DiscPercent",
        details, Catalog
      );
      this.addCellValue("Comment", details, Catalog);
      this.addCellStatus(details.Status, Model.Status);

      this.addCellValue("IsNeedDelivery", details, Catalog);
      this.addCellValue("DeliveryDate", details, Catalog);

      this.addCellValue("TotalBruto", details, Catalog);
      this.addCellValue("TotalDisc", details, Catalog);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },
    addDetails_items(items, specialPriceCount) {
      this.addRow();
      this.addRow();
      this.addCellSection_items();
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(InvItemCatalog.DispatchID.Label);
      this.addColumnHeader(InvItemCatalog.DispatchID);
      this.addColumnHeader(InvItemCatalog.SKU);
      this.addColumnHeader(InvItemCatalog.Packaging);
      this.addColumnHeader(InvItemCatalog.RequestedQuantity);
      this.addColumnHeader(InvItemCatalog.TotalRequestedQuantity);
      this.addColumnHeader(InvItemCatalog.SellPrice);
      if (specialPriceCount > 0) {
        this.addColumnHeader_ID(InvItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(InvItemCatalog.SpecialPriceID);
        this.addColumnHeader(InvItemCatalog.SpecialPriceDiscPercent);
      }
      this.addColumnHeader(InvItemCatalog.Price);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(InvItemCatalog.HppTotal);
      }

      // value
      for (const row of items) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, InvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, InvItemCatalog);
        this.addCellValue("SKU", row, InvItemCatalog);
        this.addCellValue("Packaging", row, InvItemCatalog);
        this.addCellValue("RequestedQuantity", row, InvItemCatalog);
        this.addCellValue("TotalRequestedQuantity", row, InvItemCatalog);
        this.addCellValue("SellPrice", row, InvItemCatalog);

        if (specialPriceCount > 0) {
          this.addCellValue("SpecialPriceID", row, InvItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, InvItemCatalog);
          this.addCellRelatedValue("SpecialPriceDiscPercent", row, InvItemCatalog);
        }

        this.addCellValue("Price", row, InvItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, InvItemCatalog);
        }
      }
    },

    addDeliveryList(details, deliveryList, deliveryItemList) {
      if (Array.isArray(deliveryList)) {
        const itemsSummary = Model.ExportDetails.getItemsSummaryByDelivery(
          details, deliveryItemList
        );

        this.addDeliveryList_itemsSummary(itemsSummary);
        this.addDeliveryList_listWithItems(deliveryList);
      }
      else {
        this.addCell(Info.Message.Details_NoDelivery);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.Delivery);
    },
    addDeliveryList_itemsSummary(itemsSummary) {
      this.addCellSection(Info.Section.Summary.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(InvItemCatalog.DispatchID.Label);
      this.addColumnHeader(InvItemCatalog.DispatchID);
      this.addColumnHeader(InvItemCatalog.SKU);
      this.addColumnHeader(InvItemCatalog.Packaging);
      this.addColumnHeader(InvItemCatalog.RequestedQuantity.SummaryLabel);
      this.addColumnHeader(InvItemCatalog.DeliveryQuantity);

      // value
      for (const row of itemsSummary) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, InvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, InvItemCatalog);
        this.addCellValue("SKU", row, InvItemCatalog);
        this.addCellValue("Packaging", row, InvItemCatalog);
        this.addCellValue("RequestedQuantity", row, InvItemCatalog);
        this.addCellValue("DeliveryQuantity", row, InvItemCatalog);
      }
    },
    addDeliveryList_listWithItems(deliveryList) {
      this.addRow();
      this.addRow();
      this.addCellSection_list(Model.Delivery.Module);
      this.addRow();

      // label - Delivery
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(DlvCatalog.LogisticsNumber);
      this.addColumnHeader(DlvCatalog.DepartureDate);
      this.addColumnHeader(DlvCatalog.VehicleIDNumber);
      this.addColumnHeader(DlvCatalog.Destination);
      this.addColumnHeader(DlvCatalog.ShipmentFee);
      this.addColumnHeader(DlvCatalog.ArrivalDate);
      this.addColumnHeader(DlvCatalog.CompletedDate);
      this.addColumnHeader(DlvCatalog.TotalWeight);
      this.addColumnHeader(DlvCatalog.Status);

      // label - Delivery Item
      this.addColumnHeader_ID(DlvItemCatalog.DispatchID.Label);
      this.addColumnHeader(DlvItemCatalog.DispatchID);
      this.addColumnHeader(DlvItemCatalog.SKU);
      this.addColumnHeader(DlvItemCatalog.Packaging);
      this.addColumnHeader(DlvItemCatalog.Quantity);
      this.addColumnHeader(DlvItemCatalog.TotalQuantity);
      this.addColumnHeader(DlvItemCatalog.Weight);
      this.addColumnHeader(DlvItemCatalog.LogisticsItemsTotalWeight);

      // value
      for (const row of deliveryList) {
        Model.ExportDetails.updateDeliveryItem(row);
        this.addRow();

        // value - Delivery
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("LogisticsNumber", row, DlvCatalog);
        this.addCellValue("DepartureDate", row, DlvCatalog);
        this.addCellValue("VehicleIDNumber", row, DlvCatalog);
        this.addCellValue("Destination", row, DlvCatalog);
        this.addCellValue("ShipmentFee", row, DlvCatalog);
        this.addCellValue("ArrivalDate", row, DlvCatalog);
        this.addCellValue("CompletedDate", row, DlvCatalog);
        this.addCellValue("TotalWeight", row, DlvCatalog);
        this.addCellStatus(row.Status, DlvModel.Status);

        // value - Delivery Item
        this.addCellValue("DispatchID", row, DlvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, DlvItemCatalog);
        this.addCellValue("SKU", row, DlvItemCatalog);
        this.addCellValue("Packaging", row, DlvItemCatalog);
        this.addCellValue("Quantity", row, DlvItemCatalog);
        this.addCellValue("TotalQuantity", row, DlvItemCatalog);
        this.addCellValue("Weight", row, DlvItemCatalog);
        this.addCellValue("LogisticsItemsTotalWeight", row, DlvItemCatalog);
      }
    },

    addProfit(details, rtnInvoiceList) {
      Model.ExportDetails.setDetailsByProfit(details, rtnInvoiceList);

      this.addProfit_summary(details);
      this.addProfit_invoiceItems(details.Items);
      this.addProfit_rtnInvoiceList(rtnInvoiceList);

      this.addSheet(Info.Export.Details.Excel.SheetName.Profit);
    },
    addProfit_summary(details) {
      this.addColumnHeader(InvProfitCatalog.Profit);
      this.addCellValue("Profit", details, InvProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvProfitCatalog.TotalBruto);
      this.addCellValue("TotalBruto", details, InvProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvProfitCatalog.TotalDisc);
      this.addCellValue("TotalDisc", details, InvProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvProfitCatalog.TotalReturn);
      this.addCellValue("TotalReturn", details, InvProfitCatalog);

      this.addRow();
      this.addColumnHeader(InvProfitCatalog.HppTotalNetto);
      this.addCellValue("HppTotalNetto", details, InvProfitCatalog);
    },
    addProfit_invoiceItems(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Section.ProfitInvoice);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(InvItemCatalog.DispatchID.Label);
      this.addColumnHeader(InvItemCatalog.DispatchID);
      this.addColumnHeader(InvItemCatalog.Price);
      this.addColumnHeader(InvItemCatalog.ParentDisc);
      this.addColumnHeader(InvItemCatalog.HppTotal);
      this.addColumnHeader(InvItemCatalog.Profit);
      this.addColumnHeader(InvItemCatalog.ProfitMargin);

      // value
      for (const row of items) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, InvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, InvItemCatalog);
        this.addCellValue("Price", row, InvItemCatalog);
        this.addCellValue("ParentDisc", row, InvItemCatalog);
        this.addCellValue("HppTotal", row, InvItemCatalog);
        this.addCellValue("Profit", row, InvItemCatalog);
        this.addCellValue("ProfitMargin", row, InvItemCatalog);
      }
    },
    addProfit_rtnInvoiceList(rtnInvoiceList) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Section.ProfitRtnInvoice);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(RinvCatalog.ReturnNumber);
      this.addColumnHeader(RinvCatalog.Total);
      this.addColumnHeader(RinvCatalog.HppTotal);

      // value
      for (const row of rtnInvoiceList) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("ReturnNumber", row, RinvCatalog);
        this.addCellValue("Total", row, RinvCatalog);
        this.addCellValue("HppTotal", row, RinvCatalog);
      }
    },

    addRecPayList(recData) {
      this.addRecPayList_summary(recData);
      this.addRecPayList_listWithInvoice(recData.RecPayList);

      this.addSheet(Info.Export.Details.Excel.SheetName.RecPay);
    },
    addRecPayList_summary(recData) {
      this.addColumnHeader(InvRecPayCatalog.InvoiceRecTotal);
      this.addCellValue("InvoiceRecTotal", recData, InvRecPayCatalog);

      this.addRow();
      this.addColumnHeader(InvRecPayCatalog.InvoiceTotal);
      this.addCellValue("InvoiceTotal", recData, InvRecPayCatalog);

      this.addRow();
      this.addColumnHeader(InvRecPayCatalog.RtnInvoiceTotal);
      this.addCellValue("RtnInvoiceTotal", recData, InvRecPayCatalog);

      this.addRow();
      this.addColumnHeader(InvRecPayCatalog.RecPayTotal);
      this.addCellValue("RecPayTotal", recData, InvRecPayCatalog);

      this.addRow();
      this.addColumnHeader(InvRecPayCatalog.InvoiceDueDate);
      this.addCellValue("InvoiceDueDate", recData, InvRecPayCatalog);
    },
    addRecPayList_listWithInvoice(recPayList) {
      this.addRow();
      this.addRow();
      this.addCellSection_list(Model.RecPay.Module);
      this.addRow();

      // label - RecPay
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(RcpyCatalog.PayNumber);
      this.addColumnHeader(RcpyCatalog.Date);
      this.addColumnHeader(RcpyCatalog.PaymentTypeName);
      this.addColumnHeader(RcpyCatalog.StatusID);

      // label - RecPay Invoice
      this.addColumnHeader(RcpyInvCatalog.InvPaymentValue);

      // value
      for (const row of recPayList) {
        this.addRow();

        // value - RecPay
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("PayNumber", row, RcpyCatalog);
        this.addCellValue("Date", row, RcpyCatalog);
        this.addCellValue("PaymentTypeName", row, RcpyCatalog);
        this.addCellStatus(row.StatusID, RcpyModel.StatusID);

        // value - RecPay Invoice
        this.addCellValue("InvPaymentValue", row, RcpyInvCatalog);
      }
    },

    addRtnInvoiceList(rtnInvoiceList) {
      this.addCellSection_list(Model.RtnInvoice.Module);
      this.addRow();

      this.addRtnInvoiceList_listWithItems(rtnInvoiceList);
    },
    addRtnInvoiceList_listWithItems(rtnInvoiceList) {
      // label - RtnInvoice
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(RinvCatalog.ReturnNumber);
      this.addColumnHeader(RinvCatalog.Date);
      this.addColumnHeader_ID(RinvCatalog.WarehouseID.Label);
      this.addColumnHeader(RinvCatalog.WarehouseID);
      this.addColumnHeader(RinvCatalog.DiscValue);
      this.addColumnHeader(RinvCatalog.TotalBruto);
      this.addColumnHeader(RinvCatalog.TotalDisc);
      this.addColumnHeader(RinvCatalog.Total);

      // label - RtnInvoice Item
      this.addColumnHeader_ID(RinvItemCatalog.DispatchID.Label);
      this.addColumnHeader(RinvItemCatalog.DispatchID);
      this.addColumnHeader(RinvItemCatalog.SKU);
      this.addColumnHeader(RinvItemCatalog.Packaging);
      this.addColumnHeader(RinvItemCatalog.Quantity);
      this.addColumnHeader(RinvItemCatalog.TotalQuantity);
      this.addColumnHeader(RinvItemCatalog.SellPrice);
      this.addColumnHeader(RinvItemCatalog.ReturnInvoiceItemsTotal);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(RinvItemCatalog.HppTotal);
      }

      // value
      for (const row of rtnInvoiceList) {
        Model.ExportDetails.updateDeliveryItem(row);
        this.addRow();

        // value - RtnInvoice
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("ReturnNumber", row, RinvCatalog);
        this.addCellValue("Date", row, RinvCatalog);
        this.addCellValue("WarehouseID", row, RinvCatalog);
        this.addCellRelatedValue("WarehouseID", row, RinvCatalog);
        this.addCellValue(row.DiscValue ? "DiscValue" : "DiscPercent",
          row, RinvCatalog
        );
        this.addCellValue("TotalBruto", row, RinvCatalog);
        this.addCellValue("TotalDisc", row, RinvCatalog);
        this.addCellValue("Total", row, RinvCatalog);

        // value - RtnInvoice Item
        this.addCellValue("DispatchID", row, RinvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, RinvItemCatalog);
        this.addCellValue("SKU", row, RinvItemCatalog);
        this.addCellValue("Packaging", row, RinvItemCatalog);
        this.addCellValue("Quantity", row, RinvItemCatalog);
        this.addCellValue("TotalQuantity", row, RinvItemCatalog);
        this.addCellValue("SellPrice", row, RinvItemCatalog);
        this.addCellValue("ReturnInvoiceItemsTotal", row, RinvItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, RinvItemCatalog);
        }
      }
    },

    addSalesRecapDetails(salesRecapDetails) {
      if (salesRecapDetails !== null) {
        this.addSalesRecapDetails_details(salesRecapDetails);
      }
      else {
        this.addCell(Catalog.SalesRecapID.NullText);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.SalesRecap);
    },
    addSalesRecapDetails_details(salesRecapDetails) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(SlrcCatalog.RecapNumber);
      this.addColumnHeader(SlrcCatalog.Date);
      this.addColumnHeader_ID(SlrcCatalog.WarehouseID.Label);
      this.addColumnHeader(SlrcCatalog.WarehouseID);
      this.addColumnHeader_ID(SlrcCatalog.CashierID.Label);
      this.addColumnHeader(SlrcCatalog.CashierID);
      this.addColumnHeader(SlrcCatalog.CashReceived);
      this.addColumnHeader(SlrcCatalog.Comment);
      this.addColumnHeader(SlrcCatalog.Total);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(salesRecapDetails);
      this.addCellValue("RecapNumber", salesRecapDetails, SlrcCatalog);
      this.addCellValue("Date", salesRecapDetails, SlrcCatalog);
      this.addCellValue("WarehouseID", salesRecapDetails, SlrcCatalog);
      this.addCellRelatedValue("WarehouseID", salesRecapDetails, SlrcCatalog);
      this.addCellValue("CashierID", salesRecapDetails, SlrcCatalog);
      this.addCellRelatedValue("CashierID", salesRecapDetails, SlrcCatalog);
      this.addCellValue("CashReceived", salesRecapDetails, SlrcCatalog);
      this.addCellValue("Comment", salesRecapDetails, SlrcCatalog);
      this.addCellValue("Total", salesRecapDetails, SlrcCatalog);
      this.addCellValue_CreatedBy(salesRecapDetails);
      this.addCellValue_CreatedDate(salesRecapDetails);
    },

    addStockReductionList(stockReductionList, specialPriceCount) {
      this.addCellSection_list(Model.StockReduction.Module);
      this.addRow();

      this.addStockReductionList_listWithItems(stockReductionList,
        specialPriceCount
      );
    },
    addStockReductionList_listWithItems(stockReductionList, specialPriceCount) {
      // label - StockReduction
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(StrdCatalog.ReductionNumber);
      this.addColumnHeader(StrdCatalog.Date);
      this.addColumnHeader_ID(StrdCatalog.WarehouseID.Label);
      this.addColumnHeader(StrdCatalog.WarehouseID);
      this.addColumnHeader(StrdCatalog.Total);

      // label - StockReduction Item
      this.addColumnHeader_ID(StrdItemCatalog.DispatchID.Label);
      this.addColumnHeader(StrdItemCatalog.DispatchID);
      this.addColumnHeader(StrdItemCatalog.SKU);
      this.addColumnHeader(StrdItemCatalog.Packaging);
      this.addColumnHeader(StrdItemCatalog.Qty);
      this.addColumnHeader(StrdItemCatalog.TotalQty);
      this.addColumnHeader(StrdItemCatalog.SellPrice);
      if (specialPriceCount > 0) {
        this.addColumnHeader_ID(StrdItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(StrdItemCatalog.SpecialPriceID);
        this.addColumnHeader(StrdItemCatalog.SpecialPriceDiscPercent);
      }
      this.addColumnHeader(StrdItemCatalog.StockReductionItemTotal);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(StrdItemCatalog.HppTotal);
      }

      // value
      for (const row of stockReductionList) {
        Model.ExportDetails.updateStockReductionItem(row);
        this.addRow();

        // value - StockReduction
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("ReductionNumber", row, StrdCatalog);
        this.addCellValue("Date", row, StrdCatalog);
        this.addCellValue("WarehouseID", row, StrdCatalog);
        this.addCellRelatedValue("WarehouseID", row, StrdCatalog);
        this.addCellValue("Total", row, StrdCatalog);

        // value - StockReduction Item
        this.addCellValue("DispatchID", row, StrdItemCatalog);
        this.addCellRelatedValue("DispatchID", row, StrdItemCatalog);
        this.addCellValue("SKU", row, StrdItemCatalog);
        this.addCellValue("Packaging", row, StrdItemCatalog);
        this.addCellValue("Qty", row, StrdItemCatalog);
        this.addCellValue("TotalQty", row, StrdItemCatalog);
        this.addCellValue("SellPrice", row, StrdItemCatalog);
        if (specialPriceCount > 0) {
          this.addCellValue("SpecialPriceID", row, StrdItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, StrdItemCatalog);
          this.addCellRelatedValue("SpecialPriceDiscPercent", row, StrdItemCatalog);
        }
        this.addCellValue("StockReductionItemTotal", row, StrdItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, StrdItemCatalog);
        }
      }
    },
    
    addInvoiceReplaceList(invoiceReplaceList) {
      this.addCellSection(Info.Section.InvoiceReplaceList.Title);
      this.addRow();

      this.addInvoiceReplaceList_listWithItems(invoiceReplaceList);
    },
    addInvoiceReplaceList_listWithItems(invoiceReplaceList){
      // label - InvoiceReplacement
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.TotalBruto);
      this.addColumnHeader(Catalog.TotalDisc);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Status);

      // label - InvoiceReplacement item
      this.addColumnHeader_ID(InvItemCatalog.DispatchID.Label);
      this.addColumnHeader(InvItemCatalog.DispatchID);
      this.addColumnHeader(InvItemCatalog.SKU);
      this.addColumnHeader(InvItemCatalog.Packaging);
      this.addColumnHeader(InvItemCatalog.RequestedQuantity);
      this.addColumnHeader(InvItemCatalog.TotalRequestedQuantity);
      this.addColumnHeader(InvItemCatalog.SellPrice);
      this.addColumnHeader_ID(InvItemCatalog.SpecialPriceID.Label);
      this.addColumnHeader(InvItemCatalog.SpecialPriceID);
      this.addColumnHeader(InvItemCatalog.SpecialPriceDiscPercent);
      this.addColumnHeader(InvItemCatalog.Price);
      if (this.access.Glob_HPP) {
        this.addColumnHeader(InvItemCatalog.HppTotal);
      }

      for (const row of invoiceReplaceList) {
        Model.updateItem(row);
        this.addRow();
        this.addSequenceCell();
        
        // value - InvoiceReplacement
        this.addCellValue_ID(row);
        this.addCellValue("SONumber", row, Catalog);
        this.addCellValue("InvoiceDate", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue(row.DiscValue ? "DiscValue" : "DiscPercent",
          row, Catalog
        );
        this.addCellValue("TotalBruto", row, Catalog);
        this.addCellValue("TotalDisc", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellStatus(row.Status, Model.Status);
        
        // value - InvoiceReplacement Item
        this.addCellValue("DispatchID", row, InvItemCatalog);
        this.addCellRelatedValue("DispatchID", row, InvItemCatalog);
        this.addCellValue("SKU", row, InvItemCatalog);
        this.addCellValue("Packaging", row, InvItemCatalog);
        this.addCellValue("RequestedQuantity", row, InvItemCatalog);
        this.addCellValue("TotalRequestedQuantity", row, InvItemCatalog);
        this.addCellValue("SellPrice", row, InvItemCatalog);
        this.addCellValue("SpecialPriceID", row, InvItemCatalog);
        this.addCellRelatedValue("SpecialPriceID", row, InvItemCatalog);
        this.addCellRelatedValue("SpecialPriceDiscPercent", row, InvItemCatalog);
        this.addCellValue("Price", row, InvItemCatalog);
        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, InvItemCatalog);
        }
      }
    }
  }
}
</script>