import App from "../../services/App/App.js";
import RouterModel from "../../router/RouterModel.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import SttrD from "./Details/SttrD.vue";
import SttrExL from "./ExportList/SttrExL.vue";
import SttrL from "./List/SttrL.vue";
import SttrN from "./New/SttrN.vue";
import SttrPD from "./PrintDetails/SttrPD.vue";
import SttrPL from "./PrintList/SttrPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockTrf, AppModuleType.Details),
    List: RouterModel.getPath(AppModule.StockTrf, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockTrf, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.StockTrf, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.Details),
        component: SttrD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.ExportList),
        component: SttrExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.List),
        component: SttrL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.StockTrf),
            moduleattr: AppModuleAttr.StockTrf_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.StockTrf),
            moduleattr: AppModuleAttr.StockTrf_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.StockTrf),
            moduleattr: AppModuleAttr.StockTrf_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.New),
        component: SttrN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.New),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.StockTrf, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.StockTrf, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.PrintDetails),
        component: SttrPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockTrf, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.StockTrf, AppModuleType.PrintList),
        component: SttrPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockTrf, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.StockTrf),
          moduleattr: AppModuleAttr.StockTrf_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}