<template>
  <div>
    <ClientSearchList
      @init="handleClient_init"
      @submit="handleClient_submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-4">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="SlsSearchCatalog"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-output addons floating-label 
          field="ClientID" :data="searchData" :catalog="SlsSearchCatalog"
        >
          <template #default>
            <kst-input floating-label load-on-focus
              field="ClientName" :data="searchData" :catalog="SlsSearchCatalog"
              :disabled="clientDisabled" @input="handleInput"
            />
          </template>
          <template #right>
            <div class="control">
              <template v-if="searchData.ClientID === null">
                <kst-tooltip search-mode hover-only :content="SlsSearchCatalog.ClientID.Label">
                  <kst-button search-mode @click="handleSearch_client"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="SlsSearchCatalog.ClientID.Label">
                  <kst-button clear-mode @click="handleClear_client"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="SlsSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="StatusID" :data="searchData" :catalog="SlsSearchCatalog"
          :options="statusOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="SlsSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Model from "../SlsModel.js";
import SlsSearchCatalog from "../SlsSearchCatalog.js";
import Services from "../../../services/Api/SalesServices.js";

import ClientSearchList from "../../Client/SearchList/CliScL.vue";

export default {
  components:{
    ClientSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    SlsSearchCatalog: SlsSearchCatalog,
    // func
    showClientFunc: null
  }),
  computed: {
    clientDisabled() {
      return this.searchData.ClientID !== null;
    },
    statusOptions() {
      return Model.createStatusOptions(SlsSearchCatalog.StatusID.Label);
    }
  },
  methods: {
    /*** by Client modal ***/

    handleClient_init(show) {
      this.showClientFunc = show;
    },
    handleClient_submit(data) {
      this.searchData.ClientID = data.ID;
      this.searchData.ClientName = data.Name;
      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_client() {
      this.showClientFunc(Services.getRelatedClientList);
    },
    handleClear_client() {
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";

      this.handleInput();
    }
  }
}
</script>