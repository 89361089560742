import App from "../../services/App/App.js";

export default {
  /*** method ***/

  setDetailsByData(data, details) {
    data.ID = details.ID;
    // user input
    data.Name = App.In.getString(details.Name);
    data.DiscPercent = App.In.getDecimal(details.DiscPercent);
    data.IsActive = App.In.getBoolean(details.IsActive);
    data.Comment = App.In.getString(details.Comment);
  },
}