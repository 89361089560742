import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.Details
    }
  },
  Export: {
    Excel: {
      // file
      SheetName: {
        TransList: AppModule.Transaction.FullName
      },
      // ui
      Option: {
        TransList: AppModule.Transaction.FullName
      }
    }
  }
}