import ModelBody from "./ExpModel_Body.js";
import ModelEdit from "./ExpModel_Edit.js";
import ModelNew from "./ExpModel_New.js";
import ExpenseCategoryModel from "../ExpenseCategory/ExpcModel.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createSummary() {
    return ModelBody.createSummary();
  },

  getResultSectionHelp(dataList) {
    return ModelBody.getResultSectionHelp(dataList);
  },
  getSummary(dataList) {
    return ModelBody.getSummary(dataList);
  },

  populateData(userData) {
    return ModelBody.populateDetails(userData);
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, expenseData) {
      ModelEdit.setDetailsByData(userData, expenseData);
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByExpenseCategoryOptions(userData, categoryOptions) {
      ModelNew.setDetailsByExpenseCategoryOptions(userData, categoryOptions);
    },
    setDetailsByPaymentList(userData, paymentList) {
      ModelNew.setDetailsByPaymentList(userData, paymentList);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      return ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    }
  },

  /*** external ***/

  getExpenseCategoryOptions(categoryList, optionAllText) {
    return ExpenseCategoryModel.createOptions(categoryList, optionAllText);
  },

  getPaymentOptions(paymentList, optionAllText, optionNonText) {
    return PaymentTypeModel.createOptions(paymentList, optionAllText,
      optionNonText
    );
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}