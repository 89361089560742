<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Ref :details="itemData"/>
    <Details :details="itemData"/>
    <PackagingList :details="itemData"/>
    <Variant :details="itemData"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import Details from "./ItemDL_Item_Details.vue";
import PackagingList from "./ItemDL_Item_PackagingList.vue";
import Ref from "./ItemDL_Item_Ref.vue";
import Variant from "./ItemDL_Item_Variant.vue";

export default {
  components: {
    Details,
    PackagingList,
    Ref,
    Variant
  },
  props: {
    itemData: Object
  },
  computed: {
    sectionHelp() {
      return Model.Delete.getItemSectionHelp(this.itemData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>