import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./VenModel.js";

export default {
  Button: {
    VendorItem: {
      Label: AppModuleType.New.FullName + " " + AppModule.VendorItem.FullName
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.FullName,
          DebtList: AppModuleType.List.FullName + " " + AppModule.Debt.FullName
        },
        // ui
        Option: {
          Details: Model.ModuleType.Details.FullName + " " + Model.Module.FullName,
          DebtList: AppModuleType.List.FullName + " " + AppModule.Debt.FullName
        }
      }
    },
    List: {
      Excel: {
        //file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Vendor.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Vendor.FullName,
          VendorAddress: "Dengan " + AppModule.VendorAddress.FullName
        }
      }
    }
  },
  Message: {
    Edit_ConfirmDeleteAddress:
      "Yakin menghapus " + Model.VendorAddress.Module.FullName + "?",
    New_ConfirmDeleteAddress:
      "Yakin menghapus " + Model.VendorAddress.Module.FullName + "?",
    Details_VendorItem_Submit_ConfirmDelete: "Yakin menghapus " +
      AppModule.VendorItem.FullName + "?",
  },
  Modal: {
    VendorItem: {
      Module: AppModule.VendorItem,
      ModuleType: AppModuleType.New
    }
  },
  Section: {
    Address: {
      Title: AppModuleType.List.FullName + " " + AppModule.VendorAddress.FullName
    },
    VendorItem: {
      Title: AppModuleType.List.FullName + " " + AppModule.Item.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.Name,
    DebtList: AppModuleType.List.FullName + " " + AppModule.Debt.FullName,
    VendorItemList: AppModuleType.List.FullName + " " +
      AppModule.VendorItem.FullName,
  }
}