<template>
  <kst-page-export
    :catalog="PlosSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :searchData="searchData"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.Report"
      :label="Info.Export.Excel.Option.Report"
    />
  </kst-page-export>
</template>

<script>
import Info from "../PlosInfo.js";
import PlosSearchCatalog from "../PlosSearchCatalog.js";
import Model from "../PlosModel.js";
import Services from "../../../services/Api/ProfitLossServices.js";

import Export from "./PlosEx_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    PlosSearchCatalog: PlosSearchCatalog,
    Model: Model,
    searchData: {
      Date: null,
      WarehouseID: null,
      // by system
      RptTypeID: null
    },
    userData: {
      Report: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getOptions();
      const config = Services.getExport(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.searchData.RptTypeID = data.CompanySettingDetails.ProfitLossRptTypeID;
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.userData.Report = true;
    },

    getOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID)
      };
    }
  }
}
</script>