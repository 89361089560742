<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <template v-if="itemData !== null && itemData !== undefined">
          <kst-section :title="Info.Section.ItemDetails.Title">
            <ItemDetails :itemData="itemData"/>
          </kst-section>
        </template>

        <kst-section
          :help="stockSectionHelp"
          :title="Info.Section.StockList.Title"
        >
          <template v-if="isAllWarehouse">
            <StockSummaryList
              :access="access"
              :stockList="stockList"
              :stockSummaryList="stockSummaryList"
            />
          </template>
          <template v-else>
            <StockList :access="access" :stockList="stockList"/>
          </template>
        </kst-section>
      </div>
      <div class="column is-half">
        <kst-section
          :help="transSummarySectionHelp"
          :title="Info.Section.TransSummary.Title"
        >
          <TransSummary :transSummaryList="transSummaryList"/>
        </kst-section>
      </div>
    </div>

    <kst-section :title="Info.Section.TransList.Title">
      <template v-if="isEmpty_TransGroupObj">
        <kst-table-empty />
      </template>
      <template v-else>
        <TransList
          :access="access"
          :transGroupObj="transGroupObj"
        />
      </template>
    </kst-section>
  </div>
</template>

<script>
import Info from "../StcdInfo.js";
import Model from "../StcdModel.js";

import ItemDetails from "./StcdR_Result_ItemDetails.vue";
import StockList from "./StcdR_Result_StockList.vue";
import StockSummaryList from "./StcdR_Result_StockSummaryList.vue";
import TransList from "./StcdR_Result_TransList.vue";
import TransSummary from "./StcdR_Result_TransSummary.vue";

export default {
  components: {
    ItemDetails,
    StockList,
    StockSummaryList,
    TransList,
    TransSummary
  },
  props: {
    access: Object,
    searchData: Object,
    itemData: Object,
    stockList: Array,
    transList: Array
  },
  data: () => ({
    Info: Info,
    stockSummaryList: [],
    transGroupObj: {},
    transSummaryList: []
  }),
  computed: {
    isAllWarehouse() {
      const warehouseId = this.$kst.Search.getValue(this.searchData.WarehouseID, null);
      return warehouseId === null;
    },
    isEmpty_TransGroupObj() {
      if (this.transGroupObj === undefined || this.transGroupObj === null) {
        return false;
      }
      return Object.keys(this.transGroupObj).length === 0;
    },
    stockSectionHelp() {
      return Info.Section.StockList.Help + " " +
        this.$kst.Format.getShortDate(this.$kst.In.getDateToday());
    },
    transSummarySectionHelp() {
      return Info.Section.TransSummary.Help + ": " +
        this.$kst.Format.getShortDate(this.searchData.Date[0]) + " - " +
        this.$kst.Format.getShortDate(this.searchData.Date[1]);
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.resetData();

      if (this.isAllWarehouse) {
        if (Array.isArray(this.stockList)) {
          this.stockSummaryList = Model.getStockSummaryList(this.stockList);
        }
      }

      if (Array.isArray(this.transList)) {
        this.transGroupObj = Model.getTransactionGroupObj(this.transList);
        this.transSummaryList = Model.getTransactionSummaryList(this.transList);
      }
    },

    resetData() {
      this.transGroupObj = {};
      this.stockSummaryList = [];
      this.transSummaryList = [];
    }
  }
}
</script>