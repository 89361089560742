import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  DueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  DebtTotal: {
    Label: AppModule.Debt.FullName,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  ReceiveNumber: {
    Label: AppCatalog.Field.Number.Label + " Penerimaan",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true,
    Highlight: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}