import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    SummaryList: {
      Title: AppCatalog.Info.Summary,
      ModuleType: AppModuleType.List
    }
  },
  Dialog: {
    SummaryList: {
      Title: AppCatalog.Info.Summary,
      Width: 800
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: AppModuleType.Details.FullName
        }
      },
      // support
      Option: {
        Details: AppModuleType.Details.FullName + " " + AppModule.Expense.FullName
      }
    },
    List: {
      Excel: {
        //file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Expense.FullName,
          SummaryList: AppCatalog.Info.Summary + " " + AppModule.Expense.FullName
        }
      },
      Option: {
        List: AppModuleType.List.FullName + " " + AppModule.Expense.FullName,
        SummaryList: AppCatalog.Info.Summary + " " + AppModule.Expense.FullName
      }
    }
  },
  Summary :{
    Total: AppCatalog.Field.Total.Label
  },
  Tabs: {
    Details: AppModuleType.Details.FullName
  }
}