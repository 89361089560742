import AppModule from "../../services/App/AppModule.js";
import ModelBody from "./OmzModel_Body.js";

export default {
  getSummary(transactionDetails) {
    let result = ModelBody.createSummary();
    let newTransRow;

    newTransRow = this.getSummary_createTransRow(
      AppModule.Invoice.FullName, transactionDetails.InvoiceTotal, null
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.Pos.FullName, transactionDetails.PosTotal, null
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.RtnInvoice.FullName, null, transactionDetails.RtnInvoiceTotal
    );
    result.TransactionList.push(newTransRow);

    for (const row of result.TransactionList) {
      if (row.AddTotal !== null) {
        result.Total += row.AddTotal;
      }

      if (row.SubTotal !== null) {
        result.Total -= row.SubTotal;
      }
    }

    return result;
  },
  getSummary_createTransRow(moduleName, addTotal, subTotal) {
    return {
      Label: moduleName,
      AddTotal: addTotal,
      SubTotal: subTotal
    };
  }
}