import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";

export default {
  TypeID: {
    Invoice: {
      ID: 1,
      Label: AppModule.Invoice.FullName,
      Module: AppModule.Invoice,
      ModuleAttr: AppModuleAttr.Invoice_Act_Details
    },
    RecPay: {
      ID: 3,
      Label: AppModule.RecPay.FullName,
      Module: AppModule.RecPay,
      ModuleAttr: AppModuleAttr.RecPay_Act_Details
    },
    RecRepair: {
      ID: 4,
      Label: AppModule.RecRepair.FullName,
      Module: AppModule.RecRepair,
      ModuleAttr: null
    },
    RtnInvoice: {
      ID: 2,
      Label: AppModule.RtnInvoice.FullName,
      Module: AppModule.RtnInvoice,
      ModuleAttr: AppModuleAttr.RtnInvoice_Act_Details
    }
  }
}