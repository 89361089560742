<template>
  <div class="block">
    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="ClosingNumber"
    />
  </div>
</template>

<script>
import Catalog from "../StclCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>