<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="950"
    @init="handleModal_init"
    @load="handleModal_load"
    @reset="handleModal_reset"
    @submit="handleModal_submit"
  >
    <template #search="{ handleInput }">
      <Search 
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :items="data.List"
        :userData="userData"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import Model from "../ItemModel.js";

import Search from "./ItemScL_Search.vue";
import List from "./ItemScL_List.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {
      Search: "",
      ExcludeID: null
    },
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getListService, id) {
      this.getListService = getListService;
      this.searchData.ExcludeID = id === undefined ? null : id;
      this.showFunc();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    /*** by modal ***/

    handleModal_init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_reset(loadList) {
      this.init_data();
      this.handleModal_load(loadList);
    },

    handleModal_submit(selected) {
      this.$emit(this.$kst.Enum.Event.Submit, selected);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchFields,
        excludeid: this.$kst.Out.getInteger(this.searchData.ExcludeID)
      };
    },

    init_data() {
      // searchData
      this.searchData.Search = "";
    }
  }
}
</script>