<template>
  <kst-page-export
    :catalog="ItemSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check 
      v-model="userData.WithPackaging"
      :label="Info.Export.List.Excel.Option.ItemPackaging"
    />
  </kst-page-export>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import ItemSearchCatalog from "../ItemSearchCatalog.js";
import Services from "../../../services/Api/ItemServices.js";

import Export from "./ItemExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    ItemSearchCatalog: ItemSearchCatalog,
    access: {},
    searchData: {
      Search: ""
    },
    userData: {
      WithList: true,
      WithPackaging: false
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithList = true;
      this.userData.WithPackaging = false;
    },

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields,
        withpackaging: this.$kst.Out.getBoolean(this.userData.WithPackaging)
      };
    }
  }
}
</script>