import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  DebtTotal: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Debt.FullName,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  DueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    Required: true,
    RequiredHide: true
  },
  ID: {
    Label: AppCatalog.Field.Number.Label + " Pembelian",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "ReceiveNumber",
    RelatedModule: AppModule.Receive,
    Param: AppModule.Receive.Param + AppCatalog.Field.ID.Param
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input
  },
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.Output,
    MaxValue: ["Total", AppCatalog.Field.Total.Label],
    MinValue: AppCatalog.Field.PaymentValue.MinValue,
    Required: true
  },
  PurchasingID: {
    Label: AppCatalog.Field.Number.Label +" PO" , 
    ExportLabel: AppModule.Purchase.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedModule: AppModule.Purchase,
    RelatedValue: "PurchasingNumber"
  },
  PurchasingIsActive: {
    Label: "Status Penghapusan PO",
    Type: AppCatalog.Field.Status.Type,
    ReadOnly: true
  },
  ReceiveNumber: {
    Label: AppCatalog.Field.Number.Label + " Penerimaan",
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  ReturnReceiveDate: {
    Label: AppCatalog.Field.Date.Label + " " + AppModule.RtnReceive.FullName,
    Type: AppCatalog.Field.Date.Type,
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  VendorAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Vendor.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor,
    Help: "Pilih dari master " + AppModule.Vendor.FullName + ", atau ketik manual."
  },
  VendorName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 200
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Penerimaan",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  PurchasingDate: {
    Label: AppCatalog.Field.Date.Label,
    ExportLabel: AppCatalog.Field.Date.Label + " PO",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  PurchasingNumber: {
    Label: AppCatalog.Field.Number.Label + " PO",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true,
    Highlight: true
  },
  VendorAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
}