<template>
  <kst-container class="kst-page-delete" :class="theClass">
    <ValidationObserver ref="formDetails">
      <nav class="level mb-0 p-5 ks-border-b-light">
        <div class="level-left">
          <div>
            <kst-title class="m-0">{{ theTitle }}</kst-title>
          </div>
        </div>
        <div class="level-right">
          <template v-if="reloadAreaVisible">
            <kst-button slim-size reload-mode @click="handleReload"/>
          </template>
        </div>
      </nav>

      <div class="kst-page-delete__content p-5">
        <kst-skeleton :active="isLoading"/>

        <template v-if="hasAccess === false">
          <kst-failure access-mode/>
        </template>

        <template v-if="defaultSlotVisible">
          <slot />
        </template>

        <kst-failure init-mode :items="failureInit"/>
        <kst-failure save-mode :items="failure"/>
        <kst-errors class="pt-4" :items="errors"/>
      </div>
    </ValidationObserver>

    <template v-if="buttonsAreaVisible">
      <div class="p-5 ks-border-t-light">
        <div class="buttons is-justify-content-end">
          <kst-button cancel-mode :to="cancelUrl"/>
          <kst-button submit-mode @click="handleSubmit"/>
        </div>
      </div>
    </template>
  </kst-container>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadDelete
 * 2. handle Submit event and do saveData
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    userData: {
      ID: null // required
    },
    hasAccess: null,
    autoValidate: Boolean,
    // loading
    isLoading: true,
    saveLoading: null,
    // area
    buttonsAreaVisible: false,
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    // error
    failure: null,
    failureInit: null,
    errors: null
  }),
  computed: {
    cancelUrl() {
      return this.$route.meta.cancel.url;
    },
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    submitUrl() {
      return this.$route.meta.submit.url;
    },
    submitParams() {
      return this.$route.meta.submit.params;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(
          this.$kst.Enum.Event.Init, this.userData.ID, this.loadDelete
        );
      }
    },
    init_data() {
      this.userData.ID = null;
      this.hasAccess = null;
      // loading
      this.isLoading = true;
      this.saveLoading = null;
      // area
      this.buttonsAreaVisible = false;
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failure = null
      this.failureInit = null;
      this.errors = null;
    },
    init_validate() {
      // check: access
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess !== true) {
        this.init_failed();
        return false;
      }

      // check: required url params
      this.userData.ID = this.getUrlParam(this.$kst.Enum.Param.ID);

      if (!this.userData.ID) {
        this.failureInit = [AppCatalog.Message.ParamIncomplete];
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.buttonsAreaVisible = true;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;

      this.$nextTick(() => {
        if (this.autoValidate) {
          this.$refs.formDetails.validate();
        }
      });
    },
    init_failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleReload() {
      this.init();
    },

    handleSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.$emit(this.$kst.Enum.Event.Submit,
            this.userData.ID, this.saveData, this.skipSave
          );
        }
        else {
          this.notifyFormError();
        }
      });
    },

    loadDelete(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadDelete_success,
        this.loadDelete_fault,
        this.loadDelete_fault,
        null, true
      );
    },
    loadDelete_success(data, successCallback) {
      successCallback(data);
      this.init_success();
    },
    loadDelete_fault(message) {
      this.failureInit = message;
      this.init_failed();
    },

    saveData(config, successCallback) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      this.runAxios(config, successCallback,
        this.saveData_success,
        this.saveData_error,
        this.saveData_invalid
      );
    },
    saveData_end() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_success(data, successCallback) {
      this.saveData_end();
      this.buttonsAreaVisible = false;
      successCallback();

      this.notifyFormSuccess(() => {
        this.saveData_success_redirect(data);
      });
    },
    saveData_success_redirect(data) {
      if (this.submitParams) {
        const routeParams = {};
        for (const key in this.submitParams) {
          routeParams[key] = data[this.submitParams[key]];
        }

        const urlParams = this.$kst.Route.getParams(routeParams);
        this.redirectTo(this.submitUrl + urlParams);
      }
      else {
        this.redirectTo(this.submitUrl);
      }
    },
    saveData_error(message) {
      this.failure = message;
      this.saveData_end();
    },
    saveData_invalid(message) {
      this.errors = message;
      this.saveData_end();
      this.notifyFormError();
    },

    skipSave(data, successCallback) {
      this.saveData_success(data, successCallback);
    }
  }
}
</script>