import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import CliD from "./Details/CliD.vue";
import CliE from "./Edit/CliE.vue";
import CliExD from "./ExportDetails/CliExD.vue";
import CliExL from "./ExportList/CliExL.vue";
import CliPL from "./PrintList/CliPL.vue";
import CliL from "./List/CliL.vue";
import CliN from "./New/CliN.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Client, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Client, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Client, AppModuleType.ExportList),
    PrintList: RouterModel.getPath(AppModule.Client, AppModuleType.PrintList),
    List: RouterModel.getPath(AppModule.Client, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Client, AppModuleType.New)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Client, AppModuleType.Details),
        component: CliD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Client, AppModuleType.Edit),
        component: CliE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Client, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Client, AppModuleType.ExportDetails),
        component: CliExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_ExportDetails,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Client, AppModuleType.ExportList),
        component: CliExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.List),
        name: RouterModel.getName(AppModule.Client, AppModuleType.List),
        component: CliL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_List,
          moduletype: AppModuleType.List,
          //actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Client),
            moduleattr: AppModuleAttr.Client_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Client),
            moduleattr: AppModuleAttr.Client_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Client),
            moduleattr: AppModuleAttr.Client_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.New),
        name: RouterModel.getName(AppModule.Client, AppModuleType.New),
        component: CliN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Client, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Client, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },
  
  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Client, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Client, AppModuleType.PrintList),
        component: CliPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Client, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Client),
          moduleattr: AppModuleAttr.Client_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}