import App from "../../services/App/App.js";
import RouterModel from "../../router/RouterModel.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import UserD from "./Details/UserD.vue";
import UserE from "./Edit/UserE.vue";
import UserEPwd from "./EditPassword/UserEPwd.vue";
import UserL from "./List/UserL.vue";
import UserN from "./New/UserN.vue";

export default {
  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.UserList, AppModuleType.Details),
        name: RouterModel.getName(AppModule.UserList, AppModuleType.Details),
        component: UserD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.UserList, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.UserList),
          moduleattr: AppModuleAttr.User_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.UserList, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.UserList, AppModuleType.Edit),
        component: UserE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.UserList, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.UserList),
          moduleattr: AppModuleAttr.User_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.UserList, AppModuleType.EditPassword),
        name: RouterModel.getName(AppModule.UserList, AppModuleType.EditPassword),
        component: UserEPwd,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.UserList, AppModuleType.EditPassword),
          module: RouterModel.getModule(AppModule.UserList),
          moduleattr: AppModuleAttr.User_Act_EditPassword,
          moduletype: AppModuleType.EditPassword,
          cancel: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.UserList, AppModuleType.List),
        name: RouterModel.getName(AppModule.UserList, AppModuleType.List),
        component: UserL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.UserList, AppModuleType.List),
          module: RouterModel.getModule(AppModule.UserList),
          moduleattr: AppModuleAttr.User_Act_List,
          moduletype: AppModuleType.List,
          // actions
          new: {
            module: RouterModel.getModule(AppModule.UserList),
            moduleattr: AppModuleAttr.User_Act_New,
            moduletype: AppModuleType.New
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.UserList, AppModuleType.New),
        name: RouterModel.getName(AppModule.UserList, AppModuleType.New),
        component: UserN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.UserList, AppModuleType.New),
          module: RouterModel.getModule(AppModule.UserList),
          moduleattr: AppModuleAttr.User_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.UserList, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  }
}