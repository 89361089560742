<template>
  <kst-section items-mode>
    <ItemSearch
      @init="handleItem_Init"
      @submit="handleItem_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Model.SalesItem.Module.FullName"
        :moduleType="Model.SalesItem.ModuleType.New"
        @click="handleAdd_Item"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <kst-button action-mode
          class="is-primary"
          :label="Model.SalesItem.Module.FullName"
          :moduleType="Model.SalesItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <List
      :userData="userData"
      :userItems="userItems"
    />
  </kst-section>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import Model from "../SalesModel.js";
import Services from "../../../services/ApiPublic/SalesServices.js";

import List from "./SlsN_Items_List.vue";
// external
import ItemSearch from "../../Item/SearchList/ItemScL.vue";

export default {
  mixins: [AxiosMixin],
  components: {
    List,
    // external
    ItemSearch
  },
  props: {
    serviceData: Object,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    // func
    showItemFunc: null
  }),
  methods: {
    /*** by item modal ***/

    handleItem_Init(show) {
      this.showItemFunc = show;
    },
    handleItem_Submit(itemData) {
      let item = Model.createItem();
      Model.setItemByItemData(item, itemData);
      Model.updateItem(item);
      this.userItems.push(item);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_Item() {
      this.showItemFunc(
        this.serviceData.SelfServiceID,
        this.serviceData.SelfServicePin,
        Services.getRelatedItemList
      );
    }
  }
}
</script>