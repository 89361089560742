import App from "../../services/App/App.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Enum from "./SpEnum.js";

export default {
  /*** related ***/

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.SpecialPrice_Act_Details,
    Act_Edit: AppModuleAttr.SpecialPrice_Act_Edit,
    Act_List: AppModuleAttr.SpecialPrice_Act_List,
    Act_New: AppModuleAttr.SpecialPrice_Act_New
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
    ]
  },

  Search: {
    Fields: ["Name"]
  },

  IsActive: Enum.IsActive,

  /*** method ***/

  createOptions(items, optionAllText, optionNonText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    if (optionNonText) {
      listOptions.nonActive = true;
      listOptions.nonText = optionNonText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createIsActiveOptions(optionAllText, isActiveEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: isActiveEnum.Active.ID, [rowLabel]: isActiveEnum.Active.Label },
      { [rowId]: isActiveEnum.InActive.ID, [rowLabel]: isActiveEnum.InActive.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createDetails(isActiveEnum) {
    return {
      ID: null,
      // user input
      Name: "",
      DiscPercent: "",
      IsActive: App.In.getBoolean(isActiveEnum.Active.ID),
      Comment: "",
    };
  },
  populateDetails(userData) {
    return {
      ID: userData.ID,
      Name: App.Out.getString(userData.Name),
      DiscPercent: parseFloat(userData.DiscPercent),
      IsActive: App.Out.getBoolean(userData.IsActive),
      Comment: App.Out.getString(userData.Comment),
    }
  }
}