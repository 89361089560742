import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 50,
    Highlight: true,
    Required: true,
    AlphaSpaces: true,
    Help: [
      "Tidak boleh mengandung symbol."
    ]
  },
  Email: {
    Label: AppCatalog.Field.Email.Label,
    Type: AppCatalog.Field.Email.Type,
    MaxLength: 100,
    Required: true
  },
  Phone: {
    Label: AppCatalog.Field.Phone.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 100,
    Required: true
  },
  Password: {
    Label: AppCatalog.Field.Password.Label,
    Type: AppCatalog.Field.Password.Type,
    MinLength: AppCatalog.Field.Password.MinLength,
    MaxLength: AppCatalog.Field.Password.MaxLength,
    Required: AppCatalog.Field.Password.Required
  },
  AccessID: {
    Label: AppModule.Access.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedIsActive: "AccessIsActive",
    RelatedValue: "AccessName",
    RelatedModule: AppModule.Access
  },
  AccessName: {
    Label: AppModule.Access.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  BackDateInterval:{
    Label: AppCatalog.Field.BackDateInterval.Label,
    Type: AppCatalog.Field.BackDateInterval.Type,
    MinValue: AppCatalog.Field.BackDateInterval.MinValue,
    MaxValue: AppCatalog.Field.BackDateInterval.MaxValue,
    MaxLength: AppCatalog.Field.BackDateInterval.MaxLength,
    Required: true
  },
  DefaultModuleID: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppModule.Module.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input:  AppCatalog.Field.ID.Input,
    Required: true
  },
  DefaultModuleName: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppModule.Module.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  DefaultWarehouseID: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedIsActive: "DefaultWarehouseIsActive",
    RelatedValue: "DefaultWarehouseName",
    RelatedModule: AppModule.Warehouse
  },
  DefaultWarehouseName: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },

  //Support
  ActiveUsers:{
    Label: AppCatalog.Field.Total.Label + " " + 
      AppModule.UserList.FullName + " Aktif"
  }, 
  MaxUsers: {
    Label: 
      AppCatalog.Field.Total.Label + " " + 
      AppModule.UserList.FullName + " Maksimal"
  }
}