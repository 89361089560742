<template>
  <kst-load-data activeOnLoad @init="handleLoad_init">
    <Export
      :userData="userData"
      @init="handleTemplate_init"
    />
  </kst-load-data>
</template>

<script>
import Info from "../StopInfo.js";
import Model from "../StopModel.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import Export from "./StopExT_Export.vue";

export default {
  components: {
    Export,
  },
  props: {
    userData: Object
  },
  data: () => ({
    Info: Info,
    Model: Model,
    // func
    loadData: null,
    exportExcelFunc: null
  }),
  methods: {
    /*** public method */

    show() {
      this.handleExportTemplate();
    },

    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    /*** by template ***/

    handleTemplate_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    handleExportTemplate() {
      const config = Services.getExportTemplate();
      this.loadData(config, this.handleExportTemplate_success, true);
    },
    handleExportTemplate_success(data) {
      this.exportExcelFunc(data, Info.Export.New.Excel.FileName.Template);
    },
  }
}
</script>