<template>
  <kst-table action input-mode :data="userLowStockEmailList">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Email"
      :label="LowStockEmailCatalog.Email.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="LowStockEmailCatalog"
        :index="index" @input="handleName" @error="handleErrors"
      />
    </b-table-column>

    <template #action="{ row, index }">
        <template v-if="index !== 0">
          <kst-tooltip delete-mode hover-only :content="Model.Module.FullName">
            <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
          </kst-tooltip>
        </template>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";
import LowStockEmailCatalog from "../CpLowStockEmailCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userLowStockEmailList: Array
  },
  data: () => ({
    Model: Model,
    LowStockEmailCatalog: LowStockEmailCatalog
  }),
  methods: {
    handleName() {
      Model.updateEmailErrors(this.userLowStockEmailList);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.Edit_Email_ConfirmDelete,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },
    handleRowDelete_confirm(index) {
      this.userLowStockEmailList.splice(index, 1);
    },

    handleErrors(errors, field, index) {
      Model.setEmailErrors(
        this.userLowStockEmailList,
        index,
        field,
        errors
      );
    }
  }
}
</script>