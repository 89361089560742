<template>
  <kst-table action view-mode :detailed="detailed" :data="items">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveNumber"
      :label="Catalog.ReceiveNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Fld_Amount">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal has-text-weight-bold"
        field="Total"
        :label="Catalog.Total.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items"/>
        <Total :data="row"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../RcvCatalog.js";

import Action from "./RcvRL_Row_Action.vue";
import Items from "./RcvRL_Row_Items.vue";
import Total from "./RcvRL_Row_Total.vue";

export default {
  components: {
    Action,
    Items,
    Total
  },
  props: {
    access: Object,
    detailed: Boolean,
    items: Array,
    isLoading: Boolean,
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>