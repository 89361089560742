<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-4">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-input floating-label
        field="CategoryID" :data="searchData" :catalog="SearchCatalog"
        :options="categoryOptions" @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-input floating-label
        field="IsActive" :data="searchData" :catalog="SearchCatalog"
        :options="isActiveOptions" @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./SearchCatalog.js";
import Model from "../ExpcModel.js";

export default {
  props: {
    searchData: Object,
    categoryOptions: Object,
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  computed:{
    isActiveOptions() {
      return Model.createIsActiveOptions(SearchCatalog.IsActive.Label);
    }
  },
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
  }
}
</script>