import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "IsActive"
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  }
}