import App from "../../services/App/App.js";
import ModelBody from "./VenModel_Body.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  /*** method ***/

  setVendorItemByItem(userVendorItem, item, packagingList) {
    userVendorItem.ItemID = item.ID;
    // by system
    userVendorItem.PackagingName = item.PackagingPcs;
    userVendorItem.Name = item.Name;
    userVendorItem.Barcode = item.Barcode;
    userVendorItem.SKU = item.SKU;
    userVendorItem.ImageSrc = item.ImageSrc;
    userVendorItem.PackagingOptions = ItemModel.createPackagingOptions(
      packagingList
    );

  },
  setVendorItemsByItem(userVendorItemList, itemList, 
    itemPackagingList, details
  ) {
    App.Array.truncate(userVendorItemList);
    const packagingObj = this.setVendorItemsByItem_getPackagingObj(itemPackagingList);

    let userVendorItem, packagingList;
    for (const item of itemList) {
      userVendorItem = ModelBody.createVendorItem(details);
      packagingList = packagingObj[item.ID]
      this.setVendorItemByItem(userVendorItem, item, packagingList);
      ModelBody.updateVendorItem(userVendorItem);

      userVendorItemList.push(userVendorItem);
    }
  },
  setVendorItemsByItem_getPackagingObj(packagingList) {
    let packagingObj = {};

    for (let packaging of packagingList) {
      if (!App.Data.hasProp(packagingObj, packaging.ItemID)) {
        packagingObj[packaging.ItemID] = [];
      }
      packagingObj[packaging.ItemID].push(packaging);
    }

    return packagingObj;
  },
  setVendorItemListByData(userVendorItemList, vendorItemList) {
    App.Array.truncate(userVendorItemList);

    for (let vendorItem of vendorItemList) {
      vendorItem.Packaging = App.Data.getPackaging(
        vendorItem.PackagingName, vendorItem.PackagingValue
      );
      
      userVendorItemList.push(vendorItem);
    }
  }
}