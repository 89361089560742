<template>
  <kst-page-export
    :catalog="RecSearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
  </kst-page-export>
</template>

<script>
import Info from "../RecInfo.js";
import Model from "../RecModel.js";
import RecSearchCatalog from "../RecSearchCatalog.js";
import Services from "../../../services/Api/RecServices.js";

import Export from "./RecExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    RecSearchCatalog: RecSearchCatalog,
    searchData: {
      Search: "",
      Client: "",
      ClientID: null, 
      InvoiceDate: null,
      Status: null,
      WarehouseID: null
    },
    userData: {
      WithList: true
    },
    searchParams: ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID"],
    // func
    exportExcelFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Client = "";
      this.searchData.ClientID = null;
      this.searchData.InvoiceDate = this.$kst.In.getDefaultDateRange();
      this.searchData.Status = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.Client) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[1]),
        client: clientId,
        clientname: clientName,
        status: this.$kst.Search.getValue(this.searchData.Status),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>