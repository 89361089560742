<template>
  <kst-table view-mode :data="items">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchID"
      :label="Catalog.DispatchID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="Stock"
      :label="Catalog.Stock.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="TotalQty"
      :label="Catalog.TotalQty.AltLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      cell-class="has-text-weight-bold"
      v-slot="{ column, row }"
      field="StockDiff"
      :label="Catalog.StockDiff.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../StopItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>