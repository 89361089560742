import AppCatalog from "../../../services/App/AppCatalog.js";
import AppModule from "../../../services/App/AppModule.js";
import Catalog from "../StavCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.DraftNumber.Label + ", atau " +
      Catalog.ClientID.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: Catalog.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  ItemID: {
    Label: AppModule.Item.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ItemName",
    RelatedModule: AppModule.Item,
    Param: AppModule.Item.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}