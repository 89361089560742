import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import PurItemCatalog from "./PurItemCatalog.js";

export default {
  getItemInfo(userItem, newItemDefault) {
    let infoList = [];

    // new-item
    if (userItem.DispatchID === newItemDefault.ItemID) {
      infoList.push(AppCatalog.Info.NewItem);
    }
    else {
      // deleted item
      if (!userItem.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }
      else {
        // SKU
        infoList.push(PurItemCatalog.SKU.Label + ": " +
          App.Value.getValue("SKU", userItem, PurItemCatalog)
        );
      }

      // QtyReceive
      infoList.push(PurItemCatalog.QtyReceive.Label + ": " +
        App.Value.getValue("QtyReceive", userItem, PurItemCatalog)
      );
    }

    return infoList;
  }
}