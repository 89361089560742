<template>
  <kst-modal-input
    :title="title"
    :width="500"
    @init="handleInit"
    @submit="handleSubmit"
  >
    <kst-input addons field="SelfServicePin" :data="userData" :catalog="Catalog">
      <template #right>
        <div class="control">
          <kst-button random-mode @click="handleRandom"/>
        </div>
      </template>
    </kst-input>
  </kst-modal-input>
</template>

<script>
import Catalog from "../CliCatalog.js";
import Info from "../CliInfo.js";
import Model from "../CliModel.js";
import Services from "../../../services/Api/ClientServices.js";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  data: () => ({
    Catalog: Catalog,
    userData: {
      ID: null,
      SelfServiceID: null,
      SelfServicePin: ""
    },
    // func
    hideFunc: null,
    showFunc: null
  }),
  computed: {
    title() {
      return this.userData.SelfServiceID === null
        ? Info.Modal.Activate.Title
        : Info.Modal.EditPin.Title
      ;
    }
  },
  methods: {
    /*** public method ***/

    show(id, selfServiceId) {
      this.userData.ID = id;
      this.userData.SelfServiceID = selfServiceId;

      this.showFunc();
    },

    /*** by modal ***/

    handleInit(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleSubmit(saveData) {
      let data = Model.SelfService.populateData(this.userData);
      const config = Services.putSelfService(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, data);
      this.hideFunc();
    },

    /*** custom ***/

    handleRandom() {
      this.userData.SelfServicePin = Math.random().toString(36).substring(2);
    },
    init_Data() {
      // userData
      this.userData.ID = null;
      this.userData.SelfServiceID = null;
      this.userData.SelfServicePin = "";
    }
  }
}
</script>