import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StkEnum from "./StkEnum.js";
import Catalog from "./StkCatalog.js";

export default {
  // required in model
  Module: AppModule.Stock,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    List: AppModuleType.List,
    SearchList: AppModuleType.SearchList,
    // support
    ExportList: AppModuleType.ExportList
  },

  /*** related ***/

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      Edit: AppModuleType.Edit,
      List: AppModuleType.List,
      Search: AppModuleType.Search
    }
  },

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.Item_Stock_Act_Export,
    Act_List: AppModuleAttr.Item_Stock_Act_List,
    Act_Print: AppModuleAttr.Item_Stock_Act_Print,
    Glob_HPP: AppModuleAttr.All_Glob_HPP,
    Item_Act_Edit: AppModuleAttr.Item_Act_Edit
  },

  Actions: {
    List: [
      // module: item
      AppModuleType.Edit
    ]
  },

  Search: {
    ListFields: ["Name", "SKU"],
    ListParams: ["Search", "LastInvoiceDate", "StatusID", "WarehouseID"],
    SearchListFields: ["Name", "SKU"]
  },

  LastInvoiceDate: StkEnum.LastInvoiceDate,
  Status: StkEnum.Status,

  /*** method ***/

  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      {
        [rowId]: statusEnum.Available.ID,
        [rowLabel]: statusEnum.Available.Label
      },
      {
        [rowId]: statusEnum.NotAvailable.ID,
        [rowLabel]: statusEnum.NotAvailable.Label
      }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createLastInvoiceDateOptions(optionAllText, lastInvoiceDateEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      {
        [rowId]: lastInvoiceDateEnum.Available.ID,
        [rowLabel]: lastInvoiceDateEnum.Available.Label
      },
      {
        [rowId]: lastInvoiceDateEnum.NotAvailable.ID,
        [rowLabel]: lastInvoiceDateEnum.NotAvailable.Label
      }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  getResultSectionHelp(resultData) {
    return resultData.HppTotal === null
      ? null
      : Catalog.HppTotal.ResultLabel + ": " +
        App.Value.getValue("HppTotal", resultData, Catalog);
  }
}