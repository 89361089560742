import AppCatalog from "@/services/App/AppCatalog.js";
import Catalog from "../ItemCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.Name.Label + ", " +
      Catalog.SKU.Label + " atau " +
      Catalog.Barcode.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  }
}