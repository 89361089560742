import Catalog from "./StkCatalog.js";

export default {
  LastInvoiceDate: {
    Available: {
      ID: 1,
      Label: "Ada Penjualan"
    },
    NotAvailable: {
      ID: 0,
      Label: "Tidak ada Penjualan"
    }
  },

  Status: {
    Available: {
      ID: 1,
      Label: Catalog.Quantity.Label + " Tersedia"
    },
    NotAvailable: {
      ID: 2,
      Label: Catalog.Quantity.Label + " Tidak Tersedia"
    }
  }
}