<template>
  <kst-section :title="sectionTitle">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Model.ClientAddress.Module.FullName"
        :moduleType="Model.ClientAddress.ModuleType.New"
        @click="handleAdd_Address"
      />
    </template>

    <List :userAddresses="userAddresses"/>
  </kst-section>
</template>

<script>
import Info from "../CliInfo.js";
import Model from "../CliModel.js";

import List from "./CliN_Addresses_List.vue";

export default {
  components: {
    List
  },
  props: {
    userAddresses: Array
  },
  data: () => ({
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Address.Title;
    }
  },
  methods: {
    handleAdd_Address() {
      const address = Model.createAddress();
      this.userAddresses.push(address);
    }
  }
}
</script>