import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RcvCatalog.js";
import Model from "./RcvModel.js";
import RcvScannerCatalog from "./RcvScannerCatalog.js";

export default {
  Dialog: {
    SearchItem: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.ReceiveItem.Module.Class +
        Model.ReceiveItem.ModuleType.SearchList.Class,
      Title: AppModuleType.Search.Name + " " + Model.ReceiveItem.Module.FullName +
        " dari " + Model.Module.FullName
    },
    SearchListPrice: {
      Title: AppModuleType.SearchList.FullName + " " +
        AppCatalog.Field.BuyPrice.Label
    },
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name
        },
        Section: {
          ReceiveItem: AppModuleType.List.Name + " " + AppModule.Item.FullName + ":"
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: Model.ModuleType.List.Name
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Receive.FullName,
          ReceiveItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Edit_ConfirmDeleteItem:
      "Yakin ingin menghapus " + AppModule.Item.FullName + "?",
    Edit_PurchaseFields:
      "Tidak diperkenankan mengubah " + Catalog.PurchasingID.Label + " dan " +
        Catalog.PurchasingDate.Label + ".",
    New_Purchase:
      "Memilih / menghapus " + AppModule.Purchase.FullName + ", " +
      "akan me-reset data " + Model.Module.FullName + "."
  },
  PriceSearch: {
    BuyPrice: {
      High: {
        Icon: "mdi mdi-arrow-up-bold",
        IconClass: "has-text-danger",
        TooltipClass: "is-danger",
        TooltipText: "Harga Beli lebih mahal dari normal"
      },
      Low: {
        Icon: "mdi mdi-arrow-down-bold",
        IconClass: "has-text-success",
        TooltipClass: "is-success",
        TooltipText: "Harga Beli lebih murah dari normal"
      }
    }
  },
  Tabs: {
    DebtPayList: Model.DebtPay.ModuleType.List.Name + " " +
      Model.DebtPay.Module.FullName,
    Details: AppModuleType.Details.Name,
    Return: AppCatalog.Info.Return
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    DebtPay: {
      Title: Model.DebtPay.Module.FullName
    },
    ItemSearch: {
      Title: AppCatalog.Section.SelectOne
    },
    Purchase: {
      Title: "Info " + Model.Purchase.Module.FullName
    },
    Receive: {
      Title: "Info Penerimaan"
    },
    RtnReceiveList: {
      Title: AppModuleType.List.Name + " " + AppModule.RtnReceive.FullName
    },
    Scanner: {
      Title: AppCatalog.Field.Barcode.Label + " Scanner",
      Error: {
        BarcodeRequired: AppCatalog.Field.Barcode.Label + " wajib diisi.",
        BarcodeInvalid: AppCatalog.Field.Barcode.Label + " tidak ada.",
        QtyInvalid: "Minimum nilai " + RcvScannerCatalog.Qty.ErrorLabel +
          " adalah 1."
      }
    }
  }
}