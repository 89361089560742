import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StkEx from "./Export/StkEx.vue";
import StkL from "./List/StkL.vue";
import StkP from "./Print/StkP.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.Stock, AppModuleType.List),
    Print: RouterModel.getPath(AppModule.Stock, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Stock, AppModuleType.Export),
        name: RouterModel.getName(AppModule.Stock, AppModuleType.Export),
        component: StkEx,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Stock, AppModuleType.Export),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Stock_Act_Export,
          moduletype: AppModuleType.Export
        }
      },
      {
        path: RouterModel.getPath(AppModule.Stock, AppModuleType.List),
        name: RouterModel.getName(AppModule.Stock, AppModuleType.List),
        component: StkL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Stock, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Stock_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Stock),
            moduleattr: AppModuleAttr.Item_Stock_Act_Export,
            moduletype: AppModuleType.Export
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Stock),
            moduleattr: AppModuleAttr.Item_Stock_Act_Print,
            moduletype: AppModuleType.Print
          },
          related: {
            module: RouterModel.getModule(AppModule.Item),
            moduleattr: AppModuleAttr.Item_Act_List,
            moduletype: AppModuleType.List
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Stock, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Stock, AppModuleType.Print),
        component: StkP,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Stock, AppModuleType.Print),
          module: RouterModel.getModule(AppModule.Item),
          moduleattr: AppModuleAttr.Item_Stock_Act_Print,
          moduletype: AppModuleType.Print
        }
      }
    ];
  }
}