import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  DiscType: {
    None: {
      ID: 0,
      Label: AppCatalog.Message.Without + " " + AppCatalog.Field.Disc.Label
    },
    Value: {
      ID: 1,
      Label: AppCatalog.Field.DiscValue.Label
    },
    Percent: {
      ID: 2,
      Label: AppCatalog.Field.DiscPercent.Label
    }
  },

  RecordType: {
    Invoice: {
      ID: 0,
      Label: AppModule.Invoice.FullName + " Manual"
    },
    Pos: {
      ID: 1,
      Label: AppModule.Pos.FullName
    }
  },

  Status: {
    Open: {
      ID: 1,
      Class: App.Enum.Status.Open.Class,
      Label: App.Enum.Status.Open.Label
    },
    Closed: {
      ID: 2,
      Class: App.Enum.Status.Closed.Class,
      Label: App.Enum.Status.Closed.Label
    }
  },

  StatusPos: {
    Pos: {
      ID: 1,
      Class: App.Enum.Status.Pos.Class,
      Label: App.Enum.Status.Pos.Label
    }
  },

  StatusSalesDraftIsActive: {
    InActive: {
      ID: 0,
      Class: App.Enum.Status.InActive.AltClass,
      Label: App.Enum.Status.InActive.AltLabel
    },
    Active: {
      ID: 1,
      Class: App.Enum.Status.Active.Class,
      Label: App.Enum.Status.Active.Label
    }
  }
}