import Catalog from "./AcsCatalog.js";
import Model from "./AcsModel.js";

export default {
  Message: {
    Edit_IsActive:
      "Tidak diperkenankan mengubah " + Catalog.IsActive.Label + ", " +
      "saat sudah memiliki " + Model.User.Module.FullName + ".",
  },
  Section: {
    Modules: {
      Title: Model.AccessModule.ModuleType.List.Name + " " +
        Model.AccessModule.Module.FullName
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}