import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./StkCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.Name.Label + ", atau " +
      Catalog.SKU.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },

  LastInvoiceDate: {
    Label: AppCatalog.Field.Stock.Label + " dengan Penjualan",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.LastInvoiceDate.Param 
  },
  StatusID: {
    Label: Catalog.Quantity.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}