<template>
  <div class="p-4 kst-pagenew" :class="theClass">
    <kst-container>
      <ValidationObserver ref="formDetails">
        <nav class="level mb-0 p-5 ks-border-b-light">
          <div class="level-left">
            <div class="is-flex">
              <kst-image is-public brand-img/>
              <div class="is-size-3 has-text-weight-bold m-0 pl-4 pt-4">{{ theTitle }}</div>
            </div>
          </div>
          <div class="level-right">
            <div class="buttons is-justify-content-end">
              <template v-if="signoutButtonVisible">
                <kst-button slim-size signout-mode @click="handleSignout"/>
              </template>
              <template v-if="reloadButtonVisible">
                <kst-button slim-size reload-mode @click="handleReload"/>
              </template>
            </div>
          </div>
        </nav>

        <div class="kst-pagenew__content p-5">
          <kst-skeleton :active="isLoading"/>

          <template v-if="defaultSlotVisible">
            <slot />
          </template>

          <kst-failure init-mode :items="failureInit"/>
          <kst-failure save-mode :items="failure"/>
          <kst-errors class="pt-4" :items="errors"/>
        </div>
      </ValidationObserver>

      <template v-if="buttonsAreaVisible">
        <div class="p-5 ks-border-t-light">
          <div class="buttons is-justify-content-end">
            <kst-button submit-mode @click="handleSubmit"/>
          </div>
        </div>
      </template>
    </kst-container>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import DialogMixin from "../../../mixins/DialogMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event and do loadNew,
 *    set and do reload/showFailureInit/stopInit when needed
 * 2. handle Submit event and do saveData
 */
export default {
  mixins: [AxiosMixin, DialogMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    // loading
    isLoading: true,
    saveLoading: null,
    // area
    buttonsAreaVisible: false,
    // buttons
    reloadButtonVisible: false,
    signoutButtonVisible: false,
    // slot
    defaultSlotVisible: false,
    // error
    failure: null,
    failureInit: null,
    errors: null
  }),
  computed: {
    module() {
      return this.$route.meta.module;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return AppCatalog.Info.SalesSelfService;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init,
        this.loadNew,
        this.showFailureInit,
        this.stopInit,
        this.reload
      );
    },
    init_Data() {
      // loading
      this.isLoading = true;
      this.saveLoading = null;
      // area
      this.buttonsAreaVisible = false;
      // buttons
      this.reloadButtonVisible = false;
      this.signoutButtonVisible = false;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failure = null
      this.failureInit = null;
      this.errors = null;
    },
    init_Success() {
      this.isLoading = false;
      this.buttonsAreaVisible = true;
      this.reloadButtonVisible = true;
      this.signoutButtonVisible = true;
      this.defaultSlotVisible = true;
    },
    init_Failed() {
      this.isLoading = false;
      this.reloadButtonVisible = true;
      this.signoutButtonVisible = true;
    },

    handleSignout() {
      this.$kst.Session.clear();
      this.reload();
    },
    handleReload() {
      this.showConfirm(
        AppCatalog.Message.ReloadPage,
        this.handleReload_Confirm
      );
    },
    handleReload_Confirm() {
      this.init();
    },

    /*** custom ***/

    handleSubmit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.$emit(this.$kst.Enum.Event.Submit, this.saveData);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    loadNew(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadNew_Success,
        this.loadNew_Fault,
        this.loadNew_Fault,
        null, true
      );
    },
    loadNew_Success(data, successCallback) {
      successCallback(data);
      this.init_Success();
    },
    loadNew_Fault(message) {
      this.failureInit = message;
      this.init_Failed();
    },

    saveData(config, successCallback) {
      this.errors = null;
      this.failure = null;
      this.saveLoading = this.openLoading();

      this.runAxios(config, successCallback,
        this.saveData_Success,
        this.saveData_Error,
        this.saveData_Invalid
      );
    },
    saveData_End() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_Success(data, successCallback) {
      this.saveData_End();
      this.buttonsAreaVisible = false;
      successCallback(data);

      this.notifyFormSuccess();
      this.reload();
    },
    saveData_Error(message) {
      this.failure = message;
      this.saveData_End();
    },
    saveData_Invalid(message) {
      this.errors = message;
      this.saveData_End();
      this.notifyFormError();
    },

    reload() {
      this.init();
    },
    showFailureInit(message) {
      this.failureInit = message;
      this.isLoading = false;
    },
    stopInit() {
      this.isLoading = false;
      this.reloadButtonVisible = true;
      this.defaultSlotVisible = true;
    }
  }
}
</script>