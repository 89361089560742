<template>
  <PageDetails
    @init="handleInit"
    :actions="actions"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <SelfService :details="details.Setting"/>
        <Setting :details="details.Setting"/>
        <LowStockReminder :details="details.Setting"/>
        <DebtReminder :details="details.Setting"/>
        <RecReminder :details="details.Setting"/>
        <Subscription :details="details"/>
        <ModulePackageList :items="details.ModulePackageList"/>
        <ModuleList :items="details.ModuleList"/>
      </b-tab-item>
      <b-tab-item :label="tabs.Accessibility">
        <AccessibilityList :items="details.Accessibility"/>
      </b-tab-item>
    </b-tabs>
  </PageDetails>
</template>

<script>
import Info from "../CpInfo.js";
import Model from "../CpModel.js";
import Services from "../../../services/Api/CompanyServices.js";

import PageDetails from "./CpD_PageDetails.vue";
import AccessibilityList from "./CpD_AccessibilityList.vue";
import DebtReminder from "./CpD_DebtReminder.vue";
import Details from "./CpD_Details.vue";
import LowStockReminder from "./CpD_LowStockReminder.vue";
import ModulePackageList from "./CpD_ModulePackageList.vue";
import ModuleList from "./CpD_ModuleList.vue";
import RecReminder from "./CpD_RecReminder.vue";
import SelfService from "./CpD_SelfService.vue";
import Setting from "./CpD_Setting.vue";
import Subscription from "./CpD_Subscription.vue";

export default {
  components: {
    AccessibilityList,
    DebtReminder,
    Details,
    LowStockReminder,
    PageDetails,
    ModulePackageList,
    ModuleList,
    RecReminder,
    SelfService,
    Setting,
    Subscription
  },
  data: () => ({
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      Accessibility: Info.Tabs.Accessibility
    },
  }),
  methods: {
    handleInit(loadDetails) {
      this.resetData();

      const config = Services.getDetailsData();
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      Model.Details.setDetailsByEmailList(this.details);
      this.$kst.Session.setCompanyImageSrc(this.details.ImageSrc);
    },

    /*** custom ***/

    resetData() {
      this.details = {};
      this.activeTab = 0;

      // actions
      let access = this.$kst.Access.create(Model.Access);
      
      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, "", access
      );
    }
  }
}
</script>