import App from "../../services/App/App";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr";
import AppModuleType from "../../services/App/AppModuleType.js";
import WarehouseModel from "../Warehouse/WhsModel.js";
import Catalog from "./StavCatalog.js";

export default {
  // required in model
  Module: AppModule.StockAvailability,
  ModuleType: {
    List: AppModuleType.List
  },

  /*** related ***/

  Sales: {
    Module: AppModule.Sales,
    ModuleType: {
      Details: AppModuleType.Details
    }
  },

  /*** property ***/

  Access: {
    Act_List: AppModuleAttr.StockAvailability_Act_List,
    // related
    Sales_Act_Details: AppModuleAttr.Sales_Act_Details
  },

  Search: {
    Fields: ["DraftNumber", "ClientName"]
  },

  /*** method ***/

  setDetailsByWarehouseList(userData, warehouseList) {
    return this.helper.setDetailsByWarehouseList(userData, warehouseList);
  },

  getItemInfo(item) {
    return this.helper.getItemInfo(item);
  },
  getSummaryList(items) {
    return this.helper.getSummaryList(items);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    setDetailsByWarehouseList(userData, warehouseList) {
      userData.WarehouseID = WarehouseModel.getDefault(
        warehouseList, userData.WarehouseID
      );
    },

    getItemInfo(item) {
      let infoList = [];

      // Qty
      infoList.push(Catalog.Qty.Label + ": " +
        App.Value.getValue("Qty", item, Catalog) + " " +
        App.Data.getPackaging(item.PackagingName, item.PackagingValue)
      );

      return infoList;
    },

    getSummaryList(items) {
      let fieldKeys, fieldValues;

      fieldKeys = "DispatchID";
      fieldValues = [
        { field: "TotalQty", type: App.Pivot.Type.SUM },
        { field: "StockQuantity", type: App.Pivot.Type.FIRST },
        { field: "DispatchName", type: App.Pivot.Type.FIRST }
      ];
      const pivotItems = App.Pivot.create(items, fieldKeys, fieldValues);

      for (const row of pivotItems) {
        row.MissingQty = row.TotalQty > row.StockQuantity
          ? row.TotalQty - row.StockQuantity : 0;
      }

      fieldKeys = [{
        [App.Sort.Key.Field]: "DispatchName",
        [App.Sort.Key.Order]: App.Sort.Order.Asc
      }];
      App.Sort.begin(pivotItems, fieldKeys);

      return pivotItems;
    }
  }
}