import App from "../../services/App/App.js";
import ModelBody from "./UserModel_Body.js";
import ModelEdit from "./UserModel_Edit.js";
import ModelEditPassword from "./UserModel_EditPassword.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  IsActive: ModelBody.IsActive,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.IsActive);
  },
  createOptions(items, optionAllText) {
    return ModelBody.createOptions(items, optionAllText);
  },

  createAccessOptions(items, optionAllText){
    return ModelBody.createAccessOptions(items, optionAllText);
  },
  createDefaultModuleOptions(items) {
    return ModelBody.createDefaultModuleOptions(items);
  },
  createIsActiveOptions(optionAllText) {
    return ModelBody.createIsActiveOptions(optionAllText);
  },

  getSectionHelp(deliveryData) {
    return ModelBody.getSectionHelp(deliveryData);
  },
  getSubtitle(activeUsers, maxUsers) {
    return ModelBody.getSubtitle(activeUsers, maxUsers);
  },

  populateData(data) {
    return ModelBody.populateDetails(data);
  },

  validationPassword() {
    return ModelBody.validationPassword();
  },

  /*** method - Edit ***/
  
  Edit: {
    setDetailsByData(userData, userDetails, isReachLimit) {
      return ModelEdit.setDetailsByData(userData, userDetails, isReachLimit);
    }
  },

  /*** method - Edit Password ***/

  EditPassword: {
    setDetailsByData(userData, userDetails) {
      return ModelEditPassword.setDetailsByData(userData, userDetails);
    },
    populateData(userData) {
      return ModelEditPassword.populateDetails(userData);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_EditPassword ||
        access.Act_Edit;
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}