import App from "../../../services/App/App.js";

export default {
    data: [{
        name: 'D\'Lite',
        monthly: {
            price: 0,
            disc: 0,
            desc: ''
        },
        yearly: {
            price: 0,
            disc: 0,
            desc: ''
        },
        desc: 'Ragu-ragu? Coba dulu aja, kamu bisa nikmati Fitur Dirigo secara GRATIS selamanya! *)Syarat dan Ketentuan berlaku',
        userLimit: 1,
        userLimitDetail: {
            owner: null,
            assistent: null,
            cashier: null
        },
        modules: [{
            name: 'Dashboard',
            submodules: [{
                name: 'Laba (Rugi)',
                active: false
            },{
                name: 'Penjualan (Invoice)',
                active: false
            },{
                name: 'Barang Terlaris',
                active: false
            }]
        },{
            name: 'Laporan',
            submodules: [{
                name: 'Kartu Stok',
                active: true
            },{
                name: 'Laba Rugi',
                active: false
            },{
                name: 'Omzet',
                active: false
            },{
                name: 'Laporan Harian',
                active: false
            },{
                name: 'Daftar Traksaksi',
                active: false
            },{
                name: 'Pergerakan Stok',
                active: false
            }]
        },{
            name: 'POS',
            active: false
        },{
            name: 'Finansial',
            submodules: [{
                name: 'Pengeluaran',
                active: false
            }]
        },{
            name: 'Inventaris',
            submodules: [{
                name: 'Daftar Barang',
                active: true
            },{
                name: 'Stok Opname',
                active: false
            },{
                name: 'Pengeluaran Stok',
                active: false
            },{
                name: 'Transfer Barang',
                active: false
            },{
                name: 'Penutupan Stok',
                active: false
            },{
                name: 'Penyesuaian HPP',
                active: false
            },{
                name: 'Persediaan Stok SO',
                active: false
            },{
                name: 'Penyesuaian Harga Spesial',
                active: false
            }]
        },{
            name: 'Pembelian',
            submodules: [{
                name: 'Pembelian Stok',
                active: true
            },{
                name: 'Penerimaan Stok',
                active: true
            }]
        },{
            name: 'Penjualan',
            submodules: [{
                name: 'Sales Order',
                active: false
            },{
                name: 'Nota Penjualan',
                active: true
            },{
                name: 'Retur Penjualan',
                active: false
            },{
                name: 'Pengiriman',
                active: false
            },{
                name: 'Rekap Penjualan',
                active: false
            }]
        },{
            name: 'Manajemen Kontak',
            submodules: [{
                name: 'Pelanggan',
                active: false
            },{
                name: 'Gudang',
                active: false
            },{
                name: 'Vendor',
                active: false
            }]
        },{
            name: 'Pengaturan',
            submodules: [{
                name: 'Profil Perusahaan',
                active: false
            },{
                name: 'Daftar Pengguna',
                active: false
            },{
                name: 'Penomoran Otomatis',
                active: false
            },{
                name: 'Klasifikasi Pengeluaran',
                active: false
            },{
                name: 'Harga Spesial',
                active: false
            },{
                name: 'Jenis Pembayaran',
                active: false
            },{
                name: 'Daftar Role',
                active: false
            }]
        }]
    },{
        name: 'D\'Basic',
        monthly: {
            price: 100000,
            disc: 0,
            desc: ''
        },
        yearly: {
            price: 1100000,
            disc: 0,
            desc: '(Hemat Rp 100.000!)'
        },
        desc: 'Pilihan paket terbaik untuk pebisnis baru  yang ingin meningkatkan efisiensi bisnisnya. Langkah yang baik!',
        userLimit: 5,
        userLimitDetail: {
            owner: null,
            assistent: null,
            cashier: null
        },
        modules: [{
            name: 'Dashboard',
            submodules: [{
                name: 'Penyesuaian Harga Spesial',
                active: true
            },{
                name: 'Penjualan (Invoice)',
                active: true
            },{
                name: 'Barang Terlaris',
                active: false
            }]
        },{
            name: 'Laporan',
            submodules: [{
                name: 'Kartu Stok',
                active: true
            },{
                name: 'Laba Rugi',
                active: true
            },{
                name: 'Omzet',
                active: false
            },{
                name: 'Laporan Harian',
                active: false
            },{
                name: 'Daftar Traksaksi',
                active: true
            },{
                name: 'Pergerakan Stok',
                active: false
            }]
        },{
            name: 'POS',
            active: true
        },{
            name: 'Finansial',
            submodules: [{
                name: 'Pengeluaran',
                active: false
            }]
        },{
            name: 'Inventaris',
            submodules: [{
                name: 'Daftar Barang',
                active: true
            },{
                name: 'Stok Opname',
                active: true
            },{
                name: 'Pengeluaran Stok',
                active: false
            },{
                name: 'Transfer Barang',
                active: false
            },{
                name: 'Penutupan Stok',
                active: false
            },{
                name: 'Penyesuaian HPP',
                active: false
            },{
                name: 'Persediaan Stok SO',
                active: false
            },{
                name: 'Penyesuaian Harga Spesial',
                active: false
            }]
        },{
            name: 'Pembelian',
            submodules: [{
                name: 'Pembelian Stok',
                active: true
            },{
                name: 'Penerimaan Stok',
                active: true
            }]
        },{
            name: 'Penjualan',
            submodules: [{
                name: 'Sales Order',
                active: true
            },{
                name: 'Nota Penjualan',
                active: true
            },{
                name: 'Retur Penjualan',
                active: false
            },{
                name: 'Pengiriman',
                active: false
            },{
                name: 'Rekap Penjualan',
                active: false
            }]
        },{
            name: 'Managemen Kontak',
            submodules: [{
                name: 'Pelanggan',
                active: false
            },{
                name: 'Gudang',
                active: false
            },{
                name: 'Vendor',
                active: false
            }]
        },{
            name: 'Pengaturan',
            submodules: [{
                name: 'Profil Perusahaan',
                active: true
            },{
                name: 'Daftar Pengguna',
                active: true
            },{
                name: 'Penomoran Otomatis',
                active: false
            },{
                name: 'Klasifikasi Pengeluaran',
                active: false
            },{
                name: 'Harga Spesial',
                active: false
            },{
                name: 'Jenis Pembayaran',
                active: false
            },{
                name: 'Daftar Role',
                active: true
            }]
        }]
    },{
        name: 'D\'Premium',
        monthly: {
            price: 300000,
            disc: 0,
            desc: ''
        },
        yearly: {
            price: 3100000,
            disc: 0,
            desc: '(Hemat Rp 500.000!)'
        },
        desc: 'Paket terbaik untuk pebisnis expert yang ingin fokus mengembangkan bisnisnya. Pilihan orang jenius!',
        userLimit: null,
        userLimitDetail: {
            owner: null,
            assistent: null,
            cashier: null
        },
        modules: [{
            name: 'Dashboard',
            submodules: [{
                name: 'Penyesuaian Harga Spesial',
                active: true
            },{
                name: 'Penjualan (Invoice)',
                active: true
            },{
                name: 'Barang Terlaris',
                active: true
            }]
        },{
            name: 'Laporan',
            submodules: [{
                name: 'Kartu Stok',
                active: true
            },{
                name: 'Laba Rugi',
                active: true
            },{
                name: 'Omzet',
                active: true
            },{
                name: 'Laporan Harian',
                active: false
            },{
                name: 'Daftar Traksaksi',
                active: true
            },{
                name: 'Pergerakan Stok',
                active: true
            }]
        },{
            name: 'POS',
            active: true
        },{
            name: 'Finansial',
            submodules: [{
                name: 'Pengeluaran',
                active: true
            }]
        },{
            name: 'Inventaris',
            submodules: [{
                name: 'Daftar Barang',
                active: true
            },{
                name: 'Stok Opname',
                active: true
            },{
                name: 'Pengeluaran Stok',
                active: false
            },{
                name: 'Transfer Barang',
                active: true
            },{
                name: 'Penutupan Stok',
                active: false
            },{
                name: 'Penyesuaian HPP',
                active: true
            },{
                name: 'Persediaan Stok SO',
                active: true
            },{
                name: 'Penyesuaian Harga Spesial',
                active: false
            }]
        },{
            name: 'Pembelian',
            submodules: [{
                name: 'Pembelian Stok',
                active: true
            },{
                name: 'Penerimaan Stok',
                active: true
            }]
        },{
            name: 'Penjualan',
            submodules: [{
                name: 'Sales Order',
                active: true
            },{
                name: 'Nota Penjualan',
                active: true
            },{
                name: 'Retur Penjualan',
                active: true
            },{
                name: 'Pengiriman',
                active: true
            },{
                name: 'Rekap Penjualan',
                active: false
            }]
        },{
            name: 'Managemen Kontak',
            submodules: [{
                name: 'Pelanggan',
                active: true
            },{
                name: 'Gudang',
                active: true
            },{
                name: 'Vendor',
                active: true
            }]
        },{
            name: 'Pengaturan',
            submodules: [{
                name: 'Profil Perusahaan',
                active: true
            },{
                name: 'Daftar Pengguna',
                active: true
            },{
                name: 'Penomoran Otomatis',
                active: true
            },{
                name: 'Klasifikasi Pengeluaran',
                active: true
            },{
                name: 'Harga Spesial',
                active: false
            },{
                name: 'Jenis Pembayaran',
                active: true
            },{
                name: 'Daftar Role',
                active: true
            }]
        }]
    }],

    getData() {
        return this.data.map(sub => {
            sub.monthly.priceFormatted = App.Format.getCurrency(sub.monthly.price);
            sub.monthly.discFormatted = App.Format.getCurrency(sub.monthly.disc);
            sub.yearly.priceFormatted = App.Format.getCurrency(sub.yearly.price);
            sub.yearly.discFormatted = App.Format.getCurrency(sub.yearly.disc);
            sub.limitUsersDesc = this.helper.getUserLimit(sub.userLimit);
            sub.limitUsersDetailDesc = this.helper.getUserDetail(sub.userLimitDetail);
            return sub;
        })
    },

    helper: {
        getUserLimit(userLimit) {
            if(userLimit !== null) {
                return `Max. ${userLimit} user${userLimit > 1 ? 's' : ''}`;
            } else {
                return 'Unlimited';
            }
        },
        getUserDetail(users) {
            let desc = '';
            const roles = Object.keys(users);
            if(roles.find(role => users[role])) {
                desc = Object.keys(users)
                    .map((user) => (`${users[user]} ${user}`))
                    .join(', ');
                desc = `(${desc})`;
            }
            return desc;
        }
    }
}