import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  /*** method ***/

  createDetails() {
    return {
      Email: "",
      // error
      Errors: [],
      ErrorsColl: {},
      // UI validation
      Email_Valid: true,
      Email_Null: false
    };
  },

  getErrors(userEmailList) {
    let emailList = [];

    for (let emailObj of userEmailList) {
      // validate: duplicate Email 
      if (emailList.includes(emailObj.Email)) {
        emailObj.Email_Valid = false;
      }
      else {
        emailObj.Email_Valid = true;
        emailList.push(emailObj.Email);
      }
    }
  },

  populateList(userEmailList) {
    let row, result = [], email, emailObj;

    for (row of userEmailList) {
      email = App.Out.getString(row.Email);

      if (email !== null) {
        emailObj = { Email: email };
        result.push(emailObj);
      }
    }

    return result;
  },

  setErrors(userEmailList, index, field, errors) {
    const emailObj = userEmailList[index];
    
    // custom error message
    if (App.Out.getString(emailObj.Email) === null) {
      emailObj.Email_Null = true;
    } else {
      emailObj.Email_Null = false;
    }

    const message = AppCatalog.Field.Email.Label + " wajib diisi.";
    const indexOfMessage = errors.indexOf(message)
    const isNullErrorEmail = emailObj.Email_Null && indexOfMessage === -1;

    if (isNullErrorEmail) {
      if (index !== 0) {
        errors.push(message);
      }
  
      if (index == 0 && userEmailList.length > 1) {
        errors.push(message);
      }
    }

    if (emailObj.Email_Null && 
      indexOfMessage !== -1 && 
      userEmailList.length === 1 && 
      index == 0) {
        errors.splice(indexOfMessage, 1);
    }

    // populate error in current field
    emailObj.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        emailObj.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in emailObj.ErrorsColl) {
      fieldErrors = emailObj.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    // custom error message
    if (!emailObj.Email_Valid && !emailObj.Email_Null) {
      newErrors.push(AppCatalog.Field.Email.Label + " " + 
        AppCatalog.Message.InUse);
    }

    emailObj.Errors = newErrors;
  },

  setDetailsByEmailList(userEmailList, detailsEmailList) {
    let newDetails;
    App.Array.truncate(userEmailList);

    if (detailsEmailList && detailsEmailList.length > 0) {
      for (const row of detailsEmailList) {
        newDetails = this.createDetails();
  
        newDetails.Email = App.In.getString(row.Email);
        userEmailList.push(newDetails);
      }
    } else {
      newDetails = this.createDetails();
      userEmailList.push(newDetails);
    }
  },
}