<template>
  <div>
    <div class="label">
      {{ title }}:
    </div>

    <kst-list :items="theItems"/>
  </div>
</template>

<script>
import Catalog from "../ModuleAttrCatalog.js";

export default {
  props: {
    items: Array,
    title: String
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    theItems() {
      let resultList = [];

      for (const item of this.items) {
        resultList.push(item.ModuleAttrName);
      }
      return resultList;
    }
  }
}
</script>