<template>
  <kst-output control-off field="LastUpdatedBy" :data="data" :catalog="Catalog">
    <template #default>
      <kst-output expanded floating-label
        class="pr-2"
        field="LastUpdatedByID" :data="data" :catalog="Catalog"
      />
    </template>
    <template #right>
      <kst-output addons expanded floating-label
        field="LastUpdatedDate" :data="data" :catalog="Catalog"
      />
    </template>
  </kst-output>
</template>

<script>
import Catalog from "./Catalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>