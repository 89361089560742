import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StrdD from "./Details/StrdD.vue";
import StrdExD from "./ExportDetails/StrdExD.vue";
import StrdExL from "./ExportList/StrdExL.vue";
import StrdL from "./List/StrdL.vue";
import StrdN from "./New/StrdN.vue";
import StrdPD from "./PrintDetails/StrdPD.vue";
import StrdPL from "./PrintList/StrdPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Details),
    ExportDetails: RouterModel.getPath(AppModule.StockReduction, AppModuleType.ExportDetails),
    List: RouterModel.getPath(AppModule.StockReduction, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockReduction, AppModuleType.New),
    Print: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Print),
    PrintDetails: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.Details),
        component: StrdD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.ExportDetails),
        component: StrdExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.ExportList),
        component: StrdExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.List),
        component: StrdL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.StockReduction),
            moduleattr: AppModuleAttr.StockReduction_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.StockReduction),
            moduleattr: AppModuleAttr.StockReduction_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.StockReduction),
            moduleattr: AppModuleAttr.StockReduction_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.New),
        component: StrdN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.New),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.StockReduction, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.StockReduction, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.PrintDetails),
        component: StrdPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockReduction, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.StockReduction, AppModuleType.PrintList),
        component: StrdPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockReduction, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.StockReduction),
          moduleattr: AppModuleAttr.StockReduction_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}