<template>
  <div>
    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="StockTrfNumber"
    />

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
    <kst-input field="SourceWarehouseID" :data="userData" :catalog="Catalog"
      :disabled="userItems.length > 0" :options="warehouseOptions"
    />
    <kst-input field="DestinationWarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from "../SttrCatalog.js";
import Model from "../SttrModel.js";

export default {
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>