<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="DlvItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="DlvItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="DlvItemCatalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DlvItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="DlvItemCatalog"/>
      </kst-value>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import DlvItemCatalog from "../DlvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    DlvItemCatalog: DlvItemCatalog
  })
}
</script>