<template>
  <kst-table view-mode :data="userInvoiceList">
    <b-table-column sortable
      v-slot="{ column, row}"
      field="SONumber"
      :label="SlrcInvCatalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row}"
      field="Client"
      :label="SlrcInvCatalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="SlrcInvCatalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="SlrcInvCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userInvoiceList" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import SlrcInvCatalog from "../SlrcInvCatalog.js";

export default {
  props: {
    userInvoiceList: Array
  },
  data: () => ({
    SlrcInvCatalog: SlrcInvCatalog
  })
}
</script>