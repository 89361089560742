<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../HpadCatalog.js";
import HpadItemCatalog from "../HpadItemCatalog.js";
import Info from "../HpadInfo.js";
import Model from "../HpadModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.AdjNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(HpadItemCatalog.DispatchID.Label);
        this.addColumnHeader(HpadItemCatalog.DispatchID);
        this.addColumnHeader(HpadItemCatalog.Packaging);
        this.addColumnHeader(HpadItemCatalog.Qty);
        this.addColumnHeader(HpadItemCatalog.TotalQty);
        this.addColumnHeader(HpadItemCatalog.HppTotal);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("AdjNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, HpadItemCatalog);
          this.addCellRelatedValue("DispatchID", row, HpadItemCatalog);
          this.addCellValue("Packaging", row, HpadItemCatalog);
          this.addCellValue("Qty", row, HpadItemCatalog);
          this.addCellValue("TotalQty", row, HpadItemCatalog);
          this.addCellValue("HppTotal", row, HpadItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>