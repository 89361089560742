import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  ItemID: {
    Label: AppModule.Item.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "ItemName",
    Param: AppModule.Item.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  },

  // support
  ItemName: {
    Label: AppModule.Item.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  }
}