import AppModule from "../../services/App/AppModule.js";
import Info from "./PlosInfo.js";
import ModelBody from "./PlosModel_Body.js";

// formula: AccountingBased
export default {
  getSummary(transactionDetails) {
    let result = ModelBody.createSummary();
    let newTransRow;

    newTransRow = this.getSummary_createTransRow(
      AppModule.Invoice.FullName, transactionDetails.InvoiceTotal, null
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      Info.Transaction.InvoiceHpp, null, transactionDetails.InvoiceHppTotal
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.HppAdj.FullName, transactionDetails.HppAdjTotal, null
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.StockOpname.FullName, transactionDetails.StockOpnameTotal, null
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.RtnInvoice.FullName, null, transactionDetails.RtnInvoiceTotal
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.Expense.FullName, null, transactionDetails.ExpenseTotal
    );
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_createTransRow(
      AppModule.StockReduction.FullName, null,
      transactionDetails.StockReductionTotal
    );
    result.TransactionList.push(newTransRow);

    if (Object.prototype.hasOwnProperty.call(transactionDetails, "StockTrf")) {
      newTransRow = this.getSummary_createTransRow(
        AppModule.StockTrf.FullName, transactionDetails.StockTrf.AddTotal,
        transactionDetails.StockTrf.SubTotal
      );
      result.TransactionList.push(newTransRow);
    }

    // old
    for (const row of result.TransactionList) {
      if (row.AddTotal !== null) {
        result.Total += row.AddTotal;
      }

      if (row.SubTotal !== null) {
        result.Total -= row.SubTotal;
      }
    }

    return result;
  },
  getSummary_createTransRow(moduleName, addTotal, subTotal) {
    return {
      Label: moduleName,
      AddTotal: addTotal,
      SubTotal: subTotal
    };
  }
}