import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./RcvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.ReceiveNumber.Label + ", atau " +
      Catalog.PurchasingID.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },

  Date: {
    Label: Catalog.Date.Label,
    Type: Catalog.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  VendorID: {
    Label: Catalog.VendorID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "VendorName",
    Param: AppModule.Vendor.Param + AppCatalog.Field.ID.Param
  },
  VendorName: {
    Label: Catalog.VendorID.Label,
    Type: Catalog.VendorName.Type,
    Param: AppModule.Vendor.Param + AppCatalog.Field.Name.Param 
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}