import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../CliCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.Name.Label + ", atau " +
      Catalog.Alias.Label + ", atau " +
      Catalog.Mobile.Label + ", atau " +
      Catalog.Phone.Label + ", atau " +
      Catalog.Email.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  }
}