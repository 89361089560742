<template>
  <kst-print-table :data="itemsRaw">
    <b-table-column
      v-slot="{ column, row }"
      field="ItemID"
      :label="StclItemCatalog.ItemID.ColumnLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="StclItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="StclItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StclItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StclItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StclItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="StclItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="StclItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StclItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="SubTotal"
      :label="StclItemCatalog.SubTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StclItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StclItemCatalog from "../StcvItemCatalog.js";

export default {
  props: {
    data: Object,
    itemsRaw: Array
  },
  data: () => ({
    StclItemCatalog: StclItemCatalog
  })
}
</script>