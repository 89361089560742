import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../SpCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label:  AppCatalog.Field.Search.Label + " " +
      Catalog.Name.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength,
    Param: AppCatalog.Field.Search.Param
  },
  IsActive: {
    Label: Catalog.IsActive.Label,
    Type: AppCatalog.Field.IsActive.SearchType,
    Input: AppCatalog.Field.IsActive.SearchInput,
    Param: AppCatalog.Field.IsActive.Param
  }
}