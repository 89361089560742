<template>
  <kst-form no-bezel class="pb-5">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
      />
    </template>
  </kst-form>
</template>

<script>
import Model from "../DbpyModel.js";

export default {
  data: () => ({
    Model: Model
  })
}
</script>