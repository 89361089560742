<template>
  <b-modal aria-modal has-modal-card trap-focus
    aria-role="dialog"
    class="kst-modal-search"
    :active="isActive"
    :class="theClass"
    @close="handleCancel"
  >
    <div class="modal-card ks-max-width-100p mb-5" :style="'width: ' + width + 'px'">
      <header class="modal-card-head">
        <div class="modal-card-title">
          <p class="pb-1">{{ theTitle }}</p>
          <p class="has-text-grey is-size-6">{{ theSubTitle }}</p>
        </div>
        <button class="delete" @click="handleCancel"/>
      </header>

      <section class="modal-card-body">
        <Search
          :search-data="searchData"
          @reset="handleSearch_Reset"
          @submit="handleSearch_Submit"
        >
          <template #default="{ handleInput }">
            <slot name="search" :handleInput="handleInput"/>
          </template>
        </Search>

        <kst-section search-result-mode select-one-help>
          <template #top-left>
            <template v-if="!isListLoading">
              <kst-button slim-size refresh-mode @click="handleRefresh"/>
            </template>
          </template>

          <kst-skeleton :active="isListLoading"/>
          <kst-failure list-mode :items="failureList"/>

          <template v-if="searchResultSlotVisible">
            <slot
              name="search-result"
              :data="data"
              :userData="userData"
            />
          </template>
        </kst-section>
      </section>

      <footer class="modal-card-foot is-justify-content-end">
        <div class="buttons">
          <kst-button close-mode @click="handleCancel"/>
          <kst-button confirm-mode @click="handleConfirm"/>
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import RouterModel from "../../../router/RouterModel.js";

import Search from "./ModalSearch_Search.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set search-data and do show when needed
 * 2. handle Reset and Load event, do loadList and handle Loaded when needed
 * 3. handle Submit event and do loadDetails
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, SnackbarMixin],
  components: {
    Search
  },
  props: {
    module: Object,
    moduleType: Object,
    multiple: {
      type: Boolean,
      default: false
    },
    searchData: Object,
    title: String,
    subTitle: String,
    width: Number
  },
  data: () => ({
    isActive: false,
    data: {},
    userData: {
      selected: null,
      checkedRows: [],
    },
    getDetailsService: null,
    getListService: null,
    // loading
    isListLoading: true,
    detailsLoading: null,
    // support debounce
    loadCounter: 0,
    // slot
    searchResultSlotVisible: false,
    // error
    failureList: null
  }),
  computed: {
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.title
        ? this.title
        : RouterModel.getNavSubTitle(this.module, this.moduleType);
    },
    theSubTitle() {
      return this.subTitle
        ? this.subTitle
        : null
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init, this.show, this.hide);
    },
    init_Data() {
      this.isActive = false;
      this.data = {};
      this.userData.selected = null;
      this.userData.checkedRows = [];
      this.getDetailsService = null;
      this.getListService = null;
      // loading
      this.isListLoading = true;
      this.detailsLoading = null;
      // support debounce
      this.loadCounter = 0;
      // slot
      this.searchResultSlotVisible = false;
      // error
      this.failureList = null;
    },

    handleRefresh() {
      this.$emit(this.$kst.Enum.Event.Load, this.loadList);
    },

    handleCancel() {
      this.userData.checkedRows = [];
      this.isActive = false;
    },
    handleConfirm() {
      if (this.multiple) {
        if (this.userData.checkedRows.length < 1) {
          this.notifyError_SelectOne();
          return;
        }

        this.$emit(this.$kst.Enum.Event.Submit, 
          this.userData.checkedRows,
          this.loadDetails
        );

        this.userData.checkedRows = [];
      } else {
        if (this.userData.selected === null) {
          this.notifyError_SelectOne();
          return;
        }
  
        this.$emit(this.$kst.Enum.Event.Submit,
          this.userData.selected,
          this.loadDetails
        );
      }
    },

    handleSearch_Reset() {
      this.$emit(this.$kst.Enum.Event.Reset, this.loadList);
    },
    handleSearch_Submit() {
      this.$emit(this.$kst.Enum.Event.Load, this.loadList);
    },

    /*** custom ***/

    loadDetails(config, successCallback) {
      this.detailsLoading = this.openLoading();
      this.runAxios(config, successCallback,
        this.loadDetails_Success,
        this.loadDetails_Fault,
        this.loadDetails_Fault
      );
    },
    loadDetails_End() {
      this.closeLoading(this.detailsLoading);
      this.detailsLoading = null;
    },
    loadDetails_Success(data, successCallback) {
      successCallback(data);
      this.loadDetails_End();
      this.isActive = false;
    },
    loadDetails_Fault(message) {
      this.openSnackbar({
        message: message,
        errorType: true
      });
      this.loadDetails_End();
    },

    loadList(config) {
      this.loadCounter++;
      this.failureList = null;
      this.isListLoading = true;
      this.userData.selected = null;
      this.searchResultSlotVisible = false;
      this.data = {};

      this.runAxios(config, this.loadCounter,
        this.loadList_Success,
        this.loadList_Fault,
        this.loadList_Fault
      );
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.data = data;
      this.searchResultSlotVisible = true;
      this.loadList_End();

      this.$emit(this.$kst.Enum.Event.Loaded, data);
    },
    loadList_Fault(message) {
      this.failureList = message;
      this.loadList_End();
    },

    show() {
      this.isActive = true;
      this.$emit(this.$kst.Enum.Event.Reset, this.loadList);
    },
    hide() {
      this.isActive = false;
    }
  }
}
</script>