<template>
    <Layout>
        <div id="stories">
            <div id="section1">
                <p>CERITA #TEMANKAMI</p>
                <div id="section1_child2">
                    <div class="section1_child2_pic" @click="scrollTo2">
                        <img class="section1_child2_pic_content" :src="testimony[1].gambar"/>
                        <span>{{ testimony[1].nama }}<br>{{ testimony[1].toko }}</span>
                    </div>
                    <div class="section1_child2_pic" @click="scrollTo1">
                        <img class="section1_child2_pic_content2" :src="testimony[0].gambar"/>
                        <span>{{ testimony[0].nama }}<br>{{ testimony[0].toko }}</span>
                    </div>
                    <div class="section1_child2_pic" @click="scrollTo3">
                        <img class="section1_child2_pic_content" :src="testimony[2].gambar"/>
                        <span>{{ testimony[2].nama }}<br>{{ testimony[2].toko }}</span>
                    </div>
                </div>
                <b-carousel id="section1_child2_carousel"
                    v-model="carousel"
                    :arrow-hover="arrowHover"
                    :interval="interval"
                    indicator-style="is-lines"
                    :indicator-inside="indicatorInside">
                    <b-carousel-item v-for="(carousel, i) in testimony" :key="i">
                        <section>
                            <div class="section1_child2_pic" @click="scrollTo2">
                                <img class="section1_child2_pic_content" :src="carousel.gambar"/>
                                <span>{{ carousel.nama }}<br>{{ carousel.toko }}</span>
                            </div>
                        </section>
                    </b-carousel-item>
                </b-carousel>
            </div>
            <div id="section2">
                <div id="section2_greenbg"/>
                <div id="testi1" class="section2_child">
                    <img :src="testimony[0].gambar" class="section2_child_img"/>
                    <div class="section2_child_child2">
                        <div class="section2_child_child2_upper">
                            <div class="section2_child_child2_profile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right">
                                <span class="section2_child_child2_name">{{ testimony[0].nama }}</span>
                                <span class="section2_child_child2_store">{{ testimony[0].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment">{{ testimony[0].komentar }}</div>
                    </div>
                    <div class="section2_child_child2_mobile">
                        <div class="section2_child_child2_upper_mobile">
                            <div class="section2_child_child2_profile_mobile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right_mobile">
                                <span class="section2_child_child2_name_mobile">{{ testimony[0].nama }}</span>
                                <span class="section2_child_child2_store_mobile">{{ testimony[0].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment_mobile">{{ testimony[0].komentar }}</div>
                    </div>
                </div>
                <div id="testi2" class="section2_child">
                    <img :src="testimony[1].gambar" class="section2_child_img"/>
                    <div class="section2_child_child2">
                        <div class="section2_child_child2_upper">
                            <div class="section2_child_child2_profile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right">
                                <span class="section2_child_child2_name">{{ testimony[1].nama }}</span>
                                <span class="section2_child_child2_store">{{ testimony[1].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment">{{ testimony[1].komentar }}</div>
                    </div>
                    <div class="section2_child_child2_mobile">
                        <div class="section2_child_child2_upper_mobile">
                            <div class="section2_child_child2_profile_mobile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right_mobile">
                                <span class="section2_child_child2_name_mobile">{{ testimony[1].nama }}</span>
                                <span class="section2_child_child2_store_mobile">{{ testimony[1].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment_mobile">{{ testimony[1].komentar }}</div>
                    </div>
                </div>
                <div id="testi3" class="section2_child">
                   <img :src="testimony[2].gambar" class="section2_child_img"/>
                    <div class="section2_child_child2">
                        <div class="section2_child_child2_upper">
                            <div class="section2_child_child2_profile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right">
                                <span class="section2_child_child2_name">{{ testimony[2].nama }}</span>
                                <span class="section2_child_child2_store">{{ testimony[2].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment">{{ testimony[2].komentar }}</div>
                    </div>
                    <div class="section2_child_child2_mobile">
                        <div class="section2_child_child2_upper_mobile">
                            <div class="section2_child_child2_profile_mobile">
                                <i class="fas fa-user-alt"></i>
                            </div>
                            <div class="section2_child_child2_upper_right_mobile">
                                <span class="section2_child_child2_name_mobile">{{ testimony[2].nama }}</span>
                                <span class="section2_child_child2_store_mobile">{{ testimony[2].toko }}</span>
                            </div>
                        </div>
                        <div class="section2_child_child2_comment_mobile">{{ testimony[2].komentar }}</div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../Layout/Layout.vue";
import img0 from "../../../assets/cerita_tokomutiarabali.png"
import img1 from "../../../assets/cerita_tokotelagawarna.png"
import img2 from "../../../assets/cerita_tokohaypeng.png"
export default {
  components: {
    Layout
  },
  data: () => ({
    carousel: 0,
    arrowHover: false,
    interval: 3000,
    indicatorInside: false,
    testimony: [{
        nama: "Jerry",
        toko: "Toko Mutiara Bali",
        komentar: "Dirigo, sesuai arti namanya, telah memberi kemudahan operasional bagi manajemen kami. User Interface Dirigo yang intuitif dan mudah dipelajari sangat membantu dalam pencatatan transaksi sehari-hari. Didukung dengan tim yang solid dan selalu siap melayani, aplikasi web Dirigo tidak pernah gagal dalam menyediakan apapun yang bisnis kami butuhkan. Terima kasih tim Dirigo!",
        gambar: img0
    },{
        nama: "Marianto Tedy",
        toko: "Toko Telaga Warna",
        komentar: "Dirigo membuat pengelolaan toko baik dari segi pembelian, penjualan, daftar stok dan pembukuan menjadi jauh lebih mudah rapih aman dan dapat diakses kapan dan dimana saja.",
        gambar: img1
    },{
        nama: "Mili",
        toko: "Toko Hay Peng",
        komentar: "Sebelum menggunakan Dirigo, toko kami melakukan sistem pencatatan manual. Untuk mencari data barang membutuhkan waktu, belum lagi kalau kertasnya hilang. Dengan Dirigo, data toko bisa diakses kapan saja dan dari mana saja dengan waktu yang sangat singkat. Perhitungan stok juga lebih mudah dan akurat. Sistem Dirigo simpel sehingga mudah dimengerti dan digunakan, cocok untuk toko tradisional yang baru mulai berkembang ke arah digital. Thank you Dirigo for making our lives easier.",
        gambar: img2
    }],
  }),
  methods: {
    scrollTo1 () {
        var element = document.getElementById("testi1");
        element.scrollIntoView({behavior: "smooth", block: "center"});
    },
    scrollTo2 () {
        var element = document.getElementById("testi2");
        element.scrollIntoView({behavior: "smooth", block: "center"});
    },
    scrollTo3 () {
        var element = document.getElementById("testi3");
        element.scrollIntoView({behavior: "smooth", block: "center"});
    }
  }
}
</script>
<style scoped lang="css" src="./_stories.css"/>