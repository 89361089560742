import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import DebtPayEnum from "../DebtPay/DbpyEnum.js";
import RcvItemCatalog from "./RcvItemCatalog.js";

export default {
  getItemInfo(details, item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    } 
    else {
      // SKU
      infoList.push(RcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RcvItemCatalog)
      );
    }

    // Purchase
    if (item.PurchasingItemID && details.PurchasingIsActive) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Purchase.FullName
      );

      // PurchasingItemQuantity
      infoList.push(RcvItemCatalog.PurchasingItemQuantity.Label + ": " +
        App.Value.getValue("PurchasingItemQuantity", item, RcvItemCatalog)
      );
    }

    return infoList;
  },

  updateDebtData(debtData, details) {
    debtData.ReceiveDebtTotal = details.DebtTotal;
    debtData.ReceiveDueDate = details.DueDate;
    debtData.ReceiveTotal = details.Total;
    debtData.DebtPayTotal = 0;

    for (const debtPay of debtData.DebtPayList) {
      if (debtPay.StatusID === DebtPayEnum.StatusID.Active.ID) {
        debtData.DebtPayTotal += debtPay.ReceivePaymentValue;
      }
    }
  }
}