import AppModule from "../../services/App/AppModule.js";

export default {
  InvoiceClientSourceID: {
    MasterClient: {
      ID: 3,
      Label: "Master " + AppModule.Client.FullName
    },
    MasterClient_FreeText: {
      ID: 4,
      Label: "Master " + AppModule.Client.FullName + " atau Ketik Manual"
    }
  },
  ProfitLossRptTypeID: {
    AccountingBased: {
      ID: 2,
      Label: "Accounting"
    },
    CashBased: {
      ID: 1,
      Label: "Arus Kas"
    }
  },
  ReceiveVendorSourceID: {
    MasterVendor: {
      ID: 5,
      Label: "Master " + AppModule.Vendor.FullName
    },
    MasterVendor_FreeText: {
      ID: 6,
      Label: "Master " + AppModule.Vendor.FullName + " atau Ketik Manual"
    }
  }
}