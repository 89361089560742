import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import InvD from "./Details/InvD.vue";
import InvED from "./EditDate/InvED.vue";
import InvE from "./Edit/InvE.vue";
import InvExD from "./ExportDetails/InvExD.vue";
import InvExL from "./ExportList/InvExL.vue";
import InvL from "./List/InvL.vue";
import InvN from "./New/InvN.vue";
import InvPD from "./PrintDetails/InvPD.vue";
import InvPF from "./PrintFaktur/InvPF.vue";
import InvPL from "./PrintList/InvPL.vue";
import InvPT from "./PrintThermal/InvPT.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Invoice, AppModuleType.Edit),
    EditDate: RouterModel.getPath(AppModule.Invoice, AppModuleType.EditDate),
    ExportDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Invoice, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Invoice, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintDetails),
    PrintFaktur: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintFaktur),
    PrintList: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintList),
    PrintThermal: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintThermal)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.Details),
        component: InvD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.EditDate),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.EditDate),
        component: InvED,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.EditDate),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_EditDate,
          moduletype: AppModuleType.EditDate,
          cancel: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.Edit),
        component: InvE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.ExportDetails),
        component: InvExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.ExportList),
        component: InvExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.List),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.List),
        component: InvL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Invoice),
            moduleattr: AppModuleAttr.Invoice_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Invoice),
            moduleattr: AppModuleAttr.Invoice_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Invoice),
            moduleattr: AppModuleAttr.Invoice_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.New),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.New),
        component: InvN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Invoice, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintDetails),
        component: InvPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintFaktur),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintFaktur),
        component: InvPF,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintFaktur),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_PrintFaktur,
          moduletype: AppModuleType.PrintFaktur
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintList),
        component: InvPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Invoice, AppModuleType.PrintThermal),
        name: RouterModel.getName(AppModule.Invoice, AppModuleType.PrintThermal),
        component: InvPT,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Invoice, AppModuleType.PrintThermal),
          module: RouterModel.getModule(AppModule.Invoice),
          moduleattr: AppModuleAttr.Invoice_Act_PrintThermal,
          moduletype: AppModuleType.PrintThermal
        }
      }
    ];
  }
}