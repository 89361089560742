import App from "../../services/App/App.js";
import PaytEnum from "./PaytEnum.js";

export default {
  Search: {
    Fields: ["Name"]
  },

  PaymentTypeID: PaytEnum.PaymentTypeID,

  /*** method ***/

  createOptions(dataList, optionAllText, optionNonText, params,
    paymentTypeEnum
  ) {
    const rowId = "Name";
    const rowLabel = "Label";

    const newParams = App.Data.normalizeObject(params);
    // default: use all rows
    const withCreditNote = params === undefined || params === null
      ? true : newParams.WithCreditNote;

    let validList = [], isValid;
    for (let row of dataList) {
      isValid = false;

      // if: filter specific PaymentTypeID
      if (row.PaymentTypeID === paymentTypeEnum.CreditNote.ID) {
        if (withCreditNote) {
          isValid = true;
        }
      }
      else {
        isValid = true;
      }

      if (isValid) {
        validList.push(row);
        row[rowLabel] = row[rowId];
      }
    }

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    if (optionNonText) {
      listOptions.nonActive = true;
      listOptions.nonText = optionNonText;
    }

    // create: select items
    const selectItems = App.Search.createList(validList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  getDefault(items, name) {
    if (name === undefined || name === null) {
      return items.length > 0 ? items[0].Name : null;
    }

    const defaultPayment = App.Array.searchItem(items, "Name", name);

    if (defaultPayment !== null) {
      return defaultPayment.Name;
    }
    else {
      return items.length > 0 ? items[0].Name : null;
    }
  },
  populateList(rows) {
    let resultList = [];

    for (const row of rows) {
      resultList.push({
        PaymentTypeID: row.ID,
        Name: row.Name
      });
    }

    return resultList;
  },
}