import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  Module: AppModule.Vendor,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    PrintList: AppModuleType.PrintList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  VendorAddress: {
    Module: AppModule.VendorAddress,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Vendor_Act_Details,
    Act_Edit: AppModuleAttr.Vendor_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Vendor_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Vendor_Act_ExportList,
    Act_PrintList: AppModuleAttr.Vendor_Act_PrintList,
    Act_List: AppModuleAttr.Vendor_Act_List,
    Act_New: AppModuleAttr.Vendor_Act_New,
    Tab_VendorItem: AppModuleAttr.Vendor_Tab_VendorItem,
    // related
    Debt_Act_List: AppModuleAttr.Debt_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    ListFields: ["Name", "Alias", "Mobile", "Phone", "Email"],
    ListParams: ["Search"],
    SearchListFields: ["Name", "Alias", "Mobile", "Phone", "Email"],
    SearchListAddressFields: ["Name", "Alias", "Address", "ContactMobile",
      "ContactPhone", "ContactEmail"
    ]
  },

  createDetails() {
    return {
      ID: null,
      // user input
      Name: "",
      Alias: "",
      DaysDueDate: 0,
      Mobile: "",
      Phone: "",
      Email: "",
      Comment: ""
    };
  },
  createAddress() {
    return {
      ID: null,
      // user input
      Name: "",
      Address: "",
      ContactName: "",
      ContactMobile: "",
      ContactPhone: "",
      ContactEmail: "",
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },
  createVendorItem(details){
    return {
      ID: null,
      // user input
      VendorID: details.ID,
      ItemID: null,
      PackagingName: "",
      Qty: "",
      BuyPrice: "",
      // defined by system
      PackagingValue: 1,
      // computed field
      Packaging: "",
      // support
      Name: "",
      Barcode: "",
      ImageSrc: "",
      SKU: "",
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      Alias: userData.Alias,
      DaysDueDate: App.Out.getInteger(userData.DaysDueDate),
      Name: userData.Name,
      Mobile: userData.Mobile,
      Phone: userData.Phone,
      Email: userData.Email,
      Comment: userData.Comment
    };
  },
  populateAddress(userAddressList) {
    let resultItems = [];

    for (const userAddress of userAddressList) {
      resultItems.push({
        ID: userAddress.ID,
        Name: userAddress.Name,
        Address: userAddress.Address,
        ContactName: userAddress.ContactName,
        ContactMobile: userAddress.ContactMobile,
        ContactPhone: userAddress.ContactPhone,
        ContactEmail: userAddress.ContactEmail
      });
    }

    return resultItems;
  },
  populateVendorItems(userVendorItemList) {
    let resultItems = [];

    for (const vendorItem of userVendorItemList) {
      resultItems.push({
        ID: vendorItem.ID,
        Name: vendorItem.Name,
        VendorID: vendorItem.VendorID,
        ItemID: vendorItem.ItemID,
        PackagingName: vendorItem.PackagingName,
        PackagingValue: vendorItem.PackagingValue,
        Qty: App.Out.getInteger(vendorItem.Qty),
        BuyPrice: App.Out.getInteger(vendorItem.BuyPrice),
      });
    }

    return resultItems;
  },

  updateVendorItem(userVendorItem) {
    userVendorItem.Packaging = App.Data.getPackaging(
      userVendorItem.PackagingName, userVendorItem.PackagingValue
    );
  },
  updateVendorItemByPackaging(userVendorItem) {
    if (userVendorItem.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        userVendorItem.PackagingOptions.rows, userVendorItem.PackagingName
      );

      if (packagingData) {
        // defined by system
        userVendorItem.PackagingValue = packagingData.Qty;
      }
    }
  },
  updateVendorItemList(vendorItemList) {
    for (const vendorItem of vendorItemList) {
      vendorItem.Packaging = App.Data.getPackaging(
        vendorItem.PackagingName, vendorItem.PackagingValue
      );
    }
  },
}