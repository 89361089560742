<template>
  <kst-page-print-list pageFooter
    v-slot="{ title }"
    :catalog="RcvSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="block" :dataList="dataList" :access="access"/>
  </kst-page-print-list>
</template>

<script>
import Model from "../RcvModel.js";
import RcvSearchCatalog from "../RcvSearchCatalog.js";
import Services from "../../../services/Api/ReceiveServices.js";

import List from "./RcvPL_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    Model: Model,
    RcvSearchCatalog: RcvSearchCatalog,
    access: {},
    companyData: {},
    dataList: [],
    searchData: {
      Search: "",
      Date: null,
      VendorID: null,
      VendorName: "",
      WarehouseID: null
    },
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintListData(options);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.dataList = data.List;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.companyData = {};
      this.dataList = [];
    },
    
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.VendorID = null;
      this.searchData.VendorName = "";
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>