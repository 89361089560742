// modules
import AccessRouter from "../components/Access/AcsRouter.js";
import AutoNumberRouter from "../components/AutoNumber/AnumRouter.js";
import ClientRouter from "../components/Client/CliRouter.js";
import CompanyRouter from "../components/Company/CpRouter.js";
import DailyReportRouter from "../components/DailyReport/DaiRouter.js";
import DashboardRouter from "../components/Dashboard/DashRouter.js";
import DebtRouter from "../components/Debt/DebtRouter.js";
import DebtCardRouter from "../components/DebtCard/DbcdRouter.js";
import DebtPayRouter from "../components/DebtPay/DbpyRouter.js";
import DeliveryRouter from "../components/Delivery/DlvRouter.js";
import ExpenseRouter from "../components/Expense/ExpRouter.js";
import ExpenseCategoryRouter from "../components/ExpenseCategory/ExpcRouter.js";
import HppAdjRouter from "../components/HppAdj/HpadRouter.js";
import InvoiceRouter from "../components/Invoice/InvRouter.js";
import ItemRouter from "../components/Item/ItemRouter.js";
import LowstkRouter from "../components/LowStock/LowstkRouter.js";
import OmzetRouter from "../components/Omzet/OmzRouter.js";
import PaymentTypeRouter from "../components/PaymentType/PaytRouter.js";
import PosRouter from "../components/Pos/PosRouter.js";
import ProfitLossRouter from "../components/ProfitLoss/PlosRouter.js";
import PurchaseRouter from "../components/Purchase/PurRouter.js";
import RecRouter from "../components/Rec/RecRouter.js";
import RecCardRouter from "../components/RecCard/RccdRouter.js";
import RecPayRouter from "../components/RecPay/RcpyRouter.js";
import ReceiveRouter from "../components/Receive/RcvRouter.js";
import ReportStockAdj from "../components/Report/StockAdj/StockAdjRouter.js";
import RptTransactionRouter from "../components/RptTransaction/TrxRouter.js";
import RtnInvoiceRouter from "../components/RtnInvoice/RinvRouter.js";
import RtnReceiveRouter from "../components/RtnReceive/RrcvRouter.js";
import SalesRouter from "../components/Sales/SlsRouter.js";
import SalesRecapRouter from "../components/SalesRecap/SlrcRouter.js";
import SpecialPriceRouter from "../components/SpecialPrice/SpRouter.js";
import SpecialPriceAdjRouter from "../components/SpecialPriceAdj/SpadRouter.js";
import StockRouter from "../components/Stock/StkRouter.js";
import StockAvailabilityRouter from "../components/StockAvailability/StavRouter.js";
import StockCardRouter from "../components/StockCard/StcdRouter.js";
import StockClosingRouter from "../components/StockClosing/StclRouter.js";
import StockConvRouter from "../components/StockConv/StcvRouter.js";
import StockOpnameRouter from "../components/StockOpname/StopRouter.js";
import StockReductionRouter from "../components/StockReduction/StrdRouter.js";
import StockTrfRouter from "../components/StockTrf/SttrRouter.js";
import UserRouter from "../components/User/UserRouter.js";
import VendorRouter from "../components/Vendor/VenRouter.js";
import VersionRouter from "../components/Version/VersionRouter.js";
import WarehouseRouter from "../components/Warehouse/WhsRouter.js";

// support
import SecurityRouter from "../components/Security/SecurityRouter.js";
import WebsiteRouter from "../components/Website/WebsiteRouter.js";

// public modules
import SalesRouterPublic from "../componentsPublic/Sales/SalesRouter.js";

// temporary
import TestRouter from "../components/Test/TestRouter.js";

export default {
  getRoutes() {
    return [
      ...ClientRouter.getRootRoutes(),
      ...DailyReportRouter.getRootRoutes(),
      ...DebtRouter.getRootRoutes(),
      ...DebtCardRouter.getRootRoutes(),
      ...DebtPayRouter.getRootRoutes(),
      ...DeliveryRouter.getRootRoutes(),
      ...ExpenseRouter.getRootRoutes(),
      ...HppAdjRouter.getRootRoutes(),
      ...InvoiceRouter.getRootRoutes(),
      ...ItemRouter.getRootRoutes(),
      ...OmzetRouter.getRootRoutes(),
      ...PosRouter.getRootRoutes(),
      ...ProfitLossRouter.getRootRoutes(),
      ...PurchaseRouter.getRootRoutes(),
      ...RecRouter.getRootRoutes(),
      ...RecCardRouter.getRootRoutes(),
      ...RecPayRouter.getRootRoutes(),
      ...ReceiveRouter.getRootRoutes(),
      ...RptTransactionRouter.getRootRoutes(),
      ...RtnInvoiceRouter.getRootRoutes(),
      ...RtnReceiveRouter.getRootRoutes(),
      ...SalesRouter.getRootRoutes(),
      ...SalesRecapRouter.getRootRoutes(),
      ...SpecialPriceAdjRouter.getRootRoutes(),
      ...StockRouter.getRootRoutes(),
      ...StockCardRouter.getRootRoutes(),
      ...StockClosingRouter.getRootRoutes(),
      ...StockConvRouter.getRootRoutes(),
      ...StockOpnameRouter.getRootRoutes(),
      ...StockReductionRouter.getRootRoutes(),
      ...StockTrfRouter.getRootRoutes(),
      ...VendorRouter.getRootRoutes(),
      ...WarehouseRouter.getRootRoutes(),
      // support
      ...SecurityRouter.getRootRoutes(),
      ...WebsiteRouter.getRootRoutes(),
      // public modules
      ...SalesRouterPublic.getRootRoutes(),
    ];
  },
  getRoutesChildren() {
    return [
      ...AccessRouter.getRoutes(),
      ...AutoNumberRouter.getRoutes(),
      ...ClientRouter.getRoutes(),
      ...CompanyRouter.getRoutes(),
      ...DailyReportRouter.getRoutes(),
      ...DashboardRouter.getRoutes(),
      ...DebtRouter.getRoutes(),
      ...DebtCardRouter.getRoutes(),
      ...DebtPayRouter.getRoutes(),
      ...DeliveryRouter.getRoutes(),
      ...ExpenseRouter.getRoutes(),
      ...ExpenseCategoryRouter.getRoutes(),
      ...HppAdjRouter.getRoutes(),
      ...InvoiceRouter.getRoutes(),
      ...ItemRouter.getRoutes(),
      ...LowstkRouter.getRoutes(),
      ...OmzetRouter.getRoutes(),
      ...PaymentTypeRouter.getRoutes(),
      ...ProfitLossRouter.getRoutes(),
      ...PurchaseRouter.getRoutes(),
      ...RecRouter.getRoutes(),
      ...RecCardRouter.getRoutes(),
      ...RecPayRouter.getRoutes(),
      ...ReceiveRouter.getRoutes(),
      ...ReportStockAdj.getRoutes(),
      ...RptTransactionRouter.getRoutes(),
      ...RtnInvoiceRouter.getRoutes(),
      ...RtnReceiveRouter.getRoutes(),
      ...SalesRouter.getRoutes(),
      ...SalesRecapRouter.getRoutes(),
      ...SpecialPriceRouter.getRoutes(),
      ...SpecialPriceAdjRouter.getRoutes(),
      ...StockRouter.getRoutes(),
      ...StockAvailabilityRouter.getRoutes(),
      ...StockCardRouter.getRoutes(),
      ...StockClosingRouter.getRoutes(),
      ...StockConvRouter.getRoutes(),
      ...StockOpnameRouter.getRoutes(),
      ...StockReductionRouter.getRoutes(),
      ...StockTrfRouter.getRoutes(),
      ...UserRouter.getRoutes(),
      ...VendorRouter.getRoutes(),
      ...VersionRouter.getRoutes(),
      ...WarehouseRouter.getRoutes(),
      // public modules
      ...SalesRouterPublic.getRoutes(),
      // temporary
      ...TestRouter.getRoutes(),
    ];
  }
}