<template>
  <div class="block">
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-output help-off field="Name" :data="details" :catalog="Catalog"/>
    <kst-output field="Email" :data="details" :catalog="Catalog"/>
    <kst-output field="Phone" :data="details" :catalog="Catalog"/>

    <template v-if="details.Access.AccessIsOwner">
      <kst-output field="AccessName" :data="details.Access" :catalog="Catalog"/>
    </template>
    <template v-else>
      <kst-output field="AccessID" :data="details.Access" :catalog="Catalog"/>
    </template>

    <kst-output field="DefaultWarehouseID" :data="details.Access" :catalog="Catalog"/>
    <kst-output field="DefaultModuleName" :data="details.Access" :catalog="Catalog"/>
    <kst-output field="IsActive" :data="details" :catalog="Catalog"/>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
  </div>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Info from "../UserInfo.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
  }),
  computed: {
    detailsWarning() {
      return this.details.IsVerified ? [] : [Info.Message.Details_IsEmailVerified];
    }
  }
}
</script>