<template>
  <div class="block">
    <div class="columns">
      <div class="column is-half">
        <kst-section :title="Catalog.RecTotal.Label">
          <template #top-right>
            <kst-title>{{ recTotal }}</kst-title>
          </template>
          <kst-divider columns-mode/>

          <div class="columns is-mobile">
            <div class="column is-narrow">
              {{ Catalog.CreditAmount.Label }}
            </div>
            <div class="column">
              <div class="has-text-right">
                <kst-value field="CreditAmount" :data="data" :catalog="Catalog"/>
              </div>
            </div>
          </div>

          <div class="columns is-mobile">
            <div class="column is-narrow">
              {{ Catalog.OverDueDate.Label }}
            </div>
            <div class="column">
              <div class="has-text-right">
                <kst-value field="OverDueDate" :data="data" :catalog="Catalog"/>
              </div>
            </div>
          </div>
        </kst-section>
      </div>
    </div>

    <template v-if="receivablesList !== null">
      <RecRelatedList :items="receivablesList"/>
    </template>
  </div>
</template>

<script>
import Catalog from "../CliCatalog.js";

import RecRelatedList from "../../Rec/RelatedList/RecRL.vue";

export default {
  components: {
    RecRelatedList
  },
  props: {
    data: Object,
    receivablesList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    recTotal() {
      return this.$kst.Value.getValue("RecTotal", this.data, Catalog);
    }
  }
}
</script>