import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StclItemCatalog from "./StclItemCatalog.js";

export default {
  // required in model
  Module: AppModule.StockClosing,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  StclItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.StockClosing_Act_Details,
    Act_ExportDetails: AppModuleAttr.StockClosing_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.StockClosing_Act_ExportList,
    Act_List: AppModuleAttr.StockClosing_Act_List,
    Act_New: AppModuleAttr.StockClosing_Act_New,
    Act_PrintDetails: AppModuleAttr.StockClosing_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.StockClosing_Act_PrintList
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    ListFields: ["ClosingNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: null,
      ClosingNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // by system
      Date: App.In.getDateToday(),
      Total: 0,
      SpecialPriceCount: 0
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      DispatchName: "",
      Qty: "",
      SpecialPriceID: null,
      // by system
      SKU: "",
      DispatchSellPrice: 0,
      PackagingName: "",
      PackagingValue: 1,
      SellPrice: 0,
      SpecialPriceName: "",
      SpecialPriceDiscPercent: 0,
      SpecialPriceOptions: {},
      Stock: 0,
      // computed
      Packaging: "",
      TotalQty: 0,
      Total: 0,
      // error
      Errors: [],
      ErrorsColl: {},
      // validation
      CustomValidations: {}
    }
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    userData.SpecialPriceCount = specialPriceCount;
  },

  setItemsByStockList(userItems, stockList, specialPriceId, specialPriceOptions) {
    let userItem, specialPriceDiscAmount;
    App.Array.truncate(userItems);

    const specialPrice = specialPriceId === null
      ? null
      : App.Array.searchItem(specialPriceOptions.rows, "ID", specialPriceId);
    const defaultSpecialPriceId = specialPriceId === null
      ? specialPriceOptions.rows[0].ID
      : specialPriceId;

    for (const stock of stockList) {
      specialPriceDiscAmount = specialPriceId === null
        ? 0
        : App.Data.getDiscPercent_Value(
            stock.SellPrice, specialPrice.DiscPercent
          );

      userItem = this.createItem();
      // user input
      userItem.DispatchID = stock.ItemID;
      userItem.DispatchName = stock.Name;
      userItem.Qty = App.In.getInteger(stock.Quantity);
      userItem.SpecialPriceID = defaultSpecialPriceId;
      // by system
      userItem.SKU = stock.SKU;
      userItem.DispatchSellPrice = stock.SellPrice;
      userItem.PackagingName = stock.PackagingName;
      userItem.SellPrice = stock.SellPrice - specialPriceDiscAmount;
      userItem.SpecialPriceName = specialPrice === null
        ? "" : specialPrice.Name;
      userItem.SpecialPriceDiscPercent = specialPrice === null
        ? 0 : specialPrice.DiscPercent;
      userItem.SpecialPriceOptions = specialPriceOptions;
      userItem.Stock = stock.Quantity;
      // validation
      userItem.CustomValidations = {
        [App.Vee.Rule.MaxValue]: [
          stock.Quantity,
          StclItemCatalog.Stock.Label
        ]
      };

      this.updateItem(userItem);
      userItems.push(userItem);
    }
  },

  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(StclItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, StclItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(StclItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", userItem, StclItemCatalog)
    );

    // Stock
    infoList.push(StclItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", userItem, StclItemCatalog) + " " +
      userItem.PackagingName
    );

    return infoList;
  },

  updateDetails(userData, userItems) {
    userData.Total = 0;

    for (const userItem of userItems) {
      userData.Total += userItem.Total;
    }
  },

  updateItemsBySpecialPrice(userItems, specialPriceId) {
    let specialPrice, specialPriceDiscAmount;

    for (const userItem of userItems) {
      if (specialPriceId === null) {
        userItem.SellPrice = userItem.DispatchSellPrice;
        userItem.SpecialPriceID = userItem.SpecialPriceOptions.rows[0].ID;
        userItem.SpecialPriceName = "";
        userItem.SpecialPriceDiscPercent = 0;
      }
      else {
        specialPrice = App.Array.searchItem(
          userItem.SpecialPriceOptions.rows, "ID", specialPriceId
        );
        specialPriceDiscAmount = userItem.SpecialPriceID === null
          ? 0
          : App.Data.getDiscPercent_Value(
              userItem.DispatchSellPrice, specialPrice.DiscPercent
            );

        if (specialPrice) {
          userItem.SellPrice = userItem.DispatchSellPrice -
            specialPriceDiscAmount;
          userItem.SpecialPriceID = specialPrice.ID;
          userItem.SpecialPriceName = specialPrice.Name;
          userItem.SpecialPriceDiscPercent = specialPrice.DiscPercent;
        }
      }

      this.updateItem(userItem);
    }
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.TotalQty = App.Data.getTotalQty(
      userItem.Qty, userItem.PackagingValue
    );
    userItem.Total = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
  },
  updateItemBySpecialPrice(userItem) {
    const specialPriceId = App.Search.getValue(userItem.SpecialPriceID, null);

    if (specialPriceId === null) {
      userItem.SellPrice = userItem.DispatchSellPrice;
      userItem.SpecialPriceName = "";
      userItem.SpecialPriceDiscPercent = 0;
    }
    else {
      const specialPrice = App.Array.searchItem(
        userItem.SpecialPriceOptions.rows, "ID", specialPriceId
      );
      const specialPriceDiscAmount = specialPriceId === null
        ? 0
        : App.Data.getDiscPercent_Value(
          userItem.DispatchSellPrice, specialPrice.DiscPercent
        );

      if (specialPrice) {
        userItem.SellPrice = userItem.DispatchSellPrice -
          specialPriceDiscAmount;
        userItem.SpecialPriceName = specialPrice.Name;
        userItem.SpecialPriceDiscPercent = specialPrice.DiscPercent;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      ClosingNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ClosingNumber),
      Date: App.Out.getDateString(userData.Date),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateItems(userItems) {
    let resultItems = [], specialPriceId;

    for (const userItem of userItems) {
      specialPriceId = App.Out.getInteger(
        App.Search.getValue(userItem.SpecialPriceID)
      );

      resultItems.push({
        DispatchID: userItem.DispatchID,
        DispatchName: App.Out.getString(userItem.DispatchName),
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        Qty: App.Out.getInteger(userItem.Qty),
        SellPrice: App.Out.getInteger(userItem.SellPrice),
        DispatchSellPrice: userItem.DispatchSellPrice,
        SpecialPriceID: specialPriceId,
        SpecialPriceName: specialPriceId === null
          ? null : userItem.SpecialPriceName,
        SpecialPriceDiscPercent: specialPriceId === null
          ? null : userItem.SpecialPriceDiscPercent
      });
    }

    return resultItems;
  }
}