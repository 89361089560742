import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.rec;

export default {
  getExportList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getRelatedClientList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client/list" + urlParams
    };
  }
}