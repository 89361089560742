<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Details :details="salesData"/>
    <Delivery :details="salesData"/>
    <Items :items="salesData.Items"/>
    <Total :details="salesData"/>
  </kst-section>
</template>

<script>
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";

import Delivery from "./SlsDL_Sales_Delivery.vue";
import Details from "./SlsDL_Sales_Details.vue";
import Items from "./SlsDL_Sales_Items.vue";
import Total from "./SlsDL_Sales_Total.vue";

export default {
  components: {
    Delivery,
    Details,
    Items,
    Total
  },
  props: {
    salesData: Object
  },
  computed: {
    sectionHelp() {
      return Model.Delete.getSalesSectionHelp(this.salesData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>