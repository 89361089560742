<template>
  <kst-section list-mode :title="Info.Section.PackagingList.Title">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Model.ItemPackaging.Module.FullName"
        :moduleType="Model.ItemPackaging.ModuleType.New"
        @click="handleNew"
      />
    </template>

    <kst-warning class="mb-4" :items="packagingWarning"/>

    <kst-input
      field="DefaultPackagingID" :data="userData" :catalog="Catalog"
      :options="defaultPackagingOptions"
    />

    <List
      :userData="userData"
      :userPackagingList="userPackagingList"
    />

    <kst-errors class="mt-4" :items="userData.PackagingsErrors"/>
  </kst-section>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

import List from "./ItemE_PackagingList_List.vue";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    userPackagingList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  computed: {
    defaultPackagingOptions() {
      return Model.createDefaultPackagingOptions(this.userPackagingList);
    },
    packagingWarning() {
      return [
        Info.Message.Edit_Packaging_Default,
        Info.Message.Edit_Packaging_DeleteFirst,
        Info.Message.Edit_Packaging_Fields
      ];
    }
  },
  methods: {
    handleNew() {
      this.userPackagingList.push(
        Model.createPackaging(this.userData)
      );
    }
  }
}
</script>