<template>
  <kst-section :title="Info.Section.Scanner.Title">
    <kst-load-data @init="handleLoad_Init"/>

    <ItemSearch
      :items="barcodeList"
      @init="handleItem_Init"
      @submit="handleItem_Submit"
    />

    <div class="columns">
      <div class="column">
        <div class="columns">
          <div class="column is-3">
            <kst-input floating-label ref="txtBarcode"
              field="Barcode" :data="searchData" :catalog="SpadSearchCatalog"
              @submit="handleEnterBarcode"
            />
          </div>
          <div class="column is-narrow">
            <kst-input floating-label ref="txtQty" :width="50"
              field="Qty" :data="searchData" :catalog="SpadSearchCatalog"
              @submit="handleEnterQty"
            />
          </div>
          <div class="column">
            <kst-output floating-label
              field="ItemName" :data="searchData" :catalog="SpadSearchCatalog"
            >
              <template #right>
                <kst-output addons floating-label
                  class="ks-col-sku"
                  field="SKU" :data="searchData" :catalog="SpadSearchCatalog"
                />
              </template>
            </kst-output>
          </div>
        </div>
      </div>
    </div>
  </kst-section>
</template>

<script>
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Info from "../SpadInfo.js";
import SpadSearchCatalog from "../SpadScannerCatalog.js";
import Services from "../../../services/Api/SpecialPriceAdjServices.js";

import ItemSearch from "../../Item/SearchListBarcode/ItemScLBarcode.vue";

export default {
  mixins: [SnackbarMixin],
  components: {
    ItemSearch
  },
  props: {
    itemList: Array,
    userData: Object
  },
  data: () => ({
    Info: Info,
    SpadSearchCatalog: SpadSearchCatalog,
    searchData: {
      Barcode: "",
      Qty: 1,
      // by system
      ItemID: null,
      ItemName: null,
      SKU: null
    },
    barcodeList: [],
    // func
    loadData: null,
    showItemFunc: null
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  methods: {
    /*** by load data ***/

    handleLoad_Init(loadData) {
      this.loadData = loadData;
    },

    /*** by item modal ***/

    handleItem_Init(show) {
      this.showItemFunc = show;
    },
    handleItem_Submit(itemData) {
      this.searchData.ItemID = itemData.ID;
      this.searchData.ItemName = itemData.Name;
      this.searchData.SKU = itemData.SKU;

      this.setFocusQuantity();
    },

    /*** custom ***/

    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F1:
          this.setFocusBarcode();
          event.preventDefault();
          break;
        case this.$kst.Key.Key.F2:
          this.setFocusQuantity();
          event.preventDefault();
          break;
      }
    },
    setFocusQuantity() {
      this.$refs.txtQty.focus();
    },
    setFocusBarcode() {
      this.$refs.txtBarcode.focus();
    },

    clearSearch() {
      this.searchData.Barcode = "";
      this.searchData.Qty = 1;
      this.clearSearchItem();
    },
    clearSearchItem() {
      this.searchData.ItemID = null;
      this.searchData.ItemName = null;
      this.searchData.SKU = null;
    },

    handleEnterBarcode() {
      this.clearSearchItem();
      const items = this.handleEnterBarcode_getItems();

      if (items === null) {
        return;
      }

      if (items.length === 1) {
        const item = items[0];
        this.searchData.ItemID = item.ID;
        this.searchData.ItemName = item.Name;
        this.searchData.SKU = item.SKU;

        this.setFocusQuantity();
      }
      else {
        this.barcodeList = items;
        this.showItemFunc();
      }
    },
    handleEnterBarcode_getItems() {
      const barcode = this.$kst.Out.getString(this.searchData.Barcode);

      if (barcode === null) {
        this.notifyFormError(false, Info.Section.Scanner.Error.BarcodeRequired);
        return null;
      }

      const items = this.$kst.Array.searchItem(
        this.itemList, "Barcode", barcode,
        { insensitive: true, all_insequence: true }
      );

      if (items.length === 0) {
        this.notifyFormError(false, Info.Section.Scanner.Error.BarcodeInvalid);
        return null;
      }

      return items;
    },

    handleEnterQty() {
      if (this.searchData.Qty < 1) {
        this.notifyFormError(false, Info.Section.Scanner.Error.QtyInvalid);
        return;
      }

      if (!this.searchData.ItemID === null) {
        this.setFocusBarcode();
        return;
      }

      this.loadItem(this.searchData.ItemID);
    },

    loadItem(itemId) {
      const config = Services.getRelatedItemDetails(
        itemId, this.userData.WarehouseID
      );
      this.loadData(config, this.loadItem_Success, true);
    },
    loadItem_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit,
        data.ItemDetails, this.searchData.Qty, data.StockDetails
      );
      this.clearSearch();
      this.setFocusBarcode();
    }
  }
}
</script>