<template>
  <kst-section list-mode :title="sectionTitle">
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="Name"
        :label="Catalog.Name.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-lifetime"
        field="Lifetime"
        :label="Catalog.Lifetime.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="PriceBruto"
        :label="Catalog.PriceBruto.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="DiscValue"
        :label="Catalog.DiscValue.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-status"
        field="IsAutoRenewal"
        :label="Catalog.IsAutoRenewal.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>

      <b-table-column centered
        v-slot="{ column, row }"
        field="NextRenewal"
        :label="Catalog.NextRenewal.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="Catalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Catalog from "../CpModuleCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Module.Title;
    }
  }
}
</script>