import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule";
import InputType from "../../services/Input/InputType.js";
import OutputType from "../../services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  HppTotalNetto: {
    Label: AppCatalog.Field.Hpp.Label + " Penjualan",
    Type: AppCatalog.Field.Hpp.Type,
    Output: AppCatalog.Field.Hpp.Output,
    ReadOnly: true
  },
  Profit: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Profit.Label,
    Type: AppCatalog.Field.Profit.Type,
    Output: AppCatalog.Field.Profit.Output,
    ReadOnly: AppCatalog.Field.Profit.ReadOnly
  },
  TotalBruto: {
    Label: "Penjualan",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  },
  TotalDisc: {
    Label: AppCatalog.Field.Disc.Label + " Penjualan",
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  },
  TotalReturn: {
    Label: AppModule.RtnInvoice.FullName,
    Type: InputType.Decimal,
    Output: OutputType.Decimal.Accounting2D,
    ReadOnly: true
  }
}