<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="1000"
    @init="handleModal_Init"
    @load="handleModal_Load"
    @loaded="handleModal_Loaded"
    @reset="handleModal_Reset"
    @submit="handleModal_Submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :access="access"
        :items="data.StockList"
        :userData="userData"
        :newItem="newItem"
        :sellPrice="sellPrice"
        :hppPerPcs="hppPerPcs"
        :weight="weight"
        @newitem="handleList_NewItem"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Model from "../StkModel.js";

import List from "./StkScLDefault_List.vue";
import Search from "./StkScLDefault_Search.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Loaded when needed
 * 3. handle Submit event
 */
export default {
  mixins: [SnackbarMixin],
  components: {
    List,
    Search
  },
  props: {
    // button visibility
    newItem: Boolean,
    // field visibility (order by appearance)
    sellPrice: Boolean,
    hppPerPcs: Boolean,
    weight: Boolean,
  },
  data: () => ({
    Model: Model,
    access: {},
    searchData: {
      Search: "",
      WarehouseID: null
    },
    selectedStock: null,
    getDetailsService: null,
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null,
    validateFunc: null // return {String} error message
  }),
  methods: {
    /*** public method ***/

    show(warehouseId, getListService, getDetailsService, validate) {
      this.searchData.WarehouseID = warehouseId;
      this.getDetailsService = getDetailsService;
      this.getListService = getListService;
      this.validateFunc = validate;
      this.showFunc();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.access = this.$kst.Access.create(Model.Access);
      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_Load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_Loaded(data) {
      this.$emit(this.$kst.Enum.Event.Loaded, data.StockList);
    },

    handleModal_Reset(loadList) {
      this.init_Data();
      this.handleModal_Load(loadList);
    },

    handleModal_Submit(selected, loadDetails) {
      const errorMsg = this.validateFunc
        ? this.validateFunc(selected)
        : null;

      if (errorMsg) {
        this.notifyFormError(false, errorMsg);
      }
      else {
        if (this.getDetailsService) {
          this.selectedStock = selected;
          const config = this.getDetailsService(
            selected.ItemID, this.searchData.WarehouseID
          );
          loadDetails(config, this.handleModal_Submit_Success);
        }
        else {
          this.$emit(this.$kst.Enum.Event.Submit, selected);
          this.hideFunc();
        }
      }
    },
    handleModal_Submit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, this.selectedStock, data);
      this.hideFunc();
    },

    /*** custom ***/

    handleList_NewItem() {
      this.$emit(this.$kst.Enum.Event.NewItem, this.searchData.Search);
      this.hideFunc();
    },

    getListOptions() {
      return {
        warehouse: this.searchData.WarehouseID,
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchListFields
      };
    },

    init_Data() {
      // searchData
      this.searchData.Search = "";
    }
  }
}
</script>