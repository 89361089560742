export default {
  updateDetails(data) {
    data.PaymentReturn = data.PaymentValue - data.Total;

    data.TotalItems = 0;
    for (const item of data.Items) {
      data.TotalItems += item.RequestedQuantity;
    }
  },
  updateItems(items) {
    for (const item of items) {
      item.DiscountedValue = (item.DispatchSellPrice - item.SellPrice)
        * item.RequestedQuantity;
      item.BasicTotalPrice = item.DispatchSellPrice
        * item.RequestedQuantity;
    }
  }
}