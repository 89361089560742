import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import PosN from "./New/PosN.vue";
import PosPT from "./PrintThermal/PosPT.vue";

export default {
  Path: {
    New: RouterModel.getPath(AppModule.Pos),
    PrintThermal: RouterModel.getPath(AppModule.Pos, AppModuleType.PrintThermal)
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Pos),
        name: RouterModel.getName(AppModule.Pos),
        component: PosN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Pos),
          module: RouterModel.getModule(AppModule.Pos),
          moduleattr: AppModuleAttr.Pos_Act_New,
          moduletype: AppModuleType.New
        }
      },
      {
        path: RouterModel.getPath(AppModule.Pos, AppModuleType.PrintThermal),
        name: RouterModel.getName(AppModule.Pos, AppModuleType.PrintThermal),
        component: PosPT,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Pos, AppModuleType.PrintThermal),
          module: RouterModel.getModule(AppModule.Pos),
          moduleattr: AppModuleAttr.Pos_Act_New,
          moduletype: AppModuleType.PrintThermal
        }
      }
    ];
  }
}