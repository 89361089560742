<template>
  <div class="block">
    <VendorSearchAddress
      @init="handleVendor_init"
      @submit="handleVendor_submit"
    />

    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="userData"
      dateField="Date"
      numberField="ReceiveNumber"
    />

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="VendorName"
          :disabled="vendorDisabled"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <template v-if="vendorSearchEnabled">
          <div class="control">
            <template v-if="userData.VendorID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button search-mode @click="handleSearch_vendor"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button clear-mode @click="handleClear_vendor"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="DueDate" :data="userData" :catalog="Catalog"
      :customValidations="dueDateValidations"
    />
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import VendorSearchAddress from "../../Vendor/SearchListAddress/VenScLAddress.vue";

export default {
  components: {
    VendorSearchAddress
  },
  props: {
    userData: Object,
    returnItemSummaryList: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showFunc: null
  }),
  computed: {
    dueDateValidations() {
      return Model.Edit.validationDueDate(this.userData);
    },
    vendorDisabled() {
      if (this.userData.VendorSourceID ===
        Model.ReceiveVendorSourceID.MasterVendor.ID
      ) {
        return true;
      }

      if (this.userData.VendorID !== null) {
        return true;
      }

      if (this.userData.PurchasingID !== null &&
        this.userData.PurchasingIsActive === true
      ) {
        return true;
      }

      return false;
    },
    vendorSearchEnabled() {
      if (this.userData.PurchasingID !== null &&
        this.userData.PurchasingIsActive === true
      ) {
        return false;
      }
      
      if (this.returnItemSummaryList.length > 0) {
        return false;
      }

      return true;
    }
  },
  methods: {
    /*** by client modal ***/

    handleVendor_init(show) {
      this.showFunc = show;
    },
    handleVendor_submit(row) {
      Model.setDetailsByVendor(this.userData, row);
    },

    /*** custom ***/

    handleSearch_vendor() {
      this.showFunc(Services.getRelatedVendorAddressList);
    },
    handleClear_vendor() {
      Model.clearDetailsByVendor(this.userData);
    }
  }
}
</script>