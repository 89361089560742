import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./PurModel_Body.js";
import PurItemCatalog from "./PurItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  setDetailsByData(userData, purchaseData, warehouseList, receiveCount) {
    userData.ID = purchaseData.ID;
    // user input
    userData.PONumber = App.In.getString(purchaseData.PONumber);
    userData.IsAutoNumber = false;
    userData.Date = App.In.getDate(purchaseData.Date);
    userData.VendorID = purchaseData.VendorID;
    userData.Vendor = App.In.getString(purchaseData.Vendor);
    userData.VendorAddress = App.In.getString(purchaseData.VendorAddress);
    userData.Comment = App.In.getString(purchaseData.Comment);
    userData.DeliveryAddress = App.In.getString(purchaseData.DeliveryAddress);
    userData.DeliveryDate = App.In.getDate(purchaseData.DeliveryDate);
    // by system
    userData.VendorAlias = purchaseData.VendorAlias;
    userData.VendorMobile = purchaseData.VendorMobile;
    userData.VendorPhone = purchaseData.VendorPhone;
    userData.VendorEmail = purchaseData.VendorEmail;
    userData.ReceivingCount = receiveCount;

    const warehouse = App.Array.searchItem(
      warehouseList, "ID", purchaseData.WarehouseID
    );
    if (warehouse) {
      userData.WarehouseID = warehouse.ID;
      userData.WarehouseName = warehouse.Name;
    }
  },

  setItemsByData(userItems, purchaseItems) {
    App.Array.truncate(userItems);

    for (const row of purchaseItems) {
      let item = ModelBody.createItem();
      this.setItemsByData_setItem(item, row);
      ModelBody.updateItem(item);
      userItems.push(item);
    }
  },
  setItemsByData_setItem(userItem, purchaseItem) {
    userItem.ID = purchaseItem.ID;
    // user input
    userItem.DispatchID = purchaseItem.DispatchID;
    userItem.Name = App.In.getString(purchaseItem.Name);
    userItem.Quantity = App.In.getInteger(purchaseItem.Quantity);
    userItem.PackagingName = purchaseItem.PackagingName;
    userItem.BuyPrice = App.In.getInteger(purchaseItem.BuyPrice);
    userItem.IsClosed = App.In.getBoolean(purchaseItem.IsClosed);
    // by system
    userItem.SKU = purchaseItem.SKU;
    userItem.PackagingValue = purchaseItem.PackagingValue;
    userItem.DispatchIsActive = App.In.getBoolean(purchaseItem.DispatchIsActive);
    userItem.QtyReceive = purchaseItem.QtyReceive;
    // validation
    if (userItem.QtyReceive > 0) {
      userItem.CustomValidations = {
        [App.Vee.Rule.MinValue]: [
          userItem.QtyReceive,
          PurItemCatalog.QtyReceive.Label
        ]
      };
    }

    // handle deleted-item
    if (purchaseItem.DispatchIsActive) {
      userItem.PackagingOptions = ItemModel.createPackagingOptions(
        purchaseItem.PackagingList
      );
    }
  },

  getItemInfo(userItem, newItemDefault) {
    let infoList = [];

    // new-item
    if (userItem.DispatchID === newItemDefault.ItemID) {
      infoList.push(AppCatalog.Info.NewItem);
    }
    else {
      // deleted item
      if (!userItem.DispatchIsActive) {
        infoList.push(AppCatalog.Info.DeletedItem);
      }
      else {
        // SKU
        infoList.push(PurItemCatalog.SKU.Label + ": " +
          App.Value.getValue("SKU", userItem, PurItemCatalog)
        );
      }

      // QtyReceive
      infoList.push(PurItemCatalog.QtyReceive.Label + ": " +
        App.Value.getValue("QtyReceive", userItem, PurItemCatalog)
      );
    }

    return infoList;
  }
}