import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.vendor;

export default {
  deleteVendorItem(vendorItemId) {
    return {
      method: App.Enum.Axios.Method.Delete,
      url: apiEndpoint + "/vendoritem?vendoritemid=" + vendorItemId
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getExportDetails(params) {
    const attributes = ["id", "withdebtlist"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails" + urlParams
    };
  },
  getExportList(params) {
    const attributes = ["search", "field", "withaddress"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  getPrintListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getRelatedItemList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/list" + urlParams
    };
  },
  getRelatedItemPackagingList(params) {
    const attributes = ["itemid"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/itempackaging/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  postVendorItem(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/vendoritem",
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  }
}