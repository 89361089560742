import AppCatalog from "@/services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ModuleAttrName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },

  // support
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  }
}