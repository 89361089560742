import App from "../../services/App/App.js";
import ModelBody from "./DashModel_Body.js";
import ModelReport from "./DashModel_Report.js";
import Info from "./DashInfo.js";
import DailyReportEnum from "../DailyReport/DaiEnum.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  UrlParams: ModelBody.UrlParams,

  /*** method ***/

  /*** method - Report ***/

  Report: {
    // BestSellingItems
    getInvoiceUrlObj_byBestSellingItems(searchData) {
      return ModelReport.getInvoiceUrlObj_byBestSellingItems(searchData);
    },

    // Data
    getDataSectionHelp() {
      return Info.Section.Data.Help + " " +
        App.Format.getShortDate(App.In.getDateToday());
    },
    getDataList(transDetails, access, searchData) {
      return ModelReport.getDataList(transDetails, access, searchData);
    },

    // DeathStock
    getStockUrlObj_byDeathStock(searchData) {
      return ModelReport.getStockUrlObj_byDeathStock(searchData);
    },

    // LowStock
    getLowStockUrlObj(searchData) {
      return ModelReport.getLowStockUrlObj(searchData);
    },

    // StockWithoutInvoice
    getStockUrlObj_byStockWithoutInvoice(searchData) {
      return ModelReport.getStockUrlObj_byStockWithoutInvoice(searchData);
    },

    // TopBuyer
    getInvoiceUrlObj_byTopBuyer(searchData) {
      return ModelReport.getInvoiceUrlObj_byTopBuyer(searchData);
    },
    getRtnInvoiceUrlObj_byTopBuyer(searchData) {
      return ModelReport.getRtnInvoiceUrlObj_byTopBuyer(searchData);
    },

    // TransSummary
    getTransSummaryList(transDetails, access, searchData) {
      return ModelReport.getTransSummaryList(transDetails, access, searchData);
    }
  },

  /*** external ***/

  DailyReportSearchResult: DailyReportEnum.SearchResult,

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  // DEPRECATED
  FilterType: {
    Today: {
      ID: 1, 
      Label: "Hari ini"
    },
    Yesterday: {
      ID: 2, 
      Label: "Kemarin"
    },
    LastWeek: {
      ID: 3, 
      Label: "7 Hari Terakhir"
    },
    Last30Days: {
      ID: 4, 
      Label: "30 Hari Terakhir"
    },
    ThisMonth: {
      ID: 5, 
      Label: "Bulan Ini"
    },
    LastMonth: {
      ID: 6, 
      Label: "Bulan Kemarin"
    }
  },

  createFilterOptions(optionAllText) {
    return this.helper.createFilterOptions(optionAllText, this.FilterType);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  },

  helper: {
    createFilterOptions(optionAllText, filterEnum) {
      let todayDate = App.In.getDateToday();
      const yesterday = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 1);
      const last7dDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 6);
      const last30dDate = new Date(todayDate.getFullYear(), todayDate.getMonth(),
        todayDate.getDate() - 29);
      const firstDayThisMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
      const firstDayLastMonthDate = new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1);
      const lastDayLastMonthDate = new Date(firstDayThisMonthDate.getFullYear(),
        firstDayThisMonthDate.getMonth(), firstDayThisMonthDate.getDate() - 1);

      const rowId = "ID";
      const rowLabel = "Label";
      const rowStart = "StartDate";
      const rowEnd = "EndDate";

      const items = [
        { [rowId]: filterEnum.Today.ID, [rowLabel]: filterEnum.Today.Label, [rowStart]: todayDate, [rowEnd]: todayDate },
        { [rowId]: filterEnum.Yesterday.ID, [rowLabel]: filterEnum.Yesterday.Label, [rowStart]: yesterday, [rowEnd]: yesterday },
        { [rowId]: filterEnum.LastWeek.ID, [rowLabel]: filterEnum.LastWeek.Label, [rowStart]: last7dDate, [rowEnd]: todayDate },
        { [rowId]: filterEnum.Last30Days.ID, [rowLabel]: filterEnum.Last30Days.Label, [rowStart]: last30dDate, [rowEnd]: todayDate },
        { [rowId]: filterEnum.ThisMonth.ID, [rowLabel]: filterEnum.ThisMonth.Label, [rowStart]: firstDayThisMonthDate, [rowEnd]: todayDate },
        { [rowId]: filterEnum.LastMonth.ID, [rowLabel]: filterEnum.LastMonth.Label, [rowStart]: firstDayLastMonthDate, [rowEnd]: lastDayLastMonthDate },
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel, startDate: rowStart, endDate: rowEnd };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    }
  }
}