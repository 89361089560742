import AppModule from "../../services/App/AppModule.js";
import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  Export: {
    Excel: {
      // file
      SheetName: {
        Report: AppModule.DailyReport.FullName
      },
      // ui
      Option: {
        Report: AppModule.DailyReport.FullName
      }
    }
  },
  Summary :{
    Total: AppCatalog.Field.Total.Label
  }
}