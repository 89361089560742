<template>
  <div class="block">
    <VendorSearchAddress
      @init="handleVendor_Init"
      @submit="handleVendor_Submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="PONumber"
    />

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Vendor"
          :disabled="userData.VendorID !== null"
          :data="userData"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <div class="control">
          <template v-if="userData.VendorID === null">
            <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button search-mode @click="handleSearch_Vendor"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
              <kst-button clear-mode @click="handleClear_Vendor"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions" @input="handleWarehouse"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../PurCatalog.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import VendorSearchAddress from "../../Vendor/SearchListAddress/VenScLAddress.vue";

export default {
  components: {
    VendorSearchAddress
  },
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showFunc: null
  }),
  methods: {
    /*** by vendor modal ***/

    handleVendor_Init(show) {
      this.showFunc = show;
    },
    handleVendor_Submit(vendorData, vendorItemData) {
      if (vendorData.HasItem) {
        Model.New.setItemsByVendorItem(this.userItems, 
          vendorItemData.VendorItemList, vendorItemData.ItemPackagingList
        );
        Model.updateDetails(this.userData, this.userItems);
      }

      Model.setDetailsByVendor(this.userData, vendorData);
    },

    /*** by warehouse ***/

    handleWarehouse() {
      Model.setDetailsByWarehouse(this.userData, this.warehouseOptions);
    },

    /*** custom ***/

    handleClear_Vendor() {
      Model.clearDetailsByVendor(this.userData);
    },
    handleSearch_Vendor() {
      this.showFunc(
        Services.getRelatedVendorAddressList,
        Services.getRelatedVendorItemList
      );
    }
  }
}
</script>