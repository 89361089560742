import RouterModel from "../../router/RouterModel.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import AnumL from "./List/AnumL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.AutoNumber, AppModuleType.List),
        name: RouterModel.getName(AppModule.AutoNumber, AppModuleType.List),
        component: AnumL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.AutoNumber, AppModuleType.List),
          module: RouterModel.getModule(AppModule.AutoNumber),
          moduleattr: AppModuleAttr.AutoNumber_Act_List,
          moduletype: AppModuleType.List,
        }
      }
    ];
  }
}