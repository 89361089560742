import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import ModelBody from "./PlosModel_Body.js";
import InvoiceEnum from "../Invoice/InvEnum.js";

// formula: CashBased
export default {
  getSummary(transactionDetails, access, searchData) {
    let result = ModelBody.createSummary();

    let newTransRow;
    const summaryObj = this.getSummary_getTransactionSummary(transactionDetails);

    newTransRow = this.getSummary_getHppAdj(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getPos(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getDebtPay(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getRecPay(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getExpense(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getStockOpname(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    newTransRow = this.getSummary_getStockReduction(summaryObj, access, searchData);
    result.TransactionList.push(newTransRow);

    if (Object.prototype.hasOwnProperty.call(transactionDetails, "StockTrf")) {
      newTransRow = this.getSummary_getStockTrf(summaryObj, access, searchData);
      result.TransactionList.push(newTransRow);
    }

    for (const row of result.TransactionList) {
      if (row.AddTotal !== null) {
        result.Total += row.AddTotal;
      }

      if (row.SubTotal !== null) {
        result.Total -= row.SubTotal;
      }
    }

    return result;
  },
  getSummary_getTransactionSummary(transactionDetails) {
    let addTotal, subTotal, row;
    let summaryObj = {};

    // HppAdj
    addTotal = 0;
    subTotal = null;
    for (row of transactionDetails.HppAdjList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    summaryObj[AppModule.HppAdj.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // Pos
    addTotal = 0;
    subTotal = null;
    for (row of transactionDetails.PosList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    summaryObj[AppModule.Pos.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // DebtPay
    addTotal = 0;
    subTotal = 0;
    for (row of transactionDetails.DebtPay.VoidList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    for (row of transactionDetails.DebtPay.AllList) {
      subTotal += row.Total;
    }
    subTotal = App.Data.getFixedDecimal(subTotal);

    summaryObj[AppModule.DebtPay.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // RecPay
    addTotal = 0;
    subTotal = 0;
    for (row of transactionDetails.RecPay.AllList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    for (row of transactionDetails.RecPay.VoidList) {
      subTotal += row.Total;
    }
    subTotal = App.Data.getFixedDecimal(subTotal);

    summaryObj[AppModule.RecPay.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // Expense
    addTotal = null;
    subTotal = 0;
    for (row of transactionDetails.ExpenseList) {
      subTotal += row.Total;
    }
    subTotal = App.Data.getFixedDecimal(subTotal);

    summaryObj[AppModule.Expense.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // StockOpname
    addTotal = 0;
    subTotal = null;
    for (row of transactionDetails.StockOpnameList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    summaryObj[AppModule.StockOpname.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // StockReduction
    addTotal = null;
    subTotal = 0;
    for (row of transactionDetails.StockReductionList) {
      subTotal += row.Total;
    }
    subTotal = App.Data.getFixedDecimal(subTotal);

    summaryObj[AppModule.StockReduction.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // StockTrf
    if (Object.prototype.hasOwnProperty.call(transactionDetails, "StockTrf")) {
      addTotal = 0;
      subTotal = 0;
      for (row of transactionDetails.StockTrf.AddList) {
        addTotal += row.Total;
      }
      addTotal = App.Data.getFixedDecimal(addTotal);

      for (row of transactionDetails.StockTrf.SubList) {
        subTotal += row.Total;
      }
      subTotal = App.Data.getFixedDecimal(subTotal);

      summaryObj[AppModule.StockTrf.Code] = {
        AddTotal: addTotal,
        SubTotal: subTotal
      };
    }

    return summaryObj;
  },
  getSummary_getDebtPay(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.DebtPay.Code];

    const addSearch = {
      Search: "",
      Date: searchData.Date,
      PaymentTypeName: App.Search.OptionAll,
      StatusID: App.Search.OptionAll,
      VendorID: null,
      VendorName: "",
      WarehouseID: searchData.WarehouseID
    };
    const addParams = ["Search", "Date", "PaymentTypeName", "StatusID",
      "VendorID", "VendorName", "WarehouseID"
    ];

    return this.getSummary_createTransRow(
      access.DebtPay_Act_List, AppModule.DebtPay.FullName, AppModule.DebtPay,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, addSearch, addParams
    );
  },
  getSummary_getExpense(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.Expense.Code];

    const subSearch = {
      Search: "",
      Date: searchData.Date,
      PaymentTypeName: App.Search.OptionAll,
      SubCategoryID: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const subParams = ["Search", "Date", "PaymentTypeName", "SubCategoryID", 
      "WarehouseID"
    ];

    return this.getSummary_createTransRow(
      access.Expenses_Act_List, AppModule.Expense.FullName, AppModule.Expense,
      transSummary.AddTotal, null, null,
      transSummary.SubTotal, subSearch, subParams
    );
  },
  getSummary_getHppAdj(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.HppAdj.Code];

    const addSearch = {
      Search: "",
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const addParams = ["Search", "Date", "WarehouseID"];

    return this.getSummary_createTransRow(
      access.HppAdj_Act_List, AppModule.HppAdj.FullName, AppModule.HppAdj,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, null, null
    );
  },
  getSummary_getPos(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.Pos.Code];

    const addSearch = {
      Search: "",
      Client: "",
      ClientID: null,
      InvoiceDate: searchData.Date,
      Status: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID,
      RecordTypeID: InvoiceEnum.RecordType.Pos.ID
    };
    const addParams = ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID", "RecordTypeID"
    ];

    return this.getSummary_createTransRow(
      access.Invoice_Act_List, AppModule.Pos.FullName, AppModule.Invoice,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, null, null
    );
  },
  getSummary_getRecPay(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.RecPay.Code];

    const addSearch = {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: searchData.Date,
      PaymentTypeName: App.Search.OptionAll,
      StatusID: App.Search.OptionAll,
      WarehouseID: searchData.WarehouseID
    };
    const addParams = ["Search", "ClientID", "ClientName", "Date",
      "PaymentTypeName", "StatusID", "WarehouseID"
    ];

    return this.getSummary_createTransRow(
      access.RecPay_Act_List, AppModule.RecPay.FullName, AppModule.RecPay,
      transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, addSearch, addParams
    );
  },
  getSummary_getStockOpname(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.StockOpname.Code];

    const addSearch = {
      Search: "",
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const addParams = ["Search", "Date", "WarehouseID"];

    return this.getSummary_createTransRow(
      access.StockOpname_Act_List, AppModule.StockOpname.FullName,
      AppModule.StockOpname, transSummary.AddTotal, addSearch, addParams,
      transSummary.SubTotal, null, null
    );
  },
  getSummary_getStockReduction(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.StockReduction.Code];

    const subSearch = {
      Search: "",
      Date: searchData.Date,
      WarehouseID: searchData.WarehouseID
    };
    const subParams = ["Search", "Date", "WarehouseID"];

    return this.getSummary_createTransRow(
      access.StockReduction_Act_List, AppModule.StockReduction.FullName,
      AppModule.StockReduction, transSummary.AddTotal, null, null,
      transSummary.SubTotal, subSearch, subParams
    );
  },
  getSummary_getStockTrf(summaryObj, access, searchData) {
    const transSummary = summaryObj[AppModule.StockTrf.Code];

    const addSearch = {
      Search: "",
      Date: searchData.Date,
      SourceWarehouseID: App.Search.OptionAll,
      DestinationWarehouseID: searchData.WarehouseID
    };

    const subSearch = {
      Search: "",
      Date: searchData.Date,
      SourceWarehouseID: searchData.WarehouseID,
      DestinationWarehouseID: App.Search.OptionAll
    };

    const params = ["Search", "Date", "SourceWarehouseID",
      "DestinationWarehouseID"
    ];

    return this.getSummary_createTransRow(
      access.StockTrf_Act_List, AppModule.StockTrf.FullName, AppModule.StockTrf,
      transSummary.AddTotal, addSearch, params,
      transSummary.SubTotal, subSearch, params
    );
  },
  getSummary_createTransRow(hasAccess, moduleName, module, addTotal,
    addSearch, addParams, subTotal, subSearch, subParams
  ) {
    return {
      HasAccess: hasAccess,
      Label: moduleName,
      Module: module,
      AddTotal: addTotal,
      AddSearch: addSearch,
      AddParams: addParams,
      SubTotal: subTotal,
      SubSearch: subSearch,
      SubParams: subParams
    };
  }
}