<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Ref :user-data="userData"/>
    <Details :type-options="typeOptions" :user-data="userData"/>
    <Packaging
      :user-data="userData"
      :user-packaging-list="userPackagingList"
    />
    <Variant :user-variant-list="userVariantList"/>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import Details from "./ItemN_Details.vue";
import Packaging from "./ItemN_Packaging.vue";
import Ref from "./ItemN_Ref.vue";
import Variant from "./ItemN_Variant.vue";

export default {
  components: {
    Details,
    Packaging,
    Ref,
    Variant
  },
  mixins: [InputPageMixin, RouterMixin],
  data: () => ({
    userData: {},
    userPackagingList: [],
    userVariantList: [],
    typeOptions: {},
    purchaseItemData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const options = {
        purchaseitem: this.getUrlParam(Catalog.PurchaseItemID.Param),
        ref: this.getUrlParam(Catalog.RefID.Param)
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.typeOptions = Model.createTypeOptions(data.TypeList);
      Model.setDetailsByTypeList(this.userData, this.typeOptions.rows);

      if (data.PurchasingItemDetails) {
        this.purchaseItemData = data.PurchasingItemDetails;

        Model.New.setDetailsByNewPurchasingItem(
          this.userData,
          this.userPackagingList,
          this.userVariantList,
          data.PurchasingItemDetails
        );
      }
      else if (data.RefDetails) {
        Model.New.setDetailsByNewRef(
          this.userData,
          this.userPackagingList,
          this.userVariantList,
          data.RefDetails
        );
      }
      else {
        // packaging
        this.userPackagingList = [
          Model.createPackaging(this.userData, true)
        ];
        this.userData.DefaultPackagingID = this.userPackagingList[0].ID;

        // variant
        this.userVariantList = [
          Model.createVariant(),
          Model.createVariant()
        ];
      }
    },

    handleSubmit(saveData) {
      let data = Model.populateData(
        this.userData, this.userPackagingList, this.userVariantList,
        this.purchaseItemData
      );
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userPackagingList = [];
      this.userVariantList = [];
      this.typeOptions = {};
      this.purchaseItemData = null;
    }
  }
}
</script>