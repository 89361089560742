<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Receive
      :userData="userData"
      :warehouseList="warehouseList"
      @reset="handleReceive_Reset"
      @submit="handleReceive_Submit"
    />

    <template v-if="userData.ReceiveID">
      <Details :userData="userData" :warehouseOptions="warehouseOptions"/>
      <Items
        :receiveItems="receiveItems"
        :userData="userData"
        :userItems="userItems"
      />

      <div class="columns">
        <div class="column">
          <Total :userData="userData"/>
        </div>
      </div>
    </template>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../RrcvCatalog.js";
import Model from "../RrcvModel.js";
import Services from "../../../services/Api/ReturnReceiveServices.js";

import Details from "./RrcvN_Details.vue";
import Receive from "./RrcvN_Receive.vue";
import Items from "./RrcvN_Items.vue";
import Total from "./RrcvN_Total.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    Details,
    Receive,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    receiveItems: [],
    resetFailurePageNewFunc: null,
    warehouseOptions: {},
    warehouseList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew, submit, resetFailure) {
      this.resetFailurePageNewFunc = resetFailure;
      this.resetData();

      const options = {
        receive: this.getUrlParam(Catalog.ReceiveID.Param)
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.warehouseList = data.WarehouseList;

      if (Object.prototype.hasOwnProperty.call(data, "ReceiveDetails")) {
        this.updateByReceive(data.ReceiveDetails);
      }
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    handleReceive_Reset() {
      this.resetFailurePageNewFunc();
      this.resetData();
    },
    handleReceive_Submit(data) {
      this.resetData();
      this.updateByReceive(data.ReceiveDetails);
    },

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
    },

    updateByReceive(receiveDetails) {
      // receive items
      this.receiveItems = receiveDetails.ItemsWithReturn;
      Model.New.updateReceiveItems(this.receiveItems);

      // return receive
      // ignore userItems: user need to input items manually
      Model.New.setDetailsByReceive(this.userData, receiveDetails);
    }
  }
}
</script>