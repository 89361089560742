<template>
  <div class="block">
    <kst-input 
      field="Date" 
      :data="userData" 
      :catalog="Catalog"
      :customValidations="receiveDateValidations"
    />

    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Model from "../RcvModel.js";

export default {
  props: {
    receiveValidation: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    receiveDateValidations() {
      return Model.EditDate.validationReceiveDate(this.receiveValidation);
    },
  }
}
</script>