import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import RecPayEnum from "../RecPay/RcpyEnum.js";

export default {
  setDetailsByProfit(details, rtnInvoiceList) {
    this.setDetailsByProfit_setItems(details.Items, details);

    // set: TotalReturn, HppTotalReturn
    details.TotalReturn = 0;
    details.HppTotalReturn = 0;
    for (let row of rtnInvoiceList) {
      details.TotalReturn += row.Total;
      details.HppTotalReturn += row.HppTotal;
    }

    details.HppTotalNetto = details.HppTotal - details.HppTotalReturn;
    details.TotalNetto = details.Total - details.TotalReturn;
    details.Profit = details.TotalNetto - details.HppTotalNetto;
  },
  setDetailsByProfit_setItems(items, details) {
    let index, item, totalDisc;
    details.HppTotal = 0;

    totalDisc = details.TotalDisc;
    for (index = 0; index < items.length - 1; index++) {
      item = items[index];
      item.HppTotal = item.HppTotal ? item.HppTotal : 0;

      if (details.DiscPercent !== null) {
        item.ParentDisc = App.Data.getDiscPercent_Value(
          item.Price, details.DiscPercent
        );
      }
      else if (details.DiscValue !== null) {
        item.ParentDisc = item.Price === 0
          ? 0
          : App.Data.getValueProrate(
              details.DiscValue, item.Price, details.TotalBruto
            );
      }
      else {
        item.ParentDisc = 0;
      }

      item.TotalNetto = item.Price - item.ParentDisc;
      item.Profit = item.TotalNetto - item.HppTotal;
      item.ProfitMargin = item.TotalNetto === 0
        ? item.Profit === 0 ? 0 :
          item.Profit > 0
            ? AppCatalog.PercentDec100
            : -1 * AppCatalog.PercentDec100
        : App.Data.getFixedDecimal(
          item.Profit / item.TotalNetto * AppCatalog.PercentDec100
        );

      totalDisc -= item.ParentDisc;
      details.HppTotal += item.HppTotal;
    }

    // special for last item: use available total disc
    item = items[items.length - 1];
    item.HppTotal = item.HppTotal ? item.HppTotal : 0;
    item.ParentDisc = totalDisc;
    item.TotalNetto = item.Price - item.ParentDisc;
    item.Profit = item.TotalNetto - item.HppTotal;
    item.ProfitMargin = item.TotalNetto === 0
      ? item.Profit === 0 ? 0 :
        item.Profit > 0
          ? AppCatalog.PercentDec100
          : -1 * AppCatalog.PercentDec100
      : App.Data.getFixedDecimal(
        item.Profit / item.TotalNetto * AppCatalog.PercentDec100
      );

    details.HppTotal += item.HppTotal;
  },

  getItemsSummaryByDelivery(details, deliveryItemList) {
    // summary items
    const fieldKeys = [ "DispatchID", "SKU", "Name", "PackagingName" ];
    const fieldValues = [
      { field: "PackagingValue", type: App.Pivot.Type.FIRST },
      { field: "RequestedQuantity", type: App.Pivot.Type.SUM }
    ];
    const pivotItems = App.Pivot.create(details.Items, fieldKeys, fieldValues);

    let pivotItem, conditions, deliveryItem;
    for (pivotItem of pivotItems) {
      conditions = {
        DispatchID: pivotItem.DispatchID,
        Name: pivotItem.Name,
        PackagingName: pivotItem.PackagingName
      }
      deliveryItem = App.Array.getItem2(deliveryItemList, conditions);

      pivotItem.DeliveryQuantity = deliveryItem ? deliveryItem.Quantity : 0;
    }

    return pivotItems;
  },

  updateRecData(recData, invoiceData) {
    recData.InvoiceRecTotal = invoiceData.RecTotal;
    recData.InvoiceDueDate = invoiceData.DueDate;
    recData.InvoiceTotal = invoiceData.Total;
    recData.RecPayTotal = 0;

    for (const recPay of recData.RecPayList) {
      if (recPay.StatusID === RecPayEnum.StatusID.Active.ID) {
        recData.RecPayTotal += recPay.InvPaymentValue;
      }
    }
  },

  /*** external ***/

  updateDeliveryItem(deliveryItem) {
    deliveryItem.Packaging = App.Data.getPackaging(
      deliveryItem.PackagingName, deliveryItem.PackagingValue
    );
  },
  updateRtnInvoiceItem(rtnInvoiceItem) {
    rtnInvoiceItem.Packaging = App.Data.getPackaging(
      rtnInvoiceItem.PackagingName, rtnInvoiceItem.PackagingValue
    );
  },
  updateStockReductionItem(stockReductionItem) {
    stockReductionItem.Packaging = App.Data.getPackaging(
      stockReductionItem.PackagingName, stockReductionItem.PackagingValue
    );
  }
}