import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 45,
    Required: true,
    Highlight: true
  },
  PIC: {
    Label: AppCatalog.Field.PIC.Label,
    Type: AppCatalog.Field.PIC.Type,
    MaxLength: 100
  },
  Address: {
    Label: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200,
    Required: true,
    Highlight: true
  },
  NoPIC: {
    Label: AppCatalog.Field.NoPIC.Label,
    Type: AppCatalog.Field.NoPIC.Type,
    MaxLength: 15
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 100
  },
  // Company Profile Alias
  CompanyName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 80,
  },
  CompanyAddress : {
    Label: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 150,
  },
  CompanyPhone: {
    Label: AppCatalog.Field.Phone.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 100,
  }
}