<template>
  <div>
    <div class="base-background"></div>
    <div class="diagonal-background"></div>
    <a :href="websiteUrl" class="company-logo">
      <img src="../../../assets/companylogo_ws.png" class="default-logo" />
      <img src="../../../assets/companylogo.png" class="vertical-logo" />
    </a>
    <!-- </div> -->
    <div class="base-container">
      <div class="slideshow-container slideshow-container-sign-up">
        <Banner />
        <form :class="formContainer">
          <span class="form-title">{{ formTitle }}</span>
          <div>
            <span class="help-text">Hanya menyediakan Registrasi sebagai Owner.</span>
            <Company 
              :data="company" 
              @callback="handleCompanyCallback" 
              @cancel="handleCancel"
            />
          </div>
        </form>
        <div class="copyright">
          <!-- &#169; PT. Kreatifitas Sinergisme Teknoindo 2021 -->
          <span>&#169; 2021 Dirigo By <a href="https://www.kst.co.id/" target="_blank"><u>PT Kreatifitas Sinergisme Teknoindo</u></a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppModule from "../../../services/App/AppModule.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Info from "../SecurityInfo.js";
import WebsiteRouter from "../../Website/WebsiteRouter.js";

import Company from "./SignUpCompany.vue";
import Banner from "../SecurityBanner.vue";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Company,
    Banner
  },
  data: () => ({
    company: {},
    viewState: 0,
    userData: {}
  }),
  computed: {
    formTitle(){
      let title;
      if(this.viewState == 1) {
        title = Info.Title.UserRegistration;
      } else {
        title = Info.Title.CompanyRegistration;
      }
      return title;
    },
    formContainer(){
      let container;
      if(this.viewState == 1) {
        container = "form-container form-container-signin";
      } else {
        container = "form-container form-container-signup";
      }
      return container;
    },
    websiteUrl() {
      return WebsiteRouter.Path.Home;
    }
  },
  methods: {
    handleCompanyCallback(data) {
      if(!this.userData.email) {
        this.userData.email = data.email;
      }
      if(!this.userData.name) {
        this.userData.name = data.user;
      }
      this.viewState = 1;
    },
    handleCancel() {
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignIn));
    }
  }
}
</script>

<style scoped lang="css" src="../_layout.css"/>