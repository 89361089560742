<template>
  <div :class="theClass">
    <nav class="level top is-align-items-flex-start">
      <div class="level-left is-align-items-start">
        <div class="level-item is-justify-content-left">
          <div class="columns is-mobile">
            <template v-if="collapsible">
              <div class="column is-narrow">
                <kst-button action-mode
                  class="is-primary"
                  tag="button"
                  label=""
                  :iconLeft="theCollapseIcon"
                  @click="handleCollapse"
                />
              </div>
            </template>
            <div class="column">
              <div class="mb-0">
                <div class="level-left">
                  <div class="level-item is-justify-content-flex-start">
                    <slot name="title">
                      <kst-title :class="theTitleClass">{{ theTitle }}</kst-title>
                    </slot>
                  </div>
                  <template v-if="topLeftSlotVisible">
                    <div class="level-item is-justify-content-flex-start">
                      <slot name="top-left"/>
                    </div>
                  </template>
                </div>
              </div>

              <div>
                <template v-if="collapsible">
                  <template v-if="isCollapsed">
                    <kst-subtitle>{{ helpCollapsed }}</kst-subtitle>
                  </template>
                  <template v-else>
                    <kst-subtitle>{{ helpExpanded }}</kst-subtitle>
                  </template>
                </template>
                <template v-else>
                  <kst-subtitle v-if="theHelp">{{ theHelp }}</kst-subtitle>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="topRightSlotVisible">
        <div class="level-right">
          <template v-if="!loading">
            <slot name="top-right"/>
          </template>
        </div>
      </template>
    </nav>

    <template v-if="defaultSlotVisible">
      <transition name="slide">
        <div v-if="!isCollapsed">
          <slot />
        </div>
      </transition>
    </template>

    <template v-if="bottomSlotVisible">
      <div class="level-item">
        <template v-if="!loading">
          <slot name="bottom"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import SlotMixins from "../../../mixins/SlotMixins.js";

export default {
  mixins: [SlotMixins],
  props: {
    help: String,
    helpCollapsed: String,
    helpExpanded: String,
    loading: Boolean,
    title: String,
    // mode: ONLY select 1 (one)
    confirmMode: Boolean,
    borderlessMode: Boolean,
    exportMode: Boolean,
    hideBody: Boolean,
    infoMode: Boolean,
    itemsMode: Boolean,
    listMode: Boolean,
    printMode: Boolean,
    searchMode: Boolean,
    searchResultMode: Boolean,
    // help
    selectOneHelp: Boolean,
    // collapsible options
    collapsible: Boolean,
    collapsed: Boolean
  },
  data: () => ({
    isCollapsed: false
  }),
  computed: {
    bottomSlotVisible() {
      return this.slotHasContent("bottom");
    },
    defaultSlotVisible() {
      return this.slotHasContent("default");
    },
    theClass() {
      return this.getClass();
    },
    theTitleClass() {
      return this.getTitleClass();
    },
    theHelp() {
      return this.getHelp();
    },
    theTitle() {
      return this.getTitle();
    },
    theCollapseIcon() {
      return this.getCollapseIcon();
    },
    topLeftSlotVisible() {
      return this.slotHasContent("top-left");
    },
    topRightSlotVisible() {
      return this.slotHasContent("top-right");
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isCollapsed = this.collapsed ? true : false;
    },
    getClass() {
      if (this.borderlessMode) {
        return "block kst-section";
      }
      else if (this.exportMode) {
        return "block kst-section";
      }
      else if (this.printMode) {
        return "block kst-section";
      }
      else {
        return "block p-4 ks-border-dark k-radius-large kst-section";
      }
    },
    getTitleClass() {
      return this.collapsible ? "mb-0" : "";
    },
    getHelp() {
      if (this.selectOneHelp) {
        return AppCatalog.Section.SelectOne;
      }
      return this.help;
    },
    getTitle() {
      if (this.title) {
        return this.title;
      }

      if (this.confirmMode) {
        return AppCatalog.Section.Confirmation
      }
      else if (this.exportMode) {
        return AppCatalog.Section.Export;
      }
      else if (this.infoMode) {
        return AppCatalog.Section.Info + this.title;
      }
      else if (this.itemsMode) {
        return AppCatalog.Section.Items;
      }
      else if (this.listMode) {
        return AppCatalog.Section.List + this.title;
      }
      else if (this.printMode) {
        return AppCatalog.Section.Print;
      }
      else if (this.searchMode) {
        return AppCatalog.Section.Search;
      }
      else if (this.searchResultMode) {
        return AppCatalog.Section.SearchResult;
      }

      return undefined;
    },
    getCollapseIcon() {
      return this.isCollapsed 
        ? AppCatalog.Icon.Collapse.Collapsed.Icon
        : AppCatalog.Icon.Collapse.Expanded.Icon;
    },
    // event handler
    handleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
</script>