<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="SalesItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="SalesItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="SalesItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="SalesItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="SalesItemCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SalesItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import SalesItemCatalog from "../SalesItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    SalesItemCatalog: SalesItemCatalog
  })
}
</script>