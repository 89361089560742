import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import CpCatalog from "./CpCatalog.js";
import Model from "./CpModel.js";

export default {
  Message: {
    Edit_Email_ConfirmDelete:
      AppCatalog.Message.ConfirmDelete + " " +
        AppCatalog.Field.Email.Label + "?",
    Edit_Email_Validation: AppCatalog.Message.ErrorOnForm,
    Image_Tooltips_1: AppCatalog.Info.Logo + " " + AppCatalog.Info.Company,
    Image_Tooltips_2: AppCatalog.Field.ImageSrc.Label + " " + 
      "akan tampil pada cetak file " + AppModule.Invoice.Code + ", " + 
      AppCatalog.Info.Faktur + " dan " + AppModule.Purchase.FullName,
    Image_Tooltips_3: "Ukuran maksimal gambar adalah 1000 x 1000 pixel " + 
      "dengan format " + AppCatalog.Extension.Jpg + " / " + 
      AppCatalog.Extension.Jpeg + " / " + AppCatalog.Extension.Png,
    Input: AppCatalog.Message.Input
  },
  Page: {
    VerifySpvPass: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        "verifyspvpass",
      Title: "Input " + CpCatalog.spv_passwd.Label
    }
  },
  Section: {
    DebtReminder: {
      Title: AppCatalog.Info.NotificationReminder + " " + AppModule.Debt.FullName
    },
    LowStockReminder: {
      Title: AppCatalog.Info.NotificationReminder + " " + AppModule.LowStock.FullName
    },
    Module: {
      Title: "Modul Mandiri"
    },
    ModulePackage: {
      Title: AppModule.ModulePackage.FullName
    },
    RecReminder: {
      Title: AppCatalog.Info.NotificationReminder + " " + AppModule.Rec.FullName
    },
    SelfService: {
      Title: AppCatalog.Info.SalesSelfService
    },
    Setting: {
      Title: AppModule.Settings.FullName
    },
    Subscription: {
      Title: AppModule.Subscription.FullName,
      Help: {
        Icon: "mdi mdi-help",
        Label: ""
      }
    }
  },
  Tabs: {
    Accessibility: AppModuleType.List.FullName + " " + 
      AppModule.Access.FullName + " " +AppModule.Module.FullName,
    Details: AppModuleType.Details.Name,
  },
}