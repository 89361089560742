import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import RrcvItemCatalog from "./RrcvItemCatalog.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // ReceiveItemQty
    infoList.push(RrcvItemCatalog.ReceiveItemQty.Label + ": " +
      App.Value.getValue("ReceiveItemQty", item, RrcvItemCatalog)
    );

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.unshift(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(RrcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RrcvItemCatalog)
      );
    }

    return infoList;
  }
}