<template>
  <kst-output control-off :field="numberField" :data="data" :catalog="catalog">
    <template #default>
      <template v-if="isActive">
        <kst-output floating-label 
          :field="numberField" :data="data" :catalog="catalog"
        />
      </template>
      <template v-else>
        <kst-output floating-label addons control-off expanded
          :field="numberField" :data="data" :catalog="catalog"
        />
      </template>
    </template>

    <template #right v-if="isActive">
      <kst-output addons control-off expanded floating-label
        class="pl-2"
        :field="dateField" :data="data" :catalog="catalog"
      />
    </template>
    <template #left v-else>
      <div class="k-control-py mr-4">
        <kst-status :value="statusValue" :statusEnum="statusEnum"/>
      </div>
    </template>
  </kst-output>
</template>

<script>
export default {
  props: {
    catalog: Object,
    data: Object,
    isActiveField: String,
    dateField: String,
    numberField: String
  },
  computed: {
    isActive() {
      if (this.isActiveField !== null && this.isActiveField !== undefined) {
        return this.data[this.isActiveField] ? true : false;
      }
      else {
        return true;
      }
    },
    statusEnum() {
      return {
        InActive: {
          ID: 0,
          Class: this.$kst.Enum.Status.InActive.AltClass,
          Label: this.$kst.Enum.Status.InActive.AltLabel
        },
      };
    },
    statusValue() {
      return this.data[this.isActiveField] ? 1 : 0;
    }
  }
}
</script>