<template>
  <kst-section :help="sectionHelp">
    <template #title>
      <kst-title standard>{{ Info.Section.Data.Title }}</kst-title>
    </template>

    <kst-table view-mode :data="dataList">
      <b-table-column
        v-slot="{ row }"
        field="Label"
        :label="DashListCatalog.Name.Label"
      >
        {{ row.Label }}
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal has-text-weight-bold"
        field="HppTotal"
        :label="DashListCatalog.HppTotal.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DashListCatalog"/>
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        cell-class="ks-col-action ks-col-action-view-padding"
        field="HppTotal"
      >
        <kst-button actionMode
          :class="Info.Button.Transaction.Class"
          :hasAccess="row.HasAccess"
          :label="Info.Button.Transaction.Label"
          :module="row.Module"
          :moduleType="Info.Button.Transaction.ModuleType"
          :urlParams="getUrlParams(row.Search, row.Params)"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import DashListCatalog from "../DashListCatalog.js";
import DashTransSearchCatalog from "../DashTransSearchCatalog.js";
import Info from "../DashInfo.js";
import Model from "../DashModel.js";

export default {
  mixins: [RouterMixin],
  props: {
    dataList: Array
  },
  data: () => ({
    DashListCatalog: DashListCatalog,
    Info: Info
  }),
  computed: {
    sectionHelp() {
      return Model.Report.getDataSectionHelp();
    }
  },
  methods: {
    getUrlParams(search, params) {
      const urlQueryObj = this.getUrlQueryObj(
        search, params, DashTransSearchCatalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  }
}
</script>