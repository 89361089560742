<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Invoice
      :userData="userData"
      :warehouseList="warehouseList"
      @reset="handleInvoice_Reset"
      @submit="handleInvoice_Submit"
    />

    <template v-if="userData.SalesOrderID">
      <Details :userData="userData" :warehouseOptions="warehouseOptions"/>
      <Items
        :invoiceItems="invoiceItems"
        :userData="userData"
        :userItems="userItems"
      />

      <div class="columns">
        <div class="column">
          <Details2 class="mt-5"
            :userData="userData"
            :userItems="userItems"
          />
        </div>
        <div class="column">
          <Total :userData="userData"/>
        </div>
      </div>
    </template>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Model from "../RinvModel.js";
import Services from "../../../services/Api/ReturnInvoiceServices.js";

import Details from "./RinvN_Details.vue";
import Details2 from "./RinvN_Details2.vue";
import Invoice from "./RinvN_Invoice.vue";
import Items from "./RinvN_Items.vue";
import Total from "./RinvN_Total.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    Details,
    Details2,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    invoiceItems: [],
    warehouseOptions: {},
    warehouseList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const options = {
        invoice: this.getUrlParam(this.$kst.Enum.Param.InvoiceID)
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.warehouseList = data.WarehouseList;

      if (Object.prototype.hasOwnProperty.call(data, "InvoiceDetails")) {
        this.updateByInvoice(data.InvoiceDetails);
      }
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    handleInvoice_Reset() {
      this.resetData();
    },
    handleInvoice_Submit(data) {
      this.resetData();
      this.updateByInvoice(data.InvoiceDetails);
    },

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
    },

    updateByInvoice(invoiceDetails) {
      // invoice items
      this.invoiceItems = invoiceDetails.ItemsWithReturn;
      Model.New.updateInvoiceItems(this.invoiceItems);

      // return invoice
      // ignore userItems: user need to input items manually
      Model.New.setDetailsByInvoice(this.userData, invoiceDetails);
    }
  }
}
</script>