<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import PackagingCatalog from "../PackagingCatalog.js";
import VariantCatalog from "../VariantCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details);

      if (this.userData.WithRefList) {
        this.createSheet();
        this.addRefList(data.RefList);
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);
      this.addDetails_Packaging(details, details.PackagingList);
      this.addDetails_Variant(details);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.RefID.Label);
      this.addColumnHeader(Catalog.RefID);
      this.addColumnHeader(Catalog.TypeID);
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.SKU);
      this.addColumnHeader(Catalog.Barcode);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("RefID", details, Catalog);
      this.addCellRelatedValue("RefID", details, Catalog);
      this.addCellRelatedValue("TypeID", details, Catalog);
      this.addCellValue("Name", details, Catalog);
      this.addCellValue("SKU", details, Catalog);
      this.addCellValue("Barcode", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },
    addDetails_Packaging(details, packagingList) {
      this.addRow();
      this.addRow();
      this.addDetails_Packaging_Default(details);

      this.addRow();
      this.addRow();
      this.addDetails_Packaging_List(packagingList);
    },
    addDetails_Packaging_Default(details) {
      this.addColumnHeader(Catalog.DefaultPackagingID);
      this.addCellRelatedValue("DefaultPackagingID", details, Catalog);
    },
    addDetails_Packaging_List(packagingList) {
      this.addCellSection(Info.Section.PackagingList.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(PackagingCatalog.Name);
      this.addColumnHeader(PackagingCatalog.Qty);
      this.addColumnHeader(PackagingCatalog.SellPrice);
      this.addColumnHeader(PackagingCatalog.Weight);
      this.addColumnHeader(PackagingCatalog.IsActive);

      // value
      for (const row of packagingList) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue("Name", row, PackagingCatalog);
        this.addCellValue("Qty", row, PackagingCatalog);
        this.addCellValue("SellPrice", row, PackagingCatalog);
        this.addCellValue("Weight", row, PackagingCatalog);
        this.addCellStatus(row.IsActive, Model.PackagingIsActive);
      }
    },
    addDetails_Variant(details) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.Variant.Title);

      this.addDetails_Variant_List(details);
    },
    addDetails_Variant_List(details) {
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(VariantCatalog.Type);
      this.addColumnHeader(VariantCatalog.Name);

      // set #1
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("VariantType1", details, Catalog);
      this.addCellValue("VariantName1", details, Catalog);

      // set #2
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("VariantType2", details, Catalog);
      this.addCellValue("VariantName2", details, Catalog);
    },

    addRefList(refList) {
      this.addRefList_List(refList);
      this.addSheet(Info.Export.Details.Excel.SheetName.RefList);
    },
    addRefList_List(refList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.SKU);
      this.addColumnHeader(Catalog.Barcode);
      this.addColumnHeader(Catalog.DefaultSellPrice);
      this.addColumnHeader(Catalog.DefaultWeight);
      this.addColumnHeader(Catalog.Variant1);
      this.addColumnHeader(Catalog.Variant2);

      // value
      for (const row of refList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("SKU", row, Catalog);
        this.addCellValue("Barcode", row, Catalog);
        this.addCellValue("DefaultSellPrice", row, Catalog);
        this.addCellValue("DefaultWeight", row, Catalog);
        this.addCell(Model.getVariantSet1(row));
        this.addCell(Model.getVariantSet2(row));
      }
    }
  }
}
</script>