<template>
  <div>
    <div class="columns is-mobile">
      <div class="column is-8 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.TotalFinish.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5"
            field="TotalFinish" :data="userData" :catalog="Catalog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../StcvCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>