<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="DispatchID"
      :label="SpadItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="SpadItemCatalog.PackagingName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
        :index="index" :options="row.PackagingOptions"
        @input="handlePackaging(row)"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="SpadItemCatalog.Qty.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="SpadItemCatalog.SellPrice.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
        :index="index"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-specialprice-button"
      field="SpecialPriceID"
      :label="SpadItemCatalog.SpecialPriceID.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
        :options="row.SpecialPriceOptions" :index="index"
        @input="handleSpecialPrice(row)"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="SpadItemCatalog.Total.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="SpadItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.SpadItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../SpadInfo.js";
import Model from "../SpadModel.js";
import SpadItemCatalog from "../SpadItemCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    SpadItemCatalog: SpadItemCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItemByPackaging(item);
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleSpecialPrice(item) {
      Model.updateItemBySpecialPrice(item);
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        null,
        index
      );
    },
    handleRowDelete_Confirm(index) {
      this.userItems.splice(index, 1);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    }
  }
}
</script>