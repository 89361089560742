<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Sales v-if="isSalesVisible"
      :userData="userData"
      :warehouseList="warehouseList"
      @reset="handleSales_reset"
      @submit="handleSales_submit"
    />
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseList="warehouseList"
      :warehouseOptions="warehouseOptions"
      @input="handleDetails_input"
    />
    <Items
      :salesData="salesData"
      :userData="userData"
      :userItems="userItems"
    />

    <div class="columns">
      <div class="column">
        <Details2
          :userData="userData"
          :userItems="userItems"
        />
      </div>
      <div class="column">
        <Total :userData="userData"/>
      </div>
    </div>

    <Rec v-if="userData.ClientID !== null"
      :userData="userData"
    />
    <RecPay v-if="isRecPayVisible"
      :userData="userData"
      :paymentOptions="paymentOptions"
    />
    <Delivery v-if="isDeliveryVisible"
      :userData="userData"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Delivery from "./InvN_Delivery.vue";
import Details from "./InvN_Details.vue";
import Details2 from "./InvN_Details2.vue";
import Items from "./InvN_Items.vue";
import Rec from "./InvN_Rec.vue";
import RecPay from "./InvN_RecPay.vue";
import Sales from "./InvN_Sales.vue";
import Total from "./InvN_Total.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Rec,
    RecPay,
    Sales,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    paymentOptions: {},
    paymentTypeList: [],
    salesData: {},
    warehouseOptions: {},
    warehouseList: []
  }),
  computed: {
    isDeliveryVisible() {
      return this.$kst.Session.companyHasAccess(Model.Delivery.Module);
    },
    isRecPayVisible() {
      return this.$kst.Session.companyHasAccess(Model.RecPay.Module);
    },
    isSalesVisible() {
      return this.$kst.Session.companyHasAccess(Model.Sales.Module);
    }
  },
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const options = {
        sales: this.getUrlParam(Catalog.SalesDraftID.Param),
        transaction: this.getUrlParam(Catalog.TransactionID.Param)
      };
      const config = Services.getNewData(options);
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.paymentTypeList = data.PaymentTypeList;
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.warehouseList = data.WarehouseList;

      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
      Model.setDetailsByCompanySetting(this.userData, data.CompanySetting);
      Model.setDetailsBySpecialPrice(this.userData, data.SpecialPriceCount);

      if (Object.prototype.hasOwnProperty.call(data, "SalesDetails")) {
        let clientDetails =
          Object.prototype.hasOwnProperty.call(data, "ClientDetails")
          ? data.ClientDetails : null;
        this.updateBySales(data.SalesDetails, clientDetails);
      }

      if (Object.prototype.hasOwnProperty.call(data, "TransactionDetails")) {
        let clientDetails =
          Object.prototype.hasOwnProperty.call(data, "ClientDetails")
          ? data.ClientDetails : null;
        Model.New.setDetailsByTransaction(this.userData, data.TransactionDetails, 
          clientDetails, data.WarehouseDetails
        );
      }

      this.updatePaymentOptions();
    },

    handleSubmit(saveData) {
      const data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** by sales ***/

    handleSales_reset() {
      this.resetData();
      this.updatePaymentOptions();
    },
    handleSales_submit(data) {
      this.resetData();

      let clientDetails = data.SalesDetails.ClientID === null
        ? null : data.ClientDetails;
      this.updateBySales(data.SalesDetails, clientDetails);
      
      this.updatePaymentOptions();
    },

    /*** by details ***/

    handleDetails_input() {
      this.updatePaymentOptions();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.salesData = {};
    },

    updateBySales(salesDetails, clientDetails) {
      this.salesData = salesDetails;
      Model.New.updateSalesItems(this.salesData);

      Model.New.setItemsBySales(this.userData, this.userItems,
        this.salesData.Items
      );
      Model.New.setDetailsBySales(this.userData, this.salesData, clientDetails);
    },
    updatePaymentOptions() {
      const options = {
        WithCreditNote: this.userData.ClientID !== null
      };
      this.paymentOptions = Model.getPaymentOptions(this.paymentTypeList,
        null, Catalog.PaymentTypeName.Label, options
      );

      Model.New.setDetailsByPaymentList(this.userData, this.paymentOptions.rows);
    }
  }
}
</script>