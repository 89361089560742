<template>
  <kst-section info-mode collapsed
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help="sectionHelp"
    :help-collapsed="sectionHelp"
  >
    <template v-if="invoiceList.length > 0">
      <InvoiceRelatedList2 :dataList="invoiceList"/>
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";

import InvoiceRelatedList2 from "../../Invoice/RelatedList2/InvRL2.vue"

export default {
  components: {
    InvoiceRelatedList2,
  },
  props: {
    invoiceList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.invoiceList.length > 0;
    },
    sectionHelp() {
      return Model.Delete.getInvoiceSectionHelp(this.invoiceList);
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    }
  }
}
</script>