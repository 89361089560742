import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.invoice;

export default {
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditDateData(id, currentDate) {
    const params = { id, currentdate: currentDate }
    const attributes = ["id", "currentdate"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdatedata" + urlParams
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getExportDetails(id, params) {
    let options = { id: id };
    options = Object.assign(options, params);
    const attributes = ["id", "withdeliverylist", "withinvoicereplacelist",
      "withprofit", "withrecpaylist", "withrtninvoicelist",
      "withsalesrecapdetails", "withstockreductionlist"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails" + urlParams
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "ispos", "search", "field", "withitems"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "ispos", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "ispos", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);
    
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData(params) {
    const attributes = ["sales", "transaction"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata" + urlParams
    };
  },
  getPrintDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id
    };
  },
  getPrintFakturData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printfakturdata?id=" + id
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "ispos", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    }
  },
  getPrintThermalData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printthermaldata?id=" + id
    };
  },
  getRelatedClientAddressList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client-address/list" + urlParams
    };
  },
  getRelatedClientList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client/list" + urlParams
    };
  },
  getRelatedTransactionDetails(transactionId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/transaction/details?transaction=" + transactionId
    };
  },
  getRelatedTransactionList(params) {
    const attributes = ["warehouse", "startdate", "enddate", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/transaction/list" + urlParams
    };
  },
  getRelatedInvoicePriceList(params) {
    const attributes = ["item", "startdate", "enddate", "client", "clientname",
      "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/invoice-price/list" + urlParams
    };
  },
  getRelatedItemDetails(itemId, warehouseId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/details?item=" + itemId +
        "&warehouse=" + warehouseId
    };
  },
  getRelatedSalesDetails(salesId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/sales/details?sales=" + salesId
    };
  },
  getRelatedSalesList(params) {
    const attributes = ["warehouse", "startdate", "enddate", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/sales/list" + urlParams
    };
  },
  getRelatedSalesItemDetails(salesItemId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/sales-item/details?salesitem=" + salesItemId
    };
  },
  getRelatedStockList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stock/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  },
  putDate(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/date",
      data: data
    };
  }
}
