<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section class="is-half" :title="Catalog.ClientRecTotal.Label">
        <template #top-right>
          <kst-title>{{ recTotal }}</kst-title>
        </template>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ Catalog.ClientCreditAmount.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="ClientCreditAmount" :data="userData" :catalog="Catalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ Catalog.ClientOverDueDate.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="ClientOverDueDate" :data="userData" :catalog="Catalog"/>
            </div>
          </div>
        </div>
      </kst-section>
    </div>
    <div class="column is-half is-hidden-mobile"/>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    recTotal() {
      return this.$kst.Value.getValue("ClientRecTotal", this.userData, Catalog);
    }
  }
}
</script>