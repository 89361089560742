<template>
  <kst-table paginated view-mode
    :action="hasAccessAction"
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog" />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="CategoryID"
      :label="Catalog.CategoryID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog" />
    </b-table-column>

    <b-table-column sortable centered
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="IsActive"
      :label="Catalog.IsActive.Label"
    >
      <kst-status :value="row.IsActive" :statusEnum="Model.IsActive"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>
  </kst-table>
</template>
<script>
import Catalog from "../ExpcCatalog.js";
import Model from "../ExpcModel.js";

import Action from "./ExpcL_List_Action.vue";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    hasAccessAction() {
      return this.access.Act_Edit;
    }
  }
}
</script>