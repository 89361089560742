<template>
  <kst-section collapsible collapsed 
    :title="Info.Section.Purchase.Title"
    :help-collapsed="sectionHelp_collapsed"
    :help-expanded="Info.Message.New_Purchase"
  >
    <PurchaseSearch
      ref="mdlPurchaseSelect"
      :warehouseList="warehouseList"
      @submit="handleSubmit"
    />

    <kst-number-with-date search-mode
      :catalog="Catalog"
      :data="userData"
      dateField="PurchasingDate"
      numberField="PurchasingID"
      @search="handleSearch"
      @clear="handleClear"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import PurchaseSearch from "../../Purchase/SearchList/PurScL.vue";

export default {
  components: {
    PurchaseSearch
  },
  props: {
    userData: Object,
    warehouseOptions: Object,
    warehouseList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  }),
  computed: {
    sectionHelp_collapsed() {
      return Model.getPurchaseSectionHelp(this.userData);
    }
  },
  methods: {
    handleSubmit(row) {
      this.$emit(this.$kst.Enum.Event.Submit, row);
    },

    handleClear() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch() {
      this.$refs.mdlPurchaseSelect.show(
        Services.getRelatedPurchaseList,
        Services.getRelatedPurchaseDetails,
        this.warehouseOptions.rows
      );
    }
  }
}
</script>