<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Invoice :details="details"/>
        <Details :details="details"/>
        <Items :items="details.Items"/>
        <Total :details="details"/>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../DlvInfo.js";
import Model from "../DlvModel.js";
import Services from "../../../services/Api/DeliveryServices.js";

import Details from "./DlvD_Details.vue";
import Invoice from "./DlvD_Invoice.vue";
import Items from "./DlvD_Items.vue";
import Total from "./DlvD_Total.vue";

export default {
  components: {
    Details,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      for (const item of this.details.Items) {
        Model.updateItem(item);
      }

      Model.setDetailsByStatus(this.details);
    },

    /*** custom ***/

    resetData(id) {
      const access = this.$kst.Access.create(Model.Access);

      this.details = {};
      this.actions = Model.Details.getActions(id, access);
    }
  }
}
</script>