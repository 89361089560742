<template>
  <div class="kstmod-recpay-relatedlist">
    <template v-if="access.Act_New">
      <template v-if="!hideAction">
        <template v-if="data.InvoiceCreditAmountTrans > 0">
          <kst-info class="block" :items="recPayInfo"/>
        </template>
        <Action />
      </template>
      <template v-else>
        <kst-warning class="block" :items="recPayWarning"/>
      </template>
    </template>

    <List :access="access" :dataList="dataList"/>
  </div>
</template>

<script>
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";

import Action from "./RcpyRL_Action.vue";
import List from "./RcpyRL_List.vue";

export default {
  components: {
    Action,
    List
  },
  props: {
    hideAction: Boolean,
    data: Object,
    dataList: Array
  },
  data: () => ({
    access: {}
  }),
  computed: {
    recPayWarning() {
      return [Info.Message.RelatedList_NewRecPay];
    },
    recPayInfo() {
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0
      });

      return [
        Info.Message.RelatedList_NewRecPay_CreditAmount_1 + 
          formatter.format(this.data.InvoiceCreditAmountTrans) +
          Info.Message.RelatedList_NewRecPay_CreditAmount_2
      ];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>