import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./StcvModel_Body.js";
import ModelDetails from "./StcvModel_Details.js";
import ModelNew from "./StcvModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.StockConv,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportDetails: AppModuleType.ExportDetails,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  StockConvItem: ModelBody.StockConvItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  IsRaw: ModelBody.IsRaw,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setItemByStock(userItem, stockData, itemDetails) {
    ModelBody.setItemByStock(userItem, stockData, itemDetails);
  },
  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  updateDetailsByItemsFinish(userData, userItemsFinish) {
    ModelBody.updateDetailsByItemsFinish(userData, userItemsFinish);
  },
  updateDetailsByItemsRaw(userData, userItemsRaw) {
    ModelBody.updateDetailsByItemsRaw(userData, userItemsRaw);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(userItem) {
    ModelBody.updateItemByPackaging(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItemsByStock(userData, userItemsRaw) {
    ModelBody.validateItemsByStock(userData, userItemsRaw);
  },

  populateData(userData, userItemsRaw, userItemsFinish) {
    let result = ModelBody.populateDetails(userData);
    result.ItemsRaw = ModelBody.populateItems(userItemsRaw);
    result.ItemsFinish = ModelBody.populateItems(userItemsFinish);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },
    setItemsByStock(userItems, stockData) {
      ModelNew.setItemsByStock(userItems, stockData);
    },

    getItemInfoFinish(userItem) {
      return ModelNew.getItemInfoFinish(userItem);
    },
    getItemInfoRaw(userItem) {
      return ModelNew.getItemInfoRaw(userItem);
    },

    updateItemsByStockList(userItemsRaw, stockList) {
      ModelNew.updateItemsByStockList(userItemsRaw, stockList);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}