import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import DebtExL from "./ExportList/DebtExL.vue";
import DebtL from "./List/DebtL.vue";
import DebtPL from "./PrintList/DebtPL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.Debt, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Debt, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Debt, AppModuleType.ExportList),
        component: DebtExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Debt, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Debt),
          moduleattr: AppModuleAttr.Debt_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Debt, AppModuleType.List),
        name: RouterModel.getName(AppModule.Debt, AppModuleType.List),
        component: DebtL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Debt, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Debt),
          moduleattr: AppModuleAttr.Debt_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Debt),
            moduleattr: AppModuleAttr.Debt_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Debt),
            moduleattr: AppModuleAttr.Debt_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Debt, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Debt, AppModuleType.PrintList),
        component: DebtPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Debt, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Debt),
          moduleattr: AppModuleAttr.Debt_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}