import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StcvD from "./Details/StcvD.vue";
import StcvExD from "./ExportDetails/StcvExD.vue";
import StcvExL from "./ExportList/StcvExL.vue";
import StcvL from "./List/StcvL.vue";
import StcvN from "./New/StcvN.vue";
import StcvPD from "./PrintDetails/StcvPD.vue";
import StcvPL from "./PrintList/StcvPL.vue";

export default {
  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.Details),
        component: StcvD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.ExportDetails),
        component: StcvExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.ExportList),
        component: StcvExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.List),
        component: StcvL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.StockConv),
            moduleattr: AppModuleAttr.StockConv_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.StockConv),
            moduleattr: AppModuleAttr.StockConv_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.StockConv),
            moduleattr: AppModuleAttr.StockConv_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.New),
        component: StcvN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.New),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.StockConv, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.StockConv, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.PrintDetails),
        component: StcvPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockConv, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.StockConv, AppModuleType.PrintList),
        component: StcvPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockConv, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.StockConv),
          moduleattr: AppModuleAttr.StockConv_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}