<template>
  <kst-modal-search
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :width="1000"
    @init="handleModal_init"
    @load="handleModal_load"
    @reset="handleModal_reset"
    @submit="handleModal_submit"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :access="access"
        :dataList="data.ReceiveList"
        :userData="userData"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import Model from "../RcvModel.js";
import SearchCatalog from "./SearchCatalog.js";

import List from "./RcvScL_List.vue";
import Search from "./RcvScL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  props: {
    warehouseList: Array
  },
  data: () => ({
    Model: Model,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {},
    getDetailsService: null,
    getListService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getListService, getDetailsService) {
      this.getDetailsService = getDetailsService;
      this.getListService = getListService;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.resetData();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_reset(loadList) {
      this.resetData();
      this.handleModal_load(loadList);
    },

    handleModal_submit(selected, loadDetails) {
      if (this.getDetailsService) {
        const config = this.getDetailsService(selected.ID);
        loadDetails(config, this.handleModal_submit_success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit, selected);
        this.hideFunc();
      }
    },
    handleModal_submit_success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, data);
      this.hideFunc();
    },

    /*** custom ***/

    getListOptions() {
      return {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchListFields
      };
    },

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = Model.getWarehouseOptions(
        this.warehouseList, SearchCatalog.WarehouseID.Label
      );

      // searchData
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Search.OptionAll;
    }
  }
}
</script>