<template>
  <div class="block">
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="data"
      dateField="Date"
      numberField="ReductionNumber"
    />

    <kst-output field="TransactionID" :data="data" :catalog="Catalog">
      <template #left>
        <kst-output addons controlOff floating-label
          :label="Catalog.TransactionID.AltLabel"
          field="TransactionID" :data="data" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-output addons expanded floating-label
          field="TransactionDate"
          :data="data"
          :catalog="Catalog"
        />
      </template>
    </kst-output>
    <kst-output field="Comment" :data="data" :catalog="Catalog"/>
    <kst-created :data="data"/>
    <kst-output field="WarehouseID" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../StrdCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>