<template>
  <ValidationProvider
    v-slot="{ errors }"
    :bails="provider.Bails"
    :class="provider.Class"
    :immediate="provider.Immediate"
    :name="catalog[field].Label"
    :rules="validationRules"
    :tag="provider.Tag"
    :vid="vid"
  >
    {{ updateErrors(errors) }}
    <b-field :type="{ 'is-danger': errors[0] }">
      <template v-if="!errorOff" #message>
        <kst-list hide-empty :items="getErrors(errors)"/>
      </template>

      <slot name="left" />
      <div class="control">
        <cleave
          class="input"
          ref="myField"
          v-model="data[field]"
          :class="{ 'is-danger': errors[0] }"
          :disabled="theDisabled"
          :id="vid"
          :options="cleaveOptions"
          @input="handleInput"
        />
      </div>
      <slot name="right" />
    </b-field>
  </ValidationProvider>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    errorOff: Boolean,
    loadOnFocus: Boolean,
    vid: String,
    validationRules: {
      type: Object
    },
    // catalog
    provider: Object
  },
  computed: {
    cleaveOptions() {
      const options = {
        numeral: true,
        numeralDecimalMark: AppCatalog.Cleave.DecimalMark,
        delimiter: AppCatalog.Cleave.ThousandMark
      };

      if (this.catalog[this.field].MaxPrecision !== undefined) {
        options.numeralIntegerScale = this.catalog[this.field].MaxPrecision;
      }
      else if (this.catalog[this.field].MaxLength !== undefined) {
        if (this.catalog[this.field].MaxScale !== undefined) {
          options.numeralIntegerScale =
            this.catalog[this.field].MaxLength - this.catalog[this.field].MaxScale;
        }
        else {
          options.numeralIntegerScale =
            this.catalog[this.field].MaxLength - AppCatalog.Cleave.DefaultDecimalScale;
        }
      }

      if (this.catalog[this.field].MaxScale !== undefined) {
        options.numeralDecimalScale = this.catalog[this.field].MaxScale;
      }
      else {
        options.numeralDecimalScale = AppCatalog.Cleave.DefaultDecimalScale;
      }

      if (this.catalog[this.field].MinValue !== undefined) {
        if (this.catalog[this.field].MinValue >= 0) {
          options.numeralPositiveOnly = true;
        }
      }

      return options;
    },
    theDisabled() {
      if (this.disabled === undefined) {
        return this.catalog[this.field].ReadOnly;
      }
      return this.disabled;
    }
  },
  mounted() {
    if (this.loadOnFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.myField.$el.focus();
      });
    },

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    updateErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors);
    },

    getErrors(errors) {
      let result = [];

      for (const error of errors) {
        if (error !== this.$kst.Vee.ValidRule) {
          result.push(error);
        }
      }

      return result;
    }
  }
}
</script>