import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.SalesSelfService,
  ModuleType: {
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails
  },

  /*** related ***/

  SalesItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  Item: {
    Module: AppModule.Item
  },

  /*** property ***/

  /*** method ***/

  createDetails() {
    return this.helper.createDetails();
  },
  createItem() {
    return this.helper.createItem();
  },

  setDetailsByNewData(userData, clientData) {
    this.helper.setDetailsByNewData(userData, clientData);
  },

  setItemByItemData(userItem, itemData) {
    this.helper.setItemByItemData(userItem, itemData);
  },
  setItemErrors(item, field, errors) {
    item.Errors = App.Vee.getRowErrors(item, field, errors);
  },

  updateDetails(userData, userItems) {
    this.helper.updateDetails(userData, userItems);
  },
  updateItem(userItem) {
    this.helper.updateItem(userItem);
  },

  populateData(userData, userItems, serviceData) {
    let result = this.helper.populateDetails(userData, serviceData);
    result.Items = this.helper.populateItems(userItems);
    return result;
  },

  helper: {
    createDetails() {
      return {
        PONumber: "",
        ClientAddress: "",
        Comment: "",
        // defined by system
        Date: App.In.getDateToday(),
        Total: 0
      };
    },
    createItem() {
      return {
        // user input
        DispatchID: null,
        Qty: "",
        // defined by system
        DispatchName: null,
        SellPrice: "",
        SKU: null,
        Total: 0,
        // error
        Errors: [],
        ErrorsColl: []
      }
    },

    setDetailsByNewData(userData, clientData) {
      userData.ClientAddress = clientData.Address;
    },

    setItemByItemData(userItem, itemData) {
      userItem.DispatchID = itemData.ID;
      // defined by system
      userItem.DispatchName = itemData.Name;
      userItem.SellPrice = itemData.SellPricePcs;
      userItem.SKU = itemData.SKU;
    },

    updateDetails(userData, userItems) {
      userData.Total = 0;

      for (const item of userItems) {
        userData.Total += item.Total;
      }
    },
    updateItem(userItem) {
      userItem.Total = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
    },

    populateDetails(userData, serviceData) {
      return {
        Date: App.Out.getDateString(userData.Date),
        PONumber: App.Out.getString(userData.PONumber),
        ClientAddress: App.Out.getString(userData.ClientAddress),
        Comment: App.Out.getString(userData.Comment),
        // by system
        SelfServiceID: serviceData.SelfServiceID,
        SelfServicePin: serviceData.SelfServicePin
      }
    },
    populateItems(userItems) {
      let resultItems = [];

      for (const item of userItems) {
        resultItems.push({
          DispatchID: item.DispatchID,
          DispatchName: item.DispatchName,
          Qty: App.Out.getInteger(item.Qty),
          SellPrice: item.SellPrice
        });
      }

      return resultItems;
    }
  }
}