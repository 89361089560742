<template>
  <kst-page-print-details page-footer
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column">
        <Details :details="details"/>
      </div>
      <div class="column">
        <Details2 :details="details"/>
      </div>
    </div>

    <InvoiceList class="block" :invoiceList="invoiceList"/>
    <Total class="block" :details="details"/>
  </kst-page-print-details>
</template>

<script>
import Model from "../SlrcModel.js";
import Services from "../../../services/Api/SalesRecapServices.js";

import Details from "./SlrcPD_Details.vue";
import Details2 from "./SlrcPD_Details2.vue";
import InvoiceList from "./SlrcPD_InvoiceList.vue";
import Total from "./SlrcPD_Total.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Company,
    Details,
    Details2,
    InvoiceList,
    Total
  },
  data: () => ({
    details: {},
    companyData: {},
    invoiceList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintDetailsData(id);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
      this.companyData = data.CompanyDetails;
      this.invoiceList = data.InvoiceList;
      Model.setDetailsByPaymentTypeCash(this.details, data.PaymentTypeCash);
    },

    /*** custom ***/

    resetData() {
      this.details = {};
      this.companyData = {};
      this.invoiceList = [];
    }
  }
}
</script>