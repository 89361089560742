<template>
  <div class="columns is-mobile">
    <div class="column is-narrow">
      <kst-print-sign :header="seller">
        <template #footerTitle>
          <div class="mt-1 ml-4">
            {{ footerTitle }}
          </div>
        </template>
      </kst-print-sign>
    </div>
    <div class="column is-narrow">
      <kst-print-sign :header="client">
        <template #footerTitle>
          <div class="mt-1 ml-4">
            {{ footerTitle }}
          </div>
        </template>
      </kst-print-sign>
    </div>
  </div>
</template>

<script>
import Info from "../InvInfo.js";

export default {
  computed: {
    footerTitle() {
      return Info.Section.PrintFaktur.Sign.FooterTitle;
    },
    client() {
      return Info.Section.PrintFaktur.Client.Header;
    },
    seller() {
      return Info.Section.PrintFaktur.Seller.Header;
    }
  }
}
</script>