import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./UserCatalog.js";
import Enum from "./UserEnum.js";

export default {
  // required in model
  Module: AppModule.UserList,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    EditPassword: AppModuleType.EditPassword,
    List: AppModuleType.List,
    New: AppModuleType.New
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.User_Act_Details,
    Act_Edit: AppModuleAttr.User_Act_Edit,
    Act_EditPassword: AppModuleAttr.User_Act_EditPassword,
    Act_List: AppModuleAttr.User_Act_List,
    Act_New: AppModuleAttr.User_Act_New
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.EditPassword
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.EditPassword
    ]
  },

  Search: {
    Fields: ["Name"]
  },

  IsActive: Enum.IsActive,

  /*** method ***/

  createDetails(isActiveEnum) {
    return {
      ID: null,
      Name: "",
      Email: "",
      Password: "",
      Phone: "",
      AccessID: null,
      AccessName: "",
      DefaultModuleID: null,
      DefaultWarehouseID: null,
      BackDateInterval: 0,
      IsActive: App.In.getBoolean(isActiveEnum.Active.ID),
      // defined by system
      AccessIsOwner: false,
      IsReachLimit: false
    };
  },
  createOptions(dataList, optionAllText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  createAccessOptions(items, optionAllText){
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createDefaultModuleOptions(items) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createIsActiveOptions(optionAllText) {
    const rowId = "ID";
    const rowLabel = "Name";
    const items = [
      { [rowId]: Enum.IsActive.Active.ID, [rowLabel]: Enum.IsActive.Active.Label },
      { [rowId]: Enum.IsActive.InActive.ID, [rowLabel]: Enum.IsActive.InActive.Label }
    ];
    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectRows = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },

  getSubtitle(activeUsers, maxUsers) {
    return Catalog.ActiveUsers.Label + ": " + activeUsers + ", " +
      Catalog.MaxUsers.Label + ": " + (!App.Data.isUnlimitedUser(maxUsers)
        ? maxUsers
        : AppCatalog.Message.Unlimited
      );
  },
  getSectionHelp(details) {
    return Catalog.Name.Label + ": " + App.Value.getValue("Name", details, Catalog);
  },

  populateDetails(data) {
    return {
      ID: data.ID,
      Name: App.Out.getString(data.Name),
      Email: App.Out.getString(data.Email),
      Password: App.Out.getString(data.Password),
      Phone: App.Out.getString(data.Phone),
      AccessID: data.AccessID,
      DefaultModuleID: data.DefaultModuleID,
      DefaultWarehouseID: data.DefaultWarehouseID,
      BackDateInterval: App.Out.getInteger(data.BackDateInterval),
      IsActive: App.Out.getBoolean(data.IsActive)
    };
  },

  validationPassword() {
    return {
      [App.Vee.Rule.ValidPassword]: true
    }
  }
}