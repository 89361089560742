import App from "../../services/App/App.js";
import ModelBody from "./SttrModel_Body.js";
import ModelDetails from "./SttrModel_Details.js";
import ModelNew from "./SttrModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  StockTrfItem: ModelBody.StockTrfItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  IsActive: ModelBody.IsActive,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(userItem) {
    ModelBody.updateItemByPackaging(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItemsByStock(userData, userItems) {
    ModelBody.validateItemsByStock(userData, userItems);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },

    setItemByItemDetails(userItem, itemData) {
      ModelNew.setItemByItemDetails(userItem, itemData);
    },
    setItemByStock(userItem, stockData) {
      ModelNew.setItemByStock(userItem, stockData);
    },

    getItemInfo(userItem) {
      return ModelNew.getItemInfo(userItem);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  }
}