import App_JavaScript from "./App_JavaScript.js";
import App_Value from "./App_Value.js";
import InputType from "../Input/InputType.js";

const numberTypeList = [
  InputType.Decimal,
  InputType.ID,
  InputType.Integer,
  InputType.Percent,
  InputType.Qty
];

/**
 * Convert:
 * - from: value (source: API), or nothing
 * - to: value (target: input box)
 */
export default {
  isNumberType(inputType) {
    return numberTypeList.includes(inputType);
  },

  /*** date ***/

  getDate(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newDate = new Date(value);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  },
  getDateFirstDay() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  },
  getDateFirstDayLastMonth() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth() - 1, 1);
  },
  getDateLast30Day() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29);
  },
  getDateLastDay() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
  },
  getDateLastDayLastMonth() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 0);
  },
  getDateLastWeek() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
  },
  getDateToday() {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  },
  getDateYesterday() {
    let today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  },
  getLastMonthDay() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    //dateRange.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6));
    dateRange.push(new Date(today.getFullYear(), today.getMonth() - 1, 1));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },
  getThreeMonthDay() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    //dateRange.push(new Date(today.getFullYear(), today.getMonth() - 3), today.getDate());
    dateRange.push(new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },
  getOneMonthDateRange() {
    const dateRange = [];
    const today = new Date();
    const lastDateLastMonth = this.getDateLastDayLastMonth().getDate();
    dateRange.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - lastDateLastMonth));
    today.setHours(0, 0, 0, 0);
    dateRange.push(today);
    return dateRange;
  },

  /*** datetime ***/

  getDateTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newDate = new Date(value);
    return newDate;
  },

  /*** time ***/
  
  getTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    const time = value.split(':');
    const newDate = new Date();
    
    newDate.setHours(
      parseInt(time[0]),
      parseInt(time[1] || 0),
      parseInt(time[2] || 0)
    );

    return newDate;
  },

  /*** default ***/

  getDefaultDateRange() {
    let dateRange = [];

    // source: getDateLastWeek
    let today = new Date();
    dateRange.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6));
    // dateRange.push(new Date(2020, 9, 1));

    today.setHours(0, 0, 0, 0);
    dateRange.push(today);

    return dateRange;
  },

  /*** others ***/

  getBoolean(value) {
    // if, else: boolean in string
    if (value === "true") {
      return true;
    }
    else if (value === "false") {
      return false;
    }

    const newValue = isNaN(value) ? value : Number(value);
    return newValue ? true : false;
  },
  getDecimal(value) {
    const catalog = {
      Decimal: { Type: InputType.Percent }
    };
    const data = { Decimal: value };
    return App_Value.getValue("Decimal", data, catalog);
  },
  getInteger(value, defaultValue) {
    if (value === undefined || value === null) {
      return defaultValue;
    }

    const newValue = App_JavaScript.parseInt(value, defaultValue);

    return newValue === undefined || newValue === null
      ? newValue : newValue.toString();
  },
  getSelectOptions(valueKey, labelKey, items) {
    return {
      value: valueKey,
      label: labelKey,
      rows: items
    };
  },
  getString(value) {
    if (value === undefined || value === null) {
      return "";
    }
    return value;
  }
}