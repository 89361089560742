import AppModuleAttr from "../../services/App/AppModuleAttr.js";

export default {
  /*** related ***/

  /*** property ***/

  Access: {
    Act_ExportList: AppModuleAttr.DebtCard_Act_Export,
    Act_List: AppModuleAttr.Debt_Act_List,
    Act_PrintList: AppModuleAttr.Debt_Act_PrintList,
    // related
    Receive_Act_Details: AppModuleAttr.Receive_Act_Details
  },

  Search: {
    Fields: ["ReceiveNumber"]
  },

  /*** method ***/
}