import RouterModel from "@/router/RouterModel.js";
import AppModule from "@/services/App/AppModule.js";
import ReportStockAdj from "./StockAdj";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.ReportStockAdj)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.ReportStockAdj),
        name: RouterModel.getName(AppModule.ReportStockAdj),
        component: ReportStockAdj,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ReportStockAdj),
          module: RouterModel.getModule(AppModule.ReportStockAdj)
        }
      }
    ];
  }
}