<template>
  <kst-page-export
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check
      v-model="userData.WithReceivables"
      :label="Info.Export.List.Excel.Option.Receivables"
    />
    <kst-check
      v-model="userData.WithAddress"
      :label="Info.Export.List.Excel.Option.ClientAddress"
    />
    <kst-check v-if="access.Sect_SelfService"
      v-model="userData.WithSelfService"
      :label="Info.Export.List.Excel.Option.SelfService"
    />
  </kst-page-export>
</template>

<script>
import Info from "../CliInfo.js";
import Model from "../CliModel.js";
import SearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/ClientServices.js";

import Export from "./CliExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    SearchCatalog: SearchCatalog,
    access: {},
    searchData: {
      Search: ""
    },
    searchParams: ["Search"],
    userData: {
      WithList: true,
      WithAddress: true,
      WithReceivables: true,
      WithSelfService: true
    },
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      for (let row of data.List) {
        if (row.SelfServiceID) {
          Model.SelfService.updateData(row);
        }
      }

      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithList = true;
      this.userData.WithAddress = true;
      this.userData.WithReceivables = true;
      this.userData.WithSelfService = this.access.Sect_SelfService;
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields,
        withaddress: this.$kst.Out.getBoolean(this.userData.WithAddress),
        withreceivables: this.$kst.Out.getBoolean(this.userData.WithReceivables)
      };
    }
  }
}
</script>