<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Invoice :deliveryData="deliveryData"/>
    <Details :deliveryData="deliveryData"/>
    <Items :deliveryData="deliveryData"/>
    <Total :deliveryData="deliveryData"/>
  </kst-section>
</template>

<script>
import Info from "../DlvInfo.js";
import Model from "../DlvModel.js";

import Details from "./DlvES_Delivery_Details.vue";
import Invoice from "./DlvES_Delivery_Invoice.vue";
import Items from "./DlvES_Delivery_Items.vue";
import Total from "./DlvES_Delivery_Total.vue";

export default {
  components: {
    Details,
    Invoice,
    Items,
    Total
  },
  props: {
    deliveryData: Object
  },
  computed: {
    sectionHelp() {
      return Model.getDeliverySectionHelp(this.deliveryData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>