<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Details :details="details"/>
    <TransactionSetting :details="details"/>
  </kst-section>
</template>

<script>
import Info from "../UserInfo.js";
import Model from "../UserModel.js";

import Details from "./UserEPwd_User_Details.vue";
import TransactionSetting from "./UserEPwd_User_TransactionSetting.vue";

export default {
  components: {
    Details,
    TransactionSetting
  },
  props: {
    details: Object
  },
  computed: {
    sectionHelp() {
      return Model.getSectionHelp(this.details);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>