import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.HppAdj,
  ModuleType: {
    Details: AppModuleType.Details,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  HppAdjItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.HppAdj_Act_Details,
    Act_List: AppModuleAttr.HppAdj_Act_List,
    Act_New: AppModuleAttr.HppAdj_Act_New,
    Act_PrintDetails: AppModuleAttr.HppAdj_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.HppAdj_Act_PrintList
  },

  Actions:{
    Details: [
      AppModuleType.PrintDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails
    ]
  },

  Search: {
    ListFields: ["AdjNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: null,
      AdjNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // by system
      Date: App.In.getDateToday()
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      HppPerPcs: "",
      // by system
      DispatchName: "",
      SKU: "",
      DispatchSellPrice: "",
      Stock: 0,
      HppTotal: 0,
      Packaging: "",
      PackagingName: "",
      PackagingValue: 1,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  updateItem(item) {
    item.HppTotal = App.Data.getHppTotal(item.Stock, item.HppPerPcs);
  },
  updateItemByPackaging(item) {
    item.Packaging = App.Data.getPackaging(
      item.PackagingName, item.PackagingValue
    );
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItems(userItems, row) {
    for (const item of userItems) {
      if (item.DispatchID === row.ItemID) {
        return false;
      }
    }

    return true;
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      AdjNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.AdjNumber),
      Comment: App.Out.getString(userData.Comment),
      Date: App.Out.getDateString(userData.Date)
    }
  },
  populateItems(items) {
    let resultItems = [];
    for (const item of items) {
      resultItems.push({
        DispatchID: item.DispatchID,
        DispatchName: item.DispatchName,
        HppPerPcs: App.Out.getDecimal(item.HppPerPcs)
      });
    }

    return resultItems;
  }
}