<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdatetime"
      field="CreatedDate"
      :label="Catalog.CreatedDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="TypeID"
      :label="Catalog.TypeID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="TransactionNumber"
      :label="Catalog.TransactionNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="TransWarehouseID"
      :label="DbcdTransCatalog.TransWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReceiveID"
      :label="DbcdTransCatalog.ReceiveID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="ReceiveDate"
      :label="DbcdTransCatalog.ReceiveDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ReceiveDebtChanges"
      :label="DbcdTransCatalog.ReceiveDebtChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ReceiveDebtAfter"
      :label="DbcdTransCatalog.ReceiveDebtAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VendorID"
      :label="DbcdTransCatalog.VendorID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="VendorDebtChanges"
      :label="DbcdTransCatalog.VendorDebtChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="VendorDebtAfter"
      :label="DbcdTransCatalog.VendorDebtAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="DbcdTransCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../DbcdCatalog.js";
import DbcdTransCatalog from "../DbcdTransCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    DbcdTransCatalog: DbcdTransCatalog
  })
}
</script>