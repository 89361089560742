<template>
  <kst-section 
    :title="sectionTitle"
    :help="sectionHelp"
  >
    <ReceiveSearchList status
      ref="mdlReceiveSearchList"
      :warehouseList="warehouseList"
      @submit="handleSubmit"
    />
    <kst-number-with-date search-mode
      :catalog="Catalog"
      :data="userData"
      dateField="ReceiveDate"
      numberField="ReceiveID"
      @search="handleSearch"
      @clear="handleClear"
    />
    <kst-input class="is-hidden"
      field="ReceiveID" :data="userData" :catalog="Catalog"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RrcvCatalog.js";
import Info from "../RrcvInfo.js";
import Services from "../../../services/Api/ReturnReceiveServices.js";

import ReceiveSearchList from "../../Receive/SearchList/RcvScL.vue";

export default {
  components: {
    ReceiveSearchList
  },
  props: {
    userData: Object,
    warehouseList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionHelp() {
      return this.userData.ReceiveID
        ? Info.Message.New_Receive
        : Info.Message.New_ReceiveBlank;
    },
    sectionTitle() {
      return Info.Section.Receive.Title;
    }
  },
  methods: {
    handleSubmit(row) {
      this.$emit(this.$kst.Enum.Event.Submit, row);
    },
    handleClear() {
      this.$emit(this.$kst.Enum.Event.Reset);
    },
    handleSearch() {
      this.$refs.mdlReceiveSearchList.show(
        Services.getRelatedReceiveList,
        Services.getRelatedReceiveDetails
      );
    }
  }
}
</script>