<template>
  <div class="kstmod-debtpay-relatedlist">
    <template v-if="access.Act_New">
      <template v-if="!hideAction">
        <Action />
      </template>
      <template v-else>
        <kst-warning class="mb-4" :items="debtPayWarning"/>
      </template>
    </template>

    <Row :access="access" :items="items"/>
  </div>
</template>

<script>
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";

import Action from "./DbpyRL_Action.vue";
import Row from "./DbpyRL_Row.vue";

export default {
  components: {
    Action,
    Row
  },
  props: {
    hideAction: Boolean,
    items: Array,
    // field visibility (order by name)
    clientField: Boolean,
    warehouseField: Boolean
  },
  data: () => ({
    access: {}
  }),
  computed: {
    debtPayWarning() {
      return [Info.Message.RelatedList_NewDebtPay];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>