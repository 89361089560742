<template>
  <div>
    <template v-if="hasDisc">
      <div class="columns is-mobile">
        <div class="column is-4 is-hidden-mobile"/>
        <div class="column pb-0 is-narrow">
          <div class="title is-5">
            {{ Catalog.TotalBruto.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right">
            <kst-value class="title is-5"
              field="TotalBruto" :data="userData" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="userData.DiscType === Model.DiscType.Value.ID"
        class="columns is-mobile"
      >
        <div class="column is-4 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5">
            {{ Catalog.DiscValue.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5"
              field="DiscValue" :data="userData" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="userData.DiscType === Model.DiscType.Percent.ID"
        class="columns is-mobile"
      >
        <div class="column is-4 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5 is-flex">
            {{ Catalog.DiscPercent.PrintLabel }} (
              <kst-value class="title is-5"
                field="DiscPercent" :data="userData" :catalog="Catalog"
              />
            )
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5"
              field="TotalDisc" :data="userData" :catalog="Catalog"
            />
          </div>
        </div>
      </div>
    </template>

    <div class="columns is-mobile">
      <div class="column is-4 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5"
            field="Total" :data="userData" :catalog="Catalog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    hasDisc() {
      return this.userData.DiscType !== Model.DiscType.None.ID;
    }
  }
}
</script>