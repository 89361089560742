<template>
  <kst-table paginated view-mode
    :data="dataList"
    :selected.sync="userData.selected"
  >
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-client"
      field="ClientID"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-ref"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="status">
      <b-table-column centered sortable
        v-slot="{ row }"
        cell-class="ks-col-status"
        field="Status"
        :label="Catalog.Status.Label"
      >
        <kst-status :value="row.Status" :statusEnum="Model.Status"/>
      </b-table-column>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";

export default {
  props: {
    dataList: Array,
    userData: Object,
    // field visibility (alphabetical order)
    status: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>