<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="InvItemCatalog.DispatchID.PrintLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku-large"
      field="SKU"
      :label="InvItemCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="RequestedQuantity"
      :label="InvItemCatalog.RequestedQuantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="InvItemCatalog"/>
      </kst-value>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="InvItemCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>

    <template v-if="details.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-specialpricename"
        field="SpecialPriceID"
      >
        <template v-if="row.SpecialPriceID !== null">
          <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
        </template>
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Price"
      :label="InvItemCatalog.Price.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import InvItemCatalog from "../InvItemCatalog.js";

export default {
  props: {
    details: Object,
    items: Array
  },
  data: () => ({
    InvItemCatalog: InvItemCatalog
  })
}
</script>