<template>
  <kst-modal-input hideFormSuccess
    class="Info.Page.VerifySpvPass.Class"
    :title="Info.Page.VerifySpvPass.Title"
    :width="500"
    @init="handleInit"
    @submit="handleSubmit"
  >
    <kst-input load-on-focus
      field="spv_passwd" :data="userData" :catalog="Catalog"
    />
  </kst-modal-input>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

export default {
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    userData: {},
    getRelatedService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getRelatedService) {
      this.getRelatedService = getRelatedService;
      this.init_Data();
      this.showFunc();
    },

    /*** by modal ***/

    handleInit(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_Data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleSubmit(saveData) {
      if (this.getRelatedService) {
        const data = Model.populateSpvVerifyData(this.userData);
        const config = this.getRelatedService(data);
        saveData(config, this.handleSubmit_Success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit);
        this.hideFunc();
      }
    },
    handleSubmit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, data);
      this.hideFunc();
    },

    /*** custom ***/

    init_Data() {
      this.userData = Model.createSpvDetails();
    }
  }
}
</script>