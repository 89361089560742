<template>
  <kst-modal-input
    :module="Info.Modal.VendorItem.Module"
    :moduleType="Info.Modal.VendorItem.ModuleType"
    :width="950"
    @init="handleInit"
    @submit="handleSubmit"
  >
    <List :userVendorItemList="userVendorItemList" />
  </kst-modal-input>
</template>

<script>
import Info from "../VenInfo.js";
import Model from "../VenModel.js";
import Services from "../../../services/Api/VendorService.js";

import List from "./VenD_VendorItemList_Input_List.vue";

export default {
  components: {
    List
  },
  props: {
    details: Object,
    userVendorItemList: Array
  },
  data: () => ({
    Info: Info,
    // func
    hideFunc: null,
    resetFailurePageNewFunc: null,
    showFunc: null
}),
  methods: {
    /*** public method ***/

    show() {
      this.showFunc();
      this.resetFailurePageNewFunc();
    },

    /*** by modal ***/

    handleInit(show, hide, resetFailure) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.resetFailurePageNewFunc = resetFailure;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleSubmit(saveData) {
      let data = Model.populateVendorItemData(
        this.details.ID, this.userVendorItemList);
      const config = Services.postVendorItem(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, data);
      this.hideFunc();
    }
  }
}
</script>