import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StclD from "./Details/StclD.vue";
import StclExD from "./ExportDetails/StclExD.vue";
import StclExL from "./ExportList/StclExL.vue";
import StclL from "./List/StclL.vue";
import StclN from "./New/StclN.vue";
import StclPD from "./PrintDetails/StclPD.vue";
import StclPL from "./PrintList/StclPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.StockClosing, AppModuleType.Details),
    ExportDetails: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.SalesRecap, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List),
    New: RouterModel.getPath(AppModule.StockClosing, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintDetails),
    PrintList: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.Details),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.Details),
        component: StclD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.ExportDetails),
        component: StclExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.ExportList),
        component: StclExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.List),
        component: StclL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.StockClosing),
            moduleattr: AppModuleAttr.StockClosing_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.StockClosing),
            moduleattr: AppModuleAttr.StockClosing_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.StockClosing),
            moduleattr: AppModuleAttr.StockClosing_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.New),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.New),
        component: StclN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.New),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.StockClosing, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ]
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.PrintDetails),
        component: StclPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.StockClosing, AppModuleType.PrintList),
        component: StclPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockClosing, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.StockClosing),
          moduleattr: AppModuleAttr.StockClosing_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}