import AppCatalog from "./AppCatalog.js";
import App_Value_Body from "./App_Value_Body.js";

export default {
  // returns {OutputTypeEnum}
  getType(field, catalog) {
    return App_Value_Body.getType(field, catalog);
  },
  getValue(field, data, catalog, isTooltip) {
    if (catalog[field].IsRange) {
      const fieldMin = catalog[field].RangeMin;
      const fieldMax = catalog[field].RangeMax;

      const valueMin = data[field][fieldMin];
      const valueMax = data[field][fieldMax];

      if (valueMin === valueMax) {
        return App_Value_Body.getValue_Single(valueMin, field, data, catalog);
      }
      else {
        return App_Value_Body.getValue_Single(valueMin, field, data, catalog) +
          " " + AppCatalog.Format.ValueRangeSeparator + " " +
          App_Value_Body.getValue_Single(valueMax, field, data, catalog);
      }
    }
    else {
      let value;

      if (isTooltip) {
        value = data[field];
      }
      else {
        if (catalog[field].Highlight) {
          if (data["highlight"]) {
            if (catalog[field].RelatedValue) {
              return data["highlight"][catalog[field].RelatedValue];
            }
            else if (Object.prototype.hasOwnProperty.call(data["highlight"], field)) {
              return data["highlight"][field];
            }
            else {
              value = data[field];
            }
          }
          else {
            value = data[field];
          }
        }
        else if (this.isUseNullText(field, data, catalog)) {
          return catalog[field].NullText;
        }
        else {
          value = data[field];
        }
      }

      return App_Value_Body.getValue_Single(value, field, data, catalog);
    }
  },
  getValueOutput(field, data, catalog, isTooltip) {
    if (catalog[field].IsRange) {
      const fieldMin = catalog[field].RangeMin;
      const fieldMax = catalog[field].RangeMax;

      const valueMin = data[field][fieldMin];
      const valueMax = data[field][fieldMax];

      if (valueMin === valueMax) {
        return App_Value_Body.getValue_Single(valueMin, field, data, catalog);
      }
      else {
        return App_Value_Body.getValue_Single(valueMin, field, data, catalog) +
          " " + AppCatalog.Format.ValueRangeSeparator + " " +
          App_Value_Body.getValue_Single(valueMax, field, data, catalog);
      }
    }
    else {
      let value;

      if (isTooltip) {
        value = data[field];
      }
      else {
        if (this.isUseNullText(field, data, catalog)) {
          return catalog[field].NullText;
        }
        else {
          value = data[field];
        }
      }

      return App_Value_Body.getValue_Single(value, field, data, catalog);
    }
  },
  getValueRaw(field, data, catalog) {
    if (catalog[field].IsRange) {
      const fieldMin = catalog[field].RangeMin;
      const fieldMax = catalog[field].RangeMax;

      const valueMin = data[field][fieldMin];
      const valueMax = data[field][fieldMax];

      if (valueMin === valueMax) {
        return App_Value_Body.getValue_Raw(valueMin);
      }
      else {
        return App_Value_Body.getValue_Raw(valueMin) +
          " " + AppCatalog.Format.ValueRangeSeparator + " " +
          App_Value_Body.getValue_Raw(valueMax);
      }
    }
    else {
      let value;

      if (this.isUseNullText(field, data, catalog)) {
        return catalog[field].NullText;
      }
      else {
        value = data[field];
      }

      return App_Value_Body.getValue_Raw(value, field, data, catalog);
    }
  },
  isUseNullText(field, data, catalog) {
    return (catalog[field].NullText && data[field] === null);
  }
}