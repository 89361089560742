<template>
  <div class="block">
    <kst-section confirm-mode>
      <kst-warning class="mb-4" :items="detailsWarning"/>

      <kst-input field="DraftNumberConfirm" :data="userData" :catalog="Catalog"
        :customValidations="draftNumberValidations"
      />
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../SlsCatalog.js";
import Model from "../SlsModel.js";

export default {
  props: {
    salesData: Object,
    invoiceList: Array,
    rtnInvoiceList: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return Model.Delete.getMessageConfirmationSection(
        this.invoiceList, this.rtnInvoiceList
      );
    },
    draftNumberValidations() {
      return Model.Delete.validationDraftNumber(this.salesData);
    }
  }
}
</script>