import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import {
  alpha_spaces,
  between,
  email,
  integer,
  is,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  required,
  required_if
} from "vee-validate/dist/rules";
import DateCompare from "./custom_validators/DateCompare";
import API from "./custom_validators/API";
import Collection from "./custom_validators/Collection";
import Single from "./custom_validators/Single";
import App from "../services/App/App.js";
import AppVeeValidate from "../services/App/App_VeeValidate.js";
import moment from "moment";

extend("alpha_spaces", {
  ...alpha_spaces,
  message: "{_field_} harus berisi karakter alfabet atau spasi",
});

extend("between", {
  ...between,
  message: "{_field_} harus diantara {min} dan {max}",
});

extend("email", {
  ...email,
  message: "{_field_} harus dengan format email yang benar",
});

extend("integer", {
  ...integer,
  message: "{_field_} harus tanpa desimal",
});

extend("is", {
  ...is,
  params: ["target", "targetLabel", "isNumber"],
  validate: (value, { target, isNumber }) => {
    return isNumber
      ? Number(value) === Number(target)
      : value === target;
  },
  message: (fieldName, { target, targetLabel }) => {
    return App.Vee.Message.getIs(fieldName, target, targetLabel);
  }
});

extend("length", {
  ...length,
  message: "{_field_} harus {length} karakter",
});

extend("max", {
  ...max,
  message: "Maximum {_field_} adalah {length} karakter",
});

extend("max_value", {
  ...max_value,
  params: ["max", "targetLabel"],
  message: (fieldName, { max, targetLabel }) => {
    const newMax = App.Format.getDecimal(max);
    const newTargetLabel = targetLabel ? " (" + targetLabel + ")" : "";
    return `Maximum nilai ${fieldName} adalah ${newMax}${newTargetLabel}`;
  }
});

extend("min", {
  ...min,
  message: "Minimum {_field_} adalah {length} karakter",
});

extend("min_value", {
  ...min_value,
  params: ["min", "targetLabel"],
  message: (fieldName, { min, targetLabel }) => {
    const newMin = App.Format.getDecimal(min);
    const newTargetLabel = targetLabel ? " (" + targetLabel + ")" : "";
    return `Minimum nilai ${fieldName} adalah ${newMin}${newTargetLabel}`;
  }
});

extend("numeric", {
  ...numeric,
  message: "{_field_} harus berisi angka",
});

extend("required", {
  ...required,
  message: "{_field_} wajib diisi",
});

extend("required_if", required_if);

/*** API ***/

extend("active", {
  params: ["target"],
  validate: (value, { target }) => {
    return API.isActive(target);
  },
  message: "{_field_} tidak aktif"
});

extend("exist", {
  params: ["target"],
  validate: (value, { target }) => {
    return API.isExist(target);
  },
  message: "{_field_} tidak ditemukan"
});

extend("static", {
  params: ["target"],
  validate: (value, { target }) => {
    return API.isStatic(target);
  },
  message: "{_field_} tidak boleh diubah"
});

extend("valid", {
  params: ["target"],
  validate: (value, { target }) => {
    return API.isValid(target);
  },
  message: AppVeeValidate.ValidRule //"{_field_} tidak sesuai"
});

/*** Custom ***/

extend("unequal", {
  params: ["target", "label"],
  validate: (value, { target }) => {
    return Single.isUnequal(value, target);
  },
  message: "{_field_} tidak boleh sama dengan {label}"
});

extend("min_date", {
  params: ["target", "label", "related"],
  validate: (value, { target }) => {
    return Single.minDate(value, target);
  },
  message: (fieldName, { target, label }) => {
    const newMinDate = App.Format.getShortDate(target);
    const newLabel = label ? "(" + label + ")" : "";
    return `Minimum nilai ${fieldName} adalah ${newMinDate} ${newLabel}`;
  }
});

extend("max_date", {
  params: ["target", "label", "related"],
  validate: (value, { target }) => {
    return Single.maxDate(value, target);
  },
  message: (fieldName, { target, label }) => {
    const newMinDate = App.Format.getShortDate(target);
    const newLabel = label ? "(" + label + ")" : "";
    return `Maximum nilai ${fieldName} adalah ${newMinDate} ${newLabel}`;
  }
});

extend("unique", {
  params: ["target"],
  validate: (value, { target }) => {
    return Collection.isUnique(value, target);
  },
  message: "{_field_} telah dipakai"
});

extend("valid_password", {
  params: null,
  validate: (value) => {
    return Single.isValidPassword(value);
  },
  message: "{_field_} harus terdiri dari huruf, angka, dan symbol"
});

/*** Date ***/

extend("is_earlier", {
  validate: (value, { compare }) => {
    return DateCompare({ value, compare, validationType: "earlier" });
  },
  params: ["compare", "targetDate"],
  message: (fieldName, { compare, targetDate }) => {
    return `${fieldName} harus lebih baru dari ${targetDate} (${moment(
      compare
    ).format("DD-MM-YYYY")})`;
  },
});

extend("is_beyond", {
  validate: (value, { compare }) => {
    return DateCompare({ value, compare, validationType: "beyond" });
  },
  params: ["compare", "targetDate"],
  message: (fieldName, { compare, targetDate }) => {
    return `${fieldName} harus lebih lama dari ${targetDate} (${moment(
      compare
    ).format("DD-MM-YYYY")})`;
  },
});

const ValidationUtils = {
  install(Vue) {
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
  },
};

export default ValidationUtils;