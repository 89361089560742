<template>
  <kst-section items-mode>
    <SpecialPriceSelect
      :items="specialPriceSelection"
      @init="handleSpecialPrice_Init"
      @submit="handleSpecialPrice_Submit"
    />
    <StockSearchPcs sell-price
      ref="mdlStockSearch"
      @submit="handleStock_Submit"
    />
    <StockSpecialPriceSearch sell-price
      @init="handleStockSpecialPrice_Init"
      @submit="handleStockSpecialPrice_Submit"
    />

    <template #top-left>
      <kst-button slim-size reset-mode @click="handleReset"/>
    </template>

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <div class="buttons">
          <template v-if="userData.SpecialPriceCount > 0">
            <kst-button action-mode
              class="is-primary"
              :label="Model.SpecialPrice.Module.FullName"
              :moduleType="Model.SpecialPrice.ModuleType.New"
              @click="handleAdd_SpecialPrice"
            />
          </template>

          <kst-button action-mode
            class="is-primary"
            :label="Model.StockReductionItem.Module.FullName"
            :moduleType="Model.StockReductionItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </div>
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <div class="buttons">
          <template v-if="userData.SpecialPriceCount > 0">
            <kst-button action-mode
              class="is-primary"
              :label="Model.SpecialPrice.Module.FullName"
              :moduleType="Model.SpecialPrice.ModuleType.New"
              @click="handleAdd_SpecialPrice"
            />
          </template>

          <kst-button action-mode
            class="is-primary"
            :label="Model.StockReductionItem.Module.FullName"
            :moduleType="Model.StockReductionItem.ModuleType.New"
            @click="handleAdd_Item"
          />
        </div>
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List :userData="userData" :userItems="userItems"/>

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="mt-4" :items="invalidItems"/>
    </template>
  </kst-section>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Catalog from "../StrdCatalog.js";
import Info from "../StrdInfo.js";
import Model from "../StrdModel.js";
import Services from "../../../services/Api/StockReductionServices.js";

import List from "./StrdN_Items_List.vue";
import StockSpecialPriceSearch from "../../StockSpecialPrice/SearchList/StSpScL.vue";
import SpecialPriceSelect from "../../SpecialPrice/Select/SpSe.vue";
import StockSearchPcs from "../../Stock/SearchPcs/StkScP.vue";

export default {
  mixins: [DialogMixin, SnackbarMixin],
  components: {
    List,
    StockSpecialPriceSearch,
    SpecialPriceSelect,
    StockSearchPcs
  },
  props: {
    userData: Object,
    userItems: Array,
    specialPriceList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model,
    specialPriceSelection: [],
    // func
    showSpecialPriceFunc: null,
    showStockSpecialPriceFunc: null
  }),
  computed: {
    invalidItems() {
      return Model.getInvalidItems(this.userData);
    }
  },
  methods: {
    /*** by special price modal ***/

    handleSpecialPrice_Init(show) {
      this.showSpecialPriceFunc = show;
    },
    handleSpecialPrice_Submit(specialPriceData, supportData) {
      const count = Model.New.setItemsBySpecialPriceDetails(
        this.userItems, supportData.StockSpecialPriceList
      );

      if (count > 0) {
        Model.updateDetails(this.userData, this.userItems);
      }

      this.showAddedItems(count);
    },

    /*** by stock modal ***/

    handleStock_Submit(stockData) {
      const isValid = Model.New.setItemsByStock(this.userItems, stockData);

      if (isValid) {
        Model.updateDetails(this.userData, this.userItems);
      }
      else {
        this.showErrorDuplicatedItem();
      }
    },

    /*** by stock special price modal ***/

    handleStockSpecialPrice_Init(show) {
      this.showStockSpecialPriceFunc = show;
    },
    handleStockSpecialPrice_Submit(stockSpecialPriceData) {
      const count = Model.New.setItemsByStockSpecialPrice(
        this.userItems, stockSpecialPriceData
      );

      if (count > 0) {
        Model.updateDetails(this.userData, this.userItems);
      }

      this.showAddedItems(count);
    },

    /*** custom ***/

    handleAdd_Item() {
      if (this.userData.SpecialPriceCount === 0) {
        this.$refs.mdlStockSearch.show(
          this.userData.WarehouseID,
          Services.getRelatedStockList
        );
      }
      else {
        this.showStockSpecialPriceFunc(
          this.userData.WarehouseID,
          Services.getRelatedStockSpecialPriceList
        );
      }
    },

    handleAdd_SpecialPrice() {
      this.specialPriceSelection = Model.getSpecialPriceForSelection(
        this.specialPriceList, this.userItems
      );
      this.showSpecialPriceFunc(
        this.userData.WarehouseID, Services.getRelatedSpecialPriceDetails
      );
    },

    handleReset() {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItems,
        this.handleReset_Confirm
      );
    },
    handleReset_Confirm() {
      Model.New.setItemsByReset(this.userItems);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },

    showAddedItems(count) {
      this.$nextTick(() => {
        this.openSnackbar({
          message: count + Info.Message.New_InsertedItems,
          infoType: true
        });
      });
    },
    showErrorDuplicatedItem() {
      this.openSnackbar({
        message: Info.Message.New_DuplicatedItem,
        errorType: true
      });
    }
  }
}
</script>