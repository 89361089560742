<template>
  <kst-page-print-details page-footer
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column">
        <Details :details="details"/>
      </div>
    </div>

    <div class="label">{{ Info.Section.ItemsRaw.Title }}:</div>
    <ItemsRaw class="block" :details="details" :itemsRaw="details.ItemsRaw"/>
    <TotalRaw class="block" :details="details"/>

    <div class="label">{{ Info.Section.ItemsFinish.Title }}:</div>
    <ItemsFinish class="block" :details="details" :itemsRaw="details.ItemsFinish"/>
    <TotalFinish class="block" :details="details"/>
  </kst-page-print-details>
</template>

<script>
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import Details from "./StcvPD_Details.vue";
import ItemsFinish from "./StcvPD_ItemsFinish.vue";
import ItemsRaw from "./StcvPD_ItemsRaw.vue";
import TotalFinish from "./StcvPD_TotalFinish.vue";
import TotalRaw from "./StcvPD_TotalRaw.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Details,
    ItemsFinish,
    ItemsRaw,
    TotalFinish,
    TotalRaw,
    Company
  },
  data: () => ({
    Info: Info,
    details: {},
    companyData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintDetailsData(id);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      this.companyData = data.CompanyDetails;

      for (const item of this.details.ItemsFinish) {
        Model.updateItem(item);
      }
      for (const item of this.details.ItemsRaw) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.details = {};
      this.companyData = {};
    }
  }
}
</script>
