import AppAccess from "./App_Access.js";
import AppArray from "./App_Array.js";
import AppBuefy from "./App_Buefy.js";
import AppCompare from "./App_Compare.js";
import AppConvert from "./App_Convert.js";
import AppData from "./App_Data.js";
import AppEnum from "./App_Enum.js";
import AppEnv from "./App_Env.js";
import AppFormatter from "./App_Formatter.js";
import AppInput from "./App_Input.js";
import AppJavaScript from "./App_JavaScript.js";
import AppKey from "./App_Key.js";
import AppOutput from "./App_Output.js";
import AppPivot from "./App_Pivot.js";
import AppRoute from "./App_Route.js";
import AppSearch from "./App_Search.js";
import AppSession from "./App_Session.js";
import AppSort from "./App_Sort.js";
import AppType from "./App_Type.js";
import AppValue from "./App_Value.js";
import AppValidation from "./App_Validation.js";
import AppVeeValidate from "./App_VeeValidate.js";
import AppView from "./App_View.js";
import AppVue from "./App_Vue.js";
import AppXlsx from "./App_Xlsx.js";

/**
 * WARNING !!!
 * Please kindly DO NOT change key (name) or structure.
 * - Add DEPRECATED note for unused function.
 * - Create new key for new/updated function, when:
 *   * have different parameters:
 *     > number of parameters
 *     > data-type of parameter
 *     > data-type of result
 *   * have different calculation / data processing method
 * 
 * When you change active function, you may break others !!
 * ~ Thank you ~
 */

export default {
  Access: {
    create: AppAccess.create, // (value)
    createAction: AppAccess.createAction, // (module, moduleType, urlParams)
    getAvailableActions: AppAccess.getAvailableActions, // (actions, module, access)
    getDetailsAvailableActions: AppAccess.getDetailsAvailableActions, // (id, actions, module, access)
    hasModuleAttr: AppAccess.hasModuleAttr // (moduleAttr)
  },

  Array: {
    getItem2: AppArray.getItem2, // (items, conditions, options)
    searchItem: AppArray.searchItem, // (items, key, value, options)
    searchValue: AppArray.searchValue, // (items, itemKey, value, valueKey)
    truncate: AppArray.truncate // (items)
  },

  Buefy: {
    Position: AppBuefy.Position,
    Table: AppBuefy.Table,
    Type: AppBuefy.Type
  },

  Compare: {
    getDateTime: AppCompare.getDateTime, // (value)
    getDecimal: AppCompare.getDecimal, // (value)
    validateArray: AppCompare.validateArray, // (userList, dataList, fields)
    validateObject: AppCompare.validateObject // (userObj, dataObj, fields)
  },

  Convert: {
    arrayToString: AppConvert.Array.toString // (value, separator)
  },

  Data: {
    addDaysToDate: AppData.addDaysToDate, // (value, days)
    getActivityTypeName: AppData.getActivityTypeName, // (activityTypeId)
    getDateDiffInDays: AppData.getDateDiffInDays, // (value1, value2)
    getDiscPercent_Value: AppData.getDiscPercent_Value, // (value, percent)
    getFixedDecimal: AppData.getFixedDecimal, // (value, decimalNumber)
    getHppTotal: AppData.getHppTotal, // (qty, hppPerPcs)
    getInvalidStockError: AppData.getInvalidStockError, // (userQty, stockQty, itemName)
    getPackaging: AppData.getPackaging, // (packagingName, packagingValue)
    getQtyByPackaging: AppData.getQtyByPackaging, // (qty, packagingValue)
    getStatusClass: AppData.getStatusClass, // (value, statusEnum, statusClassEnum)
    getStatusLabel: AppData.getStatusLabel, // (value, statusEnum, statusLabelEnum)
    getTotal: AppData.getTotal, // (qty, packagingValue, price)
    getTotalQty: AppData.getTotalQty, // (qty, packagingValue)
    getTotalWeight: AppData.getTotalWeight, // (qty, packagingValue, weight)
    getValueProrate: AppData.getValueProrate, // (value, totalSingle, totalAll)
    hasProp: AppData.hasProp, // (value, propName)
    isModuleType_hideActionInMobile: AppData.isModuleType_hideActionInMobile, // moduleType
    isTrue: AppData.isTrue, // (value)
    isUnlimitedUser: AppData.isUnlimitedUser, // (value)
    normalizeArray: AppData.normalizeArray, // (value)
    normalizeObject: AppData.normalizeObject // (value)
  },

  Enum: {
    ActivityType: AppEnum.ActivityType,
    Axios: AppEnum.Axios,
    Boolean: AppEnum.Boolean,
    Breakline: AppEnum.Breakline,
    Event: AppEnum.Event,
    Input: AppEnum.Input,
    ItemType: AppEnum.ItemType,
    Param: AppEnum.Param,
    ParamValue: AppEnum.ParamValue,
    Request: AppEnum.Request,
    Response: AppEnum.Response,
    Status: AppEnum.Status
  },

  Env: {
    Api: AppEnv.Api,

    // external
    WebsiteUrl: AppEnv.WebsiteUrl
  },

  Format: {
    // boolean
    getActiveInactive: AppFormatter.getActiveInactive, // (value)
    getAutoManual: AppFormatter.getAutoManual, // (value)
    getBoolean: AppFormatter.getBoolean, // (value)
    getOpenClosed: AppFormatter.getOpenClosed, // (value)
    getYesNo: AppFormatter.getYesNo, // (value)
    // date
    getShortDate: AppFormatter.getShortDate, // (value)
    getRangeShortDate: AppFormatter.getRangeShortDate, // (value)
    getMediumDate: AppFormatter.getMediumDate, // (value)
    getLongDate: AppFormatter.getLongDate, // (value)
    getLongMonthly: AppFormatter.getLongMonthly, // (value)
    getShortDateTime: AppFormatter.getShortDateTime, // (value)
    getMediumDateTime: AppFormatter.getMediumDateTime, // (value)
    getLongDateTime: AppFormatter.getLongDateTime, // (value)
    // number
    getAccounting: AppFormatter.getAccounting, // (value)
    getAccounting2D: AppFormatter.getAccounting2D, // (value)
    getCurrency: AppFormatter.getCurrency, // (value)
    getDecimal: AppFormatter.getDecimal, // (value)
    getDecimal2D: AppFormatter.getDecimal2D, // (value)
    getInteger: AppFormatter.getInteger, // (value)
    getPercent: AppFormatter.getPercent, // (value),
    // url
    getExternalUrl: AppFormatter.getExternalUrl // (value)
  },

  In: {
    isNumberType: AppInput.isNumberType, // (inputType)

    getDate: AppInput.getDate, // (value)
    getDateFirstDay: AppInput.getDateFirstDay, // ()
    getDateFirstDayLastMonth: AppInput.getDateFirstDayLastMonth, // ()
    getDateLast30Day: AppInput.getDateLast30Day, // ()
    getDateLastDay: AppInput.getDateLastDay, // ()
    getDateLastDayLastMonth: AppInput.getDateLastDayLastMonth, // ()
    getDateLastWeek: AppInput.getDateLastWeek, // ()
    getDateToday: AppInput.getDateToday, // ()
    getDateYesterday: AppInput.getDateYesterday, // ()
    getLastMonthDay: AppInput.getLastMonthDay, // ()
    getThreeMonthDay: AppInput.getThreeMonthDay, // ()
    getOneMonthDateRange: AppInput.getOneMonthDateRange, // ()

    getDateTime: AppInput.getDateTime, // (value)
    
    getTime: AppInput.getTime, // (value)

    getDefaultDateRange: AppInput.getDefaultDateRange, // ()

    getBoolean: AppInput.getBoolean, // (value)
    getDecimal: AppInput.getDecimal, // (value)
    getInteger: AppInput.getInteger, // (value)
    getSelectOptions: AppInput.getSelectOptions, // (valueKey, labelKey, items)
    getString: AppInput.getString // (value)
  },

  JS: {
    parseInt: AppJavaScript.parseInt, // (value, defaultValue)
    parseFloat: AppJavaScript.parseFloat // (value, defaultValue)
  },

  Key: {
    KeyProp: AppKey.KeyProp,
    KeyEvent: AppKey.KeyEvent,
    Key: AppKey.Key
  },

  Out: {
    getBoolean: AppOutput.getBoolean, // (value)
    getDateString: AppOutput.getDateString, // (value)
    getDecimal: AppOutput.getDecimal, // (value)
    getInteger: AppOutput.getInteger, // (value)
    getParams: AppOutput.getParams, // (options, paramNameList)
    getString: AppOutput.getString, // (value),
    getTimeString: AppOutput.getTimeString, // (value)

    // DEPRECATED
    getDate: AppOutput.getDate // (value)
  },

  Pivot: {
    Type: AppPivot.Type,
    createObj: AppPivot.createObj, // (items, fieldKeys, fieldValues, summaryKeyList)
    create: AppPivot.create // (items, fieldKeys, fieldValues)
  },

  Route: {
    getFullPath: AppRoute.getFullPath, // (module, moduleType, params)
    getParams: AppRoute.getParams, // (params, withoutQueryMark)
    getPath: AppRoute.getPath // (module, moduleType, params)
  },

  Search: {
    OptionAll: AppSearch.OptionAll,
    OptionNone: AppSearch.OptionNone,
    OptionSome: AppSearch.OptionSome,
    LabelAll: AppSearch.LabelAll,
    LabelNone: AppSearch.LabelNone,
    LabelSome: AppSearch.LabelSome,

    createList: AppSearch.createList, // (items, options)
    getValue: AppSearch.getValue // (value, defaultValue, OPTION_ALL)
  },

  Type: {
    isBoolean: AppType.isBoolean, // (value)
    isDate: AppType.isDate, // (value)
    isDecimal: AppType.isDecimal, // (value)
    isInteger: AppType.isInteger, // (value)
    isNumber: AppType.isNumber, // (value)
    isString: AppType.isString // (value)
  },

  Session: {
    clear: AppSession.clear, // ()
    companyHasAccess: AppSession.companyHasAccess, // ()

    getApiVersion: AppSession.getApiVersion, // ()
    getBackDateInterval: AppSession.getBackDateInterval, // ()
    getCompanyAccessibility: AppSession.getCompanyAccessibility, // ()
    getCompanyName: AppSession.getCompanyName, // ()
    getCompanyImageSrc: AppSession.getCompanyImageSrc, // ()
    getDefaultMenu: AppSession.getDefaultMenu, // ()
    getDefaultWarehouseID: AppSession.getDefaultWarehouseID, // ()
    getLastChecked: AppSession.getLastChecked, // ()
    getModule: AppSession.getModule, // ()
    getModuleAttr: AppSession.getModuleAttr, // ()  
    getName: AppSession.getName, // ()

    setApiVersion: AppSession.setApiVersion, // (value)
    setBackDateInterval: AppSession.setBackDateInterval, // (value)
    setCompanyAccessibility: AppSession.setCompanyAccessibility, // (value)
    setCompanyImageSrc: AppSession.setCompanyImageSrc, // (value)
    setCompanyName: AppSession.setCompanyName, // (value)
    setDefaultMenu: AppSession.setDefaultMenu, // (value)
    setDefaultWarehouseID: AppSession.setDefaultWarehouseID, // (value)
    setLastChecked: AppSession.setLastChecked, // (value)
    setModule: AppSession.setModule, // (value)
    setModuleAttr: AppSession.setModuleAttr, // (value)
    setName: AppSession.setName, // (value)

    // self service
    getSelfServicePin: AppSession.getSelfServicePin, // ()
    setSelfServicePin: AppSession.setSelfServicePin, // (value)
  },

  Sort: {
    Case: AppSort.Case,
    Key: AppSort.Key,
    Order: AppSort.Order,
    begin: AppSort.begin, // (items, fieldKeys)
  },

  Value: {
    getType: AppValue.getType, // (field, catalog)
    getValue: AppValue.getValue, // (field, data, catalog, isTooltip)
    getValueOutput: AppValue.getValueOutput, // (field, data, catalog, isTooltip)
    getValueRaw: AppValue.getValueRaw, // (field, data, catalog)
    isUseNullText: AppValue.isUseNullText // (field, data, catalog)
  },

  Validate: {
    numeric: AppValidation.numeric // (value, fieldName, options, index)
  },

  Vee: {
    Rule: AppVeeValidate.Rule,
    ValidRule: AppVeeValidate.ValidRule,
    getFieldTarget: AppVeeValidate.getFieldTarget, // (field, rule)
    getRowErrors: AppVeeValidate.getRowErrors, // (item, field, errors)
    getValidatorId: AppVeeValidate.getValidatorId, // (field, index, group)

    Message: AppVeeValidate.Message
  },

  View: {
    getTimestamp: AppView.getTimestamp, // ()
    getVersion: AppView.getVersion // ()
  },

  Vue: {
    getComponentClass: AppVue.getComponentClass, // (module, moduleType)
    hasEventHandler: AppVue.hasEventHandler // (component, eventName)
  },

  Xlsx: {
    getFileName: AppXlsx.getFileName, // (module, moduleType)
    getRelatedValue: AppXlsx.getRelatedValue, // (field, data, catalog)
    getSheet: AppXlsx.getSheet, // (file, sheetName, withHeader = 1)
    getStatus: AppXlsx.getStatus, // (value, statusEnum)
    getValue: AppXlsx.getValue // (field, data, catalog)
  }
}