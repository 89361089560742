<template>
  <kst-table action input-mode :data="userAddresses">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-Name"
      field="Name"
      :label="ClientAddressCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-Address"
      field="Address"
      :label="ClientAddressCatalog.Address.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-ContactName"
      field="ContactName"
      :label="ClientAddressCatalog.ContactName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-ContactMobile"
      field="ContactMobile"
      :label="ClientAddressCatalog.ContactMobile.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-ContactPhone"
      field="ContactPhone"
      :label="ClientAddressCatalog.ContactPhone.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="kstmod-client-new-ContactEmail"
      field="ContactEmail"
      :label="ClientAddressCatalog.ContactEmail.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="ClientAddressCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.ClientAddress.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userAddresses" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import ClientAddressCatalog from "../CliaCatalog.js";
import Info from "../CliInfo.js";
import Model from "../CliModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userAddresses: Array
  },
  data: () => ({
    ClientAddressCatalog: ClientAddressCatalog,
    Model: Model
  }),
  methods: {
    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteAddress,
        this.handleRowDelete_Confirm,
        null,
        index
      );
    },
    handleRowDelete_Confirm(index) {
      this.userAddresses.splice(index, 1);
    },

    handleRowErrors(errors, field, index) {
      Model.setAddressErrors(this.userAddresses[index], field, errors);
    }
  }
}
</script>