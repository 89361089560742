import ExpenseCategoryModel from "../ExpenseCategory/ExpcModel.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByExpenseCategoryOptions(userData, categoryOptions) {
    userData.SubCategoryID = ExpenseCategoryModel.getDefault(
      categoryOptions, userData.SubCategoryID
    );
  },
  setDetailsByPaymentList(userData, paymentList) {
    if (paymentList === undefined || paymentList === null) {
      return;
    }

    userData.PaymentTypeName = PaymentTypeModel.getDefault(paymentList);
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  }
}