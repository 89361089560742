import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import DaiEnum from "./DaiEnum.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.DailyReport,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.DailyReport_Act_Export,
    Act_Print: AppModuleAttr.DailyReport_Act_Print,
    Act_Report: AppModuleAttr.DailyReport_Act_Report
  },

  SearchResult: DaiEnum.SearchResult,

  UrlParams: ["Date", "SearchResultID", "WarehouseID"],

  /*** method ***/

  createSearchResultOptions(optionAllText) {
    return this.helper.createSearchResultOptions(
      this.SearchResult, optionAllText
    );
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
  getWarehouseDefault(items, defaultId) {
    return WarehouseModel.getDefault(items, defaultId);
  },

  helper: {
    createSearchResultOptions(enumSearchResult, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Label";

      const items = [
        {
          [rowId]: enumSearchResult.HasTransaction.ID,
          [rowLabel]: enumSearchResult.HasTransaction.Label
        },
        {
          [rowId]: enumSearchResult.StockAvailable.ID,
          [rowLabel]: enumSearchResult.StockAvailable.Label
        }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    }
  }
}