<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :paymentOptions="paymentOptions"
      :warehouseOptions="warehouseOptions"
      @input="handleDetails_input"
      @submit="handleDetails_submit"
    />
    <Invoices
      :userData="userData"
      :userInvoiceList="userInvoiceList"
      @init="handleInvoices_init"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../RcpyModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/RecPayServices.js";

import Details from "./RcpyN_Details.vue";
import Invoices from "./RcpyN_Invoices.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Invoices
  },
  data: () => ({
    userData: {},
    userInvoiceList: [],
    paymentOptions: {},
    paymentTypeList: [],
    warehouseOptions: {},
    // func
    loadListFunc: null,
    submitFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew, submit) {
      this.submitFunc = submit;
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.paymentTypeList = data.PaymentTypeList;
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, null, SearchCatalog.WarehouseID.Label
      );

      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
      this.updatePaymentOptions();
    },

    handleSubmit(saveData) {
      if (this.userData.IsVerified) {
        let data = Model.populateData(this.userData, this.userInvoiceList);
        const config = Services.postData(data);
        saveData(config, this.handleSubmit_success);
      }
      else {
        Model.New.setDetailsByDetailsVerified(this.userData);
        this.userData.IsVerified = true;
        this.loadListFunc();
      }
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** details ***/

    handleDetails_input() {
      this.updatePaymentOptions();
    },

    handleDetails_submit() {
      this.submitFunc();
    },

    /*** invoice ***/

    handleInvoices_init(loadList) {
      this.loadListFunc = loadList;
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userInvoiceList = [];
      this.paymentOptions = {};
      this.paymentTypeList = [];
      this.warehouseOptions = {};
    },

    updatePaymentOptions() {
      const options = {
        WithCreditNote: this.userData.ClientID !== null
      };
      this.paymentOptions = Model.getPaymentOptions(this.paymentTypeList,
        null, null, options
      );

      Model.New.setDetailsByPaymentList(this.userData, this.paymentOptions.rows);
    }
  }
}
</script>