import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, receiveData) {
    userData.ID = receiveData.ID;
    userData.Date = App.In.getDate(receiveData.Date);
    userData.Comment = App.In.getString(receiveData.Comment);
    userData.CurrentDate = App.In.getDateToday();
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      Date: App.Out.getDateString(userData.Date),
      Comment: App.Out.getString(userData.Comment),
      CurrentDate: App.Out.getDateString(userData.CurrentDate)
    };
  },
  
  validationReceiveDate(data) {
    const result = {
      [App.Vee.Rule.MinDate]: {
        target: App.In.getDate(data.MinDate)
      },
      [App.Vee.Rule.MaxDate]: {
        target: App.In.getDate(data.MaxDate)
      }
    };

    return result;
  }
}