import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./DebtModel_Body.js";
import ModelList from "./DebtModel_List.js";
import ModelRelatedList from "./DebtModel_RelatedList.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.Debt,
  ModuleType: {
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Search: ModelBody.Search,

  /*** method ***/

  /*** method - List ***/

  List: {
    updateList(dataList) {
      ModelList.updateList(dataList);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    updateList(dataList) {
      ModelRelatedList.updateList(dataList);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}