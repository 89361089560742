import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import ExpcD from "./Details/ExpcD.vue";
import ExpcE from "./Edit/ExpcE.vue";
import ExpcL from "./List/ExpcL.vue";
import ExpcN from "./New/ExpcN.vue";

export default {
  Path: {
    Edit: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Edit),
    List: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.List),
    New: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.New)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Details),
        name: RouterModel.getName(AppModule.ExpenseCategory, AppModuleType.Details),
        component: ExpcD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.ExpenseCategory),
          moduleattr: AppModuleAttr.ExpenseCategory_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.ExpenseCategory, AppModuleType.Edit),
        component: ExpcE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.ExpenseCategory),
          moduleattr: AppModuleAttr.ExpenseCategory_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.ExpenseCategory),
        name: RouterModel.getName(AppModule.ExpenseCategory),
        component: ExpcL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.List),
          module: RouterModel.getModule(AppModule.ExpenseCategory),
          moduleattr: AppModuleAttr.ExpenseCategory_Act_List,
          moduletype: AppModuleType.List,
          new: {
            module: RouterModel.getModule(AppModule.ExpenseCategory),
            moduleattr: AppModuleAttr.ExpenseCategory_Act_New,
            moduletype: AppModuleType.New
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.New),
        name: RouterModel.getName(AppModule.ExpenseCategory, AppModuleType.New),
        component: ExpcN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ExpenseCategory, AppModuleType.New),
          module: RouterModel.getModule(AppModule.ExpenseCategory),
          moduleattr: AppModuleAttr.ExpenseCategory_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.ExpenseCategory, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  }
}