<template>
  <kst-section class="kstmod-moduleattr-relatedglobaldetails" :title="title">
    <List :items="items"/>
  </kst-section>
</template>

<script>
import Info from "../ModuleAttrInfo.js";
import Model from "../ModuleAttrModel.js";

import List from "./Page_List.vue";

export default {
  components: {
    List
  },
  props: {
    moduleAttrList: Array
  },
  data: () => ({
    items: []
  }),
  computed: {
    title() {
      return Info.Section.Global.Title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.items = [];

      for (const moduleAttr of this.moduleAttrList) {
        if (moduleAttr.ModuleAttrTypeID === Model.Type.Global.ID) {
          this.items.push(moduleAttr.ModuleAttrName);
        }
      }
    }
  }
}
</script>