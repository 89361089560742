import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  Quantity: {
    Label: AppCatalog.Field.Qty.Label + " Kirim",
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxValue: ["InvoiceItemQty", AppCatalog.Field.Qty.Label + " Jual"],
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  TotalQuantity: {
    Label: AppCatalog.Field.TotalQty.Label,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },
  TotalWeight: {
    Label: AppCatalog.Field.TotalWeight.Label,
    Type: AppCatalog.Field.TotalWeight.Type,
    ReadOnly: AppCatalog.Field.TotalWeight.ReadOnly
  },
  Weight: {
    Label: AppCatalog.Field.Weight.Label,
    Type: AppCatalog.Field.Weight.Type,
    ReadOnly: true
  },

  // support
  InvoiceItemQty: {
    Label: AppCatalog.Field.Qty.Label + " Jual",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  LogisticsItemsTotalWeight: {
    Label: AppCatalog.Field.TotalWeight.Label,
    Type: AppCatalog.Field.TotalWeight.Type,
    ReadOnly: AppCatalog.Field.TotalWeight.ReadOnly
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },

  // DEPRECATED
  AvailableQuantity: {
    Label: "Qty Jual",
    Type: InputType.Integer,
    ReadOnly: true
  }
}