<template>
  <kst-page-export-details
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithDetails"
      :label="Info.Export.Details.Excel.Option.Details"
    />
    <kst-check v-if="access.Rec_Act_List"
      v-model="userData.WithRecList"
      :label="Info.Export.Details.Excel.Option.RecList"
    />
    <kst-check v-if="access.Sect_SelfService"
      v-model="userData.WithSelfService"
      :label="Info.Export.Details.Excel.Option.SelfService"
    />
  </kst-page-export-details>
</template>

<script>
import Info from "../CliInfo.js";
import Model from "../CliModel.js";
import Services from "../../../services/Api/ClientServices.js";

import Export from "./CliExD_Details.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    access: {},
    userData: {
      ID: null,
      WithDetails: true,
      WithRecList: true,
      WithSelfService: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    handleInit(id) {
      this.resetData();
      this.userData.ID = id;
    },

    handleExport(loadExport) {
      const options = this.getListOptions()
      const config = Services.getExportDetails(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData.ID = null;
      this.userData.WithDetails = true;
      this.userData.WithRecList = this.access.Rec_Act_List;
      this.userData.WithSelfService = this.access.Sect_SelfService;
    },

    getListOptions() {
      return {
        id: this.userData.ID,
        withreclist: this.$kst.Out.getBoolean(this.userData.WithRecList)
      };
    }
  }
}
</script>