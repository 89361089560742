<template>
  <kst-tooltip
    v-if="row.SellPricePcs !== sellPrice"
    position="is-left"
    size="is-medium"
    :type="theType"
  >
    <template #content>
      {{ theText }}
    </template>

    <span class="icon" :class="theIconClass">
      <i :class="theIcon"/>
    </span>
  </kst-tooltip>
</template>

<script>
import Info from "../InvInfo.js";

export default {
  props: {
    row: Object,
    sellPrice: Number
  },
  computed: {
    sellPriceType() {
      return this.row.SellPricePcs > this.sellPrice ? "High" : "Low";
    },
    theIcon() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].Icon;
    },
    theIconClass() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].IconClass;
    },
    theText() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].TooltipText;
    },
    theType() {
      return Info.PriceSearch.SellPrice[this.sellPriceType].TooltipClass;
    }
  }
}
</script>