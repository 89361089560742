<template>
  <div>
    <div class="columns is-mobile">
      <div class="column is-6 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.HppTotal.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5 pr-3"
            field="HppTotal" :data="details" :catalog="Catalog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../HpadCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>