import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  ActivityTypeName: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Changes: {
    Label: AppCatalog.Field.Qty.Label + " Masuk/Keluar",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label + " Kartu",
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output
  },
  HppAfter: {
    Label: AppCatalog.Field.Hpp.Label + " Akhir",
    Type: AppCatalog.Field.Hpp.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.Hpp.Output
  },
  HppChanges: {
    Label: AppCatalog.Field.Hpp.Label + " Masuk/Keluar",
    Type: AppCatalog.Field.Hpp.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.Hpp.Output
  },
  QuantityAfter: {
    Label: AppCatalog.Field.Qty.Label + " Akhir",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  TransactionID: {
    Label: "Transaksi"
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}