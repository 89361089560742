import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";
import Model from "./LayoutModel.js";
import {
  // icons non-active
  Dashboard, Transaction, Items, Warehouse, SpecialPrice, PaymentType,
  StockCard, ExpenseCategory, ProfitLoss, DailyReport, Invoice, Omzet, Client,
  Purchase, Receive, StockReduction, Expenses, Delivery, SO, AutoNumber,
  StockClosing, SpecialPriceAdj, HppAdj, StockOpname,
  StockAvailability, CompanyProfile, SalesRecap, RtnInvoice, StockTrf, Vendor,
  Finance, Inventaris, Contact, Report, Buy, Settings, Sell, POS, UserList,
  Rec, RecCard, RecGroup, RecPay, Debt, DebtCard, DebtGroup, DebtPay, StockConv, 
  RtnReceive, LowStock,
  // icons active
  DashboardActive, TransactionActive, ItemsActive, WarehouseActive,
  SpecialPriceActive, PaymentTypeActive, StockCardActive, ExpenseCategoryActive,
  ProfitLossActive, DailyReportActive, InvoiceActive, OmzetActive, ClientActive,
  PurchaseActive, ReceiveActive, StockReductionActive, ExpensesActive,
  DeliveryActive, SOActive, AutoNumberActive, StockClosingActive,
  SpecialPriceAdjActive, HppAdjActive, StockOpnameActive,
  StockAvailabilityActive, CompanyProfileActive, SalesRecapActive,
  RtnInvoiceActive, StockTrfActive, VendorActive, FinanceActive, InventarisActive,
  ContactActive, ReportActive, BuyActive, SettingsActive, SellActive, POSActive,
  UserListActive, Access, AccessActive, RecActive, RecCardActive, RecGroupActive,
  RecPayActive, DebtActive, DebtCardActive, DebtGroupActive, DebtPayActive,
  StockConvActive, RtnReceiveActive, LowStockActive
} from "../../assets/icons";

export default {
  getList() {
    let result = [
      {
        ID: AppModule.Dashboard.ID,
        Code: AppModule.Dashboard.Code,
        Label: Model.getMenuLabel(AppModule.Dashboard),
        Icon: Dashboard,
        IconActive: DashboardActive,
        Path: RouterModel.getPath(AppModule.Dashboard)
      },
      {
        ID: 901,
        Label: Model.getMenuLabel(AppModule.Report),
        Icon: Report,
        IconActive: ReportActive,
        Items: [
          {
            ID: AppModule.StockCard.ID,
            Code: AppModule.StockCard.Code,
            Label: Model.getMenuLabel(AppModule.StockCard),
            Icon: StockCard,
            IconActive: StockCardActive,
            Path: RouterModel.getPath(AppModule.StockCard)
          },
          {
            ID: AppModule.ProfitLoss.ID,
            Code: AppModule.ProfitLoss.Code,
            Label: Model.getMenuLabel(AppModule.ProfitLoss),
            Icon: ProfitLoss,
            IconActive: ProfitLossActive,
            Path: RouterModel.getPath(AppModule.ProfitLoss)
          },
          {
            ID: AppModule.Omzet.ID,
            Code: AppModule.Omzet.Code,
            Label: Model.getMenuLabel(AppModule.Omzet),
            Icon: Omzet,
            IconActive: OmzetActive,
            Path: RouterModel.getPath(AppModule.Omzet)
          },
          {
            ID: AppModule.Transaction.ID,
            Code: AppModule.Transaction.Code,
            Label: Model.getMenuLabel(AppModule.Transaction),
            Icon: Transaction,
            IconActive: TransactionActive,
            Path: RouterModel.getPath(AppModule.Transaction)
          },
          {
            ID: AppModule.DailyReport.ID,
            Code: AppModule.DailyReport.Code,
            Label: Model.getMenuLabel(AppModule.DailyReport),
            Icon: DailyReport,
            IconActive: DailyReportActive,
            Path: RouterModel.getPath(AppModule.DailyReport)
          }
        ]
      },
      {
        ID: AppModule.Pos.ID,
        Code: AppModule.Pos.Code,
        Label: Model.getMenuLabel(AppModule.Pos),
        Icon: POS,
        IconActive: POSActive,
        Path: RouterModel.getPath(AppModule.Pos)
      },
      {
        ID: 902,
        Label: Model.getMenuLabel(AppModule.Finance),
        Icon: Finance,
        IconActive: FinanceActive,
        Items: [
          {
            ID: AppModule.Expense.ID,
            Code: AppModule.Expense.Code,
            Label: Model.getMenuLabel(AppModule.Expense),
            Icon: Expenses,
            IconActive: ExpensesActive,
            Path: RouterModel.getPath(AppModule.Expense)
          }
        ]
      },
      {
        ID: 903,
        Label: Model.getMenuLabel(AppModule.Inventaris),
        Icon: Inventaris,
        IconActive: InventarisActive,
        Items: [
          {
            ID: AppModule.Item.ID,
            Code: AppModule.Item.Code,
            Label: Model.getMenuLabel(AppModule.Item),
            Icon: Items,
            IconActive: ItemsActive,
            Path: RouterModel.getPath(AppModule.Item)
          },
          {
            ID: AppModule.LowStock.ID,
            Code: AppModule.LowStock.Code,
            Label: Model.getMenuLabel(AppModule.LowStock),
            Icon: LowStock,
            IconActive: LowStockActive,
            Path: RouterModel.getPath(AppModule.LowStock)
          },
          {
            ID: AppModule.StockConv.ID,
            Code: AppModule.StockConv.Code,
            Label: Model.getMenuLabel(AppModule.StockConv),
            Icon: StockConv,
            IconActive: StockConvActive,
            Path: RouterModel.getPath(AppModule.StockConv)
          },
          {
            ID: AppModule.StockOpname.ID,
            Code: AppModule.StockOpname.Code,
            Label: Model.getMenuLabel(AppModule.StockOpname),
            Icon: StockOpname,
            IconActive: StockOpnameActive,
            Path: RouterModel.getPath(AppModule.StockOpname)
          },
          {
            ID: AppModule.StockReduction.ID,
            Code: AppModule.StockReduction.Code,
            Label: Model.getMenuLabel(AppModule.StockReduction),
            Icon: StockReduction,
            IconActive: StockReductionActive,
            Path: RouterModel.getPath(AppModule.StockReduction)
          },
          {
            ID: AppModule.StockTrf.ID,
            Code: AppModule.StockTrf.Code,
            Label: Model.getMenuLabel(AppModule.StockTrf),
            Icon: StockTrf,
            IconActive: StockTrfActive,
            Path: RouterModel.getPath(AppModule.StockTrf)
          },
          {
            ID: AppModule.StockClosing.ID,
            Code: AppModule.StockClosing.Code,
            Label: Model.getMenuLabel(AppModule.StockClosing),
            Icon: StockClosing,
            IconActive: StockClosingActive,
            Path: RouterModel.getPath(AppModule.StockClosing, AppModuleType.List)
          },
          {
            ID: AppModule.HppAdj.ID,
            Code: AppModule.HppAdj.Code,
            Label: Model.getMenuLabel(AppModule.HppAdj),
            Icon: HppAdj,
            IconActive: HppAdjActive,
            Path: RouterModel.getPath(AppModule.HppAdj, AppModuleType.List)
          },
          {
            ID: AppModule.StockAvailability.ID,
            Code: AppModule.StockAvailability.Code,
            Label: Model.getMenuLabel(AppModule.StockAvailability),
            Icon: StockAvailability,
            IconActive: StockAvailabilityActive,
            Path: RouterModel.getPath(AppModule.StockAvailability)
          },
          {
            ID: AppModule.SpecialPriceAdj.ID,
            Code: AppModule.SpecialPriceAdj.Code,
            Label: Model.getMenuLabel(AppModule.SpecialPriceAdj),
            Icon: SpecialPriceAdj,
            IconActive: SpecialPriceAdjActive,
            Path: RouterModel.getPath(AppModule.SpecialPriceAdj, AppModuleType.List)
          }
        ]
      },
      {
        ID: 904,
        Label: Model.getMenuLabel(AppModule.Buy),
        Icon: Buy,
        IconActive: BuyActive,
        Items: [
          {
            ID: AppModule.Purchase.ID,
            Code: AppModule.Purchase.Code,
            Label: Model.getMenuLabel(AppModule.Purchase),
            Icon: Purchase,
            IconActive: PurchaseActive,
            Path: RouterModel.getPath(AppModule.Purchase)
          },
          {
            ID: AppModule.Receive.ID,
            Code: AppModule.Receive.Code,
            Label: Model.getMenuLabel(AppModule.Receive),
            Icon: Receive,
            IconActive: ReceiveActive,
            Path: RouterModel.getPath(AppModule.Receive)
          },
          {
            ID: AppModule.RtnReceive.ID,
            Code: AppModule.RtnReceive.Code,
            Label: Model.getMenuLabel(AppModule.RtnReceive),
            Icon: RtnReceive,
            IconActive: RtnReceiveActive,
            Path: RouterModel.getPath(AppModule.RtnReceive)
          }
        ]
      },
      {
        ID: 905,
        Label: Model.getMenuLabel(AppModule.DebtGroup),
        Icon: DebtGroup,
        IconActive: DebtGroupActive,
        Items: [
          {
            ID: AppModule.DebtCard.ID,
            Code: AppModule.DebtCard.Code,
            Label: Model.getMenuLabel(AppModule.DebtCard),
            Icon: DebtCard,
            IconActive: DebtCardActive,
            Path: RouterModel.getPath(AppModule.DebtCard)
          },
          {
            ID: AppModule.Debt.ID,
            Code: AppModule.Debt.Code,
            Label: Model.getMenuLabel(AppModule.Debt),
            Icon: Debt,
            IconActive: DebtActive,
            Path: RouterModel.getPath(AppModule.Debt)
          },
          {
            ID: AppModule.DebtPay.ID,
            Code: AppModule.DebtPay.Code,
            Label: Model.getMenuLabel(AppModule.DebtPay),
            Icon: DebtPay,
            IconActive: DebtPayActive,
            Path: RouterModel.getPath(AppModule.DebtPay)
          }
        ]
      },
      {
        ID: 906,
        Label: Model.getMenuLabel(AppModule.Sell),
        Icon: Sell,
        IconActive: SellActive,
        Items: [
          {
            ID: AppModule.Sales.ID,
            Code: AppModule.Sales.Code,
            Label: Model.getMenuLabel(AppModule.Sales),
            Icon: SO,
            IconActive: SOActive,
            Path: RouterModel.getPath(AppModule.Sales)
          },
          {
            ID: AppModule.Invoice.ID,
            Code: AppModule.Invoice.Code,
            Label: Model.getMenuLabel(AppModule.Invoice),
            Icon: Invoice,
            IconActive: InvoiceActive,
            Path: RouterModel.getPath(AppModule.Invoice)
          },
          {
            ID: AppModule.RtnInvoice.ID,
            Code: AppModule.RtnInvoice.Code,
            Label: Model.getMenuLabel(AppModule.RtnInvoice),
            Icon: RtnInvoice,
            IconActive: RtnInvoiceActive,
            Path: RouterModel.getPath(AppModule.RtnInvoice)
          },
          {
            ID: AppModule.Delivery.ID,
            Code: AppModule.Delivery.Code,
            Label: Model.getMenuLabel(AppModule.Delivery),
            Icon: Delivery,
            IconActive: DeliveryActive,
            Path: RouterModel.getPath(AppModule.Delivery)
          },
          {
            ID: AppModule.SalesRecap.ID,
            Code: AppModule.SalesRecap.Code,
            Label: Model.getMenuLabel(AppModule.SalesRecap),
            Icon: SalesRecap,
            IconActive: SalesRecapActive,
            Path: RouterModel.getPath(AppModule.SalesRecap)
          }
        ]
      },
      {
        ID: 907,
        Label: Model.getMenuLabel(AppModule.RecGroup),
        Icon: RecGroup,
        IconActive: RecGroupActive,
        Items: [
          {
            ID: AppModule.RecCard.ID,
            Code: AppModule.RecCard.Code,
            Label: Model.getMenuLabel(AppModule.RecCard),
            Icon: RecCard,
            IconActive: RecCardActive,
            Path: RouterModel.getPath(AppModule.RecCard)
          },
          {
            ID: AppModule.Rec.ID,
            Code: AppModule.Rec.Code,
            Label: Model.getMenuLabel(AppModule.Rec),
            Icon: Rec,
            IconActive: RecActive,
            Path: RouterModel.getPath(AppModule.Rec)
          },
          {
            ID: AppModule.RecPay.ID,
            Code: AppModule.RecPay.Code,
            Label: Model.getMenuLabel(AppModule.RecPay),
            Icon: RecPay,
            IconActive: RecPayActive,
            Path: RouterModel.getPath(AppModule.RecPay)
          }
        ]
      },
      {
        ID: 908,
        Label: Model.getMenuLabel(AppModule.Contact),
        Icon: Contact,
        IconActive: ContactActive,
        Items: [
          {
            ID: AppModule.Vendor.ID,
            Code: AppModule.Vendor.Code,
            Label: Model.getMenuLabel(AppModule.Vendor),
            Icon: Vendor,
            IconActive: VendorActive,
            Path: RouterModel.getPath(AppModule.Vendor)
          },
          {
            ID: AppModule.Client.ID,
            Code: AppModule.Client.Code,
            Label: Model.getMenuLabel(AppModule.Client),
            Icon: Client,
            IconActive: ClientActive,
            Path: RouterModel.getPath(AppModule.Client)
          },
          {
            ID: AppModule.Warehouse.ID,
            Code: AppModule.Warehouse.Code,
            Label: Model.getMenuLabel(AppModule.Warehouse),
            Icon: Warehouse,
            IconActive: WarehouseActive,
            Path: RouterModel.getPath(AppModule.Warehouse)
          }
        ]
      },
      {
        ID: 909,
        Code: AppModule.Settings.Code,
        Label: Model.getMenuLabel(AppModule.Settings),
        Icon: Settings,
        IconActive: SettingsActive,
        Items: [
          {
            ID: AppModule.CompanyProfile.ID,
            Code: AppModule.CompanyProfile.Code,
            Label: Model.getMenuLabel(AppModule.CompanyProfile),
            Icon: CompanyProfile,
            IconActive: CompanyProfileActive,
            Path: RouterModel.getPath(AppModule.CompanyProfile)
          },
          {
            ID: AppModule.Access.ID,
            Code: AppModule.Access.Code,
            Label: Model.getMenuLabel(AppModule.Access),
            Icon: Access,
            IconActive: AccessActive,
            Path: RouterModel.getPath(AppModule.Access)
          },
          {
            ID: AppModule.UserList.ID,
            Code: AppModule.UserList.Code,
            Label: Model.getMenuLabel(AppModule.UserList),
            Icon: UserList,
            IconActive: UserListActive,
            Path: RouterModel.getPath(AppModule.UserList)
          },
          {
            ID: AppModule.PaymentType.ID,
            Code: AppModule.PaymentType.Code,
            Label: Model.getMenuLabel(AppModule.PaymentType),
            Icon: PaymentType,
            IconActive: PaymentTypeActive,
            Path: RouterModel.getPath(AppModule.PaymentType)
          },
          {
            ID: AppModule.AutoNumber.ID,
            Code: AppModule.AutoNumber.Code,
            Label: Model.getMenuLabel(AppModule.AutoNumber),
            Icon: AutoNumber,
            IconActive: AutoNumberActive,
            Path: RouterModel.getPath(AppModule.AutoNumber)
          },
          {
            ID: AppModule.ExpenseCategory.ID,
            Code: AppModule.ExpenseCategory.Code,
            Label: Model.getMenuLabel(AppModule.ExpenseCategory),
            Icon: ExpenseCategory,
            IconActive: ExpenseCategoryActive,
            Path: RouterModel.getPath(AppModule.ExpenseCategory)
          },
          {
            ID: AppModule.SpecialPrice.ID,
            Code: AppModule.SpecialPrice.Code,
            Label: Model.getMenuLabel(AppModule.SpecialPrice),
            Icon: SpecialPrice,
            IconActive: SpecialPriceActive,
            Path: RouterModel.getPath(AppModule.SpecialPrice)
          }
        ]
      }
    ];

    // if (App.Session.getName() === "Dirigo") {
    //   result[1].Items.push({
    //     ID: AppModule.ReportStockAdj.ID,
    //     Code: AppModule.ReportStockAdj.Code,
    //     Label: Model.getMenuLabel(AppModule.ReportStockAdj),
    //     Icon: ReportStockAdj,
    //     IconActive: ReportStockAdjActive,
    //     Path: RouterModel.getPath(AppModule.ReportStockAdj)
    //   });
    // }

    return result;
  }
}