<template>
  <div v-if="theVisible" class="kst-failure" :class="theClass">
    <b-message has-icon type="is-warning">
      <div class="title is-5 has-text-warning-dark mb-1">
        {{ theTitle }}
      </div>
      <template v-if="!simpleMode">
        <div>
          {{ AppCatalog.Failure.Hint }}
        </div>
        <kst-divider failure-mode/>
      </template>

      <div class="content is-flex">
        <span>{{ AppCatalog.Failure.MessageTitle }}</span>
        <span class="has-text-black kst-failure-message">
          <template v-if="theItems.length === 1">
            <div class="ml-2">{{ theItems[0] }}</div>
          </template>
          <template v-else>
            <ul>
              <li
                v-for="(message, index) of theItems"
                :key="index"
                class="has-text-left"
                v-html="message"
              />
            </ul>
          </template>
        </span>
      </div>
    </b-message>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    items: [Array, String],
    // mode: ONLY select 1 (one)
    accessMode: Boolean,
    dataMode: Boolean,
    initMode: Boolean,
    listMode: Boolean,
    saveMode: Boolean,
    // additional mode
    simpleMode: Boolean
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theClass() {
      return this.simpleMode ? "kst-failure-simple " : undefined;
    },
    theItems() {
      if (this.accessMode) {
        return [AppCatalog.Message.NoAccess];
      }
      return Array.isArray(this.items) ? this.items : [this.items];
    },
    theTitle() {
      return this.getTitle();
    },
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getTitle() {
      if (this.accessMode) {
        return AppCatalog.Failure.TitleInit;
      }
      else if (this.dataMode) {
        return AppCatalog.Failure.TitleData;
      }
      else if (this.initMode) {
        return AppCatalog.Failure.TitleInit;
      }
      else if (this.listMode) {
        return AppCatalog.Failure.TitleList;
      }

      // default: use saveMode
      return AppCatalog.Failure.TitleSave;
    },
    getVisible() {
      if (this.accessMode) {
        return true;
      }

      if (this.items === undefined || this.items === null) {
        return false;
      }
      else if (Array.isArray(this.items)) {
        if (this.items.length === 0) {
          return false;
        }
      }

      return true;
    }
  }
}
</script>