import AppCatalog from "../../services/App/AppCatalog.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./DbcdModel.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.Details
    }
  },
  Export: {
    Excel: {
      // file
      SheetName: {
        DebtCard: Model.Module.FullName
      },
      // ui
      Option: {
        DebtCard: Model.Module.FullName
      }
    }
  }
}