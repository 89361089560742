import App from "../../services/App/App.js";
import ModelBody from "./PlosModel_Body.js";
import PlosChartCatalog from "./PlosChartCatalog.js";

// formula: CashBased
export default {
  getChart(transactionDetails) {
    let labelList = [], summaryObj = {};
    let result = ModelBody.createChart();

    this.getChart_populateHppAdj(labelList, summaryObj, transactionDetails);
    this.getChart_populatePos(labelList, summaryObj, transactionDetails);
    this.getChart_populateDebtPay(labelList, summaryObj, transactionDetails);
    this.getChart_populateRecPay(labelList, summaryObj, transactionDetails);
    this.getChart_populateExpense(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockOpname(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockReduction(labelList, summaryObj, transactionDetails);
    this.getChart_populateStockTrf(labelList, summaryObj, transactionDetails);

    let tempData, valueTotal = 0;
    labelList.sort();

    for (const row of labelList) {
      valueTotal += summaryObj[row];

      tempData = { Date: row };
      result.LabelList.push(
        App.Value.getValue("Date", tempData, PlosChartCatalog)
      );
      result.ValueList.push(
        App.Data.getFixedDecimal(summaryObj[row])
      );
    }

    result.ValueAvg = labelList.length === 0
      ? 0
      : App.Data.getFixedDecimal(valueTotal / labelList.length);
    result.ValueAvg = App.In.getDecimal(result.ValueAvg);

    return result;
  },
  getChart_populateHppAdj(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.HppAdjList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populatePos(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.PosList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateDebtPay(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.DebtPay.AllList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = -1 * row.Total;
        labelList.push(row.Date);
      }
    }

    for (const row of transactionDetails.DebtPay.VoidList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateRecPay(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.RecPay.AllList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }

    for (const row of transactionDetails.RecPay.VoidList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = -1 * row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateExpense(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.ExpenseList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = -1 * row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateStockOpname(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.StockOpnameList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateStockReduction(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.StockReductionList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = -1 * row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateStockTrf(labelList, summaryObj, transactionDetails) {
    if (!Object.prototype.hasOwnProperty.call(transactionDetails, "StockTrf")) {
      return;
    }

    for (const row of transactionDetails.StockTrf.AddList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }

    for (const row of transactionDetails.StockTrf.SubList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = -1 * row.Total;
        labelList.push(row.Date);
      }
    }
  }
}