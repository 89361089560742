import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  PONumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Reference.Type,
    ReadOnly: true,
    Highlight: true
  },
  RecTotal: {
    Label: AppModule.Rec.FullName,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  SONumber: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true,
    Highlight: true
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    ReadOnly: true,
    RelatedValue: "StatusName"
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}