<template>
  <kst-page-list
    :catalog="SttrSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :dstWarehouseOptions="dstWarehouseOptions"
        :srcWarehouseOptions="srcWarehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../SttrModel.js";
import SttrSearchCatalog from "../SttrSearchCatalog.js";
import Services from "../../../services/Api/StockTrfServices.js";

import List from "./SttrL_List.vue";
import Search from "./SttrL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    SttrSearchCatalog : SttrSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      DestinationWarehouseID: null,
      SourceWarehouseID: null
    },
    dstWarehouseOptions: {},
    srcWarehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.dstWarehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, SttrSearchCatalog.DestinationWarehouseID.Label
      );
      this.srcWarehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, SttrSearchCatalog.SourceWarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.srcWarehouseOptions = {};
      this.dstWarehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.SourceWarehouseID === null) {
        this.searchData.SourceWarehouseID = 
          this.$kst.Session.getDefaultWarehouseID();
      }
      if (this.searchData.DestinationWarehouseID === null) {
        this.searchData.DestinationWarehouseID = 
          this.$kst.Search.OptionAll;
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.DestinationWarehouseID =
        this.$kst.Search.OptionAll;
      this.searchData.SourceWarehouseID =
        this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        dstwarehouse: this.$kst.Search.getValue(
          this.searchData.DestinationWarehouseID),
        srcwarehouse: this.$kst.Search.getValue(
          this.searchData.SourceWarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>