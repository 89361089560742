<template>
  <div class="block">
    <kst-output field="TypeID" :data="itemData" :catalog="Catalog"/>
    <kst-output field="Name" :data="itemData" :catalog="Catalog"/>
    <kst-section confirm-mode>
      <kst-input field="SKU" :data="userData" :catalog="Catalog"
        :customValidations="skuValidations"
      />
    </kst-section>
  </div>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";

export default {
  props: {
    itemData: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  computed: {
    skuValidations() {
      return Model.Delete.validationSKU(this.itemData);
    }
  }
}
</script>