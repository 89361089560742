import App from "../../services/App/App.js";
import Catalog from "./ItemCatalog.js";

export default {
  getItemSectionHelp(itemData) {
    let barcode = App.Value.getValue("Barcode", itemData, Catalog);

    return Catalog.Name.Label + ": " + 
      App.Value.getValue("Name", itemData, Catalog) + ", " + 
      Catalog.SKU.Label + ": " + 
      App.Value.getValue("SKU", itemData, Catalog) + 
      (barcode !== null 
        ? ", " + Catalog.Barcode.Label + ": " + barcode
        : ""
      )
  },

  validationSKU(itemData) {
    return {
      [App.Vee.Rule.Is]: {
        target: itemData.SKU,
        label: Catalog.SKU.Label
      }
    };
  }
}