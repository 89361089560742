<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Info from "../TrxInfo.js";
import Model from "../TrxModel.js";
import TrxListCatalog from "../TrxListCatalog.js";


export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      Model.updateTransactionList(data.List, this.access);
      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(TrxListCatalog.Date);
      this.addColumnHeader(TrxListCatalog.ModuleName);
      this.addColumnHeader_ID();
      this.addColumnHeader(TrxListCatalog.Number);
      this.addColumnHeader_ID(TrxListCatalog.WarehouseID.Label);
      this.addColumnHeader(TrxListCatalog.WarehouseID);
      this.addColumnHeader_ID(TrxListCatalog.WarehouseID2.Label);
      this.addColumnHeader(TrxListCatalog.WarehouseID2);
      this.addColumnHeader_ID(TrxListCatalog.DispatchID.Label);
      this.addColumnHeader(TrxListCatalog.DispatchID);
      this.addColumnHeader(TrxListCatalog.Qty);
      this.addColumnHeader(TrxListCatalog.Packaging);
      this.addColumnHeader(TrxListCatalog.Price);
      this.addColumnHeader(TrxListCatalog.Total);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("Date", row, TrxListCatalog);
        this.addCellValue("ModuleName", row, TrxListCatalog);
        this.addCellValue_ID(row);
        this.addCellValue("Number", row, TrxListCatalog);
        this.addCellValue("WarehouseID", row, TrxListCatalog);
        this.addCellRelatedValue("WarehouseID", row, TrxListCatalog);
        this.addCellValue("WarehouseID2", row, TrxListCatalog);
        this.addCellRelatedValue("WarehouseID2", row, TrxListCatalog);
        this.addCellValue("DispatchID", row, TrxListCatalog);
        this.addCellRelatedValue("DispatchID", row, TrxListCatalog);
        this.addCellValue("Qty", row, TrxListCatalog);
        this.addCellValue("Packaging", row, TrxListCatalog);

        if (row.HasAmountAccess) {
          this.addCellValue("Price", row, TrxListCatalog);
        }
        else {
          this.addCell(null);
        }

        if (row.HasAmountAccess) {
          this.addCellValue("Total", row, TrxListCatalog);
        }
        else {
          this.addCell(null);
        }
      }

      this.addSheet(Info.Export.Excel.SheetName.TransList);
    }
  }
}
</script>