import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientID: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  Client: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 500,
    Required: true
  },
  ClientPhone: {
    Label: AppCatalog.Field.Phone.Label,
    Type: AppCatalog.Field.Phone.Type,
    MaxLength: 50
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.AltType,
    MaxLength: 1000
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    PrintLabel: AppCatalog.Field.DiscPercent.PrintLabel,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  DiscType: {
    Label: AppCatalog.Field.DiscType.Label,
    Type: AppCatalog.Field.DiscType.Type
  },
  DiscValue: {
    Label: AppCatalog.Field.DiscValue.Label,
    PrintLabel: AppCatalog.Field.DiscValue.PrintLabel,
    Type: AppCatalog.Field.DiscValue.Type,
    MaxLength: AppCatalog.Field.DiscValue.MaxLength,
    MinValue: AppCatalog.Field.DiscValue.MinValue,
    MaxValue: "TotalBruto",
    Output: AppCatalog.Field.DiscValue.Output,
    Required: true
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    PrintLabel: AppCatalog.Field.Date.Label + " Transaksi",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  PaymentReturn: {
    Label: AppCatalog.Field.PaymentReturn.Label,
    Type: AppCatalog.Field.PaymentReturn.Type,
    Output: AppCatalog.Field.PaymentReturn.Output
  },
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MinValue: "Total",
    Required: true
  },
  PONumber: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.Reference.Type,
    MaxLength: 50,
    Highlight: true
  },
  SONumber: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  TotalBruto: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  TotalDisc: {
    Label: AppCatalog.Field.TotalDisc.Label,
    Type: AppCatalog.Field.TotalDisc.Type,
    ReadOnly: AppCatalog.Field.TotalDisc.ReadOnly,
    Output: AppCatalog.Field.TotalDisc.Output
  },
  TotalItems: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    HelpLabel: "item",
    Type: InputType.Integer,
    ReadOnly: true
  },
  TotalQty: {
    Label: AppCatalog.Field.TotalQty.AltLabel,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse  
  }
}