<template>
  <kst-page-search result
    :catalog="DashSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <Result
        :access="access"
        :searchData="searchData"
        :transDetails="data.TransactionDetails"
      />
    </template>
  </kst-page-search>
</template>

<script>
import Model from "../DashModel.js";
import DashSearchCatalog from "../DashSearchCatalog.js";
import Services from "../../../services/Api/DashboardServices.js";

import Result from "./DashR_Result.vue";
import Search from "./DashR_Search.vue";

export default {
  components: {
    Result,
    Search
  },
  data: () => ({
    Model: Model,
    DashSearchCatalog: DashSearchCatalog,
    access: {},
    searchData: {
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadSearchData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getReportData(options);
      loadSearchData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, DashSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadReport) {
      const options = this.getListOptions();
      const config = Services.getReport(options);
      loadReport(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID)
      };
    }
  }
}
</script>