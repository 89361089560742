<template>
  <kst-print-table :data="invoiceList">
    <b-table-column
      v-slot="{ column, row }"
      field="InvoiceDate"
      :label="SlrcInvCatalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row}"
      field="SONumber"
      :label="SlrcInvCatalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row}"
      field="ClientID"
      :label="SlrcInvCatalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="PaymentTypeName"
      :label="SlrcInvCatalog.PaymentTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="SlrcInvCatalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="SlrcInvCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import SlrcInvCatalog from "../SlrcInvCatalog.js";

export default {
  props: {
    invoiceList: Array
  },
  data: () => ({
    SlrcInvCatalog: SlrcInvCatalog
  })
}
</script>