import App from "@/services/App/App.js";
import AppModule from "@/services/App/AppModule.js";

export default {
  // required in model
  Module: AppModule.ItemType,

  /*** related ***/

  /*** property ***/

  /*** method ***/

  createOptions(items, optionAllText) {
    return this.helper.createOptions(items, optionAllText);
  },

  helper: {
    createOptions(items, optionAllText) {
      const rowId = "ID";
      const rowLabel = "Name";

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select items
      const selectItems = App.Search.createList(items, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectItems);
    }
  }
}