<template>
  <div>
    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="ExpenseNumber"
    />

    <kst-input field="SubCategoryID" :data="userData" :catalog="Catalog"
      :options="categoryOptions"
    />
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
    <kst-input field="PaymentTypeName" :data="userData" :catalog="Catalog"
      :options="paymentOptions"
    />
    <kst-input field="Total" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ExpCatalog.js";

export default {
  props: {
    userData: Object,
    categoryOptions: Object,
    paymentOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>