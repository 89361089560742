import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.debtcard;

export default {
  getExport(params) {
    const attributes = ["startdate", "enddate", "type", "vendor", "vendorname",
      "warehouse"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/export" + urlParams
    };
  },
  getPrintData(params) {
    const attributes = ["startdate", "enddate", "type", "vendor", "vendorname",
      "warehouse"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdata" + urlParams
    };
  },
  getRelatedVendorList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/vendor/list" + urlParams
    };
  },
  getReport(params) {
    const attributes = ["startdate", "enddate", "type", "vendor", "vendorname",
      "warehouse"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/report" + urlParams
    };
  },
  getReportData(params) {
    const attributes = ["startdate", "enddate", "type", "vendor", "vendorname",
      "warehouse"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/reportdata" + urlParams
    };
  }
}