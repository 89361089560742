import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label,
    Type: AppCatalog.Field.Barcode.Type,
    ReadOnly: true,
    Highlight: true
  },
  HppPerPcs: {
    Label: AppCatalog.Field.HppPerPcs.Label,
    Type: AppCatalog.Field.HppPerPcs.Type,
    Output: AppCatalog.Field.HppPerPcs.Output,
    ReadOnly: AppCatalog.Field.HppPerPcs.ReadOnly
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    ResultLabel: "Total Nilai Stok",
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  ItemID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item
  },
  LastInvoiceDate: {
    Label: AppCatalog.Field.LastInvoiceDate.Label,
    Type: AppCatalog.Field.LastInvoiceDate.Type,
    ReadOnly: true
  },
  LimitQty: {
    Label: AppCatalog.Field.LimitQty.Label,
    Type: AppCatalog.Field.LimitQty.Type,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true,
    Highlight: true
  },
  Quantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true,
    Highlight: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  Weight: {
    Label: AppCatalog.Field.Weight.Label,
    Type: AppCatalog.Field.Weight.Type,
    ReadOnly: true
  },

  // support - default packaging
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  TypeID: {
    Label: AppModule.ItemType.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "TypeName",
    Required: true
  }
}