<template>
  <div>
    <kst-input load-on-focus
      field="CategoryID" :data="userData" :catalog="Catalog"
      :options="categoryOptions"
    />
    <kst-input field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input field="IsActive" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ExpcCatalog.js";

export default {
  props: {
    userData: Object,
    categoryOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>