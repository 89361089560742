import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  AdjNumber: {
    Label: AppCatalog.Field.Number.Label + " Opname",
    PrintLabel: AppCatalog.Field.Number.Label,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 45,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Opname",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  }
}