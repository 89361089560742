<template>
  <kst-table input-mode :data="userVariantList">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Type"
      :label="VariantCatalog.Type.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="VariantCatalog"
        :index="index" :customValidations="getTypeValidations(row)"
        :placeholder="getTypePlaceholder()"
        @error="handleErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="VariantCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="VariantCatalog"
        :index="index" :customValidations="getNameValidations(row)"
        :placeholder="getNamePlaceholder()"
        @error="handleErrors"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import VariantCatalog from "../VariantCatalog.js";

export default {
  props: {
    userVariantList: Array
  },
  data: () => ({
    VariantCatalog: VariantCatalog,
    Model: Model
  }),
  methods: {
    handleErrors(errors, field, index) {
      Model.setVariantErrors(this.userVariantList[index], field, errors);
    },

    getNameValidations(row) {
      return Model.validationVariantName(row);
    },
    getTypeValidations(row) {
      return Model.validationVariantType(row);
    },
    getNamePlaceholder(){
      return Info.Placeholder.VariantName;
    },
    getTypePlaceholder(){
      return Info.Placeholder.VariantType;
    }
  }
}
</script>