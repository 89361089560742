<template>
  <kst-section items-mode class="block">
    <ReceiveSearchItem
      :items="receiveItemsSelection"
      @init="handleReceiveItem_init"
      @submit="handleReceiveItem_Submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        tag="button"
        :label="Info.Button.ReceiveItem.Label"
        :moduleType="Model.ModuleType.New"
        @click="handleAdd_ReceiveItem"
      />
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0" >
        <kst-button action-mode
          class="is-primary"
          tag="button"
          :label="Info.Button.ReceiveItem.Label"
          :moduleType="Model.ModuleType.New"
          @click="handleAdd_ReceiveItem"
        />
      </template>
    </template>

    <kst-warning class="mb-4" :items="itemsWarning"/>

    <List :userData="userData" :userItems="userItems"/>
  </kst-section>
</template>

<script>
import Info from "../RrcvInfo.js";
import Model from "../RrcvModel.js";

import ReceiveSearchItem from "../../Receive/SearchItem/RcvScI.vue";
import List from "./RrcvE_Items_List.vue";

export default {
  components: {
    ReceiveSearchItem,
    List
  },
  props: {
    receiveItems: Array,
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Info: Info,
    Model: Model,
    receiveItemsSelection: [],
    showReceiveItemFunc: null,
  }),
  computed: {
    itemsWarning() {
      return [
        Info.Message.Edit_ReceiveItem
      ];
    }
  },
  methods: {
    handleReceiveItem_init(show) {
      this.showReceiveItemFunc = show;
    },
    handleReceiveItem_Submit(row) {
      let item = Model.createItem();
      Model.Edit.setItemByReceiveItem(item, row);
      Model.updateItem(item);
      this.userItems.push(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleAdd_ReceiveItem() {
      this.receiveItemsSelection = Model.getReceiveItems_ForSelection(
        this.userItems, this.receiveItems 
      );
      this.showReceiveItemFunc();
    }
  }
}
</script>