import AppModule from "@/services/App/AppModule.js";
import AppModuleType from "@/services/App/AppModuleType.js";

export default {
  // required in model
  /*** related ***/
  Item: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },
  Purchase: {
    Module: AppModule.Purchase,
    ModuleType: {
      New: AppModuleType.New
    }
  },
  Receive: {
    Module: AppModule.Receive,
    ModuleType: {
      New: AppModuleType.New
    }
  },
  Sales: {
    Module: AppModule.Sales,
    ModuleType: {
      New: AppModuleType.New
    }
  },
  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /** property **/
  ActionList: [
    AppModule.Item,
    AppModule.Purchase,
    AppModule.Receive,
    AppModule.Sales,
    AppModule.Invoice
  ],
  ActionModuleType: AppModuleType.New,

  // menu
  getMenuLabel(module) {
    return this.helper.getMenuLabel(module);
  },

  helper: {
    getMenuLabel(module) {
      return module.Name;
    }
  }
}