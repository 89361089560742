<template>
  <kst-modal-view
    :title="Info.Dialog.SummaryList.Title"
    :width="Info.Dialog.SummaryList.Width"
    @init="handleModal_init"
  >
    <kst-table view-mode :data="summaryObj.List">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="SubCategoryName"
        :label="Catalog.SubCategoryName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>

      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="has-text-weight-bold"
        field="Total"
        :label="Catalog.Total.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>

      <template #footer v-if="summaryObj.List.length > 0">
        <th class="is-hidden-mobile"/>
        <th class="is-hidden-mobile">
          <div class="th-wrap">{{ Info.Summary.Total }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-numeric">
            <kst-value field="Total" :data="summaryObj" :catalog="Catalog"/>
          </div>
        </th>
      </template>
    </kst-table>
  </kst-modal-view>
</template>

<script>
import Catalog from "../ExpCatalog.js";
import Info from "../ExpInfo.js";

export default {
  props: {
    summaryObj: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    // func
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show() {
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_init(show) {
      this.showFunc = show;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    }
  }
}
</script>