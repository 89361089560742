<template>
  <div class="block">
    <kst-output field="TypeID" :data="details" :catalog="Catalog"/>
    <kst-output field="Name" :data="details" :catalog="Catalog"/>
    <kst-output field="SKU" :data="details" :catalog="Catalog"/>
    <kst-output field="Barcode" :data="details" :catalog="Catalog"/>
    <kst-output field="Comment" :data="details" :catalog="Catalog"/>
    <kst-output field="ImageSrc" :data="details" :catalog="Catalog">
      <kst-image read-only thumbnail :src="details.ImageSrc"/>
    </kst-output>
    <kst-created :data="details"/>
    <kst-last-updated :data="details"/>
  </div>
</template>

<script>
import Catalog from "../ItemCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>