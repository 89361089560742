<template>
  <div class="block">
    <kst-input field="Date" :data="userData" :catalog="Catalog"/>
    <kst-input field="PONumber" :data="userData" :catalog="Catalog"/>
    <kst-input field="ClientAddress" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SalesCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>