import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./SlsModel_Body.js";
import SlsItemCatalog from "./SlsItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  setDetailsByData(userData, salesData, invoiceCount, discTypeEnum) {
    userData.ID = salesData.ID;
    // user input
    userData.WarehouseID = salesData.WarehouseID;
    userData.DraftNumber = App.In.getString(salesData.DraftNumber);
    userData.Date = App.In.getDate(salesData.Date);
    userData.ClientID = salesData.ClientID;
    userData.ClientName = App.In.getString(salesData.ClientName);
    userData.ClientAddress = App.In.getString(salesData.ClientAddress);
    userData.PONumber = App.In.getString(salesData.PONumber);
    userData.Comment = App.In.getString(salesData.Comment);
    userData.DeliveryAddress = App.In.getString(salesData.DeliveryAddress);
    userData.DeliveryDate = App.In.getDate(salesData.DeliveryDate);

    if (userData.DeliveryDate === null) {
      userData.DeliveryDate = App.In.getDateToday();
    }

    userData.IsAutoNumber = false;
    // defined by system
    userData.ClientAlias = salesData.ClientAlias;
    userData.ClientMobile = salesData.ClientMobile;
    userData.ClientPhone = salesData.ClientPhone;
    userData.ClientEmail = salesData.ClientEmail;
    userData.InvoiceCount = invoiceCount;
    userData.WarehouseName = salesData.WarehouseName;

    if (salesData.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
      userData.DiscValue = App.In.getInteger(salesData.DiscValue);
    }
    else if (salesData.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(salesData.DiscPercent);
    }
    else {
      userData.DiscType = discTypeEnum.None.ID;
    }
  },

  setItemsByData(userItems, salesItems) {
    App.Array.truncate(userItems);

    for (const row of salesItems) {
      let userItem = ModelBody.createItem();
      this.setItemsByData_setItem(userItem, row);
      ModelBody.updateItem(userItem);
      userItems.push(userItem);
    }
  },
  setItemsByData_setItem(userItem, itemData) {
    userItem.ID = itemData.ID;
    // user input
    userItem.DispatchID = itemData.DispatchID;
    userItem.DispatchName = App.In.getString(itemData.DispatchName);
    userItem.PackagingName = itemData.PackagingName;
    userItem.Qty = App.In.getInteger(itemData.Qty);
    userItem.SellPrice = App.In.getInteger(itemData.SellPrice);
    userItem.IsClosed = App.In.getBoolean(itemData.IsClosed);
    // by system
    userItem.PackagingValue = itemData.PackagingValue;
    userItem.SKU = itemData.SKU;
    userItem.QtySalesOrder = itemData.QtySalesOrder;
    userItem.StockQty = itemData.StockQuantity;
    userItem.DispatchIsActive = App.In.getBoolean(itemData.DispatchIsActive);

    // handle deleted-item
    if (itemData.PackagingList) {
      userItem.PackagingOptions = ItemModel.createPackagingOptions(
        itemData.PackagingList
      );

      const packaging = App.Array.searchItem(
        itemData.PackagingList, "Name", userItem.PackagingName
      );

      if (packaging !== null) {
        userItem.DispatchSellPrice = packaging.SellPrice;

        // if: have no invoice and inactive packaging
        if (userItem.QtySalesOrder === 0 && packaging.IsActive === 0) {
          // reset packaging
          userItem.PackagingName = "";
          userItem.PackagingValue = 1;
        }
      }
    }
  },

  getItemInfo(userItem) {
    let infoList = [];

    // deleted item
    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(SlsItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, SlsItemCatalog)
      );

      // Stock
      infoList.push(SlsItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", userItem, SlsItemCatalog) + " " +
        userItem.PackagingName
      );
    }

    // QtySalesOrder
    infoList.push(SlsItemCatalog.QtySalesOrder.Label + ": " +
      App.Value.getValue("QtySalesOrder", userItem, SlsItemCatalog)
    );

    return infoList;
  },

  validateItem(userItem) {
    userItem.Qty_Valid = true;
    userItem.Qty_vsInvoice = true;
    userItem.Qty_vsDeleted = true;

    // validate: deleted item
    if (!userItem.DispatchIsActive) {
      if (!userItem.IsClosed) {
        if (userItem.QtySalesOrder === 0) {
          userItem.Qty_Valid = false;
          userItem.Qty_vsDeleted = false;
        }
      }
    }

    const qty = App.Out.getInteger(userItem.Qty);
    if (qty !== null) {
      if (qty < userItem.QtySalesOrder) {
        userItem.Qty_Valid = false;
        userItem.Qty_vsInvoice = false;
      }
    }
  }
}