<template>
  <div>
    <kst-output print-mode field="ReceiveNumber" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="Date" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="PurchasingNumber" :data="data" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../RcvCatalog.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>