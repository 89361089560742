<template>
  <div>
    <div class="columns my-0 is-multiline">
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="PlosSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="PlosSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PlosSearchCatalog from "../PlosSearchCatalog.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    PlosSearchCatalog: PlosSearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>