<template>
  <kst-modal-select
    :class="Info.Dialog.SearchItem.Class"
    :title="Info.Dialog.SearchItem.Title"
    :width="800"
    @init="handleModal_Init"
    @submit="handleModal_Submit"
  >
    <template #search-result="{ userData }">
      <List
        :access="access"
        :items="items"
        :userData="userData"
      />
    </template>
  </kst-modal-select>
</template>

<script>
import Info from "../PurInfo.js";
import Model from "../PurModel.js"

import List from "./PurScI_List.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    List
  },
  props: {
    items: Array
  },
  data: () => ({
    Info: Info,
    access: {},
    selectedPurchaseItem: null,
    getRelatedService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getRelatedService) {
      this.getRelatedService = getRelatedService;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.access = this.$kst.Access.create(Model.Access);

      this.showFunc = show;
      this.hideFunc = hide;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_Submit(selected, loadRelated) {
      if (this.getRelatedService) {
        this.selectedPurchaseItem = selected;
        const config = this.getRelatedService(selected.ID);
        loadRelated(config, this.handleModal_Submit_Success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit, selected);
        this.hideFunc();
      }
    },
    handleModal_Submit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, this.selectedPurchaseItem, data);
      this.hideFunc();
    }
  }
}
</script>