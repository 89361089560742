import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  // general
  Search: {
    Label: AppCatalog.Field.Search.Label,
    Type: AppCatalog.Field.Search.Type,
    Param: AppCatalog.Field.Search.Param 
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.ID.Param
  },
  ClientName: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.Name.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Param: AppCatalog.Field.Date.Param
  },
  PaymentTypeName: {
    Label: AppModule.PaymentType.FullName,
    Type: AppCatalog.Field.Name.Type,
    Param: AppModule.PaymentType.Param
  },
  StatusID: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.ID.Type,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  SubCategoryID: {
    Label: AppModule.ExpenseCategory.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppModule.ExpenseCategory.Param + AppCatalog.Field.ID.Param
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppModule.Vendor.Param + AppCatalog.Field.ID.Param
  },
  VendorName: {
    Label: AppModule.Vendor.FullName,
    Type: AppCatalog.Field.Name.Type,
    Param: AppModule.Vendor.Param + AppCatalog.Field.Name.Param
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  },

  // Invoice
  Client: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.Name.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  InvoiceDate: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Param: AppCatalog.Field.Date.Param
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.ID.Type,
    Param: AppCatalog.Field.Status.Param + AppCatalog.Field.ID.Param
  },
  RecordTypeID: {
    Label: AppCatalog.Field.RecordTypeID.Label,
    Type: AppCatalog.Field.RecordTypeID.Type,
    Param: App.Enum.Param.Invoice.RecordTypeID
  },

  // StockTrf
  DestinationWarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppCatalog.Field.Destination.Param + AppModule.Warehouse.Param 
      + AppCatalog.Field.ID.Param
  },
  SourceWarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}