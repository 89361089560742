import ModelBody from "./PlosModel_Body.js";
import ModelExport from "./PlosModel_Export.js";
import ModelPrint from "./PlosModel_Print.js";
import ModelReport from "./PlosModel_Report.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Search: ModelBody.Search,

  /*** method ***/

  createChart() {
    return ModelBody.createChart();
  },
  createSummary() {
    return ModelBody.createSummary();
  },

  getChartXLabel(searchData) {
    return ModelBody.getChartXLabel(searchData);
  },

  /*** method - Export ***/

  Export: {
    getSummary(transactionDetails, searchData) {
      return ModelExport.getSummary(transactionDetails, searchData);
    }
  },

  /*** method - Print ***/

  Print: {
    getTotalInfo(summaryData) {
      return ModelPrint.getTotalInfo(summaryData);
    },
    getSummary(transactionDetails, searchData) {
      return ModelPrint.getSummary(transactionDetails, searchData);
    }
  },

  /*** method - Report ***/

  Report: {
    getChart(transactionDetails, searchData) {
      return ModelReport.getChart(transactionDetails, searchData);
    },
    getSummary(transactionDetails, access, searchData) {
      return ModelReport.getSummary(transactionDetails, access, searchData);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}