<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Items
      :itemList="itemList"
      :userData="userData"
      :userItems="userItems"
      :specialPriceOptions="specialPriceOptions"
    />

    <div class="columns">
      <div class="column is-half">
        <Details2 :userData="userData"/>
      </div>
      <div class="column">
        <Total :userData="userData"/>
      </div>
    </div>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../SpadModel.js";
import Services from "../../../services/Api/SpecialPriceAdjServices.js";

import Details from "./SpadN_Details.vue";
import Details2 from "./SpadN_Details2.vue";
import Items from "./SpadN_Items.vue";
import Total from "./SpadN_Total.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Details2,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    itemList: [],
    specialPriceOptions: {},
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.itemList = data.ItemList;
      this.specialPriceOptions = Model.getSpecialPriceOptions(
        data.SpecialPriceList
      );
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.setDetailsBySpecialPrice(this.userData,
        this.specialPriceOptions.rows.length);
      Model.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.itemList = [];
      this.specialPriceOptions = {};
      this.warehouseOptions = {};
    }
  }
}
</script>