import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Warehouse.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Warehouse.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete
  },
  Section: {
    CompanyProfile: {
      Title: AppCatalog.Field.Alias.Label + " " + AppModule.CompanyProfile.FullName
    }
  },
}