import AppModule from "./AppModule.js";

export default {
  ActivityType: {
    // order by ID
    Receive: {
      ID: 2,
      Module: AppModule.Receive
    },
    Invoice: {
      ID: 3,
      Module: AppModule.Invoice
    },
    StockTrf: {
      ID: 4,
      Module: AppModule.StockTrf
    },
    StockOpname: {
      ID: 5,
      Module: AppModule.StockOpname
    },
    RtnInvoice: {
      ID: 7,
      Module: AppModule.RtnInvoice
    },
    HppAdj: {
      ID: 8,
      Module: AppModule.HppAdj
    },
    StockReduction: {
      ID: 9,
      Module: AppModule.StockReduction
    },
    StockRepair: {
      ID: 10,
      Module: AppModule.StockRepair
    },
    InitialData: {
      ID: 11,
      Module: AppModule.InitialData
    },
    StockConv: {
      ID: 12,
      Module: AppModule.StockConv
    },
    RtnReceive: {
      ID: 13,
      Module: AppModule.RtnReceive
    }
  },

  Axios: {
    Method: {
      Delete: "delete",
      Get: "get",
      Patch: "patch",
      Post: "post",
      Put: "put"
    }
  },

  // true-false match: see "@\services\App\App_Formatter.js"
  Boolean: {
    // true value
    Active: {
      Label: "Aktif"
    },
    Auto: {
      Label: "Otomatis"
    },
    True: {
      Label: "True"
    },
    Yes: {
      Label: "Ya"
    },

    // false value
    False: {
      Label: "False"
    },
    Inactive: {
      Label: "Non Aktif"
    },
    Manual: {
      Label: "Manual"
    },
    No: {
      Label: "Tidak"
    }
  },

  // breakline
  Breakline: {
    Html: "<br/>",
    Text: "\n"
  },

  // events name
  Event: {
    Cancel: "cancel",
    Callback: "callback",
    Change: "change",
    Check: "check",
    Click: "click",
    Clear: "clear",
    Delete: "delete",
    Edit: "edit",
    Export: "export",
    Error: "error",
    Focus: "focus",
    Init: "init",
    Input: "input",
    Load: "load",
    Loaded: "loaded",
    Menu: "menu", // Layout
    NewItem: "newitem",
    Reset: "reset",
    ResetFailure: "resetfailure",
    Save: "save",
    Search: "search",
    Sort: "sort", // buefy
    Submit: "submit",
    Success: "success"
  },

  Input: {
    Select: {
      Key: {
        Label: "label",
        Rows: "rows",
        Value: "value"
      }
    }
  },

  ItemType: {
    Operational: {
      ID: 1,
      Name: "Operational"
    },
    Sellable: {
      ID: 2,
      Name: "Sellable Item"
    }
  },

  // url param keys
  Param: {
    Invoice: {
      RecordTypeID: "ispos"
    },
    // DEPRECATED 
    // (see: AppCatalog/Field/<field>/Param, or AppModule/<module>/Param)
    Date: "date",
    EndDate: "enddate",
    ID: "id",
    Message: "message",
    Name: "name",
    Option: "opt",
    Reference: "ref",
    Search: "search",
    SearchResult: "searchresult",
    Sequence: "seq",
    ServiceID: "sid",
    StartDate: "startdate",
    Token: "id_token",
    // prefix - with
    With: "with", // general prefix
    WithDispatch: "withdispatch",
    // entity
    DispatchID: "dispatchid",
    InvoiceID: "invoiceid",
    PurchaseID: "purchaseid",
    PurchaseItemID: "purchaseitemid",
    StatusID: "statusid",
    WarehouseID: "warehouseid"
  },

  ParamValue: {
    Boolean: {
      False: "0",
      True: "1"
    }
  },

  Request: {
    Header: {
      UIVersion: "ui-version"
    },
    ID: "id"
  },

  Response: {
    ID: "id"
  },

  Status: {
    Active: {
      Class: "is-success is-light",
      Label: "Aktif"
    },
    Closed: {
      Class: "is-light",
      Label: "Closed"
    },
    Delivered: {
      Class: "is-success is-light",
      Label: "Terkirim"
    },
    Draft: {
      Class: "is-light is-light",
      Label: "Persiapan"
    },
    InActive: {
      Class: "is-light",
      Label: "Non Aktif",
      AltClass: "is-danger",
      AltLabel: "Terhapus"
    },
    InDelivery: {
      Class: "is-warning is-light",
      Label: "Dikirim"
    },
    Open: {
      Class: "is-success is-light",
      Label: "Open"
    },
    Pos: {
      Class: "is-success is-light",
      Label: AppModule.Pos.FullName
    },
    Returned: {
      Class: "is-danger is-light",
      Label: "Returned"
    },
    Void: {
      Class: "is-danger is-light",
      Label: "Batal"
    }
  }
}