<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
      </b-tab-item>

      <template v-if="access.Invoice_Act_List">
        <template v-if="invoiceList">
          <b-tab-item :label="tabs.InvoiceList">
            <InvoiceList
              :details="details"
              :invoiceList="invoiceList"
              :paymentTypeSummaryList="paymentTypeSummaryList"
            />
          </b-tab-item>
        </template>
      </template>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../SlrcInfo.js";
import Model from "../SlrcModel.js";
import Services from "../../../services/Api/SalesRecapServices.js";

import Details from "./SlrcD_Details.vue";
import InvoiceList from "./SlrcD_InvList.vue";

export default {
  components: {
    Details,
    InvoiceList
  },
  data: () => ({
    access: {},
    actions: [],
    invoiceList: [],
    paymentTypeSummaryList: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      InvoiceList: Info.Tabs.InvoiceList
    }
  }),
  methods: {
    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;

      if (Object.prototype.hasOwnProperty.call(data, "InvoiceList")) {
        this.invoiceList = data.InvoiceList;
        Model.setDetailsByPaymentTypeCash(this.details, data.PaymentTypeCash);
        this.paymentTypeSummaryList = Model.Details.getPaymentTypeSummaryList(
          this.invoiceList
        );
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.invoiceList = null;
      this.paymentTypeSummaryList = null;

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>