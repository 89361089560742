<template>
  <div class="kstmod-rtnreceive-relatedlist">
    <Rows :access="access" :dataList="dataList"/>
  </div>
</template>

<script>
import Model from "../RrcvModel.js";

import Rows from "./RrcvRL_Rows.vue";

export default {
  components: {
    Rows
  },
  props: {
    action: Boolean,
    dataList: Array
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      for (const row of this.dataList) {
        for (const item of row.Items) {
          Model.updateItemReadOnly(item);
        }
      }
    }
  }
}
</script>