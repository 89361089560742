<template>
  <kst-page-delete
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details 
      :purchaseData="purchaseData" 
      :receiveList="receiveList"
      :rtnReceiveList="rtnReceiveList"
      :userData="userData"
    />
    <Purchase 
      :access="access"
      :purchaseData="purchaseData"
    />
    <Receive :receiveList="receiveList"/>
    <RtnReceive :rtnReceiveList="rtnReceiveList"/>
  </kst-page-delete>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import Details from "./PurDL_Details.vue";
import Purchase from "./PurDL_Purchase.vue";
import Receive from "./PurDL_Receive.vue";
import RtnReceive from "./PurDL_RtnReceive.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Receive,
    RtnReceive,
    Purchase
  },
  data: () => ({
    access: {},
    receiveList: [],
    rtnReceiveList: [],
    purchaseData: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDelete) {
      this.resetData();

      const config = Services.getDeleteData(id);
      loadDelete(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.purchaseData = data.Details;

      Model.setDetailsByStatus(this.purchaseData);

      for (const item of this.purchaseData.Items) {
        Model.updateItemReadOnly(item);
      }

      this.receiveList = data.ReceiveList;
      this.rtnReceiveList = data.ReturnReceiveList;
    },

    handleSubmit(id, saveData) {
      const config = Services.deleteData(id);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.receiveList = [];
      this.rtnReceiveList = [];
      this.purchaseData = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>