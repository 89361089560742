<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StockTrfItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockTrfItemCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StockTrfItemCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StockTrfItemCatalog">
        <kst-value field="Packaging" :data="row" :catalog="StockTrfItemCatalog"/>
      </kst-value>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="StockTrfItemCatalog.HppTotal.Label"
      >
        <kst-value
          :field="column.field" :data="row" :catalog="StockTrfItemCatalog"
        />
      </b-table-column>
    </template>
  </kst-print-table>
</template>

<script>
import StockTrfItemCatalog from "../SttrItemCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    StockTrfItemCatalog: StockTrfItemCatalog
  })
}
</script>