<template>
  <div>
    <VendorSearchList
      @init="handleVendor_init"
      @submit="handleVendor_submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-3">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="PurSearchCatalog"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-output addons floating-label 
          field="VendorID" :data="searchData" :catalog="PurSearchCatalog"
        >
          <template #default>
            <kst-input floating-label load-on-focus
              field="Vendor" :data="searchData" :catalog="PurSearchCatalog"
              :disabled="vendorDisabled" @input="handleInput"
            />
          </template>
          <template #right>
            <div class="control">
              <template v-if="searchData.VendorID === null">
                <kst-tooltip search-mode hover-only :content="PurSearchCatalog.VendorID.Label">
                  <kst-button search-mode @click="handleSearch_vendor"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="PurSearchCatalog.VendorID.Label">
                  <kst-button clear-mode @click="handleClear_vendor"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div> 
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="PurSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Status" :data="searchData" :catalog="PurSearchCatalog"
          :options="statusOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="PurSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PurSearchCatalog from "../PurSearchCatalog.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import VendorSearchList from "../../Vendor/SearchList/VenScL.vue";

export default {
  components: {
    VendorSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    PurSearchCatalog: PurSearchCatalog,
    // func
    showVendorFunc: null
  }),
  computed: {
    vendorDisabled() {
      return this.searchData.VendorID !== null;
    },
    statusOptions() {
     return Model.createStatusOptions(PurSearchCatalog.Status.Label);
    }
  },
  methods: {
    /*** by Vendor modal ***/

    handleVendor_init(show) {
      this.showVendorFunc = show;
    },
    handleVendor_submit(data) {
      this.searchData.VendorID = data.ID;
      this.searchData.Vendor = data.Name;
      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_vendor() {
      this.showVendorFunc(Services.getRelatedVendorList);
    },
    handleClear_vendor() {
      this.searchData.VendorID = null;
      this.searchData.Vendor = "";

      this.handleInput();
    }
  }
}
</script>