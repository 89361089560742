<template>
  <kst-section :title="sectionTitle">
    <kst-table view-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="InvItemCatalog.Name.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="InvItemCatalog.Packaging.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty-large"
        field="RequestedQuantity"
        :label="InvItemCatalog.RequestedQuantity.SummaryLabel"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty-large"
        field="DeliveryQuantity"
        :label="InvItemCatalog.DeliveryQuantity.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="InvItemCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../InvInfo.js";
import InvItemCatalog from "../InvItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    InvItemCatalog: InvItemCatalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Summary.Title;
    }
  }
}
</script>