<template>
  <kst-page-report exportExcel
    :catalog="DbcdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <Result
        :searchData="searchData"
        :dataList="data.List"
      />
    </template>
  </kst-page-report>
</template>

<script>
import Model from "../DbcdModel.js";
import DbcdSearchCatalog from "../DbcdSearchCatalog.js";
import Services from "../../../services/Api/DebtCardServices.js";

import Result from "./DbcdR_Result.vue";
import Search from "./DbcdR_Search.vue";

export default {
  components: {
    Result,
    Search
  },
  data: () => ({
    DbcdSearchCatalog: DbcdSearchCatalog,
    Model: Model,
    searchData: {
      TransactionDate: null,
      TypeID: null,
      VendorID: null,
      VendorName: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadReportData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getReportData(options);
      loadReportData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      if (this.searchData.VendorID !== null) {
        this.searchData.VendorName = this.$kst.In.getString(
          data.Search.VendorName);
      }

      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, DbcdSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadReport) {
      const options = this.getListOptions();
      const config = Services.getReport(options);
      loadReport(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.warehouseOptions = {};

      this.normalizeSearch();
    },
    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },
    resetSearch() {
      this.searchData.TransactionDate = this.$kst.In.getDefaultDateRange();
      this.searchData.TypeID = this.$kst.Search.OptionAll;
      this.searchData.VendorID = null;
      this.searchData.VendorName = null;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.searchData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.searchData.VendorName) : null;

      return {
        type: this.$kst.Search.getValue(this.searchData.TypeID),
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.TransactionDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.TransactionDate[1])
      };
    }
  }
}
</script>