<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-3">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="SttrSearchCatalog"
        @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="SourceWarehouseID" :data="searchData" :catalog="SttrSearchCatalog"
        :options="srcWarehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="DestinationWarehouseID" :data="searchData" :catalog="SttrSearchCatalog"
        :options="dstWarehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="SttrSearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import SttrSearchCatalog from "../SttrSearchCatalog.js";

export default {
  props: {
    searchData: Object,
    dstWarehouseOptions: Object,
    srcWarehouseOptions: Object
  },
  data: () => ({
    SttrSearchCatalog: SttrSearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>