<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Sales
      v-if="isSalesVisible"
      :userData="userData"
    />
    <Details
      :userData="userData"
      :userItems="userItems"
    />
    <Items
      :salesData="salesData"
      :userData="userData"
      :userItems="userItems"
      :deliveryItemList="deliveryItemList"
      :stockObj="stockObj"
      :stockSpecialPriceObj="stockSpecialPriceObj"
    />

    <div class="columns">
      <div class="column">
        <Details2
          :userData="userData"
          :userItems="userItems"
        />
      </div>
      <div class="column">
        <Total :userData="userData"/>
      </div>
    </div>

    <Delivery v-if="isDeliveryVisible" 
      :userData="userData"
      :deliveryItemList="deliveryItemList"
    />
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Delivery from "./InvE_Delivery.vue";
import Details from "./InvE_Details.vue";
import Details2 from "./InvE_Details2.vue";
import Items from "./InvE_Items.vue";
import Sales from "./InvE_Sales.vue";
import Total from "./InvE_Total.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Sales,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    deliveryItemList: [],
    invoiceData: {},
    salesData: {},
    stockObj: {},
    stockSpecialPriceObj: {}
  }),
  computed: {
    isSalesVisible() {
      return this.$kst.Session.companyHasAccess(Model.Sales.Module);
    },
    isDeliveryVisible() {
      return this.$kst.Session.companyHasAccess(Model.Delivery.Module);
    }
  },
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.invoiceData = data.Details;

      this.stockObj = Model.Edit.getStockByInvoiceItems(this.invoiceData.Items);
      this.stockSpecialPriceObj = Model.Edit.getStockSpecialPriceByInvoiceItems(
        this.invoiceData.Items
      );

      // sales
      if (this.invoiceData.SalesDraftID !== null && 
        this.invoiceData.SalesDraftIsActive === 1
      ) {
        this.salesData = data.SalesDetails;
        Model.Edit.updateSalesItems(this.salesData, this.invoiceData.Items);
      }

      Model.Edit.setItemsByData(
        this.userData, this.userItems, this.invoiceData, this.stockObj,
        this.stockSpecialPriceObj, this.salesData.Items
      );
      Model.Edit.setDetailsByData(this.userData, this.invoiceData);
      Model.setDetailsByCompanySetting(this.userData, data.CompanySetting);
      Model.setDetailsBySpecialPrice(this.userData, data.SpecialPriceCount);
      Model.updateDetails(this.userData, this.userItems);

      // delivery
      this.deliveryItemList = data.DeliveryItemList;
    },

    handleSubmit(saveData, skipSave) {
      if (Model.Edit.compareData(this.userData, this.userItems, this.invoiceData)) {
        skipSave(
          { [this.$kst.Enum.Response.ID]: this.userData.ID },
          this.handleSubmit_success
        );
        return;
      }

      const data = Model.populateData(this.userData, this.userItems);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.deliveryItemList = [];
      this.invoiceData = {};
      this.salesData = {};
      this.stockObj = {};
      this.stockSpecialPriceObj = {};
    }
  }
}
</script>