<template>
  <kst-section :title="sectionTitle">
    <kst-output field="SelfWarehouseID" :data="details" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog
  }),
  computed: {
    sectionTitle() {
      return Info.Section.SelfService.Title;
    }
  }
}
</script>