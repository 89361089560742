<template>
  <kst-section list-mode
    :title="Info.Section.PackagingList.Title"
  >
    <kst-output field="DefaultPackagingID" :data="details" :catalog="Catalog"/>

    <List :dataList="details.PackagingList"/>
  </kst-section>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";

import List from "./ItemD_PackagingList_List.vue";

export default {
  components: {
    List
  },
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  })
}
</script>