<template>
  <kst-page-export-details
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithDetails"
      :label="Info.Export.Details.Excel.Option.Details"
    />
  </kst-page-export-details>
</template>

<script>
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import Export from "./StcvExD_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    access: {},
    userData: {
      ID: null,
      WithDetails: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id) {
      this.userData.ID = id;

      this.access = this.$kst.Access.create(Model.Access);
    },

    handleExport(loadExport) {
      const config = Services.getExportDetails(this.userData.ID);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>