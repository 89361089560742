import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./ItemCatalog.js";

export default {
  // required in model
  Module: AppModule.Item,
  ModuleType: {
    Delete: AppModuleType.Delete,
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintBarcode: AppModuleType.PrintBarcode,
    PrintList: AppModuleType.PrintList,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  ItemPackaging: {
    Module: AppModule.ItemPackaging,
    ModuleType: {
      List: AppModuleType.List,
      New: AppModuleType.New
    }
  },

  ItemVariant: {
    Module: AppModule.ItemVariant,
    ModuleType: {
      List: AppModuleType.List,
      New: AppModuleType.New
    }
  },

  // support

  Stock: {
    Module: AppModule.Stock,
    ModuleType: {
      List: AppModuleType.List
    }
  },

  /*** property ***/

  Access: {
    Act_Delete: AppModuleAttr.Item_Act_Delete,
    Act_Details: AppModuleAttr.Item_Act_Details,
    Act_Edit: AppModuleAttr.Item_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Item_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Item_Act_ExportList,
    Act_List: AppModuleAttr.Item_Act_List,
    Act_New: AppModuleAttr.Item_Act_New,
    Act_PrintList: AppModuleAttr.Item_Act_PrintList,
    Act_PrintBarcode: AppModuleAttr.Item_Act_PrintBarcode,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.PrintBarcode,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintBarcode,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ]
  },

  Search: {
    ListFields: ["Name", "SKU", "Barcode"],
    ListParams: ["Search"],
    SearchFields: ["Name", "SKU", "Barcode"]
  },

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      RefID: null,
      TypeID: null,
      Name: "",
      SKU: "",
      Barcode: "",
      Comment: "",
      ImageSrc: "",
      LimitQty: "",
      // packaging
      DefaultPackagingID: null,
      // support:
      RefName: null,
      NextPackagingID: -1,
      Image64: "",
      ImageIsLocal: true,
      // validation
      HasActivePackaging: true,
      // error
      PackagingsErrors: []
    };
  },

  setDetailsByRef(userData, refDetails) {
    userData.RefID = refDetails.ID;
    userData.RefName = refDetails.Name;
  },
  setDetailsByTypeList(userData, typeList) {
    for (const row of typeList) {
      if (row.IsDefault) {
        userData.TypeID = row.ID;
        return;
      }
    }
  },

  getSearchListSubTitle(itemList) {
    return itemList.length + " " + AppModule.Item.FullName.toLowerCase() +
      " " + AppCatalog.Message.Selected
  },
  getSearchList_getIdList(itemList){
    let idList = [];

    for (const row of itemList) {
      if (row.ID) {
        idList.push(row.ID);
      }
    }

    return idList;
  },
  getRefSectionHelp(userData) {
    return userData.RefID === null
      ? AppCatalog.Message.Without + " " +
        AppCatalog.Field.Reference.Label + " " + AppModule.Item.FullName
      : Catalog.RefID.Label + ": " + userData.RefName;
  },
  getVariantSet(row, typeField, nameField) {
    return row[typeField] === null
      ? null
      : App.Value.getValue(typeField, row, Catalog) +
        ": " + App.Value.getValue(nameField, row, Catalog);
  },

  clearDetailsByRef(userData) {
    userData.RefID = null;
    userData.RefName = null;
  },

  updateDefaultPackaging(userData, userPackagingList) {
    for (const row of userPackagingList) {
      if (row.ID === userData.DefaultPackagingID) {
        if (row.IsActive) {
          // do nothing
          return;
        }
        break;
      }
    }

    // reset when default packaging is not found or inactive
    userData.DefaultPackagingID = null;
  },

  populateDetails(userData, userVariantList, purchaseItemData) {
    return {
      ID: userData.ID,
      RefID: userData.RefID,
      TypeID: userData.TypeID,
      Name: App.Out.getString(userData.Name),
      SKU: App.Out.getString(userData.SKU),
      Barcode: App.Out.getString(userData.Barcode),
      Comment: App.Out.getString(userData.Comment),
      ImageSrc: userData.ImageIsLocal ? null : userData.ImageSrc,
      Image64: userData.ImageIsLocal ? userData.Image64 : null,
      LimitQty: App.Out.getInteger(userData.LimitQty),
      // packaging
      DefaultPackagingID: userData.DefaultPackagingID,
      // variant
      VariantType1: App.Out.getString(userVariantList[0].Type),
      VariantName1: App.Out.getString(userVariantList[0].Name),
      VariantType2: App.Out.getString(userVariantList[1].Type),
      VariantName2: App.Out.getString(userVariantList[1].Name),
      // new-item
      PurchasingItemID: purchaseItemData === null
        ? null : purchaseItemData.ID
    };
  },
  populatePackagings(userPackagingList) {
    let result = [];
    for (const row of userPackagingList) {
      result.push({
        ID: row.ID,
        Name: App.Out.getString(row.Name),
        Qty: App.Out.getInteger(row.Qty),
        SellPrice: App.Out.getInteger(row.SellPrice),
        Weight: App.Out.getInteger(row.Weight),
        IsActive: App.Out.getBoolean(row.IsActive)
      });
    }

    return result;
  }
}