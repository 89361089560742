<template>
  <div class="columns">
    <div class="column is-half">
      <kst-section class="is-half" :title="RcvDebtPayCatalog.ReceiveDebtTotal.Label">
        <template #top-right>
          <kst-title>{{ debtTotal }}</kst-title>
        </template>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ RcvDebtPayCatalog.ReceiveTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="ReceiveTotal" :data="debtData" :catalog="RcvDebtPayCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ RcvDebtPayCatalog.RtnReceiveTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="RtnReceiveTotal" :data="debtData" :catalog="RcvDebtPayCatalog"/>
            </div>
          </div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ RcvDebtPayCatalog.DebtPayTotal.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="DebtPayTotal" :data="debtData" :catalog="RcvDebtPayCatalog"/>
            </div>
          </div>
        </div>
        <kst-divider columns-mode/>

        <div class="columns is-mobile">
          <div class="column is-narrow">
            {{ RcvDebtPayCatalog.ReceiveDueDate.Label }}
          </div>
          <div class="column">
            <div class="has-text-right">
              <kst-value field="ReceiveDueDate" :data="debtData" :catalog="RcvDebtPayCatalog"/>
            </div>
          </div>
        </div>
      </kst-section>
    </div>
    <div class="column is-half is-hidden-mobile"/>
  </div>
</template>

<script>
import RcvDebtPayCatalog from "../RcvDebtPayCatalog.js";

export default {
  props: {
    debtData: Object
  },
  data: () => ({
    RcvDebtPayCatalog: RcvDebtPayCatalog
  }),
  computed: {
    debtTotal() {
      return this.$kst.Value.getValue("ReceiveDebtTotal", this.debtData,
        RcvDebtPayCatalog
      );
    }
  }
}
</script>