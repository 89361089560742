import AppCatalog from "@/services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 45
  },
  Type: {
    Label: AppCatalog.Field.Type.Label,
    Type: AppCatalog.Field.Type.Type,
    MaxLength: 45
  }
}