import App from "../../services/App/App.js";
import ModelBody from "./RinvModel_Body.js";
import ModelDetails from "./RinvModel_Details.js";
import ModelEdit from "./RinvModel_Edit.js";
import ModelNew from "./RinvModel_New.js";
import ModelPrintThermal from "./RinvModel_PrintThermal.js";
import ModelRelatedList from "./RinvModel_RelatedList.js";
import InvoiceModel from "../Invoice/InvModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  RtnInvoiceItem: ModelBody.RtnInvoiceItem,
  // other
  Item: ModelBody.Item,
  Invoice: ModelBody.Invoice,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  DiscType: ModelBody.DiscType,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.DiscType);
  },
  createItem() {
    return ModelBody.createItem();
  },
  createDiscTypeOptions(optionAllText) {
    return ModelBody.createDiscTypeOptions(optionAllText, ModelBody.DiscType);
  },

  setItemErrors(item, field, errors) {
    ModelBody.setItemErrors(item, field, errors);
  },

  getInvoiceSectionHelp(data) {
    return ModelBody.getInvoiceSectionHelp(data);
  },

  updateDetails(data, item) {
    ModelBody.updateDetails(data, item, ModelBody.DiscType);
  },
  updateItem(item) {
    ModelBody.updateItem(item);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItem(item) {
    ModelBody.validateItem(item);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData, ModelBody.DiscType);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(item) {
      return ModelDetails.getItemInfo(item);
    }
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, detailsData) {
      ModelEdit.setDetailsByData(userData, detailsData, ModelBody.DiscType);
    },
    setItemsByData(items, rows, invoiceItemGroup) {
      ModelEdit.setItemsByData(items, rows, invoiceItemGroup);
    },
    setItemByInvoiceItem(item, invoiceItem) {
      ModelEdit.setItemByInvoiceItem(item, invoiceItem);
    },

    getItemInfo(item) {
      return ModelEdit.getItemInfo(item);
    },

    updateInvoiceItems(invoiceItems, returnItems) {
      for (let invoiceItem of invoiceItems) {
        InvoiceModel.updateItem(invoiceItem);
  
        ModelEdit.updateInvoiceItems(invoiceItem, returnItems);
      }
    },
  
    compareData(userData, userItems, rtnInvoiceData) {
      return ModelEdit.compareData(userData, userItems, rtnInvoiceData,
        ModelBody.DiscType
      );
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit || access.Act_PrintThermal;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByInvoice(userData, invoiceDetails) {
      ModelNew.setDetailsByInvoice(userData, invoiceDetails, ModelBody.DiscType);
    },
    setItemByInvoiceItem(item, invoiceItem) {
      ModelNew.setItemByInvoiceItem(item, invoiceItem);
    },

    getItemInfo(item) {
      return ModelNew.getItemInfo(item);
    },

    /*** external ***/

    updateInvoiceItems(invoiceItems) {
      for (let invoiceItem of invoiceItems) {
        InvoiceModel.updateItem(invoiceItem);
      }
    }
  },

  /*** method - PrintThermal ***/

  PrintThermal: {
    updateDetails(data) {
      ModelPrintThermal.updateDetails(data);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getItemInfo(item) {
      return ModelRelatedList.getItemInfo(item);
    }
  },

  /*** related ***/

  getInvoiceItems_ForSelection(items, invoiceItems) {
    return ModelBody.getInvoiceItems_ForSelection(items, invoiceItems);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}