<template>
  <kst-table action paginated view-mode
    :data="items"
    :failure="failureList"
    :loading="isListLoading"
  >
    <b-table-column
      v-slot="{ row }"
      field="SearchTypeID"
      :label="Catalog.SearchTypeID.Label"
    >
      {{ getSearchName(row) }}
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="TransactionName"
      :label="Catalog.TransactionName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Qty"
      :label="Catalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <template v-if="hasAction(row)">
        <kst-tooltip details-mode hover-only>
          <kst-button search-mode is-round
            @click="handleItem_Details(row)"
          />
        </kst-tooltip>
      </template>
    </template>
  </kst-table>
</template>

<script>
import RouterMixin from "../../mixins/RouterMixin.js";
import Catalog from "./GlobalSearchCatalog.js";
import Model from "./GlobalSearchModel.js";
import InvRouter from "../Invoice/InvRouter.js";
import ItemRouter from "../Item/ItemRouter.js";
import ReceiveRouter from "../Receive/RcvRouter.js";
import RtnInvoiceRouter from "../RtnInvoice/RinvRouter.js";

export default {
  mixins: [RouterMixin],
  props: {
    failureList: String,
    isListLoading: Boolean,
    items: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  }),
  methods: {
    handleItem_Details(row) {
      const urlParam = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: row.TransactionID
      });

      switch(row.SearchTypeID) {
        case Model.SearchType.Receive.ID:
          this.openTab(ReceiveRouter.Path.Details + urlParam);
          break;
        case Model.SearchType.Invoice.ID:
          this.openTab(InvRouter.Path.Details + urlParam);
          break;
        case Model.SearchType.RtnInvoice.ID:
          this.openTab(RtnInvoiceRouter.Path.Details + urlParam);
          break;
        case Model.SearchType.Stock.ID:
          this.openTab(ItemRouter.Path.Details + urlParam);
          break;
      }
    },

    getSearchName(row) {
      return Model.getSearchName(row);
    },
    hasAction(row) {
      switch(row.SearchTypeID) {
        case Model.SearchType.Receive.ID:
        case Model.SearchType.Invoice.ID:
        case Model.SearchType.RtnInvoice.ID:
        case Model.SearchType.Stock.ID:
          return true;
      }

      return false;
    }
  }
}
</script>