<template>
  <div>
    <Summary :recData="recData"/>

    <RecPayRelatedList
      :hideAction="recData.InvoiceRecTotal === 0"
      :data="recData"
      :dataList="recData.RecPayList"
    />
  </div>
</template>

<script>
import Summary from "./InvD_RecPay_Summary.vue";
import RecPayRelatedList from "../../RecPay/RelatedList/RcpyRL.vue";

export default {
  components: {
    Summary,
    RecPayRelatedList
  },
  props: {
    recData: Object
  }
}
</script>