import App from "../../services/App/App.js";

export default {
  getPaymentTypeSummaryList(invoiceList) {
    let fieldKeys, fieldValues;
    fieldKeys = ["PaymentTypeName"];
    fieldValues = [
      { field: "Total", type: App.Pivot.Type.SUM }
    ];
    const pivotList = App.Pivot.create(invoiceList, fieldKeys, fieldValues);

    if (Array.isArray(pivotList)) {
      if (pivotList.length > 0) {
        fieldKeys = [{
          [App.Sort.Key.Field]: "PaymentTypeName",
          [App.Sort.Key.Order]: App.Sort.Order.Asc
        }];
        App.Sort.begin(pivotList, fieldKeys);
        return pivotList;
      }
    }

    return [];
  }
}