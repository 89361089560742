import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./StrdModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: AppModuleType.Details.FullName,
          Hpp: "Perhitungan " + AppCatalog.Info.HPP
        },
        // ui
        Option: {
          Details: AppModuleType.Details.FullName + " " +
            AppModule.StockReduction.FullName,
          Hpp: "Perhitungan " + AppCatalog.Info.HPP
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " +
            AppModule.StockReduction.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " +
            AppModule.StockReduction.FullName,
          StrdItem: "Dengan " + AppModule.Item.FullName
        }
      }
    }
  },
  Button: {
    SpecialPrice: {
      Label: AppModule.SpecialPrice.FullName
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.StockReductionItem.Module.FullName + "?",
    New_ConfirmDeleteItems:
      "Yakin menghapus semua " + Model.StockReductionItem.Module.FullName + "?",
    New_DuplicatedItem:
      Model.StockReductionItem.Module.FullName + " telah dipergunakan.",
    New_InsertedItems: // i.e. 10 Barang berhasil ditambahkan.
      " " + Model.StockReductionItem.Module.FullName + " berhasil ditambahkan."
  },
  Section: {
    StockReductionItem: AppModuleType.List.Name + " " + AppModule.Item.FullName
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    HppDetails: "Perhitungan " + AppCatalog.Info.HPP
  }
}