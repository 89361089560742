<template>
  <kst-table detailed sequenceOff view-mode :data="stockSummaryList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="StcdStockCatalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="StcdStockCatalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="StcdStockCatalog.HppTotal.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
      </b-table-column>
    </template>

    <template #detail>
      <kst-container class="p-2">
        <StockList :access="access" :stockList="stockList"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import StcdStockCatalog from "../StcdStockCatalog.js";

import StockList from "./StcdR_Result_StockSummaryList_StockList.vue";

export default {
  components: {
    StockList
  },
  props: {
    access: Object,
    stockList: Array,
    stockSummaryList: Array
  },
  data: () => ({
    StcdStockCatalog: StcdStockCatalog
  })
}
</script>