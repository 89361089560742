<template>
  <kst-page-print-list pageFooter
    v-slot="{ title }"
    :catalog="RecSearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="mt-4" :items="items"/>
  </kst-page-print-list>
</template>

<script>
import Model from "../RecModel.js";
import RecSearchCatalog from "../RecSearchCatalog.js";
import Services from "../../../services/Api/RecServices.js";

import List from "./RecPL_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    RecSearchCatalog: RecSearchCatalog,
    companyData: {},
    items: [],
    searchData: {
      Search: "",
      Client: "",
      ClientID: null, 
      InvoiceDate: null,
      Status: null,
      WarehouseID: null
    },
    searchParams: ["Search", "Client", "ClientID", "InvoiceDate", "Status",
      "WarehouseID"]
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintListData(options);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.companyData = data.CompanyDetails;
      this.items = data.List;

      for (const row of this.items) {
        Model.setDetailsByStatus(row);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.items = [];
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Client = "";
      this.searchData.ClientID = null;
      this.searchData.InvoiceDate = this.$kst.In.getDefaultDateRange();
      this.searchData.Status = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.Client) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[1]),
        client: clientId,
        clientname: clientName,
        status: this.$kst.Search.getValue(this.searchData.Status),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>