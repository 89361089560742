import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, userDetails) {
    userData.ID = userDetails.ID;
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      Password: App.Out.getString(userData.Password)
    };
  }
}