import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ModuleName: {
    Label: "Transaksi",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  AutoNumberTypeID: {
    Label: "Format",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "AutoNumberTypeFormat"
  },
  PrefixSuffix: {
    Label: AppCatalog.Field.PrefixSuffix.Label,
    Type: AppCatalog.Field.PrefixSuffix.Type,
    ReadOnly: true
  },
  Year: {
    Label: AppCatalog.Field.Year.Label,
    Type: AppCatalog.Field.Year.Type,
    Output: AppCatalog.Field.Year.Output,
    ReadOnly: true
  },
  Month: {
    Label: AppCatalog.Field.Month.Label,
    Type: AppCatalog.Field.Month.Type,
    ReadOnly: true,
  },
  Counter: {
    Label: AppCatalog.Field.Counter.Label,
    Type: AppCatalog.Field.Counter.Type,
    ReadOnly: true,
  },
  CounterLength: {
    Label: AppCatalog.Field.CounterLength.Label,
    Type: AppCatalog.Field.CounterLength.Type,
    ReadOnly: true,
  }
}