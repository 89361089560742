<template>
  <div v-if="access.Act_List" class="kstmod-rec-relatedlist">
    <Rows
      :access="access"
      :items="items"
      :client="client"
    />
  </div>
</template>

<script>
import Model from "../RecModel.js";

import Rows from "./RecRL_Rows.vue";

export default {
  components: {
    Rows
  },
  props: {
    items: Array,
    // field visibility (order by appearance)
    client: Boolean
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>