<template>
  <kst-page-edit autoValidate
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :details="details"
      :userData="userData"
    />
    <User :details="details"/>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../UserModel.js";
import Services from "../../../services/Api/UserServices.js";

import User from "./UserEPwd_User.vue";
import Details from "./UserEPwd_Details.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    User,
    Details
  },
  data: () => ({
    details: {},
    userData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditPasswordData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
      Model.EditPassword.setDetailsByData(this.userData, this.details);
    },

    handleSubmit(saveData) {
      let data = Model.EditPassword.populateData(this.userData);
      const config = Services.putPassword(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.details = {};
      this.userData = Model.createDetails();
    }
  }
}
</script>