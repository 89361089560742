<template>
  <kst-section collapsible collapsed info-mode
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <kst-output field="Name" :data="data" :catalog="Catalog"/>
    <kst-output field="Address" :data="data" :catalog="ClientAddressCatalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../ClientCatalog.js";
import ClientAddressCatalog from "../ClientAddressCatalog.js";
import Info from "../ClientInfo.js";
import Model from "../ClientModel.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog,
    ClientAddressCatalog: ClientAddressCatalog
  }),
  computed: {
    sectionHelp() {
      return Model.getSectionHelp_RelatedDetails(this.data);
    },
    sectionTitle() {
      return Info.Section.RelatedDetails.Title;
    }
  }
}
</script>