import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./RccdCatalog.js";
import RccdTransCatalog from "./RccdTransCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientID: {
    Label: RccdTransCatalog.ClientID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "ClientName",
    Param: AppModule.Client.Param + AppCatalog.Field.ID.Param
  },
  ClientName: {
    Label: RccdTransCatalog.ClientID.Label,
    Type: RccdTransCatalog.ClientName.Type,
    Param: AppModule.Client.Param + AppCatalog.Field.Name.Param
  },
  TransactionDate: {
    Label: Catalog.TransactionDate.Label,
    Type: AppCatalog.Field.Date.Type,
    IsRange: true,
    Required: true,
    RequiredHide: true,
    Param: AppCatalog.Field.Date.Param
  },
  TypeID: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.TransactionType.Param + AppCatalog.Field.ID.Param
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Param: AppModule.Warehouse.Param + AppCatalog.Field.ID.Param
  }
}