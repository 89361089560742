<template>
  <kst-load-data loadingElement @init="handleLoad_init">
    <div class="columns mb-0">
      <div class="column">
        <kst-input floating-label
          class="kstmod-pos-new__scanner_barcode" ref="txtBarcode"
          field="Barcode" :data="scannerData" :catalog="ScannerCatalog"
          @submit="handleBarcode"
        />
      </div>
      <div class="column is-4">
        <kst-input floating-label ref="txtQty"
          field="Qty" :data="scannerData" :catalog="ScannerCatalog"
          @submit="handleQty"
        />
      </div>
    </div>

    <div class="list-pos-items">
      <div class="columns is-multiline">
        <div class="column is-half"
          v-for="(item, index) in resultList"
          :key="item.ItemID + '#' + item.SpecialPriceID"
        >
          <div
            class="card-pos-item kstmod-pos-new__scanner-card"
            v-on:click="handleResult_click(item, index)"
          >
            <div class="card-info">
              <kst-tooltip info-mode size="is-small" :position="getPosition(index)">
                <template #content>
                  <kst-list info-mode :items="Model.getItemInfo(item)"/>
                </template>
                <kst-icon info-mode/>
              </kst-tooltip>
            </div>

            <template v-if="item.SpecialPriceID !== null">
              <div class="ribbon-wrapper-red">
                <div class="ribbon-red">
                  <kst-value
                    field="SpecialPriceID" :data="item" :catalog="ScannerCatalog"
                  />
                </div>
              </div>
            </template>

            <kst-image hide-preview read-only thumbnail :src="item.ImageSrc"/>
            <h5><kst-value field="ItemID" :data="item" :catalog="ScannerCatalog"/></h5>
            <div class="divider"/>

            <div class="columns mb-0">
              <div class="column pr-0">
                <div class="is-12">
                  {{ ScannerCatalog.Quantity.Label }}
                </div>
              </div>
              <div class="column pl-0">
                <div class="has-text-weight-semibold is-12">
                  <kst-value field="Quantity" :data="item" :catalog="ScannerCatalog"/>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column pr-0">
                <div class="is-12">
                  {{ ScannerCatalog.SellPrice.Label }}
                </div>
              </div>

              <template v-if="item.SpecialPriceID !== null">
                <div class="column pl-0">
                  <div class="has-text-weight-semibold is-12 has-text-danger">
                    <kst-value
                      field="SellPriceNetto" :data="item" :catalog="ScannerCatalog"
                    />
                  </div>
                  <div class="has-text-grey ks-text-line-through">
                    <kst-value class="is-size-7"
                      field="SellPrice" :data="item" :catalog="ScannerCatalog"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="column pl-0">
                  <div class="has-text-weight-semibold is-12">
                    <kst-value
                      field="SellPrice" :data="item" :catalog="ScannerCatalog"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </kst-load-data>
</template>

<script>
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";
import Info from "../PosInfo.js";
import Model from "../PosModel.js";
import ScannerCatalog from "./ScannerCatalog.js";
import Services from "../../../services/Api/PosServices.js";

export default {
  mixins: [SnackbarMixin],
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    ScannerCatalog: ScannerCatalog,
    scannerData: {
      Barcode: "",
      Qty: "1"
    },
    isFirstLoad: true,
    items: [],
    resultList: [],
    // tabs
    activeTab: 0,
    // func
    loadData: null
  }),
  mounted() {
    window.addEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  destroyed() {
    window.removeEventListener(this.$kst.Key.KeyEvent, this.handleKeyDown);
  },
  created() {
    this.init();
  },
  methods: {
    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;
      this.loadBarcode();
    },

    /*** scanner ***/

    handleBarcode() {
      this.resultList = [];

      // validation
      const barcode = this.scannerData.Barcode.trim();
      let item, isValid = true;

      if (barcode === "") {
        isValid = false;
      }
      else {
        item = this.$kst.Array.searchItem(
          this.items, "Barcode", barcode,
          { insensitive: true, all_insequence: true }
        );

        if (Array.isArray(item)) {
          if (item.length === 0) {
            isValid = false;
          }
          else if (item.length === 1) {
            item = item[0];
          }
          else {
            this.resultList = item;
            this.scannerData.Barcode = "";
            return;
          }
        }
        else {
          isValid = false;
        }
      }

      if (!isValid) {
        this.notifyFormError(false, Info.Message.New_BarcodeInvalid);
        return;
      }

      let userItemIndex = this.addItem(item);

      this.$nextTick(() => {
        this.$emit(this.$kst.Enum.Event.Input,
          this.$jquery(".kstmod-pos-new__scanner_barcode"),
          userItemIndex
        );
      });
    },
    handleQty() {
      this.setFocusBarcode();
    },

    addItem(stockSpecialPriceData) {
      let itemIdx = this.userItems.findIndex(unit =>
        unit.DispatchID === stockSpecialPriceData.ItemID &&
        unit.SpecialPriceID === stockSpecialPriceData.SpecialPriceID
      );

      if (itemIdx !== -1) {
        let item = this.userItems[itemIdx];
        Model.setItemBySpecialPrice_existing(item, this.scannerData.Qty);
        Model.updateItem(item);
      }
      else {
        let item = Model.createItem();
        Model.setItemBySpecialPrice_new(
          item, stockSpecialPriceData, this.scannerData.Qty
        );
        Model.updateItem(item);
        this.userItems.push(item);
        itemIdx = this.userItems.length - 1;
      }

      Model.updateDetails(this.userData, this.userItems);
      this.clearInput();
      this.setFocusBarcode();
      return itemIdx;
    },

    clearInput() {
      this.scannerData.Barcode = "";
      this.scannerData.Qty = "1";
    },

    /*** scanner - result ***/

    handleResult_click(item, index) {
      let userItemIndex = this.addItem(item);

      this.$nextTick(() => {
        this.$emit(this.$kst.Enum.Event.Input,
          this.$jquery(".kstmod-pos-new__scanner-card").eq(index),
          userItemIndex
        );
      });
    },

    /*** control ***/

    handleKeyDown(event) {
      switch(event[this.$kst.Key.KeyProp]) {
        case this.$kst.Key.Key.F1:
          this.setFocusBarcode();
          event.preventDefault();
          break;
        case this.$kst.Key.Key.F2:
          this.setFocusQuantity();
          event.preventDefault();
          break;
      }
    },

    setFocusQuantity() {
      this.activeTab = 0;
      this.$refs.txtQty.focus();
    },
    setFocusBarcode() {
      this.activeTab = 0;
      this.$refs.txtBarcode.focus();
    },

    /*** custom ***/

    init() {
      this.$emit(this.$kst.Enum.Event.Init, this.reload);
    },

    reload() {
      this.resultList = [];
      this.clearInput();
      this.loadBarcode();
    },

    loadBarcode() {
      const config = Services.getRelatedBarcodeList(this.userData.WarehouseID);
      this.loadData(config, this.loadBarcode_success, true);
    },
    loadBarcode_success(data) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        this.$nextTick(() => {
          this.setFocusBarcode();
        });
      }

      this.items = data.BarcodeList;
    },

    getPosition(index) {
      return (index % 2 === 0) ? "is-right" : "is-bottom";
    }
  }
}
</script>