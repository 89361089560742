import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import TrxEx from "./Export/TrxEx.vue";
import TrxR from "./Report/TrxR.vue";
import TrxP from "./Print/TrxP.vue";

export default {
  Path: {
    Export: RouterModel.getPath(AppModule.Transaction, AppModuleType.Export),
    Main: RouterModel.getPath(AppModule.Transaction),
    Print: RouterModel.getPath(AppModule.Transaction, AppModuleType.Print)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Transaction),
        name: RouterModel.getName(AppModule.Transaction),
        component: TrxR,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Transaction),
          module: RouterModel.getModule(AppModule.Transaction),
          moduleattr: AppModuleAttr.Transaction_Act_Report,
          moduletype: AppModuleType.Report,
          export: {
            module: RouterModel.getModule(AppModule.Transaction),
            moduleattr: AppModuleAttr.Transaction_Act_Export,
            moduletype: AppModuleType.Export
          },
          print: {
            module: RouterModel.getModule(AppModule.Transaction),
            moduleattr: AppModuleAttr.Transaction_Act_Print,
            moduletype: AppModuleType.Print
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Transaction, AppModuleType.Export),
        name: RouterModel.getName(AppModule.Transaction, AppModuleType.Export),
        component: TrxEx,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Transaction, AppModuleType.Export),
          module: RouterModel.getModule(AppModule.Transaction),
          moduleattr: AppModuleAttr.Transaction_Act_Export,
          moduletype: AppModuleType.Export
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Transaction, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Transaction, AppModuleType.Print),
        component: TrxP,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Transaction, AppModuleType.Print),
          module: RouterModel.getModule(AppModule.Transaction),
          moduleattr: AppModuleAttr.Transaction_Act_Print,
          moduletype: AppModuleType.Print
        }
      }
    ];
  }
}