import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./UserCatalog.js";

export default {
  Field: {
    BackDateInterval: {
      Suffix: AppCatalog.DaySign
    }
  },
  Message: {
    Details_IsEmailVerified:
      "Akun anda belum aktif. Untuk mengaktifkan, silakan cek " + 
      Catalog.Email.Label + " untuk verifikasi akun anda.",
    Edit_StaticEmail: 
      "Tidak diperkenankan mengubah " + Catalog.Email.Label + ".",
    Edit_IsOwner: 
      "Tidak diperkenankan mengubah " + Catalog.AccessID.Label + 
      ", ketika sebagai " + AppCatalog.Info.Owner + ".",
    Edit_StaticActive:
      "Tidak diperkenankan mengubah " + Catalog.IsActive.Label +
      ", untuk " + AppCatalog.Info.Owner + ".",
    Edit_MaxUsers:
      "Anda telah mencapai batas untuk mengaktifkan " +
      AppModule.UserList.FullName + ", " +
      "silakan upgrade Dirigo anda ke versi berlanganan.",
    Edit_MaxUsers2: 
      "Silakan hubungi tim Dirigo untuk info lebih lanjut"
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    TransactionSetting: {
      Title: AppModule.Settings.Name + " Transaksi"
    }
  }
}