<template>
  <kst-table view-mode :data="stockList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="StcdStockCatalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Quantity"
      :label="StcdStockCatalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="StcdStockCatalog.HppTotal.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="StcdStockCatalog"/>
      </b-table-column>
    </template>
  </kst-table>
</template>

<script>
import StcdStockCatalog from "../StcdStockCatalog.js";

export default {
  props: {
    access: Object,
    stockList: Array
  },
  data: () => ({
    StcdStockCatalog: StcdStockCatalog
  })
}
</script>