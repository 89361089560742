<template>
  <kst-table action paginated view-mode :data="items">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="TrxListCatalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-module-name"
      field="ModuleName"
      :label="TrxListCatalog.ModuleName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="Number"
      :label="TrxListCatalog.Number.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseName"
      :label="TrxListCatalog.WarehouseName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchName"
      :label="TrxListCatalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty-small"
      field="Qty"
      :label="TrxListCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-packaging-small"
      field="Packaging"
      :label="TrxListCatalog.Packaging.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price-small"
      field="Price"
      :label="TrxListCatalog.Price.Label"
    >
      <template v-if="row.HasAmountAccess">
        <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
      </template>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="TrxListCatalog.Total.Label"
    >
      <template v-if="row.HasAmountAccess">
        <kst-value :field="column.field" :data="row" :catalog="TrxListCatalog"/>
      </template>
    </b-table-column>

    <template #action="{ row }">
      <template v-if="row.HasAccess">
        <kst-tooltip details-mode hover-only>
          <kst-button action-mode
            class="ks-mt--2"
            :class="Info.Button.Transaction.Class"
            :label="Info.Button.Transaction.Label"
            :module="row.Module"
            :moduleType="Info.Button.Transaction.ModuleType"
            :urlParams="row.UrlParams"
          />
        </kst-tooltip>
      </template>
    </template>
  </kst-table>
</template>

<script>
import Info from "../TrxInfo.js";
import Model from "../TrxModel.js";
import TrxListCatalog from "../TrxListCatalog.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Info: Info,
    Model: Model,
    TrxListCatalog: TrxListCatalog
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      Model.updateTransactionList(this.items, this.access);
    }
  }
}
</script>