<template>
  <PageNew
    @init="handleInit"
    @submit="handleSubmit"
  >
    <template v-if="serviceData.SelfServicePin === null">
      <ClientEnterPin
        @init="handlePin_Init"
        @submit="handlePin_Submit"
      />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-half">
          <ClientRelatedDetails :data="clientData"/>
        </div>
        <div class="column is-half">
          <CompanyRelatedDetails :data="companyData"/>
        </div>
      </div>

      <Details :userData="userData"/>
      <Items
        :serviceData="serviceData"
        :userData="userData"
        :userItems="userItems"
      />

      <div class="columns is-flex-direction-row-reverse">
        <div class="column">
          <Total :userData="userData"/>
        </div>
        <div class="column">
          <Details2 :userData="userData"/>
        </div>
      </div>
    </template>
  </PageNew>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Model from "../SalesModel.js";
import Router from "../SalesRouter.js";
import Services from "../../../services/ApiPublic/SalesServices.js";

import Details from "./SlsN_Details.vue";
import Details2 from "./SlsN_Details2.vue";
import Items from "./SlsN_Items.vue";
import Total from "./SlsN_Total.vue";
// external
import ClientRelatedDetails from "../../Client/RelatedDetails/CliRD.vue";
import ClientEnterPin from "../../Client/EnterPin/CliEnterPin.vue";
import CompanyRelatedDetails from "../../Company/RelatedDetails/CpRD.vue";
import PageNew from "../../_shared/PageNew/PageNew.vue";

export default {
  mixins: [InputPageMixin, RouterMixin],
  components: {
    Details,
    Details2,
    Items,
    Total,
    // external
    ClientRelatedDetails,
    ClientEnterPin,
    CompanyRelatedDetails,
    PageNew
  },
  data: () => ({
    userData: {},
    userItems: [],
    clientData: {},
    companyData: {},
    serviceData: {
      SelfServiceID: null,
      SelfServicePin: null
    },
    // func
    loadNewFunc: null,
    reloadFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew, showFailureInit, stopInit, reload) {
      this.loadNewFunc = loadNew;
      this.reloadFunc = reload;
      this.resetData();

      if (this.handleInit_Validate(showFailureInit)) {
        if (this.serviceData.SelfServicePin === null) {
          stopInit();
        }
        else {
          const config = Services.getNewData(
            this.serviceData.SelfServiceID, this.serviceData.SelfServicePin
          );
          loadNew(config, this.handleInit_Success);
        }
      }
    },
    handleInit_Validate(showFailureInit) {
      // check: required url params
      this.serviceData.SelfServiceID = this.getUrlParam(
        this.$kst.Enum.Param.ServiceID
      );

      if (!this.serviceData.SelfServiceID) {
        showFailureInit(AppCatalog.Message.ParamIncomplete);
        return false;
      }

      // check: pin
      this.serviceData.SelfServicePin = this.$kst.Session.getSelfServicePin();

      return true;
    },
    handleInit_Success(data) {
      this.clientData = data.ClientDetails;
      this.companyData = data.CompanyDetails;

      Model.setDetailsByNewData(this.userData, this.clientData);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(
        this.userData, this.userItems, this.serviceData
      );
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success(data) {
      this.disableRouteLeave();
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: data.id,
        [this.$kst.Enum.Param.ServiceID]: this.serviceData.SelfServiceID
      });
      this.redirectTo(Router.Path.PrintDetails + urlParams);
    },

    /*** by enter pin ***/

    handlePin_Init(setInit) {
      setInit(this.serviceData.SelfServiceID, Services.getSecurityLogin);
    },
    handlePin_Submit() {
      this.reloadFunc();
    },

    /*** custom ***/

    resetData() {
      // userData
      this.userData = Model.createDetails();
      this.userItems = [];

      // serviceData
      this.serviceData.SelfServiceID = "";
      this.serviceData.SelfServicePin = "";

      // other
      this.clientData = {};
      this.companyData = {};
    }
  }
}
</script>