<template>
  <kst-table view-mode :data="items">
    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StrdItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="StrdItemCatalog.Packaging.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="StrdItemCatalog.Qty.Label"
    >
      <kst-value
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-hpptotal"
      field="HppTotal"
      :label="StrdItemCatalog.HppTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StrdItemCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import StrdItemCatalog from "../StrdItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    StrdItemCatalog: StrdItemCatalog
  })
}
</script>