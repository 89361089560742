import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.company;

export default {
  getDetailsData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata"
    };
  },
  getEditData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata"
    };
  },
  postRegistration(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint + "/registration",
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  }
}