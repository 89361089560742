<template>
  <kst-print-table view-mode :data="items">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Client"
      :label="Catalog.Client.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="DueDate"
      :label="Catalog.DueDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="RecTotal"
      :label="Catalog.RecTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../RecCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>