<template>
  <div>
    <kst-output print-mode field="StockTrfNumber" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="Date" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="SourceWarehouseID" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="DestinationWarehouseID" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../SttrCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>