import AppCatalog from "../../services/App/AppCatalog.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./DbpyModel.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.FullName
        }
      },
      // support
      Option: {
        Details: Model.ModuleType.Details.FullName + " " + Model.Module.FullName
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + Model.Module.FullName
        },
        Option: {
          ReceiveList: "Dengan " + Model.DebtPayReceive.Module.FullName,
          List: AppModuleType.List.FullName + " " + Model.Module.FullName
        }
      }
    }
  },
  Message: {
    New_IsVerified:
      AppCatalog.Message.NeedToVerify,
    RelatedList_NewDebtPay:
      "Tidak diperkenankan menambah " + Model.Module.FullName + ", " +
      "saat lunas."
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    ReceiveList: {
      Title: AppModuleType.List.FullName + " " +
        Model.DebtPayReceive.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.FullName
  }
}