import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    ExportLabel: AppCatalog.Field.Name.Label + " " +
      AppModule.ItemPackaging.FullName,
    Label: AppCatalog.Field.Name.Label,
    MaxLength: 45,
    RelatedValue: "PackagingName",
    Required: true,
    Type: AppCatalog.Field.Name.Type,
    Valid: true
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  PackagingIsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    ExportLabel: AppCatalog.Field.Qty.Label + " " +
      AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    RelatedValue: "PackagingQty",
    Required: true
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    RelatedValue: "PackagingSellPrice",
    Required: true
  },
  Weight: {
    Label: AppCatalog.Field.Weight.Label,
    Type: AppCatalog.Field.Weight.Type,
    MinValue: AppCatalog.Field.Weight.MinValue,
    MaxLength: AppCatalog.Field.Weight.MaxLength,
    RelatedValue: "PackagingWeight",
    Required: true
  },

  // suppport
  IsDefaultPackaging: {
    Label: AppCatalog.Field.Default.Label + "?",
  },
  IsDefaultPcs: {
    Label: AppCatalog.Field.Pcs.Label + "?",
  }
}