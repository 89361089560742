<template>
  <kst-section :title="Info.Section.RecReminder.Title">
    <kst-input field="RecDueReminderDays" :data="userData" :catalog="Catalog"/>
    <kst-input field="RecEmailSentTime" :data="userData" :catalog="Catalog"/>

    <List class="mt-5"
      :userRecEmailList="userRecEmailList"
    />

    <template #bottom>
      <kst-button action-mode
        class="is-primary"
        :label="Model.Email.ModuleType.New.Name"
        :moduleType="Model.Email.ModuleType.New"
        @click="handleNew"
      />
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

import List from "./CpE_RecReminder_List.vue";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    userRecEmailList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  methods: {
    handleNew() {
      this.userRecEmailList.push(
        Model.createEmailDetails()
      );
    }
  }
}
</script>