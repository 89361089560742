<template>
  <div class="field has-addons mr-4">
    <div class="control">
      <kst-action button-mode new-icon class="pt-4">
        <template v-for="action in Model.ActionList" >
          <template v-if="menuList.includes(action.ID)">
            <kst-action-item :key="action.Code">
              <kst-button action-mode full-label
                :module="action"
                :moduleType="Model.ActionModuleType"
              />
            </kst-action-item>
          </template>
        </template>
      </kst-action>
    </div>
  </div>
</template>

<script>
import Model from "./LayoutModel.js";

export default {
  data: () => ({
    Model: Model
  }),
  computed: {
    menuList() {
      return this.$kst.Session.getModule();
    }
  }
}
</script>