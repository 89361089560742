<template>
  <kst-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="PackagingCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="PackagingCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="Qty"
      :label="PackagingCatalog.Qty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="PackagingCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="SellPrice"
      :label="PackagingCatalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="PackagingCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="Weight"
      :label="PackagingCatalog.Weight.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="PackagingCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import PackagingCatalog from "../PackagingCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    PackagingCatalog: PackagingCatalog
  })
}
</script>