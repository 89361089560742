import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import ReceiveCatalog from "../Receive/RcvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  PaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MaxValue: "ReceiveBalance",
    MinValue: AppCatalog.Field.PaymentValue.MinValue
  },
  ReceiveID: {
    Label: AppModule.Receive.FullName,
    ColumnLabel: ReceiveCatalog.ReceiveNumber.Label,
    ExportLabel: ReceiveCatalog.ReceiveNumber.Label,
    PrintLabel: ReceiveCatalog.ReceiveNumber.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ReceiveNumber",
    RelatedModule: AppModule.Receive
  },
  ReceiveDate: {
    Label: ReceiveCatalog.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ReceiveNumber: {
    Label: ReceiveCatalog.ReceiveNumber.Label,
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor
  },
  VendorName: {
    Label: AppModule.Vendor.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName + " " + AppModule.Receive.FullName,
    ColumnLabel: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName +
      " " + AppModule.Receive.FullName,
    PrintLabel: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  ReceiveBalance: {
    Label: AppCatalog.Field.Balance.Label + " " + AppModule.Rec.FullName,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  ReceiveDueDate: {
    Label: AppCatalog.Field.DueDate.Label,
    Type: AppCatalog.Field.DueDate.Type,
    ReadOnly: true
  },
  ReceivePaymentValue: {
    Label: AppCatalog.Field.PaymentValue.Label,
    Type: AppCatalog.Field.PaymentValue.Type,
    Output: AppCatalog.Field.PaymentValue.AltOutput,
    MaxValue: "ReceiveBalance",
    MinValue: AppCatalog.Field.PaymentValue.MinValue
  },
  ReceiveVendorID: {
    Label: AppModule.Vendor.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ReceiveVendorName",
    RelatedModule: AppModule.Vendor
  },
  ReceiveWarehouseID: {
    Label: AppModule.Warehouse.FullName + " " + AppModule.Receive.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName +
      " " + AppModule.Receive.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ReceiveWarehouseName",
    RelatedModule: AppModule.Warehouse,
  },
}