<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row, index }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>

        <kst-input box-off field-only class="is-hidden"
          field="DispatchID" :data="row" :catalog="StrdItemCatalog"
          :index="index" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="DispatchID"
      :label="StrdItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.New.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="StrdItemCatalog.Packaging.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="StrdItemCatalog.Qty.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
        :customValidations="row.CustomValidations"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="StrdItemCatalog.SellPrice.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <template v-if="userData.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-specialprice"
        field="SpecialPriceID"
        :label="StrdItemCatalog.SpecialPriceID.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Total"
      :label="StrdItemCatalog.Total.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="StrdItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.StockReductionItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../StrdInfo.js";
import Model from "../StrdModel.js";
import StrdItemCatalog from "../StrdItemCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Model: Model,
    StrdItemCatalog: StrdItemCatalog
  }),
  methods: {
    handleQuantity(item) {
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_Confirm,
        null,
        index
      );
    },
    handleRowDelete_Confirm(index) {
      this.userItems.splice(index, 1);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    }
  }
}
</script>