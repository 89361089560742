<template>
  <kst-section :title="Info.Section.PosSummary.Title">
    <kst-table view-mode :data="posSummaryList">
      <b-table-column
        v-slot="{ row }"
        field="PaymentTypeName"
        :label="OmzSummaryCatalog.PaymentTypeName.Label"
      >
        {{ row.PaymentTypeName }}
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="Total"
        :label="OmzSummaryCatalog.Total.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="OmzSummaryCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Info from "../OmzInfo.js";
import OmzSummaryCatalog from "../OmzSummaryCatalog.js";

export default {
  props: {
    posSummaryList: Array
  },
  data: () => ({
    Info: Info,
    OmzSummaryCatalog: OmzSummaryCatalog
  })
}
</script>