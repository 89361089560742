<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../PurCatalog.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object,
    searchData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      if (this.userData.WithItemsByVendor) {
        this.createSheet();
        this.addSummaryItemListByVendor(data.ListByVendor);
      }

      this.download(fileName);
    },
    addList(purchaseList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader(Catalog.DeliveryAddress);
      this.addColumnHeader(Catalog.DeliveryDate);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(Catalog.Total);
      }
      this.addColumnHeader(Catalog.Status);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(PurItemCatalog.DispatchID.Label);
        this.addColumnHeader(PurItemCatalog.DispatchID);
        this.addColumnHeader(PurItemCatalog.Packaging);
        this.addColumnHeader(PurItemCatalog.Quantity);
        this.addColumnHeader(PurItemCatalog.TotalQuantity);
        if (this.access.Fld_Amount) {
          this.addColumnHeader(PurItemCatalog.BuyPrice);
          this.addColumnHeader(PurItemCatalog.ExpenseAmount);
        }
        this.addColumnHeader(PurItemCatalog.IsClosed);
      }

      // value
      for (const row of purchaseList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellRelatedValue("VendorID", row, Catalog);
        this.addCellValue("VendorAddress", row, Catalog);
        this.addCellValue("DeliveryAddress", row, Catalog);
        this.addCellValue("DeliveryDate", row, Catalog);
        if (this.access.Fld_Amount) {
          this.addCellValue("Total", row, Catalog);
        }
        this.addCellStatus(row.Status, Model.Status);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, PurItemCatalog);
          this.addCellRelatedValue("DispatchID", row, PurItemCatalog);
          this.addCellValue("Packaging", row, PurItemCatalog);
          this.addCellValue("Quantity", row, PurItemCatalog);
          this.addCellValue("TotalQuantity", row, PurItemCatalog);
          if (this.access.Fld_Amount) {
            this.addCellValue("BuyPrice", row, PurItemCatalog);
            this.addCellValue("ExpenseAmount", row, PurItemCatalog);
          }
          this.addCellValue("IsClosed", row, PurItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    },

    addSummaryItemListByVendor(dataListByVendor) {
      const dataSummary = Model.ExportList.getPivotSummaryObj(dataListByVendor);
      const summaryList = dataSummary.SummaryList;
      const vendorList = dataSummary.SummaryObj.Vendor;
      const itemList = dataSummary.SummaryObj.Name;
      let totalQtyPerRow, totalQtyPerCol = [];

      // date info
      const startDate = this.$kst.Format.getLongDate(this.searchData.Date[0]);
      const endDate = this.$kst.Format.getLongDate(this.searchData.Date[1]);
      this.addColumnHeader(Catalog.Date.Label);
      this.addRow();
      this.addCell(startDate + " - " + endDate);
      this.addRow();

      // label header
      this.addRow();
      this.addSequenceHeader();
      this.mergeRow(2);
      this.addCell(Catalog.Vendor.ExportListLabel);
      this.mergeRow(2);
      this.addCell(PurItemCatalog.Name.ExportListLabel);
      this.mergeCol(itemList.length); 
      this.moveColumn(itemList.length - 1);
      this.addCell(Catalog.Total.Label);
      this.mergeRow(2);

      // label header item
      this.addRow();
      this.addCell("");
      this.addCell("");
      for (let dispatchName of itemList) {
        totalQtyPerCol.push(0);
        this.addCell(dispatchName);
      }

      let totalQty = 0, indexSummary = 0, indexItem;

      // value vendor item
      for (let vendorName of vendorList) {
        totalQtyPerRow = 0;

        this.addRow();
        this.addSequenceCell();
        this.addCell(vendorName);

        for (indexItem = 0; indexItem < itemList.length; indexItem++) {
          if (indexSummary >= summaryList.length) {
            this.addCell(0);
          } else if (
            summaryList[indexSummary].Vendor === vendorName && 
            summaryList[indexSummary].Name === itemList[indexItem]
          ) {
            this.addCell(summaryList[indexSummary].TotalQuantity);
            totalQtyPerRow += summaryList[indexSummary].TotalQuantity;
            totalQtyPerCol[indexItem] += summaryList[indexSummary].TotalQuantity;
            indexSummary++;
          } else {
            this.addCell(0);
          }
        }

        // value total per row
        this.addCell(totalQtyPerRow);
        totalQty += totalQtyPerRow;
      }

      // value total per column
      this.addRow();
      this.addCell("");
      this.addCell(Catalog.Total.Label);
      for (indexItem = 0; indexItem < itemList.length; indexItem++) {
        this.addCell(totalQtyPerCol[indexItem]);
      }
      this.addCell(totalQty);

      // value summary total
      this.addRow();
      this.addRow();
      this.addCell("");
      this.addCell(PurItemCatalog.TotalQuantity.ExportListLabel);
      this.addCell(totalQty);

      this.addSheet(Info.Export.List.Excel.SheetName.ListByVendor);
    }
  }
}
</script>