<template>
  <kst-modal-view
    :class="Info.Dialog.Summary.Class"
    :title="Info.Dialog.Summary.Title"
    :width="800"
    @init="handleModal_init"
  >
    <kst-load-data activeOnLoad loadingElement @init="handleLoad_Init">
      <List :items="summaryList"/>
    </kst-load-data>
  </kst-modal-view>
</template>

<script>
import Info from "../StopInfo.js";
import Model from "../StopModel.js";
import Services from "../../../services/Api/StockOpnameServices.js";

import List from "./StopN_Items_Summary_List.vue";

export default {
  props: {
    userItems: Array
  },
  components: {
    List
  },
  data: () => ({
    Info: Info,
    userData: {
      WarehouseID: null
    },
    summaryList: [],
    // func
    loadData: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(warehouseID) {
      this.summaryList = [];
      this.userData.WarehouseID = warehouseID;

      this.showFunc();
    },

    /*** by load data ***/

    handleLoad_Init(loadData) {
      this.loadData = loadData;
      this.loadSummaryList();
    },

    /*** by modal ***/

    handleModal_init(show) {
      this.showFunc = show;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    /** custom */

    loadSummaryList() {
      const options = this.getListOptions();
      const config = Services.getRelatedStockList(options);
      this.loadData(config, this.loadSummaryList_Success, true);
    },
    loadSummaryList_Success(data) {
      this.summaryList = Model.New.getSummaryList(
        this.userItems, data.StockList
      );
    },

    getListOptions() {
      return {
        warehouse: this.$kst.Out.getInteger(this.userData.WarehouseID)
      };
    },
  }
}
</script>