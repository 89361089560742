<template>
  <kst-table paginated view-mode
    :action="hasAccessAction"
    :data="items"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-alias"
      field="Alias"
      :label="Catalog.Alias.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Mobile"
      :label="Catalog.Mobile.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Phone"
      :label="Catalog.Phone.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-email"
      field="Email"
      :label="Catalog.Email.Label"
    >
      <kst-value ellipsis :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="OverDueDate"
      :label="Catalog.OverDueDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="CreditAmount"
      :label="Catalog.CreditAmount.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="RecTotal"
      :label="Catalog.RecTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../CliCatalog.js";

import Action from "./CliL_List_Action.vue";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    hasAccessAction() {
      return this.access.Act_Details ||
        this.access.Act_Edit ||
        this.access.Act_ExportDetails;
    }
  }
}
</script>