<template>
  <kst-page-list 
    :catalog="LowstkSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
        :searchData="searchData"
      />
    </template>
  </kst-page-list>
</template>

<script>
import LowstkSearchCatalog from "../LowstkSearchCatalog.js";
import Model from "../LowstkModel.js";
import Services from "../../../services/Api/LowStockService.js";

import List from "./LowstkL_List.vue";
import Search from "./LowstkL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    LowstkSearchCatalog: LowstkSearchCatalog,
    Model: Model,
    access: {},
    searchData: {
      Search: "",
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options);
      loadListData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, LowstkSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.normalizeSearch();
    },

    normalizeSearch() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>