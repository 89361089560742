<template>
  <div>
    <div class="is-size-3">{{ companyName }}</div>
    <div>{{ companyAddress }}</div>
    <div>Telp. {{ companyPhone }}</div>
  </div>
</template>

<script>
export default {
  props: {
    customData: {
      type: Object,
      default: null
    },
    data: Object
  },
  computed: {
    companyAddress() {
      if (this.customData === null) {
        return this.data.address;
      }
      else {
        return this.customData.CompanyAddress === null
          ? this.data.address : this.customData.CompanyAddress;
      }
    },
    companyName() {
      if (this.customData === null) {
        return this.data.name;
      }
      else {
        return this.customData.CompanyName === null
          ? this.data.name : this.customData.CompanyName;
      }
    },
    companyPhone() {
      if (this.customData === null) {
        return this.data.phone;
      }
      else {
        return this.customData.CompanyPhone === null
          ? this.data.phone : this.customData.CompanyPhone;
      }
    }
  }
}
</script>