import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, detailsData, hasUser) {
    userData.ID = detailsData.ID;
    // user input
    userData.Name = App.In.getString(detailsData.Name);
    userData.IsActive = App.In.getBoolean(detailsData.IsActive);
    userData.Comment = App.In.getString(detailsData.Comment);
    // defined by system
    userData.HasUser = App.In.getBoolean(hasUser);
  },
  setModulesByData(userModuleList, detailsModuleList, moduleList) {
    App.Array.truncate(userModuleList);

    let module;
    for (const detailsModule of detailsModuleList) {
      module = App.Array.searchItem(moduleList, "ID", detailsModule.ModuleID);

      if (module) {
        userModuleList.push(module);
      }
    }
  }
}