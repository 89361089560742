<template>
  <div class="kstmod-delivery-relatedlist">
    <template v-if="access.Act_New === true">
      <template v-if="!hideAction">
        <Action :invoiceId="invoiceId"/>
      </template>
      <template v-else>
        <kst-warning class="mb-4" :items="deliveryWarning"/>
      </template>
    </template>

    <Row :access="access" :items="items"/>
  </div>
</template>

<script>
import Info from "../DlvInfo.js";
import Model from "../DlvModel.js";

import Action from "./DlvRL_Action.vue";
import Row from "./DlvRL_Row.vue";

export default {
  components: {
    Action,
    Row
  },
  props: {
    hideAction: Boolean,
    invoiceId: Number,
    items: Array
  },
  data: () => ({
    access: {}
  }),
  computed: {
    deliveryWarning() {
      return [Info.Message.Related_NewDelivery];
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);

      for (const row of this.items) {
        for (const item of row.Items) {
          Model.updateItem(item);
        }
      }
    }
  }
}
</script>