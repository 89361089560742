export default {
  IsRaw: {
    Raw: {
      ID: 1,
      Label: "Awal"
    },
    Finish: {
      ID: 0,
      Label: "Akhir"
    }
  }
}