<template>
  <kst-form no-bezel class="block" v-if="access.Act_New">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
      />
    </template>
  </kst-form>
</template>

<script>
import Model from "../StrdModel.js";

export default {
  data: () => ({
    Model: Model,
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>