<template>
  <div class="is-flex">
    <div class="kst-layout-sidebar-info-avatar ml-4 mr-1">
      <template v-if="isDefault">
        <kst-image is-public company-default-img/>
      </template>
      <template v-else>
        <kst-image size-auto border-off hide-preview read-only thumbnail :src="companyImage"/>
      </template>
    </div>
    <div class="kst-layout-sidebar-userinfo">
      <div>
        {{ userName }}
      </div>
      <div>
        {{ companyName }}
      </div>
      <div>
        <kst-button action-mode
          class="is-primary is-small"
          tag="button"
          :label="Info.SignOut.Label"
          :iconLeft="Info.SignOut.Icon"
          @click="handleLogout"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppModule from "../../services/App/AppModule.js";
import RouterMixin from "../../mixins/RouterMixin.js";
import Info from "./LayoutInfo.js";

export default {
  mixins: [RouterMixin],
  data: () => ({
    Info: Info
  }),
  computed: {
    companyName() {
      return this.$kst.Session.getCompanyName();
    },
    userName() {
      return this.$kst.Session.getName();
    },
    companyImage() {
      return this.$kst.Session.getCompanyImageSrc();
    },
    isDefault() {
      return this.$kst.Session.getCompanyImageSrc() === null;
    }
  },
  methods: {
    handleLogout() {
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignOut));
    }
  }
}
</script>