<template>
  <kst-modal-select
    :class="Info.Dialog.SearchItem.Class"
    :title="Info.Dialog.SearchItem.Title"
    :width="800"
    @init="handleModal_Init"
    @submit="handleModal_Submit"
  >
    <template #search-result="{ userData }">
      <List
        :items="items"
        :userData="userData"
      />
    </template>
  </kst-modal-select>
</template>

<script>
import Info from "../RcvInfo.js";

import List from "./RcvScI_List.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    List
  },
  props: {
    items: Array
  },
  data: () => ({
    Info: Info,
    selectedRow: null,
    getDetailsService: null,
    // func
    hideFunc: null,
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show(getDetailsService) {
      this.getDetailsService = getDetailsService;
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_Init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_Submit(selected, loadRelated) {
      if (this.getDetailsService) {
        this.selectedRow = selected;
        const config = this.getDetailsService(selected.ID);
        loadRelated(config, this.handleModal_Submit_Success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit, selected);
        this.hideFunc();
      }
    },
    handleModal_Submit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, this.selectedRow, data);
      this.hideFunc();
    }
  }
}
</script>