import App from "../../services/App/App.js";
import StcvItemCatalog from "./StcvItemCatalog.js";

export default {
  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(StcvItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, StcvItemCatalog)
    );

    return infoList;
  }
}