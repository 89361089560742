import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      Module: AppModule.Receive,
      ModuleType: AppModuleType.Details
    }
  },
  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Debt.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Debt.FullName
        }
      }
    }
  }
}