import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";
import OutputType from "../../services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    Required: true
  },
  Price: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    Output: AppCatalog.Field.SubTotal.Output,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly
  },
  RequestedQuantity: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.AltType,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    Required: true
  },
  TotalRequestedQuantity: {
    Label: AppCatalog.Field.TotalQty.Label,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },

  // support
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label,
    Type: AppCatalog.Field.Barcode.Type,
    ReadOnly: true
  },
  DiscValue: {
    Label: "HEMAT",
    Type: InputType.Integer,
    Output: OutputType.Integer.Currency
  },
  DispatchSellPrice: {
    Label: AppCatalog.Field.DispatchSellPrice.Label,
    Type: AppCatalog.Field.DispatchSellPrice.Type,
    ReadOnly: AppCatalog.Field.DispatchSellPrice.ReadOnly,
    Output: AppCatalog.Field.DispatchSellPrice.Output
  },
  SellPriceNetto: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  SpecialPriceID: {
    Label: AppModule.SpecialPrice.FullName,
    Type: AppCatalog.Field.ID.Type,
    RelatedValue: "SpecialPriceName",
    RelatedModule: AppModule.SpecialPrice,
    ReadOnly: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  TotalBruto: {
    Label: AppCatalog.Field.Total.Label + " Harga",
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  }
}