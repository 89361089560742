import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import RcvCatalog from "../Receive/RcvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ID: {
    Label: AppModule.RtnReceive.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "ReturnNumber",
    RelatedModule: AppModule.RtnReceive,
    Param: AppModule.RtnReceive.Param + AppCatalog.Field.ID.Param
  },
  ReturnNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppCatalog.Info.Return,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  ReceiveID: {
    Label: RcvCatalog.ReceiveNumber.Label,
    ExportLabel: AppModule.Receive.FullName,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "ReceiveNumber",
    RelatedModule: AppModule.Receive,
    Param: AppModule.Receive.Param + AppCatalog.Field.ID.Param
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName + " Pengembalian",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  VendorAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Vendor.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor
  },
  VendorName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 200
  },

  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppCatalog.Info.Return,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  ReceiveDate: {
    Label: RcvCatalog.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ReceiveNumber: {
    Label: RcvCatalog.ReceiveNumber.Label,
    Type: AppCatalog.Field.Name.Type,
    Required: true,
    Highlight: true
  },
  VendorAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  }
}