<template>
  <table class="ks-is-fullwidth">
    <template v-for="(item, index) in items">
      <template>
        <tr :key="'dispatch' + index">
          <td colspan="100">
            <kst-value class="print-thermal-size" field="DispatchID" 
              :data="item" :catalog="SlsItemCatalog"
            />
          </td>
        </tr>
      </template>

      <template>
        <tr :key="'detail' + index">
          <td class="is-flex">
            <kst-value class="print-thermal-size ks-space-right" field="Qty" 
              :data="item" :catalog="SlsItemCatalog"
            />
            <div class="ks-space-right">x</div>
            <kst-value class="print-thermal-size" field="SellPrice" 
              :data="item" :catalog="SlsItemCatalog"
            />
          </td>
          <td class="has-text-right">
            <kst-value class="print-thermal-size" field="Total" 
              :data="item" :catalog="SlsItemCatalog"
            />
          </td>
        </tr>
      </template>
    </template>
  </table>
</template>

<script>
import SlsItemCatalog from "../SlsItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    SlsItemCatalog: SlsItemCatalog
  })
}
</script>