import AppModule from "../../../services/App/AppModule.js";
import AppModuleType from "../../../services/App/AppModuleType.js";
import WarehouseModel from "../../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.ReportStockAdj,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}