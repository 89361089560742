import AppCatalog from "../../services/App/AppCatalog.js";
import DaiItemCatalog from "./DaiItemCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  QtyAdjustment: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyAdjustment.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyAdjustment.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyInvoice: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyInvoice.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyInvoice.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyLast: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyLast.Label,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyReceive: {
    Label:AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyReceive.Label,
    ExportLabel:AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyReceive.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyRtnInvoice: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyRtnInvoice.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyRtnReceive: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyRtnReceive.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStock: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStock.Label,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockClosing: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockClosing.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockClosing.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockConv: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockConv.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockConv.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockOpname: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockOpname.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockOpname.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  },
  QtyStockTrf: {
    Label: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockTrf.Label,
    ExportLabel: AppCatalog.Field.Total.Label + " " + DaiItemCatalog.QtyStockTrf.ExportLabel,
    Type: AppCatalog.Field.Qty.Type
  }
}