<template>
  <kst-form no-bezel class="block">
    <template #top-right>
      <kst-button action-mode full-label
        class="is-primary"
        :label="Info.Button.ItemVariantNew.Label"
        :module="Model.Module"
        :moduleType="Model.ModuleType.New"
        :urlParams="urlParams"
      />
    </template>
  </kst-form>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";

export default {
  props: {
    refId: Number
  },
  data: () => ({
    Info: Info,
    Model: Model
  }),
  computed: {
    urlParams() {
      return this.$kst.Route.getParams({
        [Catalog.RefID.Param]: this.refId
      });
    }
  }
}
</script>