import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  CategoryID: {
    Label: AppCatalog.Field.Category.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "CategoryName"
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  Name: {
    Label: AppModule.ExpenseCategory.FullName,
    Type: AppCatalog.Field.Name.Type,
    Required: true,
    MaxLength: 100,
    Highlight: true
  }
}