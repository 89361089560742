export default {
  methods: {
    showConfirm(message, confirmCallback, cancelCallback, params) {
      let strMessage = this.$kst.Convert.arrayToString(message);

      this.$buefy.dialog.confirm({
        message: strMessage,
        onConfirm: () => {
          if (confirmCallback) {
            confirmCallback(params);
          }
        },
        onCancel: () => {
          if (cancelCallback) {
            cancelCallback(params);
          }
        }
      });
    }
  }
}