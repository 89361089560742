import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  Date: {
    Label: AppCatalog.Field.Date.Label + " Transaksi",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  DispatchID: {
    Label: AppModule.Item.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item
  },
  ModuleName: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Number: {
    Label: AppCatalog.Field.Number.Label + " Transaksi",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  Price: {
    Label: AppCatalog.Field.SellPrice.PrintLabel,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    Output: AppCatalog.Field.Total.Output,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  },
  WarehouseID2: {
    Label: AppModule.Warehouse.FullName + "2",
    ExportLabel: AppCatalog.Field.Name.Label + " " +
      AppModule.Warehouse.FullName + "2",
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName2",
    RelatedModule: AppModule.Warehouse
  },

  // support
  DispatchName: {
    Label: AppModule.Item.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  WarehouseName: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.Name.Type
  }
}