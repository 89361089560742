<template>
  <div class="kstmod-invoice-printthermal__mb mt-5">
    <template v-if="isMutiaraBali">
      <div class="has-text-centered">
        Terima kasih sudah berbelanja di toko kami.<br/>
        Tinggalkan ulasan positif di halaman Google kami utk mendapatkan discount 2%
      </div>
      <div class="is-flex is-justify-content-center">
        <kst-image read-only thumbnail src="client_mb_site_3yNdmtYll.png"/>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    companyData: Object
  },
  computed: {
    isMutiaraBali() {
      return this.companyData.id === 21;
    }
  }
}
</script>