<template>
  <kst-page-export
    :catalog="WhsSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
  </kst-page-export>
</template>

<script>
import Info from "../WhsInfo.js";
import Model from "../WhsModel.js";
import WhsSearchCatalog from "../WhsSearchCatalog.js";
import Services from "../../../services/Api/WarehouseService.js";

import Export from "./WhsExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    WhsSearchCatalog: WhsSearchCatalog,
    searchData: {
      Search: "",
      IsActive: null
    },
    userData: {
      WithList: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
    },

    getListOptions() {
      return {
        active: this.$kst.Search.getValue(this.searchData.IsActive),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>