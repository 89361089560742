<template>
  <div>
    <div class="label">{{ header }}</div>
    <kst-value field="ClientID" :data="details" :catalog="Catalog"/>
    <kst-value field="ClientAddress" :data="details" :catalog="Catalog"/>
    <kst-value field="PONumber" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    header() {
      return Info.Section.PrintFaktur.ClientHeader.Title;
    }
  }
}
</script>