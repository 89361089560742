import App from "../../services/App/App.js";
import Catalog from "./RcpyCatalog.js";

export default {
  setDetailsByEditData(userData, recPayData, statusEnum) {
    userData.ID = recPayData.ID;

    if (recPayData.StatusID === statusEnum.Active.ID) {
      userData.StatusID = statusEnum.Void.ID;
    }
  },

  getRecPaySectionHelp(recPayData) {
    return Catalog.PayNumber.Label + ": " +
      App.Value.getValue("PayNumber", recPayData, Catalog) + ", " +
      Catalog.Date.Label + ": " +
      App.Value.getValue("Date", recPayData, Catalog);
  },

  populateData(userData) {
    // target StatusID: Void
    return {
      ID: userData.ID,
      StatusID: userData.StatusID,
      VoidComment: App.Out.getString(userData.VoidComment)
    };
  }
}