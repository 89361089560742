import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import Catalog from "./DbpyCatalog.js";
import ModelBody from "./DbpyModel_Body.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByDetailsVerified(userData) {
    userData.IsVerified = true;
    App.Array.truncate(userData.SelectedReceiveList);
  },
  setDetailsByPaymentList(userData, paymentList) {
    userData.PaymentTypeName = PaymentTypeModel.getDefault(paymentList);
  },
  setDetailsByVendor(userData, vendorData) {
    // user input
    userData.VendorID = vendorData.ID;
    userData.VendorName = vendorData.Name;
    // by system
    userData.VendorAlias = vendorData.Alias;
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setReceiveByPaymentValue(userData, userReceiveList, selectedReceive) {
    const selectedPaymentValue = App.Out.getInteger(
      selectedReceive.PaymentValue
    );

    if (selectedPaymentValue === null) {
      const index = userData.SelectedReceiveList.indexOf(selectedReceive);
      if (index !== -1) {
        userData.SelectedReceiveList.splice(index, 1);
      }
    }
    else {
      const index = userData.SelectedReceiveList.indexOf(selectedReceive);
      if (index === -1) {
        userData.SelectedReceiveList.push(selectedReceive);
      }
    }

    this.updateDetails(userData, userReceiveList);
  },

  setReceiveListBySearch(userReceiveList, receiveList) {
    App.Array.truncate(userReceiveList);

    let userReceive;
    for (const receive of receiveList) {
      userReceive = ModelBody.createReceive();
      this.setReceiveListBySearch_setReceive(userReceive, receive);

      userReceiveList.push(userReceive);
    }
  },
  setReceiveListBySearch_setReceive(userReceive, receive) {
    userReceive.ReceiveID = receive.ID;
    // by system
    userReceive.ReceiveNumber = receive.ReceiveNumber;
    userReceive.ReceiveDate = receive.Date;
    userReceive.ReceiveBalance = receive.DebtTotal;
    userReceive.ReceiveDueDate = receive.DueDate;
    userReceive.VendorID = receive.VendorID;
    userReceive.VendorName = receive.VendorName;
    userReceive.WarehouseID = receive.WarehouseID;
    userReceive.WarehouseName = receive.WarehouseName;
  },
  setReceiveListByCheck(userData, userReceiveList, selectedReceiveList,
    selectedReceive
  ) {
    if (selectedReceiveList.includes(selectedReceive)) {
      let totalSelectedPaymentValue = 0;
      for (const row of selectedReceiveList) {
        if (row !== selectedReceive) {
          totalSelectedPaymentValue += App.Out.getInteger(row.PaymentValue);
        }
      }

      const userPaymentValue = App.Out.getInteger(userData.PaymentValue);
      const availPaymentValue = userPaymentValue - totalSelectedPaymentValue;
      if (availPaymentValue === 0) {
        const index = selectedReceiveList.indexOf(selectedReceive);
        if (index !== -1) {
          selectedReceiveList.splice(index, 1);
        }
      }
      else if (availPaymentValue >= selectedReceive.ReceiveBalance) {
        selectedReceive.PaymentValue = App.In.getInteger(
          selectedReceive.ReceiveBalance
        );
      }
      else {
        selectedReceive.PaymentValue = App.In.getInteger(availPaymentValue);
      }
    }
    else {
      selectedReceive.PaymentValue = "";
    }

    this.updateDetails(userData, userReceiveList);
  },
  setReceiveListByCheckAll(userData, userReceiveList) {
    let userReceive, totalSelectedPaymentValue = 0;
    for (userReceive of userReceiveList) {
      totalSelectedPaymentValue += App.Out.getInteger(userReceive.PaymentValue);
    }

    let selectedReceiveList = [];
    const userPaymentValue = App.Out.getInteger(userData.PaymentValue);
    let availPaymentValue = userPaymentValue - totalSelectedPaymentValue;
    for (userReceive of userReceiveList) {
      if (App.Out.getInteger(userReceive.PaymentValue) !== null) {
        selectedReceiveList.push(userReceive);
        continue;
      }

      if (availPaymentValue === 0) {
        // do nothing
      }
      else if (availPaymentValue >= userReceive.ReceiveBalance) {
        userReceive.PaymentValue = App.In.getInteger(
          userReceive.ReceiveBalance
        );
        availPaymentValue -= userReceive.ReceiveBalance;
        selectedReceiveList.push(userReceive);
      }
      else {
        userReceive.PaymentValue = App.In.getInteger(availPaymentValue);
        availPaymentValue = 0;
        selectedReceiveList.push(userReceive);
      }
    }

    userData.SelectedReceiveList = selectedReceiveList;
    this.updateDetails(userData, userReceiveList);
  },
  setReceiveListByUncheckAll(userData, userReceiveList) {
    for (let userReceive of userReceiveList) {
      userReceive.PaymentValue = "";
    }

    this.updateDetails(userData, userReceiveList);
  },

  getInvalidReceiveTotal(userData) {
    return AppCatalog.Message.MustEqualsTo + " " +
      App.Format.getDecimal(userData.PaymentValue) + " (" +
      Catalog.PaymentValue.Label + ")";
  },

  clearDetailsByVendor(userData) {
    // user input
    userData.VendorID = null;
    userData.VendorName = "";
    // by system
    userData.VendorAlias = "";
  },

  resetReceiveTotal(userData) {
    userData.ReceiveTotal = 0;
  },

  updateDetails(userData, userReceiveList) {
    let paymentValue;
    userData.ReceiveTotal = 0;

    for (const userReceive of userReceiveList) {
      paymentValue = App.Out.getInteger(userReceive.PaymentValue);
      userData.ReceiveTotal += paymentValue;
    }
  }
}