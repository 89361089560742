<template>
  <div>
    <Summary :debtData="debtData"/>

    <DebtPayRelatedList
      :hideAction="debtData.ReceiveDebtTotal === 0"
      :items="debtData.DebtPayList"
    />
  </div>
</template>

<script>
import Summary from "./RcvD_DebtPay_Summary.vue";
import DebtPayRelatedList from "../../DebtPay/RelatedList/DbpyRL.vue";

export default {
  components: {
    Summary,
    DebtPayRelatedList
  },
  props: {
    debtData: Object
  }
}
</script>