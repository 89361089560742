<template>
  <div>
    <ClientSearchList
      @init="handleClient_Init"
      @submit="handleClient_Submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-2">
        <kst-input floating-label load-on-focus
          field="Search" :data="searchData" :catalog="SearchCatalog"
          @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-output addons floating-label 
          field="ClientID" :data="searchData" :catalog="SearchCatalog"
        >
          <template #default>
            <kst-input floating-label
              field="ClientName"
              :data="searchData"
              :disabled="clientDisabled"
              :catalog="SearchCatalog"
              @input="handleInput"
            />
          </template>
          <template #right>
            <div class="control">
              <template v-if="searchData.ClientID === null">
                <kst-tooltip search-mode hover-only :content="SearchCatalog.ClientID.Label">
                  <kst-button search-mode @click="handleSearch_Client"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="SearchCatalog.ClientID.Label">
                  <kst-button clear-mode @click="handleClear_Client"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="PaymentTypeName" :data="searchData" :catalog="SearchCatalog"
          :options="paymentOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="StatusID" :data="searchData" :catalog="SearchCatalog"
          :options="statusOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="SearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Model from "../RcpyModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/RecPayServices.js";

import ClientSearchList from "../../Client/SearchList/CliScL.vue";

export default {
  components:{
    ClientSearchList
  },
  props: {
    searchData: Object,
    paymentOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    // func
    showClientFunc: null
  }),
  computed: {
    clientDisabled() {
      return this.searchData.ClientID !== null;
    },
    statusOptions() {
     return Model.createStatusOptions(SearchCatalog.StatusID.Label);
    }
  },
  methods: {
    /*** by client modal ***/

    handleClient_Init(show) {
      this.showClientFunc = show;
    },
    handleClient_Submit(data) {
      this.searchData.ClientID = data.ID;
      this.searchData.ClientName = data.Name;
      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_Client() {
      this.showClientFunc(Services.getRelatedClientList);
    },
    handleClear_Client() {
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";

      this.handleInput();
    }
  }
}
</script>