import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./ExpCatalog.js";

export default {
  // required in model
  Module: AppModule.Expense,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Expenses_Act_Details,
    Act_Edit: AppModuleAttr.Expenses_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Expenses_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Expenses_Act_ExportList,
    Act_List: AppModuleAttr.Expenses_Act_List,
    Act_New: AppModuleAttr.Expenses_Act_New,
    Act_PrintList: AppModuleAttr.Expenses_Act_PrintList
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    Fields: ["ExpenseNumber"],
    ListParams: ["Search", "Date", "PaymentTypeName", "SubCategoryID", 
      "WarehouseID"]
  },

  createDetails() {
    return {
      ID: null,
      // user input
      ExpenseNumber: "",
      IsAutoNumber: true,
      Date: App.In.getDateToday(),
      SubCategoryID: null,
      WarehouseID: App.Session.getDefaultWarehouseID(),
      PaymentTypeName: null,
      Total: "",
      Comment: ""
    };
  },
  createSummary() {
    return {
      Total: 0,
      List: []
    };
  },

  getResultSectionHelp(dataList) {
    let result = {
      Total: 0
    };
    for (const row of dataList) {
      result.Total += row.Total;
    }

    return Catalog.Total.Label + ": " +
      App.Value.getValue("Total", result, Catalog);
  },
  getSummary(dataList) {
    let result = this.createSummary();

    // populate: List
    let fieldKeys = [ "SubCategoryName" ];
    const fieldValues = [
      { field: "Total", type: App.Pivot.Type.SUM }
    ];

    result.List = App.Pivot.create(dataList, fieldKeys, fieldValues);

    fieldKeys = [{
      [App.Sort.Key.Field]: "SubCategoryName",
      [App.Sort.Key.Order]: App.Sort.Order.Asc
    }];
    App.Sort.begin(result.List, fieldKeys);

    // populate: Total
    for (const row of result.List) {
      result.Total += row.Total;
    }

    return result;
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      ExpenseNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ExpenseNumber),
      Date: App.Out.getDateString(userData.Date),
      SubCategoryID: userData.SubCategoryID,
      WarehouseID: userData.WarehouseID,
      PaymentTypeName: userData.PaymentTypeName,
      Total: App.Out.getInteger(userData.Total),
      Comment: App.Out.getString(userData.Comment)
    };
  }
}