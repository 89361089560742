<template>
<html>
<head>
<meta charset="utf-8"/>
<meta http-equiv="X-UA-Compatible" content="IE=edge"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Verifikasi</title>
<link rel="stylesheet" type="text/css" id="applicationStylesheet" href="./_verification.css"/>
</head>
<body>
	<div class="base-background"></div>
	<div class="second-background"></div>
	<div class="layout" v-if="email || userID">
		<div class="content">
			<div class="logo">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="45" height="45" viewBox="0 0 71.694 71.744">
					<defs>
						<linearGradient id="linear-gradient" x1="0.147" y1="0.853" x2="0.859" y2="0.141" gradientUnits="objectBoundingBox">
						<stop offset="0" stop-color="#f27c21"/>
						<stop offset="1" stop-color="#f89f1f"/>
						</linearGradient>
						<linearGradient id="linear-gradient-2" x1="0.125" y1="0.79" x2="0.864" y2="-0.038" gradientUnits="objectBoundingBox">
						<stop offset="0.035" stop-color="#0e1921"/>
						<stop offset="0.4" stop-color="#143d3c"/>
						<stop offset="1" stop-color="#055453"/>
						</linearGradient>
						<linearGradient id="linear-gradient-3" x1="0.111" y1="0.734" x2="0.836" y2="0.087" xlink:href="#linear-gradient-2"/>
						<linearGradient id="linear-gradient-4" x1="-0.18" y1="0.891" x2="1.434" y2="0.219" xlink:href="#linear-gradient-2"/>
					</defs>
					<g id="Group_576" data-name="Group 576" transform="translate(-18.967)">
						<g id="Group_25" data-name="Group 25" transform="translate(18.967 0)">
						<circle id="Ellipse_9" data-name="Ellipse 9" cx="17.33" cy="17.33" r="17.33" transform="translate(37.033)" fill="#e0e0df"/>
						<circle id="Ellipse_10" data-name="Ellipse 10" cx="7.081" cy="7.081" r="7.081" transform="translate(16.442 4.379)" fill="url(#linear-gradient)"/>
						<path id="Path_18" data-name="Path 18" d="M79.394,25.008c2.05-.373,3.261-1.957,2.609-3.354-.559-1.491-2.7-2.329-4.752-1.863-6.895,1.4-10.808,5.218-12.951,9.131,4.566,4.659,5.4,6.709,5.4,6.709C70.636,31.717,72.872,26.313,79.394,25.008Z" transform="translate(-30.621 -1.342)" fill="url(#linear-gradient-2)"/>
						<g id="Group_23" data-name="Group 23" transform="translate(0 22.425)">
							<g id="Group_22" data-name="Group 22">
							<g id="Group_21" data-name="Group 21">
								<path id="Path_19" data-name="Path 19" d="M67.33,35.838a20.236,20.236,0,0,1,.652-2.236,10.142,10.142,0,0,0-.652,2.236s-.839-2.05-5.4-6.709h0a21.933,21.933,0,0,0-5.963-3.354c-5.4-2.05-11.554-2.236-18.262-.652a27.4,27.4,0,0,0-4.659,1.491,11.135,11.135,0,0,0-3.82,2.609,3.559,3.559,0,0,0-.652,4.193,9.466,9.466,0,0,0,4.659,4.193,17.385,17.385,0,0,0,5.684,1.863,5.249,5.249,0,0,0,1.211,0c-.093.186-.186.466-.28.652.093-.186.186-.466.28-.652l1.3-3.075c-.093-.093-.093-.186-.186-.186l-.28-.186a6.207,6.207,0,0,1-2.7-2.7,2.2,2.2,0,0,1,.186-2.7A6.058,6.058,0,0,1,41.8,28.85a14.081,14.081,0,0,1,9.038.466,10.981,10.981,0,0,1,5.963,5.59,20.625,20.625,0,0,1,1.4,12.765,28.529,28.529,0,0,1-5.684,12.951,17.7,17.7,0,0,1-9.5,6.709c.652,3.82,5.777,2.423,5.777,2.423-.093.093-.28.093-.373.186a36.091,36.091,0,0,0,6.988-3.82A32.949,32.949,0,0,0,64.814,56.9a26.863,26.863,0,0,0,4.1-10.436A17.425,17.425,0,0,0,67.61,36.4Z" transform="translate(-28.154 -24.068)" fill="url(#linear-gradient-3)"/>
							</g>
							</g>
						</g>
						<g id="Group_24" data-name="Group 24" transform="translate(3.584 28.843)">
							<path id="Path_20" data-name="Path 20" d="M43.367,67.708c.373-4.845,2.143-13.51,5.4-25.53v-.093c.932-4.379,1.211-7.268,1.025-8.758a2.083,2.083,0,0,0-.28-.932,1.986,1.986,0,0,0-.932-1.025,2.862,2.862,0,0,0-2.329-.28,3.97,3.97,0,0,0-2.423,1.863A34.233,34.233,0,0,0,41.6,36.867l-1.3,3.075c-.093.186-.186.466-.28.652-.28.652-.559,1.4-.839,2.143a112.338,112.338,0,0,0-4.566,14.722c-1.211,5.031-1.957,8.293-2.143,9.97-.186,1.584-.373,2.888-.466,3.82v.932l.093.186c.186.373.839,1.491,3.168,1.491h.466l.652-.093h.186c.373-.093,1.118-.186,2.05-.373s1.957-.373,2.982-.652a46.107,46.107,0,0,0,7.174-2.329c.093-.093.28-.093.373-.186C49.144,70.223,44.019,71.528,43.367,67.708Z" transform="translate(-32 -30.956)" fill="url(#linear-gradient-4)"/>
						</g>
						</g>
					</g>
				</svg>
			</div>
			<div class="content-child">
				<p class="p1" @click="isModalActive=true">Verifikasi Email</p>
				<p class="p2">Tinggal satu langkah lagi! Kami telah mengirimkan e-mail verifikasi ke {{this.email}}</p>
				<p class="p2 extra-margin"><br>Klik link yang telah kami berikan di e-mail untuk menyelesaikan pendaftaranmu. Apabila tidak menemukan email, harap mengecek folder Spam.</p>
				<!-- <p class="p3">{{ message }}</p> -->
				<p class="p2"><br>Tidak menemukan e-mail verifikasi?</p>
				<button class="button-kirim" :disabled="disableResend" @click.prevent="startCountDown">
					<p class="p2">Kirim Ulang</p>
				</button>
				<p class="p3" v-if="countDown>0">Kirim ulang dalam 00:{{ countDown }}</p>
				<p class="p2 p2-italic"><br>Butuh Bantuan? <a href="contactus" target="_blank">Kontak Kami</a></p>
			</div>
		</div>
	</div>
	<b-modal v-model="isModalActive">
		<div class="modal-content">
			<div class="modal-content-child">
				<b-icon v-if="isSentEmail == true"
					pack="fas"
					icon="check-circle"
					size="is-large"
					type="is-success">
				</b-icon>
				<b-icon v-if="isSentEmail == false"
					pack="fas"
					icon="times-circle"
					size="is-large"
					type="is-danger">
				</b-icon>
				<p class="p2">{{ message }}</p>
			</div>
			<button class="button-kirim" @click="isModalActive = false">
				<p class="p2">OK</p>
			</button>
		</div>
	</b-modal>
</body>
</html>
</template>
<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
// import Model from "../SecurityModel.js";
// import Services from "../SecurityServices.js";

export default {
  mixins: [AxiosMixin],
  data: () => ({
	countDown : 0,
	disableResend : false,
	userID: 0,
	email: 'dirigocoba@gmail.com',
	message: '',
	isModalActive: false,
	isSentEmail: false
  }),
	mounted() {
		this.initCountDown();
		this.email = window.email_param;
		this.userID = window.userid_param;
	},
  methods: {
	initCountDown() {
		this.countDown = this.readCookie('cd');
		if(this.countDown > 0) {
			this.disableResend = true;
			this.countDownTimer();
		}
    },
	async startCountDown() {
        this.countDown = 59;
		this.disableResend = true;
		try {
			await this.resendEmail();
        } catch(err) {
			this.isSentEmail = false;
			this.isModalActive = true;
			this.message = 'Gagal mengirim email, silahkan coba lagi atau hubungi kami.';
        }
		this.countDownTimer();
    },
	countDownTimer() {
		if(this.countDown > 0) {
			setTimeout(() => {
				this.countDown -= 1;
				this.countDown = ("0" + this.countDown).slice(-2);
				this.writeCookie('cd', this.countDown, 1);
				this.countDownTimer();
			}, 1000)
		} else {
			this.disableResend = false;
		}
	},
	writeCookie(name,value,mins) {
		var date, expires;
		if (mins) {
			date = new Date();
			date.setTime(date.getTime()+(mins*60*1000));
			expires = "; expires=" + date.toGMTString();
				}else{
			expires = "";
		}
		document.cookie = name + "=" + value + expires + "; path=/";
	},
	readCookie(name) {
		var i, c, ca, nameEQ = name + "=";
		ca = document.cookie.split(';');
		for(i=0;i < ca.length;i++) {
			c = ca[i];
			while (c.charAt(0)==' ') {
				c = c.substring(1,c.length);
			}
			if (c.indexOf(nameEQ) == 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return '';
	},
	async resendEmail() {
      // return new Promise((resolve, reject) => {
      //   const config = Services.resendEmail(Model.getResendEmail(this.userID));
      //   this.runAxios(config, null, data => {
			// this.isSentEmail = true;
			// this.isModalActive = true;
			// this.message = 'Email verifikasi berhasil dikirim.';
			// resolve(data.id);
      //   }, err => {
			// reject(new Error(err));
      //   })
      // })
    },
  }
}
</script>
<style scoped lang="css" src="./_verification.css"/>