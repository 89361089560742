import App from "../../services/App/App.js";
import Catalog from "./PlosCatalog.js";
import SummaryByAcc from "./PlosModel_Print_SummaryByAcc.js";
import SummaryByCash from "./PlosModel_Print_SummaryByCash.js";
import CstEnum from "../CompanySettingType/CstEnum.js";

export default {
  getTotalInfo(summaryData) {
    return Catalog.Total.Label + ": " +
      App.Value.getValue("Total", summaryData, Catalog);
  },

  getSummary(transactionDetails, searchData) {
    return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
      ? SummaryByAcc.getSummary(transactionDetails, searchData)
      : SummaryByCash.getSummary(transactionDetails, searchData);
  }
}