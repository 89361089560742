<template>
  <div>
    <template v-if="hasDisc">
      <div class="columns is-mobile">
        <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
        <div class="column pb-0 is-narrow">
          <div class="is-size-5">
            {{ Catalog.TotalBruto.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right">
            <kst-value class="is-size-5 pr-3"
              field="TotalBruto" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="details.DiscValue !== null" class="columns is-mobile">
        <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="is-size-5">
            {{ Catalog.DiscValue.PrintLabel }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="is-size-5 pr-3"
              field="DiscValue" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="details.DiscPercent !== null" class="columns is-mobile">
        <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="is-flex is-size-5">
            {{ Catalog.DiscPercent.PrintLabel }} (
              <kst-value class="is-size-5"
                field="DiscPercent" :data="details" :catalog="Catalog"
              />
            )
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="is-size-5 pr-3"
              field="TotalDisc" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>
    </template>

    <div class="columns is-mobile">
      <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5 pr-3"
            field="Total" :data="details" :catalog="Catalog"
          />
        </div>
      </div>
    </div>

    <div v-if="companySettingData.InvoicePrintNote !== null" class="columns is-mobile">
      <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
      <div class="column">
        <kst-value field="InvoicePrintNote" :data="companySettingData" :catalog="InvCpCatalog"/>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-4 is-hidden-mobile ks-print-hidden-mobile"/>
      <div class="column">
        <template v-for="(item, index) in footer">
          <div :key="index">{{ item }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import InvCpCatalog from "../InvCpCatalog.js";
import Info from "../InvInfo.js";

export default {
  props: {
    details: Object,
    companySettingData: Object
  },
  data: () => ({
    Catalog: Catalog,
    InvCpCatalog: InvCpCatalog
  }),
  computed: {
    hasDisc() {
      return (this.details.DiscValue === null && this.details.DiscPercent === null)
        ? false : true;
    },
    footer() {
      return Info.Section.PrintFaktur.Footer;
    }
  }
}
</script>