<template>
  <kst-section search-mode class="pb-0">
    <form novalidate @submit.prevent="debounceSearch">
      <div class="columns my-0 is-multiline">
        <div class="column is-narrow">
          <kst-input floating-label
            field="WarehouseID" :data="userData" :catalog="Catalog"
            :options="warehouseOptions" @input="handleInput"
          />
        </div>
        <div class="column is-narrow">
          <kst-input floating-label
            field="CashierID" :data="userData" :catalog="Catalog"
            :options="cashierOptions" @input="handleInput"
          />
        </div>
        <div class="column is-narrow">
          <kst-input floating-label
            field="InvoiceRecordTypeID" :data="userData" :catalog="Catalog"
            :options="invoiceRecordTypeOptions" @input="handleInput"
          />
        </div>
        <div class="column is-narrow">
          <kst-output floating-label
            field="Date" :data="userData" :catalog="Catalog"
          />
        </div>

        <div class="column is-narrow">
          <input type="submit" value="Cari" class="is-hidden">
        </div>
      </div>
    </form>
  </kst-section>
</template>

<script>
import SearchMixin from "../../../mixins/SearchMixin.js";
import Catalog from "../SlrcCatalog.js";
import Model from "../SlrcModel.js";

export default {
  mixins: [SearchMixin],
  props: {
    userData: Object,
    cashierOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    invoiceRecordTypeOptions() {
      return Model.getInvoiceRecordTypeOptions(Catalog.InvoiceRecordTypeID.Label);
    }
  },
  created() {
    this.initSearch(this.userData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>