<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../CliCatalog.js";
import CliaCatalog from "../CliaCatalog.js";
import Info from "../CliInfo.js";
import Model from "../CliModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },
    addList(clientList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);
      this.addColumnHeader(Catalog.DiscPercent);

      if (this.userData.WithReceivables) {
        this.addColumnHeader(Catalog.DaysDueDate);
        this.addColumnHeader(Catalog.OverDueDate);
        this.addColumnHeader(Catalog.CreditAmount);
        this.addColumnHeader(Catalog.RecTotal);
      }

      if (this.userData.WithAddress) {
        this.addColumnHeader(CliaCatalog.AddressName);
        this.addColumnHeader(CliaCatalog.Address);
        this.addColumnHeader(CliaCatalog.ContactName);
        this.addColumnHeader(CliaCatalog.ContactMobile);
        this.addColumnHeader(CliaCatalog.ContactPhone);
        this.addColumnHeader(CliaCatalog.ContactEmail);
      }

      if (this.userData.WithSelfService && this.access.Sect_SelfService) {
        this.addColumnHeader(Catalog.SelfServiceUrl);
      }

      // value
      for (const row of clientList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("Alias", row, Catalog);
        this.addCellValue("Mobile", row, Catalog);
        this.addCellValue("Phone", row, Catalog);
        this.addCellValue("Email", row, Catalog);
        this.addCellValue("DiscPercent", row, Catalog);

        if (this.userData.WithReceivables) {
          this.addCellValue("DaysDueDate", row, Catalog);
          this.addCellValue("OverDueDate", row, Catalog);
          this.addCellValue("CreditAmount", row, Catalog);
          this.addCellValue("RecTotal", row, Catalog);
        }

        if (this.userData.WithAddress) {
          this.addCellValue("AddressName", row, CliaCatalog);
          this.addCellValue("Address", row, CliaCatalog);
          this.addCellValue("ContactName", row, CliaCatalog);
          this.addCellValue("ContactMobile", row, CliaCatalog);
          this.addCellValue("ContactPhone", row, CliaCatalog);
          this.addCellValue("ContactEmail", row, CliaCatalog);
        }

        if (this.userData.WithSelfService && this.access.Sect_SelfService) {
          Model.SelfService.updateData(row);
          this.addCellValue("SelfServiceUrl", row, Catalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>