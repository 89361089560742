import App from "../../services/App/App.js";
import Catalog from "./DlvCatalog.js";
import ModelBody from "./DlvModel_Body.js";
import ModelEditStatus from "./DlvModel_EditStatus.js";
import ModelNew from "./DlvModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  DeliveryItem: ModelBody.DeliveryItem,
  // other
  Invoice: ModelBody.Invoice,
  InvoiceItem: ModelBody.InvoiceItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  Status: ModelBody.Status,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },
  createStatusOptions(optionAllText) {
    return ModelBody.createStatusOptions(optionAllText, ModelBody.Status);
  },

  setDetailsByInvoice(userData, invoiceData) {
    ModelBody.setDetailsByInvoice(userData, invoiceData, ModelBody.Status);
  },
  setDetailsByStatus(details) {
    ModelBody.setDetailsByStatus(details, ModelBody.Status);
  },

  setItemsByInvoice(userItems, invoiceData) {
    ModelBody.setItemsByInvoice(userItems, invoiceData);
  },
  setItemByInvoiceItem(userItem, invoiceItemData) {
    ModelBody.setItemByInvoiceItem(userItem, invoiceItemData);
  },
  setItemErrors(item, field, errors) {
    item.Errors = App.Vee.getRowErrors(item, field, errors);
  },

  getItemInfo(userItem) {
    return ModelBody.getItemInfo(userItem);
  },

  getDeliverySectionHelp(deliveryData) {
    return ModelBody.getDeliverySectionHelp(deliveryData);
  },
  getInvoiceSectionHelp(userData) {
    return ModelBody.getInvoiceSectionHelp(userData);
  },
  getInvoiceItemsForSelection(invoiceItems, userItems) {
    return ModelBody.getInvoiceItemsForSelection(invoiceItems, userItems);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getActions(id, access) {
      return App.Access.getDetailsAvailableActions(
        id, ModelBody.Actions.Details, ModelBody.Module, access
      );
    }
  },


  /*** method - EditStatus ***/

  EditStatus: {
    setDetailsByData(userData, deliveryData) {
      ModelEditStatus.setDetailsByData(userData, deliveryData, ModelBody.Status);
    },

    populateData(userData) {
      return ModelEditStatus.populateDetails(userData, ModelBody.Status);
    },

    validationCompletedDate(userData) {
      return {
        [App.Vee.Rule.MinDate]: {
          target: userData.ArrivalDate,
          label: Catalog.ArrivalDate.Label,
          related: "@ArrivalDate"
        }
      };
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_EditStatus ||
        access.Act_PrintSJ;
    }
  },

  /*** method - New ***/

  New: {
    createStatusOptions() {
      return ModelNew.createStatusOptions(ModelBody.Status);
    },

    // related: Invoice
    updateInvoiceItems(invoiceData, deliveryItems) {
      ModelNew.updateInvoiceItems(invoiceData, deliveryItems);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
} 