<template>
  <kst-page-export
    :catalog="SpadSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check 
      v-model="userData.WithItems"
      :label="Info.Export.List.Excel.Option.SpadItem"
    />
  </kst-page-export>
</template>

<script>
import Info from "../SpadInfo.js";
import Model from "../SpadModel.js";
import SpadSearchCatalog from "../SpadSearchCatalog.js";
import Services from "../../../services/Api/SpecialPriceAdjServices.js";

import Export from "./SpadExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    SpadSearchCatalog: SpadSearchCatalog,
    searchData: {
      Search: "",
      Date: null,
      WarehouseID: null
    },
    userData: {
      WithList: true,
      WithItems: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
      this.userData.WithItems = true;
    },

    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields,
        withitems: this.$kst.Out.getBoolean(this.userData.WithItems)
      };
    }
  }
}
</script>