<template>
  <div>
    <kst-warning class="mb-4" :items="detailsWarning"/>

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      dateField="Date"
      numberField="ReturnNumber"
    />

    <template v-if="userData.ClientID">
      <kst-output control-off field="ClientID" :data="userData" :catalog="Catalog">
        <template #left>
          <kst-output addons floating-label
            field="ClientAlias" :data="userData" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="ClientName" :data="userData" :catalog="Catalog"
          />
        </template>
      </kst-output>
    </template>
    <template v-else>
      <kst-output control-off field="ClientID" :data="userData" :catalog="Catalog">
        <kst-output addons expanded floating-label
          field="ClientName" :data="userData" :catalog="Catalog"
        />
      </kst-output>
    </template>

    <kst-output field="ClientAddress" :data="userData" :catalog="Catalog"/>

    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from "../RinvCatalog.js";
import Info from "../RinvInfo.js";

export default {
  props: {
    userData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    detailsWarning() {
      return [Info.Message.Edit_RtnInvoiceFields];
    }
  }
}
</script>