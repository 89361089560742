<template>
  <div class="kstmod-item-relatedlist">
    <template v-if="access.Act_New">
      <Action :refId="refId"/>
    </template>

    <List :access="access" :dataList="dataList"/>
  </div>
</template>

<script>
import Model from "../ItemModel.js";

import Action from "./ItemRL_Action.vue";
import List from "./ItemRL_List.vue";

export default {
  components: {
    Action,
    List
  },
  props: {
    dataList: Array,
    refId: Number
  },
  data: () => ({
    access: {}
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.access = this.$kst.Access.create(Model.Access);
    }
  }
}
</script>