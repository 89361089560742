<template>
  <kst-table sequenceOff view-mode :data="transSummaryList">
    <b-table-column
      v-slot="{ column, row }"
      field="ActivityTypeName"
      :label="StcdTransSummaryCatalog.ActivityTypeName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdTransSummaryCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty-large"
      field="ChangesIn"
      :label="StcdTransSummaryCatalog.ChangesIn.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdTransSummaryCatalog"/>
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty-large"
      field="ChangesOut"
      :label="StcdTransSummaryCatalog.ChangesOut.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="StcdTransSummaryCatalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import StcdTransSummaryCatalog from "../StcdTransSummaryCatalog.js";

export default {
  props: {
    transSummaryList: Array
  },
  data: () => ({
    StcdTransSummaryCatalog: StcdTransSummaryCatalog
  })
}
</script>