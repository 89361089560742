<template>
  <kst-page-list
    :catalog="ItemSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        @input="handleInput"
      />
    </template>
    
    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import ItemSearchCatalog from "../ItemSearchCatalog.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import Search from "./ItemL_Search.vue";
import List from "./ItemL_List.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    ItemSearchCatalog: ItemSearchCatalog,
    Model: Model,
    access: {},
    searchData: {
      Search: ""
    }
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config);
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
    },

    resetSearch() {
      this.searchData.Search = "";
    },

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>