import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StrdItemCatalog from "./StrdItemCatalog.js";

export default {
  // required in model
  Module: AppModule.StockReduction,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  StockReductionItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  // other
  SpecialPrice: {
    Module: AppModule.SpecialPrice,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.StockReduction_Act_Details,
    Act_ExportDetails: AppModuleAttr.StockReduction_Act_ExportDetails,
    Act_List: AppModuleAttr.StockReduction_Act_List,
    Act_New: AppModuleAttr.StockReduction_Act_New,
    Act_PrintDetails: AppModuleAttr.StockReduction_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.StockReduction_Act_PrintList,
    Glob_HPP: AppModuleAttr.All_Glob_HPP
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    ListFields: ["ReductionNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      ReductionNumber: "",
      IsAutoNumber: true,
      TransactionID: null,
      Comment: "",
      // defined by system
      Date: App.In.getDateToday(),
      TransactionNumber: null,
      TransactionDate: null,
      Total: 0,
      SpecialPriceCount: 0,
      // UI validation
      InvalidItems_vsStock: null,
      StockMissingQty: 0
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      DispatchName: "",
      Qty: "",
      SellPrice: "0",
      // by system
      SKU: "",
      DispatchSellPrice: 0,
      PackagingName: "",
      PackagingValue: 1,
      SpecialPriceID: null,
      SpecialPriceName: "",
      SpecialPriceDiscPercent: 0,
      Stock: 0,
      SpecialPriceQty: 0,
      // computed
      Packaging: "",
      TotalQty: 0,
      Total: 0,
      // error
      Errors: [],
      ErrorsColl: {},
      // validation
      CustomValidations: {}
    }
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    userData.SpecialPriceCount = specialPriceCount;
  },

  getInvalidItems(details) {
    let items = [];

    if (details.InvalidItems_vsStock) {
      for (const invalidItem of details.InvalidItems_vsStock) {
        items.push(
          App.Data.getInvalidStockError(
            invalidItem.TotalQty,
            invalidItem.Stock,
            invalidItem.DispatchName
          )
        );
      }
    }

    return items;
  },

  updateDetails(userData, userItems) {
    userData.Total = 0;

    for (const userItem of userItems) {
      userData.Total += userItem.Total;
    }
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.TotalQty = App.Data.getTotalQty(
      userItem.Qty, userItem.PackagingValue
    );
    userItem.Total = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItemsByStock(userData, userItems) {
    // reset indicator
    userData.StockMissingQty = 0;

    userData.InvalidItems_vsStock = null;
    let invalidItems = [];

    const fieldKeys = "DispatchID";
    const fieldValues = [
      { field: "TotalQty", type: App.Pivot.Type.SUM },
      { field: "Stock", type: App.Pivot.Type.FIRST },
      { field: "DispatchName", type: App.Pivot.Type.FIRST }
    ];
    const pivotItems = App.Pivot.create(userItems, fieldKeys, fieldValues);

    for (const pivotItem of pivotItems) {
      if (pivotItem.TotalQty > pivotItem.Stock) {
        userData.StockMissingQty = 1;
        invalidItems.push({
          DispatchName: pivotItem.DispatchName,
          TotalQty: pivotItem.TotalQty,
          Stock: pivotItem.Stock
        });
      }
    }

    if (invalidItems.length > 0) {
      userData.InvalidItems_vsStock = invalidItems;
    }
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      ReductionNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.ReductionNumber),
      Date: App.Out.getDateString(userData.Date),
      TransactionID: userData.TransactionID,
      TransactionNumber: App.Out.getString(userData.TransactionNumber),
      TransactionDate: App.Out.getDateString(userData.TransactionDate),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        DispatchID: userItem.DispatchID,
        DispatchName: App.Out.getString(userItem.DispatchName),
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        Qty: App.Out.getInteger(userItem.Qty),
        SellPrice: App.Out.getInteger(userItem.SellPrice),
        DispatchSellPrice: userItem.DispatchSellPrice,
        SpecialPriceID: App.Out.getInteger(userItem.SpecialPriceID),
        SpecialPriceName: userItem.SpecialPriceName,
        SpecialPriceDiscPercent: userItem.SpecialPriceDiscPercent
      });
    }

    return resultItems;
  },

  /*** external ***/

  getSpecialPriceForSelection(specialPriceList, userItems) {
    let resultList = [], excludeIdList = [];

    for (const userItem of userItems) {
      if (!excludeIdList.includes(userItem.SpecialPriceID)) {
        excludeIdList.push(userItem.SpecialPriceID);
      }
    }

    // init row: all rows option
    resultList.push({
      ID: App.Search.OptionAll,
      Name: App.Search.LabelAll +
        StrdItemCatalog.SpecialPriceID.Label
    });

    for (const specialPrice of specialPriceList) {
      if (!excludeIdList.includes(specialPrice.ID)) {
        resultList.push(specialPrice);
      }
    }

    return resultList;
  }
}