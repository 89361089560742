import App from "../../services/App/App.js";
import ModelBody from "./StrdModel_Body.js";
import StrdItemCatalog from "./StrdItemCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByInvoice(userData, invoiceDetails) {
    userData.TransactionID = invoiceDetails.ID;
    userData.TransactionNumber = invoiceDetails.SONumber;
    userData.TransactionDate = App.In.getDate(invoiceDetails.InvoiceDate);
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },
  setItemsByStock(userItems, stockData) {
    let userItem;
    for (userItem of userItems) {
      if (userItem.DispatchID === stockData.ItemID &&
        userItem.SpecialPriceID === null
      ) {
        return false;
      }
    }

    userItem = ModelBody.createItem();
    // user input
    userItem.DispatchID = stockData.ItemID;
    userItem.DispatchName = stockData.Name;
    userItem.SellPrice = stockData.SellPrice;
    // by system
    userItem.SKU = stockData.SKU;
    userItem.DispatchSellPrice = stockData.SellPrice;
    userItem.PackagingName = stockData.PackagingName;
    userItem.Stock = stockData.Quantity;

    ModelBody.updateItem(userItem);
    userItems.push(userItem);

    return true;
  },
  setItemsBySpecialPriceDetails(userItems, stockSpecialPriceList) {
    let count = 0, userItem, olduserItem, conditions;
    let olduserItems = userItems.slice();

    for (const stockSpecialPrice of stockSpecialPriceList) {
      conditions = {
        DispatchID: stockSpecialPrice.ItemID,
        SpecialPriceID: stockSpecialPrice.SpecialPriceID
      };
      olduserItem = App.Array.getItem2(olduserItems, conditions);

      if (olduserItem === null) {
        userItem = ModelBody.createItem();
        this.setItemsBySpecialPriceDetails_setItem(
          userItem, stockSpecialPrice
        );
        ModelBody.updateItem(userItem);
        userItems.push(userItem);

        count++;
      }
    }

    return count;
  },
  setItemsBySpecialPriceDetails_setItem(userItem, stockSpecialPrice) {
    const specialPriceDiscAmount = App.Data.getDiscPercent_Value(
      stockSpecialPrice.SellPrice, stockSpecialPrice.DiscPercent
    );

    // user input
    userItem.DispatchID = stockSpecialPrice.ItemID;
    userItem.DispatchName = stockSpecialPrice.Name;
    userItem.SellPrice = stockSpecialPrice.SellPrice - specialPriceDiscAmount;
    // by system
    userItem.SKU = stockSpecialPrice.SKU;
    userItem.DispatchSellPrice = stockSpecialPrice.SellPrice;
    userItem.PackagingName = stockSpecialPrice.PackagingName;
    userItem.SpecialPriceID = stockSpecialPrice.SpecialPriceID;
    userItem.SpecialPriceName = stockSpecialPrice.SpecialPriceName;
    userItem.SpecialPriceDiscPercent = stockSpecialPrice.DiscPercent;
    userItem.Stock = stockSpecialPrice.Stock;
    userItem.SpecialPriceQty = stockSpecialPrice.Quantity;
    // validation
    if (userItem.SpecialPriceID !== null) {
      userItem.CustomValidations = {
        [App.Vee.Rule.MaxValue]: [
          userItem.SpecialPriceQty,
          StrdItemCatalog.SpecialPriceQty.Label
        ]
      };
    }
  },
  setItemsByStockSpecialPrice(userItems, stockSpecialPriceData) {
    const conditions = {
      DispatchID: stockSpecialPriceData.ItemID,
      SpecialPriceID: stockSpecialPriceData.SpecialPriceID
    };
    let userItem = App.Array.getItem2(userItems, conditions);

    if (userItem === null) {
      userItem = ModelBody.createItem();
      this.setItemsByStockSpecialPrice_setItem(
        userItem, stockSpecialPriceData
      );
      ModelBody.updateItem(userItem);
      userItems.push(userItem);

      return 1;
    }

    return 0;
  },
  setItemsByStockSpecialPrice_setItem(userItem, stockSpecialPrice) {
    const specialPriceDiscAmount = stockSpecialPrice.SpecialPriceID === null
      ? 0
      : App.Data.getDiscPercent_Value(
        stockSpecialPrice.SellPrice, stockSpecialPrice.DiscPercent
      );

    // user input
    userItem.DispatchID = stockSpecialPrice.ItemID;
    userItem.DispatchName = stockSpecialPrice.Name;
    userItem.SellPrice = stockSpecialPrice.SellPrice - specialPriceDiscAmount;
    // by system
    userItem.SKU = stockSpecialPrice.SKU;
    userItem.DispatchSellPrice = stockSpecialPrice.SellPrice;
    userItem.PackagingName = stockSpecialPrice.PackagingName;
    userItem.SpecialPriceID = stockSpecialPrice.SpecialPriceID;
    userItem.SpecialPriceName = stockSpecialPrice.SpecialPriceName;
    userItem.SpecialPriceDiscPercent = stockSpecialPrice.DiscPercent;
    userItem.Stock = stockSpecialPrice.Stock;
    userItem.SpecialPriceQty = stockSpecialPrice.Quantity;
    // validation
    if (userItem.SpecialPriceID !== null) {
      userItem.CustomValidations = {
        [App.Vee.Rule.MaxValue]: [
          userItem.SpecialPriceQty,
          StrdItemCatalog.SpecialPriceQty.Label
        ]
      };
    }
  },

  clearDetailsByInvoice(userData) {
    userData.TransactionID = null;
    userData.TransactionNumber = null;
    userData.TransactionDate = null;
  },

  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(StrdItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, StrdItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(StrdItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", userItem, StrdItemCatalog)
    );

    // Stock
    infoList.push(StrdItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", userItem, StrdItemCatalog) + " " +
      userItem.PackagingName
    );

    if (userItem.SpecialPriceID !== null) {
      // SpecialPriceQty
      infoList.push(StrdItemCatalog.SpecialPriceQty.Label + ": " +
        App.Value.getValue("SpecialPriceQty", userItem, StrdItemCatalog) +
        " " + userItem.PackagingName
      );
    }

    return infoList;
  }
}