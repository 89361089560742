<template>
  <div class="block">
    <ClientSearchList
      @init="handleClient_init"
      @submit="handleClient_submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="PayNumber"
    />

    <kst-output control-off status-off
      field="ClientID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="ClientName"
          :data="userData"
          :disabled="clientDisabled"
          :catalog="Catalog"
        />
      </template>
      <template #right v-if="!userData.IsVerified">
        <div class="control">
          <template v-if="userData.ClientID === null">
            <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
              <kst-button search-mode @click="handleSearch_client"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
              <kst-button clear-mode @click="handleClear_client"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>

    <Rec v-if="userData.ClientID !== null"
      :userData="userData"
    />

    <kst-input
      field="PaymentTypeName" :data="userData" :catalog="Catalog"
      :options="paymentOptions"
    />
    <kst-input field="PaymentValue" :data="userData" :catalog="Catalog"
      :disabled="userData.IsVerified"
    />
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />

    <kst-field :errors="invalidIsVerified">
      <template v-if="userData.IsVerified">
        <kst-input class="is-hidden"
          field="InvoiceTotal" :data="userData" :catalog="Catalog"
          @error="handleError_invoiceTotal"
        />
        <kst-button resetMode @click="handleReset"/>
      </template>
      <template v-else>
        <kst-button verifyMode @click="handleVerify"/>
      </template>
    </kst-field>
  </div>
</template>

<script>
import Catalog from "../RcpyCatalog.js";
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";
import Services from "../../../services/Api/RecPayServices.js";

import Rec from "./RcpyN_Details_Rec.vue";
import ClientSearchList from "../../Client/SearchList/CliScL.vue";

export default {
  components:{
    Rec,
    ClientSearchList
  },
  props: {
    userData: Object,
    paymentOptions: Object,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showClientFunc: null
  }),
  computed: {
    clientDisabled() {
      return this.userData.IsVerified ? true : this.userData.ClientID !== null;
    },
    invalidIsVerified() {
      return this.userData.IsVerified ? null : Info.Message.New_IsVerified;
    }
  },
  methods: {
    /*** by client modal ***/

    handleClient_init(show) {
      this.showClientFunc = show;
    },
    handleClient_submit(row) {
      Model.New.setDetailsByClient(this.userData, row);
      this.$emit(this.$kst.Enum.Event.Input);
    },

    /*** custom ***/

    handleSearch_client() {
      this.showClientFunc(Services.getRelatedClientList);
    },
    handleClear_client() {
      Model.New.clearDetailsByClient(this.userData);
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleError_invoiceTotal(errors) {
      this.userData.InvoiceTotalIsValid = errors.length === 0;
    },

    handleReset() {
      this.userData.IsVerified = false;
    },
    handleVerify() {
      this.$emit(this.$kst.Enum.Event.Submit);
    }
  }
}
</script>