<template>
  <kst-section :title="sectionTitle">
    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="buttonLabel"
        :iconLeft="buttonIcon"
        :module="Model.Price.Module"
        :moduleType="Model.Price.ModuleType.List"
      />
    </template>
    <kst-output field="Subscription" :data="details" :catalog="Catalog"/>
    <kst-output field="NextSubscription" :data="details" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog,
    Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Subscription.Title;
    },
    buttonLabel() {
      return Info.Section.Subscription.Help.Label
    },
    buttonIcon() {
      return Info.Section.Subscription.Help.Icon
    }
  }
}
</script>