<template>
  <kst-page-list
    :catalog="RinvSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../RinvModel.js";
import RinvSearchCatalog from "../RinvSearchCatalog.js";
import Services from "../../../services/Api/ReturnInvoiceServices.js";

import List from "./RinvL_List.vue";
import Search from "./RinvL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    Model: Model,
    RinvSearchCatalog: RinvSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      if (this.searchData.ClientID !== null) {
        this.searchData.ClientName = this.$kst.In.getString(
          data.Search.ClientName
        );
      }

      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, RinvSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        client: clientId,
        clientname: clientName,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields
      };
    }
  }
}
</script>