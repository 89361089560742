export default {
  Default: {
    PathSeparator: "/",
    NameSeparator: " "
  },

  // router
  getPath(module, moduleType) {
    return this.helper.getPath(module, moduleType, this.Default);
  },
  getName(module, moduleType) {
    return this.helper.getName(module, moduleType, this.Default);
  },
  getModule(module) {
    return this.helper.getModule(module);
  },

  // navbar
  getNavTitle(module) {
    return this.helper.getNavTitle(module);
  },
  getNavSubTitle(module, moduleType) {
    return this.helper.getNavSubTitle(module, moduleType, this.Default);
  },

  // browser
  getDocumentTitle(route) {
    return this.helper.getDocumentTitle(route);
  },

  helper: {
    getPath(module, moduleType, defaults) {
      if (moduleType) {
        if (moduleType.Path) {
          return defaults.PathSeparator + module.Path +
            defaults.PathSeparator + moduleType.Path;
        }
      }
      return defaults.PathSeparator + module.Path;
    },
    getName(module, moduleType, defaults) {
      if (moduleType) {
        return moduleType.FullName + defaults.NameSeparator + module.FullName;
      }
      return module.FullName;
    },
    getModule(module) {
      return module;
    },

    getNavTitle(module) {
      return module.FullName;
    },
    getNavSubTitle(module, moduleType, defaults) {
      if (moduleType) {
        return moduleType.FullName + defaults.NameSeparator + module.FullName;
      }
      return module.FullName;
    },

    getDocumentTitle(route) {
      return route.name;
    }
  }
}