<template>
  <div class="columns">
    <div class="column is-half">
      <template v-if="transSummaryList !== null">
        <TransSummary :transSummaryList="transSummaryList"/>
      </template>

      <template v-if="topBuyerList !== null">
        <TopBuyer
          :access="access"
          :dataList="topBuyerList"
          :searchData="searchData"
        />
      </template>

      <template v-if="bestSellingItemList !== null">
        <BestSellingItems
          :access="access"
          :dataList="bestSellingItemList"
          :searchData="searchData"
        />
      </template>
    </div>
    <div class="column is-half">
      <template v-if="dataList !== null">
        <Data :dataList="dataList"/>
      </template>

      <template v-if="deathStockList !== null">
        <DeathStock
          :access="access"
          :dataList="deathStockList"
          :searchData="searchData"
        />
      </template>

      <template v-if="deathStockList !== null">
        <StockWithoutInvoice
          :access="access"
          :dataList="stockWithoutInvoiceList"
          :searchData="searchData"
        />
      </template>

      <template v-if="lowStockList !== null">
        <LowStock
          :access="access"
          :dataList="lowStockList"
          :searchData="searchData"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Model from "../DashModel.js";

import BestSellingItems from "./DashR_Result_BestSellingItems.vue";
import Data from "./DashR_Result_Data.vue";
import DeathStock from "./DashR_Result_DeathStock.vue";
import LowStock from "./DashR_Result_LowStock.vue";
import StockWithoutInvoice from "./DashR_Result_StockWithoutInvoice.vue";
import TopBuyer from "./DashR_Result_TopBuyer.vue";
import TransSummary from "./DashR_Result_TransSummary.vue";

export default {
  components: {
    BestSellingItems,
    Data,
    DeathStock,
    LowStock,
    StockWithoutInvoice,
    TopBuyer,
    TransSummary
  },
  props: {
    access: Object,
    searchData: Object,
    transDetails: Object
  },
  data: () => ({
    bestSellingItemList: [],
    dataList: [],
    deathStockList: [],
    lowStockList: [],
    stockWithoutInvoiceList: [],
    topBuyerList: [],
    transSummaryList: []
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.resetData();

      if (this.access.Sect_BestSellingItems) {
        if (Object.prototype.hasOwnProperty.call(this.transDetails, "BestSellingItems")) {
          this.bestSellingItemList = this.transDetails.BestSellingItems.List;
        }
      }

      if (this.access.Sect_DeathStock) {
        if (Object.prototype.hasOwnProperty.call(this.transDetails, "DeathStock")) {
          this.deathStockList = this.transDetails.DeathStock.List;
        }
      }

      if (this.access.Sect_LowStock) {
        if (Object.prototype.hasOwnProperty.call(this.transDetails, "LowStock")) {
          this.lowStockList = this.transDetails.LowStock.List;
        }
      }

      if (this.access.Sect_StockWithoutInvoice) {
        if (Object.prototype.hasOwnProperty.call(
          this.transDetails, "StockWithoutInvoice"
        )) {
          this.stockWithoutInvoiceList =
            this.transDetails.StockWithoutInvoice.List;
        }
      }

      this.dataList = Model.Report.getDataList(
        this.transDetails, this.access, this.searchData
      );

      if (this.access.Sect_TopBuyer) {
        if (Object.prototype.hasOwnProperty.call(this.transDetails, "TopBuyer")) {
          this.topBuyerList = this.transDetails.TopBuyer.List;
        }
      }

      this.transSummaryList = Model.Report.getTransSummaryList(
        this.transDetails, this.access, this.searchData
      );
    },

    resetData() {
      this.bestSellingItemList = null;
      this.dataList = null;
      this.deathStockList = null;
      this.lowStockList = null;
      this.stockWithoutInvoiceList = null;
      this.topBuyerList = null;
      this.transSummaryList = null;
    }
  }
}
</script>