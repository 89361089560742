import App_Formatter from "./App_Formatter.js";

// vee-validate support functions

const SEPARATOR_FIELD = "_";

export default {
  Rule: {
    AlphaSpaces: "alpha_spaces",
    Between: "between",
    Email: "email",
    Is: "is",
    Integer: "integer",
    Length: "length",
    MaxLength: "max",
    MaxValue: "max_value",
    MinLength: "min",
    MinValue: "min_value",
    Numeric: "numeric",
    Required: "required",
    RequiredIf: "required_if",

    // custom
    MinDate: "min_date",
    MaxDate: "max_date",
    Unequal: "unequal",
    ValidPassword: "valid_password",
  },

  ValidRule: "#valid",

  /**
   * Generate field target name
   * i.e. Name (field) is Unique (rule)
   * will return filed targe = Name_Unique
   * @param {*} field 
   * @param {*} rule 
   **/
  getFieldTarget(field, rule) {
    return field + SEPARATOR_FIELD + rule;
  },

  getRowErrors(item, field, errors) {
    // init
    if (!Object.prototype.hasOwnProperty.call(item, "ErrorsColl")) {
      item.ErrorsColl = {};
    }

    // populate error in current field
    item.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== this.ValidRule) {
        item.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in item.ErrorsColl) {
      fieldErrors = item.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    return newErrors;
  },

  /**
   * Generate vid for ValidationProvider-tag
   * @param {String} field - field name (required)
   * @param {Number} index - index of field, useful for collection (optional)
   * @param {String} group - group of field, useful for multiple collections (optional)
   */
  getValidatorId(field, index, group) {
    let vid = field;

    if (index !== undefined) {
      vid += "-" + index;
    }

    if (group !== undefined) {
      vid += "-" + this.group;
    }

    return vid;
  },

  /*** custom message ***/

  Message: {
    getIs(fieldName, target, targetLabel) {
      const newValue = App_Formatter.getDecimal(target);
      const newTargetLabel = targetLabel ? " (" + targetLabel + ")" : "";
      return `Nilai ${fieldName} harus sama dengan ${newValue}${newTargetLabel}`;
    }
  }
}