<template>
  <kst-section collapsible collapsed info-mode
    :help-collapsed="sectionHelp"
    :help="sectionHelp"
    :title="sectionTitle"
  >
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="deliveryData"
      dateField="SalesOrderDate"
      numberField="SOID"
    />
  </kst-section>
</template>

<script>
import Catalog from "../DlvCatalog.js";
import Info from "../DlvInfo.js";
import Model from "../DlvModel.js";

export default {
  props: {
    deliveryData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    sectionHelp() {
      return Model.getInvoiceSectionHelp(this.deliveryData);
    },
    sectionTitle() {
      return Info.Section.Invoice.Title;
    }
  }
}
</script>