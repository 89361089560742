<template>
  <b-modal aria-modal has-modal-card trap-focus
    aria-role="dialog"
    class="kst-modal-view"
    :active="isActive"
    @close="handleCancel"
  >
    <div class="modal-card ks-max-width-100p mb-5" :style="'width: ' + width + 'px'">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button class="delete" @click="handleCancel"/>
      </header>

      <section class="modal-card-body">
        <slot />
      </section>

      <footer class="modal-card-foot is-justify-content-end">
        <div class="buttons">
          <kst-button confirm-mode @click="handleCancel"/>
        </div>
      </footer>
    </div>
  </b-modal>
</template>

<script>
/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 */
export default {
  props: {
    title: String,
    width: Number
  },
  data: () => ({
    isActive: false
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.init_Data();
      this.$emit(this.$kst.Enum.Event.Init, this.show, this.hide);
    },
    init_Data() {
      this.isActive = false;
    },

    handleCancel() {
      this.isActive = false;
    },

    show() {
      this.isActive = true;
    },
    hide() {
      this.isActive = false;
    }
  }
}
</script>