export default {
  createDetails() {
    return {
      // user input
      spv_passwd: ""
    };
  },

  populateVerifyData(userData) {
    return {
      spv_passwd: userData.spv_passwd
    };
  }
}