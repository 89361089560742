import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import StrdItemCatalog from "./StrdItemCatalog.js";

export default {
  setDetailsByHppCalculation(details) {
    details.HppTotal = 0;

    for (const item of details.Items) {
      details.HppTotal += item.HppTotal;
    }
  },
  getItemInfo(userItem) {
    let infoList = [];

    // deleted item
    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    else {
      // SKU
      infoList.push(StrdItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, StrdItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(StrdItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", userItem, StrdItemCatalog)
      );
    }

    return infoList;
  }
}