import AppCatalog from "../services/App/AppCatalog.js";

export default {
  data: () => ({
    currentSnackbar: null,
    hasRunAction: false
  }),
  methods: {
    notifyError_SelectOne() {
      this.openSnackbar({
        actionText: AppCatalog.Snackbar.ActionText,
        duration: AppCatalog.Snackbar.Duration,
        message: AppCatalog.Message.Error_SelectOne,
        queue: false,
        errorType: true
      });
    },

    notifyFormError(isClose, message) {
      this.openSnackbar({
        indefinite: isClose ? true : false,
        actionText: AppCatalog.Snackbar.ActionText,
        duration: AppCatalog.Snackbar.Duration,
        message: message ? message : AppCatalog.Message.ErrorOnForm,
        queue: false,
        errorType: true
      });
    },
    notifyFormSuccess(callback) {
      let options = {
        actionText: AppCatalog.Snackbar.ActionText,
        duration: AppCatalog.Snackbar.Duration,
        message: AppCatalog.Message.SuccessOnForm,
        queue: false,
        successType: true
      };

      if (callback) {
        options.onAction = callback;
      }

      this.openSnackbar(options);
    },

    /**
     * additional type option: ONLY select 1 (one)
     * {Boolean} errorType - use error style for type
     * {Boolean} infoType - use info style for type
     * {Boolean} successType - use success style for type
     * - will override user-defined type
     */
    openSnackbar(options) {
      if (options === undefined) {
        options = {};
      }

      if (!options.position) {
        options.position = this.$kst.Buefy.Position.Top;
      }

      // handle value: false
      if (options.queue === undefined) {
        options.queue = false;
      }

      if (Array.isArray(options.message)) {
        options.message = options.message[0];
      }

      if (options.onAction) {
        const callback = options.onAction;

        options.onAction = () => {
          this.hasRunAction = true;
          callback();
        };

        setTimeout(
          () => {
            if (!this.hasRunAction) {
              this.hasRunAction = true;
              callback();
            }
          },
          AppCatalog.Snackbar.Duration
        );
      }
      else {
        if (options.duration === undefined) {
          // set duration default when have no onAction
          options.duration = AppCatalog.Snackbar.Duration;
        }
      }

      if (options.errorType) {
        options.type = this.$kst.Buefy.Type.Danger + " kst-snackbar-form-error";
      }
      else if (options.infoType) {
        options.type = this.$kst.Buefy.Type.Info + " kst-snackbar-form-info";
      }
      else if (options.successType) {
        options.type = this.$kst.Buefy.Type.Success + " kst-snackbar-form-success";
      }

      this.hasRunAction = false;
      this.closeSnackbar();
      this.currentSnackbar = this.$buefy.snackbar.open(options);
    },
    closeSnackbar() {
      if (this.currentSnackbar) {
        this.currentSnackbar.close();
      }
    }
  }
}