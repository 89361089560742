import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.StockCard,
  ModuleType: {
    Export: AppModuleType.Export,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.StockCard_Act_Export,
    Act_Report: AppModuleAttr.StockCard_Act_Report,
    Glob_HPP: AppModuleAttr.All_Glob_HPP,
    // related
    Receive_Act_Details: AppModuleAttr.Receive_Act_Details,
    Invoice_Act_Details: AppModuleAttr.Invoice_Act_Details,
    StockOpname_Act_Details: AppModuleAttr.StockOpname_Act_Details,
    RtnInvoice_Act_Details: AppModuleAttr.RtnInvoice_Act_Details,
    HppAdj_Act_Details: AppModuleAttr.HppAdj_Act_Details,
    StockConv_Act_Details: AppModuleAttr.StockConv_Act_Details,
    StockReduction_Act_Details: AppModuleAttr.StockReduction_Act_Details,
    StockTrf_Act_Details: AppModuleAttr.StockTrf_Act_Details,
    RtnReceive_Act_Details: AppModuleAttr.RtnReceive_Act_Details
  },

  UrlParams: ["Date", "ItemID", "WarehouseID"],

  /*** method ***/

  getStockSummaryList(stockList) {
    let resultList = [{
      WarehouseName: App.Search.LabelAll + " " + AppModule.Warehouse.FullName,
      Quantity: 0,
      HppTotal: 0
    }];

    for (const stock of stockList) {
      resultList[0].Quantity += stock.Quantity;
      resultList[0].HppTotal += stock.HppTotal;
    }

    return resultList;
  },
  getTransactionGroupObj(transList) {
    let transGroupObj = {}, warehouseIdList = [];
    let warehouseId, transGroup;

    for (const trans of transList) {
      warehouseId = trans.WarehouseID;

      if (!warehouseIdList.includes(warehouseId)) {
        warehouseIdList.push(warehouseId);

        // new warehouse: create group
        transGroup = {
          WarehouseID: trans.WarehouseID,
          WarehouseName: trans.WarehouseName,
          TransactionList: []
        };
        transGroupObj[warehouseId] = transGroup;
      }
      else {
        transGroup = transGroupObj[warehouseId];
      }

      transGroup.TransactionList.push(trans);
    }

    return transGroupObj;
  },
  getTransactionActivityTypeName(trans) {
    return App.Data.getActivityTypeName(trans.ActivityTypeID);
  },
  getTransactionHasAccess(trans, access) {
    switch(trans.ActivityTypeID) {
      case App.Enum.ActivityType.Receive.ID:
        return access.Receive_Act_Details;
      case App.Enum.ActivityType.Invoice.ID:
        return access.Invoice_Act_Details;
      case App.Enum.ActivityType.StockOpname.ID:
        return access.StockOpname_Act_Details;
      case App.Enum.ActivityType.RtnInvoice.ID:
        return access.RtnInvoice_Act_Details;
      case App.Enum.ActivityType.HppAdj.ID:
        return access.HppAdj_Act_Details;
      case App.Enum.ActivityType.StockReduction.ID:
        return access.StockReduction_Act_Details;
      case App.Enum.ActivityType.StockTrf.ID:
        return access.StockTrf_Act_Details;
      case App.Enum.ActivityType.StockConv.ID:
        return access.StockConv_Act_Details;
      case App.Enum.ActivityType.RtnReceive.ID:
        return access.RtnReceive_Act_Details;
      default:
        return false;
    }
  },
  getTransactionModule(trans) {
    for (const activityTypeName in App.Enum.ActivityType) {
      if (trans.ActivityTypeID === App.Enum.ActivityType[activityTypeName].ID) {
        return App.Enum.ActivityType[activityTypeName].Module;
      }
    }

    return null;
  },
  getTransactionUrlParams(trans) {
    return App.Route.getParams({
      [AppCatalog.Field.ID.Param]: trans.TransactionID
    });
  },
  getTransactionSummaryList(transList) {
    const activityTypeEnum = App.Enum.ActivityType;
      
    let totalInvoiceChanges = { In: null, Out: 0 };
    let totalReceiveChanges = { In: 0, Out: null };
    let totalStockOpnameChanges = { In: 0, Out: 0 };
    let totalRtnInvoiceChanges = { In: 0, Out: null };
    let totalStockReductionChanges = { In: null, Out: 0 };
    let totalRtnReceiveChanges = { In: null, Out: 0 };
    
    for (const trans of transList) {
      if (trans.ActivityTypeID === activityTypeEnum.Invoice.ID) {
        totalInvoiceChanges.Out += trans.Changes;
      }
      else if (trans.ActivityTypeID === activityTypeEnum.Receive.ID) {
        totalReceiveChanges.In += trans.Changes;
      }
      else if (trans.ActivityTypeID === activityTypeEnum.StockOpname.ID) {
        if (trans.Changes < 0) {
          totalStockOpnameChanges.Out += trans.Changes;
        } else {
          totalStockOpnameChanges.In += trans.Changes;
        }
      }
      else if (trans.ActivityTypeID === activityTypeEnum.RtnInvoice.ID) {
        totalRtnInvoiceChanges.In += trans.Changes;
      }
      else if (trans.ActivityTypeID === activityTypeEnum.StockReduction.ID) {
        totalStockReductionChanges.Out += trans.Changes;
      }
      else if (trans.ActivityTypeID === activityTypeEnum.RtnReceive.ID) {
        totalRtnReceiveChanges.Out += trans.Changes;
      }
    }

    let resultList = [];
    resultList.push({
      ActivityTypeID: activityTypeEnum.Invoice.ID,
      ActivityTypeName: activityTypeEnum.Invoice.Module.FullName,
      ChangesIn: null,
      ChangesOut: Math.abs(totalInvoiceChanges.Out)
    });
    resultList.push({
      ActivityTypeID: activityTypeEnum.Receive.ID,
      ActivityTypeName: activityTypeEnum.Receive.Module.FullName,
      ChangesIn: totalReceiveChanges.In,
      ChangesOut: null
    });
    resultList.push({
      ActivityTypeID: activityTypeEnum.StockOpname.ID,
      ActivityTypeName: activityTypeEnum.StockOpname.Module.FullName,
      ChangesIn: totalStockOpnameChanges.In,
      ChangesOut: Math.abs(totalStockOpnameChanges.Out)
    });
    resultList.push({
      ActivityTypeID: activityTypeEnum.RtnInvoice,
      ActivityTypeName: activityTypeEnum.RtnInvoice.Module.FullName,
      ChangesIn: totalRtnInvoiceChanges.In,
      ChangesOut: null
    });
    resultList.push({
      ActivityTypeID: activityTypeEnum.StockReduction.ID,
      ActivityTypeName: activityTypeEnum.StockReduction.Module.FullName,
      ChangesIn: null,
      ChangesOut: Math.abs(totalStockReductionChanges.Out)
    });
    resultList.push({
      ActivityTypeID: activityTypeEnum.RtnReceive.ID,
      ActivityTypeName: activityTypeEnum.RtnReceive.Module.FullName,
      ChangesIn: null,
      ChangesOut: Math.abs(totalRtnReceiveChanges.Out)
    });

    return resultList;
  }
}