import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, invoiceData) {
    userData.ID = invoiceData.ID;
    userData.InvoiceDate = App.In.getDate(invoiceData.InvoiceDate);
    userData.Comment = App.In.getString(invoiceData.Comment);
    userData.CurrentDate = App.In.getDateToday();
    userData.WarehouseID = App.In.getInteger(invoiceData.WarehouseID);
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      InvoiceDate: App.Out.getDateString(userData.InvoiceDate),
      Comment: App.Out.getString(userData.Comment),
      CurrentDate: App.Out.getDateString(userData.CurrentDate),
      WarehouseID: App.Out.getInteger(userData.WarehouseID)
    };
  },
  
  validationInvoiceDate(data) {
    const result = {
      [App.Vee.Rule.MinDate]: {
        target: App.In.getDate(data.MinDate)
      }
    };
    
    if (data.MaxDate !== null) {
      result[App.Vee.Rule.MaxDate] = {
        target: App.In.getDate(data.MaxDate)
      };
    }

    return result;
  }
}