import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./RccdCatalog.js";
import RccdEnum from "./RccdEnum.js";
import RccdTransCatalog from "./RccdTransCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.RecCard,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.RecCard_Act_Export,
    Act_Print: AppModuleAttr.RecCard_Act_Print,
    Act_Report: AppModuleAttr.RecCard_Act_Report,
    // related
    Client_Act_Details: AppModuleAttr.Client_Act_Details,
    Invoice_Act_Details: AppModuleAttr.Invoice_Act_Details,
    RecPay_Act_Details: AppModuleAttr.RecPay_Act_Details,
    RtnInvoice_Act_Details: AppModuleAttr.RtnInvoice_Act_Details
  },

  TypeID: RccdEnum.TypeID,

  UrlParams: ["TransactionDate", "ClientID", "ClientName", "TypeID",
    "WarehouseID"
  ],

  /*** method ***/

  createTypeOptions(optionAllText) {
    return this.helper.createTypeOptions(optionAllText, RccdEnum.TypeID);
  },

  getResultInfo(row) {
    return this.helper.getResultInfo(row);
  },

  updateList(dataList) {
    this.helper.updateList(dataList, RccdEnum.TypeID);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createTypeOptions(optionAllText, typeEnum) {
      const rowId = "ID";
      const rowLabel = "Name";

      const dataList = [
        { [rowId]: typeEnum.Invoice.ID, [rowLabel]: typeEnum.Invoice.Label },
        { [rowId]: typeEnum.RecPay.ID, [rowLabel]: typeEnum.RecPay.Label },
        { [rowId]: typeEnum.RtnInvoice.ID, [rowLabel]: typeEnum.RtnInvoice.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select rows
      const selectRows = App.Search.createList(dataList, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectRows);
    },

    getResultInfo(row) {
      let infoList = [];

      // TypeID
      infoList.push(Catalog.TypeID.Label + ": " +
        App.Value.getValue("TypeID", row, Catalog)
      );

      // TransactionDate
      infoList.push(Catalog.TransactionDate.Label + ": " +
        App.Value.getValue("TransactionDate", row, Catalog)
      );

      // InvoiceDate
      infoList.push(RccdTransCatalog.InvoiceDate.Label + ": " +
        App.Value.getValue("InvoiceDate", row, RccdTransCatalog)
      );

      return infoList;
    },

    updateList(dataList, typeEnum) {
      for (const row of dataList) {
        switch (row.TypeID) {
          case typeEnum.Invoice.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.Invoice);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.Invoice.Module.FullName;
            break;
          case typeEnum.RecPay.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.RecPay);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.RecPay.Module.FullName;
            break;
          case typeEnum.RecRepair.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.RecRepair);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.RecRepair.Module.FullName;
            break;
          case typeEnum.RtnInvoice.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.RtnInvoice);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.RtnInvoice.Module.FullName;
            break;
          default:
            row.HasAccess = false;
        }
      }
    },
    updateList_createActions(row, typeEnumObj) {
      let resultList = [];
      const module = typeEnumObj.Module;

      if (App.Access.hasModuleAttr(typeEnumObj.ModuleAttr)) {
        resultList.push(
          App.Access.createAction(
            module,
            AppModuleType.Details,
            App.Route.getParams({
              [AppCatalog.Field.ID.Param]: row.TransactionID
            })
          )
        );
      }

      if (module.ID !== AppModule.Invoice.ID) {
        if (App.Access.hasModuleAttr(AppModuleAttr.Invoice_Act_Details)) {
          resultList.push(
            App.Access.createAction(
              AppModule.Invoice,
              AppModuleType.Details,
              App.Route.getParams({
                [AppCatalog.Field.ID.Param]: row.InvoiceID
              })
            )
          );
        }
      }

      if (row.ClientID !== null) {
        if (App.Access.hasModuleAttr(AppModuleAttr.Client_Act_Details)) {
          resultList.push(
            App.Access.createAction(
              AppModule.Client,
              AppModuleType.Details,
              App.Route.getParams({
                [AppCatalog.Field.ID.Param]: row.ClientID
              })
            )
          );
        }
      }

      return resultList;
    }
  }
}