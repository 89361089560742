import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./CompanyCatalog.js";

export default {
  // required in model
  Module: AppModule.CompanyProfile,
  ModuleType: {
    Details: AppModuleType.Details
  },

  /*** related ***/

  /*** property ***/

  /*** method ***/

  getSectionHelp_RelatedDetails(data) {
    return this.helper.getSectionHelp_RelatedDetails(data);
  },

  helper: {
    getSectionHelp_RelatedDetails(data) {
      return Catalog.name.Label + ": " +
        App.Value.getValue("name", data, Catalog) + ", " +
        Catalog.address.Label + ": " +
        App.Value.getValue("address", data, Catalog);
    }
  }
}