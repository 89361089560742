<template>
  <kst-table paginated view-mode
    :action="Model.List.hasAccessAction(access)"
    :data="dataList"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="Catalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="isAllWarehouse">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="WarehouseID"
        :label="Catalog.WarehouseID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Client"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="PONumber"
      :label="Catalog.PONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-status :value="row[column.field]" :statusEnum="Model.Status"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="DueDate"
      :label="Catalog.DueDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="RecTotal"
      :label="Catalog.RecTotal.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip details-mode hover-only>
        <kst-button action-mode
          :class="Info.Button.Transaction.Class"
          :label="Info.Button.Transaction.Label"
          :module="Info.Button.Transaction.Module"
          :moduleType="Info.Button.Transaction.ModuleType"
          :urlParams="row.UrlParams"
        />
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../../Rec/RecCatalog.js";
import Info from "../../Rec/RecInfo.js";
import Model from "../../Rec/RecModel.js";

export default {
  props: {
    access: Object,
    dataList: Array,
    isAllWarehouse: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      Model.updateResult(this.dataList);
    }
  }
}
</script>