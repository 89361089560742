<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItemsRaw="userItemsRaw"
      :warehouseOptions="warehouseOptions"
    />
    <ItemsRaw
      :userData="userData"
      :userItemsRaw="userItemsRaw"
    />
    <ItemsFinish
      :userData="userData"
      :userItemsFinish="userItemsFinish"
      :userItemsRaw="userItemsRaw"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../StcvModel.js";
import Services from "../../../services/Api/StockConvServices.js";

import Details from "./StcvN_Details.vue";
import ItemsFinish from "./StcvN_ItemsFinish.vue";
import ItemsRaw from "./StcvN_ItemsRaw.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    ItemsFinish,
    ItemsRaw
  },
  data: () => ({
    userData: {},
    userItemsFinish: [],
    userItemsRaw: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItemsRaw,
        this.userItemsFinish
      );
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItemsFinish = [];
      this.userItemsRaw = [];
      this.warehouseOptions = {};
    }
  }
}
</script>