import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./DlvCatalog.js";
import DlvItemCatalog from "./DlvItemCatalog.js";

export default {
  // required in model
  Module: AppModule.Delivery,
  ModuleType: {
    Details: AppModuleType.Details,
    EditStatus: AppModuleType.EditStatus,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintList: AppModuleType.PrintList,
    PrintSJ: AppModuleType.PrintSJ
  },

  /*** related ***/

  DeliveryItem: {
    Module: AppModule.Item
  },

  // other
  Invoice: {
    Module: AppModule.Invoice
  },

  InvoiceItem: {
    Label: AppModule.Item.FullName + " " + AppModule.Invoice.FullName,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Delivery_Act_Details,
    Act_EditStatus: AppModuleAttr.Delivery_Act_EditStatus,
    Act_List: AppModuleAttr.Delivery_Act_List,
    Act_New: AppModuleAttr.Delivery_Act_New,
    Act_PrintList: AppModuleAttr.Delivery_Act_PrintList,
    Act_PrintSJ: AppModuleAttr.Delivery_Act_PrintSJ
  },

  Actions: {
    Details: [
      AppModuleType.EditStatus,
      AppModuleType.PrintSJ
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.EditStatus,
      AppModuleType.PrintSJ
    ]
  },

  Search: {
    ListFields: ["LogisticsNumber", "VehicleIDNumber", "Destination"],
    ListParams: ["Search", "Client", "ClientID", "DepartureDate", "Status",
      "WarehouseID"]
  },

  Status: {
    Draft: {
      ID: 1,
      Class: App.Enum.Status.Draft.Class,
      Label: App.Enum.Status.Draft.Label,
    },
    InDelivery: {
      ID: 2,
      Class: App.Enum.Status.InDelivery.Class,
      Label: App.Enum.Status.InDelivery.Label,
    },
    Delivered: {
      ID: 3,
      Class: App.Enum.Status.Delivered.Class,
      Label: App.Enum.Status.Delivered.Label,
    }
  },

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      // user input
      SOID: null,
      LogisticsNumber: null,
      IsAutoNumber: true,
      DepartureDate: App.In.getDateToday(),
      ArrivalDate: App.In.getDateToday(),
      CompletedDate: App.In.getDateToday(),
      VehicleIDNumber: null,
      Destination: "",
      ShipmentFee: null,
      Comment: "",
      Status: null,
      // by system
      SONumber: "",
      SalesOrderDate: null,
      WarehouseID: null,
      WarehouseName: "",
      ClientID: null,
      ClientAlias: "",
      Client: "",
      // computed
      TotalWeight: 0
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      Quantity: "",
      // by system
      InvoiceItemQty: 0,
      SKU: "",
      Name: "",
      PackagingName: "",
      PackagingValue: 0,
      Weight: 0,
      DispatchIsActive: true,
      // computed
      Packaging: "",
      TotalWeight: 0,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Draft.ID, [rowLabel]: statusEnum.Draft.Label },
      { [rowId]: statusEnum.InDelivery.ID, [rowLabel]: statusEnum.InDelivery.Label },
      { [rowId]: statusEnum.Delivered.ID, [rowLabel]: statusEnum.Delivered.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  setDetailsByInvoice(userData, invoiceData, statusEnum) {
    // user input
    userData.SOID = invoiceData.ID;
    userData.Destination = App.In.getString(invoiceData.ClientAddress);
    userData.Status = statusEnum.Draft.ID;
    // by system
    userData.SONumber = invoiceData.SONumber;
    userData.SalesOrderDate = App.In.getDate(invoiceData.InvoiceDate);
    userData.WarehouseID = invoiceData.WarehouseID;
    userData.WarehouseName = invoiceData.WarehouseName;
    userData.ClientID = invoiceData.ClientID;
    userData.ClientAlias = invoiceData.ClientAlias;
    userData.Client = invoiceData.Client;

    if (invoiceData.DeliveryDate !== null) {
      userData.DepartureDate = App.In.getDate(invoiceData.DeliveryDate);
    }
  },
  setDetailsByStatus(details, statusEnum) {
    details.StatusName = App.Data.getStatusLabel(details.Status, statusEnum);
  },

  setItemsByInvoice(userItems, invoiceData) {
    App.Array.truncate(userItems);

    for (const invoiceItem of invoiceData.Items) {
      let userItem = this.createItem();
      this.setItemByInvoiceItem(userItem, invoiceItem);
      this.updateItem(userItem);
      userItems.push(userItem);
    }
  },
  setItemByInvoiceItem(userItem, invoiceItemData) {
    // user input
    userItem.DispatchID = invoiceItemData.DispatchID;
    userItem.Quantity = App.In.getInteger(invoiceItemData.QtyAvailable);
    // by system
    userItem.InvoiceItemQty = invoiceItemData.QtyAvailable;
    userItem.SKU = invoiceItemData.DispatchIsActive ? invoiceItemData.SKU : "";
    userItem.Name = invoiceItemData.Name;
    userItem.PackagingName = invoiceItemData.PackagingName;
    userItem.PackagingValue = invoiceItemData.PackagingValue;
    userItem.Weight = invoiceItemData.DispatchIsActive
      ? invoiceItemData.DispatchWeight : 0;
    userItem.DispatchIsActive = App.In.getBoolean(
      invoiceItemData.DispatchIsActive);
  },

  getItemInfo(userItem) {
    let infoList = [];

    if (!userItem.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(DlvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, DlvItemCatalog)
      );
    }

    return infoList;
  },

  getDeliverySectionHelp(deliveryData) {
    return Catalog.LogisticsNumber.Label + ": " +
      App.Value.getValue("LogisticsNumber", deliveryData, Catalog) + ", " +
      Catalog.DepartureDate.Label + ": " +
      App.Value.getValue("DepartureDate", deliveryData, Catalog);
  },
  getInvoiceSectionHelp(userData) {
    return userData.SOID === null
      ? AppModule.Invoice.FullName + " " + AppCatalog.Message.NotDefinedYet
      : Catalog.SOID.Label + ": " + userData.SONumber + ", " +
        Catalog.SalesOrderDate.Label + ": " +
        App.Value.getValue("SalesOrderDate", userData, Catalog);
  },
  getInvoiceItemsForSelection(invoiceItems, userItems) {
    let resultItems = [];
    let item, conditions;

    for (const invoiceItem of invoiceItems) {
      conditions = {
        DispatchID: invoiceItem.DispatchID,
        PackagingName: invoiceItem.PackagingName
      }
      item = App.Array.getItem2(userItems, conditions);

      if (!item) {
        resultItems.push(invoiceItem);
      }
    }

    return resultItems;
  },

  updateDetails(userData, userItems) {
    userData.TotalWeight = 0;

    for (const userItem of userItems) {
      userData.TotalWeight += userItem.TotalWeight;
    }
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.TotalWeight = App.Data.getTotalWeight(
      userItem.Quantity, userItem.Weight
    );
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData) {
    const shipmentFee = App.Out.getInteger(userData.ShipmentFee);

    return {
      SOID: userData.SOID,
      WarehouseID: userData.WarehouseID,
      LogisticsNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.LogisticsNumber),
      DepartureDate: App.Out.getDateString(userData.DepartureDate),
      VehicleIDNumber: App.Out.getString(userData.VehicleIDNumber),
      Destination: App.Out.getString(userData.Destination),
      ShipmentFee: shipmentFee === null ? 0 : App.Out.getInteger(shipmentFee),
      Comment: App.Out.getString(userData.Comment),
      Status: userData.Status
    };
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        DispatchID: userItem.DispatchID,
        Name: userItem.Name,
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        Quantity: App.Out.getInteger(userItem.Quantity),
        Weight: userItem.Weight
      });
    }

    return resultItems;
  },
}