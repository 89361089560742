<template>
  <kst-page-export
    :catalog="SlrcSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check 
      v-model="userData.WithInvoice"
      :label="Info.Export.List.Excel.Option.Invoice"
    />
  </kst-page-export>
</template>

<script>
import Info from "../SlrcInfo.js";
import Model from "../SlrcModel.js";
import SlrcSearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/SalesRecapServices.js";

import Export from "./SlrcExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    Model: Model,
    SlrcSearchCatalog: SlrcSearchCatalog,
    access: {},
    searchData: {
      Search: "",
      CashierID: null, 
      Date: null,
      WarehouseID: null
    },
    userData: {
      WithList: true,
      WithInvoice: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithList = true;
      this.userData.WithInvoice = true;
    },

    getListOptions() {
      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        cashier: this.$kst.Search.getValue(this.searchData.CashierID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields,
        withinvoice: this.$kst.Out.getBoolean(this.userData.WithInvoice)
      };
    }
  }
}
</script>