<template>
  <div class="block">
    <kst-output field="name" :data="details" :catalog="Catalog"/>
    <kst-output field="address" :data="details" :catalog="Catalog"/>
    <kst-output field="phone" :data="details" :catalog="Catalog"/>
    <kst-output field="email" :data="details" :catalog="Catalog"/>
    <kst-output field="owner" :data="details" :catalog="Catalog"/>
    <kst-output field="ImageSrc" :data="details" :catalog="Catalog">
      <template slot="tooltips">
        <kst-tooltip class="ml-2" info-mode position="is-bottom">
          <template #content>
            <kst-list :items="imageFieldTooltipList"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>

      <kst-image read-only thumbnail :src="details.ImageSrc"/>
    </kst-output>
  </div>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Model from "../CpModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog
  }),
  computed: {
    imageFieldTooltipList() {
      return Model.Details.getImageFieldTooltipList();
    }
  }
}
</script>