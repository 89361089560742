<template>
  <kst-section :title="sectionTitle">
    <kst-input field="ProfitLossRptTypeID" :data="userData" :catalog="Catalog"
      :options="profitLossRptTypeOptions"
    />
    <kst-input field="ReceiveVendorSourceID" :data="userData" :catalog="Catalog"
      :options="receiveVendorSourceOptions"
    />
    <kst-input field="InvoiceClientSourceID" :data="userData" :catalog="Catalog"
      :options="invoiceClientSourceOptions"
    />
    <kst-input field="InvoicePrintNote" :data="userData" :catalog="Catalog"/>
  </kst-section>
</template>

<script>
import Catalog from "../CpCatalog.js";
import Info from "../CpInfo.js";
import Model from "../CpModel.js";

export default {
  props: {
    userData: Object,
    invoiceClientSourceOptions: Object,
    profitLossRptTypeOptions: Object,
    receiveVendorSourceOptions: Object
  },
  data: () => ({
    Catalog,
    Model
  }),
  computed: {
    sectionTitle() {
      return Info.Section.Setting.Title;
    }
  }
}
</script>