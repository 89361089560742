import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import DbpyEnum from "./DbpyEnum.js";

export default {
  // required in model
  Module: AppModule.DebtPay,
  ModuleType: {
    Details: AppModuleType.Details,
    EditStatus: AppModuleType.EditStatus,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  DebtPayReceive: {
    Module: AppModule.Receive
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.DebtPay_Act_Details,
    Act_EditStatus: AppModuleAttr.DebtPay_Act_EditStatus,
    Act_ExportDetails: AppModuleAttr.DebtPay_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.DebtPay_Act_ExportList,
    Act_List: AppModuleAttr.DebtPay_Act_List,
    Act_New: AppModuleAttr.DebtPay_Act_New,
    Act_PrintDetails: AppModuleAttr.DebtPay_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.DebtPay_Act_PrintList
  },

  Actions: {
    RelatedList: [
      AppModuleType.Details,
      AppModuleType.EditStatus,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  StatusID: DbpyEnum.StatusID,

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      PayNumber: "",
      IsAutoNumber: true,
      VendorID: null,
      VendorName: "",
      PaymentTypeName: null,
      PaymentValue: "",
      Comment: "",
      StatusID: null,
      VoidComment: "",
      // by system
      ID: null,
      WarehouseName: null,
      VendorAlias: "",
      Date: App.In.getDateToday(),
      IsVerified: false,
      SelectedReceiveList: [],
      // validation
      ReceiveTotal: 0,
      ReceiveTotalIsValid: false
    };
  },
  createReceive() {
    return {
      ReceiveID: null,
      PaymentValue: "",
      // by system
      ReceiveNumber: null,
      ReceiveDate: null,
      ReceiveBalance: 0,
      ReceiveDueDate: null,
      VendorID: null,
      VendorName: null,
      WarehouseID: null,
      WarehouseName: null,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Active.ID, [rowLabel]: statusEnum.Active.Label },
      { [rowId]: statusEnum.Void.ID, [rowLabel]: statusEnum.Void.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  setDetailsByStatus(details, statusEnum) {
    details.StatusName = App.Data.getStatusLabel(details.StatusID, statusEnum);
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID === App.Search.OptionSome
        ? null : App.Out.getInteger(userData.WarehouseID),
      PayNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.PayNumber),
      Date: App.Out.getDateString(userData.Date),
      VendorID: userData.VendorID,
      VendorName: App.Out.getString(userData.VendorName),
      PaymentTypeName: App.Out.getString(userData.PaymentTypeName),
      PaymentValue: App.Out.getInteger(userData.PaymentValue),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateReceiveList(userReceiveList) {
    let resultItems = [];

    let paymentValue;
    for (const userReceive of userReceiveList) {
      paymentValue = App.Out.getInteger(userReceive.PaymentValue);

      if (paymentValue !== null) {
        resultItems.push({
          WarehouseID: userReceive.WarehouseID,
          ReceiveID: userReceive.ReceiveID,
          ReceiveNumber: userReceive.ReceiveNumber,
          ReceiveDate: userReceive.ReceiveDate,
          VendorID: userReceive.VendorID,
          VendorName: userReceive.VendorName,
          PaymentValue: paymentValue
        });
      }
    }

    return resultItems;
  }
}