<template>
  <div class="block">
    <kst-input load-on-focus field="Password" :data="userData" :catalog="Catalog"
      :customValidations="passwordValidations"
    />
  </div>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Model from "../UserModel.js";

export default {
  props: {
    details: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    passwordValidations() {
      return Model.validationPassword();
    },
  },
}
</script>