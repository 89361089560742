<template>
  <div>
    <div class="is-size-3 has-text-centered">{{ companyName }}</div>
    <div class="has-text-centered">{{ companyAddress }}</div>
    <div class="has-text-centered">Telp. {{ companyPhone }}</div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    customData: {
      type: Object,
      default: null
    }
  },
  computed: {
    companyAddress() {
      if (this.customData === null) {
        return this.data.address;
      }
      else {
        return this.customData.CompanyAddress === null
          ? this.data.address : this.customData.CompanyAddress;
      }
    },
    companyName() {
      if (this.customData === null) {
        return this.data.name;
      }
      else {
        return this.customData.CompanyName === null
          ? this.data.name : this.customData.CompanyName;
      }
    },
    companyPhone() {
      if (this.customData === null) {
        return this.data.phone;
      }
      else {
        return this.customData.CompanyPhone === null
          ? this.data.phone : this.customData.CompanyPhone;
      }
    }
  }
}
</script>