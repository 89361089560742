<template>
  <kst-table checkable input-mode paginated
    :checkedRows.sync="userData.SelectedInvoiceList"
    :data="userInvoiceList"
    :headerCheckable="false"
    @check="handleCheck_invoice"
  >
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="RcpyInvCatalog.InvoiceDate.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="InvoiceNumber"
      :label="RcpyInvCatalog.InvoiceNumber.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientName"
      :label="RcpyInvCatalog.ClientName.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="WarehouseID"
      :label="RcpyInvCatalog.WarehouseID.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="InvoiceStatus"
      :label="RcpyInvCatalog.InvoiceStatus.Label"
    >
      <div class="k-control-py">
        <kst-status :value="row[column.field]" :statusEnum="Model.InvoiceStatus"/>
      </div>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="InvoiceBalance"
      :label="RcpyInvCatalog.InvoiceBalance.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDueDate"
      :label="RcpyInvCatalog.InvoiceDueDate.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
      />
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row, index }"
      cell-class="ks-col-price"
      field="PaymentValue"
      :label="RcpyInvCatalog.PaymentValue.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        :index="index" @input="handlePaymentValue(row)" @error="handleRowErrors"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import Model from "../RcpyModel.js";
import RcpyInvCatalog from "../RcpyInvCatalog.js";

export default {
  props: {
    userData: Object,
    userInvoiceList: Array
  },
  data: () => ({
    RcpyInvCatalog: RcpyInvCatalog,
    Model: Model
  }),
  methods: {
    handleCheck_invoice(checkedList, row) {
      Model.New.setInvoiceListByCheck(this.userData, this.userInvoiceList,
        checkedList, row
      );
    },

    handlePaymentValue(row) {
      Model.New.setInvoiceByPaymentValue(this.userData, this.userInvoiceList,
        row
      );
    },

    handleRowErrors(errors, field, index) {
      Model.New.setInvoiceErrors(this.userInvoiceList[index], field, errors);
    }
  }
}
</script>