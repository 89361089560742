<template>
  <kst-section :title="Info.Section.VendorItem.Title">
    <ItemSearchListMultiple
      @init="handleItem_init"
      @submit="handleItem_submit"
    />

    <Input 
      :details="details"
      :userVendorItemList="userVendorItemList"
      @init="handleInput_init"
      @submit="handleInput_submit"
    />

    <template #top-right>
      <kst-button action-mode
        class="is-primary"
        :label="Info.Button.VendorItem.Label"
        @click="handleAdd_vendorItem"
      />
    </template>

    <VendorItemRelatedList 
      :dataList="vendorItemList"
      @init="handleVendorItem_init"
      @delete="handleVendorItem_delete"
    />
  </kst-section>
</template>

<script>
import Info from "../VenInfo.js";
import Model from "../VenModel.js";
import Services from "../../../services/Api/VendorService.js";

import ItemSearchListMultiple from "../../Item/SearchListMultiple/ItemScLMultiple.vue";
import VendorItemRelatedList from "../../VendorItem/RelatedList/VeniRL.vue";
import Input from "./VenD_VendorItemList_Input.vue";

export default {
  components: {
    ItemSearchListMultiple,
    Input,
    VendorItemRelatedList
  },
  props: {
    details: Object,
    vendorItemList: Array
  },
  data: () => ({
    Info: Info,
    userVendorItemList: [],
    // func
    showItemFunc: null,
    showInputFunc: null
  }),
  created() {
    this.init();
  },
  methods: {
    /*** by component ***/

    init() {
      this.userVendorItemList = [];
    },

    /*** by input modal***/

    handleInput_init(show) {
      this.showInputFunc = show;
    },
    handleInput_submit(data){
      this.$emit(this.$kst.Enum.Event.Submit, data);
    },

    /*** by item modal ***/

    handleItem_init(show) {
      this.showItemFunc = show;
    },
    handleItem_submit(itemList, itemPackagingData) {
      Model.Details.setVendorItemsByItem(this.userVendorItemList, itemList, 
        itemPackagingData.ItemPackagingList, this.details
      );

      this.showInputFunc();
    },
    
    /*** by Vendor Item ***/

    handleVendorItem_init(setService){
      setService(Services.deleteVendorItem);
    },
    handleVendorItem_delete(data){
      if (Object.prototype.hasOwnProperty.call(data, "VendorItemList")) {
        Model.Details.setVendorItemListByData(this.vendorItemList,
          data.VendorItemList
        );
      }
    },

    /*** custom ***/

    handleAdd_vendorItem() {
      this.showItemFunc(Services.getRelatedItemList, 
        Services.getRelatedItemPackagingList
      );
    }
  }
}
</script>