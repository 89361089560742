<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StcvCatalog.js";
import Info from "../StcvInfo.js";
import Model from "../StcvModel.js";
import StcvItemCatalog from "../StcvItemCatalog";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ConvNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.TotalRaw);
      this.addColumnHeader(Catalog.TotalFinish);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(StcvItemCatalog.ItemID.Label);
        this.addColumnHeader(StcvItemCatalog.ItemID);
        this.addColumnHeader(StcvItemCatalog.Packaging);
        this.addColumnHeader(StcvItemCatalog.Qty);
        this.addColumnHeader(StcvItemCatalog.TotalQty);
        this.addColumnHeader(StcvItemCatalog.SellPrice);
        this.addColumnHeader(StcvItemCatalog.SubTotal);
        if (this.access.Glob_HPP) {
          this.addColumnHeader(StcvItemCatalog.HppTotal);
        }
        this.addColumnHeader(StcvItemCatalog.IsRaw);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("ConvNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("TotalRaw", row, Catalog);
        this.addCellValue("TotalFinish", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("ItemID", row, StcvItemCatalog);
          this.addCellRelatedValue("ItemID", row, StcvItemCatalog);
          this.addCellValue("Packaging", row, StcvItemCatalog);
          this.addCellValue("Qty", row, StcvItemCatalog);
          this.addCellValue("TotalQty", row, StcvItemCatalog);
          this.addCellValue("SellPrice", row, StcvItemCatalog);
          this.addCellValue("SubTotal", row, StcvItemCatalog);
          if (this.access.Glob_HPP) {
            this.addCellValue("HppTotal", row, StcvItemCatalog);
          }
          this.addCellStatus(row.IsRaw, Model.IsRaw);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>