import AppCatalog from "../../services/App/AppCatalog.js";
import Model from "./ClientModel.js";

export default {
  Section: {
    EnterPin: {
      Title: AppCatalog.Info.Login
    },
    RelatedDetails: {
      Title: Model.Module.FullName
    }
  }
}