<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Sales :invoiceData="invoiceData" v-if="isSalesVisible"/>
    <Details :invoiceData="invoiceData"/>
    <Items :invoiceData="invoiceData" :items="invoiceData.Items"/>
    <Total :invoiceData="invoiceData"/>

    <template v-if="isDeliveryVisible">
      <Delivery :invoiceData="invoiceData"/>
    </template>
  </kst-section>
</template>

<script>
import Info from "../InvInfo.js";
import Model from "../InvModel.js";


import Sales from "./InvED_Invoice_Sales.vue";
import Details from "./InvED_Invoice_Details.vue";
import Items from "./InvED_Invoice_Items.vue";
import Total from "./InvED_Invoice_Total.vue";
import Delivery from "./InvED_Invoice_Delivery.vue";

export default {
  components: {
    Sales,
    Details,
    Items,
    Total,
    Delivery
  },
  props: {
    invoiceData: Object
  },
  computed: {
    sectionHelp() {
      return Model.getInvoiceSectionHelp(this.invoiceData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    },
    isDeliveryVisible() {
      return this.$kst.Session.companyHasAccess(Model.Delivery.Module);
    },
    isSalesVisible() {
      return this.$kst.Session.companyHasAccess(Model.Sales.Module);
    }
  }
}
</script>