<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Details :debtPayData="debtPayData"/>
    <ReceiveList :debtPayData="debtPayData"/>
  </kst-section>
</template>

<script>
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";

import Details from "./DbpyES_DebtPay_Details.vue";
import ReceiveList from "./DbpyES_DebtPay_ReceiveList.vue";

export default {
  components: {
    Details,
    ReceiveList
  },
  props: {
    debtPayData: Object
  },
  computed: {
    sectionHelp() {
      return Model.EditStatus.getDebtPaySectionHelp(this.debtPayData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>