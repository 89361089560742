import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import StavL from "./List/StavL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.StockAvailability, AppModuleType.List)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.StockAvailability, AppModuleType.List),
        name: RouterModel.getName(AppModule.StockAvailability, AppModuleType.List),
        component: StavL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.StockAvailability, AppModuleType.List),
          module: RouterModel.getModule(AppModule.StockAvailability),
          moduleattr: AppModuleAttr.StockAvailability_Act_List,
          moduletype: AppModuleType.List
        }
      }
    ];
  }
}