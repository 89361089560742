<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Ref :userData="userData"/>
    <Details :userData="userData" :typeOptions="typeOptions"/>
    <PackagingList
      :userData="userData"
      :userPackagingList="userPackagingList"
    />
    <Variant :userVariantList="userVariantList"/>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import Details from "./ItemE_Details.vue";
import PackagingList from "./ItemE_PackagingList.vue";
import Ref from "./ItemE_Ref.vue";
import Variant from "./ItemE_Variant.vue";

export default {
  components: {
    Details,
    PackagingList,
    Ref,
    Variant
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userPackagingList: [],
    userVariantList: [],
    typeOptions: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.typeOptions = Model.createTypeOptions(data.TypeList);

      Model.Edit.setDetailsByData(
        this.userData,
        this.userPackagingList,
        this.userVariantList, 
        data.Details
      );
    },

    handleSubmit(saveData) {
      const data = Model.populateData(
        this.userData,
        this.userPackagingList,
        this.userVariantList,
        null
      );
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userPackagingList = [];
      this.userVariantList = [];
    }
  }
}
</script>