import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import InvEnum from "../Invoice/InvEnum.js";
import ModelBody from "./CliModel_Body.js";
import ModelEdit from "./CliModel_Edit.js";
import ModelSelfService from "./CliModel_SelfService.js";

export default {
  // required in model
  Module: AppModule.Client,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    PrintList: AppModuleType.PrintList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    SearchList: AppModuleType.SearchList,
    // self service
    Activate: AppModuleType.Activate,
    Deactivate: AppModuleType.Deactivate,
    EditPin: AppModuleType.EditPin
  },

  /*** related ***/

  ClientAddress: ModelBody.ClientAddress,
  SalesSelfService: ModelBody.SalesSelfService,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createAddress() {
    return ModelBody.createAddress();
  },

  setAddressErrors(address, field, errors) {
    address.Errors = App.Vee.getRowErrors(address, field, errors);
  },

  populateData(userData, userAddresses) {
    let result = ModelBody.populateDetails(userData);
    result.AddressList = ModelBody.populateAddress(userAddresses);
    return result;
  },

  /*** method - edit ***/

  Edit: {
    setDetailsByData(userData, clientData) {
      ModelEdit.setDetailsByData(userData, clientData);
    },
    setAddressesByData(userAddresses, addressList) {
      ModelEdit.setAddressesByData(userAddresses, addressList);
    }  
  },

  /*** method - self service ***/

  SelfService: {
    updateData(data) {
      if (Object.prototype.hasOwnProperty.call(data, "SelfServiceID")) {
        ModelSelfService.updateData(data, ModelBody.SalesSelfService);
      }
    },

    populateData(userData) {
      return ModelSelfService.populateData(userData);
    }
  },

  /*** external ***/

  InvoiceStatus: InvEnum.Status,
}