<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../PurCatalog.js";
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import PurItemCatalog from "../PurItemCatalog.js";
import ReceiveCatalog from "../../Receive/RcvCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details);

      if (this.userData.WithReceiveList) {
        if (data.ReceiveList) {
          this.createSheet();
          this.addReceiveList(data.ReceiveList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_Details(details);
      this.addDetails_Item(details.Items);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorAlias);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.VendorAddress);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.Status);
      this.addColumnHeader(Catalog.DeliveryAddress);
      this.addColumnHeader(Catalog.DeliveryDate);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(Catalog.Total);
      }
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("PONumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("VendorID", details, Catalog);
      this.addCellValue("VendorAlias", details, Catalog);
      this.addCellRelatedValue("VendorID", details, Catalog);
      this.addCellValue("VendorAddress", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellStatus(details.Status, Model.Status);
      this.addCellValue("DeliveryAddress", details, Catalog);
      this.addCellValue("DeliveryDate", details, Catalog);
      if (this.access.Fld_Amount) {
        this.addCellValue("Total", details, Catalog);
      }
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },
    addDetails_Item(items) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.ReceiveItem);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(PurItemCatalog.DispatchID.Label);
      this.addColumnHeader(PurItemCatalog.DispatchID);
      this.addColumnHeader(PurItemCatalog.SKU);
      this.addColumnHeader(PurItemCatalog.QtyReceive);
      this.addColumnHeader(PurItemCatalog.Packaging);
      this.addColumnHeader(PurItemCatalog.Quantity);
      if (this.access.Fld_Amount) {
        this.addColumnHeader(PurItemCatalog.BuyPrice);
        this.addColumnHeader(PurItemCatalog.ExpenseAmount);
      }
      this.addColumnHeader(PurItemCatalog.IsClosed);

      // value
      for (const row of items) {
        Model.updateItemReadOnly(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, PurItemCatalog);
        this.addCellRelatedValue("DispatchID", row, PurItemCatalog);
        this.addCellValue("SKU", row, PurItemCatalog);
        this.addCellValue("QtyReceive", row, PurItemCatalog);
        this.addCellValue("Packaging", row, PurItemCatalog);
        this.addCellValue("Quantity", row, PurItemCatalog);
        if (this.access.Fld_Amount) {
          this.addCellValue("BuyPrice", row, PurItemCatalog);
          this.addCellValue("ExpenseAmount", row, PurItemCatalog);
        }
        this.addCellValue("IsClosed", row, PurItemCatalog);
      }
    },
    addReceiveList(receiveList) {
      this.addReceiveList_Rows(receiveList);

      this.addSheet(Info.Export.Details.Excel.SheetName.ReceiveList);
    },
    addReceiveList_Rows(receiveList) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(ReceiveCatalog.ReceiveNumber);
      this.addColumnHeader_ID(ReceiveCatalog.WarehouseID.Label);
      this.addColumnHeader(ReceiveCatalog.WarehouseID);
      this.addColumnHeader(ReceiveCatalog.Date);
      if (this.access.Receive_Fld_Amount) {
        this.addColumnHeader(ReceiveCatalog.Total);
      }

      // value
      for ( const row of receiveList) {
        this.addRow();
        this.addSequenceCell();
        this.addCellValue_ID(row);
        this.addCellValue("ReceiveNumber", row, ReceiveCatalog);
        this.addCellValue("WarehouseID", row, ReceiveCatalog);
        this.addCellRelatedValue("WarehouseID", row, ReceiveCatalog);
        this.addCellValue("Date", row, ReceiveCatalog);
        if (this.access.Receive_Fld_Amount) {
          this.addCellValue("Total", row, ReceiveCatalog);
        }
      }
    }
  }
}
</script>