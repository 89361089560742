import AppCatalog from "../../../services/App/AppCatalog.js";
import PosItemCatalog from "../PosItemCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      AppCatalog.Field.Name.Label + ", atau " +
      AppCatalog.Field.SKU.Label + ", atau " +
      AppCatalog.Field.Barcode.Label + " [F3]",
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  },

  // result
  ItemID: {
    Label: PosItemCatalog.DispatchID.Label,
    Type: PosItemCatalog.DispatchID.Type,
    RelatedValue: PosItemCatalog.DispatchID.RelatedValue,
    RelatedModule: PosItemCatalog.DispatchID.RelatedModule,
    ReadOnly: true
  },
  Quantity: {
    Label: PosItemCatalog.Stock.Label,
    Type: PosItemCatalog.Stock.Type,
    ReadOnly: PosItemCatalog.Stock.ReadOnly
  },
  SellPrice: {
    Label: PosItemCatalog.SellPrice.Label,
    Type: PosItemCatalog.SellPrice.Type,
    Output: PosItemCatalog.SellPrice.Output,
    ReadOnly: true
  },
  SellPriceNetto: {
    Label: PosItemCatalog.SellPriceNetto.Label,
    Type: PosItemCatalog.SellPriceNetto.Type,
    Output: PosItemCatalog.SellPriceNetto.Output,
    ReadOnly: PosItemCatalog.SellPriceNetto.ReadOnly
  },
  SpecialPriceID: {
    Label: PosItemCatalog.SpecialPriceID.Label,
    Type: PosItemCatalog.SpecialPriceID.Type,
    RelatedValue: PosItemCatalog.SpecialPriceID.RelatedValue,
    RelatedModule: PosItemCatalog.SpecialPriceID.RelatedModule,
    ReadOnly: PosItemCatalog.SpecialPriceID.ReadOnly
  }
}