<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../RecCatalog.js";
import Info from "../RecInfo.js";
import Model from "../RecModel.js";

export default {
  mixins: [XlsxMixin],
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.SalesDraftID.ExportLabel);
      this.addColumnHeader(Catalog.SalesDraftID.Label);
      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Status);
      this.addColumnHeader(Catalog.DueDate);
      this.addColumnHeader(Catalog.RecTotal);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("SalesDraftID", row, Catalog);
        this.addCellRelatedValue("SalesDraftID", row, Catalog);
        this.addCellValue("SONumber", row, Catalog);
        this.addCellValue("InvoiceDate", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("ClientAddress", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellStatus(row.Status, Model.Status);
        this.addCellValue("DueDate", row, Catalog);
        this.addCellValue("RecTotal", row, Catalog);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>