<template>
  <kst-page-report 
    :catalog="DaiSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <Result
        :dataList="data.List"
        :dataSummary="data.Summary"
        :searchData="searchData"
      />
    </template>
  </kst-page-report>
</template>

<script>
import DaiSearchCatalog from "../DaiSearchCatalog.js";
import Model from "../DaiModel.js";
import Services from "../../../services/Api/DailyServices.js";

import Result from "./DaiR_Result.vue";
import Search from "./DaiR_Search.vue";

export default {
  components: {
    Result,
    Search
  },
  data: () => ({
    DaiSearchCatalog: DaiSearchCatalog,
    Model: Model,
    searchData: {
      Date: null,
      SearchResultID: null,
      WarehouseID: null
    },
    warehouseOptions: {}
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadReportData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getReportData(options);
      loadReportData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(
        data.WarehouseList, DaiSearchCatalog.WarehouseID.Label
      );
    },

    handleLoad(loadReport) {
      const options = this.getListOptions();
      const config = Services.getReport(options);
      loadReport(config);
    },

    handleReset() {
      this.resetSearch();
    },

    /*** custom ***/

    resetData() {
      this.warehouseOptions = {};

      this.normalizeSearch();
    },

    normalizeSearch() {
      if (this.searchData.SearchResultID === null) {
        this.searchData.SearchResultID = Model.SearchResult.HasTransaction.ID;
      }
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Date = this.$kst.In.getDateToday();
      this.searchData.SearchResultID = Model.SearchResult.HasTransaction.ID;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },

    getListOptions() {
      return {
        date: this.$kst.Out.getDateString(this.searchData.Date),
        searchresult: this.$kst.Search.getValue(this.searchData.SearchResultID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID)
      };
    }
  }
}
</script>