<template>
  <PageEdit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <PaymentTypeList
      :userData="userData"
      :paymentTypeList="paymentTypeList"
    />
  </PageEdit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../PaytModel.js";
import Services from "../../../services/Api/PaymentTypeServices.js";

import PaymentTypeList from "./PaytE_PaytList.vue";
import PageEdit from "./PaytE_PageEdit.vue";

export default {
  components: {
    PaymentTypeList,
    PageEdit,
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {
      checkedRows: []
    },
    paymentTypeList: [],
  }),
  methods: {
    /*** by page ***/

    handleInit(loadEdit) {
      this.resetData();

      const config = Services.getEditData();
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.paymentTypeList = data.PaymentTypeList;
      Model.Edit.setListByData(this.userData.checkedRows, data.DetailsList,
        this.paymentTypeList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData.checkedRows);
      const config = Services.putData(data);

      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData.checkedRows = [];
      this.paymentTypeList = [];
    }
  }
}
</script>