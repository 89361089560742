import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  ReceiveDate: {
    Label: AppCatalog.Field.Date.Label + " Penerimaan",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  ReceiveDebtAfter: {
    Label: AppModule.Debt.FullName + " Akhir",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ReceiveDebtBefore: {
    Label: AppModule.Debt.FullName + " Awal",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ReceiveDebtChanges: {
    Label: AppModule.Debt.FullName + " Masuk/Keluar",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  ReceiveID: {
    Label: AppModule.Receive.FullName,
    ExportLabel: AppCatalog.Field.Number.Label + " " + AppModule.Receive.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ReceiveNumber"
  },
  VendorDebtAfter: {
    Label: AppModule.Debt.FullName + " Akhir",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  VendorDebtBefore: {
    Label: AppModule.Debt.FullName + " Awal",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  VendorDebtChanges: {
    Label: AppModule.Debt.FullName + " Masuk/Keluar",
    Type: AppCatalog.Field.Total.AltType,
    Output: AppCatalog.Field.Total.AltOutput,
    ReadOnly: AppCatalog.Field.Total.ReadOnly
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "VendorName",
    RelatedModule: AppModule.Vendor
  },
  VendorName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 500,
    ReadOnly: true
  },

  // support
  TransWarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "TransWarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}