import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../StSpCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: AppCatalog.Field.Search.Label + " " +
      Catalog.Name.Label + ", atau " +
      Catalog.SKU.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  }
}