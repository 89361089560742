<template>
  <kst-section :title="Info.Section.TransactionSetting.Title">
    <kst-input field="BackDateInterval" :data="userData" :catalog="Catalog">
      <template #right>
        <div class="control">
          <div class="input ks-output" readonly>
            {{ Info.Field.BackDateInterval.Suffix }}
          </div>
        </div>
      </template>
    </kst-input>
  </kst-section>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Info from "../UserInfo.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
  })
}
</script>