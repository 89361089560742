<template>
  <div class="block">
    <kst-input load-on-focus field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input field="Address" :data="userData" :catalog="Catalog"/>
    <kst-input field="PIC" :data="userData" :catalog="Catalog"/>
    <kst-input field="NoPIC" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../WhsCatalog.js";

export default {
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>