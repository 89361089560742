<template>
  <div>
    <template v-if="showImage && data.ImageSrc !== null">
      <div class="columns">
        <div class="column mt-3 ks-max-width-5r">
          <kst-image size-auto border-off hide-preview read-only thumbnail 
            :src="data.ImageSrc"/>
        </div>
        <div class="column">
          <Details :data="data" :customData="customData"/>
        </div>
      </div>
    </template>
    <template v-else>
      <Details :data="data" :customData="customData"/>
    </template>
  </div>
</template>

<script>
import Details from "./CpRPD_Details.vue";

export default {
  components: {
    Details,
  },
  props: {
    customData: {
      type: Object,
      default: null
    },
    data: Object,
    showImage: Boolean
  }
}
</script>