import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  address: {
    Label: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    ReadOnly: true
  },
  name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  }
}