import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./SlsCatalog.js";
import InvoiceCatalog from "../Invoice/InvCatalog.js";
import RtnInvoiceCatalog from "../RtnInvoice/RinvCatalog.js";

export default {
  getMessageConfirmationSection(invoiceList, rtnInvoiceList) {
    let messageList = [AppCatalog.Message.CannotRestoreDeletedData];

    if (invoiceList.length > 0) {
      messageList.push(
        AppModule.Sales.FullName + " ini punya " + 
        invoiceList.length + " " + AppModule.Invoice.FullName
      )
    }

    if (rtnInvoiceList.length > 0) {
      messageList.push(
        AppModule.Sales.FullName + " ini punya " + 
        rtnInvoiceList.length + " " + AppModule.RtnInvoice.FullName
      )
    }
    
    return messageList;
  },

  getSectionHelp(dataList, key, label, moduleName) {
    const maxData = 10;

    if (dataList.length > 0) {
      let currentNumber, dataListText = "";

      for (const [i, invoice] of dataList.entries()) {
        currentNumber = i + 1;

        if (currentNumber !== 1) {
          if (currentNumber > maxData) {
            dataListText += " .... ";
            break;
          } else {
            dataListText += ", ";
          }
        }

        dataListText += invoice[key];
      }
  
      return label + ": " + dataListText;
    }

    return AppCatalog.Message.Without + " " + moduleName;
  },

  getInvoiceSectionHelp(invoiceList) {
    return this.getSectionHelp(
      invoiceList, 
      'SONumber', 
      InvoiceCatalog.SONumber.Label, 
      AppModule.Invoice.FullName
    );
  },

  getRtnInvoiceSectionHelp(rtnInvoiceList) {
    return this.getSectionHelp(
      rtnInvoiceList, 
      'ReturnNumber', 
      RtnInvoiceCatalog.ReturnNumber.Label, 
      AppModule.RtnInvoice.FullName
    );
  },

  getSalesSectionHelp(salesData) {
    return Catalog.DraftNumber.Label + ": " + 
      App.Value.getValue("DraftNumber", salesData, Catalog) + ", " +
      Catalog.Date.Label + ": " +
      App.Value.getValue("Date", salesData, Catalog);
  },

  validationDraftNumber(salesData) {
    return {
      [App.Vee.Rule.Is]: {
        target: salesData.DraftNumber,
        label: Catalog.DraftNumber.Label
      }
    };
  }
}