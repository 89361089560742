import AppCatalog from "../../services/App/AppCatalog";
import AppModule from "../../services/App/AppModule.js";

export default {
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client,
    Highlight: true
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  DraftNumber: {
    Label: AppCatalog.Field.Number.Label + " SO",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true,
    Highlight: true
  },
  StockQuantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: true
  },
  TotalQty: {
    Label: AppCatalog.Field.Qty.Label + " SO",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  },

  // summary
  MissingQty: {
    Label: "Kekurangan " + AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type
  },

  // support
  DispatchName: { // handle sort
    Label: AppModule.Item.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label + " SO",
    Type: AppCatalog.Field.Qty.Type
  }
}