import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  HppPerPcs: {
    Label: AppCatalog.Field.HppPerPcs.Label,
    Type: AppCatalog.Field.HppPerPcs.Type,
    Output: AppCatalog.Field.HppPerPcs.Output,
    ReadOnly: AppCatalog.Field.HppPerPcs.ReadOnly
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  Quantity: {
    Label: AppCatalog.Field.Total.Label + " " + AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  }
}