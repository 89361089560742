<template>
  <kst-page-print-list pageFooter
    v-slot="{ title }"
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <List class="mt-4" :items="items"/>
  </kst-page-print-list>
</template>

<script>
import Model from "../RcpyModel.js";
import SearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/RecPayServices.js";

import List from "./RcpyPL_List.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    List,
    Company
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    companyData: {},
    items: [],
    searchData: {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: null,
      PaymentTypeName: null,
      StatusID: null,
      WarehouseID: null
    },
    searchParams: ["Search", "ClientID", "ClientName", "Date",
      "PaymentTypeName", "StatusID", "WarehouseID"]
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadPrint) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getPrintListData(options);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.companyData = data.CompanyDetails;
      this.items = data.List;

      for (const row of this.items) {
        Model.setDetailsByStatus(row);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.items = [];
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      this.searchData.StatusID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;
      const paymentType =
        this.$kst.Search.getValue(this.searchData.PaymentTypeName, null) === null
        ? null
        : this.searchData.PaymentTypeName;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        client: clientId,
        clientname: clientName,
        paymenttype: paymentType,
        status: this.$kst.Search.getValue(this.searchData.StatusID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.List.Search.Fields
      };
    }
  }
}
</script>