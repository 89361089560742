<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../DbcdCatalog.js";
import DbcdTransCatalog from "../DbcdTransCatalog.js";
import Info from "../DbcdInfo.js";
import Model from "../DbcdModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDebtCardList(data.List);

      this.download(fileName);
    },

    addDebtCardList(dataList) {
      Model.updateList(dataList);

      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.CreatedDate);
      this.addColumnHeader(Catalog.TypeID);
      this.addColumnHeader(Catalog.TransactionNumber);
      this.addColumnHeader(Catalog.TransactionDate);
      this.addColumnHeader_ID(DbcdTransCatalog.TransWarehouseID.Label);
      this.addColumnHeader(DbcdTransCatalog.TransWarehouseID);
      this.addColumnHeader_ID(DbcdTransCatalog.ReceiveID.Label);
      this.addColumnHeader(DbcdTransCatalog.ReceiveID);
      this.addColumnHeader(DbcdTransCatalog.ReceiveDate);
      this.addColumnHeader(DbcdTransCatalog.ReceiveDebtBefore);
      this.addColumnHeader(DbcdTransCatalog.ReceiveDebtChanges);
      this.addColumnHeader(DbcdTransCatalog.ReceiveDebtAfter);
      this.addColumnHeader_ID(DbcdTransCatalog.VendorID.Label);
      this.addColumnHeader(DbcdTransCatalog.VendorID);
      this.addColumnHeader(DbcdTransCatalog.VendorDebtBefore);
      this.addColumnHeader(DbcdTransCatalog.VendorDebtChanges);
      this.addColumnHeader(DbcdTransCatalog.VendorDebtAfter);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("CreatedDate", row, Catalog);
        this.addCellRelatedValue("TypeID", row, Catalog);
        this.addCellValue("TransactionNumber", row, Catalog);
        this.addCellValue("TransactionDate", row, Catalog);
        this.addCellValue("TransWarehouseID", row, DbcdTransCatalog);
        this.addCellRelatedValue("TransWarehouseID", row, DbcdTransCatalog);
        this.addCellValue("ReceiveID", row, DbcdTransCatalog);
        this.addCellRelatedValue("ReceiveID", row, DbcdTransCatalog);
        this.addCellValue("ReceiveDate", row, DbcdTransCatalog);
        this.addCellValue("ReceiveDebtBefore", row, DbcdTransCatalog);
        this.addCellValue("ReceiveDebtChanges", row, DbcdTransCatalog);
        this.addCellValue("ReceiveDebtAfter", row, DbcdTransCatalog);
        this.addCellValue("VendorID", row, DbcdTransCatalog);
        this.addCellRelatedValue("VendorID", row, DbcdTransCatalog);
        this.addCellValue("VendorDebtBefore", row, DbcdTransCatalog);
        this.addCellValue("VendorDebtChanges", row, DbcdTransCatalog);
        this.addCellValue("VendorDebtAfter", row, DbcdTransCatalog);
      }

      this.addSheet(Info.Export.Excel.SheetName.DebtCard);
    }
  }
}
</script>