<template>
  <div class="kst-table">
    <kst-skeleton :active="loading"/>
    <kst-failure list-mode :items="failure"/>

    <template v-if="!failure">
      <template v-if="!loading">
        <b-table focusable hoverable striped
          ref="myTable"
          :checkable="checkable"
          :checked-rows="checkedRows"
          :class="theClass"
          :custom-is-checked="customIsChecked"
          :data="data"
          :default-sort="sort"
          :default-sort-direction="sortDirection"
          :detail-key="detailKey"
          :detailed="detailed"
          :header-checkable="headerCheckable"
          :opened-detailed="openedDetails"
          :paginated="thePaginated"
          :per-page="perPage"
          :selected="selected"
          :show-detail-icon="detailed"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          @check="handleCheck"
          @page-change="handlePageChange"
          @select="handleSelect"
          @sort="handleSort"
        >
          <template v-if="!sequenceOff">
            <b-table-column
              v-slot="{ index }"
              cell-class="ks-col-sequence"
              width="10"
              :label="theSequenceLabel"
            >
              <div :class="viewMode ? '' : 'k-control-py'">
                {{ ((currentPage - 1) * perPage) + index + 1 }}
              </div>
            </b-table-column>
          </template>

          <slot/>

          <template v-if="action">
            <b-table-column :cell-class="theActionCellClass" width="10">
              <template #header>
                <kst-header-blank />
              </template>
              <template #default="props">
                <slot name="action"
                  :colindex="props.colindex"
                  :column="props.column"
                  :index="props.index"
                  :row="props.row"
                  :toggleDetails="props.toggleDetails"
                />
              </template>
            </b-table-column>
          </template>

          <template v-if="detailed" #detail="props">
            <slot name="detail"
              :colindex="props.colindex"
              :column="props.column"
              :index="props.index"
              :row="props.row"
              :toggleDetails="props.toggleDetails"
            />
          </template>

          <template #empty>
            <slot name="empty">
              <kst-table-empty>
                <slot name="empty-body"/>
              </kst-table-empty>
            </slot>
          </template>

          <template #footer>
            <slot name="footer"/>
          </template>
        </b-table>
      </template>
    </template>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    action: Boolean,
    autoToggleDetails: Boolean, // MUST have detailKey, detailed
    checkable: Boolean,
    checkedRows: Array,
    customIsChecked: {
      type: Function,
      default: null
    },
    data: Array,
    detailKey: String,
    detailed: Boolean,
    failure: [Array, String],
    headerCheckable: {
      type: Boolean,
      default: true
    },
    loading: Boolean,
    paginated: Boolean,
    perPage: {
      type: Number,
      default: AppCatalog.Table.PerPage
    },
    selected: Object,
    sequenceOff: Boolean,
    sort: String, // column name
    sortDirection: String, // see Table.Sort enum in "@/services/App/App_Buefy.js",
    // mode: ONLY select 1 (one)
    inputMode: Boolean,
    viewMode: Boolean
  },
  data: () => ({
    currentPage: 1,
    openedDetails: [],
    sortIcon: AppCatalog.Table.SortIcon,
    sortIconSize: AppCatalog.Table.SortIconSize
  }),
  watch: {
    data() {
      this.currentPage = 1;
    }
  },
  computed: {
    theActionCellClass() {
      return this.viewMode
        ? "ks-col-action ks-col-action-view-padding"
        : "ks-col-action";
    },
    theClass() {
      return this.getClass();
    },
    thePaginated() {
      return this.getPaginated();
    },
    theSequenceLabel() {
      return AppCatalog.Info.Sequence;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.setAutoToggleDetails(this.checkedRows);
    },

    handleCheck(checkedList, row) {
      this.$emit("update:checked-rows", checkedList);
      this.$emit(this.$kst.Enum.Event.Check, checkedList, row);

      this.updateAutoToggleDetails(checkedList, row);
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    handleSelect(row) {
      this.$emit("update:selected", row);
    },
    handleSort(field, order) {
      this.$emit(this.$kst.Enum.Event.Sort, field, order);
    },

    getClass() {
      if (this.inputMode) {
        return "ks-table-input";
      }
      else if (this.viewMode) {
        return "ks-table-view";
      }

      return undefined;
    },
    getPaginated() {
      if (this.data === undefined || this.data === null) {
        return false;
      }
      else if (this.data.length === 0) {
        return false;
      }

      return this.paginated;
    },

    setAutoToggleDetails(checkedList) {
      // validation
      if (!this.hasAutoToggleDetails()) {
        return;
      }

      this.openedDetails = [];

      for (const row of checkedList) {
        this.openedDetails.push(row[this.detailKey]);
      }
    },
    updateAutoToggleDetails(checkedList, row) {
      // if: check/uncheck all
      if (row === undefined) {
        this.setAutoToggleDetails(checkedList);
      }
      // else: check/uncheck row
      else {
        // validation
        if (!this.hasAutoToggleDetails()) {
          return;
        }

        if (checkedList.includes(row)) {
          const rowId = row[this.detailKey];
          if (!this.openedDetails.includes(rowId)) {
            this.$refs.myTable.openDetailRow(row);
          }
        }
        else {
          this.$refs.myTable.closeDetailRow(row);
        }
      }
    },
    hasAutoToggleDetails() {
      if (!this.autoToggleDetails) {
        return false;
      }
      if (this.detailKey === undefined || this.detailKey === null) {
        return false;
      }

      return true;
    }
  }
}
</script>