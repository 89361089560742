<template>
  <kst-modal-view
    :class="Info.Dialog.Summary.Class"
    :title="Info.Dialog.Summary.Title"
    :width="800"
    @init="handleModal_init"
  >
    <List :items="summaryList"/>
  </kst-modal-view>
</template>

<script>
import Info from "../StavInfo.js";
import Model from "../StavModel.js";

import List from "./StavL_Summary_List.vue";

export default {
  props: {
    items: Array
  },
  components: {
    List
  },
  data: () => ({
    Info: Info,
    summaryList: [],
    // func
    showFunc: null
  }),
  methods: {
    /*** public method ***/

    show() {
      this.summaryList = Model.getSummaryList(this.items);
      this.showFunc();
    },

    /*** by modal ***/

    handleModal_init(show) {
      this.showFunc = show;

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    }
  }
}
</script>