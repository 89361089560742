import App from "../../services/App/App.js";
import ModelBody from "./OmzModel_Body.js";
import OmzChartCatalog from "./OmzChartCatalog.js";

export default {
  getChart(transactionDetails) {
    let labelList = [], summaryObj = {};
    let result = ModelBody.createChart();

    this.getChart_populateInvoice(labelList, summaryObj, transactionDetails);
    this.getChart_populatePos(labelList, summaryObj, transactionDetails);
    this.getChart_populateRtnInvoice(labelList, summaryObj, transactionDetails);

    let tempData, valueTotal = 0;
    labelList.sort();

    for (const row of labelList) {
      valueTotal += summaryObj[row];

      tempData = { Date: row };
      result.LabelList.push(
        App.Value.getValue("Date", tempData, OmzChartCatalog)
      );
      result.ValueList.push(
        App.Data.getFixedDecimal(summaryObj[row])
      );
    }

    result.ValueAvg = labelList.length === 0
      ? 0
      : App.Data.getFixedDecimal(valueTotal / labelList.length);
    result.ValueAvg = App.In.getDecimal(result.ValueAvg);

    return result;
  },
  getChart_populateInvoice(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.InvoiceList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populatePos(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.PosList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] + row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  },
  getChart_populateRtnInvoice(labelList, summaryObj, transactionDetails) {
    for (const row of transactionDetails.RtnInvoiceList) {
      if (labelList.includes(row.Date)) {
        summaryObj[row.Date] = summaryObj[row.Date] - row.Total;
      }
      else {
        summaryObj[row.Date] = row.Total;
        labelList.push(row.Date);
      }
    }
  }
}