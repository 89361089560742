import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./ClientCatalog.js";
import ClientAddressCatalog from "./ClientAddressCatalog.js";

export default {
  // required in model
  Module: AppModule.Client,
  ModuleType: {
    Details: AppModuleType.Details
  },

  /*** related ***/

  /*** property ***/

  /*** method ***/

  getSectionHelp_RelatedDetails(data) {
    return this.helper.getSectionHelp_RelatedDetails(data);
  },

  helper: {
    getSectionHelp_RelatedDetails(data) {
      return Catalog.Name.Label + ": " +
        App.Value.getValue("Name", data, Catalog) + ", " +
        ClientAddressCatalog.Address.Label + ": " +
        App.Value.getValue("Address", data, ClientAddressCatalog);
    }
  }
}