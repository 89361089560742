<template>
  <div>
    <template v-for="item in items">
      <kst-check
        :key="item.ID"
        :label="item.Name"
        v-model="item.IsSelected"
      />
    </template>
  </div>
</template>

<script>
import Catalog from "../ModuleAttrCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>