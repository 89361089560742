<template>
  <div class="has-text-centered kst-table-empty">
    <b-icon icon="information-outline"/>
    <div>{{ title }}</div>

    <slot />
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    title: {
      type: String,
      default: AppCatalog.Message.TableEmpty
    }
  }
}
</script>