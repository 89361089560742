<template>
  <div class="block">
    <kst-input
      field="TypeID" :data="userData" :catalog="Catalog"
      :options="typeOptions"
    />
    <kst-input load-on-focus field="Name" :data="userData" :catalog="Catalog"/>
    <kst-input field="SKU" :data="userData" :catalog="Catalog"/>
    <kst-input field="Barcode" :data="userData" :catalog="Catalog"/>
    <kst-input field="Comment" :data="userData" :catalog="Catalog"/>

    <kst-output field="ImageSrc" :data="userData" :catalog="Catalog">
      <kst-image is-local :src="userData.ImageSrc"
        @input="handleImageInput" @delete="handleImageDelete"
      />
    </kst-output>

    <kst-input field="LimitQty" :data="userData" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../ItemCatalog.js";

export default {
  props: {
    typeOptions: Object,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleImageInput(imgSrc, imgText) {
      this.userData.ImageSrc = imgSrc;
      this.userData.Image64 = imgText;
    },
    handleImageDelete() {
      this.userData.ImageSrc = "";
      this.userData.Image64 = "";
    }
  }
}
</script>