<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StclCatalog.js";
import Info from "../StclInfo.js";
import Model from "../StclModel.js";
import StclItemCatalog from "../StclItemCatalog.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();
      this.addDetails(data.Details, data.SpecialPriceCount);

      this.download(fileName);
    },

    addDetails(details, specialPriceCount) {
      this.addDetails_Details(details);
      this.addDetails_Items(details.Items, specialPriceCount);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.ClosingNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();

      // value
      this.addRow();
      this.addCellValue_ID(details);
      this.addCellValue("ClosingNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
    },
    addDetails_Items(items, specialPriceCount) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Export.Details.Excel.Section.StockClosingItems);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(StclItemCatalog.DispatchID.Label);
      this.addColumnHeader(StclItemCatalog.DispatchID);
      this.addColumnHeader(StclItemCatalog.SKU);
      this.addColumnHeader(StclItemCatalog.Packaging);
      this.addColumnHeader(StclItemCatalog.Qty);
      this.addColumnHeader(StclItemCatalog.SellPrice);

      if (specialPriceCount > 0) {
        this.addColumnHeader_ID(StclItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(StclItemCatalog.SpecialPriceID);
      }

      this.addColumnHeader(StclItemCatalog.Total);

      // value
      for ( const row of items ) {
        Model.updateItem(row);

        this.addRow();
        this.addSequenceCell();
        this.addCellValue("DispatchID", row, StclItemCatalog);
        this.addCellRelatedValue("DispatchID", row, StclItemCatalog);
        this.addCellValue("SKU", row, StclItemCatalog);
        this.addCellValue("Packaging", row, StclItemCatalog);
        this.addCellValue("Qty", row, StclItemCatalog);
        this.addCellValue("SellPrice", row, StclItemCatalog);

        if (specialPriceCount > 0) {
          this.addCellValue("SpecialPriceID", row, StclItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, StclItemCatalog);
        }

        this.addCellValue("Total", row, StclItemCatalog);
      }
    }
  }
}
</script>