import AppCatalog from "../../services/App/AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 50,
    Required: true,
    Highlight: true
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  }
}