<template>
  <div>
    <div class="base-background"></div>
    <div class="second-background"></div>
    <div class="company-logo">
      <img src="../../../assets/companylogo.png" class="default-logo" />
    </div>
    <div class="content">
      <h1>Syarat & Ketentuan</h1>
      <article>
        <h4>PERHATIAN</h4>
        <p class="attention">Mohon baca seluruh ketentuan penggunaan Dirigo sebelum melakukan registrasi dan menggunakan layanan Dirigo. Menekan tombol “Daftar” berarti Pengguna telah membaca dan menyetujui semua informasi yang tertera pada halaman ini.</p>
        <p>Bagian Syarat dan Ketentuan penggunaan Dirigo ini berisikan syarat dan ketentuan yang mengatur hubungan antara Anda (Pengguna Layanan Dirigo / Pengguna) dan Pihak DIrigo (Dirigo / Kami) dalam penggunaan layanan, fitur, dan teknologi Dirigo.</p>
        <h5>1. DEFINISI</h5>
        <p>Bagian ini menjelaskan kata atau istilah tertentu yang tercantum dalam Syarat dan Ketentuan.</p>
        <ol>
          <li><b>KST</b> - PT Kreatifitas Sinergisme Teknoindo - atau dapat disebut juga Pengembang – adalah perusahaan yang membuat dan mengembangkan Dirigo dan berperan sebagai pemilik dan pengelola sah semua layanan Dirigo;</li>
          <li><b>Dirigo</b> adalah layanan teknologi yang dibuat dan dikembangkan oleh KST (dapat diakses pada www.dirigo.id) yang berguna untuk membantu pengelolaan kasir dan stok pada toko, atau jenis badan usaha lainnya, serta membantu memberikan laporan usaha sehingga pengguna dapat dimudahkan dalam mengelola usaha;</li>
          <li><b>Pengguna / User</b> adalah perorangan atau badan usaha atau perusahaan yang menggunakan fasilitas dan layanan Dirigo;</li>
          <li><b>Syarat dan Ketentuan</b> adalah aturan yang mengatur segala macam keperluan terkait interaksi antara pengguna dan Dirigo;</li>
        </ol>
        <h5>2. KETENTUAN UMUM</h5>
        <ol>
          <li>Ketentuan-ketentuan yang tertera pada bagian ini mengatur segala macam bentuk interaksi antara Pengguna dan Dirigo saat menggunakan layanan atau fasilitas yang Dirigo sediakan. Layanan yang dimaksud di sini adalah:
            <ul>
              <li>Aplikasi website Dirigo dan semua fitur yang disediakan di dalamnya;</li>
              <li>Dirigo.id (situs).</li>
            </ul>
          </li>
          <li>Pengguna setuju bahwa Dirigo dapat melakukan pembaharuan Syarat dan Ketentuan secara berkala untuk meningkatkan pelayanan pada pengguna. Pengguna dapat mendapatkan informasi perubahan aturan yang mempengaruhi layanan yang diberikan oleh Dirigo melalui social media, contact person, atau e-mail. Apabila informasi telah diberikan dan Pengguna tetap menggunakan layanan Dirigo, maka Pengguna dianggap setuju dengan isi pembaharuan tersebut;</li>
          <li>Pengguna setuju bahwa pihak Dirigo dapat melakukan pembaharuan pada sistem yang mencakup : pembuatan fitur baru, pembuangan fitur tertentu, atau pemindahan letak fitur tertentu, secara berkala. Perubahan tersebut akan dilakukan setiap jangka waktu tertentu melalui pembaharuan versi.</li>
        </ol>
        <h5>3. AKUN</h5>
        <ol>
          <li>Sebelum resmi terdaftar sebagai pengguna Dirigo, Pengguna harus mencantumkan nama toko, nama pemilik, alamat e-mail, dan alamat toko, dan menyetujui semua Syarat dan Ketentuan yang tercantum;</li>
          <li>Pengguna bertanggung jawab penuh terhadap kebenaran informasi yang diberikan kepada Dirigo. Apabila terjadi permasalahan terkait pemalsuan atau ketidaktepatan informasi, maka seluruh tanggung jawab ada pada pihak Pengguna dan Dirigo berhak memberikan sanksi yang dirasa diperlukan;</li>
          <li>Setelah melakukan registrasi, tim Dirigo akan memproses semua data keperluan Pengguna dan usaha Pengguna dan memberikan informasi akun yang dapat Pengguna akses untuk menggunakan semua layanan Dirigo melalui contact person atau e-mail;</li>
          <li>Dirigo berhak menolak dan menggagalkan proses registrasi Pengguna apabila Pengguna atau usaha Pengguna dinilai:
            <ul>
              <li>Tidak memiliki legalitas usaha;</li>
              <li>Memiliki keterkaitan dengan tindakan yang melanggar norma hukum, sosial, agama, moral atau bersifat kriminal;</li>
              <li>Memiliki pertimbangan lain yang membuat Dirigo dan Pengguna tidak dapat bekerja sama.</li>
            </ul>
          </li>
          <li>Dirigo dapat sewaktu-waktu meminta informasi tambahan dari Pengguna demi memberikan layanan yang lebih baik. Apabila Pengguna tidak bersedia memberikan dan hal tersebut dirasa dapat mengganggu jalannya sistem, Dirigo berhak memblokir atau membekukan akun yang terdaftar;</li>
          <li>Semua privasi yang telah Pengguna percayakan akan digunakan dan diolah untuk:
            <ul>
              <li>Memperbaiki dan mengembangkan layanan Dirigo;</li>
              <li>Membuat fitur-fitur baru demi kepentingan layanan Dirigo yang lebih baik;</li>
              <li>Mencegah permasalahan yang mungkin timbul di kemudian hari terkait layanan yang diberikan;</li>
              <li>Menjalin komunikasi yang baik antara Dirigo dan Pengguna;</li>
              <li>Menghubungi Pengguna apabila ada kepentingan yang mendesak atau informasi yang hendak disampaikan;</li>
              <li>Melakukan penelitian/analisis/pengujian terhadap suatu produk demi meningkatkan layanan yang diberikan Dirigo;</li>
              <li>Keperluan investigasi penegak hukum HANYA apabila dirasa ada transaksi-transaksi atau data yang mencurigakan dan mengindikasikan terjadinya hal-hal yang melanggar norma hukum;</li>
              <li>Selain kepentingan-kepentingan yang tercantum di atas, Dirigo hanya berhak menggunakan data privasi Pengguna untuk kepentingan lain setelah melakukan konfirmasi ulang pada Pengguna.</li>
            </ul>
          </li>
          <li>Dirigo akan melakukan pemusnahan seluruh data Pengguna ketika Pengguna berhenti menggunakan layanan Dirigo;</li>
          <li>Dirigo berhak membekukan akun Pengguna apabila (dengan atau tanpa pengembalian dana):
            <ul>
              <li>Pengguna D’Lite yang tidak melakukan login selama 90 hari terhitung sejak pertama kali terdaftar di paket D’Lite, atau pengguna belum melakukan pembayaran untuk periode berlangganan selanjutnya dalam waktu 14 hari sejak masa berlangganan berakhir;</li>
              <li>Melakukan pelanggaran Syarat dan Ketentuan;</li>
              <li>Terdapat laporan bahwa layanan Dirigo disalahgunakan;</li>
              <li>Terdapat informasi bahwa layanan Dirigo dialihkan pada pihak lain tanpa adanya pemberitahuan terlebih dahulu;</li>
              <li>Ada dugaan dengan bukti cukup bahwa terdapat hal-hal yang dapat merugikan Dirigo;</li>
              <li>Terjadi keterlambatan pembayaran tanpa pemberitahuan sebelumnya dan tidak ada indikasi penyelesaian dari pengguna;</li>
              <li>Terdapat perintah pembekuan akun yang diterbitkan oleh lembaga berwenang.</li>
            </ul>
          </li>
          <li>Dirigo berhak melakukan pemblokiran akun Pengguna (dengan atau tanpa pengembalian dana) apabila:
            <ul>
              <li>Pengguna secara tertulis telah memutuskan untuk tidak melanjutkan paket berlangganan, dan pengguna telah terdaftar untuk paket D’Lite lebih dari 90 hari, dan tidak ada aktivitas login dalam kurun waktu 3 bulan terakhir;</li>
              <li>Pengguna tidak mengindahkan teguran lisan, tertulis, maupun resmi yang diberikan Dirigo dan tetap melakukan pelanggaran;</li>
              <li>Tidak ada intensi penyelesaian masalah dari pengguna;</li>
              <li>Pengguna dan Dirigo tidak menemukan penyelesaian masalah setelah berunding dan pemutusan hubungan kerja dirasa menjadi jalan yang paling baik;</li>
              <li>Terjadi peretasan akun dan akun terpaksa diblokir atas permintaan Pengguna;</li>
              <li>Terdapat perintah pemblokiran akun yang diterbitkan oleh lembaga berwenang.</li>
            </ul>
          </li>
          <li>Pengguna yang telah menyelesaikan 90 hari masa percobaan D’Premium dan memutuskan tidak melanjutkan ke paket berlangganan D’Basic/D’Premium akan otomatis terdaftar sebagai pelanggan D’Lite.</li>
        </ol>
        <h5>4. LAYANAN & JALUR KOMUNIKASI</h5>
        <ol>
          <li>Selama Pengguna menggunakan layanan, Dirigo berhak memiliki akses terhadap data usaha Pengguna jika terjadi permasalahan dan pengaksesan data dilakukan dalam upaya untuk menyelesaikan masalah. Perubahan data hanya dapat dilakukan oleh Pengguna atau Dirigo dengan seijin Pengguna apabila terjadi hal-hal yang tidak diinginkan;</li>
          <li>Dirigo berhak mengakses data Pengguna (tanpa melakukan perubahan) untuk kepentingan peningkatan layanan yang diberikan;</li>
          <li>Dirigo tidak berhak menyebarluaskan data Pengguna pada pihak di luar perusahaan dengan tujuan apapun tanpa sepengetahuan Pengguna;</li>
          <li>Komunikasi antara Pengguna dan Dirigo akan dilakukan melalui contact person yang bertanggung jawab atas akun Pengguna. Segala macam permasalahan terkait layanan, pembaharuan sistem, maupun kepentingan lainnya dapat Pengguna komunikasikan melalui contact person.</li>
        </ol>
        <h5>5. BIAYA & PEMBAYARAN</h5>
        <ol>
          <li>Pengguna tidak dikenakan biaya apapun dalam Masa Percobaan D’Premium selama 90 hari;</li>
          <li>Pengguna yang telah menyelesaikan 90 hari masa percobaan D’Premium dan memutuskan tidak melanjutkan ke paket berlangganan D’Basic/D’Premium akan otomatis terdaftar sebagai pelanggan D’Lite;</li>
          <li>Pengguna D’Lite tidak dikenakan biaya apapun;</li>
          <li>Harga biaya berlangganan Dirigo dapat dilihat pada halaman harga website Dirigo atau ditanyakan pada contact person apabila ada tambahan D’Custom atau D’Extra;</li>
          <li>Pengguna diwajibkan mengikuti metode pembayaran yang disediakan Dirigo apabila setuju untuk berlangganan. Metode pembayaran dapat ditanyakan lebih lanjut pada contact person Dirigo.</li>
        </ol>
      </article>
    </div>
    <div id="scrollup_button" v-if="showScrollUpButton" @click="scrollToTop()">
      <i class="fas fa-long-arrow-alt-up"></i>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    showScrollUpButton: false
  }),
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      // Any code to be executed when the window is scrolled
      if(window.scrollY > 1000) {
          this.showScrollUpButton = true;
      } else {
          this.showScrollUpButton = false;
      }
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
<style scoped lang="css" src="./_tnc.css"/>