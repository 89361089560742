import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.sales;

export default {
  getNewData(serviceId, servicePin) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata?sid=" + serviceId + "&pin=" + servicePin
    };
  },
  getSecurityLogin(serviceId, servicePin) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/security/login?sid=" + serviceId + "&pin=" + servicePin
    };
  },
  getPrintDetailsData(id, serviceId, servicePin) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id + "&sid=" + serviceId +
        "&pin=" + servicePin
    };
  },
  getRelatedItemList(serviceId, servicePin, params) {
    let options = { sid: serviceId, pin: servicePin };
    options = Object.assign(options, params);
    const attributes = ["sid", "pin", "search", "field"];
    const urlParams = App.Out.getParams(options, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  }
}