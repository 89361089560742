<template>
  <kst-page-export-details
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithDetails"
      :label="Info.Export.Details.Excel.Option.Details"
    />
  </kst-page-export-details>
</template>

<script>
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import Export from "./RcvExD_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    access: {},
    userData: {
      ID: null,
      WithDetails: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id) {
      this.userData.ID = id;
      this.access = this.$kst.Access.create(Model.Access);
    },

    handleExport(loadExport) {
      const config = Services.getExportDetails(this.userData.ID);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>