import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.client;

export default {
  deleteSelfService(id) {
    return {
      method: App.Enum.Axios.Method.Delete,
      url: apiEndpoint + "/self-service?id=" + id
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getExportDetails(params) {
    const attributes = ["id", "withreclist"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails" + urlParams
    };
  },
  getExportList(params) {
    const attributes = ["search", "field", "withaddress", "withreceivables"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getPrintListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  },
  putSelfService(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/self-service",
      data: data
    };
  }
}