<template>
  <canvas id="report-chart" :height="Info.Chart.Height">
    Your browser does not support chart.
  </canvas>
</template>

<script>
import Info from "../OmzInfo.js";
import Model from "../OmzModel.js";
import OmzChartCatalog from "../OmzChartCatalog.js";

export default {
  props: {
    chartData: Object,
    searchData: Object
  },
  data: () => ({
    Info: Info
  }),
  mounted () {
    this.createChart();
  },
  methods: {
    createChart() {
      const kstApp = this.$kst;

      new this.$chart("report-chart", {
        data: {
          labels: this.chartData.LabelList,
          datasets: [{
            data: this.chartData.ValueList,
            backgroundColor: Info.Chart.Datasets.BackgroundColor,
          }]
        },
        options: {
          annotation: {
            annotations: [{
              borderColor: Info.Chart.Threshold.BorderColor,
              borderWidth: Info.Chart.Threshold.BorderWidth,
              drawTime: Info.Chart.Threshold.DrawTime,
              mode: Info.Chart.Threshold.Mode,
              scaleID: Info.Chart.Threshold.ScaleID,
              type: Info.Chart.Threshold.Type,
              value: this.chartData.ValueAvg
            }]
          },
          legend: { display: false },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: Model.getChartXLabel(this.searchData),
                fontColor: Info.Chart.ScaleLabel.FontColor,
                fontSize: Info.Chart.ScaleLabel.FontSize,
                fontStyle: Info.Chart.ScaleLabel.FontStyle
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: Info.Chart.Datasets.Label,
                fontColor: Info.Chart.ScaleLabel.FontColor,
                fontSize: Info.Chart.ScaleLabel.FontSize,
                fontStyle: Info.Chart.ScaleLabel.FontStyle
              },
              ticks: {
                callback: function(value) {
                  const tempData = { TotalAxes: value };
                  return kstApp.Value.getValue("TotalAxes", tempData, OmzChartCatalog);
                },
                min: 0
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                const tempData = { Total: tooltipItem.yLabel };
                return kstApp.Value.getValue("Total", tempData, OmzChartCatalog);
              }
            }
          }
        },
        type: Info.Chart.Type
      });
    }
  }
}
</script>