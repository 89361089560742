import App from "../../services/App/App.js";
import ModelBody from "./StrdModel_Body.js";
import ModelDetails from "./StrdModel_Details.js";
import ModelRelatedList from "./StrdModel_RelatedList.js";
import ModelNew from "./StrdModel_New.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  StockReductionItem: ModelBody.StockReductionItem,
  SpecialPrice: ModelBody.SpecialPrice,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    return ModelBody.setDetailsBySpecialPrice(userData, specialPriceCount);
  },

  setItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  getInvalidItems(details) {
    return ModelBody.getInvalidItems(details);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },
  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItemsByStock(userData, userItems) {
    ModelBody.validateItemsByStock(userData, userItems);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    setDetailsByHppCalculation(details) {
      ModelDetails.setDetailsByHppCalculation(details);
    },
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails ||
        access.Act_ExportDetails;
    }
  },

  /*** method - New ***/

  New: {
    setDetailsByInvoice(userData, invoiceDetails) {
      ModelNew.setDetailsByInvoice(userData, invoiceDetails);
    },
    setDetailsByWarehouseList(userData, warehouseList) {
      ModelNew.setDetailsByWarehouseList(userData, warehouseList);
    },
    setItemsByReset(userItems) {
      App.Array.truncate(userItems);
    },
    setItemsByStock(userItems, stockData) {
      return ModelNew.setItemsByStock(userItems, stockData);
    },
    setItemsBySpecialPriceDetails(userItems, stockSpecialPriceList) {
      return ModelNew.setItemsBySpecialPriceDetails(userItems,
        stockSpecialPriceList
      );
    },
    setItemsByStockSpecialPrice(userItems, stockSpecialPriceData) {
      return ModelNew.setItemsByStockSpecialPrice(userItems, stockSpecialPriceData);
    },

    clearDetailsByInvoice(userData) {
      ModelNew.clearDetailsByInvoice(userData);
    },

    getItemInfo(userItem) {
      return ModelNew.getItemInfo(userItem);
    }
  },

  /*** method - RelatedList ***/

  RelatedList: {
    getItemInfo(userItem) {
      return ModelRelatedList.getItemInfo(userItem);
    }
  },

  /*** external ***/

  getSpecialPriceForSelection(specialPriceList, userItems) {
    return ModelBody.getSpecialPriceForSelection(specialPriceList, userItems);
  },

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}