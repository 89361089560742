<template>
  <kst-section collapsible collapsed 
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
  >
    <ItemSearchList
      @init="handleItem_init"
      @submit="handleItem_submit"
    />

    <kst-output control-off status-off
      field="RefID" :data="userData" :catalog="Catalog"
    >
      <template #right>
        <div class="control">
          <template v-if="userData.RefID === null">
            <kst-tooltip search-mode hover-only :content="Model.Module.FullName">
              <kst-button search-mode @click="handleSearch"/>
            </kst-tooltip>
          </template>
          <template v-else>
            <kst-tooltip clear-mode hover-only :content="Model.Module.FullName">
              <kst-button clear-mode @click="handleClear"/>
            </kst-tooltip>
          </template>
        </div>
      </template>
    </kst-output>
  </kst-section>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Info from "../ItemInfo.js";
import Model from "../ItemModel.js";
import Services from "../../../services/Api/ItemServices.js";

import ItemSearchList from "../SearchList/ItemScL.vue";

export default {
  components: {
    ItemSearchList
  },
  props: {
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    // func
    showItemFunc: null
  }),
  computed: {
    sectionHelp() {
      return Model.getRefSectionHelp(this.userData);
    },
    sectionTitle() {
      return Info.Section.Ref.Title;
    }
  },
  methods: {
    /*** by item modal ***/

    handleItem_init(show) {
      this.showItemFunc = show;
    },
    handleItem_submit(itemData) {
      Model.setDetailsByRef(this.userData, itemData);
    },

    /*** custom ***/

    handleSearch() {
      this.showItemFunc(Services.getRelatedItemList, this.userData.ID);
    },
    handleClear() {
      Model.clearDetailsByRef(this.userData);
    }
  }
}
</script>