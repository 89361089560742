<template>
  <div class="kst-upload">
    <b-field class="file is-light" :class="{'has-name': !!data[field]}">
      <b-upload
        v-model="data[field]" 
        :class="uploadClass"
        :accept="accept" 
        @input="handleInput"
      >
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">{{ label }}</span>
        </span>
        <span class="file-name" v-if="data[field]">
          {{ data[field].name }}
        </span>
      </b-upload>
    </b-field>
  </div>
</template>

<script>
import AppCatalog from '../../../services/App/AppCatalog';

export default {
  props: {
    accept: String,
    data: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true 
    },
    label: String,
    mxauto: Boolean,
    readerOff: Boolean
  },
  computed: {
    uploadClass() {
      return "file-label" + this.mxauto ? " mx-auto" : "";
    }
  },
  methods: {
    handleInput(file) {
      if (this.readerOff) {
        this.$emit(this.$kst.Enum.Event.Input, file);
      } else {
        this.getFileReader(file);
      }
    },

    getFileReader(file) {
      var reader = new FileReader();

      reader.onload = () => {
        const fileText = reader.result;
        this.$emit(this.$kst.Enum.Event.Input, file, fileText);
      }

      reader.onerror = function (error) {
        console.log(error);
        this.$emit(this.$kst.Enum.Event.Error, 
          AppCatalog.Message.FailedToLoadFile + ", " + AppCatalog.Message.TryAgain);
      }

      reader.readAsArrayBuffer(file);
    }
  }
}
</script>