import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import SignIn from "./SignIn/SignIn.vue";
import SignOut from "./SignOut/SignOut.vue";
import SignUp from "./SignUp/SignUp.vue";
import ForgotPassword from "./ForgotPassword/ForgotPassword.vue";
import Verification from "./Verification/Verification.vue";

export default {
  Path: {
    SignIn: RouterModel.getPath(AppModule.SignIn, AppModuleType.List),
    SignOut: RouterModel.getPath(AppModule.SignOut, AppModuleType.List),
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SignIn),
        name: RouterModel.getName(AppModule.SignIn),
        component: SignIn,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SignIn),
          module: RouterModel.getModule(AppModule.SignIn)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SignOut),
        name: RouterModel.getName(AppModule.SignOut),
        component: SignOut,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SignOut),
          module: RouterModel.getModule(AppModule.SignOut)
        }
      },
      {
        path: RouterModel.getPath(AppModule.SignUp),
        name: RouterModel.getName(AppModule.SignUp),
        component: SignUp,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SignUp),
          module: RouterModel.getModule(AppModule.SignUp)
        }
      },
      {
        path: RouterModel.getPath(AppModule.ForgotPassword),
        name: RouterModel.getName(AppModule.ForgotPassword),
        component: ForgotPassword,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.ForgotPassword),
          module: RouterModel.getModule(AppModule.ForgotPassword)
        }
      },
      {
        path: RouterModel.getPath(AppModule.Verification),
        name: RouterModel.getName(AppModule.Verification),
        component: Verification,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Verification),
          module: RouterModel.getModule(AppModule.Verification)
        }
      }
    ]
  }
}