<template>
  <kst-section :title="Info.Section.InvoiceList.Title" v-show="userData.IsVerified">
    <template #top-right>
      <div>
        <div class="columns is-mobile mb-0">
          <div class="column is-narrow pb-0">
            <div class="title is-5">
              {{ Catalog.InvoiceTotal.PrintLabel }}:
            </div>
          </div>
          <div class="column pb-0">
            <div class="has-text-right">
              <kst-value class="title is-5"
                field="InvoiceTotal" :data="userData" :catalog="Catalog"
              />
            </div>
          </div>
        </div>

        <div class="columns is-mobile" v-if="invalidInvoiceTotal !== null">
          <div class="column is-narrow ks-max-width-100p">
            <kst-errors-helper :items="invalidInvoiceTotal"/>
          </div>
        </div>
      </div>
    </template>

    <kst-load-data activeOnLoad loadingElement @init="handleLoad_init">
      <template v-if="userData.IsVerified">
        <div class="buttons">
          <kst-button checkAllMode slimSize
            @click="handleCheckAll"
          />
          <kst-button slimSize uncheckAllMode
            @click="handleUncheckAll"
          />
        </div>

        <List
          :userData="userData"
          :userInvoiceList="userInvoiceList"
        />
      </template>
    </kst-load-data>
  </kst-section>
</template>

<script>
import Catalog from "../RcpyCatalog.js";
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";
import Services from "../../../services/Api/RecPayServices.js";

import List from "./RcpyN_Invoices_List.vue";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    userInvoiceList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    // func
    loadData: null
  }),
  computed: {
    invalidInvoiceTotal() {
      return this.userData.InvoiceTotalIsValid
        ? null : Model.New.getInvalidInvoiceTotal(this.userData);
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** by component ***/

    init() {
      this.$emit(this.$kst.Enum.Event.Init, this.loadList);
    },

    /*** by load data ***/

    handleLoad_init(loadData) {
      this.loadData = loadData;
    },

    /*** custom ***/

    handleCheckAll() {
      Model.New.setInvoiceListByCheckAll(this.userData, this.userInvoiceList);
    },
    handleUncheckAll() {
      Model.New.setInvoiceListByUncheckAll(this.userData, this.userInvoiceList);
    },

    loadList() {
      Model.New.resetInvoiceTotal(this.userData);

      const options = this.getListOptions();
      const config = Services.getRelatedInvoiceList(options);
      this.loadData(config, this.loadList_success, true);
    },
    loadList_success(data) {
      Model.New.setInvoiceListBySearch(this.userInvoiceList, data.InvoiceList);
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.userData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.userData.ClientName) : null;

      return {
        client: clientId,
        clientname: clientName,
        warehouse: this.userData.WarehouseID === this.$kst.Search.OptionSome
          ? null : this.userData.WarehouseID
      };
    }
  }
}
</script>