import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import SlsN from "./New/SlsN.vue";
import SlsPD from "./PrintDetails/SlsPD.vue";

export default {
  Path: {
    New: RouterModel.getPath(AppModule.SalesSelfService, AppModuleType.New),
    PrintDetails: RouterModel.getPath(AppModule.SalesSelfService, AppModuleType.PrintDetails)
  },

  getRoutes() {
    return [];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.SalesSelfService, AppModuleType.New),
        name: RouterModel.getName(AppModule.SalesSelfService, AppModuleType.New),
        component: SlsN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesSelfService, AppModuleType.New),
          module: RouterModel.getModule(AppModule.SalesSelfService),
          moduletype: AppModuleType.New
        }
      },
      {
        path: RouterModel.getPath(AppModule.SalesSelfService, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.SalesSelfService, AppModuleType.PrintDetails),
        component: SlsPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.SalesSelfService, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.SalesSelfService),
          moduletype: AppModuleType.PrintDetails
        }
      }
    ];
  }
}