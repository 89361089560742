import AppCatalog from "./AppCatalog.js";
import App_Enum from "./App_Enum.js";
import App_Route from "./App_Route.js";
import App_Session from "./App_Session.js";

export default {
  /**
   * Create access enumeration
   * @param {Object} value
   * @returns {Object} access
   */
  create(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let resultObj = {};
    const moduleAttrList = App_Session.getModuleAttr();

    for (const key in value) {
      resultObj[key] = moduleAttrList.includes(value[key]);
    }

    return resultObj;
  },

  /**
   * Get available actions for a module
   * Do not check access
   * @param {Object} module
   * @param {Object} moduleType
   * @param {String} urlParams
   */
  createAction(module, moduleType, urlParams) {
    const modulePrefix = AppCatalog.Action.Prefix.Module;
    const moduleTypePrefix = AppCatalog.Action.Prefix.ModuleType;
    const urlParamsPrefix = AppCatalog.Action.Prefix.UrlParams;

    return {
      [modulePrefix]: module,
      [moduleTypePrefix]: moduleType,
      [urlParamsPrefix]: urlParams
    };
  },

  /**
   * Get available actions for a module
   * @param {Array} actions
   * @param {Object} module
   * @param {String} urlParams
   * @param {Object} access
   */
  getAvailableActions(actions, module, urlParams, access) {
    const modulePrefix = AppCatalog.Action.Prefix.Module;
    const moduleTypePrefix = AppCatalog.Action.Prefix.ModuleType;
    const urlParamsPrefix = AppCatalog.Action.Prefix.UrlParams;

    let resultList = [];
    for (const action of actions) {
      if (access[action.Attr]) {
        resultList.push({
          [modulePrefix]: module,
          [moduleTypePrefix]: action,
          [urlParamsPrefix]: urlParams
        });
      }
    }

    return resultList;
  },
  /**
   * Get available actions for a module per details (row)
   * @param {Number} id
   * @param {Array} actions
   * @param {Object} module
   * @param {Object} access
   */
  getDetailsAvailableActions(id, actions, module, access) {
    const urlParams = App_Route.getParams({
      [App_Enum.Param.ID]: id
    });

    return this.getAvailableActions(
      actions, module, urlParams, access
    );
  },

  /**
   * Has access to module attr
   * @param {Number} moduleAttr
   * @returns {Boolean} valid
   */
  hasModuleAttr(moduleAttr) {
    const moduleAttrList = App_Session.getModuleAttr();
    return moduleAttrList.includes(moduleAttr);
  }
}