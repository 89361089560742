<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../ExpCatalog.js";
import Info from "../ExpInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      this.download(fileName);
    },
    addDetails(details) {
      this.addDetails_Details(details);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_Details(details) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.ExpenseNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.CategoryID.Label);
      this.addColumnHeader(Catalog.CategoryID);
      this.addColumnHeader_ID(Catalog.SubCategoryID.Label);
      this.addColumnHeader(Catalog.SubCategoryID);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();

      this.addSequenceCell();
      this.addCellValue("ExpenseNumber", details, Catalog);
      this.addCellValue("Date", details, Catalog);
      this.addCellValue("CategoryID", details, Catalog);
      this.addCellRelatedValue("CategoryID", details, Catalog);
      this.addCellValue("SubCategoryID", details, Catalog);
      this.addCellRelatedValue("SubCategoryID", details, Catalog);
      this.addCellValue("WarehouseID", details, Catalog);
      this.addCellRelatedValue("WarehouseID", details, Catalog);
      this.addCellValue("PaymentTypeName", details, Catalog);
      this.addCellValue("Total", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    }
  }
}
</script>