import AppModule from "../../services/App/AppModule.js";

export default {
  Button: {
    VendorItem: {
      Label: AppModule.VendorItem.FullName
    }
  },
  Message: {
    ConfirmDelete: "Yakin menghapus " + AppModule.VendorItem.FullName + "?",
  },
}