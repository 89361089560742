<template>
  <kst-page-list
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :warehouseOptions="warehouseOptions"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data }">
      <List
        :access="access"
        :dataList="data.SalesList"
        :userData="userData"
      />
    </template>
    <template #search-result-buttons>
      <kst-button actionMode slimSize
        class="is-primary"
        :disabled="userData.checkedRows.length === 0"
        :label="Info.Button.Summary.Title"
        :moduleType="Info.Button.Summary.ModuleType"
        @click="handleSummary_click"
      />
    </template>

    <template #search-result-footer>
      <Summary
        :items="userData.checkedRows"
        @init="handleSummary_init"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Info from "../StavInfo.js";
import Model from "../StavModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/StockAvailabilityServices.js";

import List from "./StavL_List.vue";
import Search from "./StavL_Search.vue";
import Summary from "./StavL_Summary.vue";

export default {
  components: {
    List,
    Search,
    Summary
  },
  data: () => ({
    Info: Info,
    Model: Model,
    SearchCatalog: SearchCatalog,
    access: {},
    searchData: {
      Search: "",
      Date: null,
      ItemID: null,
      WarehouseID: null,
      // support
      ItemName: null
    },
    searchParams: ["Search", "Date", "ItemID", "WarehouseID"],
    userData: {
      checkedRows: []
    },
    warehouseOptions: {},
    // func
    showSummaryFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options);
      loadListData(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.searchData.ItemName = data.Search.ItemName;
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
    },

    handleLoad(loadList) {
      this.resetUser();

      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config);
    },

    handleReset() {
      this.resetSearch();
      this.resetUser();
    },

    /*** by summary modal ***/

    handleSummary_init(show) {
      this.showSummaryFunc = show;
    },

    /*** custom ***/

    handleSummary_click() {
      this.showSummaryFunc();
    },

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.warehouseOptions = {};

      this.resetData_search();
    },
    resetData_search() {
      if (this.searchData.WarehouseID === null) {
        this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      }
    },

    resetSearch() {
      this.searchData.Search = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.ItemID = null;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
      // support
      this.searchData.ItemName = null;
    },

    resetUser() {
      this.userData.checkedRows = [];
    },

    getListOptions() {
      return {
        warehouse: this.$kst.Out.getInteger(this.searchData.WarehouseID),
        item: this.$kst.Out.getInteger(this.searchData.ItemID),
        search: this.$kst.Out.getString(this.searchData.Search),
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        field: Model.Search.Fields
      };
    }
  }
}
</script>