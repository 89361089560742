import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  /*** related ***/

  ClientAddress: {
    Module: AppModule.ClientAddress,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  SalesSelfService: {
    Module: AppModule.SalesSelfService,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Client_Act_Details,
    Act_Edit: AppModuleAttr.Client_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Client_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Client_Act_ExportList,
    Act_PrintList: AppModuleAttr.Client_Act_PrintList,
    Act_List: AppModuleAttr.Client_Act_List,
    Act_New: AppModuleAttr.Client_Act_New,
    Sect_SelfService: AppModuleAttr.Client_Sect_SelfService,
    // related
    Rec_Act_List: AppModuleAttr.Rec_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.ExportDetails
    ]
  },

  Search: {
    Fields: ["Name", "Alias", "Mobile", "Phone", "Email"],
    ListParams: ["Search"],
    SearchListFields: ["Name", "Alias", "Mobile", "Phone", "Email"],
    SearchListAddressFields: ["Name", "Alias", "Address", "ContactMobile",
      "ContactPhone", "ContactEmail"
    ]
  },

  /*** method ***/

  createDetails() {
    return {
      ID: null,
      // user input
      Name: "",
      Alias: "",
      DaysDueDate: 0,
      Mobile: "",
      Phone: "",
      Email: "",
      Comment: "",
      DiscPercent: 0
    };
  },
  createAddress() {
    return {
      ID: null,
      // user input
      Name: "",
      Address: "",
      ContactName: "",
      ContactMobile: "",
      ContactPhone: "",
      ContactEmail: "",
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  populateDetails(userData) {
    return {
      ID: userData.ID,
      Alias: App.Out.getString(userData.Alias),
      DaysDueDate: App.Out.getInteger(userData.DaysDueDate),
      Name: App.Out.getString(userData.Name),
      Mobile: App.Out.getString(userData.Mobile),
      Phone: App.Out.getString(userData.Phone),
      Email: App.Out.getString(userData.Email),
      Comment: App.Out.getString(userData.Comment),
      DiscPercent: App.Out.getDecimal(userData.DiscPercent)
    }
  },
  populateAddress(userAddresses) {
    let resultItems = [];

    for (const userAddress of userAddresses) {
      resultItems.push({
        ID: userAddress.ID,
        Name: App.Out.getString(userAddress.Name),
        Address: App.Out.getString(userAddress.Address),
        ContactName: App.Out.getString(userAddress.ContactName),
        ContactMobile: App.Out.getString(userAddress.ContactMobile),
        ContactPhone: App.Out.getString(userAddress.ContactPhone),
        ContactEmail: App.Out.getString(userAddress.ContactEmail)
      });
    }

    return resultItems;
  }
}