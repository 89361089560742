<template>
  <div class="kst-layout has-navbar-fixed-top">
    <Nav
      :userData="userData"
      @menu="handleMenu_Open"
    />

    <div class="is-flex">
      <div class="is-hidden-touch ks-border-r-light kst-layout-sidebar kst-layout-sidebar-width">
        <div class="px-4 kst-layout-sidebar-menu">
          <kst-menu :items="menuList"/>
        </div>
        <About class="kst-layout-sidebar-width"/>
      </div>

      <kst-modal full-screen
        class="kst-layout-sidebar-modal"
        title="Menu"
        :active="menuIsActive"
        @cancel="handleMenu_Close"
      >
        <div class="ks-border-r-light kst-layout-sidebar">
          <div class="px-4 kst-layout-sidebar-menu">
            <kst-menu :items="menuList" @submit="handleMenu_Close"/>
          </div>
          <UserInfo />
          <About class="ks-is-fullwidth"/>
        </div>
      </kst-modal>

      <kst-page class="ks-max-width-100p">
        <router-view />
      </kst-page>
    </div>
  </div>
</template>

<script>
import AxiosMixin from "../../mixins/AxiosMixin.js";
import Menu from "./LayoutMenu.js";

import About from "./Layout_About.vue";
import Nav from "./Layout_Nav.vue";
import UserInfo from "./Layout_UserInfo.vue";

export default {
  mixins: [AxiosMixin],
  components: {
    About,
    Nav,
    UserInfo
  },
  data: () => ({
    userData: {
      Search: ""
    },
    menuList: Menu.getList(),
    menuIsActive: false
  }),
  methods: {
    handleMenu_Open() {
      this.menuIsActive = true;
    },
    handleMenu_Close() {
      this.menuIsActive = false;
    }
  }
}
</script>

<style>
@import "./_layout.css";
</style>