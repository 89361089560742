<template>
  <kst-table view-mode
    :action="Model.RelatedList.hasAccessAction(access)"
    :detailed="detailed"
    :data="dataList"
  >
    <template v-if="date">
      <b-table-column centered sortable
        v-slot="{ column, row }"
        cell-class="ks-col-shortdate"
        field="InvoiceDate"
        :label="Catalog.InvoiceDate.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="SONumber"
      :label="Catalog.SONumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="warehouse">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="WarehouseID"
        :label="Catalog.WarehouseID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template v-if="client">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="Client"
        :label="Catalog.ClientID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template v-if="ponumber">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="PONumber"
        :label="Catalog.PONumber.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template v-if="paymenttype">
      <b-table-column sortable
        v-slot="{ column, row }"
        field="PaymentTypeName"
        :label="Catalog.PaymentTypeName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="status">
      <b-table-column centered sortable
        v-slot="{ row }"
        cell-class="ks-col-status"
        field="Status"
        :label="Catalog.Status.Label"
      >
        <kst-status :value="row.Status" :statusEnum="Model.Status"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <kst-actions :actions="Model.RelatedList.getActions(row, access)"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items" :specialPriceCount="specialPriceCount"/>
        <Total :details="row"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";

import Items from "./InvRL_List_Items.vue";
import Total from "./InvRL_List_Total.vue";

export default {
  components: {
    Items,
    Total
  },
  props: {
    access: Object,
    detailed: Boolean,
    dataList: Array,
    specialPriceCount: Number,
    // field visibility (alphabetical order)
    client: Boolean,
    date: Boolean,
    paymenttype: Boolean,
    ponumber: Boolean,
    status: Boolean,
    warehouse: Boolean
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>