<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import DaiSummaryCatalog from "../DaiSummaryCatalog.js";
import DaiItemCatalog from "../DaiItemCatalog.js";
import Info from "../DaiInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    searchData: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List, data.Summary);

      this.download(fileName);
    },
    
    addList(dataList, dataSummary) {
      this.addList_list(dataList);
      this.addList_total(dataSummary);

      this.addSheet(Info.Export.Excel.SheetName.Report);
    },
    addList_list(dataList) {
      const isAllWarehouse =
        this.$kst.Search.getValue(this.searchData.WarehouseID, null) === null;

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(DaiItemCatalog.ID.Label);
      this.addColumnHeader(DaiItemCatalog.ID);
      this.addColumnHeader(DaiItemCatalog.QtyStock);
      this.addColumnHeader(DaiItemCatalog.QtyReceive);
      this.addColumnHeader(DaiItemCatalog.QtyRtnReceive);
      if (!isAllWarehouse) {
        this.addColumnHeader(DaiItemCatalog.QtyStockTrf);
      }
      this.addColumnHeader(DaiItemCatalog.QtyStockOpname);
      this.addColumnHeader(DaiItemCatalog.QtyAdjustment);
      this.addColumnHeader(DaiItemCatalog.QtyStockConv);
      this.addColumnHeader(DaiItemCatalog.QtyInvoice);
      this.addColumnHeader(DaiItemCatalog.QtyRtnInvoice);
      this.addColumnHeader(DaiItemCatalog.QtyLast);
      if (!isAllWarehouse) {
        this.addColumnHeader(DaiItemCatalog.QtyStockClosing);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("ID", row, DaiItemCatalog);
        this.addCellRelatedValue("ID", row, DaiItemCatalog);
        this.addCellValue("QtyStock", row, DaiItemCatalog);
        this.addCellValue("QtyReceive", row, DaiItemCatalog);
        this.addCellValue("QtyRtnReceive", row, DaiItemCatalog);
        if (!isAllWarehouse) {
          this.addCellValue("QtyStockTrf", row, DaiItemCatalog);
        }
        this.addCellValue("QtyStockOpname", row, DaiItemCatalog);
        this.addCellValue("QtyAdjustment", row, DaiItemCatalog);
        this.addCellValue("QtyStockConv", row, DaiItemCatalog);
        this.addCellValue("QtyInvoice", row, DaiItemCatalog);
        this.addCellValue("QtyRtnInvoice", row, DaiItemCatalog);
        this.addCellValue("QtyLast", row, DaiItemCatalog);
        if (!isAllWarehouse) {
          this.addCellValue("QtyStockClosing", row, DaiItemCatalog);
        }
      }
    },
    addList_total(dataSummary) {
      const isAllWarehouse =
        this.$kst.Search.getValue(this.searchData.WarehouseID, null) === null;

      this.addRow();
      this.addColumnHeader(Info.Summary.Total);
      this.addCell("");
      this.addCell("");
      this.addCellValue("QtyStock", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyReceive", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyRtnReceive", dataSummary, DaiSummaryCatalog);
      if (!isAllWarehouse) {
        this.addCellValue("QtyStockTrf", dataSummary, DaiSummaryCatalog);
      }
      this.addCellValue("QtyStockOpname", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyAdjustment", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyStockConv", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyInvoice", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyRtnInvoice", dataSummary, DaiSummaryCatalog);
      this.addCellValue("QtyLast", dataSummary, DaiSummaryCatalog);
      if (!isAllWarehouse) {
        this.addCellValue("QtyStockClosing", dataSummary, DaiSummaryCatalog);
      }
    }
  }
}
</script>