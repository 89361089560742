<template>
  <div>
    <div class="columns my-0 is-multiline">
      <div class="column is-full">
        <Summary :summaryData="summaryData"/>
      </div>
      <div class="column is-half">
        <InvoiceSummary :invoiceSummaryList="invoiceSummaryList"/>
      </div>
      <div class="column is-half">
        <PosSummary :posSummaryList="posSummaryList"/>
      </div>
    </div>

    <kst-section>
      <form novalidate @submit.prevent="debounceSearch">
        <div class="columns is-mobile">
          <div class="column is-half pt-0">
            <kst-input field="ValueAvg" :data="chartData" :catalog="OmzChartCatalog"
              @input="handleInput"
            />
          </div>
        </div>

        <div class="is-hidden">
          <input type="submit" value="Cari" class="is-hidden">
        </div>
      </form>

      <template v-if="chartEnabled">
        <Chart
          :chartData="chartData"
          :searchData="searchData"
        />
      </template>
    </kst-section>
  </div>
</template>

<script>
import SearchMixin from "../../../mixins/SearchMixin.js";
import OmzChartCatalog from "../OmzChartCatalog.js";

import Chart from "./OmzR_Result_Chart.vue";
import InvoiceSummary from "./OmzR_Result_InvoiceSummary.vue";
import PosSummary from "./OmzR_Result_PosSummary.vue";
import Summary from "./OmzR_Result_Summary.vue";

export default {
  mixins: [SearchMixin],
  components: {
    Chart,
    InvoiceSummary,
    PosSummary,
    Summary
  },
  props: {
    chartData: Object,
    invoiceSummaryList: Array,
    posSummaryList: Array,
    summaryData: Object,
    searchData: Object
  },
  data: () => ({
    OmzChartCatalog: OmzChartCatalog,
    chartEnabled: true
  }),
  created() {
    this.initSearch(this.chartData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.handleValueAvg();
    },

    handleValueAvg() {
      if (this.chartCanvas !== null) {
        this.chartEnabled = false;

        this.$nextTick(() => {
          this.chartEnabled = true;
        });
      }
    }
  }
}
</script>