import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Dashboard,
  ModuleType: {
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Dashboard: AppModuleAttr.Dashboard_Act_Dashboard,
    Sect_BestSellingItems: AppModuleAttr.Dashboard_Sect_BestSellingItems,
    Sect_DeathStock: AppModuleAttr.Dashboard_Sect_DeathStock,
    Sect_Debt: AppModuleAttr.Dashboard_Sect_Debt,
    Sect_DebtOverdue: AppModuleAttr.Dashboard_Sect_DebtOverdue,
    Sect_Invoice: AppModuleAttr.Dashboard_Sect_Invoice,
    Sect_LowStock: AppModuleAttr.Dashboard_Sect_LowStock,
    Sect_ProfitLoss: AppModuleAttr.Dashboard_Sect_ProfitLoss,
    Sect_Rec: AppModuleAttr.Dashboard_Sect_Rec,
    Sect_RecOverdue: AppModuleAttr.Dashboard_Sect_RecOverdue,
    Sect_Stock: AppModuleAttr.Dashboard_Sect_Stock,
    Sect_StockWithoutInvoice: AppModuleAttr.Dashboard_Sect_StockWithoutInvoice,
    Sect_TopBuyer: AppModuleAttr.Dashboard_Sect_TopBuyer,
    // related
    DailyReport_Act_Report: AppModuleAttr.DailyReport_Act_Report,
    Debt_Act_List: AppModuleAttr.Debt_Act_List,
    Invoice_Act_List: AppModuleAttr.Invoice_Act_List,
    Item_Stock_Act_List: AppModuleAttr.Item_Stock_Act_List,
    LowStock_Act_List: AppModuleAttr.LowStock_Act_List,
    ProfitLoss_Act_Report: AppModuleAttr.ProfitLoss_Act_Report,
    Rec_Act_List: AppModuleAttr.Rec_Act_List,
    RtnInvoice_Act_List: AppModuleAttr.RtnInvoice_Act_List
  },

  UrlParams: ["Date", "WarehouseID"],

  /*** method ***/

  createSummaryRow(hasAccess, moduleName, module, hppTotal, search, params) {
    return {
      HasAccess: hasAccess,
      Label: moduleName,
      Module: module,
      HppTotal: hppTotal,
      Search: search,
      Params: params
    };
  }
}