<template>
  <div class="kst-form">
    <template v-if="headerIsActive">
      <nav class="level mb-0" :class="theHeaderClass">
        <div class="level-left">
          <div>
            <kst-title>{{ title }}</kst-title>
            <kst-subtitle>{{ subtitle }}</kst-subtitle>
          </div>
        </div>
        <div v-if="slotHasContent('top-right')" class="level-right">
          <template v-if="hasAccess !== false">
            <template v-if="!failureInit">
              <template v-if="!loading">
                <template v-if="!warnings">
                  <slot name="top-right"/>
                </template>
              </template>
            </template>
          </template>
        </div>
      </nav>
    </template>

    <template v-if="slotHasContent('default')">
      <div class="kst-form-content" :class="theBodyClass">
        <kst-skeleton :active="loading"/>

        <template v-if="hasAccess === false">
          <kst-failure access-mode/>
        </template>
        <template v-else>
          <template v-if="!failureInit">
            <template v-if="!hideBody">
              <template v-if="!loading">
                <slot />
              </template>
            </template>
          </template>
        </template>

        <slot name="failure">
          <kst-failure init-mode :items="failureInit"/>
          <kst-failure save-mode :items="failure"/>
        </slot>

        <slot name="warnings">
          <kst-warning :class="warningsClass" :items="warnings"/>
        </slot>

        <slot name="errors">
          <kst-errors :class="errorsClass" :items="errors"/>
        </slot>
      </div>
    </template>

    <template v-if="hasAccess !== false">
      <template v-if="!failureInit">
        <template v-if="!hideFooter">
          <template v-if="footerIsActive">
            <template v-if="!loading">
              <nav class="level ks-border-t-light kst-form-footer" :class="theFooterClass">
                <div
                  class="level-left"
                  :class="slotHasContent('bottom-left') ? '' : 'ks-no-content'"
                >
                  <slot name="bottom-left"/>
                </div>
                <div class="level-right">
                  <div class="buttons">
                    <template v-if="buttonCancelIsActive">
                      <kst-button cancel-mode :disabled="cancelDisabled" :to="cancelUrl"/>
                    </template>
                    <template v-if="buttonOkIsActive">
                      <kst-button submit-mode :disabled="okDisabled" @click="handleOkClick"/>
                    </template>
                  </div>
                </div>
              </nav>
            </template>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import SlotMixins from "@/mixins/SlotMixins.js";

export default {
  mixins: [SlotMixins],
  props: {
    errors: [Array, String],
    failure: [Array, String],
    failureInit: [Array, String],
    hasAccess: {
      type: Boolean,
      default: null
    },
    loading: Boolean,
    noBezel: Boolean,
    title: String,
    subtitle: String,
    warnings: Array,
    // section
    hideBody: Boolean,
    hideFooter: Boolean,
    // button: ok
    okDisabled: Boolean,
    submit: Boolean,
    // button: cancel
    cancelDisabled: Boolean,
    cancelUrl: String
  },
  computed: {
    errorsClass() {
      return (this.hideBody ? "" : "pt-4");
    },
    headerIsActive() {
      if (this.title) {
        return true;
      }
      else if (this.slotHasContent("top-right")) {
        return true;
      }
      return false;
    },
    footerIsActive() {
      if (this.getOkIsActive()) {
        return true;
      }
      else if (this.getCancelIsActive()) {
        return true;
      }
      else if (this.slotHasContent("bottom-left")) {
        return true;
      }
      return false;
    },
    buttonOkIsActive() {
      return this.getOkIsActive();
    },
    buttonCancelIsActive() {
      return this.getCancelIsActive();
    },
    theHeaderClass() {
      let className = "";

      if (!this.noBezel) {
        className += "p-5 ";
      }

      if (this.slotHasContent("default")) {
        className += "ks-border-b-light ";
      }

      return className;
    },
    theBodyClass() {
      let className = "";

      if (!this.noBezel) {
        className += "p-5 ";
      }

      return className;
    },
    theFooterClass() {
      let className = "";

      if (!this.noBezel) {
        className += "p-5 ";
      }

      return className;
    },
    warningsClass() {
      return (this.hideBody ? "" : "pt-4");
    }
  },
  methods: {
    handleOkClick(event) {
      this.$emit(this.$kst.Enum.Event.Submit, event);
    },

    getOkIsActive() {
      if (this.submit) {
        return true;
      }

      return false;
    },

    getCancelIsActive() {
      if (this.cancelUrl) {
        return true;
      }
      else if (this.cancelUrl !== undefined) {
        return true;
      }

      return false;
    }
  }
}
</script>