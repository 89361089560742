<template>
  <table class="ks-is-fullwidth">
    <tr>
      <td>
        <kst-value class="print-thermal-size ks-word-break" field="ClientName" 
          :data="details" :catalog="Catalog"
        />
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="ClientMobile" 
          :data="details" :catalog="Catalog"
        />
      </td>
    </tr>
    <tr>
      <td>
        <kst-value class="print-thermal-size ks-word-break" field="DraftNumber" 
          :data="details" :catalog="Catalog"
        />
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="DeliveryDate" 
          :data="details" :catalog="Catalog"
        />
      </td>
    </tr>
    <tr>
      <td>
        <kst-value class="print-thermal-size" field="WarehouseID" 
          :data="details" :catalog="Catalog"
        />
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="CreatedByID" 
          :data="details" :catalog="Catalog"
        />
      </td>
    </tr>
  </table>
</template>

<script>
import Catalog from "../SlsCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>