import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  DiscType: {
    None: {
      ID: 0,
      Label: AppCatalog.Message.Without + " " + AppCatalog.Field.Disc.Label
    },
    Value: {
      ID: 1,
      Label: AppCatalog.Field.DiscValue.Label
    },
    Percent: {
      ID: 2,
      Label: AppCatalog.Field.DiscPercent.Label
    }
  }
}