import AppCatalog from "./AppCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  }
}