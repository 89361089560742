import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Omzet,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.Omzet_Act_Export,
    Act_Print: AppModuleAttr.Omzet_Act_Print,
    Act_Report: AppModuleAttr.Omzet_Act_Report,
    // related
    Invoice_Act_List: AppModuleAttr.Invoice_Act_List,
    RtnInvoice_Act_List: AppModuleAttr.RtnInvoice_Act_List
  },

  Search: {
    ListParams: ["Date", "WarehouseID"]
  },

  /*** method ***/

  createChart() {
    return {
      LabelList: [],
      ValueAvg: 0,
      ValueList: []
    };
  },
  createSummary() {
    return {
      InvoiceSummary: [],
      Total: 0,
      TransactionList: []
    };
  },

  getChartXLabel(searchData) {
    const year1 = searchData.Date[0].getFullYear();
    const year2 = searchData.Date[1].getFullYear();

    if (year1 === year2) {
      const month1 = searchData.Date[0].getMonth();
      const month2 = searchData.Date[1].getMonth();

      if (month1 === month2) {
        return AppCatalog.MonthLongNames[month1] + " " + year1;
      }
      else {
        return AppCatalog.MonthLongNames[month1] + " - " +
        AppCatalog.MonthLongNames[month2] + " " + year1;
      }
    }
    else {
      const month1 = searchData.Date[0].getMonth();
      const month2 = searchData.Date[1].getMonth();

      return AppCatalog.MonthLongNames[month1] + " " + year1 + " - " +
        AppCatalog.MonthLongNames[month2] + " " + year2;
    }
  },

  getTransactionSummary(transactionDetails) {
    let addTotal, subTotal, row;
    let summaryObj = {};

    // Invoice
    addTotal = 0;
    subTotal = null;
    for (row of transactionDetails.InvoiceList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    summaryObj[AppModule.Invoice.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // Pos
    addTotal = 0;
    subTotal = null;
    for (row of transactionDetails.PosList) {
      addTotal += row.Total;
    }
    addTotal = App.Data.getFixedDecimal(addTotal);

    summaryObj[AppModule.Pos.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    // RtnInvoice
    addTotal = null;
    subTotal = 0;
    for (row of transactionDetails.RtnInvoiceList) {
      subTotal += row.Total;
    }
    subTotal = App.Data.getFixedDecimal(subTotal);

    summaryObj[AppModule.RtnInvoice.Code] = {
      AddTotal: addTotal,
      SubTotal: subTotal
    };

    return summaryObj;
  }
}