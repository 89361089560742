<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../DbpyCatalog.js";
import DbpyRcvCatalog from "../DbpyRcvCatalog.js";
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.PayNumber);
      this.addColumnHeader(Catalog.Date);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.VendorID.Label);
      this.addColumnHeader(Catalog.VendorID);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.PaymentValue);
      this.addColumnHeader(Catalog.StatusID);
      this.addColumnHeader(Catalog.VoidByID);
      this.addColumnHeader(Catalog.VoidDate);
      this.addColumnHeader(Catalog.VoidComment);
      this.addColumnHeader(Catalog.Comment);

      if (this.userData.WithReceiveList) {
        this.addColumnHeader_ID(DbpyRcvCatalog.ReceiveID.Label);
        this.addColumnHeader(DbpyRcvCatalog.ReceiveID);
        this.addColumnHeader(DbpyRcvCatalog.ReceiveDate);
        this.addColumnHeader_ID(DbpyRcvCatalog.ReceiveWarehouseID.Label);
        this.addColumnHeader(DbpyRcvCatalog.ReceiveWarehouseID);
        this.addColumnHeader_ID(DbpyRcvCatalog.ReceiveVendorID.Label);
        this.addColumnHeader(DbpyRcvCatalog.ReceiveVendorID);
        this.addColumnHeader(DbpyRcvCatalog.ReceivePaymentValue);
      }

      // value
      for (const row of dataList) {
        this.addRow();
        this.addCellValue_ID(row);
        this.addCellValue("PayNumber", row, Catalog);
        this.addCellValue("Date", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("VendorID", row, Catalog);
        this.addCellRelatedValue("VendorID", row, Catalog);
        this.addCellValue("PaymentTypeName", row, Catalog);
        this.addCellValue("PaymentValue", row, Catalog);
        this.addCellStatus(row.StatusID, Model.StatusID);
        this.addCellRelatedValue("VoidByID", row, Catalog);
        this.addCellValue("VoidDate", row, Catalog);
        this.addCellValue("VoidComment", row, Catalog);
        this.addCellValue("Comment", row, Catalog);

        if (this.userData.WithReceiveList) {
          this.addCellValue("ReceiveID", row, DbpyRcvCatalog);
          this.addCellRelatedValue("ReceiveID", row, DbpyRcvCatalog);
          this.addCellValue("ReceiveDate", row, DbpyRcvCatalog);
          this.addCellValue("ReceiveWarehouseID", row, DbpyRcvCatalog);
          this.addCellRelatedValue("ReceiveWarehouseID", row, DbpyRcvCatalog);
          this.addCellValue("ReceiveVendorID", row, DbpyRcvCatalog);
          this.addCellRelatedValue("ReceiveVendorID", row, DbpyRcvCatalog);
          this.addCellValue("ReceivePaymentValue", row, DbpyRcvCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>