<template>
  <kst-page-export
    :catalog="InvSearchCatalog"
    :params="Model.Search.ListParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :access="access"
      :userData="userData"
      @init="handleExport_init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check 
      v-model="userData.WithItems"
      :label="Info.Export.List.Excel.Option.InvoiceItem"
    />
  </kst-page-export>
</template>

<script>
import Info from "../InvInfo.js";
import InvSearchCatalog from "../InvSearchCatalog.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Export from "./InvExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    InvSearchCatalog: InvSearchCatalog,
    Model: Model,
    access: {},
    searchData: {
      Search: "",
      Client: "",
      ClientID: null, 
      InvoiceDate: null,
      Status: null,
      WarehouseID: null,
      RecordTypeID: null
    },
    userData: {
      WithList: true,
      WithItems: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_success);
    },
    handleExport_success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** by export ***/

    handleExport_init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);

      this.userData.WithList = true;
      this.userData.WithItems = true;
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const client = clientId === null
        ? this.$kst.Out.getString(this.searchData.Client) : null;

      return {
        client: clientId,
        clientname: client,
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.InvoiceDate[1]),
        status: this.$kst.Search.getValue(this.searchData.Status),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.ListFields,
        withitems: this.$kst.Out.getBoolean(this.userData.WithItems),
        ispos: this.$kst.Search.getValue(this.searchData.RecordTypeID)
      };
    }
  }
}
</script>