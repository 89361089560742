import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./RrcvModel_Body.js";
import RrcvItemCatalog from "./RrcvItemCatalog.js";

export default {
  setDetailsByData(userData, detailsData) {
    userData.ID = detailsData.ID;
    // user input
    userData.WarehouseID = detailsData.WarehouseID;
    userData.ReturnNumber = App.In.getString(detailsData.ReturnNumber);
    userData.ReceiveID = detailsData.ReceiveID;
    userData.Comment = App.In.getString(detailsData.Comment);
    userData.IsAutoNumber = false;
    // defined by system
    userData.ReceiveNumber = detailsData.ReceiveNumber;
    userData.VendorID = detailsData.VendorID;
    userData.VendorName = detailsData.VendorName;
    userData.VendorAlias = detailsData.VendorAlias;
    userData.VendorAddress = detailsData.VendorAddress;
    userData.Date = App.In.getDate(detailsData.Date);
    userData.ReceiveDate = detailsData.ReceiveDate;
  },
  setItemsByData(items, returnItems) {
    App.Array.truncate(items);

    for (const returnItem of returnItems) {
      let item = ModelBody.createItem();
      this.setItemsByData_Item(item, returnItem);
      ModelBody.updateItem(item);
      items.push(item);
    }
  },
  setItemsByData_Item(item, returnItem) {
    item.ID = returnItem.ID;
    // user input
    item.DispatchID = returnItem.DispatchID;
    item.Qty = App.In.getInteger(returnItem.Qty);
    // defined by system
    item.DispatchName = App.In.getString(returnItem.DispatchName);
    item.PackagingName = returnItem.PackagingName;
    item.PackagingValue = returnItem.PackagingValue;
    item.BuyPrice = returnItem.BuyPrice;
    item.SKU = returnItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(returnItem.DispatchIsActive);
    item.ReceiveItemID = returnItem.ReceiveItemID;
    // UI validation
    item.ReceiveItemQty = returnItem.ReceiveItemQty;
  },
  setItemByReceiveItem(item, receiveItem) {
    // user input
    item.DispatchID = receiveItem.DispatchID;
    item.Qty = App.In.getInteger(
      receiveItem.QtyReturn === null
        ? receiveItem.QtyAvailable : receiveItem.QtyReturn
    );
    // defined by system
    item.BuyPrice = App.In.getInteger(receiveItem.BuyPrice);
    item.DispatchName = receiveItem.Name;
    item.PackagingName = receiveItem.PackagingName;
    item.PackagingValue = receiveItem.PackagingValue;
    item.SKU = receiveItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(receiveItem.DispatchIsActive);
    item.ReceiveItemQty = receiveItem.QtyAvailable;
    item.ReceiveItemID = receiveItem.ID;
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    if (item.DispatchIsActive) {
      // SKU
      infoList.push(RrcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RrcvItemCatalog)
      );
    }

    return infoList;
  },

  updateReceiveItems(receiveItem, returnItems) {
    for (const returnItem of returnItems) {
      receiveItem.QtyReturn = null;

      if (returnItem.ReceiveItemID === receiveItem.ID) {
        receiveItem.QtyAvailable += returnItem.Qty;
        receiveItem.QtyReturn = returnItem.Qty;

        returnItem.Index = receiveItem.Index;
        returnItem.ReceiveItemQty = receiveItem.QtyAvailable;
        return;
      }
    }
  },

  compareData(userData, userItems, rtnReceiveData) {
    const data = ModelBody.populateDetails(userData);
    const items = ModelBody.populateItems(userItems);
    let fields;

    /*** compare: details ***/

    // fields: based on populateDetails
    fields = [
      "ID", "WarehouseID", "ReturnNumber", "ReceiveID", "Comment", "ReceiveNumber", 
      "Date", "VendorID", "VendorName", "VendorAddress"
    ];
    if (!App.Compare.validateObject(data, rtnReceiveData, fields)) {
      return false;
    }

    /*** compare: items ***/

    // fields: based on populateItems
    fields = [
      "ID", "ReceiveItemID", "DispatchID", "Qty",
      "BuyPrice", "DispatchName", "PackagingName", "PackagingValue"
    ];
    if (!App.Compare.validateArray(items, rtnReceiveData.Items, fields)) {
      return false;
    }

    return true;
  }
}