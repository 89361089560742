<template>
  <kst-tooltip
    v-if="row.BuyPricePcs !== buyPrice"
    position="is-left"
    size="is-medium"
    :type="theType"
  >
    <template #content>
      {{ theText }}
    </template>

    <span class="icon" :class="theIconClass">
      <i :class="theIcon"/>
    </span>
  </kst-tooltip>
</template>

<script>
import Info from "../RcvInfo.js";

export default {
  props: {
    row: Object,
    buyPrice: Number
  },
  computed: {
    buyPriceType() {
      return this.row.BuyPricePcs > this.buyPrice ? "High" : "Low";
    },
    theIcon() {
      return Info.PriceSearch.BuyPrice[this.buyPriceType].Icon;
    },
    theIconClass() {
      return Info.PriceSearch.BuyPrice[this.buyPriceType].IconClass;
    },
    theText() {
      return Info.PriceSearch.BuyPrice[this.buyPriceType].TooltipText;
    },
    theType() {
      return Info.PriceSearch.BuyPrice[this.buyPriceType].TooltipClass;
    }
  }
}
</script>