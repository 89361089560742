<template>
  <div>
    <div class="columns">
      <div class="column is-half">
        <PaymentTypeSummaryList
          :paymentTypeSummaryList="paymentTypeSummaryList"
        />
      </div>
      <div class="column is-half">
        <Details :details="details"/>
      </div>
    </div>

    <InvoiceRelatedList client paymenttype
      :dataList="invoiceList"
      :paymentTypeSummaryList="paymentTypeSummaryList"
    />
  </div>
</template>

<script>
import Details from "./SlrcD_InvList_Details.vue";
import PaymentTypeSummaryList from "./SlrcD_InvList_PaytSummaryList.vue";
import InvoiceRelatedList from "../../Invoice/RelatedList/InvRL.vue";

export default {
  components: {
    Details,
    PaymentTypeSummaryList,
    InvoiceRelatedList
  },
  props: {
    details: Object,
    invoiceList: Array,
    paymentTypeSummaryList: Array
  }
}
</script>