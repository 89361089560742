import AppCatalog from "../../services/App/AppCatalog.js";
import Model from "./SalesModel.js";

export default {
  Message: {
    New_ConfirmDeleteItem:
      AppCatalog.Info.AreYouSureToDelete + " " +
        Model.SalesItem.Module.FullName + "?"
  },
  Print: {
    Details: {
      SubTitle: AppCatalog.Info.SalesSelfService
    }
  }
}