import ModelBody from "./StcdModel_Body.js";
import ModelExport from "./StcdModel_Export.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,

  UrlParams: ModelBody.UrlParams,

  /*** method ***/

  getStockSummaryList(stockList) {
    return ModelBody.getStockSummaryList(stockList);
  },
  getTransactionGroupObj(transList) {
    return ModelBody.getTransactionGroupObj(transList);
  },
  getTransactionActivityTypeName(trans) {
    return ModelBody.getTransactionActivityTypeName(trans);
  },
  getTransactionHasAccess(trans, access) {
    return ModelBody.getTransactionHasAccess(trans, access);
  },
  getTransactionModule(trans) {
    return ModelBody.getTransactionModule(trans);
  },
  getTransactionUrlParams(trans) {
    return ModelBody.getTransactionUrlParams(trans);
  },
  getTransactionSummaryList(transList) {
    return ModelBody.getTransactionSummaryList(transList);
  },

  /*** method - Export ***/

  Export: {
    getTransactionGroupObj(transList) {
      return ModelExport.getTransactionGroupObj(transList);
    },
    getTransactionSummaryList(transSummaryList) {
      return ModelExport.getTransactionSummaryList(transSummaryList);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },
}