<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <ItemSearchList
      @init="handleItem_Init"
      @submit="handleItem_Submit"
    />

    <b-tabs v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :data="data"/>
        <Delivery :data="data"/>
        <Items
          :access="access" 
          :data="data" 
          :items="data.Items"
          @newitem="handleNewItem"
        />
        <template v-if="access.Fld_Amount">
          <Total :data="data"/>
        </template>
      </b-tab-item>
      <template v-if="access.Receive_Act_List">
        <template v-if="receiveList">
          <b-tab-item :label="tabs.ReceiveList">
            <ReceiveRelatedList detailed
              :items="receiveList"
              :purchaseId="data.ID"
            />
          </b-tab-item>
        </template>
      </template>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../PurInfo.js";
import Model from "../PurModel.js";
import Services from "../../../services/Api/PurchaseServices.js";

import Details from "./PurD_Details.vue";
import Delivery from "./PurD_Delivery.vue";
import Items from "./PurD_Items.vue";
import Total from "./PurD_Total.vue";
import ItemSearchList from "../../Item/SearchListNewItem/ItemScLNewItem.vue";
import ReceiveRelatedList from "../../Receive/RelatedList/RcvRL.vue";

export default {
  components: {
    Details,
    Delivery,
    Items,
    Total,
    ItemSearchList,
    ReceiveRelatedList
  },
  data: () => ({
    access: {},
    actions: [],
    data: {},
    receiveList: [],
    // func
    reloadFunc: null,
    showItemFunc: null,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      ReceiveList: Info.Tabs.ReceiveList
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails, reload) {
      this.resetData(id);
      this.reloadFunc = reload;

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.data = data.Details;
      for (const item of this.data.Items) {
        Model.updateItemReadOnly(item);
      }
      Model.setDetailsByStatus(this.data);

      this.receiveList = data.ReceiveList;
    },

    /*** by item modal ***/

    handleItem_Init(show) {
      this.showItemFunc = show;
    },
    handleItem_Submit() {
      this.reloadFunc();
    },

    /*** custom ***/

    handleNewItem(purchaseItem) {
      this.showItemFunc(
        purchaseItem.ID,
        purchaseItem.Name,
        Services.getRelatedItemList,
        Services.putPurchaseItem
      );
    },

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.data = {};
      this.receiveList = [];

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>