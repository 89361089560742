<template>
  <div>
    <EditSellPrice
      :userData="userData"
      :userItems="userItems"
      :itemIndex="itemIndex"
      @init="handleEditSellPrice_init"
    />
    <VerifySpvPass
      @init="handleVerifySpvPass_init"
      @submit="handleVerifySpvPass_submit"
    />

    <kst-table action input-mode :data="userItems">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="PosItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only box-off
          :field="column.field" :data="row" :catalog="PosItemCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row, index }"
        cell-class="ks-qty kstmod-pos-new__cart_qty"
        field="RequestedQuantity"
        :label="PosItemCatalog.RequestedQuantity.Label"
      >
        <kst-input field-only
          :field="column.field" :data="row" :catalog="PosItemCatalog"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="SellPrice"
        :label="PosItemCatalog.SellPrice.Label"
      >
        <template v-if="row.SpecialPriceID === null">
          <kst-output field-only
            box-off :field="column.field" :data="row" :catalog="PosItemCatalog"
          />
        </template>
        <template v-else>
          <div class="has-text-danger">
            <kst-output field-only box-off
              :field="column.field" :data="row" :catalog="PosItemCatalog"
            />
          </div>
          <div class="has-text-grey ks-text-line-through">
            <kst-value class="is-size-7"
              field="DispatchSellPrice" :data="row" :catalog="PosItemCatalog"
            />
          </div>
        </template>
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ index }">
          <kst-tooltip edit-mode hover-only :content="PosItemCatalog.SellPrice.Label">
            <kst-button edit-mode is-round @click="handleEdit_sellPrice(index)"/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="Price"
        :label="PosItemCatalog.Price.Label"
      >
        <kst-output field-only box-off
          :field="column.field" :data="row" :catalog="PosItemCatalog"
        />
      </b-table-column>

      <template #action="{ index }">
        <kst-tooltip delete-mode hover-only :content="Model.Item.Module.FullName">
          <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
        </kst-tooltip>
      </template>

      <template #empty-body>
        <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
          <kst-error :array-blank-mode="!!errors"/>
        </ValidationProvider>
      </template>
    </kst-table>
  </div>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../PosInfo.js";
import Model from "../PosModel.js";
import PosItemCatalog from "../PosItemCatalog.js";
import Services from "../../../services/Api/PosServices.js";

import EditSellPrice from "./PosN_Cart_EditSellPrice.vue";
import VerifySpvPass from "../../Company/VerifySpvPass/CpVerifySpvPass.vue";

export default {
  mixins: [DialogMixin],
  components: {
    EditSellPrice,
    VerifySpvPass
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    PosItemCatalog: PosItemCatalog,
    Model: Model,
    itemIndex: null,
    // func
    showEditSellPriceFunc: null,
    showVerifySpvPassFunc: null
  }),
  methods: {
    /*** by edit sell price modal ***/

    handleEditSellPrice_init(show) {
      this.showEditSellPriceFunc = show;
    },

    /*** by verify spv pass modal ***/

    handleVerifySpvPass_init(show) {
      this.showVerifySpvPassFunc = show;
    },
    handleVerifySpvPass_submit() {
      const item = this.userItems[this.itemIndex];
      this.showEditSellPriceFunc(item.SellPrice);
    },

    /*** custom ***/

    handleQuantity(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },
    handleEdit_sellPrice(index) {
      this.itemIndex = index;
      this.showVerifySpvPassFunc(Services.postRelatedVerifySpvPass);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },
    handleRowDelete_confirm(index) {
      this.userItems.splice(index, 1);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    }
  }
}
</script>