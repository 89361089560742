import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InvItemCatalog from "./InvItemCatalog.js";
import RecPayEnum from "../RecPay/RcpyEnum.js";
import PaymentTypeEnum from "../PaymentType/PaytEnum.js";

export default {
  setDetailsByProfit(details, rtnInvoiceList) {
    this.setDetailsByProfit_setItems(details.Items, details);

    // set: TotalReturn, HppTotalReturn
    details.TotalReturn = 0;
    details.HppTotalReturn = 0;
    for (let row of rtnInvoiceList) {
      details.TotalReturn += row.Total;
      details.HppTotalReturn += row.HppTotal;
    }

    details.HppTotalNetto = details.HppTotal - details.HppTotalReturn;
    details.TotalNetto = details.Total - details.TotalReturn;
    details.Profit = details.TotalNetto - details.HppTotalNetto;
  },
  setDetailsByProfit_setItems(items, details) {
    let index, item, totalDisc;
    details.HppTotal = 0;

    totalDisc = details.TotalDisc;
    for (index = 0; index < items.length - 1; index++) {
      item = items[index];
      item.HppTotal = item.HppTotal ? item.HppTotal : 0;

      if (details.DiscPercent !== null) {
        item.ParentDisc = App.Data.getDiscPercent_Value(
          item.Price, details.DiscPercent
        );
      }
      else if (details.DiscValue !== null) {
        item.ParentDisc = item.Price === 0
          ? 0
          : App.Data.getValueProrate(
              details.DiscValue, item.Price, details.TotalBruto
            );
      }
      else {
        item.ParentDisc = 0;
      }

      item.TotalNetto = item.Price - item.ParentDisc;
      item.Profit = item.TotalNetto - item.HppTotal;
      item.ProfitMargin = item.TotalNetto === 0
        ? item.Profit === 0 ? 0 :
          item.Profit > 0
            ? AppCatalog.PercentDec100
            : -1 * AppCatalog.PercentDec100
        : App.Data.getFixedDecimal(
          item.Profit / item.TotalNetto * AppCatalog.PercentDec100
        );

      totalDisc -= item.ParentDisc;
      details.HppTotal += item.HppTotal;
    }

    // special for last item: use available total disc
    item = items[items.length - 1];
    item.HppTotal = item.HppTotal ? item.HppTotal : 0;
    item.ParentDisc = totalDisc;
    item.TotalNetto = item.Price - item.ParentDisc;
    item.Profit = item.TotalNetto - item.HppTotal;
    item.ProfitMargin = item.TotalNetto === 0
      ? item.Profit === 0 ? 0 :
        item.Profit > 0
          ? AppCatalog.PercentDec100
          : -1 * AppCatalog.PercentDec100
      : App.Data.getFixedDecimal(
        item.Profit / item.TotalNetto * AppCatalog.PercentDec100
      );

    details.HppTotal += item.HppTotal;
  },

  getItemInfo(details, item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(InvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvItemCatalog)
      );
    }

    if (item.DispatchSellPrice !== null) {
      // DispatchSellPrice
      infoList.push(InvItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, InvItemCatalog)
      );
    }

    // Sales
    if (item.SalesDraftItemID && details.SalesDraftIsActive) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Sales.FullName
      );

      // SalesDraftItemQty
      infoList.push(InvItemCatalog.SalesDraftItemQty.Label + ": " +
        App.Value.getValue("SalesDraftItemQty", item, InvItemCatalog)
      );
    }

    return infoList;
  },
  getItemsSummaryByDelivery(details, deliveryList) {
    let row, pivotItem, conditions, deliveryItem;
    let fieldKeys, fieldValues;

    let deliveryItemList = [];
    for (row of deliveryList) {
      deliveryItemList = deliveryItemList.concat(row.Items);
    }

    // summary delivery items
    fieldKeys = [ "DispatchID", "Name", "PackagingName" ];
    fieldValues = [
      { field: "Quantity", type: App.Pivot.Type.SUM }
    ];
    const pivotDeliveryItems = App.Pivot.create(
      deliveryItemList, fieldKeys, fieldValues
    );

    // summary items
    fieldKeys = [ "DispatchID", "Name", "PackagingName" ];
    fieldValues = [
      { field: "PackagingValue", type: App.Pivot.Type.FIRST },
      { field: "RequestedQuantity", type: App.Pivot.Type.SUM }
    ];
    const pivotItems = App.Pivot.create(details.Items, fieldKeys, fieldValues);

    for (pivotItem of pivotItems) {
      conditions = {
        DispatchID: pivotItem.DispatchID,
        Name: pivotItem.Name,
        PackagingName: pivotItem.PackagingName
      }
      deliveryItem = App.Array.getItem2(pivotDeliveryItems, conditions);

      pivotItem.DeliveryQuantity = deliveryItem ? deliveryItem.Quantity : 0;
      pivotItem.Packaging = App.Data.getPackaging(
        pivotItem.PackagingName, pivotItem.PackagingValue
      );
    }

    return pivotItems;
  },

  updateRecData(recData, invoiceData) {
    const creditNoteLabel = PaymentTypeEnum.PaymentTypeID.CreditNote.Label;

    recData.InvoiceCreditAmount = invoiceData.CreditAmount;
    recData.InvoiceCreditAmountTrans = invoiceData.CreditAmount;
    recData.InvoiceRecTotal = invoiceData.RecTotal;
    recData.InvoiceDueDate = invoiceData.DueDate;
    recData.InvoiceTotal = invoiceData.Total;
    recData.RecPayTotal = 0;

    for (const recPay of recData.RecPayList) {
      if (recPay.StatusID === RecPayEnum.StatusID.Active.ID) {
        recData.RecPayTotal += recPay.InvPaymentValue;

        if (recPay.PaymentTypeName === creditNoteLabel) {
          recData.InvoiceCreditAmountTrans -= recPay.InvPaymentValue;
        }
      }
    }
  }
}