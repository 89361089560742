<template>
  <div class="form-body">
    <div class="form-group">
      <label for="organization" class="main-label">Nama Toko *</label>
      <div class="form-inline">
        <input type="text" name="organization" id="organization" :disabled="disableSubmit" v-model="data.company" >
        <span v-if="errorCompanyName">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorCompanyName }}</i>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="user" class="main-label">Nama Pemilik *</label>
      <div class="form-inline">
        <input type="text" name="user" id="user" :disabled="disableSubmit" v-model="data.user">
        <span v-if="errorUser">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorUser }}</i>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="phone" class="main-label">No. HP *</label>
      <div class="form-inline">
        <input type="number" min="0" name="phone" id="phone" :disabled="disableSubmit" v-model="data.phone">
        <span v-if="errorPhone">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorPhone }}</i>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="email" class="main-label">Email *</label>
      <div class="form-inline">
        <input type="text" name="email" id="email" :disabled="disableSubmit" v-model="data.email">
        <span v-if="errorEmail">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorEmail }}</i>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="phone" class="main-label">Kata Sandi *</label>
      <div class="form-inline">
        <input type="password" name="password" id="password" :disabled="disableSubmit" v-model="data.password">
        <span v-if="errorPassword">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorPassword }}</i>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label for="address" class="main-label">Alamat</label>
      <div class="form-inline">
        <input type="text" name="address" id="address" :disabled="disableSubmit" v-model="data.address">
        <span v-if="errorAddress">
          <i class="field-status-error mdi mdi-alert"></i>
          <i class="field-status-message">{{ errorAddress }}</i>
        </span>
      </div>
    </div>
    <div v-if="infoMessage">
      <div class="info-message" ref="info">
        <i class="mdi mdi-information"></i>
        <span>{{ infoMessage }}</span>
      </div>
    </div>
    <div v-if="errorMessage">
      <div class="error-message" ref="error">
        <i class="mdi mdi-alert"></i>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
    <kst-recaptcha
      ref="recaptcha"
      :sitekey="sitekey" 
      :loadRecaptchaScript="true" 
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired"
    />
    <div class="form-group no-underline">
      <div class="form-flex-col">
        <input type="checkbox" @change="onChangeCheckbox" />
        <span class="tnc">Dengan mendaftar saya setuju dengan <a @click="onClickTNC">Syarat & Ketentuan</a> yang berlaku</span>
      </div>
    </div>
    <div class="form-footer">
      <div v-if="loadingOrganization">
        <button class="submit-button" disabled>Memproses <i class="mdi mdi-autorenew mdi-spin"></i></button>
      </div>
      <div v-else>
        <button class="submit-button" :disabled="disableSubmit || disableBtnSubmit" @click.prevent="onClickNext">Daftar</button>
      </div>
    </div>
    <p class="sign-section">Sudah memiliki akun?<a @click="onClickLogin">Login</a></p>
  </div>
</template>

<script>
import AppModule from "../../../services/App/AppModule.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Model from "../SecurityModel.js";
import Info from "../SecurityInfo.js";
import Services from "../../../services/Api/CompanyServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  props: {
    data: Object
  },
  data: () => ({
    errorCompanyName: "",
    errorUser: "",
    errorEmail: "",
    errorPhone: "",
    errorAddress: "",
    errorMessage: "",
    errorPassword: "",
    infoMessage: "",
    disableSubmit: false,
    disableBtnSubmit: true,
    loadingOrganization: false,
    sitekey: ""
  }),
  created() {
    this.sitekey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
  },
  methods: {
    async onClickNext() {
      const isValid = this.isValid();
      if (isValid) {
        let data = Model.populateData(this.data);
        this.saveData(data);
      }
    },
    isValid() {
      this.infoMessage = "";
      this.errorMessage = "";
      this.errorCompanyName = "";
      this.errorUser = "";
      this.errorEmail = "";
      this.errorPhone = "";
      this.errorAddress = "";
      this.errorPassword = "";
      let result = true;
      const validateCompanyName = Model.validateCompany(this.data.company);
      const validateUserName = Model.validateName(this.data.user);
      const validateUserPhone = Model.validatePhone(this.data.phone);
      const validateUserEmail = Model.validateEmail(this.data.email);
      const validatePassword = Model.validatePassword(this.data.password);
      if(validateCompanyName) {
        result = false;
        this.errorCompanyName = validateCompanyName.error;
      }
      if(validateUserName) {
        result = false;
        this.errorUser = validateUserName.error;
      }
      if(validateUserPhone) {
        result = false;
        this.errorPhone = validateUserPhone.error;
      }
      if(validateUserEmail) {
        result = false;
        this.errorEmail = validateUserEmail.error;
      }
      if(validatePassword.length > 0) {
        result = false;
        this.errorPassword = Model.getErrorMessage(validatePassword);
      }
      return result;
    },
    onCaptchaVerified(token) {
      this.data.recaptchaToken = token;
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },

    saveData(data) {
      this.errorMessage = null;
      this.loadingOrganization = true;

      const config = Services.postRegistration(data);
      this.runAxios(config, null,
        this.saveData_Success,
        this.saveData_Fault,
        this.saveData_Fault
      );
    },
    saveData_End() {
      this.loadingOrganization = false;
    },
    saveData_Success(data) {
      this.data.userid = data.UserID;
      this.infoMessage = Info.Message.VerifyEmailInfo;

      this.disableSubmit = true;
      this.saveData_End();

      this.redirectTo(this.$kst.Route.getPath(AppModule.SignIn));
    },
    saveData_Fault(message) {
      this.errorMessage = message;
      this.saveData_End();
    },

    onClickLogin() {
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    onClickTNC() {
      window.open('termandcondition');
    },
    onChangeCheckbox(event) {
      this.disableBtnSubmit = !event.target.checked;
    },
    // DEPRECATED
    // openVerification() {
    //   if(this.data.userid) {
    //     var newWindow = window.open('verification');
    //     // Access it using its variable
    //     newWindow.userid_param = this.data.userid;
    //     newWindow.email_param = this.data.email;
    //   }
    // }
  }
}
</script>

<style scoped lang="css" src="../_layout.css"/>