import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  DispatchName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    ExportListLabel: AppCatalog.Field.Name.Label + " " + AppModule.Item.FullName,
    Required: true
  },
  IsClosed: {
    Label: AppCatalog.Field.IsClosed.Label, 
    ExportLabel: AppCatalog.Field.IsClosed.Label + 
      " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.IsClosed.Type,
    Output: AppCatalog.Field.IsClosed.Output
  },
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true,
    Valid: true
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    Required: true
  },
  TotalQty: {
    ExportListLabel: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName
  },
  Total: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },

  // support
  DispatchImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  DispatchSellPrice: {
    Label: AppCatalog.Field.DispatchSellPrice.Label,
    Type: AppCatalog.Field.DispatchSellPrice.Type,
    ReadOnly: AppCatalog.Field.DispatchSellPrice.ReadOnly,
    Output: AppCatalog.Field.DispatchSellPrice.Output
  },
  ItemTotal: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  QtyAvailable: {
    Label: AppCatalog.Field.Qty.Label + " SO",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  QtySalesOrder: {
    Label: AppCatalog.Field.Qty.Label + " Jual",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  }
}