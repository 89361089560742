import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RcpyEnum from "./RcpyEnum.js";

export default {
  // required in model
  Module: AppModule.RecPay,
  ModuleType: {
    Details: AppModuleType.Details,
    EditStatus: AppModuleType.EditStatus,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  RecPayInvoice: {
    Module: AppModule.Invoice
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.RecPay_Act_Details,
    Act_EditStatus: AppModuleAttr.RecPay_Act_EditStatus,
    Act_ExportDetails: AppModuleAttr.RecPay_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.RecPay_Act_ExportList,
    Act_List: AppModuleAttr.RecPay_Act_List,
    Act_New: AppModuleAttr.RecPay_Act_New,
    Act_PrintDetails: AppModuleAttr.RecPay_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.RecPay_Act_PrintList
  },

  Actions: {
    Details: [
      AppModuleType.EditStatus,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.EditStatus,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ],
    RelatedList: [
      AppModuleType.Details,
      AppModuleType.EditStatus,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails
    ]
  },

  StatusID: RcpyEnum.StatusID,

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      PayNumber: "",
      IsAutoNumber: true,
      ClientID: null,
      ClientName: "",
      PaymentTypeName: null,
      PaymentValue: "",
      Comment: "",
      StatusID: null,
      VoidComment: "",
      // by system
      ID: null,
      WarehouseName: null,
      ClientAlias: "",
      ClientCreditAmount: 0,
      ClientOverDueDate: null,
      ClientRecTotal: 0,
      Date: App.In.getDateToday(),
      IsVerified: false,
      SelectedInvoiceList: [],
      // validation
      InvoiceTotal: 0,
      InvoiceTotalIsValid: false
    };
  },
  createInvoice() {
    return {
      InvoiceID: null,
      PaymentValue: "",
      // by system
      InvoiceNumber: null,
      InvoiceDate: null,
      InvoiceBalance: 0,
      InvoiceStatus: null,
      InvoiceDueDate: null,
      ClientID: null,
      ClientName: null,
      WarehouseID: null,
      WarehouseName: null,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Active.ID, [rowLabel]: statusEnum.Active.Label },
      { [rowId]: statusEnum.Void.ID, [rowLabel]: statusEnum.Void.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select items
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  setDetailsByStatus(details, statusEnum) {
    details.StatusName = App.Data.getStatusLabel(details.StatusID, statusEnum);
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID === App.Search.OptionSome
        ? null : App.Out.getInteger(userData.WarehouseID),
      PayNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.PayNumber),
      Date: App.Out.getDateString(userData.Date),
      ClientID: userData.ClientID,
      ClientName: App.Out.getString(userData.ClientName),
      PaymentTypeName: App.Out.getString(userData.PaymentTypeName),
      PaymentValue: App.Out.getInteger(userData.PaymentValue),
      Comment: App.Out.getString(userData.Comment)
    };
  },
  populateInvoiceList(userInvoiceList) {
    let resultItems = [];

    let paymentValue;
    for (const userInvoice of userInvoiceList) {
      paymentValue = App.Out.getInteger(userInvoice.PaymentValue);

      if (paymentValue !== null) {
        resultItems.push({
          WarehouseID: userInvoice.WarehouseID,
          InvoiceID: userInvoice.InvoiceID,
          InvoiceNumber: userInvoice.InvoiceNumber,
          InvoiceDate: userInvoice.InvoiceDate,
          ClientID: userInvoice.ClientID,
          ClientName: userInvoice.ClientName,
          PaymentValue: paymentValue
        });
      }
    }

    return resultItems;
  }
}