import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ArrivalDate: {
    Label: AppCatalog.Field.Date.Label + " Terkirim",
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  Client: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    PrintLabel: AppCatalog.Field.Name.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "Client",
    RelatedModule: AppModule.Client
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 500
  },
  CompletedDate: {
    Label: AppCatalog.Field.Date.Label + " Selesai",
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  DepartureDate: {
    Label: AppCatalog.Field.Date.Label + " Kirim",
    Type: AppCatalog.Field.Date.Type,
    Required: true
  },
  Destination: {
    Label: AppCatalog.Field.Destination.Label,
    Type: AppCatalog.Field.Destination.Type,
    MaxLength: 100,
    Required: true
  },
  LogisticsNumber: {
    Label: AppCatalog.Field.Number.Label + " Pengiriman",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: AppCatalog.Field.Number.MaxLength,
    Highlight: true
  },
  ShipmentFee: {
    Label: AppCatalog.Field.Fee.Label + " Kirim",
    Type: AppCatalog.Field.Fee.Type,
    MinValue: AppCatalog.Field.Fee.MinValue,
    MaxLength: AppCatalog.Field.Fee.MaxLength,
    Output: AppCatalog.Field.Fee.Output
  },
  SOID: {
    Label: AppCatalog.Field.Number.Label + " Penjualan",
    ExportLabel: AppModule.Invoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "SONumber",
    RelatedModule: AppModule.Invoice,
    Param: AppModule.Invoice.Param + AppCatalog.Field.ID.Param
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    NewLabel: AppCatalog.Field.Status.Label + " " + AppCatalog.Message.New,
    OldLabel: AppCatalog.Field.Status.Label + " " + AppCatalog.Message.Old,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "StatusName",
    Required: true,
    RequiredHide: true
  },
  VehicleIDNumber: {
    Label: AppCatalog.Field.Number.Label + " Kendaraan",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 20
  },
  TotalWeight: {
    Label: AppCatalog.Field.TotalWeight.Label,
    Type: AppCatalog.Field.TotalWeight.Type,
    ReadOnly: AppCatalog.Field.TotalWeight.ReadOnly
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  ClientAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " Pengiriman",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  SalesOrderDate: {
    Label: AppCatalog.Field.Date.Label + " Penjualan",
    Type: InputType.Date,
    ReadOnly: true
  }
}