<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details :userData="userData"/>
    <Addresses :userAddressList="userAddressList"/>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../VenModel.js";
import Services from "../../../services/Api/VendorService.js";

import Details from "./VenE_Details.vue";
import Addresses from "./VenE_AddressList.vue";

export default {
  components: {
    Addresses,
    Details
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userAddressList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      Model.Edit.setAddressesByData(this.userAddressList,
        data.Details.AddressList
      );
      Model.Edit.setDetailsByData(this.userData, data.Details);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userAddressList);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userAddressList = [];
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>