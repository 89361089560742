<template>
  <div class="columns is-mobile">
    <div class="column is-narrow">
      <kst-print-sign :header="createdBy"/>
    </div>
    <div class="column is-narrow">
      <kst-print-sign :header="approvedBy"/>
    </div>

    <div class="column"/>

    <div class="column is-narrow">
      <kst-print-sign :header="receivedBy"/>
    </div>
  </div>
</template>

<script>
import Info from "../DlvInfo.js";

export default {
  computed: {
    approvedBy() {
      return Info.Section.PrintSJ.ApprovedBy.Header;
    },
    createdBy() {
      return Info.Section.PrintSJ.CreatedBy.Header;
    },
    receivedBy() {
      return Info.Section.PrintSJ.ReceivedBy.Header;
    }
  }
}
</script>