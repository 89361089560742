import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Enum from "./WhsEnum.js";

export default {
  // required in model
  Module: AppModule.Warehouse,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.Warehouse_Act_Details,
    Act_Edit: AppModuleAttr.Warehouse_Act_Edit,
    Act_List: AppModuleAttr.Warehouse_Act_List,
    Act_New: AppModuleAttr.Warehouse_Act_New,
    Act_PrintList: AppModuleAttr.Warehouse_Act_PrintList
  },

  Actions: {
    Details: [
      AppModuleType.Edit
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit
    ]
  },

  Search: {
    ListFields: ["Name", "Address"],
    ListParams: ["Search", "IsActive"]
  },

  IsActive: Enum.IsActive,

  /*** method ***/

  createDetails(statusEnum) {
    return {
      ID: null,
      Name: "",
      PIC: "",
      Address: "",
      NoPIC: "",
      IsActive: App.In.getBoolean(statusEnum.Active.ID),
      Comment: "",
      // Company Profile Alias
      CompanyName: "",
      CompanyAddress: "",
      CompanyPhone: "",
    };
  },
  createOptions(dataList, optionAllText, optionSomeText) {
    const rowId = "ID";
    const rowLabel = "Name";

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    if (optionSomeText) {
      listOptions.someActive = true;
      listOptions.someText = optionSomeText;
    }

    // create: select rows
    const selectRows = App.Search.createList(dataList, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectRows);
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [
      { [rowId]: statusEnum.Active.ID, [rowLabel]: statusEnum.Active.Label },
      { [rowId]: statusEnum.InActive.ID, [rowLabel]: statusEnum.InActive.Label }
    ];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  getDefault(dataList, defaultId) {
    const defaultWarehouse = App.Array.searchItem(dataList, "ID", defaultId);

    if (defaultWarehouse !== null) {
      return defaultId;
    }
    else {
      return dataList.length > 0 ? dataList[0].ID : null;
    }
  },

  populateDetails(userData) {
    return {
      ID: App.Out.getInteger(userData.ID),
      Name: App.Out.getString(userData.Name),
      PIC: App.Out.getString(userData.PIC),
      Address: App.Out.getString(userData.Address),
      NoPIC: App.Out.getString(userData.NoPIC),
      Comment: App.Out.getString(userData.Comment),
      IsActive: App.Out.getBoolean(userData.IsActive),
      // Company Profile Alias
      CompanyName: App.Out.getString(userData.CompanyName),
      CompanyAddress: App.Out.getString(userData.CompanyAddress),
      CompanyPhone: App.Out.getString(userData.CompanyPhone)
    };
  }
}