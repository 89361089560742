<template>
  <kst-section :title="Info.Section.ReceiveList.Title" v-show="userData.IsVerified">
    <template #top-right>
      <div>
        <div class="columns is-mobile mb-0">
          <div class="column is-narrow pb-0">
            <div class="title is-5">
              {{ Catalog.ReceiveTotal.PrintLabel }}:
            </div>
          </div>
          <div class="column pb-0">
            <div class="has-text-right">
              <kst-value class="title is-5"
                field="ReceiveTotal" :data="userData" :catalog="Catalog"
              />
            </div>
          </div>
        </div>

        <div class="columns is-mobile" v-if="invalidReceiveTotal !== null">
          <div class="column is-narrow ks-max-width-100p">
            <kst-errors-helper :items="invalidReceiveTotal"/>
          </div>
        </div>
      </div>
    </template>

    <kst-load-data activeOnLoad loadingElement @init="handleLoad_Init">
      <template v-if="userData.IsVerified">
        <div class="buttons">
          <kst-button checkAllMode slimSize
            @click="handleCheckAll"
          />
          <kst-button slimSize uncheckAllMode
            @click="handleUncheckAll"
          />
        </div>

        <List
          :userData="userData"
          :userReceiveList="userReceiveList"
        />
      </template>
    </kst-load-data>
  </kst-section>
</template>

<script>
import Catalog from "../DbpyCatalog.js";
import Info from "../DbpyInfo.js";
import Model from "../DbpyModel.js";
import Services from "../../../services/Api/DebtPayServices.js";

import List from "./DbpyN_RcvList_List.vue";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    userReceiveList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    // func
    loadData: null
  }),
  computed: {
    invalidReceiveTotal() {
      return this.userData.ReceiveTotalIsValid
        ? null : Model.New.getInvalidReceiveTotal(this.userData);
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** by component ***/

    init() {
      this.$emit(this.$kst.Enum.Event.Init, this.loadList);
    },

    /*** by load data ***/

    handleLoad_Init(loadData) {
      this.loadData = loadData;
    },

    /*** custom ***/

    handleCheckAll() {
      Model.New.setReceiveListByCheckAll(this.userData, this.userReceiveList);
    },
    handleUncheckAll() {
      Model.New.setReceiveListByUncheckAll(this.userData, this.userReceiveList);
    },

    loadList() {
      Model.New.resetReceiveTotal(this.userData);

      const options = this.getListOptions();
      const config = Services.getRelatedReceiveList(options);
      this.loadData(config, this.loadList_Success, true);
    },
    loadList_Success(data) {
      Model.New.setReceiveListBySearch(this.userReceiveList, data.ReceiveList);
    },

    getListOptions() {
      const vendorId = this.$kst.Out.getInteger(this.userData.VendorID);
      const vendorName = vendorId === null
        ? this.$kst.Out.getString(this.userData.VendorName) : null;

      return {
        vendor: vendorId,
        vendorname: vendorName,
        warehouse: this.userData.WarehouseID === this.$kst.Search.OptionSome
          ? null : this.userData.WarehouseID
      };
    }
  }
}
</script>