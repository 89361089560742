import App from "../../services/App/App.js";

export default {
  setDetailsByData(userData, details) {
    userData.ID = details.ID;
    // user input
    userData.CategoryID = App.In.getInteger(details.CategoryID);
    userData.IsActive = App.In.getBoolean(details.IsActive);
    userData.Name = App.In.getString(details.Name);
  }
}