<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../CliCatalog.js";
import CliaCatalog from "../CliaCatalog.js";
import CliRecCatalog from "../CliRecCatalog.js";
import Info from "../CliInfo.js";
import Model from "../CliModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access:Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      if (this.userData.WithRecList) {
        if (Object.prototype.hasOwnProperty.call(data, "RecList")) {
          this.createSheet();
          this.addRec(data.Details, data.RecList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_details(details);
      this.addDetails_addressList(details.AddressList);

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_details(details) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.DaysDueDate);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      if (this.userData.WithSelfService) {
        this.addColumnHeader(Catalog.SelfServiceUrl);
      }

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue_ID(details);
      this.addCellValue("Name", details, Catalog);
      this.addCellValue("Alias", details, Catalog);
      this.addCellValue("DaysDueDate", details, Catalog);
      this.addCellValue("Mobile", details, Catalog);
      this.addCellValue("Phone", details, Catalog);
      this.addCellValue("Email", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);

      if (this.userData.WithSelfService) {
        Model.SelfService.updateData(details);
        this.addCellValue("SelfServiceUrl", details, Catalog);
      }
    },

    addDetails_addressList(addressList) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.Address.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID();
      this.addColumnHeader(CliaCatalog.Name);
      this.addColumnHeader(CliaCatalog.Address);
      this.addColumnHeader(CliaCatalog.ContactName);
      this.addColumnHeader(CliaCatalog.ContactMobile);
      this.addColumnHeader(CliaCatalog.ContactPhone);
      this.addColumnHeader(CliaCatalog.ContactEmail);

      // value
      for ( const row of addressList ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue_ID(row);
      this.addCellValue("Name", row, CliaCatalog);
      this.addCellValue("Address", row, CliaCatalog);
      this.addCellValue("ContactName", row, CliaCatalog);
      this.addCellValue("ContactMobile", row, CliaCatalog);
      this.addCellValue("ContactPhone", row, CliaCatalog);
      this.addCellValue("ContactEmail", row, CliaCatalog);
      }
    },

    addRec(details, recList) {
      this.addRec_summary(details);
      this.addRec_recList(recList);

      this.addSheet(Info.Export.Details.Excel.SheetName.RecList);
    },
    addRec_summary(details) {
      this.addColumnHeader(Catalog.RecTotal);
      this.addCellValue("RecTotal", details, Catalog);

      this.addRow();
      this.addColumnHeader(Catalog.OverDueDate);
      this.addCellValue("OverDueDate", details, Catalog);
    },
    addRec_recList(recList) {
      this.addRow();
      this.addRow();

      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(CliRecCatalog.SONumber);
      this.addColumnHeader(CliRecCatalog.InvoiceDate);
      this.addColumnHeader_ID(CliRecCatalog.WarehouseID.Label);
      this.addColumnHeader(CliRecCatalog.WarehouseID);
      this.addColumnHeader(CliRecCatalog.PONumber);
      this.addColumnHeader(CliRecCatalog.Total);
      this.addColumnHeader(CliRecCatalog.Status);
      this.addColumnHeader(CliRecCatalog.DueDate);
      this.addColumnHeader(CliRecCatalog.RecTotal);

      // value
      for (const row of recList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("SONumber", row, CliRecCatalog);
        this.addCellValue("InvoiceDate", row, CliRecCatalog);
        this.addCellValue("WarehouseID", row, CliRecCatalog);
        this.addCellRelatedValue("WarehouseID", row, CliRecCatalog);
        this.addCellValue("PONumber", row, CliRecCatalog);
        this.addCellValue("Total", row, CliRecCatalog);
        this.addCellStatus(row.Status, Model.InvoiceStatus);
        this.addCellValue("DueDate", row, CliRecCatalog);
        this.addCellValue("RecTotal", row, CliRecCatalog);
      }
    }
  }
}
</script>