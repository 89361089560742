<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../VenCatalog.js";
import AddressCatalog from "../VenaCatalog.js";
import DebtCatalog from "../VenDebtCatalog.js";
import Info from "../VenInfo.js";
  
export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addDetails(data.Details);

      if (this.userData.WithDebtList) {
        if (Object.prototype.hasOwnProperty.call(data, "DebtList")) {
          this.createSheet();
          this.addDebt(data.Details, data.DebtList);
        }
      }

      this.download(fileName);
    },

    addDetails(details) {
      this.addDetails_details(details);
      if (details.AddressList) {
        this.addDetails_addressList(details.AddressList);
      }

      this.addSheet(Info.Export.Details.Excel.SheetName.Details);
    },
    addDetails_details(details) {
      // label
      this.addSequenceHeader();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Alias);
      this.addColumnHeader(Catalog.DaysDueDate);
      this.addColumnHeader(Catalog.Mobile);
      this.addColumnHeader(Catalog.Phone);
      this.addColumnHeader(Catalog.Email);
      this.addColumnHeader(Catalog.Comment);
      this.addColumnHeader_CreatedBy();
      this.addColumnHeader_CreatedDate();
      this.addColumnHeader_LastUpdatedBy();
      this.addColumnHeader_LastUpdatedDate();

      // value
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", details, Catalog);
      this.addCellValue("Alias", details, Catalog);
      this.addCellValue("DaysDueDate", details, Catalog);
      this.addCellValue("Mobile", details, Catalog);
      this.addCellValue("Phone", details, Catalog);
      this.addCellValue("Email", details, Catalog);
      this.addCellValue("Comment", details, Catalog);
      this.addCellValue_CreatedBy(details);
      this.addCellValue_CreatedDate(details);
      this.addCellValue_LastUpdatedBy(details);
      this.addCellValue_LastUpdatedDate(details);
    },

    addDetails_addressList(addressList) {
      this.addRow();
      this.addRow();
      this.addCellSection(Info.Section.Address.Title);
      this.addRow();

      // label
      this.addSequenceHeader();
      this.addColumnHeader(AddressCatalog.Name);
      this.addColumnHeader(AddressCatalog.Address);
      this.addColumnHeader(AddressCatalog.ContactName);
      this.addColumnHeader(AddressCatalog.ContactMobile);
      this.addColumnHeader(AddressCatalog.ContactPhone);
      this.addColumnHeader(AddressCatalog.ContactEmail);

      // value
      for ( const row of addressList ) {
      this.addRow();
      this.addSequenceCell();
      this.addCellValue("Name", row, AddressCatalog);
      this.addCellValue("Address", row, AddressCatalog);
      this.addCellValue("ContactName", row, AddressCatalog);
      this.addCellValue("ContactMobile", row, AddressCatalog);
      this.addCellValue("ContactPhone", row, AddressCatalog);
      this.addCellValue("ContactEmail", row, AddressCatalog);
      }
    },

    addDebt(details, debtList) {
      this.addDebt_summary(details);
      this.addDebt_debtlist(debtList);

      this.addSheet(Info.Export.Details.Excel.SheetName.DebtList);
    },

    addDebt_summary(details) {
      this.addColumnHeader(Catalog.DebtTotal);
      this.addCellValue("DebtTotal", details, Catalog);
    },

    addDebt_debtlist(debtList) {
      this.addRow();
      this.addRow();
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(DebtCatalog.Date);
      this.addColumnHeader(DebtCatalog.ReceiveNumber);
      this.addColumnHeader_ID(DebtCatalog.WarehouseID.Label);
      this.addColumnHeader(DebtCatalog.WarehouseID);
      this.addColumnHeader(DebtCatalog.Total);
      this.addColumnHeader(DebtCatalog.DueDate);
      this.addColumnHeader(DebtCatalog.DebtTotal);
    
      // value
      for (const row of debtList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("Date", row, DebtCatalog);
        this.addCellValue("ReceiveNumber", row, DebtCatalog);
        this.addCellValue("WarehouseID", row, DebtCatalog);
        this.addCellRelatedValue("WarehouseID", row, DebtCatalog);
        this.addCellValue("Total", row, DebtCatalog);
        this.addCellValue("DueDate", row, DebtCatalog);
        this.addCellValue("DebtTotal", row, DebtCatalog);
      }
    },
  }
}
</script>