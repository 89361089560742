<template>
  <kst-page class="ks-max-width-100p ks-is-fullwidth" :class="theClass">
    <ValidationObserver ref="formDetails">
      <kst-container v-if="isLoading">
        <div class="p-4">
          <kst-skeleton :total="2" :active="isLoading"/>
        </div>
      </kst-container>

      <kst-container v-if="hasAccess !== true">
        <div class="p-4">
          <kst-failure access-mode/>
        </div>
      </kst-container>

      <template v-if="contentSlotVisible">
        <kst-container>
          <slot name="header"/>
        </kst-container>

        <kst-container>
          <slot name="body"/>
        </kst-container>
      </template>

      <kst-container v-if="failureInit">
        <div class="p-4">
          <kst-failure init-mode :items="failureInit"/>
        </div>
      </kst-container>
    </ValidationObserver>
  </kst-page>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

/**
 * flow (how to use):
 * 1. handle Init event, do loadNew, set and do submit when needed
 * 2. handle Save event and do saveData
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, RouterMixin, SnackbarMixin],
  data: () => ({
    hasAccess: null,
    // loading
    isLoading: true,
    saveLoading: null,
    // area
    reloadAreaVisible: false,
    // slot
    contentSlotVisible: false,
    // error
    failureInit: null
  }),
  computed: {
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(this.$kst.Enum.Event.Init, this.loadNew, this.submit);
      }
    },
    init_data() {
      this.hasAccess = null;
      // loading
      this.isLoading = true;
      this.saveLoading = null;
      // area
      this.reloadAreaVisible = false;
      // slot
      this.contentSlotVisible = false;
      // error
      this.failureInit = null;
    },
    init_validate() {
      // check: access
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess !== true) {
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
      this.contentSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleReload() {
      this.init();
    },

    /*** custom ***/

    loadNew(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadNew_success,
        this.loadNew_fault,
        this.loadNew_fault,
        null, true
      );
    },
    loadNew_success(data, successCallback) {
      successCallback(data);
      this.init_success();
    },
    loadNew_fault(message) {
      this.failureInit = message;
      this.init_failed();
    },

    submit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.$emit(this.$kst.Enum.Event.Save, this.saveData);
        }
        else {
          this.notifyFormError();
        }
      });
    },

    saveData(config, successCallback) {
      this.saveLoading = this.openLoading();

      this.runAxios(config, successCallback,
        this.saveData_success,
        this.saveData_error,
        this.saveData_invalid
      );
    },
    saveData_end() {
      this.closeLoading(this.saveLoading);
      this.saveLoading = null;
    },
    saveData_success(data, successCallback) {
      this.$refs.formDetails.reset();
      this.saveData_end();
      this.notifyFormSuccess();
      successCallback(data);
    },
    saveData_error(message) {
      this.notifyFormError(false, message);
      this.saveData_end();
    },
    saveData_invalid(message) {
      this.notifyFormError(false, message);
      this.saveData_end();
    }
  }
}
</script>