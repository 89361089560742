import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.delivery;

export default {
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditStatusData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editstatusdata?id=" + id
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field", "withitems"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData(params) {
    const attributes = ["invoice"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata" + urlParams
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getPrintSJData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printsjdata?id=" + id
    };
  },
  getRelatedClientList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client/list" + urlParams
    };
  },
  getRelatedInvoiceDetails(invoiceId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/invoice/details?invoice=" + invoiceId
    };
  },
  getRelatedInvoiceList(params) {
    const attributes = ["warehouse", "startdate", "enddate", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/invoice/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putStatus(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint + "/status",
      data: data
    };
  }
}