import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  AddTotal: {
    Label: AppCatalog.Field.Credit.Label,
    Type: AppCatalog.Field.Credit.Type,
    Output: AppCatalog.Field.Credit.Output,
    ReadOnly: AppCatalog.Field.Credit.ReadOnly
  },
  ModuleName: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SubTotal: {
    Label: AppCatalog.Field.Debit.Label,
    Type: AppCatalog.Field.Debit.Type,
    Output: AppCatalog.Field.Debit.Output,
    ReadOnly: AppCatalog.Field.Debit.ReadOnly
  }
}