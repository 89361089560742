<template>
  <kst-section collapsed info-mode
    :collapsible="isCollapsible"
    :help-collapsed="sectionHelp"
    :help="sectionHelp"
    :title="Info.Section.Purchase.Title"
  >
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="details"
      isActiveField="PurchasingIsActive"
      dateField="PurchasingDate"
      numberField="PurchasingID"
    />
  </kst-section>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Info from "../RcvInfo.js";
import Model from "../RcvModel.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  }),
  computed: {
    isCollapsible() {
      return this.details.PurchasingID !== null;
    },
    sectionHelp() {
      return Model.getPurchaseSectionHelp(this.details);
    }
  }
}
</script>