import AppCatalog from "../../../services/App/AppCatalog.js";
import InputType from "../../../services/Input/InputType.js";
import Catalog from "../SlsCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari " +
      Catalog.DraftNumber.Label + ", atau " +
      Catalog.ClientID.Label + ", atau " +
      Catalog.PONumber.Label,
    Type: AppCatalog.Field.Search.Type,
    MaxLength: AppCatalog.Field.Search.MaxLength
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: InputType.ID,
    Input: InputType.Select
  }
}