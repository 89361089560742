<template>
  <kst-table paginated view-mode
    :data="dataList"
    :selected.sync="userData.selected"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-barcode"
      field="Barcode"
      :label="Catalog.Barcode.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      cell-class="ks-col-variant"
      field="VariantType1"
      :label="Catalog.Variant1.Label"
    >
      {{ getVariantType1(row) }}
    </b-table-column>

    <b-table-column sortable
      v-slot="{ row }"
      cell-class="ks-col-variant"
      field="Variant2"
      :label="Catalog.Variant2.Label"
    >
      {{ getVariantType2(row) }}
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../ItemCatalog.js";
import Model from "../ItemModel.js";

export default {
  props: {
    dataList: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model:Model
  }),
  methods: {
    getVariantType1(row) {
      return Model.getVariantSet1(row);
    },
    getVariantType2(row) {
      return Model.getVariantSet2(row);
    }
  }
}
</script>