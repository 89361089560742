import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./InvCatalog.js";
import InvItemCatalog from "./InvItemCatalog.js";
import ModelBody from "./InvModel_Body.js";
import PaymentTypeModel from "../PaymentType/PaytModel.js";
import SalesModel from "../Sales/SlsModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  setDetailsByClient(userData, clientData, discTypeEnum) {
    // user input
    userData.ClientID = clientData.ID;
    userData.Client = App.In.getString(clientData.Name);
    userData.ClientAddress = App.In.getString(clientData.Address);
    userData.DueDate = App.Data.addDaysToDate(userData.InvoiceDate,
      clientData.DaysDueDate);
    // defined by system
    userData.ClientAlias = clientData.Alias;
    userData.ClientCreditAmount = clientData.CreditAmount;
    userData.ClientOverDueDate = clientData.OverDueDate === null
      ? null : clientData.OverDueDate;
    userData.ClientRecTotal = clientData.RecTotal;

    if (clientData.DiscPercent > 0) {
      userData.ClientDiscPercent_MessageCredit = 2;
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(clientData.DiscPercent);
    }
  },
  setDetailsByFullPayment(userData) {
    userData.PaymentValue = App.In.getInteger(userData.Total);
  },
  setDetailsByPaymentList(userData, paymentList) {
    if (paymentList === undefined || paymentList === null) {
      return;
    }

    userData.PaymentTypeName = PaymentTypeModel.getDefault(
      paymentList, userData.PaymentTypeName
    );
  },
  setDetailsBySales(userData, salesData, discTypeEnum, clientDetails) {
    // user input
    userData.SalesDraftID = salesData.ID;
    userData.WarehouseID = salesData.WarehouseID;
    userData.PONumber = App.In.getString(salesData.PONumber);
    userData.ClientID = salesData.ClientID;
    userData.Client = App.In.getString(salesData.ClientName);
    userData.ClientAddress = App.In.getString(salesData.ClientAddress);
    userData.DueDate = salesData.ClientID === null
      ? App.In.getDateToday()
      : App.Data.addDaysToDate(null, clientDetails.DaysDueDate);
    // defined by system
    userData.WarehouseName = salesData.WarehouseName;
    userData.ClientAlias = salesData.ClientAlias;
    userData.DraftNumber = salesData.DraftNumber;
    userData.DraftDate = salesData.Date;
    userData.SalesDraftIsActive = App.In.getBoolean(
      salesData.IsActive
    );

    if (salesData.ClientID !== null) {
      userData.ClientCreditAmount = clientDetails.CreditAmount;
      userData.ClientOverDueDate = clientDetails.OverDueDate === null
        ? null : clientDetails.OverDueDate;
      userData.ClientRecTotal = clientDetails.RecTotal;
    }

    if (salesData.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
      userData.DiscValue = App.In.getInteger(salesData.DiscValue);
    }
    else if (salesData.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(salesData.DiscPercent);
    }
    else {
      userData.DiscType = discTypeEnum.None.ID;
    }
  },
  setDetailsByTransaction(userData, transactionData, clientDetails, 
    warehouseDetails, discTypeEnum
  ) {
    // user input
    userData.TransactionID = transactionData.ID;
    userData.WarehouseID = transactionData.WarehouseID;
    userData.PONumber = App.In.getString(transactionData.PONumber);
    userData.ClientID = transactionData.ClientID;
    userData.Client = App.In.getString(transactionData.Client);
    userData.ClientAddress = App.In.getString(transactionData.ClientAddress);
    userData.DueDate = transactionData.ClientID === null
      ? App.In.getDateToday()
      : App.Data.addDaysToDate(null, clientDetails.DaysDueDate);
    // defined by system
    userData.TransactionNumber = transactionData.SONumber;
    userData.TransactionDate = App.In.getDate(transactionData.InvoiceDate);
    userData.TransactionVisible = true;
    userData.WarehouseName = warehouseDetails.Name;

    if (transactionData.ClientID !== null) {
      userData.ClientAlias = clientDetails.Alias;
      userData.ClientCreditAmount = clientDetails.CreditAmount;
      userData.ClientOverDueDate = clientDetails.OverDueDate === null
        ? null : clientDetails.OverDueDate;
      userData.ClientRecTotal = clientDetails.RecTotal;
    }

    if (transactionData.SalesDraftID !== null && 
      transactionData.SalesDraftIsActive === 1
    ) {
      userData.SalesDraftID = transactionData.SalesDraftID;
      userData.DraftNumber = transactionData.DraftNumber;
      userData.DraftDate = transactionData.DraftDate;
      userData.SalesDraftIsActive = App.In.getBoolean(
        transactionData.SalesDraftIsActive
      );
    }

    if (transactionData.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
    }
    else if (transactionData.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
    }
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setItemsBySales(userData, userItems, salesItems) {
    App.Array.truncate(userItems);

    for (const row of salesItems) {
      if (row.IsClosed === 0) {
        let item = ModelBody.createItem(userData);
        this.setItemsBySales_setItem(item, row);
        ModelBody.updateItemByPackaging(item);
        ModelBody.updateItem(item);
        userItems.push(item);
      }
    }
  },
  setItemsBySales_setItem(item, salesItem) {
    item.SalesDraftItemID = salesItem.ID;
    item.SalesDraftItemQty = salesItem.QtyAvailable;
    item.CustomValidations = {
      [App.Vee.Rule.MaxValue]: [
        item.SalesDraftItemQty,
        InvItemCatalog.SalesDraftItemQty.Label
      ]
    };
    // user input
    item.DispatchID = salesItem.DispatchID;
    item.Name = App.In.getString(salesItem.DispatchName);
    item.RequestedQuantity = App.In.getInteger(salesItem.QtyAvailable);
    item.PackagingName = salesItem.PackagingName;
    item.SellPrice = App.In.getInteger(salesItem.SellPrice);
    // by system
    item.SKU = salesItem.SKU;
    item.PackagingValue = salesItem.PackagingValue;
    item.StockQty = salesItem.StockQuantity;
    item.DispatchSellPrice = salesItem.DispatchSellPrice;
    item.DispatchIsActive = App.In.getBoolean(salesItem.DispatchIsActive);
    item.SpecialPriceOptions = ModelBody.createSpecialPriceOptions(
      salesItem.StockSpecialPriceList, InvItemCatalog.SpecialPriceID.Label
    );
  },
  setItemBySalesItem(item, salesItemData, stockDetails, stockSpecialPriceList) {
    item.SalesDraftItemID = salesItemData.ID;
    item.SalesDraftItemQty = salesItemData.QtyAvailable;
    item.CustomValidations = {
      [App.Vee.Rule.MaxValue]: [
        item.SalesDraftItemQty,
        InvItemCatalog.SalesDraftItemQty.Label
      ]
    };
    // user input
    item.DispatchID = salesItemData.DispatchID;
    item.Name = App.In.getString(salesItemData.DispatchName);
    item.RequestedQuantity = App.In.getInteger(salesItemData.QtyAvailable);
    item.PackagingName = salesItemData.PackagingName;
    item.SellPrice = App.In.getInteger(salesItemData.SellPrice);
    // defined by system
    item.SKU = salesItemData.SKU;
    item.PackagingValue = salesItemData.PackagingValue;
    item.StockQty = stockDetails.Quantity;
    item.DispatchSellPrice = salesItemData.DispatchSellPrice;
    item.DispatchIsActive = App.In.getBoolean(salesItemData.DispatchIsActive);
    item.SpecialPriceOptions = ModelBody.createSpecialPriceOptions(
      stockSpecialPriceList, InvItemCatalog.SpecialPriceID.Label
    );
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }
    else {
      // SKU
      infoList.push(InvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvItemCatalog)
      );

      // DispatchSellPrice
      infoList.push(InvItemCatalog.DispatchSellPrice.Label + ": " +
        App.Value.getValue("DispatchSellPrice", item, InvItemCatalog)
      );

      // Stock
      infoList.push(InvItemCatalog.Stock.Label + ": " +
        App.Value.getValue("Stock", item, InvItemCatalog) + " " +
        item.PackagingName
      );

      if (item.SpecialPriceID !== App.Search.OptionNone) {
        // SpecialPriceStock
        infoList.push(InvItemCatalog.SpecialPriceStock.Label + ": " +
          App.Value.getValue("SpecialPriceStock", item, InvItemCatalog) +
          " " + item.PackagingName
        );
      }
    }

    // Sales
    if (item.SalesDraftItemID) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Sales.FullName
      );
    }

    return infoList;
  },

  clearDetailsByClient(userData) {
    userData.ClientID = null;
    userData.Client = "";
    userData.ClientAddress = "";
    userData.PaymentTypeName = App.Search.OptionNone;
    userData.PaymentValue = "";
    userData.DueDate = App.In.getDate(userData.InvoiceDate);
    // by system
    userData.ClientAlias = "";
    userData.ClientCreditAmount = 0;
    userData.ClientOverDueDate = null;
    userData.ClientRecTotal = 0;
  },
  clearDetailsByTransaction(userData, discTypeEnum) {
    userData.TransactionID = null;
    userData.SalesDraftID = null;
    userData.PONumber = "";
    userData.ClientID = null;
    userData.Client = "";
    userData.ClientAddress = "";
    userData.PaymentTypeName = App.Search.OptionNone;
    userData.PaymentValue = "";
    userData.DueDate = App.In.getDate(userData.InvoiceDate);
    userData.DiscType = discTypeEnum.None.ID,
    // by system
    userData.TransactionNumber = null;
    userData.TransactionDate = null;
    userData.DraftNumber = null;
    userData.DraftDate = null;
    userData.ClientAlias = "";
    userData.ClientCreditAmount = 0;
    userData.ClientOverDueDate = null;
    userData.ClientRecTotal = 0;
  },

  updateItemsByStockList(userItems, stockList) {
    let stock;

    // update stock
    for (const item of userItems) {
      stock = App.Array.searchItem(stockList, "ItemID", item.DispatchID);

      if (stock !== null) {
        item.DispatchIsActive = true;
        item.StockQty = stock.QuantityPcs;
        ModelBody.updateItem(item);
      }
    }
  },

  validationDeliveryDate(data) {
    if (data.IsNeedDelivery) {
      return {
        [App.Vee.Rule.MinDate]: {
          target: App.In.getDateToday(),
          label: AppCatalog.Info.Today
        }
      };
    }

    return undefined;
  },
  validationDueDate() {
    return {
      [App.Vee.Rule.MinDate]: {
        target: App.In.getDateToday(),
        label: AppCatalog.Info.Today
      }
    };
  },
  validationInvoiceDate(userData) {
    const userBackDateInterval = App.Session.getBackDateInterval();
    if (userBackDateInterval !== 0){
      const backDate = App.Data.addDaysToDate(
        userData.CurrentDate, -1 * userBackDateInterval);

      if (userData.DraftDate !== null) {
        let draftDate = App.In.getDate(userData.DraftDate)
        
        return {
          [App.Vee.Rule.MinDate]: {
            target: draftDate > backDate ? draftDate : backDate,
            label: draftDate > backDate ? 
              Catalog.DraftDate.ExportLabel : null
          },
          [App.Vee.Rule.MaxDate]: {
            target: userData.CurrentDate,
            label: AppCatalog.Info.Today
          }
        };
      }

      return {
        [App.Vee.Rule.MinDate]: {
          target: backDate
        },
        [App.Vee.Rule.MaxDate]: {
          target: App.In.getDateToday(),
          label: AppCatalog.Info.Today
        }
      };
    }
    
    return undefined
  },

  /*** external ***/

  updateSalesItems(salesData) {
    let newSalesItems = [], isValid;

    for (let salesItem of salesData.Items) {
      isValid = true;

      salesItem.QtyAvailable = salesItem.Qty - salesItem.QtySalesOrder;

      // closed item
      if (salesItem.IsClosed === 1) {
        isValid = false;
      }
      // deleted-item
      else if (salesItem.DispatchIsActive === 0) {
        isValid = false;
      }
      // qty available
      else if (salesItem.QtyAvailable <= 0) {
        isValid = false;
      }

      if (isValid) {
        newSalesItems.push(salesItem);
        SalesModel.updateItem(salesItem);
      }
    }

    salesData.Items = newSalesItems;
  }
}