import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  CreatedDate: {
    Label: AppCatalog.Field.CreatedDate.Label + " Kartu",
    Type: AppCatalog.Field.CreatedDate.Type,
    ReadOnly: AppCatalog.Field.CreatedDate.ReadOnly,
    Output: AppCatalog.Field.CreatedDate.Output
  },
  TransactionDate: {
    Label: AppCatalog.Field.Date.Label + " Transaksi",
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  TransactionNumber: {
    Label: AppCatalog.Field.Number.Label + " Transaksi",
    Type: AppCatalog.Field.Number.Type,
    ReadOnly: true
  },
  TypeID: {
    Label: AppModule.TransactionType.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "TypeName"
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse
  }
}