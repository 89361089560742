<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import InvItemCatalog from "../InvItemCatalog.js";
import Model from "../InvModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.SalesDraftID.ExportLabel);
      this.addColumnHeader(Catalog.SalesDraftID.Label);
      this.addColumnHeader(Catalog.SalesDraftIsActive);
      this.addColumnHeader(Catalog.SONumber);
      this.addColumnHeader(Catalog.InvoiceDate);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.TransactionID.Label);
      this.addColumnHeader(Catalog.TransactionID);
      this.addColumnHeader(Catalog.TransactionDate);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.ClientAddress);
      this.addColumnHeader(Catalog.PONumber);
      this.addColumnHeader(Catalog.DiscValue);
      this.addColumnHeader(Catalog.TotalDisc);
      this.addColumnHeader(Catalog.Total);
      this.addColumnHeader(Catalog.Status);
      this.addColumnHeader(Catalog.IsNeedDelivery);
      this.addColumnHeader(Catalog.DeliveryDate);
      this.addColumnHeader(Catalog.IsPos);
      this.addColumnHeader(Catalog.PaymentTypeName);
      this.addColumnHeader(Catalog.PaymentValue);
      this.addColumnHeader(Catalog.DueDate);
      this.addColumnHeader(Catalog.RecTotal);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(InvItemCatalog.DispatchID.Label);
        this.addColumnHeader(InvItemCatalog.DispatchID);
        this.addColumnHeader(InvItemCatalog.Packaging);
        this.addColumnHeader(InvItemCatalog.RequestedQuantity);
        this.addColumnHeader(InvItemCatalog.TotalRequestedQuantity);
        this.addColumnHeader_ID(InvItemCatalog.SpecialPriceID.Label);
        this.addColumnHeader(InvItemCatalog.SpecialPriceID);
        this.addColumnHeader(InvItemCatalog.SpecialPriceDiscPercent);
        this.addColumnHeader(InvItemCatalog.SellPrice);
        this.addColumnHeader(InvItemCatalog.Price);
        if (this.access.Glob_HPP) {
          this.addColumnHeader(InvItemCatalog.HppTotal);
        }
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("SalesDraftID", row, Catalog);
        this.addCellRelatedValue("SalesDraftID", row, Catalog);
        if (row.SalesDraftID !== null) {
          this.addCellStatus(row.SalesDraftIsActive, 
            Model.StatusSalesDraftIsActive);
        } else {
          this.addCell();
        }
        this.addCellValue("SONumber", row, Catalog);
        this.addCellValue("InvoiceDate", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("TransactionID", row, Catalog);
        this.addCellRelatedValue("TransactionID", row, Catalog);
        this.addCellValue("TransactionDate", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("ClientAddress", row, Catalog);
        this.addCellValue("PONumber", row, Catalog);
        this.addCellValue(row.DiscValue ? "DiscValue" : "DiscPercent",
          row, Catalog);
        this.addCellValue("TotalDisc", row, Catalog);
        this.addCellValue("Total", row, Catalog);
        this.addCellStatus(row.Status, Model.Status);
        this.addCellValue("IsNeedDelivery", row, Catalog);
        this.addCellValue("DeliveryDate", row, Catalog);
        this.addCellStatus(row.IsPos, Model.RecordType);
        this.addCellValue("PaymentTypeName", row, Catalog);
        this.addCellValue("PaymentValue", row, Catalog);
        this.addCellValue("DueDate", row, Catalog);
        this.addCellValue("RecTotal", row, Catalog);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, InvItemCatalog);
          this.addCellRelatedValue("DispatchID", row, InvItemCatalog);
          this.addCellValue("Packaging", row, InvItemCatalog);
          this.addCellValue("RequestedQuantity", row, InvItemCatalog);
          this.addCellValue("TotalRequestedQuantity", row, InvItemCatalog);
          this.addCellValue("SpecialPriceID", row, InvItemCatalog);
          this.addCellRelatedValue("SpecialPriceID", row, InvItemCatalog);
          this.addCellValue("SpecialPriceDiscPercent", row, InvItemCatalog);
          this.addCellValue("SellPrice", row, InvItemCatalog);
          this.addCellValue("Price", row, InvItemCatalog);
          if (this.access.Glob_HPP) {
            this.addCellValue("HppTotal", row, InvItemCatalog);
          }
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>