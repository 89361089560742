<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row, index }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>

        <kst-input box-off field-only class="is-hidden"
          field="DispatchID" :data="row" :catalog="InvItemCatalog"
          :index="index" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Name"
      :label="InvItemCatalog.Name.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="InvItemCatalog"
        :index="index" @input="handleName" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.Edit.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="InvItemCatalog.PackagingName.Label"
    >
      <template v-if="row.SalesDraftItemID === null">
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="InvItemCatalog"
          :index="index" :options="row.PackagingOptions"
          @input="handlePackaging(row)" @error="handleRowErrors"
        />
      </template>
      <template v-else>
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="InvItemCatalog"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="RequestedQuantity"
      :label="InvItemCatalog.RequestedQuantity.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="InvItemCatalog"
        :customValidations="row.CustomValidations"
        :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-price-button"
      field="SellPrice"
      :label="InvItemCatalog.SellPrice.Label"
    >
      <kst-input error-off field-only :key="row.Key"
        :field="column.field" :data="row" :catalog="InvItemCatalog"
        :index="index" @input="handleSellPrice(row)" @error="handleRowErrors"
      >
        <template #right v-if="row.SalesDraftItemID === null">
          <div class="control">
            <kst-tooltip search-mode hover-only
              position="is-bottom"
              :content="InvItemCatalog.SellPrice.Label"
            >
              <kst-button search-mode @click="handleSearch_price(index)"/>
            </kst-tooltip>
          </div>
        </template>
      </kst-input>
    </b-table-column>

    <template v-if="userData.SpecialPriceCount > 0">
      <b-table-column
        v-slot="{ column, row, index }"
        cell-class="ks-col-specialprice-button"
        field="SpecialPriceID"
        :label="InvItemCatalog.SpecialPriceID.Label"
      >
        <kst-input error-off field-only
          :field="column.field" :data="row" 
          :options="row.SpecialPriceOptions"
          :catalog="InvItemCatalog"
          :index="index"
          @input="handleSpecialPrice(row)"
        />
      </b-table-column>
    </template>

    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="Price"
      :label="InvItemCatalog.Price.Label"
    >
      <kst-output box-off field-only
        :field="column.field" :data="row" :catalog="InvItemCatalog"
      />
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.InvoiceItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../InvInfo.js";
import InvItemCatalog from "../InvItemCatalog.js";
import Model from "../InvModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userData: Object,
    userItems: Array,
    deliveryItemList: Array,
    stockSpecialPriceObj: Object
  },
  data: () => ({
    Model: Model,
    InvItemCatalog: InvItemCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItemByPackaging(item);
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.Edit.validateItemsByDeliveryItems(this.userData, this.userItems,
        this.deliveryItemList
      );
      Model.updateDetails(this.userData, this.userItems);
    },
    handleQuantity(item) {
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.Edit.validateItemsByDeliveryItems(this.userData, this.userItems,
        this.deliveryItemList
      );
      Model.updateDetails(this.userData, this.userItems);
    },
    handleName() {
      Model.Edit.validateItemsByDeliveryItems(this.userData, this.userItems,
        this.deliveryItemList
      );
    },
    handleSellPrice(item) {
      Model.updateItem(item);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.Edit_ConfirmDeleteItem,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },
    handleRowDelete_confirm(index) {
      this.userItems.splice(index, 1);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.Edit.validateItemsByDeliveryItems(this.userData, this.userItems,
        this.deliveryItemList
      );
      Model.updateDetails(this.userData, this.userItems);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    },

    handleSearch_price(index) {
      this.$emit(this.$kst.Enum.Event.Callback, index);
    },

    handleSpecialPrice(item) {
      Model.updateItemBySpecialPrice(item);      
      Model.updateItem(item);
      Model.validateItemsByStock(this.userData, this.userItems);
      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>