const caseEnum = {
  Insensitive: 1,
  Sensitive: 2 // default, support non-string type
};
const keyEnum = {
  Field: "Field",
  Order: "Order", // required with default Asc
  Case: "Case" // optional, must be String-type
};
const orderEnum = {
  Asc: 1, // default
  Desc: 2
};

export default {
  // summarize type
  Case: caseEnum,
  Key: keyEnum,
  Order: orderEnum,

  addField(fieldKey) {
    // prop: Field
    let newFieldKey = {
      [keyEnum.Field]: fieldKey[keyEnum.Field]
    };

    // prop: Order
    if (Object.prototype.hasOwnProperty.call(fieldKey, keyEnum.Order)) {
      newFieldKey[keyEnum.Order] = fieldKey[keyEnum.Order];
    }
    else {
      newFieldKey[keyEnum.Order] = orderEnum.Asc;
    }

    // prop: Order
    if (Object.prototype.hasOwnProperty.call(fieldKey, keyEnum.Case)) {
      newFieldKey[keyEnum.Case] = fieldKey[keyEnum.Case];
    }
    else {
      newFieldKey[keyEnum.Case] = caseEnum.Sensitive;
    }

    return newFieldKey;
  },

  compare(fieldKeys) {
    let index = 0;

    return function innerSort(a, b) {
      const fieldKey = fieldKeys[index];

      const field = fieldKey[keyEnum.Field];
      const isAscending = fieldKey[keyEnum.Order] === orderEnum.Asc;
      const isCaseInsensitive = fieldKey[keyEnum.Case] === caseEnum.Insensitive;

      let valueA = a[field];
      let valueB = b[field];

      if (isCaseInsensitive) {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let comparison = 0;
      if (valueA === valueB) {
        if (index + 1 === fieldKeys.length) {
          return 0; // equals
        }
        else {
          index++;
          comparison = innerSort(a, b);
          index--;
          return comparison;
        }
      }
      else if (valueA > valueB) {
        comparison = 1;
      }
      else if (valueA < valueB) {
        comparison = -1;
      }

      return isAscending ? comparison : -1 * comparison;
    }
  }
}