<template>
  <div>
    <div class="base-background"></div>
    <div class="diagonal-background"></div>
    <a :href="websiteUrl" class="company-logo">
      <img src="../../../assets/companylogo_ws.png" class="default-logo" />
      <img src="../../../assets/companylogo.png" class="vertical-logo" />
    </a>
    <!-- </div> -->
    <div class="base-container">
      <div class="slideshow-container">
        <Banner />
        <form class="form-container form-container-signin">
          <span class="form-title">Reset Password</span>
          <div class="form-body form-body-signin">
            <div class="form-group">
              <label for="email" class="main-label">Email *</label>
              <div class="form-inline">
                <input type="text" name="email" id="email" v-model="userData.email">
                <span v-if="errorEmail">
                  <i class="field-status-error mdi mdi-alert"></i>
                  <i class="field-status-message">{{ errorEmail }}</i>
                </span>
              </div>
            </div>
            <div class="form-group no-underline">
              <label for="email" class="link-label"><i>Silakan masukan email untuk mengatur ulang password</i></label>
            </div>
          </div>
          <div v-if="infoMessage">
            <div class="info-message" ref="info">
              <i class="mdi mdi-information"></i>
              <span>{{ infoMessage }}</span>
            </div>
          </div>
          <div v-if="errorMessage">
            <div class="error-message" ref="error">
              <i class="mdi mdi-alert"></i>
              <span>{{ errorMessage }}</span>
            </div>
          </div>
          <div class="form-footer form-footer-signin">
            <div v-if="loading">
              <button class="submit-button" disabled>Memproses <i class="mdi mdi-autorenew mdi-spin"></i></button>
            </div>
            <div v-else>
              <button class="submit-button" @click.prevent="onClickResetPassword">Reset Password</button>
            </div>
          </div>
          <p class="sign-section sign-section-signin">Sudah punya akun?<a v-on:click="onClickLogin">Login</a></p>
        </form>
        <div class="copyright">
          &#169; PT. Kreatifitas Sinergisme Teknoindo 2021
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppModule from "../../../services/App/AppModule.js";
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import WebsiteRouter from "@/components/Website/WebsiteRouter.js";

import Banner from "../SecurityBanner.vue";

export default {
  mixins: [AxiosMixin, RouterMixin],
  components: {
    Banner
  },
  data: () => ({
    userData: {},
    errorEmail: "",
    errorMessage: "",
    infoMessage: "",
    loading: false
  }),
  computed: {
    websiteUrl() {
      return WebsiteRouter.Path.Home;
    }
  },
  methods: {
    validate() {
      this.errorEmail = "";
      this.infoMessage = "";
      let valid = true;
      if(!this.userData.email) {
        this.errorEmail = "Email tidak boleh kosong";
        valid = false;
      }
      return valid;
    },
    onClickResetPassword() {
      // const valid = this.validate();
      // this.loading = true;
    },
    onClickLogin() {
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignIn));
    }
  }
}
</script>

<style scoped lang="css" src="../_layout.css"/>