<template>
  <kst-table action input-mode :data="userItems">
    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="k-control-py"
      field="DispatchID"
      :label="StopItemCatalog.DispatchID.ColumnLabel"
    >
      <kst-value error-off field-only
        class="k-control-py"
        :field="column.field" :data="row" :catalog="StopItemCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode>
          <template #content>
            <kst-list info-mode :items="Model.New.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="PackagingName"
      :label="StopItemCatalog.PackagingName.Label"
    >
      <template v-if="row.IsImport">
        <kst-output box-off field-only
          field="Packaging" :data="row" :catalog="StopItemCatalog"
        />
      </template>
      <template v-else>
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
          :index="index" :options="row.PackagingOptions"
          @input="handlePackaging(row)" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      field="Qty"
      :label="StopItemCatalog.Qty.Label"
    >
      <template v-if="row.IsImport">
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
        />
      </template>
      <template v-else>
        <kst-input error-off field-only
          :field="column.field" :data="row" :catalog="StopItemCatalog"
          :index="index" @input="handleQuantity(row)" @error="handleRowErrors"
        />
      </template>
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Model.StockOpnameItem.Module.FullName">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>

    <template #empty-body>
      <ValidationProvider v-slot="{ errors }" name="userItems" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Info from "../StopInfo.js";
import Model from "../StopModel.js";
import StopItemCatalog from "../StopItemCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    userItems: Array
  },
  data: () => ({
    Model: Model,
    StopItemCatalog: StopItemCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateItemByPackaging(item);
      Model.updateItem(item);
    },
    handleQuantity(item) {
      Model.updateItem(item);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.New_ConfirmDeleteItem,
        this.handleRowDelete_confirm,
        null,
        index
      );
    },
    handleRowDelete_confirm(index) {
      this.userItems.splice(index, 1);
    },

    handleRowErrors(errors, field, index) {
      Model.setItemErrors(this.userItems[index], field, errors);
    }
  }
}
</script>