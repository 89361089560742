import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import StcdSearchCatalog from "./StcdSearchCatalog.js";

export default {
  Button: {
    Transaction: {
      Label: null,
      Class: AppCatalog.Output.Related.Class,
      ModuleType: AppModuleType.Details
    }
  },
  Export: {
    Excel: {
      // file
      SheetName: {
        ItemStock: "Data " + AppModule.Item.FullName,
        StockList: "Data " + AppModule.Stock.FullName,
        TransList: AppModule.Transaction.FullName,
        TransSummary: AppCatalog.Info.Summary + " Transaksi"
      },
      // ui
      Option: {
        ItemDetails: "Data " + AppModule.Item.FullName,
        StockList: "Data " + AppModule.Stock.FullName,
        TransList: AppModule.Transaction.FullName,
        TransSummary: AppCatalog.Info.Summary + " Transaksi"
      }
    }
  },
  Section: {
    ItemDetails: {
      Title: "Data " + AppModule.Item.FullName
    },
    StockList: {
      Title: "Data " + AppModule.Stock.FullName,
      Help: AppCatalog.Help.StockSummary
    },
    TransList: {
      Title: AppModule.Transaction.FullName
    },
    TransSummary: {
      Title: AppCatalog.Info.Summary + " Transaksi",
      Help: StcdSearchCatalog.Date.Label
    }
  }
}