<template>
  <div>
    <ItemSearchList
      @init="handleItem_Init"
      @submit="handleItem_Submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-narrow">
        <kst-output addons floating-label
          field="ItemID" :data="searchData" :catalog="StcdSearchCatalog"
        >
          <template #right>
            <div class="control">
              <template v-if="searchData.ItemID === null">
                <kst-tooltip search-mode hover-only :content="StcdSearchCatalog.ItemID.Label">
                  <kst-button search-mode @click="handleSearch_Item"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="StcdSearchCatalog.ItemID.Label">
                  <kst-button clear-mode @click="handleClear_Item"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="StcdSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="Date" :data="searchData" :catalog="StcdSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StcdSearchCatalog from "../StcdSearchCatalog.js";
import Services from "../../../services/Api/StockCardServices.js";

import ItemSearchList from "../../Item/SearchList/ItemScL.vue";

export default {
  components: {
    ItemSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    StcdSearchCatalog: StcdSearchCatalog,
    // func
    showItemFunc: null
  }),
  methods: {
    /*** by item modal ***/

    handleItem_Init(show) {
      this.showItemFunc = show;
    },
    handleItem_Submit(itemData) {
      this.searchData.ItemID = itemData.ID;
      this.searchData.ItemName = itemData.Name;

      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_Item() {
      this.showItemFunc(Services.getRelatedItemList);
    },
    handleClear_Item() {
      this.searchData.ItemID = null;
      this.searchData.ItemName = null;

      this.handleInput();
    }
  }
}
</script>