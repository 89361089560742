<template>
  <kst-modal
    class="kst-stock-searchpcs"
    :width="900"
    :active="active"
    :title="title"
    @cancel="handleModal_Cancel"
    @submit="handleModal_Submit"
  >
    <kst-section search-mode class="pb-0">
      <Search
        :searchData="searchData"
        @submit="handleSearch_Submit"
        @reset="handleSearch_Reset"
      />
    </kst-section>

    <kst-section search-result-mode select-one-help>
      <List
        :access="access"
        :failureList="failureList"
        :isListLoading="isListLoading"
        :items="items"
        :userData="userData"
        :newItem="newItem"
        :sellPrice="sellPrice"
        :hppPerPcs="hppPerPcs"
        :weight="weight"
        @newitem="handleList_NewItem"
      />
    </kst-section>
  </kst-modal>
</template>

<script>
import AxiosMixin from "@/mixins/AxiosMixin.js";
import RouterModel from "@/router/RouterModel.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import Model from "../StkModel.js";

import Search from "./StkScP_Search.vue";
import List from "./StkScP_List.vue";

export default {
  mixins: [AxiosMixin, SnackbarMixin],
  components: {
    Search,
    List
  },
  props: {
    // button visibility
    newItem: Boolean,
    // field visibility (order by appearance)
    sellPrice: Boolean,
    hppPerPcs: Boolean,
    weight: Boolean,
  },
  data: () => ({
    access: {},
    userData: {
      selected: null
    },
    searchData: {
      Search: "",
      WarehouseID: null
    },
    items: [],
    getListService: null,
    active: false,
    isListLoading: false,
    // support debounce
    loadCounter: 0,
    // error
    failureList: null
  }),
  computed: {
    title() {
      return RouterModel.getNavSubTitle(Model.Item.Module, Model.Item.ModuleType.Search);
    }
  },
  methods: {
    /*** public method ***/

    show(warehouseId, getListService) {
      this.getListService = getListService;
      this.init(warehouseId);
      this.active = true;
    },
    hide() {
      this.active = false;
    },

    /*** private method ***/

    handleModal_Cancel() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.Cancel);
    },
    handleModal_Submit() {
      if (this.userData.selected === null) {
        this.notifyError_SelectOne();
        return;
      }

      this.active = false;
      this.$emit(this.$kst.Enum.Event.Submit, this.userData.selected);
    },

    handleSearch_Submit() {
      this.loadList();
    },
    handleSearch_Reset() {
      this.init_Data(this.searchData.WarehouseID);
      this.loadList();
    },

    handleList_NewItem() {
      this.active = false;
      this.$emit(this.$kst.Enum.Event.NewItem, this.searchData.Search);
    },

    getListOptions() {
      return {
        warehouse: this.searchData.WarehouseID,
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchListFields
      };
    },

    loadList() {
      this.loadCounter++;
      this.failureList = null;
      this.isListLoading = true;
      this.items = [];

      const options = this.getListOptions();
      const config = this.getListService(options);
      this.runAxios(config, this.loadCounter,
        this.loadList_Success,
        this.loadList_Fault,
        this.loadList_Fault
      );
    },
    loadList_End() {
      this.isListLoading = false;
    },
    loadList_Success(data, counter) {
      // handle repeated execution on slow network to show last request
      if (counter !== this.loadCounter) {
        return;
      }

      this.items = data.List;
      this.loadList_End();
      this.$emit(this.$kst.Enum.Event.Load, this.items);
    },
    loadList_Fault(message) {
      this.failureList = message;
      this.loadList_End();
    },

    init(warehouseId) {
      this.init_Data(warehouseId);
      this.loadList();
    },
    init_Data(warehouseId) {
      this.access = this.$kst.Access.create(Model.Access);

      // searchData
      this.searchData.Search = "";
      this.searchData.WarehouseID = warehouseId;

      // userData
      this.userData.selected = null;
    }
  }
}
</script>