import SummaryByAcc from "./PlosModel_Export_SummaryByAcc.js";
import SummaryByCash from "./PlosModel_Export_SummaryByCash.js";
import CstEnum from "../CompanySettingType/CstEnum.js";

export default {
  getSummary(transactionDetails, searchData) {
    return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
      ? SummaryByAcc.getSummary(transactionDetails)
      : SummaryByCash.getSummary(transactionDetails);
  }
}