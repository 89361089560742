<template>
  <div>
    <template v-if="hasDisc">
      <div class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column pb-0 is-narrow">
          <div class="title is-5">
            {{ Catalog.TotalBruto.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right">
            <kst-value class="title is-5"
              field="TotalBruto" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="details.DiscValue !== null" class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5">
            {{ Catalog.DiscValue.Label }}
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5"
              field="DiscValue" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>

      <div v-if="details.DiscPercent !== null" class="columns is-mobile">
        <div class="column is-8 is-hidden-mobile"/>
        <div class="column is-narrow">
          <div class="title is-5 is-flex">
            {{ Catalog.DiscPercent.Label }} (
              <kst-value class="title is-5"
                field="DiscPercent" :data="details" :catalog="Catalog"
              />
            )
          </div>
        </div>
        <div class="column pb-0">
          <div class="has-text-right pb-3 ks-border-b-black">
            <kst-value class="title is-5"
              field="TotalDisc" :data="details" :catalog="Catalog"
            />
          </div>
        </div>
      </div>
    </template>

    <div class="columns is-mobile">
      <div class="column is-8 is-hidden-mobile"/>
      <div class="column is-narrow">
        <div class="title is-5">
          {{ Catalog.Total.Label }}
        </div>
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value class="title is-5"
            field="Total" :data="details" :catalog="Catalog"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    hasDisc() {
      return (this.details.DiscValue === null && this.details.DiscPercent === null)
        ? false : true;
    }
  }
}
</script>