import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./RcpyModel.js";
import PaymentTypeEnum from "../PaymentType/PaytEnum.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.FullName
        }
      },
      // support
      Option: {
        Details: Model.ModuleType.Details.FullName + " " + Model.Module.FullName
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.RecPay.FullName
        },
        Option: {
          InvoiceList: "Dengan " + AppModule.Invoice.FullName,
          List: AppModuleType.List.FullName + " " + AppModule.RecPay.FullName
        }
      }
    }
  },
  Message: {
    New_IsVerified:
      AppCatalog.Message.NeedToVerify,
    RelatedList_NewRecPay:
      "Tidak diperkenankan menambah " + Model.Module.FullName + ", " +
      "saat lunas.",
    RelatedList_NewRecPay_CreditAmount_1:
      AppModule.Invoice.FullName + " ini telah di kreditkan ke pelanggan. " + 
      "Silakan melakukan " + AppModule.RecPay.Name + " sebesar ",
    RelatedList_NewRecPay_CreditAmount_2:
      " dengan " + AppModule.PaymentType.Name + " " + 
      PaymentTypeEnum.PaymentTypeID.CreditNote.Label + " untuk menyesuaikan " + 
      AppCatalog.Field.Total.Label + " " + AppModule.Rec.Name + " pada " +
      AppModule.Invoice.Name,
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    InvoiceList: {
      Title: AppModuleType.List.FullName + " " + Model.RecPayInvoice.Module.FullName
    }
  },
  Tabs: {
    Details: AppModuleType.Details.FullName
  }
}