<template>
  <kst-table paginated view-mode
    :action="hasAccessAction"
    :data="dataList"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="isAllWarehouse">
      <b-table-column sortable
        v-slot="{ column, row }"
        cell-class="ks-col-warehouse"
        field="WarehouseID"
        :label="Catalog.WarehouseID.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="LastInvoiceDate"
      :label="Catalog.LastInvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price"
      field="SellPrice"
      :label="Catalog.SellPrice.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpp"
        field="HppPerPcs"
        :label="Catalog.HppPerPcs.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="access.Glob_HPP">
      <b-table-column numeric sortable
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="HppTotal"
        :label="Catalog.HppTotal.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
      </b-table-column>
    </template>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../StkCatalog.js";

import Action from "./StkL_List_Action.vue";

export default {
  components: {
    Action
  },
  props: {
    access: Object,
    dataList: Array,
    searchData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    hasAccessAction() {
      return this.access.Item_Act_Edit;
    },
    isAllWarehouse() {
      return this.searchData.WarehouseID === this.$kst.Search.OptionAll;
    }
  }
}
</script>