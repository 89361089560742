<template>
  <kst-table paginated view-mode
    :data="items"
    :selected.sync="userData.selected"
  >
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="Catalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="Catalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
    
    <b-table-column numeric
      v-slot="{ column, row }"
      cell-class="ks-col-qty has-text-weight-bold"
      field="Quantity"
      :label="Catalog.Quantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template v-if="sellPrice">
      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="Catalog.SellPrice.Label"
      >
        <template v-if="row.SpecialPriceID === null">
          <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
        </template>
        <template v-else>
          <kst-value
            class="has-text-danger"
            field="SellPriceNetto" :data="row" :catalog="Catalog"
          />
          <kst-value
            class="has-text-grey ks-text-line-through"
            :field="column.field" :data="row" :catalog="Catalog"
          />
        </template>
      </b-table-column>
    </template>

    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-specialprice"
      field="SpecialPriceID"
      :label="Catalog.SpecialPriceID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../StSpCatalog.js";

export default {
  props: {
    items: Array,
    userData: Object,
    // field visibility
    sellPrice: Boolean
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>