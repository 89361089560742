<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Purchase :userData="userData"/>
    <Details
      :userData="userData"
      :returnItemSummaryList="returnItemSummaryList"
      :warehouseOptions="warehouseOptions"
    />
    <Items
      :access="access"
      :userData="userData"
      :userItems="userItems"
      :purchaseItems="purchaseItems"
    />

    <div class="columns">
      <div class="column is-half">
        <Details2 :userData="userData"/>
      </div>
      <div class="column" v-if="access.Fld_Amount">
        <Total :userData="userData"/>
      </div>
    </div>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import Details from "./RcvE_Details.vue";
import Items from "./RcvE_Items.vue";
import Purchase from "./RcvE_Purchase.vue";
import Details2 from "./RcvE_Details2.vue";
import Total from "./RcvE_Total.vue";

export default {
  components: {
    Details,
    Items,
    Purchase,
    Details2,
    Total
  },
  mixins: [InputPageMixin],
  data: () => ({
    access: {},
    userData: {},
    userItems: [],
    purchaseItems: [],
    receiveData: {},
    returnItemSummaryList: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.receiveData = data.Details;
      this.returnItemSummaryList = data.ReturnItemList;
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      
      if (data.PurchaseItemList) {
        this.purchaseItems = Model.Edit.getPurchaseItems(
          data.PurchaseItemList, data.Details.Items
        );
      }

      Model.Edit.setItemsByEditData(this.userData, this.userItems,
        data.Details, this.purchaseItems, this.returnItemSummaryList
      );
      Model.Edit.setDetailsByEditData(this.userData, data.Details);
      Model.setDetailsByCompanySetting(this.userData, data.CompanySetting);
      Model.updateDetails(this.userData, this.userItems);
    },

    handleSubmit(saveData, skipSave) {
      if (Model.Edit.compareData(this.userData, this.userItems,
        this.receiveData
      )) {
        skipSave(
          { [this.$kst.Enum.Response.ID]: this.userData.ID },
          this.handleSubmit_success
        );
        return;
      }

      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.userData = Model.createDetails();
      this.userItems = [];
      this.purchaseItems = [];
      this.receiveData = {};
      this.returnItemSummaryList = [];
      this.warehouseOptions = {};
    }
  }
}
</script>