<template>
  <kst-section>
    <template #title>
      <kst-title standard>{{ Info.Section.TopBuyer.Title }}</kst-title>
    </template>

    <template #top-right>
      <div class="buttons">
        <kst-button actionMode fullLabel slimSize
          class="is-primary"
          :hasAccess="access.Invoice_Act_List"
          :module="Info.Button.TopBuyer.Invoice.Module"
          :moduleType="Info.Button.TopBuyer.Invoice.ModuleType"
          :urlParams="invoiceUrlParams"
        />
        <kst-button actionMode fullLabel slimSize
          class="is-primary"
          :hasAccess="access.RtnInvoice_Act_List"
          :module="Info.Button.TopBuyer.RtnInvoice.Module"
          :moduleType="Info.Button.TopBuyer.RtnInvoice.ModuleType"
          :urlParams="rtnInvoiceUrlParams"
        />
      </div>
    </template>

    <kst-table view-mode :data="dataList">
      <b-table-column
        v-slot="{ row }"
        field="Name"
        :label="DashListCatalog.Name.Label"
      >
        {{ row.Name }}
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-hpptotal"
        field="Total"
        :label="DashListCatalog.Total.TopBuyerLabel"
      >
        <kst-value :field="column.field" :data="row" :catalog="DashListCatalog"/>
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="Qty"
        :label="DashListCatalog.Qty.TopBuyerLabel"
      >
        <kst-value :field="column.field" :data="row" :catalog="DashListCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import DashListCatalog from "../DashListCatalog.js";
import DashTransSearchCatalog from "../DashTransSearchCatalog.js";
import Info from "../DashInfo.js";
import Model from "../DashModel.js";

export default {
  mixins: [RouterMixin],
  props: {
    access: Object,
    dataList: Array,
    searchData: Object
  },
  data: () => ({
    DashListCatalog: DashListCatalog,
    Info: Info
  }),
  computed: {
    invoiceUrlParams() {
      const urlObj = Model.Report.getInvoiceUrlObj_byTopBuyer(
        this.searchData
      );
      const urlQueryObj = this.getUrlQueryObj(
        urlObj.Search, urlObj.Params, DashTransSearchCatalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    },
    rtnInvoiceUrlParams() {
      const urlObj = Model.Report.getRtnInvoiceUrlObj_byTopBuyer(
        this.searchData
      );
      const urlQueryObj = this.getUrlQueryObj(
        urlObj.Search, urlObj.Params, DashTransSearchCatalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  }
}
</script>