<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
    />
    <ModuleAttrRelatedGlobalInput
      :moduleAttrList="userModuleAttrList"
    />
    <ModuleList
      :userModule="userModule"
      :userModuleAttrList="userModuleAttrList"
      :moduleList="moduleList"
    />
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../AcsModel.js";
import Services from "../../../services/Api/AccessServices.js";

import Details from "./AcsN_Details.vue";
import ModuleList from "./AcsN_ModuleList.vue";
import ModuleAttrRelatedGlobalInput from "../../ModuleAttr/RelatedGlobalInput/MattReGI.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    ModuleList,
    ModuleAttrRelatedGlobalInput
  },
  data: () => ({
    userData: {},
    userModule: {
      checkedRows: []
    },
    userModuleAttrList: [],
    moduleList: []
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.moduleList = data.ModuleList;
      this.userModuleAttrList = Model.New.getModuleAttr(
        data.ModuleAttrList, this.moduleList
      );
    },

    handleSubmit(saveData) {
      const data = Model.populateData(
        this.userData, this.userModule.checkedRows, this.userModuleAttrList
      );
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userModule.checkedRows = [];
      this.userModuleAttrList = [];
      this.moduleList = [];
    }
  }
}
</script>