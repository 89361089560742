import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  Button: {
    NewItem: AppModuleType.New.Name + " barang baru"
  },

  Export: {
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Stock.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Stock.FullName
        }
      }
    }
  }
}