import RouterModel from "../../router/RouterModel.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

import PaytE from "./Edit/PaytE.vue";
import PaytL from "./List/PaytL.vue";

export default {
  Path: {
    List: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List),
    Edit: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Edit)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.List),
        component: PaytL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.List),
          module: RouterModel.getModule(AppModule.PaymentType),
          moduleattr: AppModuleAttr.PaymentType_Act_List,
          moduletype: AppModuleType.List,
          // actions
          edit: {
            module: RouterModel.getModule(AppModule.PaymentType),
            moduleattr: AppModuleAttr.PaymentType_Act_Edit,
            moduletype: AppModuleType.Edit
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.PaymentType, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.PaymentType, AppModuleType.Edit),
        component: PaytE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.PaymentType, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.PaymentType),
          moduleattr: AppModuleAttr.PaymentType_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.PaymentType, AppModuleType.List)
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [];
  }
}