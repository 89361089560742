<template>
    <kst-page-list
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    :subtitle="subtitle"
    @init="handleInit"
    @load="handleLoad"
    @reset="handleReset"
  >
    <template #search="{ handleInput }">
      <Search
        :searchData="searchData"
        :accessOptions="accessOptions"
        @input="handleInput"
      />
    </template>
    
    <template #search-result="{ data }">
      <List
        :access="access"
        :items="data.List"
      />
    </template>
  </kst-page-list>
</template>

<script>
import Model from "../UserModel.js";
import SearchCatalog from "./SearchCatalog.js";
import Services from "../../../services/Api/UserServices.js";

import List from "./UserL_List.vue";
import Search from "./UserL_Search.vue";

export default {
  components: {
    List,
    Search
  },
  data: () => ({
    SearchCatalog: SearchCatalog,
    access: {},
    searchData: {
      Search: "",
      IsActive: null,
      AccessID: null
    },
    searchParams: ["Search", "AccessID", "IsActive"],
    accessOptions: {},   
    maxUsers: null,
    activeUsers: null
  }),
  computed:{
    subtitle() {
      return this.maxUsers === null
        ? null : Model.getSubtitle(this.activeUsers, this.maxUsers);
    }
  },
  created() {
    this.resetSearch();
  },
  methods: {
    handleInit(loadListData) {
      this.resetData();

      const options = this.getListOptions();
      const config = Services.getListData(options); 
      loadListData(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.accessOptions = Model.createAccessOptions(
        data.AccessList, SearchCatalog.AccessID.Label
      );
      this.maxUsers = data.MaxUsers;
      this.activeUsers = data.ActiveUsers;
    },

    handleLoad(loadList) {
      const options = this.getListOptions();
      const config = Services.getList(options);
      loadList(config, this.handleLoad_Success);
    },
    handleLoad_Success(data) {
      this.maxUsers = data.MaxUsers;
      this.activeUsers = data.ActiveUsers;
    },
    handleReset() {
      this.resetSearch();
    },

    resetData() {
      this.access = this.$kst.Access.create(Model.Access);
      this.accessOptions = {};

      this.normalizeSearch();
    },

    normalizeSearch() {
      if (this.searchData.AccessID === null) {
        this.searchData.AccessID = this.$kst.Search.OptionAll
      }
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.AccessID = this.$kst.Search.OptionAll;
      this.searchData.IsActive = this.$kst.Search.OptionAll;
    },

    getListOptions() {
      return {
        access: this.$kst.Search.getValue(this.searchData.AccessID),
        active: this.$kst.Search.getValue(this.searchData.IsActive),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.Fields,
      };
    },
  }
}
</script>