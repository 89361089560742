import App from "../../services/App/App.js";
import ModelBody from "./LowstkModel_Body.js";
import AppModule from "../../services/App/AppModule.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.LowStock,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  Item: ModelBody.Item,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Item_Act_Edit;
    }
  },

  /** external */

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}