<template>
  <kst-section>
    <template #title>
      <kst-title standard>{{ Info.Section.LowStock.Title }}</kst-title>
    </template>

    <template #top-right>
      <kst-button actionMode fullLabel slimSize
        class="is-primary"
        :hasAccess="access.LowStock_Act_List"
        :module="Info.Button.LowStock.Module"
        :moduleType="Info.Button.LowStock.ModuleType"
        :urlParams="urlParams"
      />
    </template>

    <kst-table view-mode :data="dataList">
      <b-table-column
        v-slot="{ row }"
        field="Name"
        :label="DashListCatalog.Name.BestSellingItemsLabel"
      >
        {{ row.Name }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="Gudang"
        :label="DashListCatalog.WarehouseID.Label"
      >
        {{ row.Gudang }}
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        field="Qty"
        :label="DashListCatalog.Qty.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="DashListCatalog"/>
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import DashListCatalog from "../DashListCatalog.js";
import DashTransSearchCatalog from "../DashTransSearchCatalog.js";
import Info from "../DashInfo.js";
import Model from "../DashModel.js";

export default {
  mixins: [RouterMixin],
  props: {
    access: Object,
    dataList: Array,
    searchData: Object
  },
  data: () => ({
    DashListCatalog: DashListCatalog,
    Info: Info
  }),
  computed: {
    urlParams() {
      const urlObj = Model.Report.getLowStockUrlObj(
        this.searchData
      );
      const urlQueryObj = this.getUrlQueryObj(
        urlObj.Search, urlObj.Params, DashTransSearchCatalog
      );
      return this.$kst.Route.getParams(urlQueryObj);
    }
  }
}
</script>