import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";
import InvCatalog from "../Invoice/InvCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Client.FullName,
    Type: AppCatalog.Field.Address.Type,
    ReadOnly: true
  },
  ClientID: {
    Label: AppModule.Client.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    RelatedValue: "ClientName",
    RelatedModule: AppModule.Client
  },
  ClientName: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true,
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 1000
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  DiscPercent: {
    Label: AppCatalog.Field.DiscPercent.Label,
    PrintLabel: AppCatalog.Field.DiscPercent.PrintLabel,
    Type: AppCatalog.Field.DiscPercent.Type,
    MinValue: AppCatalog.Field.DiscPercent.MinValue,
    MaxValue: AppCatalog.Field.DiscPercent.MaxValue,
    Required: true
  },
  DiscType: {
    Label: AppCatalog.Field.DiscType.Label,
    Type: AppCatalog.Field.DiscType.Type,
  },
  DiscValue: {
    Label: AppCatalog.Field.DiscValue.Label,
    PrintLabel: AppCatalog.Field.DiscValue.PrintLabel,
    Type: AppCatalog.Field.DiscValue.Type,
    MaxLength: AppCatalog.Field.DiscValue.MaxLength,
    MinValue: AppCatalog.Field.DiscValue.MinValue,
    MaxValue: "TotalBruto",
    Output: AppCatalog.Field.DiscValue.Output,
    Required: true
  },
  ID: {
    Label: AppModule.RtnInvoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "ReturnNumber",
    RelatedModule: AppModule.RtnInvoice,
    Param: AppModule.RtnInvoice.Param + AppCatalog.Field.ID.Param
  },
  ReturnNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppCatalog.Info.Return,
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  SalesOrderID: {
    Label: InvCatalog.SONumber.Label,
    ExportLabel: AppModule.Invoice.FullName,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "SONumber",
    RelatedModule: AppModule.Invoice
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  TotalBruto: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  TotalDisc: {
    Label: AppCatalog.Field.TotalDisc.Label,
    Type: AppCatalog.Field.TotalDisc.Type,
    ReadOnly: AppCatalog.Field.TotalDisc.ReadOnly,
    Output: AppCatalog.Field.TotalDisc.Output
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName + " Pengembalian",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },

  // support
  ClientAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    ReadOnly: true
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " " + AppCatalog.Info.Return,
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: AppCatalog.Field.IsAutoNumber.Output
  },
  SalesOrderDate: {
    Label: InvCatalog.InvoiceDate.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  SONumber: {
    Label: InvCatalog.SONumber.Label,
    Type: AppCatalog.Field.Name.Type,
    Required: true,
    Highlight: true
  },
  TotalItems: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    HelpLabel: "item",
    Type: InputType.Integer,
    ReadOnly: true
  }
}