import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InvItemCatalog from "./InvItemCatalog.js";

export default {
  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    } else {
      // SKU
      infoList.push(InvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, InvItemCatalog)
      );
    }

    // Sales
    if (item.SalesDraftItemID) {
      infoList.push(AppModule.Item.FullName + " dari " +
        AppModule.Sales.FullName
      );
    }

    return infoList;
  }
}