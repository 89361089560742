<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <Details :details="details"/>
  </kst-page-details>
</template>

<script>
import Model from "../SpModel.js";
import Services from "../../../services/Api/SpecialPriceServices.js";

import Details from "./SpD_Details.vue";

export default {
  components: {
    Details,
  },
  data: () => ({
    actions: [],
    details: {},
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;
    },

    /*** custom ***/

    resetData(id) {
      this.details = {};

      // actions
      const access = this.$kst.Access.create(Model.Access);
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, access
      );
    }
  }
}
</script>