<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Items :userData="userData" :userItems="userItems"/>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../HpadModel.js";
import Services from "../../../services/Api/HppAdjServices.js";

import Details from "./HpadN_Details.vue";
import Items from "./HpadN_Items.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Items
  },
  data: () => ({
    userData: {},
    userItems: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_success);
    },
    handleSubmit_success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.warehouseOptions = {};
    }
  }
}
</script>