import App from "../../services/App/App.js";

export default {
  /*** method ***/

  setListByData(checkedRows, detailsPaymentTypeList, paymentTypeList) {
    App.Array.truncate(checkedRows);

    let paymentType;
    for (const row of detailsPaymentTypeList) {
      paymentType = App.Array.searchItem(paymentTypeList, "ID", row.PaymentTypeID);

      if (paymentType) {
        checkedRows.push(paymentType);
      }
    }
  },
}