import App from "../../services/App/App.js";
import ModelBody from "./VenModel_Body.js";
import ModelEdit from "./VenModel_Edit.js";
import ModelDetails from "./VenModel_Details.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  VendorAddress: ModelBody.VendorAddress,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createAddress() {
    return ModelBody.createAddress();
  },

  setAddressErrors(address, field, errors) {
    address.Errors = App.Vee.getRowErrors(address, field, errors);
  },

  setVendorItemErrors(userItem, field, errors) {
    userItem.Errors = App.Vee.getRowErrors(userItem, field, errors);
  },

  populateData(userData, userAddressList) {
    let result = ModelBody.populateDetails(userData);
    result.AddressList = ModelBody.populateAddress(userAddressList);
    return result;
  },
  populateVendorItemData(id, userVendorItemList) {
    return {
      ID: id,
      VendorItemList: ModelBody.populateVendorItems(userVendorItemList)
    };
  },

  updateVendorItem(userItem) {
    ModelBody.updateVendorItem(userItem);
  },
  updateVendorItemByPackaging(userItem) {
    ModelBody.updateVendorItemByPackaging(userItem);
  },
  updateVendorItemList(vendorItemList) {
    return ModelBody.updateVendorItemList(vendorItemList);
  },

  /*** method - Details ***/

  Details: {
    setVendorItemListByData(userVendorItemList, vendorItemList) {
      ModelDetails.setVendorItemListByData(userVendorItemList, vendorItemList);
    },
    setVendorItemsByItem(userVendorItemList, itemList, 
      itemPackagingList, details
    ) {
      ModelDetails.setVendorItemsByItem(userVendorItemList, itemList,
        itemPackagingList, details);
    },
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, vendorData) {
      ModelEdit.setDetailsByData(userData, vendorData);
    },
    setAddressesByData(userAddressList, addressList) {
      ModelEdit.setAddressesByData(userAddressList, addressList);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_Edit ||
        access.Act_ExportDetails;
    }
  }
}