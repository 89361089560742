import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  BuyPrice: {
    Label: AppCatalog.Field.BuyPrice.Label,
    Type: AppCatalog.Field.BuyPrice.Type,
    MinValue: AppCatalog.Field.BuyPrice.MinValue,
    MaxLength: AppCatalog.Field.BuyPrice.MaxLength,
    Output: AppCatalog.Field.BuyPrice.Output,
    Required: true
  },
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "Name",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  ExpenseAmount: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly,
    Output: AppCatalog.Field.SubTotal.Output
  },
  ID: {
    Label: AppModule.Item.FullName + " " + AppModule.Purchase.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    Param: App.Enum.Param.PurchaseItemID
  },
  IsClosed: {
    Label: AppCatalog.Field.IsClosed.Label, 
    ExportLabel: AppCatalog.Field.IsClosed.Label + 
      " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.IsClosed.Type,
    Output: AppCatalog.Field.IsClosed.Output
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    ExportListLabel: AppCatalog.Field.Name.Label + " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    Required: true
  },
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true
  },
  TotalQuantity: {
    Label:AppCatalog.Field.Total.Label +" "+ AppCatalog.Field.Qty.Label,
    ExportListLabel: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  Quantity: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },

  // support
  DispatchImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  QtyAvailable: {
    Label: AppCatalog.Field.Qty.Label + " Pesan",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  QtyReceive: {
    Label: AppCatalog.Field.Qty.Label + " Terima",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  }
}