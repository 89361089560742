import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.hppadj;

export default {
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "warehouse", "search", "field",
      "withitems"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  getPrintDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getRelatedStockList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stock/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  }
}