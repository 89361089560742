import App from "../../services/App/App.js";

export default {
  /*** related ***/

  /*** property ***/

  /*** method ***/

  setDetailsByData(data, details) {
    data.ID = details.ID;
    // user input
    data.Name = App.In.getString(details.Name);
    data.PIC = App.In.getString(details.PIC);
    data.Address = App.In.getString(details.Address);
    data.NoPIC = App.In.getString(details.NoPIC);
    data.IsActive = App.In.getBoolean(details.IsActive);
    data.Comment = App.In.getString(details.Comment);
    // Company Profile Alias user input
    data.CompanyName = App.In.getString(details.CompanyName);
    data.CompanyAddress = App.In.getString(details.CompanyAddress);
    data.CompanyPhone = App.In.getString(details.CompanyPhone);
  },
}