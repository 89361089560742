<template>
  <kst-section collapsed
    :collapsible="isCollapsible"
    :title="sectionTitle"
    :help-collapsed="sectionHelp"
    :help-expanded="sectionHelpExpanded"
    :help="sectionHelp"
  >
    <template v-if="isCollapsible">
      <kst-number-with-date view-mode
        :catalog="Catalog"
        :data="userData"
        isActiveField="SalesDraftIsActive"
        dateField="DraftDate"
        numberField="SalesDraftID"
      />
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    userData: Object,
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.userData.SalesDraftID !== null;
    },
    sectionHelp() {
      return Model.getSalesSectionHelp(this.userData);
    },
    sectionHelpExpanded() {
      return Info.Message.Edit_SalesFields;
    },
    sectionTitle() {
      return Info.Section.Sales.Title;
    }
  }
}
</script>