import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  Email: {
    Label: AppCatalog.Field.Email.Label + " Penerima Pengingat " + 
      AppModule.Rec.FullName,
    Type: AppCatalog.Field.Email.Type,
    MaxLength: 100,
    Valid: true
  }
}