import App from "../../services/App/App.js";
import ChartDayByAcc from "./PlosModel_Report_ChartDayByAcc.js";
import ChartDayByCash from "./PlosModel_Report_ChartDayByCash.js";
import ChartMonthByAcc from "./PlosModel_Report_ChartMonthByAcc.js";
import ChartMonthByCash from "./PlosModel_Report_ChartMonthByCash.js";
import SummaryByAcc from "./PlosModel_Report_SummaryByAcc.js";
import SummaryByCash from "./PlosModel_Report_SummaryByCash.js";
import CstEnum from "../CompanySettingType/CstEnum.js";

export default {
  getChart(transactionDetails, searchData) {
    if (searchData.Date[0].getMonth() === searchData.Date[1].getMonth()) {
      return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
        ? ChartDayByAcc.getChart(transactionDetails)
        : ChartDayByCash.getChart(transactionDetails);
    }
    else {
      const dateDiff = App.Data.getDateDiffInDays(searchData.Date[0],
        searchData.Date[1], true
      );

      if (dateDiff <= 30) {
        return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
          ? ChartDayByAcc.getChart(transactionDetails)
          : ChartDayByCash.getChart(transactionDetails);
      }
      else {
        return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
          ? ChartMonthByAcc.getChart(transactionDetails)
          : ChartMonthByCash.getChart(transactionDetails);
      }
    }
  },

  getSummary(transactionDetails, access, searchData) {
    return searchData.RptTypeID === CstEnum.ProfitLossRptTypeID.AccountingBased.ID
      ? SummaryByAcc.getSummary(transactionDetails, access, searchData)
      : SummaryByCash.getSummary(transactionDetails, access, searchData);
  }
}