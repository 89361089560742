<template>
  <table class="ks-is-fullwidth">
    <template v-if="data.DiscValue !== null || data.DiscPercent !== null">
      <tr>
        <td>
          {{ Catalog.TotalBruto.Label }}
        </td>
        <td class="has-text-right">
         <kst-value class="print-thermal-size" field="TotalBruto" :data="data" :catalog="Catalog"/>
        </td>
      </tr>

      <tr>
        <td class="is-flex">
          <template v-if="data.DiscValue !== null">
            {{ Catalog.DiscValue.PrintLabel }}
          </template>
          <template v-else-if="data.DiscPercent !== null">
            {{ Catalog.DiscPercent.PrintLabel }}
            (<kst-value class="print-thermal-size" field="DiscPercent" :data="data" :catalog="Catalog"/>)
          </template>
        </td>
        <td class="has-text-right">
          <kst-value class="print-thermal-size" field="TotalDisc" :data="data" :catalog="Catalog"/>
        </td>
      </tr>
    </template>

    <tr>
      <td>
        {{ Catalog.Total.Label }}
      </td>
      <td class="has-text-right">
        <kst-value class="print-thermal-size" field="Total" :data="data" :catalog="Catalog"/>
      </td>
    </tr>

    <tr>
      <td>
        {{ Catalog.TotalItems.Label }}
      </td>
      <td class="is-flex is-justify-content-end">
        <kst-value class="print-thermal-size ks-space-right"
          field="TotalItems" :data="data" :catalog="Catalog"
        />
        {{ Catalog.TotalItems.HelpLabel }}
      </td>
    </tr>

    <template v-if="data.PaymentTypeName !== null">
      <tr>
        <td>
          {{ data.PaymentTypeName }}
        </td>
        <td class="has-text-right">
          <kst-value class="print-thermal-size" field="PaymentValue" :data="data" :catalog="Catalog"/>
        </td>
      </tr>

      <tr>
        <td>
          {{ Catalog.PaymentReturn.Label }}
        </td>
        <td class="has-text-right">
          <kst-value class="print-thermal-size" field="PaymentReturn" :data="data" :catalog="Catalog"/>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import Catalog from "../PosCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>