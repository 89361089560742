import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.LowStock,
  ModuleType: {
    List: AppModuleType.List
  },

  /*** related ***/

  Item: {
    Module: AppModule.Item,
    ModuleType: {
      Edit: AppModuleType.Edit
    }
  },

  /*** property ***/

  Access: {
    Act_List: AppModuleAttr.LowStock_Act_List,
    Item_Act_Edit: AppModuleAttr.Item_Act_Edit
  },

  Actions: {
    List: [
      AppModuleType.Edit
    ]
  },

  Search: {
    ListFields: ["Name", "SKU"],
    ListParams: ["Search", "WarehouseID"]
  },

  /*** method ***/
}