import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label,
    Type: AppCatalog.Field.Barcode.Type,
    MaxLength: 50,
    Highlight: true
  },
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 200
  },
  DefaultPackagingID: {
    Label: AppCatalog.Field.Default.Label + " " + AppModule.ItemPackaging.FullName,
    ExportLabel: AppCatalog.Field.Default.Label + " " +
      AppModule.ItemPackaging.FullName + ":",
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "DefaultPackagingName",
    Required: true
  },
  DefaultPackagingName: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppCatalog.Field.Name.Label + " " + AppModule.ItemPackaging.FullName,
  },
  DefaultPackagingQty: {
    Label: AppCatalog.Field.Default.Label + " " + 
      AppCatalog.Field.Qty.Label + " " + AppModule.ItemPackaging.FullName,
  },
  DefaultSellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    ExportLabel: AppCatalog.Field.Default.Label + " " +
      AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    MinValue: AppCatalog.Field.SellPrice.MinValue,
    MaxLength: AppCatalog.Field.SellPrice.MaxLength,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  DefaultWeight: {
    Label: AppCatalog.Field.Weight.Label,
    ExportLabel: AppCatalog.Field.Default.Label + " " +
      AppCatalog.Field.Weight.Label,
    Type: AppCatalog.Field.Weight.Type,
    ReadOnly: true
  },
  ID: {
    HeaderLabel: AppModule.Item.FullName,
    Label: AppCatalog.Field.Name.Label + " " + AppModule.Item.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  LimitQty: {
    Label: AppCatalog.Field.LimitQty.Label,
    ExportLabel: "Limit " + AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.LimitQty.Type,
    MinValue: AppCatalog.Field.LimitQty.MinValue
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    Required: true,
    Highlight: true
  },
  PackagingPcs: {
    Label: AppModule.ItemPackaging.FullName + " " + AppCatalog.PackagingPcs.Name,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Quantity: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: true
  },
  RefID: {
    Label: AppCatalog.Field.Reference.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "RefName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "RefIsActive",
    Param: App.Enum.Param.Reference
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    MaxLength: 45,
    Required: true,
    Highlight: true
  },
  TypeID: {
    Label: AppModule.ItemType.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "TypeName",
    Required: true
  },
  VariantName1: {
    Label: AppCatalog.Field.Name.Label + " " + AppModule.ItemVariant.FullName +
      " #1",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  VariantName2: {
    Label: AppCatalog.Field.Name.Label + " " + AppModule.ItemVariant.FullName +
      " #2",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  VariantType1: {
    Label: AppCatalog.Field.Type.Label + " " + AppModule.ItemVariant.FullName +
      " #1",
    Type: AppCatalog.Field.Type.Type,
    ReadOnly: true
  },
  VariantType2: {
    Label: AppCatalog.Field.Type.Label + " " + AppModule.ItemVariant.FullName +
      " #2",
    Type: AppCatalog.Field.Type.Type,
    ReadOnly: true
  },

  // support
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  PurchaseItemID: {
    Label: AppModule.Item.FullName + " " + AppModule.Purchase.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    Param: App.Enum.Param.PurchaseItemID
  },
  Variant1: {
    Label: AppModule.ItemVariant.FullName + " #1",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Variant2: {
    Label: AppModule.ItemVariant.FullName + " #2",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  }
}