import AppCatalog from "../../../services/App/AppCatalog.js";
import Catalog from "../RcpyCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  ClientID: {
    Label: Catalog.ClientID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    ReadOnly: true
  },
  ClientName: {
    Label: Catalog.ClientID.Label,
    Type: AppCatalog.Field.Name.Type,
    Required: true
  },
  WarehouseID: {
    Label: Catalog.WarehouseID.Label,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input
  }
}