<template>
  <kst-section items-mode>
    <kst-table input-mode :data="items">
      <b-table-column
        v-slot="{ column, row }"
        field="DispatchID"
        :label="StrdItemCatalog.DispatchID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>

      <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
        <template #header>
          <kst-header-blank />
        </template>
        <template #default="{ row }">
          <kst-tooltip info-mode>
            <template #content>
              <kst-list info-mode :items="Model.Details.getItemInfo(row)"/>
            </template>
            <kst-icon info-mode/>
          </kst-tooltip>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-packaging"
        field="Packaging"
        :label="StrdItemCatalog.Packaging.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-qty"
        field="Qty"
        :label="StrdItemCatalog.Qty.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="SellPrice"
        :label="StrdItemCatalog.SellPrice.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>

      <template v-if="data.SpecialPriceCount > 0">
        <b-table-column
          v-slot="{ column, row }"
          cell-class="ks-col-specialpricename"
          field="SpecialPriceID"
          :label="StrdItemCatalog.SpecialPriceID.Label"
        >
          <kst-output field-only
            :field="column.field" :data="row" :catalog="StrdItemCatalog"
          />
        </b-table-column>
      </template>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-subtotal"
        field="Total"
        :label="StrdItemCatalog.Total.Label"
      >
        <kst-output box-off field-only
          :field="column.field" :data="row" :catalog="StrdItemCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import Model from "../StrdModel.js";
import StrdItemCatalog from "../StrdItemCatalog.js";

export default {
  props: {
    data: Object,
    items: Array
  },
  data: () => ({
    Model: Model,
    StrdItemCatalog: StrdItemCatalog
  })
}
</script>