import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import RrcvD from "./Details/RrcvD.vue";
import RrcvE from "./Edit/RrcvE.vue";
import RrcvL from "./List/RrcvL.vue";
import RrcvN from "./New/RrcvN.vue";
import RrcvPD from "./PrintDetails/RrcvPD.vue";
import RrcvPL from "./PrintList/RrcvPL.vue";
import RrcvExl from "./ExportList/RrcvExL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Edit),
    List: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.List),
    New: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.New),
    PrintList: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.PrintList),
    ExportList: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.ExportList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Details),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.Details),
        component: RrcvD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.Edit),
        component: RrcvE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.ExportList),
        component: RrcvExl,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.List),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.List),
        component: RrcvL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.List),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.RtnReceive),
            moduleattr: AppModuleAttr.RtnReceive_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.RtnReceive),
            moduleattr: AppModuleAttr.RtnReceive_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.RtnReceive),
            moduleattr: AppModuleAttr.RtnReceive_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.New),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.New),
        component: RrcvN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.New),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.PrintDetails),
        component: RrcvPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.RtnReceive, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.RtnReceive, AppModuleType.PrintList),
        component: RrcvPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.RtnReceive, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.RtnReceive),
          moduleattr: AppModuleAttr.RtnReceive_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}