<template>
  <kst-page-print-details page-footer
    v-slot="{ title }"
    @init="handleInit"
  >
    <div class="columns is-mobile">
      <div class="column is-half">
        <Company :data="companyData"/>
      </div>
      <div class="column is-half">
        <div class="is-size-3">{{ title }}</div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column">
        <Details :details="details"/>
      </div>
      <div class="column">
        <Client :details="details"/>
      </div>
    </div>

    <Items class="block" :items="details.Items"/>
    <Sign class="block"/>
  </kst-page-print-details>
</template>

<script>
import Model from "../DlvModel.js";
import Services from "../../../services/Api/DeliveryServices.js";

import Details from "./DlvPSJ_Details.vue";
import Client from "./DlvPSJ_Client.vue";
import Items from "./DlvPSJ_Items.vue";
import Sign from "./DlvPSJ_Sign.vue";
import Company from "../../Company/RelatedPrintDetails/CpRPD.vue";

export default {
  components: {
    Details,
    Client,
    Items,
    Sign,
    Company
  },
  data: () => ({
    details: {},
    companyData: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintSJData(id);
      loadPrint(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.companyData = data.CompanyDetails;
      this.details = data.Details;

      for (const item of this.details.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.details = {};
      this.companyData = {};
    }
  }
}
</script>