<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-4">
      <kst-input load-on-focus floating-label
        field="Search" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-input floating-label
        field="Vendor"
        :data="searchData"
        :disabled="searchData.VendorID !== null"
        :catalog="SearchCatalog"
        @input="handleInput"
      >
      <template #right>
        <div class="control">
          <template v-if="searchData.VendorID !== null">
            <kst-tooltip clear-mode hover-only :content="SearchCatalog.Vendor.Label">
              <kst-button clear-mode @click="handleReset"/>
            </kst-tooltip>
          </template>
          <template v-else>
          </template>
        </div>
      </template>
      </kst-input>
    </div>

    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./SearchCatalog.js";

export default {
  props: {
    searchData: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleReset() {
      this.searchData.VendorID = null;
      this.searchData.Vendor = "";
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>