<template>
  <div>
    <kst-value class="has-text-centered"
      field="SONumber" :data="details" :catalog="Catalog"
    />
    <div class="is-flex ks-flex-center">
      <div class="ks-space-right">
        {{ Catalog.InvoiceDate.PrintFakturLabel }}:
      </div>
      <kst-value field="InvoiceDate" :data="details" :catalog="Catalog"/>
    </div>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>