<template>
  <kst-table action detailed view-mode :data="dataList">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ReductionNumber"
      :label="Catalog.ReductionNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="TransactionNumber"
      :label="Catalog.TransactionNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-price has-text-weight-bold"
      field="Total"
      :label="Catalog.Total.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <template #action="{ row }">
      <Action :access="access" :row="row"/>
    </template>

    <template #detail="{ row }">
      <kst-container class="p-2">
        <Items :items="row.Items"/>
        <Total :data="row"/>
      </kst-container>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../StrdCatalog.js";
import Model from "../StrdModel.js";

import Action from "./StrdRL_Rows_Action.vue";
import Items from "./StrdRL_Rows_Items.vue";
import Total from "./StrdRL_Rows_Total.vue";

export default {
  components: {
    Action,
    Items,
    Total
  },
  props: {
    access: Object,
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>