import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModelBody from "./SpModel_Body.js";
import ModelEdit from "./SpModel_Edit.js";

export default {
  // required in model
  Module: AppModule.SpecialPrice,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    New: AppModuleType.New,
    Select: AppModuleType.Select
  },

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,
  IsActive: ModelBody.IsActive,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.IsActive);
  },
  createOptions(items, optionAllText, optionNonText) {
    return ModelBody.createOptions(items, optionAllText, optionNonText);
  },
  createIsActiveOptions(optionAllText) {
    return ModelBody.createIsActiveOptions(optionAllText, ModelBody.IsActive);
  },

  populateData(data) {
    return ModelBody.populateDetails(data);
  },

  /*** method - Edit ***/
  
  Edit: {
    setDetailsByData(data, details) {
      return ModelEdit.setDetailsByData(data, details);
    }
  },
}