<template>
  <kst-section :title="title">
    <kst-table detailed paginated view-mode :data="moduleList">
      <b-table-column
        v-slot="{ column, row }"
        field="ModuleName"
        :label="AcsModuleCatalog.ModuleName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="AcsModuleCatalog"/>
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="ModuleGroupName"
        :label="AcsModuleCatalog.ModuleGroupName.Label"
      >
        <kst-value :field="column.field" :data="row" :catalog="AcsModuleCatalog"/>
      </b-table-column>

      <template #detail="{ row }">
        <ModuleAttrRelatedDetails
          :moduleAttrList="moduleAttrList"
          :moduleId="row.ModuleID"
          @submit="handleSubmit(row)"
        />
      </template>
    </kst-table>
  </kst-section>
</template>

<script>
import AcsModuleCatalog from "../AcsModuleCatalog.js";
import Info from "../AcsInfo.js";
import Model from "../AcsModel.js";

import ModuleAttrRelatedDetails from "../../ModuleAttr/RelatedDetails/Page.vue";

export default {
  components: {
    ModuleAttrRelatedDetails
  },
  props: {
    moduleList: Array,
    moduleAttrList: Array
  },
  data: () => ({
    AcsModuleCatalog: AcsModuleCatalog,
    Model: Model
  }),
  computed: {
    title() {
      return Info.Section.Modules.Title;
    }
  }
}
</script>