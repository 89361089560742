<template>
  <ValidationProvider
    v-slot="{ errors }"
    :bails="provider.Bails"
    :class="provider.Class"
    :immediate="provider.Immediate"
    :name="catalog[field].Label"
    :rules="validationRules"
    :tag="provider.Tag"
    :vid="vid"
  >
    {{ updateErrors(errors) }}
    <b-field :type="{ 'is-danger': errors[0] }">
      <template v-if="!errorOff" #message>
        <kst-list hide-empty :items="getErrors(errors)"/>
      </template>

      <slot name="left"/>
      <b-timepicker expanded trap-focus
        icon="clock-outline"
        ref="myField"
        v-model="data[field]"
        :disabled="theDisabled"
        :id="vid"
        :position="position"
        :mobile-native="false"
        @input="handleInput"
      />
      <template v-if="!catalog[field].Required">
        <template v-if="!theDisabled">
          <div class="control">
            <kst-tooltip clear-mode hover-only :content="catalog[field].Label">
              <kst-button clear-mode @click="handleClear"/>
            </kst-tooltip>
          </div>
        </template>
      </template>
      <slot name="right" />
    </b-field>
  </ValidationProvider>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    errorOff: Boolean,
    loadOnFocus: Boolean,
    position: String,
    vid: String,
    validationRules: {
      type: Object
    },
    // catalog
    provider: Object
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theDisabled() {
      if (this.disabled === undefined) {
        return this.catalog[this.field].ReadOnly;
      }
      return this.disabled;
    }
  },
  mounted() {
    if (this.loadOnFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      this.$refs.myField.focus();
    },

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    handleClear() {
      this.data[this.field] = null;
      this.$emit(this.$kst.Enum.Event.Input);
    },
    updateErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors);
    },

    getErrors(errors) {
      let result = [];

      for (const error of errors) {
        if (error !== this.$kst.Vee.ValidRule) {
          result.push(error);
        }
      }

      return result;
    }
  }
}
</script>