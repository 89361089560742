<template>
  <ValidationProvider
    v-slot="{ errors }"
    :bails="provider.Bails"
    :class="provider.Class"
    :immediate="provider.Immediate"
    :name="catalog[field].Label"
    :rules="validationRules"
    :tag="provider.Tag"
    :vid="vid"
  >
    {{ updateErrors(errors) }}
    <b-field :type="{ 'is-danger': errors[0] }">
      <template v-if="!errorOff" #message>
        <kst-list hide-empty :items="getErrors(errors)"/>
      </template>

      <slot name="left" />
      <div class="b-numberinput field has-addons">
        <p class="control minus">
          <b-button 
            class="button is-primary is-rounded"
            @click="handleMinus"
          >
            <span class="icon"><i class="mdi mdi-minus mdi-20px"></i></span>
          </b-button>
        </p>
        <p class="control">
          <cleave
            class="input"
            ref="myField"
            v-model="data[field]"
            :class="{ 'is-danger': errors[0] }"
            :disabled="theDisabled"
            :id="vid"
            :maxlength="maxlength"
            :options="cleaveOptions"
            :style="'width: ' + width + 'px'"
            @keydown.native.enter="handleEnter"
            @input="handleInput"
            @focus.native="handleFocus"
          />
        </p>
        <p class="control plus">
          <b-button
            class="button is-primary is-rounded"
            @click="handlePlus"
            >
            <span class="icon"><i class="mdi mdi-plus mdi-20px"></i></span>
          </b-button>
        </p>
      </div>
      <slot name="right" />
    </b-field>
  </ValidationProvider>
</template>

<script>
import AppCatalog from "@/services/App/AppCatalog.js";

export default {
  props: {
    field: {
      type: String,
      required: true 
    },
    data: {
      type: Object,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    },
    disabled: Boolean,
    errorOff: Boolean,
    loadOnFocus: Boolean,
    vid: String,
    validationRules: {
      type: Object
    },
    width: {
      type: Number,
      default: 34 // in pixel(px)
    },
    // catalog
    provider: Object
  },
  computed: {
    cleaveOptions() {
      const options = {
        numeral: true,
        numeralDecimalMark: AppCatalog.Cleave.DecimalMark,
        delimiter: AppCatalog.Cleave.ThousandMark,
        numeralDecimalScale: 0,
      };

      if (this.catalog[this.field].MaxLength !== undefined) {
        options.numeralIntegerScale = this.catalog[this.field].MaxLength;
      }

      if (this.catalog[this.field].MinValue !== undefined) {
        if (this.catalog[this.field].MinValue >= 0) {
          options.numeralPositiveOnly = true;
        }
      }

      return options;
    },
    maxlength() {
      if (this.catalog[this.field].Length !== undefined) {
        return this.catalog[this.field].Length;
      }
      else if (this.catalog[this.field].MaxLength !== undefined) {
        return this.catalog[this.field].MaxLength;
      }

      return undefined;
    },
    theDisabled() {
      if (this.disabled === undefined) {
        return this.catalog[this.field].ReadOnly;
      }
      return this.disabled;
    }
  },
  mounted() {
    if (this.loadOnFocus) {
      this.focus();
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        this.$refs.myField.$el.focus();
      });
    },

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },
    updateErrors(errors) {
      this.$emit(this.$kst.Enum.Event.Error, errors);
    },
    handleFocus(event) {
      this.$emit(this.$kst.Enum.Event.Focus, event);
    },
    handleEnter() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleMinus() {
      this.data[this.field]--;

      if (isNaN(this.data[this.field]) || this.data[this.field] <= 1) {
        this.data[this.field] = 1;
      }
		},
    handlePlus() {
      this.data[this.field]++;
		},

    getErrors(errors) {
      let result = [];

      for (const error of errors) {
        if (error !== this.$kst.Vee.ValidRule) {
          result.push(error);
        }
      }

      return result;
    }
  }
}
</script>