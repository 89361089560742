import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import PurDL from "./Delete/PurDL.vue";
import PurL from "./List/PurL.vue";
import PurN from "./New/PurN.vue";
import PurD from "./Details/PurD.vue";
import PurE from "./Edit/PurE.vue";
import PurExD from "./ExportDetails/PurExD.vue";
import PurExL from "./ExportList/PurExL.vue";
import PurPD from "./PrintDetails/PurPD.vue";
import PurPL from "./PrintList/PurPL.vue";
import PurPT from "./PrintThermal/PurPT.vue";

export default {
  Path: {
    Delete: RouterModel.getPath(AppModule.Purchase, AppModuleType.Delete),
    Details: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Purchase, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Purchase, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Purchase, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Purchase, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Purchase, AppModuleType.New),
    PrintList: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintList),
    PrintDetails: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintDetails),
    PrintThermal: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintThermal)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.Delete),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.Delete),
        component: PurDL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.Delete),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_Delete,
          moduletype: AppModuleType.Delete,
          cancel: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.List)
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.Details),
        component: PurD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.Edit),
        component: PurE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.ExportDetails),
        component: PurExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.ExportList),
        component: PurExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.List),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.List),
        component: PurL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Purchase),
            moduleattr: AppModuleAttr.Purchase_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Purchase),
            moduleattr: AppModuleAttr.Purchase_Act_PrintList,
            moduletype: AppModuleType.PrintList
          },
          new: {
            module: RouterModel.getModule(AppModule.Purchase),
            moduleattr: AppModuleAttr.Purchase_Act_New,
            moduletype: AppModuleType.New
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.New),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.New),
        component: PurN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.List)
          },     
          submit: {
            url: RouterModel.getPath(AppModule.Purchase, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.PrintList),
        component: PurPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintDetails),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.PrintDetails),
        component: PurPD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.PrintDetails),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_PrintDetails,
          moduletype: AppModuleType.PrintDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Purchase, AppModuleType.PrintThermal),
        name: RouterModel.getName(AppModule.Purchase, AppModuleType.PrintThermal),
        component: PurPT,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Purchase, AppModuleType.PrintThermal),
          module: RouterModel.getModule(AppModule.Purchase),
          moduleattr: AppModuleAttr.Purchase_Act_PrintThermal,
          moduletype: AppModuleType.PrintThermal
        }
      }
    ];
  }
}