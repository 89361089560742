import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";

export default {
  // required in model
  Module: AppModule.Invoice,
  ModuleType: {
    Edit: AppModuleType.Edit,
    List: AppModuleType.List
  },

  /*** property ***/

  Format: {
    Prefix: "prefix",
    Suffix: "suffix"
  },

  /*** method ***/
  updateDetails(data) {
    this.helper.updateDetails(data, this.Format);
  },

  helper: {
    updateDetails(data, formatEnum) {
      data.PrefixSuffix = data.AutoNumberTypeFormat.includes(formatEnum.Prefix)
        ? data.Prefix : data.Suffix;
    }
  }
}