import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Catalog from "./PurCatalog.js";
import ReceiveCatalog from "../Receive/RcvCatalog.js";
import RtnReceiveCatalog from "../RtnReceive/RrcvCatalog.js";

export default {
  getMessageConfirmationSection(receiveList, rtnReceiveList) {
    let messageList = [AppCatalog.Message.CannotRestoreDeletedData];
    if (receiveList.length > 0) {
      messageList.push(
        AppModule.Purchase.FullName + " ini punya " + 
        receiveList.length + " " + AppModule.Receive.FullName
      )
    }
    if (rtnReceiveList.length > 0) {
      messageList.push(
        AppModule.Purchase.FullName + " ini punya " + 
        rtnReceiveList.length + " " + AppModule.RtnReceive.FullName
      )
    }
    
    return messageList;
  },

  getPurchaseSectionHelp(purchaseData) {
    return Catalog.PONumber.Label + ": " + 
      App.Value.getValue("PONumber", purchaseData, Catalog) + ", " +
      Catalog.Date.Label + ": " +
      App.Value.getValue("Date", purchaseData, Catalog);
  },

  getReceiveSectionHelp(receiveList) {
    return this.getSectionHelp(
      receiveList, 
      'ReceiveNumber', 
      ReceiveCatalog.ReceiveNumber.Label, 
      AppModule.Receive.FullName
    );
  },

  getRtnReceiveSectionHelp(rtnReceiveList) {
    return this.getSectionHelp(
      rtnReceiveList, 
      'ReturnNumber', 
      RtnReceiveCatalog.ReturnNumber.Label, 
      AppModule.RtnReceive.FullName
    );
  },

  getSectionHelp(dataList, key, label, moduleName) {
    const maxData = 10;

    if (dataList.length > 0) {
      let currentNumber, dataListText = "";

      for (const [i, data] of dataList.entries()) {
        currentNumber = i + 1;

        if (currentNumber !== 1) {
          if (currentNumber > maxData) {
            dataListText += " .... ";
            break;
          } else {
            dataListText += ", ";
          }
        }

        dataListText += data[key];
      }
  
      return label + ": " + dataListText;
    }

    return AppCatalog.Message.Without + " " + moduleName;
  },

  validationPONumber(purchaseData) {
    return {
      [App.Vee.Rule.Is]: {
        target: purchaseData.PONumber,
        label: Catalog.PONumber.Label
      }
    };
  }
}