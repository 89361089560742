<template>
  <div>
    <kst-output print-mode field="PayNumber" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="Date" :data="data" :catalog="Catalog"/>
    <kst-output print-mode field="WarehouseID" :data="data" :catalog="Catalog"/>
    <template v-if="data.StatusID === Model.StatusID.Void.ID">
      <kst-output print-mode field="StatusID" :data="data" :catalog="Catalog"/>
      <kst-output print-mode field="VoidByID" :data="data" :catalog="Catalog"/>
      <kst-output print-mode field="VoidDate" :data="data" :catalog="Catalog"/>
      <kst-output print-mode field="VoidComment" :data="data" :catalog="Catalog"/>
    </template>
  </div>
</template>

<script>
import Catalog from "../DbpyCatalog.js";
import Model from "../DbpyModel.js";

export default {
  props: {
    data: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>