import ModelBody from "./OmzModel_Body.js";
import ModelPrint from "./OmzModel_Print.js";
import ModelReport from "./OmzModel_Report.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  /*** property ***/

  Access: ModelBody.Access,
  Search: ModelBody.Search,

  /*** method ***/

  createChart() {
    return ModelBody.createChart();
  },
  createSummary() {
    return ModelBody.createSummary();
  },

  getChartXLabel(searchData) {
    return ModelBody.getChartXLabel(searchData);
  },

  /*** method - Print ***/

  Print: {
    getSummary(transactionDetails) {
      return ModelPrint.getSummary(transactionDetails);
    }
  },

  /*** method - Report ***/

  Report: {
    getChart(transactionDetails, searchData) {
      return ModelReport.getChart(transactionDetails, searchData);
    },
    getInvoiceSummary(invoiceDetails) {
      return ModelReport.getInvoiceSummary(invoiceDetails);
    },
    getSummary(transactionDetails, access, searchData) {
      return ModelReport.getSummary(transactionDetails, access, searchData);
    }
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}