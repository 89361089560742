import AppCatalog from "../../services/App/AppCatalog.js";

export default {
  DiscValue: {
    Label: AppCatalog.Field.DiscValue.Label,
    Type: AppCatalog.Field.DiscValue.Type,
    ReadOnly: true,
    Output: AppCatalog.Field.DiscValue.Output
  },
  IsActive: {
    Label: AppCatalog.Field.IsActive.Label,
    Type: AppCatalog.Field.IsActive.Type,
    Output: AppCatalog.Field.IsActive.Output
  },
  IsAutoRenewal: {
    Label: AppCatalog.Field.IsAutoRenewal.Label,
    Type: AppCatalog.Field.IsAutoRenewal.Type,
    Output: AppCatalog.Field.IsAutoRenewal.Output
  },
  Lifetime: {
    Label: AppCatalog.Field.Lifetime.Label,
    Type: AppCatalog.Field.Lifetime.Type,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  NextRenewal: {
    Label: AppCatalog.Field.NextRenewal.Label,
    Type: AppCatalog.Field.NextRenewal.Type,
    ReadOnly: true
  },
  PriceBruto: {
    Label: AppCatalog.Field.SellPrice.PrintLabel,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  }
}