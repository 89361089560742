<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseList="warehouseList"
      :warehouseOptions="warehouseOptions"
    />
    <Items
      :userData="userData"
      :userItems="userItems"
      :specialPriceList="specialPriceList"
    />

    <div class="columns">
      <div class="column">
        <Details2 :userData="userData"/>
      </div>
      <div class="column">
        <Total :userData="userData"/>
      </div>
    </div>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../StrdModel.js";
import Services from "../../../services/Api/StockReductionServices.js";

import Details from "./StrdN_Details.vue";
import Details2 from "./StrdN_Details2.vue";
import Items from "./StrdN_Items.vue";
import Total from "./StrdN_Total.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Details2,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    specialPriceList: [],
    warehouseList: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.specialPriceList = data.SpecialPriceList;
      this.warehouseList = data.WarehouseList;
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.setDetailsBySpecialPrice(this.userData,
        this.specialPriceList.length);
      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.specialPriceList = [];
      this.warehouseList = [];
      this.warehouseOptions = {};
    }
  }
}
</script>