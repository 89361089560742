import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.autonumber;

export default {
  getListData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" 
    };
  },
}