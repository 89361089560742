<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Invoice :userData="userData"/>
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Items
      :invoiceItems="invoiceItems"
      :userData="userData"
      :userItems="userItems"
    />

    <div class="columns">
      <div class="column">
        <Details2
          :userData="userData"
          :userItems="userItems"
        />
      </div>
      <div class="column">
        <Total :userData="userData"/>
      </div>
    </div>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../RinvModel.js";
import Services from "../../../services/Api/ReturnInvoiceServices.js";

import Details from "./RinvE_Details.vue";
import Details2 from "./RinvE_Details2.vue";
import Invoice from "./RinvE_Invoice.vue";
import Items from "./RinvE_Items.vue";
import Total from "./RinvE_Total.vue";

export default {
  mixins: [InputPageMixin],
  components: {
    Details,
    Details2,
    Invoice,
    Items,
    Total
  },
  data: () => ({
    userData: {},
    userItems: [],
    rtnInvoiceData: {},
    invoiceItems: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);
      this.rtnInvoiceData = data.Details;
      this.invoiceItems = data.InvoiceItemsWithReturn;

      this.updateByData();
    },

    handleSubmit(saveData, skipSave) {
      if (Model.Edit.compareData(this.userData, this.userItems,
        this.rtnInvoiceData
      )) {
        skipSave(
          { [this.$kst.Enum.Response.ID]: this.userData.ID },
          this.handleSubmit_Success
        );
        return;
      }

      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.rtnInvoiceData = {};
      this.invoiceItems = [];
      this.warehouseOptions = {};
    },

    updateByData() {
      // invoice items
      Model.Edit.updateInvoiceItems(
        this.invoiceItems, this.rtnInvoiceData.Items
      );

      // return invoice
      Model.Edit.setItemsByData(this.userItems, this.rtnInvoiceData.Items);
      Model.Edit.setDetailsByData(this.userData, this.rtnInvoiceData);
      Model.updateDetails(this.userData, this.userItems);
    }
  }
}
</script>