import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  BuyPrice: {
    Label: AppCatalog.Field.BuyPrice.Label,
    Type: AppCatalog.Field.BuyPrice.Type,
    Output: AppCatalog.Field.BuyPrice.Output,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },

  // support
  Barcode: {
    Label: AppCatalog.Field.Barcode.Label,
    Type: AppCatalog.Field.Barcode.Type,
    MaxLength: 50,
    Highlight: true
  },
  ImageSrc: {
    Label: AppCatalog.Field.ImageSrc.Label,
    Type: AppCatalog.Field.ImageSrc.Type,
    ReadOnly: true
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 255,
    Required: true,
    Highlight: true
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    MaxLength: 45,
    Required: true,
    Highlight: true
  }
}