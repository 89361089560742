<template>
  <div class="block">
    <VendorSearchList
      @init="handleVendor_init"
      @submit="handleVendor_submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      :dateValidations="dateValidations"
      :dateDisabled="dateDisabled"
      autoNumberField="IsAutoNumber"
      dateField="Date"
      numberField="ReceiveNumber"
    />

    <kst-output control-off status-off
      field="VendorID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="VendorAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="VendorName"
          :data="userData"
          :disabled="vendorDisabled"
          :catalog="Catalog"
        />
      </template>
      <template #right>
        <template v-if="userData.PurchasingID === null">
          <div class="control">
            <template v-if="userData.VendorID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button search-mode @click="handleSearch_vendor"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.VendorID.Label">
                <kst-button clear-mode @click="handleClear_vendor"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="VendorAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
      :options="warehouseOptions"
    />
  </div>
</template>

<script>
import Catalog from "../RcvCatalog.js";
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import VendorSearchList from "../../Vendor/SearchListAddress/VenScLAddress.vue";

export default {
  components: {
    VendorSearchList
  },
  props: {
    userData: Object,
    userItems: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showFunc: null
  }),
  computed: {
    dateDisabled() {
      return Model.getDateDisabled();
    },
    dateValidations() {
      return Model.New.validationDate(this.userData);
    },
    vendorDisabled() {
      if (this.userData.VendorSourceID ===
        Model.ReceiveVendorSourceID.MasterVendor.ID
      ) {
        return true;
      }

      if (this.userData.VendorID !== null) {
        return true;
      }

      if (this.userData.PurchasingID !== null) {
        return true;
      }

      return false;
    }
  },
  methods: {
    /*** by vendor modal ***/

    handleVendor_init(show) {
      this.showFunc = show;
    },
    handleVendor_submit(vendorData, vendorItemData) {
      if (vendorData.HasItem) {
        let item, vendorItem;
        for (vendorItem of vendorItemData.VendorItemList) {
          item = Model.createItem(this.userData);
          Model.setItemByVendorItem(item, vendorItem);
          Model.updateItem(item);
          this.userItems.push(item);
        }

        Model.updateDetails(this.userData, this.userItems);
      }

      Model.setDetailsByVendor(this.userData, vendorData);
    },

    /*** custom ***/

    handleClear_vendor() {
      Model.clearDetailsByVendor(this.userData);
    },
    handleSearch_vendor() {
      this.showFunc(
        Services.getRelatedVendorAddressList,
        Services.getRelatedVendorItemList
      );
    }
  }
}
</script>