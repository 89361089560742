<template>
  <div>
    <div class="label">
      {{ title }}:
    </div>

    <template v-for="item in items">
      <kst-check
        :key="item.ID"
        :disabled="item.IsDefault"
        :label="item.Name"
        v-model="item.IsSelected"
        @input="handleInput(item)"
      />
    </template>
  </div>
</template>

<script>
import Catalog from "../ModuleAttrCatalog.js";
import Model from "../ModuleAttrModel.js";

export default {
  props: {
    items: Array,
    title: String
  },
  data: () => ({
    Catalog: Catalog
  }),
  methods: {
    handleInput(item) {
      if (item.IsSelected) {
        Model.selectListByModuleAttr(this.items);
        this.$emit(this.$kst.Enum.Event.Input, item);
      }
    }
  }
}
</script>