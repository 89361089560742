<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-narrow">
      <kst-input floating-label
        field="WarehouseID" :data="searchData" :catalog="SearchCatalog"
        :options="warehouseOptions" @input="handleInput"
      />
    </div>
    <div class="column is-narrow">
      <kst-input floating-label
        field="Date" :data="searchData" :catalog="SearchCatalog"
        @input="handleInput"
      />
    </div>

    <div class="column is-narrow">
      <kst-button reset-mode @click="handleReset"/>
    </div>
  </div>
</template>

<script>
import SearchCatalog from "./StockAdjSearchCatalog.js";

export default {
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  methods: {
    handleInput() {
      const filter = {
        startDate: this.searchData.Date[0],
        endDate: this.searchData.Date[1],
        warehouseId: this.searchData.WarehouseID,
        byMonth: 0
      }
      this.$emit(this.$kst.Enum.Event.Submit, filter);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>