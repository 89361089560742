<template>
  <kst-section items-mode>
    <PriceSearch
      @init="handlePrice_init"
      @submit="handlePrice_submit"
    />
    <PurchaseItemSearch
      :items="purchaseItemsSelection"
      @init="handlePurchaseItem_init"
      @submit="handlePurchaseItem_submit"
    />
    <StockSearchListPcs
      @init="handleStock_init"
      @submit="handleStock_submit"
    />

    <template #top-right>
      <div class="buttons">
        <template v-if="userData.PurchasingID && 
          userData.PurchasingIsActive === true">
          <kst-button action-mode
            class="is-primary"
            :label="Model.PurchaseItem.Label"
            :moduleType="Model.PurchaseItem.ModuleType.New"
            @click="handleAdd_purchaseItem"
          />
        </template>
        <kst-button action-mode
          class="is-primary"
          :label="Model.ReceiveItem.Module.FullName"
          :moduleType="Model.ReceiveItem.ModuleType.New"
          @click="handleAdd_item"
        />
      </div>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <div class="buttons">
          <template v-if="userData.PurchasingID && 
            userData.PurchasingIsActive === true">
            <kst-button action-mode
              class="is-primary"
              :label="Model.PurchaseItem.Label"
              :moduleType="Model.PurchaseItem.ModuleType.New"
              @click="handleAdd_purchaseItem"
            />
          </template>
          <kst-button action-mode
            class="is-primary"
            :label="Model.ReceiveItem.Module.FullName"
            :moduleType="Model.ReceiveItem.ModuleType.New"
            @click="handleAdd_item"
          />
        </div>
      </template>
    </template>

    <List
      :access="access"
      :userData="userData" 
      :userItems="userItems"
      @callback="handleList_callback"
    />
  </kst-section>
</template>

<script>
import Model from "../RcvModel.js";
import Services from "../../../services/Api/ReceiveServices.js";

import List from "./RcvE_Items_List.vue";
import PriceSearch from "../SearchListPrice/RcvScLPrice.vue";
import PurchaseItemSearch from "../../Purchase/SearchItem/PurScI.vue";
import StockSearchListPcs from "../../Stock/SearchListPcs/StkScLPcs.vue";

export default {
  components: {
    List,
    PriceSearch,
    PurchaseItemSearch,
    StockSearchListPcs
  },
  props: {
    access: Object,
    userData: Object,
    userItems: Array,
    purchaseItems: Array
  },
  data:() => ({
    Model: Model,
    purchaseItemsSelection: [],
    rowIndex: null,
    // func
    showPriceFunc: null,
    showPurchaseItemFunc: null,
    showStockFunc: null
  }),
  methods: {
    /*** by price modal ***/

    handlePrice_init(show) {
      this.showPriceFunc = show;
    },
    handlePrice_submit(row) {
      let userItem = this.userItems[this.rowIndex];
      Model.setItemByPrice(userItem, row);
      Model.updateItem(userItem);
    },

    /*** by purchase item modal ***/

    handlePurchaseItem_init(show) {
      this.showPurchaseItemFunc = show;
    },
    handlePurchaseItem_submit(purchaseItemData) {
      let item = Model.createItem(this.userData);
      Model.Edit.setItemByPurchase(item, purchaseItemData);
      Model.updateItem(item);
      this.userItems.push(item);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** by stock modal ***/

    handleStock_init(show) {
      this.showStockFunc = show;
    },
    handleStock_submit(stockData, itemData) {
      let item = Model.createItem(this.userData);
      Model.setItemByStock(item, itemData.ItemDetails);
      Model.updateItem(item);
      this.userItems.push(item);
    },

    /*** custom ***/

    handleAdd_item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    },

    handleAdd_purchaseItem() {
      this.purchaseItemsSelection = Model.Edit.getPurchaseItemsForSelection(
        this.purchaseItems, this.userItems
      );
      this.showPurchaseItemFunc();
    },

    handleList_callback(index) {
      this.rowIndex = index;
      const userItem = this.userItems[index];
      const buyPrice = this.$kst.Out.getInteger(userItem.BuyPrice) /
        userItem.PackagingValue;

      this.showPriceFunc(
        // dispatchId, vendorId, vendorName, buyPrice, packagingList, getListService
        userItem.DispatchID,
        this.userData.VendorID,
        this.userData.VendorName,
        buyPrice,
        userItem.PackagingOptions.rows,
        Services.getRelatedReceivePriceList
      );
    }
  }
}
</script>