import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Catalog from "./DbcdCatalog.js";
import DbcdEnum from "./DbcdEnum.js";
import DbcdTransCatalog from "./DbcdTransCatalog.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.DebtCard,
  ModuleType: {
    Export: AppModuleType.Export,
    Print: AppModuleType.Print,
    Report: AppModuleType.Report
  },

  /*** related ***/

  /*** property ***/

  Access: {
    Act_Export: AppModuleAttr.DebtCard_Act_Export,
    Act_Print: AppModuleAttr.DebtCard_Act_Print,
    Act_Report: AppModuleAttr.DebtCard_Act_Report,
    // related
    DebtPay_Act_Details: AppModuleAttr.DebtPay_Act_Details,
    Receive_Act_Details: AppModuleAttr.Receive_Act_Details,
    Vendor_Act_Details: AppModuleAttr.Vendor_Act_Details,
  },

  TypeID: DbcdEnum.TypeID,

  UrlParams: ["TransactionDate", "TypeID", "VendorID", "VendorName",
    "WarehouseID"
  ],

  /*** method ***/

  createTypeOptions(optionAllText) {
    return this.helper.createTypeOptions(optionAllText, DbcdEnum.TypeID);
  },

  getResultInfo(row) {
    return this.helper.getResultInfo(row);
  },

  updateList(dataList) {
    this.helper.updateList(dataList, DbcdEnum.TypeID);
  },

  /*** external ***/

  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  },

  helper: {
    createTypeOptions(optionAllText, typeEnum) {
      const rowId = "ID";
      const rowLabel = "Name";

      const dataList = [
        { [rowId]: typeEnum.DebtPay.ID, [rowLabel]: typeEnum.DebtPay.Label },
        { [rowId]: typeEnum.Receive.ID, [rowLabel]: typeEnum.Receive.Label },
        { [rowId]: typeEnum.RtnReceive.ID, [rowLabel]: typeEnum.RtnReceive.Label }
      ];

      // set: list options
      let listOptions = { id: rowId, label: rowLabel };

      if (optionAllText) {
        listOptions.allActive = true;
        listOptions.allText = optionAllText;
      }

      // create: select rows
      const selectRows = App.Search.createList(dataList, listOptions);

      // create: select options
      return App.In.getSelectOptions(rowId, rowLabel, selectRows);
    },

    getResultInfo(row) {
      let infoList = [];

      // TypeID
      infoList.push(Catalog.TypeID.Label + ": " +
        App.Value.getValue("TypeID", row, Catalog)
      );

      // TransactionDate
      infoList.push(Catalog.TransactionDate.Label + ": " +
        App.Value.getValue("TransactionDate", row, Catalog)
      );

      // ReceiveDate
      infoList.push(DbcdTransCatalog.ReceiveDate.Label + ": " +
        App.Value.getValue("ReceiveDate", row, DbcdTransCatalog)
      );

      return infoList;
    },

    updateList(dataList, typeEnum) {
      for (const row of dataList) {
        switch (row.TypeID) {
          case typeEnum.DebtPay.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.DebtPay);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.DebtPay.Module.FullName;
            break;
          case typeEnum.Receive.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.Receive);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.Receive.Module.FullName;
            break;
          case typeEnum.RtnReceive.ID:
            row.Actions = this.updateList_createActions(row, typeEnum.RtnReceive);
            row.HasAccess = row.Actions.length > 0;
            row.TypeName = typeEnum.RtnReceive.Module.FullName;
            break;
          default:
            row.HasAccess = false;
        }
      }
    },
    updateList_createActions(row, typeEnumObj) {
      let resultList = [];
      const module = typeEnumObj.Module;

      if (App.Access.hasModuleAttr(typeEnumObj.ModuleAttr)) {
        resultList.push(
          App.Access.createAction(
            module,
            AppModuleType.Details,
            App.Route.getParams({
              [AppCatalog.Field.ID.Param]: row.TransactionID
            })
          )
        );
      }

      if (module.ID !== AppModule.Receive.ID) {
        if (App.Access.hasModuleAttr(AppModuleAttr.Receive_Act_Details)) {
          resultList.push(
            App.Access.createAction(
              AppModule.Receive,
              AppModuleType.Details,
              App.Route.getParams({
                [AppCatalog.Field.ID.Param]: row.ReceiveID
              })
            )
          );
        }
      }

      if (row.VendorID !== null) {
        if (App.Access.hasModuleAttr(AppModuleAttr.Vendor_Act_Details)) {
          resultList.push(
            App.Access.createAction(
              AppModule.Vendor,
              AppModuleType.Details,
              App.Route.getParams({
                [AppCatalog.Field.ID.Param]: row.VendorID
              })
            )
          );
        }
      }

      return resultList;
    }
  }
}