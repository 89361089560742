import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./RinvModel_Body.js";
import RinvItemCatalog from "./RinvItemCatalog.js";

export default {
  setDetailsByData(userData, detailsData, discTypeEnum) {
    userData.ID = detailsData.ID;
    // user input
    userData.WarehouseID = detailsData.WarehouseID;
    userData.ReturnNumber = App.In.getString(detailsData.ReturnNumber);
    userData.SalesOrderID = detailsData.SalesOrderID;
    userData.Comment = App.In.getString(detailsData.Comment);
    userData.IsAutoNumber = false;
    // defined by system
    userData.SONumber = detailsData.SONumber;
    userData.ClientID = detailsData.ClientID;
    userData.ClientName = detailsData.ClientName;
    userData.ClientAlias = detailsData.ClientAlias;
    userData.ClientAddress = detailsData.ClientAddress;
    userData.Date = App.In.getDate(detailsData.Date);
    userData.SalesOrderDate = detailsData.SalesOrderDate;

    if (detailsData.DiscValue !== null) {
      userData.DiscType = discTypeEnum.Value.ID;
      userData.DiscValue = App.In.getInteger(detailsData.DiscValue);
    }
    else if (detailsData.DiscPercent !== null) {
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(detailsData.DiscPercent);
    }
    else {
      userData.DiscType = discTypeEnum.None.ID;
    }
  },
  setItemsByData(items, returnItems) {
    App.Array.truncate(items);

    for (const returnItem of returnItems) {
      let item = ModelBody.createItem();
      this.setItemsByData_Item(item, returnItem);
      ModelBody.updateItem(item);
      items.push(item);
    }
  },
  setItemsByData_Item(item, returnItem) {
    item.ID = returnItem.ID;
    // user input
    item.DispatchID = returnItem.DispatchID;
    item.Quantity = App.In.getInteger(returnItem.Quantity);
    // defined by system
    item.DispatchName = App.In.getString(returnItem.DispatchName);
    item.PackagingName = returnItem.PackagingName;
    item.PackagingValue = returnItem.PackagingValue;
    item.SellPrice = returnItem.SellPrice;
    item.SKU = returnItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(returnItem.DispatchIsActive);
    item.Index = returnItem.Index;
    // UI validation
    item.InvoiceItemQty = returnItem.InvoiceItemQty;
  },
  setItemByInvoiceItem(item, invoiceItem) {
    // user input
    item.DispatchID = invoiceItem.DispatchID;
    item.PackagingName = invoiceItem.PackagingName;
    item.Quantity = App.In.getInteger(
      invoiceItem.QtyReturn === null
        ? invoiceItem.QtyAvailable : invoiceItem.QtyReturn
    );
    item.SellPrice = App.In.getInteger(invoiceItem.SellPrice);
    // defined by system
    item.DispatchName = invoiceItem.Name;
    item.PackagingValue = invoiceItem.PackagingValue;
    item.SKU = invoiceItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(invoiceItem.DispatchIsActive);
    item.InvoiceItemQty = invoiceItem.QtyAvailable;
    item.Index = invoiceItem.Index;
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    if (item.DispatchIsActive) {
      // SKU
      infoList.push(RinvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RinvItemCatalog)
      );
    }

    return infoList;
  },

  updateInvoiceItems(invoiceItem, returnItems) {
    for (const returnItem of returnItems) {
      invoiceItem.QtyReturn = null;

      if (returnItem.DispatchID === invoiceItem.DispatchID &&
        returnItem.DispatchName === invoiceItem.Name &&
        returnItem.PackagingName === invoiceItem.PackagingName &&
        returnItem.SellPrice === invoiceItem.SellPrice
      ) {
        invoiceItem.QtyAvailable += returnItem.Quantity;
        invoiceItem.QtyReturn = returnItem.Quantity;

        returnItem.Index = invoiceItem.Index;
        returnItem.InvoiceItemQty = invoiceItem.QtyAvailable;
        return;
      }
    }
  },

  compareData(userData, userItems, rtnInvoiceData, discTypeEnum) {
    const data = ModelBody.populateDetails(userData, discTypeEnum);
    const items = ModelBody.populateItems(userItems);
    let fields;

    // normalize: details
    rtnInvoiceData.DiscPercent = App.Compare.getDecimal(
      rtnInvoiceData.DiscPercent
    );

    /*** compare: details ***/

    // fields: based on populateDetails
    fields = [
      // skip: Date
      "ID", "WarehouseID", "ReturnNumber", "SalesOrderID", "DiscValue",
      "DiscPercent", "Comment"
    ];
    if (!App.Compare.validateObject(data, rtnInvoiceData, fields)) {
      return false;
    }

    /*** compare: items ***/

    // fields: based on populateItems
    fields = [
      // skip: DispatchName
      "ID", "DispatchID", "Quantity", "PackagingName", "PackagingValue",
      "SellPrice"
    ];
    if (!App.Compare.validateArray(items, rtnInvoiceData.Items, fields)) {
      return false;
    }

    return true;
  }
}