<template>
  <div>
    <Summary :details="details"/>

    <kst-section :title="invoiceItemsSectionTitle">
      <InvoiceProfit :items="details.Items"/>
    </kst-section>

    <kst-section :title="rtninvoiceSectionTitle">
      <RtnInvoiceProfit :dataList="profitData.RtnInvoiceList"/>
    </kst-section>
  </div>
</template>

<script>
import Info from "../InvInfo.js";

import Summary from "./InvD_Profit_Summary.vue";
import InvoiceProfit from "../Profit/InvProfit.vue";
import RtnInvoiceProfit from "../../RtnInvoice/Profit/RinvProfit.vue";

export default {
  components: {
    Summary,
    InvoiceProfit,
    RtnInvoiceProfit
  },
  props: {
    details: Object,
    profitData: Object
  },
  computed: {
    invoiceItemsSectionTitle() {
      return Info.Section.ProfitInvoice.Title;
    },
    rtninvoiceSectionTitle() {
      return Info.Section.ProfitRtnInvoice.Title;
    }
  }
}
</script>