import InputType from "../../../services/Input/InputType.js";
import Catalog from "../PurCatalog.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Search: {
    Label: "Cari Nomor PO, atau Vendor",
    Type: InputType.Text,
    MaxLength: 50
  },
  Date: {
    Label: Catalog.Date.Label,
    Type: InputType.Date,
    IsRange: true,
    Required: true,
    RequiredHide: true
  },
  WarehouseID: {
    Label: "Gudang",
    Type: InputType.ID,
    Input: InputType.Select
  }
}