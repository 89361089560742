<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="LogisticsNumber"
      :label="Catalog.LogisticsNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="DepartureDate"
      :label="Catalog.DepartureDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="WarehouseID"
      :label="Catalog.WarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Destination"
      :label="Catalog.Destination.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="VehicleIDNumber"
      :label="Catalog.VehicleIDNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-weight-total has-text-weight-bold"
      field="TotalWeight"
      :label="Catalog.TotalWeight.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-status"
      field="Status"
      :label="Catalog.Status.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../DlvCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>