import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import ExpD from "./Details/ExpD.vue";
import ExpE from "./Edit/ExpE.vue";
import ExpExD from "./ExportDetails/ExpExD.vue"
import ExpExL from "./ExportList/ExpExL.vue";
import ExpL from "./List/ExpL.vue";
import ExpN from "./New/ExpN.vue";
import ExpPL from "./PrintList/ExpPL.vue";

export default {
  Path: {
    Details: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
    Edit: RouterModel.getPath(AppModule.Expense, AppModuleType.Edit),
    ExportDetails: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportDetails),
    ExportList: RouterModel.getPath(AppModule.Vendor, AppModuleType.ExportList),
    List: RouterModel.getPath(AppModule.Expense, AppModuleType.List),
    New: RouterModel.getPath(AppModule.Expense, AppModuleType.New),
    PrintList: RouterModel.getPath(AppModule.Expense, AppModuleType.PrintList)
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.Details),
        component: ExpD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.Details),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_Details,
          moduletype: AppModuleType.Details
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.Edit),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.Edit),
        component: ExpE,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.Edit),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_Edit,
          moduletype: AppModuleType.Edit,
          cancel: {
            url: RouterModel.getPath(AppModule.Expense, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.ExportList),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.ExportList),
        component: ExpExL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.ExportList),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_ExportList,
          moduletype: AppModuleType.ExportList
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.ExportDetails),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.ExportDetails),
        component: ExpExD,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.ExportDetails),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_ExportDetails,
          moduletype: AppModuleType.ExportDetails
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.List),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.List),
        component: ExpL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.List),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_List,
          moduletype: AppModuleType.List,
          // actions
          exportlist: {
            module: RouterModel.getModule(AppModule.Expense),
            moduleattr: AppModuleAttr.Expenses_Act_ExportList,
            moduletype: AppModuleType.ExportList
          },
          new: {
            module: RouterModel.getModule(AppModule.Expense),
            moduleattr: AppModuleAttr.Expenses_Act_New,
            moduletype: AppModuleType.New
          },
          printlist: {
            module: RouterModel.getModule(AppModule.Expense),
            moduleattr: AppModuleAttr.Expenses_Act_PrintList,
            moduletype: AppModuleType.PrintList
          }
        }
      },
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.New),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.New),
        component: ExpN,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.New),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_New,
          moduletype: AppModuleType.New,
          cancel: {
            url: RouterModel.getPath(AppModule.Expense, AppModuleType.List)
          },
          submit: {
            url: RouterModel.getPath(AppModule.Expense, AppModuleType.Details),
            params: {
              [App.Enum.Param.ID]: [App.Enum.Response.ID]
            }
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Expense, AppModuleType.PrintList),
        name: RouterModel.getName(AppModule.Expense, AppModuleType.PrintList),
        component: ExpPL,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Expense, AppModuleType.PrintList),
          module: RouterModel.getModule(AppModule.Expense),
          moduleattr: AppModuleAttr.Expenses_Act_PrintList,
          moduletype: AppModuleType.PrintList
        }
      }
    ];
  }
}