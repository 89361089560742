import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

export default {
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    ReadOnly: true
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  Name: {
    Label: AppCatalog.Field.Name.Label,
    BestSellingItemsLabel: AppCatalog.Field.Name.Label + " Barang",
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    BestSellingItemsLabel: AppCatalog.Field.Qty.Label + " Terjual",
    TopBuyerLabel: "Jumlah Nota",
    Type: AppCatalog.Field.Qty.Type,
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label + " Pembelian",
    TopBuyerLabel: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Hpp.Type,
    Output: AppCatalog.Field.Hpp.Output,
    ReadOnly: true
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
  },
}