import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.stockcard;

export default {
  getExport(params) {
    const attributes = ["item", "warehouse", "startdate", "enddate",
      "withstocklist", "withtransactionsummary", "withtransactionlist"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/export" + urlParams
    };
  },
  getPrintData(params) {
    const attributes = ["item", "warehouse", "startdate", "enddate"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdata" + urlParams
    };
  },
  getRelatedItemList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/list" + urlParams
    };
  },
  getReport(params) {
    const attributes = ["item", "warehouse", "startdate", "enddate"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/report" + urlParams
    };
  },
  getReportData(params) {
    const attributes = ["item", "warehouse", "startdate", "enddate"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/reportdata" + urlParams
    };
  }
}