<template>
  <kst-section list-mode :title="Info.Section.Variant.Title">
    <List :userVariantList="userVariantList"/>
  </kst-section>
</template>

<script>
import Info from "../ItemInfo.js";

import List from "./ItemE_Variant_List.vue";

export default {
  components: {
    List
  },
  props: {
    userVariantList: Array
  },
  data: () => ({
    Info: Info
  })
}
</script>