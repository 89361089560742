<template>
  <kst-section :title="Info.Section.Info.Title">
    <kst-divider columns-mode/>

    <div class="columns is-mobile is-multiline">
      <div class="column is-narrow">
        {{ Catalog.CashReceived.Label }}
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value field="CashReceived" :data="details" :catalog="Catalog"/>
        </div>
      </div>
    </div>

    <div class="columns is-mobile is-multiline">
      <div class="column is-narrow">
        {{ Catalog.WarehouseID.Label }}
      </div>
      <div class="column">
        <div class="has-text-right">
          <kst-value field="WarehouseID" :data="details" :catalog="Catalog"/>
        </div>
      </div>
    </div>
  </kst-section>
</template>

<script>
import Catalog from "../SlrcCatalog.js";
import Info from "../SlrcInfo.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info
  })
}
</script>