<template>
  <div class="kst-icon">
    <template v-if="theIcon">
      <span class="icon" :class="theClass">
        <i :class="theIcon"/>
      </span>
    </template>
    <template v-else>
      <span class="has-text-white has-background-info px-1 k-radius ks-cursor-default">
        {{ theLabel }}
      </span>
    </template>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    icon: String,
    iconClass: String,
    value: [String, Number, Boolean],
    // mode: ONLY select 1 (one)
    addCartMode: Boolean,
    arrowRightMode: Boolean,
    errorMode: Boolean,
    infoMode: Boolean,
    itemActiveMode: Boolean, // for (master) status, i.e. packaging
    itemStatusMode: Boolean, // for (transaction-draft) item status
    newItemMode: Boolean,
    statusMode: Boolean, // for related-master
    warningMode: Boolean
  },
  computed: {
    theClass() {
      return this.getClass();
    },
    theIcon() {
      return this.getIcon();
    },
    theLabel() {
      return this.getLabel();
    }
  },
  methods: {
    getClass() {
      if (this.iconClass !== undefined) {
        return this.iconClass;
      }

      if (this.addCartMode) {
        return AppCatalog.Icon.AddCart.Class;
      }
      else if (this.arrowRightMode) {
        return AppCatalog.Icon.ArrowRight.Class;
      }
      else if (this.errorMode) {
        return AppCatalog.Icon.Error.Class;
      }
      else if (this.infoMode) {
        return AppCatalog.Icon.Info.Class;
      }
      else if (this.itemActiveMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemActive.Active.Class;
        }
        return AppCatalog.Icon.ItemActive.InActive.Class;
      }
      else if (this.itemStatusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemStatus.Closed.Class;
        }
        return AppCatalog.Icon.ItemStatus.Open.Class;
      }
      else if (this.newItemMode) {
        return AppCatalog.Icon.NewItem.Class;
      }
      else if (this.statusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.Status.Active.Class;
        }
        return AppCatalog.Icon.Status.InActive.Class;
      }
      else if (this.warningMode) {
        return AppCatalog.Icon.Warning.Class;
      }

      return undefined;
    },
    getIcon() {
      if (this.icon !== undefined) {
        return this.icon;
      }

      if (this.addCartMode) {
        return AppCatalog.Icon.AddCart.Icon;
      }
      else if (this.arrowRightMode) {
        return AppCatalog.Icon.ArrowRight.Icon;
      }
      else if (this.errorMode) {
        return AppCatalog.Icon.Error.Icon;
      }
      else if (this.infoMode) {
        return AppCatalog.Icon.Info.Icon;
      }
      else if (this.itemActiveMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemActive.Active.Icon;
        }
        return AppCatalog.Icon.ItemActive.InActive.Icon;
      }
      else if (this.itemStatusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemStatus.Closed.Icon;
        }
        return AppCatalog.Icon.ItemStatus.Open.Icon;
      }
      else if (this.newItemMode) {
        return AppCatalog.Icon.NewItem.Icon;
      }
      else if (this.statusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.Status.Active.Icon;
        }
        return AppCatalog.Icon.Status.InActive.Icon;
      }
      else if (this.warningMode) {
        return AppCatalog.Icon.Warning.Icon;
      }

      return undefined;
    },
    getLabel() {
      if (this.addCartMode) {
        return AppCatalog.Icon.AddCart.Label;
      }
      else if (this.arrowRightMode) {
        return AppCatalog.Icon.ArrowRight.Label;
      }
      else if (this.errorMode) {
        return AppCatalog.Icon.Error.Label;
      }
      else if (this.infoMode) {
        return AppCatalog.Icon.Info.Label;
      }
      else if (this.itemActiveMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemActive.Active.Label;
        }
        return AppCatalog.Icon.ItemActive.InActive.Label;
      }
      else if (this.itemStatusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.ItemStatus.Closed.Label;
        }
        return AppCatalog.Icon.ItemStatus.Open.Label;
      }
      else if (this.newItemMode) {
        return AppCatalog.Icon.NewItem.Label;
      }
      else if (this.statusMode) {
        if (this.$kst.Data.isTrue(this.value)) {
          return AppCatalog.Icon.Status.Active.Label;
        }
        return AppCatalog.Icon.Status.InActive.Label;
      }
      else if (this.warningMode) {
        return AppCatalog.Icon.Warning.Label;
      }

      return undefined;
    }
  }
}
</script>