import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import SpadItemCatalog from "./SpadItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: AppModule.SpecialPriceAdj,
  ModuleType: {
    Details: AppModuleType.Details,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList
  },

  /*** related ***/

  SpadItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Details: AppModuleAttr.SpecialPriceAdj_Act_Details,
    Act_ExportList: AppModuleAttr.SpecialPriceAdj_Act_ExportList,
    Act_List: AppModuleAttr.SpecialPriceAdj_Act_List,
    Act_New: AppModuleAttr.SpecialPriceAdj_Act_New,
    Act_PrintDetails: AppModuleAttr.SpecialPriceAdj_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.SpecialPriceAdj_Act_PrintList
  },

  Actions: {
    Details: [
      AppModuleType.PrintDetails
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.PrintDetails
    ]
  },

  Search: {
    ListFields: ["AdjNumber"],
    ListParams: ["Search", "Date", "WarehouseID"]
  },

  /*** method ***/

  createDetails() {
    return {
      // user input
      WarehouseID: App.Session.getDefaultWarehouseID(),
      AdjNumber: "",
      IsAutoNumber: true,
      Comment: "",
      // by system
      Date: App.In.getDateToday(),
      Total: 0,
      SpecialPriceCount: 0,
      // UI validation
      InvalidItems_vsStock: null,
      StockMissingQty: 0
    };
  },
  createItem() {
    return {
      // user input
      DispatchID: null,
      DispatchName: "",
      PackagingName: "",
      Qty: "",
      SpecialPriceID: null,
      // by system
      SKU: "",
      StockQty: 0,
      DispatchSellPrice: 0,
      PackagingValue: 1,
      SellPrice: 0,
      PackagingOptions: null,
      SpecialPriceName: "",
      SpecialPriceDiscPercent: 0,
      SpecialPriceOptions: {},
      // computed
      Packaging: "",
      TotalQty: 0,
      Total: 0,
      Stock: 0,
      // error
      Errors: [],
      ErrorsColl: {}
    };
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    userData.SpecialPriceCount = specialPriceCount;
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    userData.WarehouseID = WarehouseModel.getDefault(
      warehouseList, userData.WarehouseID
    );
  },

  setItemByScanner(userItem, itemData, qty, stockDetails, specialPriceOptions) {
    userItem.DispatchID = itemData.ID;
    userItem.DispatchName = itemData.Name;
    userItem.Qty = App.In.getInteger(qty);
    // by system
    userItem.SKU = itemData.SKU;
    userItem.StockQty = stockDetails.Quantity;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemData.PackagingList
    );
    userItem.SpecialPriceOptions = specialPriceOptions;

    this.setItemByScanner_setPackaging(userItem);
    this.setItemByScanner_setSpecialPrice(userItem);
  },
  setItemByScanner_setPackaging(userItem) {
    let packaging;
    for (packaging of userItem.PackagingOptions.rows) {
      if (packaging.Qty === AppCatalog.PackagingPcs.Qty) {
        userItem.PackagingName = packaging.Name;
        userItem.PackagingValue = packaging.Qty;
        userItem.DispatchSellPrice = packaging.SellPrice;
        return;
      }
    }

    packaging = userItem.PackagingOptions.rows[0];
    userItem.PackagingName = packaging.Name;
    userItem.PackagingValue = packaging.Qty;
    userItem.DispatchSellPrice = packaging.SellPrice;
  },
  setItemByScanner_setSpecialPrice(userItem) {
    if (userItem.SpecialPriceOptions.rows.length > 0) {
      const specialPrice = userItem.SpecialPriceOptions.rows[0];
      const discAmount = App.Data.getDiscPercent_Value(
        userItem.DispatchSellPrice, specialPrice.DiscPercent
      );

      userItem.SpecialPriceID = specialPrice.ID;
      userItem.SpecialPriceName = specialPrice.Name;
      userItem.SpecialPriceDiscPercent = specialPrice.DiscPercent;
      userItem.SellPrice = userItem.DispatchSellPrice - discAmount;
    }
  },
  setItemByStock(userItem, stockDetails, itemDetails, specialPriceOptions) {
    // user input
    userItem.DispatchID = itemDetails.ID;
    userItem.DispatchName = App.In.getString(itemDetails.Name);
    userItem.PackagingName = stockDetails.PackagingName;
    // defined by system
    userItem.SKU = itemDetails.SKU;
    userItem.StockQty = stockDetails.QuantityPcs;
    userItem.DispatchSellPrice = stockDetails.SellPrice;
    userItem.PackagingValue = stockDetails.PackagingQty;
    userItem.SellPrice = stockDetails.SellPrice;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(
      itemDetails.PackagingList
    );
    userItem.SpecialPriceOptions = specialPriceOptions;

    if (userItem.SpecialPriceOptions.rows.length > 0) {
      const specialPrice = userItem.SpecialPriceOptions.rows[0];
      const discAmount = App.Data.getDiscPercent_Value(
        userItem.DispatchSellPrice, specialPrice.DiscPercent
      );

      userItem.SpecialPriceID = specialPrice.ID;
      userItem.SpecialPriceName = specialPrice.Name;
      userItem.SpecialPriceDiscPercent = specialPrice.DiscPercent;
      userItem.SellPrice = userItem.DispatchSellPrice - discAmount;
    }
  },

  getItemInfo(userItem) {
    let infoList = [];

    // SKU
    infoList.push(SpadItemCatalog.SKU.Label + ": " +
      App.Value.getValue("SKU", userItem, SpadItemCatalog)
    );

    // DispatchSellPrice
    infoList.push(SpadItemCatalog.DispatchSellPrice.Label + ": " +
      App.Value.getValue("DispatchSellPrice", userItem, SpadItemCatalog)
    );

    // Stock
    infoList.push(SpadItemCatalog.Stock.Label + ": " +
      App.Value.getValue("Stock", userItem, SpadItemCatalog) + " " +
      userItem.PackagingName
    );

    return infoList;
  },

  getInvalidItems(details) {
    let items = [];

    if (details.InvalidItems_vsStock) {
      for (const invalidItem of details.InvalidItems_vsStock) {
        items.push(
          App.Data.getInvalidStockError(
            invalidItem.TotalQty,
            invalidItem.Stock,
            invalidItem.DispatchName,
            SpadItemCatalog.Stock.Label
          )
        );
      }
    }

    return items;
  },

  updateDetails(userData, userItems) {
    userData.Total = 0;

    for (const userItem of userItems) {
      userData.Total += userItem.Total;
    }
  },

  updateItemsByStockList(userItems, stockList) {
    let stock;

    // update stock
    for (const userItem of userItems) {
      stock = App.Array.searchItem(stockList, "ItemID", userItem.DispatchID);

      if (stock !== null) {
        userItem.StockQty = stock.QuantityPcs;
        this.updateItem(userItem);
      }
    }
  },

  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.Stock = App.Data.getQtyByPackaging(
      userItem.StockQty, userItem.PackagingValue
    );
    userItem.TotalQty = App.Data.getTotalQty(
      userItem.Qty, userItem.PackagingValue
    );
    userItem.Total = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
  },
  updateItemByPackaging(userItem) {
    if (userItem.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        userItem.PackagingOptions.rows, userItem.PackagingName
      );

      if (packagingData) {
        // defined by system
        userItem.PackagingValue = packagingData.Qty;
        userItem.DispatchSellPrice = packagingData.SellPrice;

        // user input
        const discAmount = App.Data.getDiscPercent_Value(
          userItem.DispatchSellPrice, userItem.SpecialPriceDiscPercent
        );
        userItem.SellPrice = userItem.DispatchSellPrice - discAmount;
      }
    }
  },
  updateItemBySpecialPrice(userItem) {
    const specialPrice = App.Array.searchItem(
      userItem.SpecialPriceOptions.rows, "ID", userItem.SpecialPriceID
    );
    userItem.SpecialPriceName = specialPrice.Name;
    userItem.SpecialPriceDiscPercent = specialPrice.DiscPercent;

    const discAmount = App.Data.getDiscPercent_Value(
      userItem.DispatchSellPrice, specialPrice.DiscPercent
    );
    userItem.SellPrice = userItem.DispatchSellPrice - discAmount;
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  validateItemsByStock(userData, userItems) {
    // reset indicator
    userData.StockMissingQty = 0;

    userData.InvalidItems_vsStock = null;
    let invalidItems = [];

    this.validateItemsByStock_Stock(userData, userItems, invalidItems);

    if (invalidItems.length > 0) {
      userData.InvalidItems_vsStock = invalidItems;
    }
  },
  validateItemsByStock_Stock(userData, userItems, invalidItems) {
    const fieldKeys = "DispatchID";
    const fieldValues = [
      { field: "TotalQty", type: App.Pivot.Type.SUM },
      { field: "StockQty", type: App.Pivot.Type.FIRST },
      { field: "DispatchName", type: App.Pivot.Type.FIRST },
    ];
    const pivotItems = App.Pivot.create(userItems, fieldKeys, fieldValues);

    for (const pivotItem of pivotItems) {
      if (pivotItem.TotalQty > pivotItem.StockQty) {
        userData.StockMissingQty = 1;
        invalidItems.push({
          DispatchName: pivotItem.DispatchName,
          TotalQty: pivotItem.TotalQty,
          Stock: pivotItem.StockQty
        });
      }
    }
  },

  populateDetails(userData) {
    return {
      WarehouseID: userData.WarehouseID,
      AdjNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.AdjNumber),
      Date: App.Out.getDateString(userData.Date),
      Comment: App.Out.getString(userData.Comment)
    }
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const userItem of userItems) {
      resultItems.push({
        DispatchID: userItem.DispatchID,
        DispatchName: userItem.DispatchName,
        PackagingName: userItem.PackagingName,
        PackagingValue: userItem.PackagingValue,
        Qty: App.Out.getInteger(userItem.Qty),
        SellPrice: userItem.SellPrice,
        DispatchSellPrice: userItem.DispatchSellPrice,
        SpecialPriceID: userItem.SpecialPriceID,
        SpecialPriceName: userItem.SpecialPriceName,
        SpecialPriceDiscPercent: userItem.SpecialPriceDiscPercent
      });
    }

    return resultItems;
  }
}