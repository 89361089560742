<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <Addresses :items="details.AddressList"/>
      </b-tab-item>
      <b-tab-item :label="tabs.DebtList" v-if="access.Debt_Act_List">
        <Debt
          :details="details"
          :debtList="debtList"
        />
      </b-tab-item>
      <b-tab-item :label="tabs.VendorItemList" v-if="access.Tab_VendorItem">
        <VendorItemList 
          :vendorItemList="vendorItemList" 
          :details="details"
          @submit="handleVendorItemList_submit"
        />
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../VenInfo.js";
import Model from "../VenModel.js";
import Services from "../../../services/Api/VendorService.js";

import Debt from "./VenD_Debt.vue";
import Details from "./VenD_Details.vue";
import Addresses from "./VenD_Addresses.vue";
import VendorItemList from "./VenD_VendorItemList.vue";

export default {
  components: {
    Debt,
    Details,
    Addresses,
    VendorItemList
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    debtList: [],
    vendorItemList: [],
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      DebtList: Info.Tabs.DebtList,
      VendorItemList: Info.Tabs.VendorItemList
    },
    // func
    reloadFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails, reload) {
      this.resetData(id);
      this.reloadFunc = reload;

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;

      if (this.access.Debt_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "DebtList")) {
          this.debtList = data.DebtList;
        }
        else {
          this.access.Delivery_Act_List = false;
        }
      }

      if (this.access.Tab_VendorItem) {
        if (Object.prototype.hasOwnProperty.call(data, "VendorItemList")) {
          this.vendorItemList = data.VendorItemList;
          Model.updateVendorItemList(this.vendorItemList);
        }
        else {
          this.access.Tab_VendorItem = false;
        }
      }
    },

    /*** custom ***/

    handleVendorItemList_submit() {
      this.reloadFunc();
    },

    resetData(id) {
      this.details = {};
      this.debtList = null;
      this.access = this.$kst.Access.create(Model.Access);
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>