<template>
  <kst-container class="kstmod-moduleattr-relateddetails">
    <kst-section :title="title">
      <Section
        :module-attr-list="moduleAttrList"
        :module-id="moduleId"
        :sub-code="null"
      />

      <template v-for="item of subCodeList">
        <div :key="item">
          <kst-divider />
          <div class="label">
            ({{ getSubLabel(item) }})
          </div>

          <Section
            :module-attr-list="moduleAttrList"
            :module-id="moduleId"
            :sub-code="item"
          />
        </div>
      </template>
    </kst-section>
  </kst-container>
</template>

<script>
import Model from "../ModuleAttrModel.js";

import Section from "./Page_Section.vue";

export default {
  components: {
    Section
  },
  props: {
    moduleAttrList: Array,
    moduleId: Number
  },
  data: () => ({
    subCodeList: []
  }),
  computed: {
    title() {
      return Model.ModuleType.List.Name + " " + Model.Module.FullName
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      for (const moduleAttr of this.moduleAttrList) {
        if (moduleAttr.ModuleID === this.moduleId) {
          // populate: ModuleAttrSubCode
          if (moduleAttr.ModuleAttrSubCode !== null) {
            if (!this.subCodeList.includes(moduleAttr.ModuleAttrSubCode)) {
              this.subCodeList.push(moduleAttr.ModuleAttrSubCode);
            }
          }
        }
      }
    },

    getSubLabel(subCode) {
      return Model.getSubLabel(subCode);
    }
  }
}
</script>