<template>
  <div v-if="theVisible" class="kst-errors">
    <b-message type="is-danger" :closable="false">
      <template #header>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-narrow">
            <kst-icon icon-class="has-text-white" error-mode/>
          </div>
          <div class="column pl-0">
            {{ AppCatalog.Message.ErrorOnForm }}
          </div>
        </div>
      </template>
      <div class="content">
        <ul>
          <li
            v-for="(message, index) of theItems"
            :key="index"
            class="has-text-left"
            v-html="message"
          />
        </ul>
      </div>
    </b-message>
  </div>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    items: [Array, String]
  },
  data: () => ({
    AppCatalog: AppCatalog
  }),
  computed: {
    theItems() {
      return Array.isArray(this.items) ? this.items : [this.items];
    },
    theVisible() {
      return this.getVisible();
    }
  },
  methods: {
    getVisible() {
      if (this.items === undefined || this.items === null) {
        return false;
      }
      else if (Array.isArray(this.items)) {
        if (this.items.length === 0) {
          return false;
        }
      }

      return true;
    }
  }
}
</script>