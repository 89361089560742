import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import RouterModel from "../../router/RouterModel.js";

import DashR from "./Report/DashR.vue";

export default {
  Path: {
    Main: RouterModel.getPath(AppModule.Dashboard),
  },

  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Dashboard),
        name: RouterModel.getName(AppModule.Dashboard),
        component: DashR,
        meta: {
          title: RouterModel.getName(AppModule.Dashboard),
          module: RouterModel.getModule(AppModule.Dashboard),
          moduleattr: AppModuleAttr.Dashboard_Act_Dashboard
        }
      }
    ];
  }
}