<template>
  <kst-page-new
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItems="userItems"
      :warehouseOptions="warehouseOptions"
    />
    <Delivery
      :userData="userData" 
    />
    <Items 
      :userData="userData" 
      :userItems="userItems"
    />
    <div class="columns">
      <div class="column">
        <Details2
          :userData="userData"
          :userItems="userItems"
        />
      </div>
      <div class="column">
        <Total :data="userData" />
      </div>
    </div>
  </kst-page-new>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import Delivery from "./SlsN_Delivery.vue";
import Details from "./SlsN_Details.vue";
import Details2 from "./SlsN_Details2.vue";
import Items from "./SlsN_Items.vue";
import Total from "./SlsN_Total.vue";

export default {
  components: {
    Delivery,
    Details,
    Details2,
    Items,
    Total
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userItems: [],
    warehouseOptions: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(loadNew) {
      this.resetData();

      const config = Services.getNewData();
      loadNew(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.warehouseOptions = Model.getWarehouseOptions(data.WarehouseList);

      Model.New.setDetailsByWarehouseList(this.userData, data.WarehouseList);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.postData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
      this.userData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    }
  }
}
</script>