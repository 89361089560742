<template>
  <kst-output control-off status-off
    :field="numberField" :data="data" :catalog="catalog"
  >
    <template #default>
      <kst-output control-off addons floating-label
        :field="numberField" :data="data" :catalog="catalog"
      />
      <div class="control">
        <template v-if="data[numberField] === null">
          <kst-tooltip search-mode hover-only :content="moduleTitle">
            <kst-button search-mode @click="handleSearch"/>
          </kst-tooltip>
        </template>
        <template v-else>
          <kst-tooltip clear-mode hover-only :content="moduleTitle">
            <kst-button clear-mode @click="handleClear"/>
          </kst-tooltip>
        </template>
      </div>
    </template>
    <template #right>
      <kst-output control-off addons expanded floating-label class="pl-2"
        :field="dateField" :data="data" :catalog="catalog"
      />
    </template>
  </kst-output>
</template>

<script>
export default {
  props: {
    catalog: Object,
    data: Object,
    dateField: String,
    numberField: String
  },
  computed: {
    moduleTitle() {
      if(this.catalog[this.numberField].RelatedModule) {
        return this.catalog[this.numberField].RelatedModule.FullName;
      }
      return null;
    }
  },
  methods: {
    handleSearch() {
      this.$emit(this.$kst.Enum.Event.Search);
    },
    handleClear() {
      this.$emit(this.$kst.Enum.Event.Clear);
    }
  }
}
</script>