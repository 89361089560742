<template>
  <kst-page-export
    :catalog="RccdSearchCatalog"
    :params="Model.UrlParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithRecCard"
      :label="Info.Export.Excel.Option.RecCard"
    />
  </kst-page-export>
</template>

<script>
import Info from "../RccdInfo.js";
import Model from "../RccdModel.js";
import RccdSearchCatalog from "../RccdSearchCatalog.js";
import Services from "../../../services/Api/RecCardServices.js";

import Export from "./RccdEx_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    RccdSearchCatalog: RccdSearchCatalog,
    Info: Info,
    Model: Model,
    searchData: {
      TransactionDate: null,
      ClientID: null,
      ClientName: null,
      TypeID: null,
      WarehouseID: null
    },
    userData: {
      WithRecCard: true
    },
    // func
    exportExcelFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExport(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithRecCard = true;
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    },

    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;

      return {
        client: clientId,
        clientname: clientName,
        type: this.$kst.Search.getValue(this.searchData.TypeID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        startdate: this.$kst.Out.getDateString(this.searchData.TransactionDate[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.TransactionDate[1])
      };
    }
  }
}
</script>