<template>
  <kst-table paginated view-mode
    :action="Model.List.hasAccessAction(access)"
    :data="items"
  > 
    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="Catalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Email"
      :label="Catalog.Email.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Phone"
      :label="Catalog.Phone.Label"  
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="AccessID"
      :label="Catalog.AccessID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DefaultModuleName"
      :label="Catalog.DefaultModuleName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
    
    <b-table-column sortable centered
      v-slot="{ row }"
      cell-class="ks-col-status"
      field="IsActive"
      :label="Catalog.IsActive.Label"
    >
      <kst-status :value="row.IsActive" :statusEnum="Model.IsActive"/>
    </b-table-column>

    <template #action="{ row }">
      <kst-actions :actions="Model.List.getActions(row, access)"/>
    </template>
  </kst-table>
</template>

<script>
import Catalog from "../UserCatalog.js";
import Model from "../UserModel.js";

export default {
  props: {
    access: Object,
    items: Array
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model
  })
}
</script>