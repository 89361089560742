<template>
  <b-dropdown
    aria-role="list"
    class="kst-action"
    position="is-bottom-left"
    :class="theClass"
  >
    <button class="button is-outlined" slot="trigger" :class="theButtonClass">
      <b-icon :icon="theIcon"/>
    </button>
    <slot />
  </b-dropdown>
</template>

<script>
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  props: {
    // mode: ONLY select 1 (one)
    buttonMode: Boolean,
    normalMode: Boolean,
    // icon: ONLY select 1 (one)
    newIcon: Boolean,
    // size
    slimSize: Boolean
  },
  computed: {
    theButtonClass() {
      return this.getButtonClass();
    },
    theClass() {
      return this.getClass();
    },
    theIcon() {
      return this.getIcon();
    }
  },
  methods: {
    getButtonClass() {
      let className = "";
      if (this.slimSize) {
        className += "py-0 ks-height-auto ";
      }

      if (this.buttonMode) {
        return className + AppCatalog.Action.Mode.Button;
      }
      else if (this.normalMode) {
        return className + AppCatalog.Action.Mode.Normal;
      }

      return className + AppCatalog.Action.Mode.Normal;
    },
    getClass() {
      if (this.normalMode) {
        return undefined;
      }

      return "kst-action-position ";
    },
    getIcon() {
      if (this.newIcon) {
        return AppCatalog.Action.Icon.New;
      }

      return AppCatalog.Action.Icon.Default;
    }
  }
}
</script>