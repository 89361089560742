<template>
  <kst-section :title="Info.Section.PaymentTypeSummaryList.Title">
    <kst-divider columns-mode/>

    <template v-for="summary of paymentTypeSummaryList">
      <div class="columns is-mobile" :key="summary.PaymentTypeName">
        <div class="column is-narrow">
          {{ summary.PaymentTypeName }}
        </div>
        <div class="column">
          <div class="has-text-right">
            <kst-value field="Total" :data="summary" :catalog="SlrcInvCatalog"/>
          </div>
        </div>
      </div>
    </template>
  </kst-section>
</template>

<script>
import Info from "../SlrcInfo.js";
import SlrcInvCatalog from "../SlrcInvCatalog.js";

export default {
  props: {
    paymentTypeSummaryList: Array
  },
  data: () => ({
    Info: Info,
    SlrcInvCatalog: SlrcInvCatalog
  })
}
</script>