import AppData from "./App_Data.js";

const typeEnum = {
  SUM: 1,
  COUNT: 2,
  AVERAGE: 3,
  MAX: 4,
  MIN: 5,
  FIRST: 6,
};

export default {
  // summarize type
  Type: typeEnum,

  /**
   * Create data pivot provided by excel / spreadsheet with summary list & object.
   * @param {Array} items
   * @param {Array | String} fieldKeys - single or multiple keys
   * @param {*} fieldValues - keys: field, type (from summarize type section)
   * @param {Array} summaryKeyList - multiple keys
   * i.e.
   * fieldKeys = [ "DispatchID", "PackagingName" ]
   * fieldValues =
   *  [
   *    { field: "Stock", type: MAX },
   *    { field: "Qty", type: SUM }
   *  ]
   * summaryKeyList = [ "DispatchID", "PackagingName" ]
   * WARNING: ensure the fields listed in parameters are available
   */
  createObj(items, fieldKeys, fieldValues, summaryKeyList) {
    const SEPARATOR = "#";
    let resultObj = {};
    let key, dataRow, fieldName, fieldType, value;
    
    let summaryObj = {};
    for (let summaryKey of summaryKeyList) {
      summaryObj[summaryKey] = [];
    }

    // summarize items
    for (const item of items) {
      for (let summaryKey of summaryKeyList) {
        if (AppData.hasProp(item, summaryKey)) {
          if (!summaryObj[summaryKey].includes(item[summaryKey])) {
            summaryObj[summaryKey].push(item[summaryKey]);
          }
        }
      }

      // create key
      key = "";
      if (Array.isArray(fieldKeys)) {
        for (const fieldKey of fieldKeys) {
          key = key + item[fieldKey] + SEPARATOR;
        }
      }
      else {
        key = item[fieldKeys];
      }

      if (AppData.hasProp(resultObj, key)) {
        // get row
        dataRow = resultObj[key];
      }
      else {
        // create row
        dataRow = {};
        resultObj[key] = dataRow;

        // set fieldKeys
        if (Array.isArray(fieldKeys)) {
          for (const fieldKey of fieldKeys) {
            fieldName = fieldKey;
            value = item[fieldName];
            dataRow[fieldName] = { value: value };
          }
        }
        else {
          fieldName = fieldKeys;
          value = item[fieldName];
          dataRow[fieldName] = { value: value };
        }

        // set fieldValues: default values
        for (const fieldValue of fieldValues) {
          fieldName = fieldValue.field;
          fieldType = fieldValue.type;

          switch(fieldType) {
            case typeEnum.SUM:
              dataRow[fieldName] = { value: 0 };
              break;
            case typeEnum.COUNT:
              dataRow[fieldName] = { value: 0 };
              break;
            case typeEnum.AVERAGE:
              dataRow[fieldName] = { value: 0, number: 0, total: 0 };
              break;
            case typeEnum.MAX:
              dataRow[fieldName] = { value: null };
              break;
            case typeEnum.MIN:
              dataRow[fieldName] = { value: null };
              break;
            case typeEnum.FIRST:
              dataRow[fieldName] = { value: null };
              break;
          }
        }
      }

      // set/update values
      for (const fieldValue of fieldValues) {
        fieldName = fieldValue.field;
        fieldType = fieldValue.type;
        value = item[fieldName];

        if (value === undefined || value === null) {
          continue;
        }

        switch(fieldType) {
          case typeEnum.SUM:
            dataRow[fieldName].value += value;
            break;
          case typeEnum.COUNT:
            dataRow[fieldName].value += 1;
            break;
          case typeEnum.AVERAGE:
            dataRow[fieldName].number += 1;
            dataRow[fieldName].total += value;
            dataRow[fieldName].value = dataRow[fieldName].total / dataRow[fieldName].number;
            break;
          case typeEnum.MAX:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            else if (value > dataRow[fieldName].value) {
              dataRow[fieldName].value = value;
            }
            break;
          case typeEnum.MIN:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            else if (value < dataRow[fieldName].value) {
              dataRow[fieldName].value = value;
            }
            break;
          case typeEnum.FIRST:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            break;
        }
      }
    }

    // convert resultObj: Object -> Array
    let resultArr = [];
    let resultRow;

    for (key in resultObj) {
      dataRow = resultObj[key];
      resultRow = {};
      resultArr.push(resultRow);

      for (fieldName in dataRow) {
        resultRow[fieldName] = dataRow[fieldName].value;
      }
    }

    return {
      SummaryList: resultArr,
      SummaryObj: summaryObj
    };
  },

  /**
   * Create data pivot provided by excel / spreadsheet.
   * @param {Array} items
   * @param {Array | String} fieldKeys - single or multiple keys
   * @param {*} fieldValues - keys: field, type (from summarize type section)
   * i.e.
   * fieldKeys = [ "DispatchID", "PackagingName" ]
   * fieldValues =
   *  [
   *    { field: "Stock", type: MAX },
   *    { field: "Qty", type: SUM }
   *  ]
   * WARNING: ensure the fields listed in parameters are available
   */
  create(items, fieldKeys, fieldValues) {
    const SEPARATOR = "#";
    let resultObj = {};
    let key, dataRow, fieldName, fieldType, value;

    // summarize items
    for (const item of items) {
      // create key
      key = "";
      if (Array.isArray(fieldKeys)) {
        for (const fieldKey of fieldKeys) {
          key = key + item[fieldKey] + SEPARATOR;
        }
      }
      else {
        key = item[fieldKeys];
      }

      if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
        // get row
        dataRow = resultObj[key];
      }
      else {
        // create row
        dataRow = {};
        resultObj[key] = dataRow;

        // set fieldKeys
        if (Array.isArray(fieldKeys)) {
          for (const fieldKey of fieldKeys) {
            fieldName = fieldKey;
            value = item[fieldName];
            dataRow[fieldName] = { value: value };
          }
        }
        else {
          fieldName = fieldKeys;
          value = item[fieldName];
          dataRow[fieldName] = { value: value };
        }

        // set fieldValues: default values
        for (const fieldValue of fieldValues) {
          fieldName = fieldValue.field;
          fieldType = fieldValue.type;

          switch(fieldType) {
            case typeEnum.SUM:
              dataRow[fieldName] = { value: 0 };
              break;
            case typeEnum.COUNT:
              dataRow[fieldName] = { value: 0 };
              break;
            case typeEnum.AVERAGE:
              dataRow[fieldName] = { value: 0, number: 0, total: 0 };
              break;
            case typeEnum.MAX:
              dataRow[fieldName] = { value: null };
              break;
            case typeEnum.MIN:
              dataRow[fieldName] = { value: null };
              break;
            case typeEnum.FIRST:
              dataRow[fieldName] = { value: null };
              break;
          }
        }
      }

      // set/update values
      for (const fieldValue of fieldValues) {
        fieldName = fieldValue.field;
        fieldType = fieldValue.type;
        value = item[fieldName];

        if (value === undefined || value === null) {
          continue;
        }

        switch(fieldType) {
          case typeEnum.SUM:
            dataRow[fieldName].value += value;
            break;
          case typeEnum.COUNT:
            dataRow[fieldName].value += 1;
            break;
          case typeEnum.AVERAGE:
            dataRow[fieldName].number += 1;
            dataRow[fieldName].total += value;
            dataRow[fieldName].value = dataRow[fieldName].total / dataRow[fieldName].number;
            break;
          case typeEnum.MAX:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            else if (value > dataRow[fieldName].value) {
              dataRow[fieldName].value = value;
            }
            break;
          case typeEnum.MIN:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            else if (value < dataRow[fieldName].value) {
              dataRow[fieldName].value = value;
            }
            break;
          case typeEnum.FIRST:
            if (dataRow[fieldName].value === null) {
              dataRow[fieldName].value = value;
            }
            break;
        }
      }
    }

    // convert resultObj: Object -> Array
    let resultArr = [];
    let resultRow;

    for (key in resultObj) {
      dataRow = resultObj[key];
      resultRow = {};
      resultArr.push(resultRow);

      for (fieldName in dataRow) {
        resultRow[fieldName] = dataRow[fieldName].value;
      }

    }

    return resultArr;
  }
}