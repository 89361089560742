<template>
  <kst-section :title="title">
    <List
      :userData="userData"
      :paymentTypeList="paymentTypeList"
    />

    <template v-if="userData.checkedRows.length === 0">
      <ValidationProvider v-slot="{ errors }" name="userData" rules="required">
        <kst-error :array-blank-mode="!!errors"/>
      </ValidationProvider>
    </template>
  </kst-section>
</template>

<script>
import Info from "../PaytInfo.js";

import List from "./PaytE_PaytList_List.vue";

export default {
  components: {
    List
  },
  props: {
    userData: Object,
    paymentTypeList: Array
  },
  computed: {
    title() {
      return Info.Section.Modules.Title +
        " (" + this.userData.checkedRows.length + " terpilih)";
    }
  }
}
</script>