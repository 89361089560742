<template>
  <kst-page-export
    :catalog="SearchCatalog"
    :params="searchParams"
    :search="searchData"
    @init="handleInit"
    @export="handleExport"
  >
    <Export
      :userData="userData"
      @init="handleExport_Init"
    />

    <kst-check disabled
      v-model="userData.WithList"
      :label="Info.Export.List.Excel.Option.List"
    />
    <kst-check
      v-model="userData.WithInvoiceList"
      :label="Info.Export.List.Excel.Option.InvoiceList"
    />
  </kst-page-export>
</template>

<script>
import Info from "../RcpyInfo.js";
import Model from "../RcpyModel.js";
import SearchCatalog from "../List/SearchCatalog.js";
import Services from "../../../services/Api/RecPayServices.js";

import Export from "./RcpyExL_Export.vue";

export default {
  components: {
    Export
  },
  data: () => ({
    Info: Info,
    SearchCatalog: SearchCatalog,
    searchData: {
      Search: "",
      ClientID: null,
      ClientName: "",
      Date: null,
      PaymentTypeName: null,
      StatusID: null,
      WarehouseID: null
    },
    userData: {
      WithList: true,
      WithInvoiceList: true
    },
    searchParams: ["Search", "ClientID", "ClientName", "Date",
      "PaymentTypeName", "StatusID", "WarehouseID"],
    // func
    exportExcelFunc: null
  }),
  created() {
    this.resetSearch();
  },
  methods: {
    /*** by page ***/

    handleInit() {
      this.resetData();
    },

    handleExport(loadExport) {
      const options = this.getListOptions();
      const config = Services.getExportList(options);
      loadExport(config, this.handleExport_Success);
    },
    handleExport_Success(data, fileName) {
      this.exportExcelFunc(data, fileName);
    },

    /*** custom ***/

    resetData() {
      this.userData.WithList = true;
      this.userData.WithInvoiceList = true;
    },
    resetSearch() {
      this.searchData.Search = "";
      this.searchData.ClientID = null;
      this.searchData.ClientName = "";
      this.searchData.Date = this.$kst.In.getDefaultDateRange();
      this.searchData.PaymentTypeName = this.$kst.Search.OptionAll;
      this.searchData.StatusID = this.$kst.Search.OptionAll;
      this.searchData.WarehouseID = this.$kst.Session.getDefaultWarehouseID();
    },
    getListOptions() {
      const clientId = this.$kst.Out.getInteger(this.searchData.ClientID);
      const clientName = clientId === null
        ? this.$kst.Out.getString(this.searchData.ClientName) : null;
      const paymentType =
        this.$kst.Search.getValue(this.searchData.PaymentTypeName, null) === null
        ? null
        : this.searchData.PaymentTypeName;

      return {
        startdate: this.$kst.Out.getDateString(this.searchData.Date[0]),
        enddate: this.$kst.Out.getDateString(this.searchData.Date[1]),
        client: clientId,
        clientname: clientName,
        paymenttype: paymentType,
        status: this.$kst.Search.getValue(this.searchData.StatusID),
        warehouse: this.$kst.Search.getValue(this.searchData.WarehouseID),
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.List.Search.Fields,
        withinvoicelist: this.$kst.Out.getBoolean(this.userData.WithInvoiceList)
      };
    },

    handleExport_Init(exportExcel) {
      this.exportExcelFunc = exportExcel;
    }
  }
}
</script>