<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../StkCatalog.js";
import Info from "../StkInfo.js";

export default {
  mixins: [XlsxMixin],
  props: {
    access: Object,
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      let summaryData = null;
      if (this.access.Glob_HPP) {
        if (Object.prototype.hasOwnProperty.call(data, "HppTotal")) {
          summaryData = {
            HppTotal: data.HppTotal
          };
        }
      }

      this.addList(data.List, summaryData);

      this.download(fileName);
    },

    addList(dataList, summaryData) {
      if (summaryData !== null) {
        this.addCellSection(Catalog.HppTotal.ResultLabel);
        this.addCellValue("HppTotal", summaryData, Catalog);

        this.addRow();
        this.addRow();
      }

      // label
      this.addSequenceHeader();
      this.addColumnHeader_ID(Catalog.ItemID.Label);
      this.addColumnHeader(Catalog.ItemID);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader(Catalog.LastInvoiceDate);
      this.addColumnHeader(Catalog.SKU);
      this.addColumnHeader(Catalog.Barcode);
      this.addColumnHeader(Catalog.SellPrice);

      if (this.access.Glob_HPP) {
        this.addColumnHeader(Catalog.HppPerPcs);
      }

      this.addColumnHeader(Catalog.Quantity);
      this.addColumnHeader(Catalog.LimitQty);

      if (this.access.Glob_HPP) {
        this.addColumnHeader(Catalog.HppTotal);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addSequenceCell();
        this.addCellValue("ItemID", row, Catalog);
        this.addCellRelatedValue("ItemID", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("LastInvoiceDate", row, Catalog);
        this.addCellValue("SKU", row, Catalog);
        this.addCellValue("Barcode", row, Catalog);
        this.addCellValue("SellPrice", row, Catalog);

        if (this.access.Glob_HPP) {
          this.addCellValue("HppPerPcs", row, Catalog);
        }

        this.addCellValue("Quantity", row, Catalog);
        this.addCellValue("LimitQty", row, Catalog);

        if (this.access.Glob_HPP) {
          this.addCellValue("HppTotal", row, Catalog);
        }
      }
      
      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>