<template>
  <span/>
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../DlvCatalog.js";
import DlvItemCatalog from "../DlvItemCatalog.js";
import Info from "../DlvInfo.js";
import Model from "../DlvModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader_ID(Catalog.SOID.ExportLabel);
      this.addColumnHeader(Catalog.SOID.Label);
      this.addColumnHeader(Catalog.LogisticsNumber);
      this.addColumnHeader(Catalog.DepartureDate);
      this.addColumnHeader_ID(Catalog.WarehouseID.Label);
      this.addColumnHeader(Catalog.WarehouseID);
      this.addColumnHeader_ID(Catalog.ClientID.Label);
      this.addColumnHeader(Catalog.ClientID);
      this.addColumnHeader(Catalog.Destination);
      this.addColumnHeader(Catalog.VehicleIDNumber);
      this.addColumnHeader(Catalog.TotalWeight);
      this.addColumnHeader(Catalog.Status);

      if (this.userData.WithItems) {
        this.addColumnHeader_ID(DlvItemCatalog.DispatchID.Label);
        this.addColumnHeader(DlvItemCatalog.DispatchID);
        this.addColumnHeader(DlvItemCatalog.Packaging);
        this.addColumnHeader(DlvItemCatalog.Quantity);
        this.addColumnHeader(DlvItemCatalog.Weight);
        this.addColumnHeader(DlvItemCatalog.LogisticsItemsTotalWeight);
      }

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("SOID", row, Catalog);
        this.addCellRelatedValue("SOID", row, Catalog);
        this.addCellValue("LogisticsNumber", row, Catalog);
        this.addCellValue("DepartureDate", row, Catalog);
        this.addCellValue("WarehouseID", row, Catalog);
        this.addCellRelatedValue("WarehouseID", row, Catalog);
        this.addCellValue("ClientID", row, Catalog);
        this.addCellRelatedValue("ClientID", row, Catalog);
        this.addCellValue("Destination", row, Catalog);
        this.addCellValue("VehicleIDNumber", row, Catalog);
        this.addCellValue("TotalWeight", row, Catalog);
        this.addCellStatus(row.Status, Model.Status);

        if (this.userData.WithItems) {
          Model.updateItemReadOnly(row);

          this.addCellValue("DispatchID", row, DlvItemCatalog);
          this.addCellRelatedValue("DispatchID", row, DlvItemCatalog);
          this.addCellValue("Packaging", row, DlvItemCatalog);
          this.addCellValue("Quantity", row, DlvItemCatalog);
          this.addCellValue("Weight", row, DlvItemCatalog);
          this.addCellValue("LogisticsItemsTotalWeight", row, DlvItemCatalog);
        }
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>