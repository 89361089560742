<template>
  <kst-table input-mode :data="emailList">
    <b-table-column
      v-slot="{ column, row }"
      field="Email"
      :label="DebtEmailCatalog.Email.Label"
    >
      <kst-output error-off field-only
        :field="column.field" :data="row" :catalog="DebtEmailCatalog"
      />
    </b-table-column>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Model from "../CpModel.js";
import DebtEmailCatalog from "../CpDebtEmailCatalog.js";

export default {
  mixins: [DialogMixin],
  props: {
    emailList: Array
  },
  data: () => ({
    Model: Model,
    DebtEmailCatalog: DebtEmailCatalog
  })
}
</script>