<template>
  <kst-section collapsed info-mode
    :collapsible="isCollapsible"
    :help-collapsed="sectionHelp"
    :help="sectionHelp"
    :title="sectionTitle"
  >
    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="invoiceData"
      isActiveField="SalesDraftIsActive"
      dateField="DraftDate"
      numberField="SalesDraftID"
    />
  </kst-section>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";

export default {
  props: {
    invoiceData: Object
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    isCollapsible() {
      return this.invoiceData.SalesDraftID !== null;
    },
    sectionHelp() {
      return Model.getSalesSectionHelp(this.invoiceData);
    },
    sectionTitle() {
      return Info.Section.Sales.Title;
    }
  }
}
</script>