<template>
  <div>
    <ClientSearchList
      @init="handleClient_Init"
      @submit="handleClient_Submit"
    />

    <div class="columns my-0 is-multiline">
      <div class="column is-narrow">
        <kst-output addons floating-label
          field="ClientID" :data="searchData" :catalog="RccdSearchCatalog"
        >
          <template #right>
            <div class="control">
              <template v-if="searchData.ClientID === null">
                <kst-tooltip search-mode hover-only :content="RccdSearchCatalog.ClientID.Label">
                  <kst-button search-mode @click="handleSearch_Client"/>
                </kst-tooltip>
              </template>
              <template v-else>
                <kst-tooltip clear-mode hover-only :content="RccdSearchCatalog.ClientID.Label">
                  <kst-button clear-mode @click="handleClear_Client"/>
                </kst-tooltip>
              </template>
            </div>
          </template>
        </kst-output>
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="TypeID" :data="searchData" :catalog="RccdSearchCatalog"
          :options="typeOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="WarehouseID" :data="searchData" :catalog="RccdSearchCatalog"
          :options="warehouseOptions" @input="handleInput"
        />
      </div>
      <div class="column is-narrow">
        <kst-input floating-label
          field="TransactionDate" :data="searchData" :catalog="RccdSearchCatalog"
          @input="handleInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Model from "../RccdModel.js";
import RccdSearchCatalog from "../RccdSearchCatalog.js";
import Services from "../../../services/Api/RecCardServices.js";

import ClientSearchList from "../../Client/SearchList/CliScL.vue";

export default {
  components: {
    ClientSearchList
  },
  props: {
    searchData: Object,
    warehouseOptions: Object
  },
  data: () => ({
    RccdSearchCatalog: RccdSearchCatalog,
    // func
    showClientFunc: null
  }),
  computed: {
    typeOptions() {
      return Model.createTypeOptions(RccdSearchCatalog.TypeID.Label);
    }
  },
  methods: {
    /*** by client modal ***/

    handleClient_Init(show) {
      this.showClientFunc = show;
    },
    handleClient_Submit(clientData) {
      this.searchData.ClientID = clientData.ID;
      this.searchData.ClientName = clientData.Name;

      this.handleInput();
    },

    /*** custom ***/

    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    },

    handleSearch_Client() {
      this.showClientFunc(Services.getRelatedClientList);
    },
    handleClear_Client() {
      this.searchData.ClientID = null;
      this.searchData.ClientName = null;

      this.handleInput();
    }
  }
}
</script>