export default {
  SearchResult: {
    HasTransaction: {
      ID: 1,
      Label: "Ada Pergerakan Stok"
    },
    StockAvailable: {
      ID: 2,
      Label: "Stok Tersedia"
    }
  }
}