<template>
  <ValidationObserver ref="formDetails">
    <form novalidate @submit.prevent="debounceSearch">
      <div class="columns my-0 is-multiline">
        <div class="column is-4">
          <kst-input load-on-focus floating-label
            field="Search" :data="searchData" :catalog="SearchCatalog"
            @input="handleInput"
          />
        </div>

        <div class="column is-narrow">
          <kst-button reset-mode @click="handleReset"/>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import SearchMixin from "@/mixins/SearchMixin.js";
import SnackbarMixin from "@/mixins/SnackbarMixin.js";
import SearchCatalog from "./GlobalSearch_SearchCatalog.js";

export default {
  mixins: [SearchMixin, SnackbarMixin],
  props: {
    searchData: Object
  },
  data: () => ({
    SearchCatalog: SearchCatalog
  }),
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$refs.formDetails.validate().then(isValid => {
        if (isValid) {
          this.$emit(this.$kst.Enum.Event.Submit);
        }
        else {
          this.notifyFormError();
        }
      });
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>