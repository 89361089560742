<template>
  <kst-print-table :data="items">
    <b-table-column
      v-slot="{ row }"
      field="DispatchID"
      :label="StockOpnameItemCatalog.DispatchID.ColumnLabel"
    >
      {{ row.Name }}
    </b-table-column>

    <b-table-column
      v-slot="{ row }"
      cell-class="ks-col-packaging"
      field="Packaging"
      :label="StockOpnameItemCatalog.Packaging.Label"
    >
      {{ row.PackagingName + " (" + row.PackagingQty + ")" }}
    </b-table-column>
    
    <b-table-column
      cell-class="ks-col-qty-print"
      field="Qty"
      :label="StockOpnameItemCatalog.Qty.Label"
    >
      <template #default/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import StockOpnameItemCatalog from "../StopItemCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    StockOpnameItemCatalog: StockOpnameItemCatalog
  })
}
</script>