<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Sales :details="details" v-if="isSalesVisible"/>
        <Details :details="details"/>
        <Items :details="details" :items="details.Items"/>
        <Total :details="details"/>

        <template v-if="isDeliveryVisible">
          <Delivery :details="details"/>
        </template>
      </b-tab-item>
      <b-tab-item :label="tabs.DeliveryList" v-if="access.Delivery_Act_List">
        <template v-if="details.IsNeedDelivery">
          <DeliveryItemsSummary :items="itemsSummary"/>
          <DeliveryRelatedList
            class="block"
            :hide-action="actionDeliveryHidden"
            :invoiceId="details.ID"
            :items="deliveryList"
          />
        </template>
        <template v-else>
          <kst-warning :items="deliveryWarning"/>
        </template>
      </b-tab-item>
      <template v-if="access.RtnInvoice_Act_List || access.StockReduction_Act_List || access.Act_List">
        <b-tab-item :label="tabs.Return">
          <Return
            :access="access"
            :details="details"
            :invoiceReplaceList="invoiceReplaceList"
            :returnInvoiceList="returnInvoiceList"
            :stockReductionList="stockReductionList"
          />
        </b-tab-item>
      </template>
      <b-tab-item :label="tabs.Profit" v-if="access.Tab_Profit">
        <Profit :details="details" :profitData="profitData"/>
      </b-tab-item>
      <b-tab-item :label="tabs.SalesRecap" v-if="access.SalesRecap_Act_Details">
        <template v-if="salesRecapDetails !== null">
          <SalesRecapRelatedDetails :details="salesRecapDetails"/>
        </template>
        <template v-else>
          <kst-table-empty/>
        </template>
      </b-tab-item>
      <b-tab-item :label="tabs.RecPayList" v-if="access.RecPay_Act_List && !details.IsPos">
        <RecPay :recData="recData"/>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../InvInfo.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import Delivery from "./InvD_Delivery.vue";
import DeliveryItemsSummary from "./InvD_DeliveryItemsSummary.vue";
import Details from "./InvD_Details.vue";
import Items from "./InvD_Items.vue";
import Profit from "./InvD_Profit.vue";
import RecPay from "./InvD_RecPay.vue";
import Return from "./InvD_Return.vue";
import Sales from "./InvD_Sales.vue";
import Total from "./InvD_Total.vue";
import DeliveryRelatedList from "../../Delivery/RelatedList/DlvRL.vue";
import SalesRecapRelatedDetails from "../../SalesRecap/RelatedDetails/SlrcRD.vue";

export default {
  components: {
    Delivery,
    DeliveryItemsSummary,
    Details,
    Items,
    Profit,
    RecPay,
    Return,
    Sales,
    Total,
    DeliveryRelatedList,
    SalesRecapRelatedDetails
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    profitData: {},
    itemsSummary: [],
    deliveryList: [],
    returnInvoiceList: [],
    salesRecapDetails: {},
    stockReductionList: [],
    invoiceReplaceList: [],
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      DeliveryList: Info.Tabs.DeliveryList,
      Return: Info.Tabs.Return,
      Profit: Info.Tabs.Profit,
      SalesRecap: Info.Tabs.SalesRecapList,
      RecPayList: Info.Tabs.RecPayList
    }
  }),
  computed: {
    actionDeliveryHidden() {
      return this.details.Status === Model.Status.Closed.ID;
    },
    deliveryWarning() {
      return [Info.Message.Details_NoDelivery];
    },
    isDeliveryVisible() {
      return this.$kst.Session.companyHasAccess(Model.Delivery.Module);
    },
    isSalesVisible() {
      return this.$kst.Session.companyHasAccess(Model.Sales.Module);
    }
  },
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      Model.setDetailsByStatus(this.details);
      Model.setDetailsBySpecialPrice(this.details, data.SpecialPriceCount);

      for (const item of this.details.Items) {
        Model.updateItem(item);
      }

      if (this.access.Delivery_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "DeliveryList")) {
          this.deliveryList = data.DeliveryList;

          if (Array.isArray(this.deliveryList)) {
            this.itemsSummary = Model.Details.getItemsSummaryByDelivery(
              this.details, this.deliveryList
            );
          }
        }
        else {
          this.access.Delivery_Act_List = false;
        }
      }

      if (this.access.Tab_Profit) {
        if (Object.prototype.hasOwnProperty.call(data, "Profit")) {
          this.profitData = data.Profit;
          Model.Details.setDetailsByProfit(this.details,
            this.profitData.RtnInvoiceList
          );
        }
        else {
          this.access.Tab_Profit = false;
        }
      }

      if (this.access.RecPay_Act_List) {
        if (!this.details.IsPos) {
          if (Object.prototype.hasOwnProperty.call(data, "RecPay")) {
            this.recData = data.RecPay;
            Model.Details.updateRecData(this.recData, this.details);
          }
          else {
            this.access.RecPay_Act_List = false;
          }
        }
      }

      if (this.access.RtnInvoice_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "RtnInvoiceList")) {
          this.returnInvoiceList = data.RtnInvoiceList;
        }
        else {
          this.access.RtnInvoice_Act_List = false;
        }
      }

      if (this.access.SalesRecap_Act_Details) {
        if (Object.prototype.hasOwnProperty.call(data, "SalesRecapDetails")) {
          this.salesRecapDetails = data.SalesRecapDetails;
        }
        else {
          this.access.SalesRecap_Act_Details = false;
        }
      }

      if (this.access.StockReduction_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "StockReductionList")) {
          this.stockReductionList = data.StockReductionList;
        }
        else {
          this.access.StockReduction_Act_List = false;
        }
      }

      if (this.access.Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "InvoiceReplaceList")) {
          this.invoiceReplaceList = data.InvoiceReplaceList;
        }
        else {
          this.access.Act_List = false;
        }
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.actions = Model.Details.getActions(id, this.access);
      this.details = {};
      this.activeTab = 0;
      this.profitData = {};
      this.recData = {};
      this.itemsSummary = [];
      this.deliveryList = [];
      this.returnInvoiceList = [];
      this.salesRecapDetails = {};
      this.stockReductionList = [];
      this.invoiceReplaceList = [];
    }
  }
}
</script>