import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  DispatchID: {
    Label: AppModule.Item.FullName,
    ColumnLabel: AppCatalog.Field.Name.Label,
    ExportLabel: AppCatalog.Field.Name.Label,
    PrintLabel: AppCatalog.Field.Name.Label,
    Type: AppCatalog.Field.ID.Type,
    Required: true,
    RelatedValue: "DispatchName",
    RelatedModule: AppModule.Item,
    RelatedIsActive: "DispatchIsActive"
  },
  PackagingName: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  Qty: {
    Label: AppCatalog.Field.Qty.Label,
    Type: AppCatalog.Field.Qty.Type,
    MinValue: AppCatalog.Field.Qty.MinValue,
    MaxLength: AppCatalog.Field.Qty.MaxLength,
    Required: true
  },
  SellPrice: {
    Label: AppCatalog.Field.SellPrice.Label,
    Type: AppCatalog.Field.SellPrice.Type,
    Output: AppCatalog.Field.SellPrice.Output,
    ReadOnly: true
  },
  SpecialPriceDiscPercent: {
    Label:AppCatalog.Field.DiscPercent.ExportLabel + " " +
      AppModule.SpecialPrice.FullName,
    Type: AppCatalog.Field.DiscPercent.Type,
  },
  SpecialPriceID: {
    Label: AppModule.SpecialPrice.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    RelatedValue: "SpecialPriceName",
    RelatedModule: AppModule.SpecialPrice,
    RelatedIsActive: "SpecialPriceIsActive",
    NullText: AppCatalog.Message.Without + " " + AppModule.SpecialPrice.FullName
  },
  Total: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    Output: AppCatalog.Field.SubTotal.Output,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly
  },
  TotalQty: {
    Label: AppCatalog.Field.TotalQty.Label,
    Type: AppCatalog.Field.TotalQty.Type,
    ReadOnly: AppCatalog.Field.TotalQty.ReadOnly
  },

  // support
  DispatchSellPrice: {
    Label: AppCatalog.Field.DispatchSellPrice.Label,
    Type: AppCatalog.Field.DispatchSellPrice.Type,
    ReadOnly: AppCatalog.Field.DispatchSellPrice.ReadOnly,
    Output: AppCatalog.Field.DispatchSellPrice.Output
  },
  HppTotal: {
    Label: AppCatalog.Field.HppTotal.Label,
    Type: AppCatalog.Field.HppTotal.Type,
    Output: AppCatalog.Field.HppTotal.Output,
    ReadOnly: AppCatalog.Field.HppTotal.ReadOnly
  },
  Packaging: {
    Label: AppModule.ItemPackaging.FullName,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
  SKU: {
    Label: AppCatalog.Field.SKU.Label,
    Type: AppCatalog.Field.SKU.Type,
    ReadOnly: true
  },
  Stock: {
    Label: AppCatalog.Field.Stock.Label,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  },
  StockReductionItemTotal: {
    Label: AppCatalog.Field.SubTotal.Label,
    Type: AppCatalog.Field.SubTotal.Type,
    Output: AppCatalog.Field.SubTotal.Output,
    ReadOnly: AppCatalog.Field.SubTotal.ReadOnly
  },
  SpecialPriceQty: {
    Label: AppCatalog.Field.Stock.Label + " " + AppModule.SpecialPrice.FullName,
    Type: AppCatalog.Field.Stock.Type,
    ReadOnly: AppCatalog.Field.Stock.ReadOnly
  }
}