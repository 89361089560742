import { render, staticRenderFns } from "./Verification.vue?vue&type=template&id=6e451498&scoped=true"
import script from "./Verification.vue?vue&type=script&lang=js"
export * from "./Verification.vue?vue&type=script&lang=js"
import style0 from "./_verification.css?vue&type=style&index=0&id=6e451498&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e451498",
  null
  
)

export default component.exports