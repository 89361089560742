<template>
  <kst-container class="kst-pagedetails" :class="theClass">
    <nav class="level mb-0 p-5 ks-border-b-light">
      <div class="level-left">
        <div>
          <kst-title class="m-0">{{ theTitle }}</kst-title>
        </div>
      </div>
      <div class="level-right" v-if="reloadAreaVisible || actionAreaVisible">
        <div class="buttons">
          <template v-if="reloadAreaVisible">
            <kst-button slim-size reload-mode @click="handleReload"/>
          </template>
          <template v-if="actionAreaVisible">
            <template v-if="hasActions">
              <slot name="actions">
                <Actions :actions="actions"/>
              </slot>
            </template>
          </template>
        </div>
      </div>
    </nav>

    <div class="kst-pagedetails__content p-5">
      <kst-skeleton :active="isLoading"/>

      <template v-if="hasAccess === false">
        <kst-failure access-mode/>
      </template>

      <template v-if="defaultSlotVisible">
        <slot />
      </template>

      <kst-failure init-mode :items="failureInit"/>
    </div>
  </kst-container>
</template>

<script>
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import LoadingMixin from "../../../mixins/LoadingMixin.js";
import SlotMixins from "../../../mixins/SlotMixins.js";
import SnackbarMixin from "../../../mixins/SnackbarMixin.js";

import Actions from "./CpD_PageDetails_Actions.vue";

/**
 * flow (how to use):
 * 1. handle Init event and do loadDetails, set and do reload (as needed)
 */
export default {
  mixins: [AxiosMixin, LoadingMixin, SlotMixins, SnackbarMixin],
  components: {
    Actions
  },
  props: {
    actions: Array
  },
  data: () => ({
    hasAccess: null,
    // loading
    isLoading: true,
    // area
    actionAreaVisible: false,
    reloadAreaVisible: false,
    // slot
    defaultSlotVisible: false,
    // error
    failureInit: null
  }),
  computed: {
    hasActions() {
      if (this.slotHasContent("actions")) {
        return true;
      }
      else if (Array.isArray(this.actions)) {
        if (this.actions.length > 0) {
          return true;
        }
      }

      return false;
    },
    module() {
      return this.$route.meta.module;
    },
    moduleAttr() {
      return this.$route.meta.moduleattr;
    },
    moduleType() {
      return this.$route.meta.moduletype;
    },
    theClass() {
      return this.$kst.Vue.getComponentClass(this.module, this.moduleType);
    },
    theTitle() {
      return this.$route.meta.title;
    }
  },
  created() {
    this.init();
  },
  methods: {
    /*** page template ***/

    init() {
      this.init_data();
      if (this.init_validate()) {
        this.$emit(this.$kst.Enum.Event.Init,
          this.loadDetails,
          this.reload
        );
      }
      else {
        this.init_failed();
      }
    },
    init_data() {
      this.hasAccess = null;
      // loading
      this.isLoading = true;
      // area
      this.actionAreaVisible = false;
      this.reloadAreaVisible = false;
      // slot
      this.defaultSlotVisible = false;
      // error
      this.failureInit = null;
    },
    init_validate() {
      // check: access
      const moduleAttrList = this.$kst.Session.getModuleAttr();
      this.hasAccess = moduleAttrList.includes(this.moduleAttr);

      if (this.hasAccess !== true) {
        this.init_failed();
        return false;
      }

      return true;
    },
    init_success() {
      this.isLoading = false;
      this.actionAreaVisible = true;
      this.reloadAreaVisible = true;
      this.defaultSlotVisible = true;
    },
    init_failed() {
      this.isLoading = false;
      this.reloadAreaVisible = true;
    },

    handleReload() {
      this.init();
    },

    /*** custom ***/

    reload() {
      this.init();
    },

    loadDetails(config, successCallback) {
      this.runAxios(config, successCallback,
        this.loadDetails_success,
        this.loadDetails_fault,
        this.loadDetails_fault,
        null, true
      );
    },
    loadDetails_success(data, successCallback) {
      successCallback(data);
      this.init_success();
    },
    loadDetails_fault(message) {
      this.failureInit = message;
      this.init_failed();
    }
  }
}
</script>