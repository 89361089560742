import AppType from "./App_Type.js";

export default {
  // normalize date or date-time type
  getDateTime(value) {
    if (value === undefined || value === null) {
      return null;
    }

    let newValue = AppType.isDate(value) ? value : new Date(value);
    return newValue.getTime();
  },
  getDecimal(value) {
    if (value === undefined || value === null) {
      return null;
    }

    return parseFloat(value);
  },
  // mutates: userList
  validateArray(userList, dataList, fields) {
    if (!Array.isArray(userList)) {
      return false;
    }
    if (!Array.isArray(dataList)) {
      return false;
    }

    if (userList.length !== dataList.length) {
      return false;
    }

    let item, dataItem, isFieldsEqual, index, field;

    for (dataItem of dataList) {
      for (index = 0; index < userList.length; index++) {
        isFieldsEqual = true;
        item = userList[index];

        for (field of fields) {
          if (item[field] !== dataItem[field]) {
            isFieldsEqual = false;
            break;
          }
        }

        if (isFieldsEqual) {
          userList.splice(index, 1);
          break;
        }
      }

      if (!isFieldsEqual) {
        return false;
      }
    }

    return true;
  },
  validateObject(userObj, dataObj, fields) {
    for (const field of fields) {
      if (userObj[field] !== dataObj[field]) {
        return false;
      }
    }

    return true;
  }
}