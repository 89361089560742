import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./StcvModel.js";
import StcvCatalog from "./StcvCatalog.js";

export default {
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name
        },
        // ui
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.StockConv.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.StockConv.FullName,
          StockConvItem: "Dengan " + Model.StockConvItem.Module.FullName
        }
      }
    }
  },
  Message: {
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.StockConvItem.Module.FullName + "?",
    New_DuplicatedItem:
      Model.StockConvItem.Module.FullName + " telah dipergunakan di " +
      AppCatalog.Section.Items + " Awal."
  },
  Section: {
    ItemsFinish: {
      Title: AppCatalog.Section.Items + " Akhir",
      Help: "Max " + StcvCatalog.ItemsFinish.MaxLength + " " + AppModule.Item.FullName
    },
    ItemsRaw: {
      Title: AppCatalog.Section.Items + " Awal"
    }
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name
  }
}