<template>
  <div class="columns">
    <template v-if="actionItems.length > 0">
      <div class="column">
        <List
          :items="actionItems"
          :title="actionTitle"
        />
      </div>
    </template>
    <template v-if="fieldItems.length > 0">
      <div class="column">
        <List
          :items="fieldItems"
          :title="fieldTitle"
        />
      </div>
    </template>
    <template v-if="interfaceItems.length > 0">
      <div class="column">
        <List
          :items="interfaceItems"
          :title="interfaceTitle"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Model from "../ModuleAttrModel.js";

import List from "./Page_Section_List.vue";

export default {
  components: {
    List
  },
  props: {
    moduleAttrList: Array,
    moduleId: Number,
    subCode: String
  },
  data: () => ({
    actionItems: [],
    fieldItems: [],
    interfaceItems: []
  }),
  computed: {
    actionTitle() {
      return Model.Type.Action.Label;
    },
    fieldTitle() {
      return Model.Type.Field.Label;
    },
    interfaceTitle() {
      return Model.Type.Interface;
    },
    title() {
      return Model.ModuleType.List.Name + " " + Model.Module.FullName;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.actionItems = [];
      this.fieldItems = [];
      this.interfaceItems = [];

      for (const moduleAttr of this.moduleAttrList) {
        if (moduleAttr.ModuleID === this.moduleId) {
          // validation
          if (moduleAttr.ModuleAttrSubCode !== this.subCode) {
            continue;
          }

          if (moduleAttr.ModuleAttrTypeID === Model.Type.Action.ID) {
            this.actionItems.push(moduleAttr);
          }
          else if (moduleAttr.ModuleAttrTypeID === Model.Type.Field.ID) {
            this.fieldItems.push(moduleAttr);
          }
          else if (moduleAttr.ModuleAttrTypeID === Model.Type.Section.ID ||
            moduleAttr.ModuleAttrTypeID === Model.Type.Tab.ID
          ) {
            this.interfaceItems.push(moduleAttr);
          }
          // else: unknown (skip)
        }
      }
    }
  }
}
</script>