<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :data="data"/>
        <TransactionSetting :data="data"/>
        <template v-if="data.SelfService">
          <SelfService
            :data="data.SelfService"
            :id="data.ID"
            @submit="handleSelfService_Submit"
          />
        </template>
        <Addresses :data="data" :items="data.AddressList"/>
      </b-tab-item>
      <b-tab-item :label="tabs.ReceivablesList">
        <Receivables
          :data="data"
          :receivablesList="receivablesList"
        />
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../CliInfo.js";
import Model from "../CliModel.js";
import Services from "../../../services/Api/ClientServices.js";

import Addresses from "./CliD_Addresses.vue";
import Details from "./CliD_Details.vue";
import Receivables from "./CliD_Receivables.vue";
import SelfService from "./CliD_SelfService.vue";
import TransactionSetting from "./CliD_TransactionSetting.vue";

export default {
  components: {
    Addresses,
    Details,
    Receivables,
    SelfService,
    TransactionSetting
  },
  data: () => ({
    actions: [],
    data: {},
    receivablesList: [],
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      ReceivablesList: Info.Tabs.ReceivablesList
    },
    // func
    reloadFunc: null
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails, reload) {
      this.resetData(id);
      this.reloadFunc = reload;

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.data = data.Details;
      this.receivablesList =
        Object.prototype.hasOwnProperty.call(data, "ReceivablesList")
        ? data.ReceivablesList : null;

      if (Object.prototype.hasOwnProperty.call(this.data, "SelfService")) {
        Model.SelfService.updateData(this.data.SelfService);
      }
    },

    /*** custom ***/

    resetData(id) {
      this.data = {};
      this.receivablesList = null;
      this.activeTab = 0;

      // actions
      const access = this.$kst.Access.create(Model.Access);
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, access
      );
    },

    handleSelfService_Submit() {
      this.reloadFunc();
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>