import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import RouterModel from "../../router/RouterModel.js";

import OmzP from "./Print/OmzP.vue";
import OmzR from "./Report/OmzR.vue";

export default {
  getRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Omzet),
        name: RouterModel.getName(AppModule.Omzet),
        component: OmzR,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Omzet),
          module: RouterModel.getModule(AppModule.Omzet),
          moduleattr: AppModuleAttr.Omzet_Act_Report,
          moduletype: AppModuleType.Report,
          // actions
          print: {
            module: RouterModel.getModule(AppModule.Omzet),
            moduleattr: AppModuleAttr.Omzet_Act_Print,
            moduletype: AppModuleType.Print
          }
        }
      }
    ];
  },

  getRootRoutes() {
    return [
      {
        path: RouterModel.getPath(AppModule.Omzet, AppModuleType.Print),
        name: RouterModel.getName(AppModule.Omzet, AppModuleType.Print),
        component: OmzP,
        meta: {
          title: RouterModel.getNavSubTitle(AppModule.Omzet, AppModuleType.Print),
          module: RouterModel.getModule(AppModule.Omzet),
          moduleattr: AppModuleAttr.Omzet_Act_Print,
          moduletype: AppModuleType.Print
        }
      }
    ];
  }
}