<template>
  <kst-section collapsible collapsed
    :help-collapsed="sectionHelp"
    :title="sectionTitle"
  >
    <Details :details="purchaseData"/>
    <Delivery :details="purchaseData"/>
    <Items 
      :access="access"
      :items="purchaseData.Items"/>
    <template v-if="access.Fld_Amount">
      <Total :details="purchaseData"/>
    </template>
  </kst-section>
</template>

<script>
import Info from "../PurInfo.js";
import Model from "../PurModel.js";

import Delivery from "./PurDL_Purchase_Delivery.vue";
import Details from "./PurDL_Purchase_Details.vue";
import Items from "./PurDL_Purchase_Items.vue";
import Total from "./PurDL_Purchase_Total.vue";

export default {
  components: {
    Delivery,
    Details,
    Items,
    Total
  },
  props: {
    access: Object,
    purchaseData: Object
  },
  computed: {
    sectionHelp() {
      return Model.Delete.getPurchaseSectionHelp(this.purchaseData);
    },
    sectionTitle() {
      return Info.Section.Details.Title;
    }
  }
}
</script>