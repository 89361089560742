<template>
  <kst-table view-mode :data="items">
    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchName"
      :label="Catalog.DispatchName.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="StockQuantity"
      :label="Catalog.StockQuantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      field="TotalQty"
      :label="Catalog.TotalQty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      cell-class="has-text-weight-bold"
      v-slot="{ column, row }"
      field="MissingQty"
      :label="Catalog.MissingQty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>
  </kst-table>
</template>

<script>
import Catalog from "../StavCatalog.js";

export default {
  props: {
    items: Array
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>