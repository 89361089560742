<template>
  <kst-print-table view-mode :data="dataList">
    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdatetime"
      field="CreatedDate"
      :label="Catalog.CreatedDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="TypeID"
      :label="Catalog.TypeID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-number"
      field="TransactionNumber"
      :label="Catalog.TransactionNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-warehouse"
      field="TransWarehouseID"
      :label="RccdTransCatalog.TransWarehouseID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="InvoiceID"
      :label="RccdTransCatalog.InvoiceID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="InvoiceDate"
      :label="RccdTransCatalog.InvoiceDate.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="InvoiceRecChanges"
      :label="RccdTransCatalog.InvoiceRecChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="InvoiceRecAfter"
      :label="RccdTransCatalog.InvoiceRecAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientID"
      :label="RccdTransCatalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ClientRecChanges"
      :label="RccdTransCatalog.ClientRecChanges.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-subtotal"
      field="ClientRecAfter"
      :label="RccdTransCatalog.ClientRecAfter.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="RccdTransCatalog"/>
    </b-table-column>
  </kst-print-table>
</template>

<script>
import Catalog from "../RccdCatalog.js";
import RccdTransCatalog from "../RccdTransCatalog.js";

export default {
  props: {
    dataList: Array
  },
  data: () => ({
    Catalog: Catalog,
    RccdTransCatalog: RccdTransCatalog
  })
}
</script>