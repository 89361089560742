<template>
  <div class="columns my-0 is-multiline">
    <div class="column is-3">
      <kst-input floating-label load-on-focus
        field="Search" :data="searchData" :catalog="ItemSearchCatalog"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script>
import ItemSearchCatalog from "../ItemSearchCatalog.js";

export default {
  props: {
    searchData: Object
  },
  data: () => ({
    ItemSearchCatalog: ItemSearchCatalog
  }),
  methods: {
    handleInput() {
      this.$emit(this.$kst.Enum.Event.Input);
    }
  }
}
</script>