// JavaScript extended functions

export default {
  /**
   * extend parseInt() with default value = 0
   * when ignore defaultValue param, or set defaultValue = undefined
   **/
  parseInt(value, defaultValue) {
    let newDefaultValue = 0;
    if (defaultValue !== undefined) {
      newDefaultValue = defaultValue;
    }

    const newValue = parseInt(value);

    if (isNaN(newValue)) {
      return newDefaultValue;
    }
    return newValue;
  },

  /**
   * extend parseFloat() with default value = 0
   * when ignore defaultValue param, or set defaultValue = undefined
   **/
  parseFloat(value, defaultValue) {
    const newValue = parseFloat(value);

    if (isNaN(newValue)) {
      return defaultValue !== undefined ? defaultValue : 0;
    }
    return newValue;
  }
}