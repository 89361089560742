<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <Items :access="access" :items="details.Items"/>

        <template v-if="access.Glob_HPP">
          <Total :details="details"/>
        </template>
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import Info from "../SttrInfo.js";
import Model from "../SttrModel.js";
import Services from "../../../services/Api/StockTrfServices.js";

import Details from "./SttrD_Details.vue";
import Items from "./SttrD_Items.vue";
import Total from "./SttrD_Total.vue";

export default {
  components: {
    Details,
    Items,
    Total
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.details = data.Details;

      for (const item of this.details.Items) {
        Model.updateItemReadOnly(item);
      }
    },
    
    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.details = {};
      this.activeTab = 0;

      // actions
      const urlParams = this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });

      this.actions = this.$kst.Access.getAvailableActions(
        Model.Actions.Details, Model.Module, urlParams, this.access
      );
    }
  }
}
</script>