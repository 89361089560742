<template>
  <kst-section search-mode>
    <template #top-left>
      <kst-button slim-size reset-mode @click="handleReset"/>
    </template>

    <form novalidate @submit.prevent="debounceSearch">
        <slot :handleInput="handleInput"/>

        <div class="is-hidden">
          <input type="submit" value="Cari" class="is-hidden">
        </div>
    </form>
  </kst-section>
</template>

<script>
import SearchMixin from "../../../mixins/SearchMixin.js";

export default {
  mixins: [SearchMixin],
  props: {
    searchData: Object,
    isActiveOptions: Object
  },
  created() {
    this.initSearch(this.searchData, this.submit);
  },
  methods: {
    handleInput() {
      this.handleSearch();
    },
    submit() {
      this.$emit(this.$kst.Enum.Event.Submit);
    },
    handleReset() {
      this.$emit(this.$kst.Enum.Event.Reset);
    }
  }
}
</script>