<template>
  <div class="block">
    <ClientSearchListAddress
      @init="handleClient_init"
      @submit="handleClient_submit"
    />

    <InvoiceSearchList
      :warehouseList="warehouseList"
      @init="handleInvoice_init"
      @submit="handleInvoice_submit"
    />

    <kst-number-with-date input-mode
      :catalog="Catalog"
      :data="userData"
      :dateValidations="invoiceDateValidations"
      :dateDisabled="invoiceDateDisabled"
      autoNumberField="IsAutoNumber"
      dateField="InvoiceDate"
      numberField="SONumber"
    />

    <template v-if="userData.TransactionVisible">
      <kst-output control-off status-off
        field="TransactionID" :data="userData" :catalog="Catalog"
      >
        <template #left>
          <kst-output addons controlOff floating-label
            :label="Catalog.TransactionID.AltLabel"
            field="TransactionID" :data="userData" :catalog="Catalog"
          />
        </template>
        <template #default>
          <kst-output addons expanded floating-label
            field="TransactionDate" :data="userData" :catalog="Catalog"
          />
        </template>
        <template #right>
          <div class="control">
            <template v-if="userData.TransactionID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.TransactionID.AltLabel">
                <kst-button search-mode @click="handleSearch_transaction"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.TransactionID.AltLabel">
                <kst-button clear-mode @click="handleClear_transaction"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </kst-output>
    </template>

    <kst-output control-off status-off
      field="ClientID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Client" :data="userData" :catalog="Catalog"
          :disabled="clientDisabled"
        />
      </template>
      <template #right>
        <template v-if="userData.SalesDraftID === null && userData.TransactionID === null">
          <div class="control">
            <template v-if="userData.ClientID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button search-mode @click="handleSearch_client"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button clear-mode @click="handleClear_client"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="ClientAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="PONumber" :data="userData" :catalog="Catalog"/>

    <template v-if="userData.SalesDraftID === null">
      <kst-input field="WarehouseID" :data="userData" :catalog="Catalog"
        :disabled="userItems.length > 0" :options="warehouseOptions"
      />
    </template>
    <template v-else>
      <kst-output control-off status-off
        field="WarehouseID" :data="userData" :catalog="Catalog"
      />
    </template>
  </div>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import Catalog from "../InvCatalog.js";
import Info from "../InvInfo.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import ClientSearchListAddress from "../../Client/SearchListAddress/CliScLAddress.vue";
import InvoiceSearchList from "../SearchList/InvScL.vue";

export default {
  mixins: [DialogMixin],
  components: {
    ClientSearchListAddress,
    InvoiceSearchList
  },
  props: {
    userData: Object,
    userItems: Array,
    warehouseList: Array,
    warehouseOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showClientFunc: null,
    showInvoiceFunc: null
  }),
  computed: {
    invoiceDateValidations(){
      return Model.New.validationInvoiceDate(this.userData);
    },
    clientDisabled() {
      if (this.userData.ClientSourceID ===
        Model.InvoiceClientSourceID.MasterClient.ID
      ) {
        return true;
      }

      if (this.userData.ClientID !== null) {
        return true;
      }

      if (this.userData.SalesDraftID !== null) {
        return true;
      }

      if (this.userData.TransactionID !== null){
        return true;
      }

      return false;
    },
    invoiceDateDisabled(){
      return Model.getInvoiceDateDisabled();
    }
  },
  methods: {
    /*** by client modal ***/

    handleClient_init(show) {
      this.showClientFunc = show;
    },
    handleClient_submit(row) {
      Model.New.setDetailsByClient(this.userData, row);
      this.$emit(this.$kst.Enum.Event.Input);
    },

    /*** by invoice modal ***/

    handleInvoice_init(show) {
      this.showInvoiceFunc = show;
    },
    handleInvoice_submit(data) {
      let clientDetails =
        Object.prototype.hasOwnProperty.call(data, "ClientDetails")
        ? data.ClientDetails : null;

      Model.New.setDetailsByTransaction(this.userData, data.TransactionDetails,
        clientDetails, data.WarehouseDetails
      );
    },

    /*** custom ***/

    handleSearch_client() {
      this.showClientFunc(Services.getRelatedClientAddressList);
    },

    handleClear_client() {
      if (this.userData.ClientID !== null &&
        this.userData.PaymentTypeName !== this.$kst.Search.OptionNone
      ) {
        this.showConfirm(
          Info.Message.New_Client_ConfirmDelete,
          this.handleClear_client_confirm
        );
      }
      else {
        this.handleClear_client_confirm();
      }
    },
    handleClear_client_confirm() {
      Model.New.clearDetailsByClient(this.userData);
    },

    handleSearch_transaction() {
      this.showInvoiceFunc(
        Services.getRelatedTransactionList,
        Services.getRelatedTransactionDetails
      );
    },
    handleClear_transaction() {
      Model.New.clearDetailsByTransaction(this.userData);
    }
  }
}
</script>