import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import ModelBody from "./PurModel_Body.js";
import PurItemCatalog from "./PurItemCatalog.js";

export default {
  getItemInfo(userItem, newItemDefault) {
    let infoList = [];

    // new-item
    if (userItem.DispatchID === newItemDefault.ItemID) {
      infoList.push(AppCatalog.Info.NewItem);
    }
    else {
      // SKU
      infoList.push(PurItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", userItem, PurItemCatalog)
      );
    }

    return infoList;
  },
  setItemsByVendorItem_getPackagingObj(packagingList) {
    let packagingObj = {};

    for (let packaging of packagingList) {
      if (!App.Data.hasProp(packagingObj, packaging.ItemID)) {
        packagingObj[packaging.ItemID] = [];
      }
      packagingObj[packaging.ItemID].push(packaging);
    }

    return packagingObj;
  },
  setItemsByVendorItem(userItems, vendorItemList, packagingList) {
    const packagingObj = this.setItemsByVendorItem_getPackagingObj(packagingList);
    
    let item;
    for (let vendorItem of vendorItemList) {
      item = ModelBody.createItem();
      if (App.Data.hasProp(packagingObj, vendorItem.ItemID)) {
        vendorItem.PackagingList = packagingObj[vendorItem.ItemID];
      }
      ModelBody.setItemByVendorItem(item, vendorItem);
      ModelBody.updateItem(item);
      userItems.push(item);
    }
  }
}