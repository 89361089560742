<template>
  <kst-modal-search multiple
    :module="Model.Module"
    :moduleType="Model.ModuleType.SearchList"
    :searchData="searchData"
    :sub-title="modal_subTitle"
    :width="950"
    @init="handleModal_init"
    @load="handleModal_load"
    @reset="handleModal_reset"
    @submit="handleModal_submit"
  >
    <template #search="{ handleInput }">
      <Search 
        :searchData="searchData"
        @input="handleInput"
      />
    </template>

    <template #search-result="{ data, userData }">
      <List
        :items="data.List"
        :userData="userData"
        @check="handleList_check"
      />
    </template>
  </kst-modal-search>
</template>

<script>
import Model from "../ItemModel.js";

import Search from "./ItemScLMultiple_Search.vue";
import List from "./ItemScLMultiple_List.vue";

/**
 * flow (how to use):
 * 1. handle Init event, set and do show when needed
 * 2. handle Submit event
 */
export default {
  components: {
    Search,
    List
  },
  data: () => ({
    Model: Model,
    searchData: {
      Search: "",
      ExcludeID: null
    },
    getListService: null,
    getRelatedListService: null,
    itemList: [],
    // func
    hideFunc: null,
    showFunc: null
  }),
  computed: {
    modal_subTitle() {
      return Model.getSearchListSubTitle(this.itemList);
    }
  },
  methods: {
    /*** public method ***/

    show(getListService, getRelatedListService, excludeID) {
      this.getListService = getListService;
      this.getRelatedListService = getRelatedListService;
      this.searchData.ExcludeID = excludeID === undefined ? null : excludeID;
      this.showFunc();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    /*** by modal ***/

    handleModal_init(show, hide) {
      this.showFunc = show;
      this.hideFunc = hide;
      this.init_data();

      this.$emit(this.$kst.Enum.Event.Init, this.show);
    },

    handleModal_load(loadList) {
      const options = this.getListOptions();
      const config = this.getListService(options);
      loadList(config);
    },

    handleModal_reset(loadList) {
      this.init_data();
      this.handleModal_load(loadList);
    },

    handleModal_submit(checkedRows, loadRelatedList) {
      if (this.getRelatedListService) {
        this.itemList = checkedRows;

        let idList = Model.getSearchList_getIdList(checkedRows);
        let options = this.getRelatedListOptions(idList);
        const config = this.getRelatedListService(options);
        loadRelatedList(config, this.handleModal_submit_Success);
      }
      else {
        this.$emit(this.$kst.Enum.Event.Submit, checkedRows);
        this.hideFunc();
      }
    },
    handleModal_submit_Success(data) {
      this.$emit(this.$kst.Enum.Event.Submit, this.itemList, data);
      this.hideFunc();
    },

    handleList_check(itemList) {
      this.itemList = itemList;
    },
    
    /*** custom ***/

    getListOptions() {
      return {
        search: this.$kst.Out.getString(this.searchData.Search),
        field: Model.Search.SearchFields,
        excludeid: this.$kst.Out.getInteger(this.searchData.ExcludeID)
      };
    },

    getRelatedListOptions(idList) {
      return {
        itemid: idList
      };
    },

    init_data() {
      this.itemList = [];
      // searchData
      this.searchData.Search = "";
    }
  }
}
</script>