<template>
  <kst-table checkable paginated view-mode
    :action="hasAccessAction"
    :checkedRows.sync="userData.checkedRows"
    :data="dataList"
  >
    <b-table-column sortable
      v-slot="{ column, row }"
      field="DraftNumber"
      :label="Catalog.DraftNumber.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column centered sortable
      v-slot="{ column, row }"
      cell-class="ks-col-shortdate"
      field="Date"
      :label="Catalog.Date.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="ClientID"
      :label="Catalog.ClientID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="DispatchID"
      :label="Catalog.DispatchID.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="StockQuantity"
      :label="Catalog.StockQuantity.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column numeric sortable
      v-slot="{ column, row }"
      cell-class="ks-col-qty"
      field="TotalQty"
      :label="Catalog.TotalQty.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="Catalog"/>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip info-mode class="pr-4">
          <template #content>
            <kst-list info-mode :items="Model.getItemInfo(row)"/>
          </template>
          <kst-icon info-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <template #action="{ row }">
      <kst-tooltip detailsMode search-mode hover-only :content="Model.Sales.Module.FullName">
        <kst-button action-mode
          :class="Info.Button.Sales.Class"
          :label="Info.Button.Sales.Label"
          :module="Info.Button.Sales.Module"
          :moduleType="Info.Button.Sales.ModuleType"
          :urlParams="getUrlParams(row.ID)"
        />
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import Catalog from "../StavCatalog.js";
import Info from "../StavInfo.js";
import Model from "../StavModel.js";

export default {
  mixins: [RouterMixin],
  props: {
    access: Object,
    dataList: Array,
    userData: Object
  },
  data: () => ({
    Catalog: Catalog,
    Info: Info,
    Model: Model
  }),
  computed: {
    hasAccessAction() {
      return this.access.Sales_Act_Details;
    }
  },
  methods: {
    getUrlParams(id) {
      return this.$kst.Route.getParams({
        [this.$kst.Enum.Param.ID]: id
      });
    }
  }
}
</script>