<template>
  <kst-table action input-mode :data="userVendorItemList">
    <b-table-column
      v-slot="{ column, row }"
      cell-class="ks-col-image"
      field="ImageSrc"
      :label="VeniCatalog.ImageSrc.Label"
    >
      <kst-image read-only thumbnail :src="row[column.field]"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      field="Name"
      :label="VeniCatalog.Name.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-sku"
      field="SKU"
      :label="VeniCatalog.SKU.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column sortable
      v-slot="{ column, row }"
      cell-class="ks-col-barcode"
      field="Barcode"
      :label="VeniCatalog.Barcode.Label"
    >
      <kst-value :field="column.field" :data="row" :catalog="VeniCatalog"/>
    </b-table-column>

    <b-table-column
      v-slot="{ column, row, index }"
      cell-class="ks-col-packaging"
      field="PackagingName"
      :label="VeniCatalog.PackagingName.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="VeniCatalog"
        :index="index" :options="row.PackagingOptions"
        @input="handlePackaging(row)" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-qty"
      field="Qty"
      :label="VeniCatalog.Qty.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="VeniCatalog"
        :index="index" @error="handleRowErrors"
      />
    </b-table-column>

    <b-table-column numeric
      v-slot="{ column, row, index }"
      cell-class="ks-col-price"
      field="BuyPrice"
      :label="VeniCatalog.BuyPrice.Label"
    >
      <kst-input error-off field-only
        :field="column.field" :data="row" :catalog="VeniCatalog"
        :index="index" @error="handleRowErrors"
      >
      </kst-input>
    </b-table-column>

    <b-table-column cell-class="ks-col-nopad" header-class="p-0" width="1">
      <template #header>
        <kst-header-blank />
      </template>
      <template #default="{ row }">
        <kst-tooltip v-if="row.Errors.length > 0" error-mode>
          <template #content>
            <kst-list :items="row.Errors"/>
          </template>
          <kst-icon error-mode/>
        </kst-tooltip>
      </template>
    </b-table-column>

    <template #action="{ index }">
      <kst-tooltip delete-mode hover-only :content="Info.Button.VendorItem.Label">
        <kst-button delete-mode is-round @click="handleRowDelete(index)"/>
      </kst-tooltip>
    </template>
  </kst-table>
</template>

<script>
import DialogMixin from "../../../mixins/DialogMixin.js";
import VeniCatalog from "../VeniCatalog.js";
import Info from "../VenInfo.js";
import Model from "../VenModel.js";

export default {
  mixins: [DialogMixin],
  props: {
    userVendorItemList: Array
  },
  data: () => ({
    Info: Info,
    VeniCatalog: VeniCatalog
  }),
  methods: {
    handlePackaging(item) {
      Model.updateVendorItemByPackaging(item);
      Model.updateVendorItem(item);
    },
    handleBuyPrice(vendorItem) {
      Model.updateVendorItem(vendorItem);
    },

    handleRowDelete(index) {
      this.showConfirm(
        Info.Message.Details_VendorItem_Submit_ConfirmDelete,
        this.handleRowDelete_Confirm,
        null,
        index
      );
    },
    handleRowDelete_Confirm(index) {
      this.userVendorItemList.splice(index, 1);
    },

    handleRowErrors(errors, field, index) {
      Model.setVendorItemErrors(this.userVendorItemList[index], field, errors);
    },
  }
}
</script>