<template>
  <div class="block">
    <ClientSearchListAddress
      @init="handleClient_init"
      @submit="handleClient_submit"
    />

    <kst-number-with-date view-mode
      :catalog="Catalog"
      :data="userData"
      dateField="InvoiceDate"
      numberField="SONumber"
    />

    <kst-output control-off status-off
      field="ClientID" :data="userData" :catalog="Catalog"
    >
      <template #left>
        <kst-output addons floating-label
          field="ClientAlias" :data="userData" :catalog="Catalog"
        />
      </template>
      <template #default>
        <kst-input addons expanded floating-label
          field="Client" :data="userData" :catalog="Catalog"
          :disabled="clientDisabled"
        />
      </template>
      <template #right>
        <template v-if="userData.SalesDraftID === null || userData.SalesDraftIsActive === false">
          <div class="control">
            <template v-if="userData.ClientID === null">
              <kst-tooltip search-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button search-mode @click="handleSearch_client"/>
              </kst-tooltip>
            </template>
            <template v-else>
              <kst-tooltip clear-mode hover-only :content="Catalog.ClientID.Label">
                <kst-button clear-mode @click="handleClear_client"/>
              </kst-tooltip>
            </template>
          </div>
        </template>
      </template>
    </kst-output>

    <kst-input field="ClientAddress" :data="userData" :catalog="Catalog"/>
    <kst-input field="PONumber" :data="userData" :catalog="Catalog"/>
    <kst-input field="DueDate" :data="userData" :catalog="Catalog"
      :customValidations="dueDateValidations"
    />
    <kst-output status-off control-off
      field="WarehouseID" :data="userData" :catalog="Catalog"
    />
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";
import Model from "../InvModel.js";
import Services from "../../../services/Api/InvoiceServices.js";

import ClientSearchListAddress from "../../Client/SearchListAddress/CliScLAddress.vue";

export default {
  components: {
    ClientSearchListAddress
  },
  props: {
    userData: Object,
    userItems: Array
  },
  data: () => ({
    Catalog: Catalog,
    // func
    showFunc: null
  }),
  computed: {
    clientDisabled() {
      if (this.userData.ClientSourceID ===
        Model.InvoiceClientSourceID.MasterClient.ID
      ) {
        return true;
      }

      if (this.userData.ClientID !== null) {
        return true;
      }

      if (this.userData.SalesDraftID !== null && 
        this.userData.SalesDraftIsActive === true
      ) {
        return true;
      }

      return false;
    },
    dueDateValidations() {
      return Model.Edit.validationDueDate(this.userData);
    }
  },
  methods: {
    /*** by client modal ***/

    handleClient_init(show) {
      this.showFunc = show;
    },
    handleClient_submit(row) {
      Model.Edit.setDetailsByClient(this.userData, row);
    },

    /*** custom ***/

    handleSearch_client() {
      this.showFunc(Services.getRelatedClientAddressList);
    },
    handleClear_client() {
      Model.Edit.clearDetailsByClient(this.userData);
    }
  }
}
</script>