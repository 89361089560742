<template>
  <div class="block">
    <div class="columns">
      <div class="column is-half">
        <kst-section :title="Catalog.DebtTotal.Label">
          <template #top-right>
            <kst-title>{{ debtTotal }}</kst-title>
          </template>
        </kst-section>
      </div>
    </div>

    <template v-if="hasAccessDebt">
      <DebtRelatedList :items="debtList"/>
    </template>
  </div>
</template>

<script>
import Catalog from "../VenCatalog.js";

import DebtRelatedList from "../../Debt/RelatedList/DebtRL.vue";

export default {
  components: {
    DebtRelatedList
  },
  props: {
    details: Object,
    debtList: Array
  },
  data: () => ({
    Catalog: Catalog
  }),
  computed: {
    debtTotal() {
      return this.$kst.Value.getValue("DebtTotal", this.details, Catalog);
    },
    hasAccessDebt() {
      return Array.isArray(this.debtList);
    }
  }
}
</script>