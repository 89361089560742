<template>
  <kst-loading />
</template>

<script>
import AppModule from "../../../services/App/AppModule.js";
import AxiosMixin from "../../../mixins/AxiosMixin.js";
import RouterMixin from "../../../mixins/RouterMixin.js";
import Model from "../SecurityModel.js";
import Services from "../../../services/Api/SecurityServices.js";

export default {
  mixins: [AxiosMixin, RouterMixin],
  created() {
    this.logout();
  },
  methods: {
    logout() {
      const config = Services.postLogout();

      this.runAxios(config, null,
        this.logout_success,
        this.logout_fault,
        this.logout_fault
      );
    },
    logout_success() {
      Model.clearSession();
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignIn));
    },
    logout_fault(message) {
      console.log(message);

      Model.clearSession();
      this.redirectTo(this.$kst.Route.getPath(AppModule.SignIn));
    }
  }
}
</script>
