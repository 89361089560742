<template>
  <span />
</template>

<script>
import XlsxMixin from "../../../mixins/XlsxMixin.js";
import Catalog from "../WhsCatalog.js";
import Info from "../WhsInfo.js";
import Model from "../WhsModel.js";

export default {
  mixins: [XlsxMixin],
  props: {
    userData: Object
  },
  created() {
    this.$emit(this.$kst.Enum.Event.Init, this.exportExcel);
  },
  methods: {
    exportExcel(data, fileName) {
      this.createBook();
      this.createSheet();

      this.addList(data.List);

      this.download(fileName);
    },

    addList(dataList) {
      // label
      this.addColumnHeader_ID();
      this.addColumnHeader(Catalog.Name);
      this.addColumnHeader(Catalog.Address);
      this.addColumnHeader(Catalog.PIC);
      this.addColumnHeader(Catalog.NoPIC);
      this.addColumnHeader(Catalog.CompanyName);
      this.addColumnHeader(Catalog.CompanyAddress);
      this.addColumnHeader(Catalog.CompanyPhone);
      this.addColumnHeader(Catalog.IsActive);

      // value
      for (const row of dataList) {
        this.addRow();

        this.addCellValue_ID(row);
        this.addCellValue("Name", row, Catalog);
        this.addCellValue("Address", row, Catalog);
        this.addCellValue("PIC", row, Catalog);
        this.addCellValue("NoPIC", row, Catalog);
        this.addCellValue("CompanyName", row, Catalog);
        this.addCellValue("CompanyAddress", row, Catalog);
        this.addCellValue("CompanyPhone", row, Catalog);
        this.addCellStatus(row.IsActive, Model.IsActive);
      }

      this.addSheet(Info.Export.List.Excel.SheetName.List);
    }
  }
}
</script>