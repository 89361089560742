<template>
  <kst-container v-if="hasAccess" class="kst-dashboard-list2 px-5 py-5">
    <nav class="level is-align-items-start">
      <div class="level-left">
        <div class="has-text-weight-bold">
          {{ title }}
        </div>
      </div>
      <div class="level-right">
        <kst-button action-mode
          class="is-primary"
          :has-access="hasButtonAccess"
          :iconLeft="buttonIcon"
          :label="titleButton"
          :module="module"
          :moduleType="moduleType"
          :urlParams="urlParams"
        />
      </div>
    </nav>

    <slot />
  </kst-container>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import AppCatalog from "../../../services/App/AppCatalog.js";

export default {
  mixins: [RouterMixin],
  props: {
    // button
    buttonIcon: String,
    hasButtonAccess: Boolean,
    module: Object,
    moduleType: Object,
    urlParams: String,
    // other
    hasAccess: Boolean,
    title: String
  },
  computed: {
    titleButton() {
      return AppCatalog.Info.Report;
    }
  }
}
</script>