<template>
  <div>
    <kst-output print-mode field="SONumber" :data="details" :catalog="Catalog"/>
    <kst-output print-mode field="InvoiceDate" :data="details" :catalog="Catalog"/>
  </div>
</template>

<script>
import Catalog from "../InvCatalog.js";

export default {
  props: {
    details: Object
  },
  data: () => ({
    Catalog: Catalog
  })
}
</script>