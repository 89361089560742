import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import RrcvItemCatalog from "./RrcvItemCatalog.js";

export default {
  setDetailsByReceive(userData, receiveDetails) {
    // user input
    userData.WarehouseID = receiveDetails.WarehouseID;
    userData.ReceiveID = receiveDetails.ID;
    // defined by system
    userData.ReceiveNumber = receiveDetails.ReceiveNumber;
    userData.ReceiveDate = App.In.getDate(receiveDetails.Date);
    userData.VendorID = receiveDetails.VendorID;
    userData.VendorAlias = receiveDetails.VendorAlias;
    userData.VendorName = receiveDetails.VendorName;
    userData.VendorAddress = receiveDetails.VendorAddress;
  },
  setItemByReceiveItem(item, receiveItem) {
    // user input
    item.DispatchID = receiveItem.DispatchID;
    item.Qty = App.In.getInteger(receiveItem.QtyAvailable);
    // defined by system
    item.ReceiveItemID = receiveItem.ID;
    item.BuyPrice = App.In.getInteger(receiveItem.BuyPrice);
    item.PackagingName = receiveItem.PackagingName;
    item.DispatchName = receiveItem.Name;
    item.PackagingValue = receiveItem.PackagingValue;
    item.SKU = receiveItem.SKU;
    item.DispatchIsActive = App.In.getBoolean(receiveItem.DispatchIsActive);
    item.ReceiveItemQty = receiveItem.QtyAvailable;
  },

  getItemInfo(item) {
    let infoList = [];

    // deleted item
    if (!item.DispatchIsActive) {
      infoList.push(AppCatalog.Info.DeletedItem);
    }

    if (item.DispatchIsActive) {
      // SKU
      infoList.push(RrcvItemCatalog.SKU.Label + ": " +
        App.Value.getValue("SKU", item, RrcvItemCatalog)
      );
    }

    return infoList;
  }
}