import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.paymenttype;

export default {
  getEditData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata"
    };
  },
  getList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  }
}