<template>
  <kst-section :title="Info.Section.InvoiceList.Title">
    <kst-table input-mode :data="recPayData.InvoiceList">
      <b-table-column
        v-slot="{ column, row }"
        cell-class="ks-col-shortdate"
        field="InvoiceDate"
        :label="RcpyInvCatalog.InvoiceDate.Label"
      >
        <kst-output boxOff field-only
          :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="InvoiceID"
        :label="RcpyInvCatalog.InvoiceID.ColumnLabel"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="ClientID"
        :label="RcpyInvCatalog.ClientID.Label"
      >
        <kst-output field-only
          :controlOff="row.ClientID === null"
          :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        />
      </b-table-column>

      <b-table-column
        v-slot="{ column, row }"
        field="WarehouseID"
        :label="RcpyInvCatalog.WarehouseID.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        />
      </b-table-column>

      <b-table-column numeric
        v-slot="{ column, row }"
        cell-class="ks-col-price"
        field="PaymentValue"
        :label="RcpyInvCatalog.PaymentValue.Label"
      >
        <kst-output field-only
          :field="column.field" :data="row" :catalog="RcpyInvCatalog"
        />
      </b-table-column>
    </kst-table>
  </kst-section>
</template>

<script>
import RcpyInvCatalog from "../RcpyInvCatalog.js";
import Info from "../RcpyInfo.js";

export default {
  props: {
    recPayData: Object
  },
  data: () => ({
    RcpyInvCatalog: RcpyInvCatalog,
    Info: Info
  })
}
</script>