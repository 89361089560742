import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import ModuleAttrModel from "../ModuleAttr/ModuleAttrModel.js";
import ModelBody from "./AcsModel_Body.js";
import ModelEdit from "./AcsModel_Edit.js";

export default {
  // required in model
  Module: AppModule.Access,
  ModuleType: {
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    List: AppModuleType.List,
    New: AppModuleType.New
  },

  /*** related ***/

  AccessModule: ModelBody.AccessModule,
  User: ModelBody.User,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  IsActive: ModelBody.IsActive,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails(ModelBody.IsActive);
  },
  createIsActiveOptions(optionAllText) {
    return ModelBody.createIsActiveOptions(optionAllText, ModelBody.IsActive);
  },

  selectListByModuleAtrr(userModuleList, moduleList, moduleAttr) {
    ModelBody.selectListByModuleAtrr(userModuleList, moduleList, moduleAttr);
  },

  updateModulesBySelection(userModuleAttrList, checkedList, row) {
    ModelBody.updateModulesBySelection(userModuleAttrList, checkedList, row);
  },

  populateData(userData, userModuleList, userModuleAttrList) {
    let result = ModelBody.populateDetails(userData);
    result.Modules = ModelBody.populateModules(userModuleList);
    result.ModuleAttrs = ModelBody.populateModuleAttrs(
      userModuleAttrList, userModuleList
    );
    return result;
  },

  /*** method - Edit ***/

  Edit: {
    setDetailsByData(userData, detailsData, hasUser) {
      ModelEdit.setDetailsByData(userData, detailsData, hasUser);
    },
    setModulesByData(userModuleList, detailsModuleList, moduleList) {
      ModelEdit.setModulesByData(userModuleList, detailsModuleList, moduleList);
    },

    /*** external ***/

    getModuleAttr(moduleAttrList, moduleList, detailsModuleAttrList) {
      return ModuleAttrModel.getList_Edit(moduleAttrList, moduleList,
        detailsModuleAttrList
      );
    }
  },

  /*** method - New ***/

  New: {
    /*** external ***/

    getModuleAttr(moduleAttrList, moduleList) {
      return ModuleAttrModel.getList(moduleAttrList, moduleList);
    }
  }
}